<div class="flex flex-col center">

  <h2 class="padding10 no-margin">Roles of Customer Service</h2>

</div>

<mat-tab-group>

  <mat-tab label="Coach">

    <div class="padding10">

      <p>A coach is anyone who leads a team of providers. They are the first person a provider will 
        approach for help. They are responsible for their providers' skill development, performance, 
        and quality scores. They are among the most important people to a provider.</p>
  
      <p>If you want to achieve any of the following goals, this is exactly what you are looking for.</p>
      
      <app-coach-goals-card></app-coach-goals-card>
      
      <p>We are going to share our experience and knowledge with you. We will teach you our best practices, 
        share the tips and advice that has helped us grow our teams.</p>
      
    </div>

  </mat-tab>

  <mat-tab label="Owner">

    <div class="padding10">

      <p>An owner is a decision-maker who defines the rules and policies related to customer service.</p>
  
      <p>If you want to achieve any of the following goals, this is exactly what you are looking for.</p>
      
      <app-owner-goals-card></app-owner-goals-card>
      
      <p>We are going to share our experience and knowledge with you. We will teach you our best practices, 
        share the tips and advice that has helped us grow our clients' businesses as well as our own. We will 
        teach you exactly how we provide our customers with the best experiences every time and how we help 
        our client's with their experiences.</p>
      
    </div>

  </mat-tab>

  <mat-tab label="Provider">

    <div class="padding10">

      <p>For anyone and everyone who interacts with customers and provides customer service, whether it 
        be face-to-face, phone calls, emails, chats, text or video calls. We have learned a lot about 
        providing customers with great experiences and want to share what we have learned.</p>
  
      <p>We wrote this book for everyone who provides customer service and wants to to provide the best 
        experiences every time. If you want to improve your skills, get a higher bonus, a promotion or 
        a raise, this book is exactly what you are looking for.</p>
  
      <p>We know that, as a customer service provider, we have the ability to provide each customer with 
        the best experience every time we interact with them, even when things did not go well for the 
        customer.</p>
  
      <p>We are going to share our experience and knowledge with you. We will teach you our 
        best practices, share tips and the advice that has helped us become the best providers at every 
        company we have worked for. We will teach you exactly how we provide our customers with 
        the best experiences possible.</p>
  
      <h3>We will help you achieve any of the following goals:</h3>
  
      <app-provider-goals-card></app-provider-goals-card>
  
    </div>

  </mat-tab>

</mat-tab-group>

<div class="flex flex-col main-content"
  [ngSwitch]="currentRole">

  

  

</div>
