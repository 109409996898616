import { Component } from '@angular/core';
import { COACH, ProductIdMap, TRAINING } from '@bcx/models';

@Component({
  selector: 'app-coach-book-step-content',
  templateUrl: './step-content.component.html',
  styleUrl: './step-content.component.sass',
})
export class StepContentComponent {

  product_id: string = ProductIdMap.training.books.coach;

}
