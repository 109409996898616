import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PaymentAgreementService } from '../payment-agreement.service';
import Bluebird from 'bluebird';
import { TrainingPaymentAgreement } from '@bcx/models';
import { SubsComponent } from '@bcx/ng-helpers';

@Component({
  selector: 'ng-training-payment-agreement-agreement-card',
  templateUrl: './agreement-card.component.html',
  styleUrls: ['./agreement-card.component.sass'],
})
export class AgreementCardComponent extends SubsComponent implements OnChanges, OnDestroy {

  checking = false;

  defaultDelay = 10000;

  error?: string;

  iframeLoading = true;

  _isDestroyed = false;

  loading: boolean;

  maxAttempts = 30;

  maxAttemptsReached = false;

  @Input() agreement?: TrainingPaymentAgreement;

  safeUrl?: SafeResourceUrl;

  url?: string;

  constructor(
    private sani: DomSanitizer,
    private service: PaymentAgreementService,
  ) {

    super();

  }

  checkURL(url: string, attempts = 0, delay = this.defaultDelay): Promise<boolean> {

    attempts++;

    return fetch(url)
    .then((response: Response) => {

      if (response.status === 403) {

        if (attempts > this.maxAttempts) {

          this.checking = false;
          this.maxAttemptsReached = true;

          throw new Error('Maximum Attempts Reached');

        } else if (this._isDestroyed) {

          return false;

        }

        return Bluebird.delay(delay)
        .then(() => {

          return this.checkURL(url, attempts, delay);

        });

      }

      return true;

    })
    .catch((e: Error) => {

      console.error(e);

      return false;

    });

  }

  fetchURL(agreement_id: string) {

    return this.service.generateSignedUrl({agreement_id});

  }
  
  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['agreement']) {

      this.onAgreementChange();

    }

  }

  override ngOnDestroy(): void {

    this._isDestroyed = true;
    
    super.ngOnDestroy();

  }

  onIframeLoad() {

    this.iframeLoading = false;

  }

  onAgreementChange() {

    if (this.subs['url']) {

      this.subs['url'].unsubscribe();

    }

    if (this.agreement) {

      

      this.fetchURL(this.agreement.agreement_id)
      .catch((e: Error) => {

        console.error(e);

      });

    }

  }

  tryAgain(url: string) {

    this.checking = true;
    this.maxAttemptsReached = false;

    return this.fetchURL(this.agreement?.agreement_id as string)
    .then(() => {

      this.checkURL(url);

    })
    .catch((e: Error) => {

      console.error(e);

    });

  }

}
