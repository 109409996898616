import { Component, Input, TemplateRef, } from '@angular/core';
import { CalendarDay } from '../day';
import { Subject } from 'rxjs';
import { CalendarEvent } from '../../ng-calendar.module';

@Component({
  selector: 'ng-calendar-day',
  templateUrl: './day.component.html',
  styleUrls: ['./day.component.sass'],
})
export class DayComponent {

  @Input() day: CalendarDay;

  @Input() eventClick: Subject<CalendarEvent>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() linkTemplate: TemplateRef<any>;
  
  @Input() showMonthName = false;

}
