import { UserCognito, UserCognitoActionNameMap, UserCognitoCreateAction, UserCognitoCreatePayload, UserCognitoGetAction, UserCognitoGetPayload, UserCognitoListAction, UserCognitoListPayload, UserCognitoStatuses } from "@bcx/models";
import { epoch } from "@digitaltoolbuilders/schema-helpers";
import { ExclusiveStartKey } from "@bcx/models";
import Joi from "joi";
import { user_id } from "../common";
import { createActionSchema } from "../model-action";
import { consistent_read } from "../dynamo-db";

export type Create = Omit<UserCognito, 'last_updated_epoch' | 'schema_version'>
  & Partial<Pick<UserCognito, 'schema_version'>>;

export type Delete = Pick<UserCognito, 'last_updated_epoch' | 'user_id' | 'user_pool_id'>;

export type Get = Pick<UserCognito, 'user_name' | 'user_pool_id'> & {
  consistent_read?: boolean;
};

export type List = Partial<Pick<UserCognito, 'user_id' | 'user_name' | 'user_pool_id'>> & {
  exclusive_start_key?: ExclusiveStartKey,
  limit?: number;
};

export type Update = Omit<UserCognito, 'schema_version'>;

export const alias = Joi.string();

export const region = Joi.string();

export const state = Joi.string().allow(...UserCognitoStatuses);

export const user_name = Joi.string();

export const user_pool_id = Joi.string();

export const create = Joi.object<UserCognitoCreatePayload>()
  .keys({
    alias: alias.required(),
    last_login_epoch: epoch.optional().allow(null),
    region: region.required(),
    state: state.required(),
    user_id: user_id.required(),
    user_name: user_name.required(),
    user_pool_id: user_pool_id.required(),
  });

export const createAction = createActionSchema<UserCognitoCreateAction>(UserCognitoActionNameMap.CREATE, create);

export const get = Joi.object<UserCognitoGetPayload>()
  .keys({
    consistent_read: consistent_read,
    user_name: user_name.required(),
    user_pool_id: user_pool_id.required(),
  });

export const getAction = createActionSchema<UserCognitoGetAction>(UserCognitoActionNameMap.GET, get);

export const list = Joi.object<UserCognitoListPayload>()
  .keys({
    user_id: user_id.optional().allow(null),
  });

export const listAction = createActionSchema<UserCognitoListAction>(UserCognitoActionNameMap.LIST, list);
