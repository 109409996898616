import { FormControl, FormGroup, Validators } from "@angular/forms";

export class ConfirmForm extends FormGroup {

  constructor() {

    super({
      confirm: new FormControl(false, [Validators.requiredTrue]),
      confirmed_epoch: new FormControl(null, []),
      google_calendar_event_link: new FormControl(null, [Validators.required]),
      google_meet_details: new FormControl(null, [Validators.required]),
      google_meet_link: new FormControl(null, [Validators.required]),
    });

  }

}
