import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { parsePhoneNumber } from 'awesome-phonenumber';

export function isPhoneNumber(control: AbstractControl) {

  const errors: ValidationErrors = {};

  if (!control.value || control.value === '') {

    errors['invalid'] = true;

    return errors;

  }

  const pn = parsePhoneNumber(control.value);

  // console.log('isPhoneNumber', control.value, pn);

  if (pn.valid) {

    return null;

  } else {

    errors[pn.possibility] = true;

    return errors;

  }

}

export class IntlPhoneNumberControl extends FormControl {

  constructor(config: { validators?: Array<ValidatorFn> } = {}) {

    super();

    this.addValidators(isPhoneNumber);

    if (config.validators) {

      this.addValidators(config.validators);

    }

  }

}
