import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './form/form.component';
import { CardComponent } from './card/card.component';
import { NgPickerModule } from '@bcx/ng-helpers';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    CardComponent,
    FormComponent,
  ],
  exports: [
    CardComponent,
    FormComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    NgPickerModule,
    ReactiveFormsModule,
  ]
})
export class AddressModule { }
