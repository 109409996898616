import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import { featureKey, reducer } from './state/customer.reducer';
import { ListTableComponent } from './list-table/list-table.component';
import { MatTableModule } from '@angular/material/table';
import { PipesModule } from '../pipes/pipes.module';
import { FullDetailsComponent } from './full-details/full-details.component';
import { SummaryCardComponent } from './summary-card/summary-card.component';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [
    ListTableComponent,
    FullDetailsComponent,
    SummaryCardComponent,
  ],
  exports: [ListTableComponent, FullDetailsComponent, SummaryCardComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatTableModule,
    PipesModule,
    StoreModule.forFeature(featureKey, reducer),
  ],
})
export class CustomerModule {}
