
import { on } from '@ngrx/store';
import { createImmerReducer } from 'ngrx-immer/store';
import { loadAllSubscriptions, loadSubscription, loadMySubscriptions } from './subscription.actions';
import { Stripe } from 'stripe';
import { STRIPE_SUBSCRIPTION } from '@bcx/models';
import { Entities, Entity, InternalManyEntitiesState } from '@bcx/ng-helpers';

export const featureKey = STRIPE_SUBSCRIPTION;

export interface State {

  all: InternalManyEntitiesState<Stripe.Subscription, string>;

  entities: Entities<Stripe.Subscription>;

  my: InternalManyEntitiesState<Stripe.Subscription, string>;

}

export const initialState: State = {
  all: {
    ids: [],
    loading: false,
  },
  entities: {},
  my: {
    ids: [],
    loading: false,
  }
};

export function createEntityId(id: string) {

  return `${id}`;

}

export function getEntity(id: string, state: State) {

  const entity: Entity<Stripe.Subscription> = state.entities[id] || {
    loaded: false,
    loading: false,
    processing: false,
  };

  return entity;

}

export const reducer = createImmerReducer(
  initialState,

  on(loadSubscription, (state: State, { error, id, loading, subscription }) => {

    const entityId = createEntityId(id);

    const entity = getEntity(id, state);

    entity.error = error;
    entity.loading = loading;

    if (subscription) {

      entity.model = subscription;
      entity.loaded = Date.now();

    }

    state.entities[entityId] = entity;

    return state;

  }),
  on(loadAllSubscriptions, (state: State, { data, error, last, loading, }) => {

    state.all.error = error;
    state.all.last = last;
    state.all.loading = loading;

    if (data) {

      const loaded = Date.now();

      data.forEach((invoice: Stripe.Subscription) => {

        const id = createEntityId(invoice.id as string);

        const entity = getEntity(id, state);

        entity.model = invoice;
        entity.loaded = loaded;
        entity.loading = false;

        state.entities[id] = entity;

      });

    }

    return state;

  }),
  on(loadMySubscriptions, (state: State, { data, error, loading }) => {

    state.my.error = error;
    state.my.loading = loading;

    if (data) {

      const loaded = Date.now();

      data.forEach((invoice: Stripe.Subscription) => {

        const id = createEntityId(invoice.id as string);

        const entity = getEntity(id, state);

        entity.model = invoice;
        entity.loaded = loaded;
        entity.loading = false;

        state.entities[id] = entity;

        if (!state.my.ids.includes(id)) {

          state.my.ids.push(id);
          
        }

      });

    }

    return state;

  }),

);
