import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PickerComponent } from './picker/picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { NowPickerComponent } from './now-picker/now-picker.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TimeModule } from '../time/time.module';

@NgModule({
  declarations: [PickerComponent, NowPickerComponent],
  exports: [PickerComponent, NowPickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
    TimeModule,
  ],
})
export class DateModule {}
