<div class="flex flex-col h-full w-full">

  <div class="flex flex-row justify-center">

    <h2>We will teach you everything you need to provide the best customer experiences everytime.</h2>

  </div>

  <div class="flex flex-col gap-y-6 justify-between">

    <app-marketing-books></app-marketing-books>

    <app-marketing-training></app-marketing-training>

    <app-marketing-consulting></app-marketing-consulting>

  </div>

</div>
