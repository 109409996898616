import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AttendingAsOptions, ConsultingSessionAttendee } from '@bcx/models';

@Component({
  selector: 'ng-consulting-session-add-attendee-form',
  templateUrl: './add-attendee-form.component.html',
  styleUrls: ['./add-attendee-form.component.sass'],
})
export class AddAttendeeFormComponent implements OnChanges {

  @Input() attendees: Array<ConsultingSessionAttendee>;

  attendingAsOptions = AttendingAsOptions;

  @Input() form: FormGroup;

  ignoreUsers: Array<string> = [];

  userControl: FormControl;

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['attendees']) {

      this.ignoreUsers = (this.attendees || []).map(attendee => attendee.user_id);

    }

    if (changes['form'] && this.form) {

      this.userControl = this.form.get('user') as FormControl;

    }

  }

}
