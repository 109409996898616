<mat-form-field
  *ngIf="control">

  <mat-label>{{label}}</mat-label>

  <mat-select
    [formControl]="control"
    matInput>

    <mat-option
      *ngFor="let country of countries"
      [value]="country">{{country}}</mat-option>

  </mat-select>

  <mat-error>Please select a country.</mat-error>

</mat-form-field>