import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ConsultingSession } from '@bcx/models';

@Component({
  selector: 'ng-consulting-session-list-table',
  templateUrl: './list-table.component.html',
  styleUrls: ['./list-table.component.sass'],
})
export class ListTableComponent implements OnChanges {

  // eslint-disable-next-line
  @Input() actions: TemplateRef<any>;

  @Input() actionsLabel = 'Actions';

  @Input() display = [
    'session_id',
    'state',
    'actions',
  ];

  @Input() sessions: Array<ConsultingSession>;

  table = new MatTableDataSource<ConsultingSession>([]);

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['sessions']) {

      this.table.data = this.sessions || [];

    }

  }

}
