import { Component, Input, OnChanges } from '@angular/core';
import { ProductPrice } from '@bcx/models';
import { SubsComponent } from '@bcx/ng-helpers';
import { PriceService } from '../../../billing/price/price.service';

@Component({
  selector: 'ng-consulting-session-type-option',
  templateUrl: './type-option.component.html',
  styleUrls: ['./type-option.component.sass'],
})
export class TypeOptionComponent extends SubsComponent implements OnChanges {

  @Input() category: string;

  @Input() currency?: string;

  error?: string;

  loading: boolean;

  price?: ProductPrice;

  prices: Array<ProductPrice> = [];

  processing: boolean;

  @Input() role: string;

  @Input() sessionType: string;

  constructor(private service: PriceService) {

    super();

  }

  checkPrice() {

    this.price = this.prices.find((price) => {

      return price.currency === this.currency
        && price.session_category === this.category
        && price.session_type === this.sessionType

    });

  }

  ngOnChanges(): void {
    
    this.checkPrice();

  }

}
