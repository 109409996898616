import { APP_INITIALIZER, InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from './api.service';

export function initializeModuleFactory(service: ApiService) {

  return () => {

    return service.init();

  };

}

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [ ApiService ],
      multi: true,
      useFactory: initializeModuleFactory,
    }
  ]
})
export class ApiModule { }
