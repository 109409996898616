import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Currencies, CurrencyCodesMap } from '@bcx/models';

@Component({
  selector: 'ng-picker-mini-select-currency',
  templateUrl: './mini-select.component.html',
  styleUrls: ['./mini-select.component.sass'],
})
export class MiniSelectComponent implements OnInit {

  codes = CurrencyCodesMap;

  @Input() control: FormControl;

  currencies = Currencies;

  ngOnInit(): void {
    
    console.log('code', this.codes);

  }

}
