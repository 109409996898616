import { StripeProductActionNameMap, StripeProductGetAction, StripeProductGetPayload, StripeProductListAction, StripeProductListPayload } from "@bcx/models";
import Joi from "joi";
import { subscription_id } from "./common";
import { createActionSchema } from "../model-action";

export const get = Joi.object<StripeProductGetPayload>()
  .keys({
    id: subscription_id.required(),
  });

export const getAction = createActionSchema<StripeProductGetAction>(StripeProductActionNameMap.GET, get);

export const list = Joi.object<StripeProductListPayload>()
  .keys({
    
  });

export const listAction = createActionSchema<StripeProductListAction>(StripeProductActionNameMap.LIST, list);
