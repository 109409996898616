import { Component } from '@angular/core';
import { ProductIdMap } from '@bcx/models';

@Component({
  selector: 'app-small-to-best-training-step-content',
  templateUrl: './step-content.component.html',
  styleUrl: './step-content.component.sass',
})
export class StepContentComponent {

  product_id = ProductIdMap.training.courses['small-to-best'];

}
