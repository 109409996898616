import { Component } from '@angular/core';
import { COACH, OWNER, PROVIDER } from '@bcx/models';

@Component({
  selector: 'app-ads-customer-service-roles',
  templateUrl: './customer-service-roles.component.html',
  styleUrl: './customer-service-roles.component.sass',
})
export class CustomerServiceRolesComponent {

  COACH = COACH;

  currentRole: string;

  OWNER = OWNER;

  PROVIDER = PROVIDER;

}
