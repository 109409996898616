import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { StartForm } from './start-form.form';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ng-training-class-start-form',
  templateUrl: './start-form.component.html',
  styleUrls: ['./start-form.component.sass'],
})
export class StartFormComponent implements OnChanges {

  @Input() form: StartForm;

  startedEpoch: FormControl;

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['form'] && this.form) {

      this.startedEpoch = this.form.get('started_epoch') as FormControl;

    }

  }

}
