import { Injectable } from '@angular/core';
// import { Analytics } from 'aws-amplify';
// import { AnalyticsEvent, PersonalizeAnalyticsEvent, EventAttributes } from '@aws-amplify/analytics/lib-esm/types';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  // record(event: AnalyticsEvent | PersonalizeAnalyticsEvent, provider?: string) {

  //   if ((event as AnalyticsEvent).attributes) {

  //     const typedEvent = <AnalyticsEvent> event;

  //     const typedAttrs = <EventAttributes> typedEvent.attributes;

  //     Object.keys(typedAttrs)
  //     .forEach((key: string) => {

  //       if (typedAttrs[key].length > 200) {

  //         typedAttrs[key] = typedAttrs[key].substring(0, 200);

  //       }

  //     });

  //   } else if ((event as PersonalizeAnalyticsEvent).properties) {

  //     const typedProps = <{[key: string]: string}> (event as PersonalizeAnalyticsEvent).properties;

  //     Object.keys(typedProps).forEach((key: string) => {

  //       if (typedProps[key] && typedProps[key].length > 200) {

  //         typedProps[key] = typedProps[key].substring(0,200);

  //       }

  //     });

  //   }

  //   return Analytics.record(event, provider);

  // }

}
