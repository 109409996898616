
import { CANCELED, COACH, CONFIRMED, CONSULTING_SESSION, FINISHED, IN_PERSON, ONLINE, OWNER, PROVIDER, REQUESTED, SCHEDULED, SMALL_TO_BEST, STARTED } from "../common";
import { ModelAbstract, ModelAction, ModelEvent, ModelInterface } from "../model-action";
import { ConsistentRead, ExclusiveStartKey, LastEvaluatedKey, Limit } from "../helpers";
import { List } from "@digitaltoolbuilders/model-helpers";

export class ConsultingSession extends ModelAbstract implements ConsultingSessionInterface {

  actual: ConsultingSessionTiming;

  billing: ConsultingSessionBilling;

  cancellation?: ConsultingSessionStatusChange;

  category: ConsultingSessionCategory;

  confirmation?: ConsultingSessionStatusChange;

  currency: string;

  google?: ConsultingSessionGoogle;

  scheduled: ConsultingSessionTiming;

  session_id: string;

  status: ConsultingSessionStatus;

  type: ConsultingSessionType;

  constructor(input: Partial<ConsultingSession>) {

    super(input);

    if (input.actual) this.actual = input.actual;
    if (input.billing) this.billing = input.billing;
    if (input.cancellation) this.cancellation = input.cancellation;
    if (input.category) this.category = input.category;
    if (input.confirmation) this.confirmation = input.confirmation;
    if (input.currency) this.currency = input.currency;
    if (input.google) this.google = input.google;
    if (input.scheduled) this.scheduled = input.scheduled;
    if (input.session_id) this.session_id = input.session_id;
    if (input.status) this.status = input.status;
    if (input.type) this.type = input.type;

  }

  static override generateModelId(input: Pick<ConsultingSession, 'session_id'>): string {
    
    return `${input.session_id}`;

  }

}

export const ConsultingSessionActionNameMap = {
  CANCEL: `${CONSULTING_SESSION}/cancel`,
  CONFIRM: `${CONSULTING_SESSION}/confirm`,
  CREATE: `${CONSULTING_SESSION}/create`,
  FINISH: `${CONSULTING_SESSION}/finish`,
  GET: `${CONSULTING_SESSION}/get`,
  LIST: `${CONSULTING_SESSION}/list`,
  REQUEST: `${CONSULTING_SESSION}/request`,
  SET_ACTUAL_LENGTH: `${CONSULTING_SESSION}/set-actual-length`,
  START: `${CONSULTING_SESSION}/start`,
} as const;

export type ConsultingSessionActionName = typeof ConsultingSessionActionNameMap[keyof typeof ConsultingSessionActionNameMap];

export const ConsultingSessionActionNames = Object.values(ConsultingSessionActionNameMap);

export class ConsultingSessionActualLengthSetEvent extends ModelEvent<ConsultingSession> {

  readonly event_name = ConsultingSessionEventNameMap.ACTUAL_LENGTH_SET;

  constructor(input: Partial<Omit<ConsultingSessionActualLengthSetEvent, 'event_name'>>) {

    super(input);

  }

}

export interface ConsultingSessionBilling {

  currency: string;

  stripe_customer_id: string;

  stripe_payment_method_id?: string;

  stripe_price_id: string;

  stripe_product_id: string;

}

export class ConsultingSessionCancelAction extends ModelAction<ConsultingSessionCancelPayload>{

  readonly action_name = ConsultingSessionActionNameMap.CANCEL;

  constructor(input: Partial<Omit<ConsultingSessionCancelAction, 'action_name'>>) {

    super(input);

  }

}

export type ConsultingSessionCancellation = ConsultingSessionStatusChange;

export type ConsultingSessionConfirmation = ConsultingSessionStatusChange;

export class ConsultingSessionCanceledEvent extends ModelEvent<ConsultingSession> {

  readonly event_name = ConsultingSessionEventNameMap.CANCELED;

  constructor(input: Partial<Omit<ConsultingSessionCanceledEvent, 'event_name'>>) {

    super(input);

  }

}

export type ConsultingSessionCancelPayload = Pick<ConsultingSession, 'cancellation' | 'session_id' | 'last_updated_epoch'>;

export class ConsultingSessionConfirmAction extends ModelAction<ConsultingSessionConfirmPayload>{

  readonly action_name = ConsultingSessionActionNameMap.CONFIRM;

  constructor(input: Partial<Omit<ConsultingSessionConfirmAction, 'action_name'>>) {

    super(input);

  }

}

export class ConsultingSessionConfirmedEvent extends ModelEvent<ConsultingSession> {

  readonly event_name = ConsultingSessionEventNameMap.CONFIRMED;

  constructor(input: Partial<Omit<ConsultingSessionConfirmedEvent, 'event_name'>>) {

    super(input);

  }

}

export type ConsultingSessionConfirmPayload = Pick<ConsultingSession, 'confirmation' | 'google' | 'session_id' | 'last_updated_epoch'>;

export class ConsultingSessionCreateAction extends ModelAction<ConsultingSessionCreatePayload>{

  readonly action_name = ConsultingSessionActionNameMap.CREATE;

  constructor(input: Partial<Omit<ConsultingSessionCreateAction, 'action_name'>>) {

    super(input);

  }

}

export class ConsultingSessionCreatedEvent extends ModelEvent<ConsultingSession> {

  readonly event_name = ConsultingSessionEventNameMap.CREATED;

  constructor(input: Partial<Omit<ConsultingSessionCreatedEvent, 'event_name'>>) {

    super(input);

  }

}

export type ConsultingSessionCreatePayload = Omit<ConsultingSession, 
  | 'cancellation'
  | 'confirmation'
  | 'last_updated_epoch' 
  | 'model_id'>;

export const ConsultingSessionEventNameMap = {
  ACTUAL_LENGTH_SET: `${CONSULTING_SESSION}:actual-length-set`,
  CANCELED: `${CONSULTING_SESSION}:canceled`,
  CONFIRMED: `${CONSULTING_SESSION}:confirmed`,
  CREATED: `${CONSULTING_SESSION}:created`,
  FINISHED: `${CONSULTING_SESSION}:finished`,
  LISTED: `${CONSULTING_SESSION}:listed`,
  REQUESTED: `${CONSULTING_SESSION}:requested`,
  SCHEDULED: `${CONSULTING_SESSION}:scheduled`,
  STARTED: `${CONSULTING_SESSION}:started`,
  VIEWED: `${CONSULTING_SESSION}:viewed`,
} as const;

export class ConsultingSessionFinishAction extends ModelAction<ConsultingSessionFinishPayload>{

  readonly action_name = ConsultingSessionActionNameMap.FINISH;

  constructor(input: Partial<Omit<ConsultingSessionFinishAction, 'action_name'>>) {

    super(input);

  }

}
  
export class ConsultingSessionFinishedEvent extends ModelEvent<ConsultingSession> {

  readonly event_name = ConsultingSessionEventNameMap.FINISHED;

  constructor(input: Partial<Omit<ConsultingSessionFinishedEvent, 'event_name'>>) {

    super(input);

  }

}

export type ConsultingSessionFinishPayload = Pick<ConsultingSession, 'last_updated_epoch' | 'session_id'>
  & {
    actual_finish_epoch: number;
  };

export class ConsultingSessionGetAction extends ModelAction<ConsultingSessionGetPayload>{

  readonly action_name = ConsultingSessionActionNameMap.GET;

  constructor(input: Partial<Omit<ConsultingSessionGetAction, 'action_name'>>) {

    super(input);

  }

}

export type ConsultingSessionGetPayload = Pick<ConsultingSession, 'session_id'>
  & {
    consistent_read?: ConsistentRead;
  };

export class ConsultingSessionListAction extends ModelAction<ConsultingSessionListPayload>{

  readonly action_name = ConsultingSessionActionNameMap.LIST;

  constructor(input: Partial<Omit<ConsultingSessionListAction, 'action_name'>>) {

    super(input);

  }

}
  
export class ConsultingSessionListedEvent extends ModelEvent<List<ConsultingSession>> {

  readonly event_name = ConsultingSessionEventNameMap.LISTED;

  constructor(input: Partial<Omit<ConsultingSessionListedEvent, 'event_name'>>) {

    super(input);

  }

}

export type ConsultingSessionListPayload = Partial<Pick<ConsultingSession, 
  | 'category'
  | 'type'
  | 'status'>>
  & {
    exclusive_start_key?: ExclusiveStartKey;
    limit?: Limit;
  };
  
export class ConsultingSessionRequestAction extends ModelAction<ConsultingSessionRequestPayload>{

  readonly action_name = ConsultingSessionActionNameMap.REQUEST;

  constructor(input: Partial<Omit<ConsultingSessionRequestAction, 'action_name'>>) {

    super(input);

  }

}

export class ConsultingSessionRequestedEvent extends ModelEvent<ConsultingSession> {

  readonly event_name = ConsultingSessionEventNameMap.REQUESTED;

  constructor(input: Partial<Omit<ConsultingSessionRequestedEvent, 'event_name'>>) {

    super(input);

  }

}

export type ConsultingSessionRequestPayload = ConsultingSessionCreatePayload;

export class ConsultingSessionSetActualLengthAction extends ModelAction<ConsultingSessionSetActualLengthPayload>{

  readonly action_name = ConsultingSessionActionNameMap.SET_ACTUAL_LENGTH;

  constructor(input: Partial<Omit<ConsultingSessionSetActualLengthAction, 'action_name'>>) {

    super(input);

  }

}

export type ConsultingSessionSetActualLengthPayload = Pick<ConsultingSession, 'last_updated_epoch' | 'session_id'>
  & {
    actual_length_minutes: number;
  };
  
export class ConsultingSessionStartAction extends ModelAction<ConsultingSessionStartPayload>{

  readonly action_name = ConsultingSessionActionNameMap.START;

  constructor(input: Partial<Omit<ConsultingSessionStartAction, 'action_name'>>) {

    super(input);

  }

}

export class ConsultingSessionStartedEvent extends ModelEvent<ConsultingSession> {

  readonly event_name = ConsultingSessionEventNameMap.STARTED;

  constructor(input: Partial<Omit<ConsultingSessionStartedEvent, 'event_name'>>) {

    super(input);

  }

}

export type ConsultingSessionStartPayload = Pick<ConsultingSession, 'last_updated_epoch' | 'session_id'>
  & {
    actual_start_epoch: number;
  };
  
export interface ConsultingSessionGoogle {

  calendar_link: string;

  meet_details: string;

  meet_link: string;

}

export interface ConsultingSessionInterface extends ModelInterface {

  actual: ConsultingSessionTiming;

  billing: ConsultingSessionBilling;

  cancellation?: ConsultingSessionCancellation;

  category: ConsultingSessionCategory;

  confirmation?: ConsultingSessionConfirmation;

  google?: ConsultingSessionGoogle;

  last_updated_epoch: number;

  scheduled: ConsultingSessionTiming;

  session_id: string;

  status: ConsultingSessionStatus;

  type: ConsultingSessionType;

}

export const ConsultingSessionCategoryMap = {
  COACH,
  OWNER,
  PROVIDER,
  SMALL_TO_BEST,
} as const;

export type ConsultingSessionCategoryMapKeys = keyof typeof ConsultingSessionCategoryMap;

export type ConsultingSessionCategory = typeof ConsultingSessionCategoryMap[ConsultingSessionCategoryMapKeys];

export const ConsultingSessionCategories = Object.values(ConsultingSessionCategoryMap);

export interface ConsultingSessionStatusChange {

  action_id: string;

  epoch: number;

  reason: string;

  user_id: string;

}

export const ConsultingSessionStatusMap = {
  CANCELED,
  CONFIRMED,
  FINISHED,
  REQUESTED,
  SCHEDULED,
  STARTED,
} as const;

export type ConsultingSessionStatus = typeof ConsultingSessionStatusMap[keyof typeof ConsultingSessionStatusMap];

export const ConsultingSessionStatuses = Object.values(ConsultingSessionStatusMap);

export interface ConsultingSessionTiming {

  finish_epoch?: number;

  length_minutes: number;

  start_epoch: number;

}

export const ConsultingSessionTypeMap = {
  IN_PERSON,
  ONLINE,
} as const;

export type ConsultingSessionType = typeof ConsultingSessionTypeMap[keyof typeof ConsultingSessionTypeMap];

export const ConsultingSessionTypes = Object.values(ConsultingSessionTypeMap);

export class ConsultingSessionViewedEvent extends ModelEvent<ConsultingSession> {

  readonly event_name = ConsultingSessionEventNameMap.VIEWED;

  constructor(input: Partial<Omit<ConsultingSessionViewedEvent, 'event_name'>>) {

    super(input);

  }

}
