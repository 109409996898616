<div>

  <mat-card
    *ngIf="invoice">

    <mat-card-header>

      <mat-card-title>Invoice #{{invoice.number}}</mat-card-title>

    </mat-card-header>

    <mat-card-content>

      <pre>{{invoice | json}}</pre>

    </mat-card-content>

  </mat-card>

</div>
