
import { createAction, props } from '@ngrx/store';
import { Stripe } from 'stripe';

export interface LoadMyInvoicesProps {

  data?: Array<Stripe.Invoice>;

  error?: string;

  last?: string;

  loading: boolean;

}

export const loadMyInvoices = createAction(
  '[Stripe.Invoice] Load My Invoices',
  props<LoadMyInvoicesProps>(),
);

export interface LoadInvoiceProps {

  invoice?: Stripe.Invoice;

  error?: string;

  id: string;

  loading: boolean;

}

export const loadInvoice = createAction(
  '[Stripe.Invoice] Load Invoice Method',
  props<LoadInvoiceProps>(),
);

export interface LoadAllInvoicesProps {

  data?: Array<Stripe.Invoice>;

  error?: string;

  last?: string;

  loading: boolean;

}

export const loadAllInvoices = createAction(
  '[Stripe.Invoice] Load All Invoices',
  props<LoadAllInvoicesProps>(),
);
