import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UserService } from '../user.service';
import { User } from '@bcx/models';
import { SubsComponent } from '@bcx/ng-helpers';

@Component({
  selector: 'ng-user-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.sass'],
})
export class NameComponent extends SubsComponent implements OnChanges {

  error?: string;

  loaded?: number;

  loading: boolean;

  user?: User;

  @Input() userId: string;

  constructor(
    private service: UserService
  ) {

    super();

  }

  fetchUser() {

    return this.service.get({
      user_id: this.userId,
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['userId']) {

      this.onUserIdChange();

    }

  }

  private onUserIdChange() {

    if (this.subs['user']) {

      this.subs['user'].unsubscribe();

    }

    if (this.userId) {

      

      this.fetchUser()
      .catch((e) => {

        console.error(e);

      });
  
    }

  }

}
