<div>

  <mat-card>

    <mat-card-content>

      <pre>{{invoice | json}}</pre>

    </mat-card-content>

  </mat-card>

</div>
