import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Countries } from '@bcx/models';

@Component({
  selector: 'ng-billing-settings-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.sass'],
})
export class SelectCountryComponent {

  @Input() control: FormControl;

  countries = Countries;

  @Input() label = 'Select A Country';

}
