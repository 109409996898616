<div fxLayout="column"
  *ngIf="form"
  [formGroup]="form">

  <div fxLayout="row" fxLayoutAlign="center center">

    <button type="button" color="primary"
      *ngIf="session"
      (click)="createGoogleCalendarEvent()"
      mat-raised-button>Create Google Calendar Event</button>

  </div>

  <mat-form-field>

    <mat-label>Google Meet Details</mat-label>

    <input type="text"
      formControlName="google_meet_details"
      matInput>

    <mat-error>Please copy and paste the Google Meet details.</mat-error>

  </mat-form-field>

  <mat-form-field>

    <mat-label>Google Calendar Link</mat-label>

    <input type="text"
      formControlName="google_calendar_link"
      matInput>

    <mat-error>Please copy and paste the Google Calendar link.</mat-error>

  </mat-form-field>

  <mat-checkbox formControlName="confirm">
    I can attend this session.
  </mat-checkbox>

</div>