<mat-card>

  <mat-card-header>

    <mat-card-title>Small To Best</mat-card-title>
    <mat-card-subtitle>How to implement big ideas in a small business with an even smaller budget.</mat-card-subtitle>

  </mat-card-header>

  <mat-card-content>

    

  </mat-card-content>

  <mat-card-actions>

    <ng-content></ng-content>

  </mat-card-actions>

</mat-card>