import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { GetStartedState } from '../get-started.state';
import { GetStartedService } from '../get-started.service';

@Component({
  selector: 'app-get-started-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrl: './recommendations.component.sass',
})
export class RecommendationsComponent {

  vm$: Observable<GetStartedState>;

  constructor(
    private service: GetStartedService,
  ) { 

    this.vm$ = this.service.state$;

    this.vm$.subscribe((state) => console.log('state', state));

  }

}
