import { List } from "@digitaltoolbuilders/model-helpers";
import { BILLING_SETTINGS, INVOICE_PER_SESSION, ONCE_PER_MONTH } from "../common";
import { ConsistentRead, ExclusiveStartKey, Limit } from "../helpers";
import { ModelAbstract, ModelAction, ModelEvent, ModelInterface } from "../model-action";

export interface ConsultingBillingSettings {

  preference: ConsultingBillingPreference;

  stripe_subscription_id?: string;

}

export class BillingSettings extends ModelAbstract implements BillingSettingsInterface {

  consulting: ConsultingBillingSettings;

  default_currency: string;

  default_payment_method?: string;

  user_id: string;

  constructor(input: Partial<BillingSettings>) {

    super(input);

    if (input.consulting) this.consulting = input.consulting;
    if (input.default_currency) this.default_currency = input.default_currency;
    if (input.default_payment_method) this.default_payment_method = input.default_payment_method;
    if (input.user_id) this.user_id = input.user_id;

  }

}

export const BillingSettingsActionNameMap = {
  CREATE: `${BILLING_SETTINGS}/create`,
  GET: `${BILLING_SETTINGS}/get`,
  LIST: `${BILLING_SETTINGS}/list`,
  UPDATE: `${BILLING_SETTINGS}/update`,
} as const;

export type BillingSettingsActionName = typeof BillingSettingsActionNameMap[keyof typeof BillingSettingsActionNameMap];

export const BillingSettingsActionNames = Object.values(BillingSettingsActionNameMap);

export class BillingSettingsCreateAction extends ModelAction<BillingSettingsCreatePayload> {

  readonly action_name = BillingSettingsActionNameMap.CREATE;

  constructor(input: Partial<Omit<BillingSettingsCreateAction, 'action_name'>>) {

    super(input);

  }

}

export class BillingSettingsCreatedEvent extends ModelEvent<BillingSettings> {

  readonly event_name = BillingSettingsEventNameMap.CREATED;

  constructor(input: Partial<Omit<BillingSettingsCreatedEvent, 'event_name'>>) {

    super(input);

  }

}

export type BillingSettingsCreatePayload = Omit<BillingSettings, 'last_updated_epoch'>;

export const BillingSettingsEventNameMap = {
  CREATED: `${BILLING_SETTINGS}:created`,
  LISTED: `${BILLING_SETTINGS}/listed`,
  UPDATED: `${BILLING_SETTINGS}/updated`,
  VIEWED: `${BILLING_SETTINGS}:viewed`,
} as const;

export type BillingSettingsEventName = typeof BillingSettingsEventNameMap[keyof typeof BillingSettingsEventNameMap];

export const BillingSettingsEventNames = Object.values(BillingSettingsEventNameMap);

export class BillingSettingsGetAction extends ModelAction<BillingSettingsGetPayload> {

  readonly action_name = BillingSettingsActionNameMap.GET;

  constructor(input: Partial<Omit<BillingSettingsGetAction, 'action_name'>>) {

    super(input);

  }

}

export type BillingSettingsGetPayload = Pick<BillingSettings, 'user_id'> & {
  consistent_read?: ConsistentRead;
};

export interface BillingSettingsInterface extends ModelInterface {

  consulting: ConsultingBillingSettings;

  default_currency: string;

  default_payment_method?: string;

  user_id: string;

}

export class BillingSettingsListAction extends ModelAction<BillingSettingsListPayload> {

  readonly action_name = BillingSettingsActionNameMap.LIST;

  constructor(input: Partial<Omit<BillingSettingsListAction, 'action_name'>>) {

    super(input);

  }

}

export class BillingSettingsListedEvent extends ModelEvent<List<BillingSettings>> {

  readonly event_name = BillingSettingsEventNameMap.LISTED;

  constructor(input: Partial<Omit<BillingSettingsListedEvent, 'event_name'>>) {

    super(input);

  }

}

export type BillingSettingsListPayload = Pick<BillingSettings, 'default_currency'> & {
  exclusive_start_key?: ExclusiveStartKey;
  limit?: Limit;
};

export class BillingSettingsUpdateAction extends ModelAction<BillingSettingsUpdatePayload> {

  readonly action_name = BillingSettingsActionNameMap.UPDATE;

  constructor(input: Partial<Omit<BillingSettingsUpdateAction, 'action_name'>>) {

    super(input);

  }

}

export class BillingSettingsUpdatedEvent extends ModelEvent<BillingSettings> {

  readonly event_name = BillingSettingsEventNameMap.UPDATED;

  constructor(input: Partial<Omit<BillingSettingsUpdatedEvent, 'event_name'>>) {

    super(input);

  }

}

export type BillingSettingsUpdatePayload = BillingSettings;

export const ConsultingBillingPreferenceMap = {
  INVOICE_PER_SESSION,
  ONCE_PER_MONTH,
} as const;

export type ConsultingBillingPreference = typeof ConsultingBillingPreferenceMap[keyof typeof ConsultingBillingPreferenceMap];

export const ConsultingBillingPreferences = Object.values(ConsultingBillingPreferenceMap);

export class BillingSettingsViewedEvent extends ModelEvent<BillingSettings> {

  readonly event_name = BillingSettingsEventNameMap.VIEWED;

  constructor(input: Partial<Omit<BillingSettingsViewedEvent, 'event_name'>>) {

    super(input);

  }

}
