
export interface Address {

  city?: string;

  country?: string;

  postal_code?: string;

  state?: string;

  street?: string;

  town?: string;

  unit?: string;

}
