<div>

  <div class="flex flex-row justify-between"
    mat-dialog-title>

    <h1 class="text-lg">Other Options</h1>

    <button type="button" color="warn"
      (click)="closeDialog()"
      mat-icon-buton><mat-icon>close</mat-icon></button>

  </div>

  <mat-dialog-content>

    
  <div class="flex flex-row justify-between">

    <ng-container 
      *ngFor="let option of options"
      [ngSwitch]="option.step_type">
    
      <app-small-to-best-book-card *ngSwitchCase="BOOK">

        <button type="button" color="primary"
          (click)="selectBook(option)"
          mat-raised-button>Select Book</button>

      </app-small-to-best-book-card>

      <app-small-to-best-consulting-card *ngSwitchCase="CONSULTING">

        <button type="button" color="primary"
          (click)="selectConsulting(option)"
          mat-raised-button>Select Consulting</button>

      </app-small-to-best-consulting-card>

      <app-small-to-best-training-card *ngSwitchCase="TRAINING">

        <button type="button" color="primary"
          (click)="selectTraining(option)"
          mat-raised-button>Select Training</button>

      </app-small-to-best-training-card>

    </ng-container>

  </div>


  </mat-dialog-content>

</div>