<div fxLayout="column" fxLayoutGap="10px"
  *ngIf="form"
  [formGroup]="form">

  <div fxLayout="row" fxLayoutAlign="center center">

    <button type="button" color="primary"
      *ngIf="trainingClass"
      (click)="createGoogleCalendarEvent()"
      mat-raised-button>Create Google Calendar Event</button>

  </div>

  <mat-form-field>

    <mat-label>Google Calendar Event Link</mat-label>

    <input type="text"
      formControlName="google_calendar_event_link"
      matInput>

    <mat-error>Please create a Google Calendar event and paste the link here.</mat-error>

  </mat-form-field>

  <mat-form-field>

    <mat-label>Google Meet Details</mat-label>

    <input type="text"
      formControlName="google_meet_details"
      matInput>

    <mat-error>Please create a Google Meet and paste the details here.</mat-error>

  </mat-form-field>

  <mat-checkbox formControlName="confirm">I want to confirm this class.</mat-checkbox>

</div>
