import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LevelOfImportanceComponent } from './level-of-importance/level-of-importance.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [LevelOfImportanceComponent],
  exports: [LevelOfImportanceComponent],
  imports: [CommonModule, MatButtonToggleModule, ReactiveFormsModule],
})
export class GoalModule {}
