import { inject, Inject, Injectable } from '@angular/core';
import { ConsultingSessionAttendeeListAction, ConsultingSessionAttendeeListPayload } from '@bcx/models';
import { consultingSessionAttendee as schemas } from '@bcx/schemas';
import { v4 } from 'uuid';
import { AttendeeServiceAdapter } from './attendee.service.adapter';
import { BACKEND_ADAPTER, BackendAdapter, BackendAdapterMap } from '@bcx/ng-helpers';
import { RestService } from './rest.service';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class AttendeeService {

  private adapter: AttendeeServiceAdapter;

  constructor(
    @Inject(BACKEND_ADAPTER) backendAdapter: BackendAdapter,
  ) { 

    switch (backendAdapter) {

      case BackendAdapterMap.REST:

        this.adapter = inject(RestService);

        break;

      case BackendAdapterMap.WEBSOCKET:

        this.adapter = inject(WebsocketService);

        break;

      default:

        throw new Error(`unknown adapter: ${backendAdapter}`);

    }

  }

  list(data: ConsultingSessionAttendeeListPayload, now = Date.now()) {

    return schemas.list.validateAsync(data, {})
    .then((clean) => {

      const group = 'all';

      const action = new ConsultingSessionAttendeeListAction({
        action_id: v4(),
        epoch: now,
        model_id: group,
        payload: clean,
        user_id: '',
      });

      return this.adapter.list(action);

    });

  }

}
