import Joi from "joi";
import { ModelAction } from "@bcx/models";
import { epoch } from "@digitaltoolbuilders/schema-helpers";
import { user_id } from "./common";

export const action_id = Joi.string().uuid();

export const action_name = Joi.string();

export const payload = Joi.any();

export const modelAction = Joi.object<ModelAction>()
  .keys({
    action_id: action_id.required(),
    action_name: action_name.required(),
    epoch: epoch.required(),
    payload: payload.required(),
    user_id: user_id.required(),
  });

export function createActionSchema<Action extends ModelAction>(name: string, payload: Joi.Schema) {

  return Joi.object<Action>().keys({
    action_id: action_id.required(),
    action_name: action_name.allow(name).required(),
    epoch: epoch.required(),
    payload: payload.required(),
    user_id: user_id.required(),
  });

}
