import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { OfficeHoursService } from '@engagedcx/ng-calendar';
import { SubsComponent } from '@bcx/ng-helpers';
import { addDays, isValid } from 'date-fns';
import format from 'date-fns/format';
import { combineLatest, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'ng-consulting-session-select-start',
  templateUrl: './select-start.component.html',
  styleUrls: ['./select-start.component.sass'],
})
export class SelectStartComponent extends SubsComponent implements OnChanges, OnInit {

  @Input() control: FormControl;

  dt: Date;

  dateControl: FormControl;

  localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  minDate = addDays(new Date(), 1);

  officeHoursEnd = this.officeHours.endTime;

  officeHoursStart = this.officeHours.startTime;

  timeControl: FormControl;

  constructor(
    private fb: FormBuilder,
    private officeHours: OfficeHoursService,
  ) {

    super();

    this.dateControl = this.fb.control('', [Validators.required]);

    this.timeControl = this.fb.control('', [Validators.required]);

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['control'] && this.control) {

      this.onControlChange();

    }

  }

  ngOnInit(): void {
    
    this.subs['controls'] = combineLatest({
      date: this.dateControl.valueChanges,
      time: this.timeControl.valueChanges,
    })
    .subscribe(({ date, time }) => {

      // console.log('controls', date, time);

      const dt = new Date(Date.parse(`${format(date, 'MM/dd/yyyy')} ${time}`));

      // console.log('controls', dt);

      if (isValid(dt) && this.control) {

        this.control.patchValue(dt.valueOf());

      }

    });

  }

  private onControlChange() {

    if (this.subs['control']) {

      this.subs['control'].unsubscribe();

    }

    if (this.control) {

      // console.log('select-start', 'control', this.control);

      this.subs['control'] = this.control.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((epoch: number) => {

        this.onControlValueChange(epoch);

      });

      this.onControlValueChange(this.control.value);

    }

  }

  private onControlValueChange(epoch?: number) {

    if (epoch) {

      const dt = new Date(epoch);
      
      if (isValid(dt)) {

        const time = format(dt, 'h:mm a');
    
        this.dateControl.patchValue(dt);
        this.timeControl.patchValue(time);
    
      }
  
    }

  }

}
