<div class="flex flex-col divide-y-2"
  [formGroup]="form">

  <app-question-length-of-time
    [control]="lengthInPosition"
    label="How long have you been a team leader?"></app-question-length-of-time>

  <app-question-number-of-people
    [control]="numberOfTeamMembers"
    label="How many people are on your team?"></app-question-number-of-people>

  <app-question-select-industry
    [control]="industry"
    label="What industry do you work in?"></app-question-select-industry>

  <app-question-length-of-time
    [control]="lengthInIndustry"
    label="How long have you been in this industry?"></app-question-length-of-time>

  <app-question-length-of-time
    [control]="lengthInBusiness"
    label="How long has the company you work for been in business?"></app-question-length-of-time>

  <app-question-number-of-people
    [control]="numberOfEmployees"
    label="How many employees does the company have?"></app-question-number-of-people>

</div>
