
import pino from 'pino';

export const LOG_LEVEL = process.env['PINO_LOG_LEVEL'] || 'info';

export const logger = pino({
  level: LOG_LEVEL,
});

export const parent = logger;
