import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SettingsService } from '../../settings.service';
import { OWNER, PROVIDER } from '@bcx/models';
import { SubsComponent } from '@bcx/ng-helpers';

@Component({
  animations: [
    trigger('rightLeft', [
      state('left', style({
        left: 0
      })),
      state('right', style({
        left: '60%'
      })),
      transition('* => *', [
        animate('190ms')
      ]),
    ])
  ],
  selector: 'app-role-switch',
  templateUrl: './role-switch.component.html',
  styleUrls: ['./role-switch.component.sass'],
})
export class RoleSwitchComponent extends SubsComponent implements OnInit {

  role: string;

  constructor(private service: SettingsService) {

    super();

  }

  ngOnInit(): void {
    
    this.subs['role'] = this.service.role$
    .subscribe((role: string) => {

      this.role = role;

    });

  }

  selectOwner() {

    this.service.setRole(OWNER);

  }

  selectProvider() {

    this.service.setRole(PROVIDER);

  }

}
