import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card/card.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmFormComponent } from './confirm-form/confirm-form.component';
import { ListAccordionComponent } from './list-accordion/list-accordion.component';
import { ListTableComponent } from './list-table/list-table.component';
import { ReleaseDialogComponent } from './release-dialog/release-dialog.component';
import { ReleaseFormComponent } from './release-form/release-form.component';
import { ResendConfirmationButtonComponent } from './resend-confirmation-button/resend-confirmation-button.component';
import { SmartCardComponent } from './smart-card/smart-card.component';
import { SummaryComponent } from './summary/summary.component';
import { SummaryCardComponent } from './summary-card/summary-card.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { UserModule } from '../../user/user.module';


@NgModule({
  declarations: [
    CardComponent,
    ConfirmDialogComponent,
    ConfirmFormComponent,
    ListAccordionComponent,
    ListTableComponent,
    ReleaseDialogComponent,
    ReleaseFormComponent,
    ResendConfirmationButtonComponent,
    SmartCardComponent,
    SummaryComponent,
    SummaryCardComponent,
  ],
  exports: [
    CardComponent,
    ConfirmDialogComponent,
    ConfirmFormComponent,
    ListAccordionComponent,
    ListTableComponent,
    ReleaseDialogComponent,
    ReleaseFormComponent,
    ResendConfirmationButtonComponent,
    SmartCardComponent,
    SummaryComponent,
    SummaryCardComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTableModule,
    ReactiveFormsModule,
    UserModule,
  ]
})
export class SeatModule { }
