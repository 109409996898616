<ng-container [ngSwitch]="ticket.ticket_type">

  <ng-training-ticket-book-summary-card
    *ngSwitchCase="BOOK"
    [ticket]="ticket"></ng-training-ticket-book-summary-card>

  <ng-training-ticket-class-summary-card
    *ngSwitchCase="CLASS"
    [ticket]="ticket"></ng-training-ticket-class-summary-card>

  <ng-training-ticket-video-summary-card
    *ngSwitchCase="VIDEO"
    [ticket]="ticket"></ng-training-ticket-video-summary-card>

</ng-container>
