import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { addDays, startOfDay } from 'date-fns';
import { SubsComponent } from '@bcx/ng-helpers';
import { CreateForm } from '../create-form/create-form.form';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ng-training-class-request-form',
  templateUrl: './request-form.component.html',
  styleUrls: ['./request-form.component.sass'],
})
export class RequestFormComponent extends SubsComponent implements OnChanges, OnDestroy {

  @Input() form: CreateForm;

  @Input() maxTime = '8:00 pm';

  @Input() minDate = startOfDay(addDays(new Date(), 1));

  @Input() minTime = '8:00 am';

  startEpoch: FormControl;

  startTimePicker: FormControl;

  constructor() {

    super();

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['form'] && this.form) {

      this.startEpoch = this.form.get('start_epoch') as FormControl;

    }

  }

}
