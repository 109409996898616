<div fxLayout="row wrap" fxLayoutGap="10px">

  <div 
    *ngFor="let trainingClass of trainingClasses">

    <ng-training-class-summary-card
      [actions]="actions"
      [trainingClass]="trainingClass"></ng-training-class-summary-card>

  </div>

  <div
    *ngIf="!trainingClasses || trainingClasses?.length === 0">

    <mat-card>

      <mat-card-content>

        <span>{{empty}}</span>

      </mat-card-content>

    </mat-card>
  </div>

  <div
    *ngIf="loading">

    <mat-spinner></mat-spinner>

  </div>

</div>
