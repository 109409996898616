import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { Path, PathStep, ProductIdMap } from '@bcx/models';
import { SettingsService } from '@bcx/ng-models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-owner-training-step-content',
  templateUrl: './step-content.component.html',
  styleUrl: './step-content.component.sass',
})
export class StepContentComponent {

  @Input() path: Observable<Path>;

  @Input() step: Observable<PathStep>;

  product_id = ProductIdMap.training.courses.owner;

  vm$: Observable<{
    currency: string;
    otherOptionsPrice: number;
    path: Path;
    step: PathStep;
  }>;

  constructor(
    private settings: SettingsService
  ) {

    this.vm$ = combineLatest({
      currency: this.settings.currency$,
      otherOptionsPrice: of(499),
      path: this.path,
      step: this.step,
    });

  }

}
