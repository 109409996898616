<div
  *ngIf="form"
  [formGroup]="form">

  <ng-billing-settings-select-currency
    [control]="currency"></ng-billing-settings-select-currency>

  <ng-stripe-payment-method-select
    [control]="defaultPaymentMethod"
    [paymentMethods]="paymentMethods"
    label="Default Payment Method"></ng-stripe-payment-method-select>

</div>
