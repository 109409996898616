import { Injectable, OnDestroy } from '@angular/core';
import { COACH, CXRole, OWNER, PROVIDER, UNDETERMINED } from '@bcx/models';
import { cloneDeep, merge } from 'lodash';
import { BehaviorSubject, throttleTime } from 'rxjs';
import { InitialQuestions } from './initial-questions/initial-questions.form';
import { DeepPartial, DEFAULT_STATE, GetStartedState } from './get-started.state';
import { toSignal } from '@angular/core/rxjs-interop';
import { PersonService, AttributeService } from '@bcx/ng-models';
import { ComponentSubscriptions, unsubscribeAll } from '@bcx/ng-helpers';

export const CUSTOMER_PROFILE_ATTRIBUTE = 'customer_profile';

export const LOCAL_STORAGE_KEY = 'get-started-state';

@Injectable({
  providedIn: 'root'
})
export class GetStartedService implements OnDestroy {

  private state = new BehaviorSubject<GetStartedState>(cloneDeep(DEFAULT_STATE));

  state$ = this.state.asObservable();

  $state = toSignal(this.state$);

  private subs: ComponentSubscriptions = {};

  constructor(
    private attribute: AttributeService,
    private person: PersonService,
  ) {

    const state = this.getLocalState();

    if (state) {

      this.state.next(state);

    }

    this.subs['status'] = this.state$
    .pipe(throttleTime(250))
    .subscribe((state) => {

      this.saveLocal(state);

      this.saveRemote(state);

    });

  }

  clearIndustry() {

    const state = this.state.getValue();

    state.industry = UNDETERMINED;

    this.state.next(state);

  }

  clearRole() {

    const state = this.state.getValue();

    state.role = UNDETERMINED;

    this.state.next(state);

  }

  determineRole(input: InitialQuestions) {

    let role: CXRole;

    if (input.is_business_owner) {

      role = OWNER;

    } else if (input.is_team_leader) {

      role = COACH;

    } else if (input.can_change_policy) {

      role = OWNER;

    } else {

      role = PROVIDER;

    }

    return role;

  }

  getCurrentState() {

    return this.state.getValue();

  }

  private getLocalState() {

    const state = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (state) {

      return JSON.parse(state) as GetStartedState;

    }

    return null;

  }

  ngOnDestroy(): void {
    
    unsubscribeAll(this.subs);

  }

  reset() {

    this.state.next(DEFAULT_STATE);

  }

  save(state: DeepPartial<GetStartedState>) {

    const current = this.state.getValue();

    const next = merge(current, state);

    this.state.next(next);

  }

  private saveLocal(state: GetStartedState) {

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));

  }

  private saveRemote(state: GetStartedState) {

    return this.person.me()
    .then((me) => {

      return this.attribute.save({
        account_id: me.account_id,
        person_id: me.person_id,
        attr_key: CUSTOMER_PROFILE_ATTRIBUTE,
        attr_value: state,
        model_id: me.model_id,
      });

    });

  }

}
