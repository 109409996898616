
export const ACCOUNT = 'account';

export const ACHIEVE_PROMOTIONS = 'achieve-promotions';

export const ACTIVATION_FAILED = 'activation-failed';

export const ACTIVE = 'active';

export const ACTIVATING = 'activating';

export const ADMIN = 'admin';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyPayload = any;

export const ARCHIVED = 'ARCHIVED';

export const ARRIVED = 'arrived';

export const AVAILABLE = 'available';

export const BANNED = 'banned';

export const BEST_EXPERIENCES_EVERY_TIME = 'best-experiences-every-time';

export const BILLING_SETTINGS = 'billing-settings';

export const BOOK = 'book';

export const BOTH_ROLES = 'both-roles';

export const CANCELED = 'canceled';

export const CLASS = 'class';

export const CLIENT = 'client';

export const CLOSED = 'closed';

export const COACH = 'coach';

export const COLLECTABLE = 'collectable';

export const COMPROMISED = 'COMPROMISED';

export const CONDITIONAL = 'conditional';

export const CONFIRMED = 'CONFIRMED';

export const CONSULTANT = 'consultant';

export const CONSULTING = 'consulting';

export const CONSULTING_BILLING_SETTINGS = `${CONSULTING}.billing-settings`;

export const CONSULTING_SESSION = `${CONSULTING}.session`;

export const CONSULTING_SESSION_ATTENDEE = `${CONSULTING_SESSION}.atttendee`;

export const CUSTOMER = 'customer';

export const DRAFT = 'draft';

export const DECREASE_COST_PER_MINUTE = 'decrease-cost-per-minute';

export const DECREASE_REPEAT_INTERACTIONS = 'decrease-repeat-interactions';

export const DECREASE_TURN_OVER_RATE = 'decrease-turn-over-rate';

export const EBOOK = 'ebook';

export const EMAIL = 'email';

export const EMAIL_ADDRESS = 'email-address';

export const FINISHED = 'finished';

export const FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD';

export const HARDCOVER = 'hardcover';

export const INACTIVE = 'inactive';

export const INCREASE_BONUS_COMMISSION = 'increase-bonus-commission';

export const INCREASE_COMPLIANCE_QUALITY_SCORES = 'increase-compliance-quality-scores';

export const INCREASE_AVERAGE_PURCHASE = 'increase-average-purchase';

export const INCREASE_CONVERSION_RATE = 'increase-conversion-rate';

export const INCREASE_CSAT = 'increase-csat'

export const INCREASE_CUSTOMER_BASE = 'increase-customer-base';

export const INCREASE_JOB_SATISFACTION = 'increase-job-satisfaction';

export const INCREASE_PAY_RATE = 'increase-pay-rate';

export const INCREASE_ROI = 'increase-roi';

export const IN_PERSON = 'in-person';

export const INVOICE_PER_SESSION = 'invoice-per-session';

export const LEFT = 'left';

export const MAIL_ADDRESS = 'mail-address';

export const MORE_PREMIUMS_UPGRADES = 'more-premiums-upgrades';

export const MORE_REFERRALS = 'more-referrals';

export const NEW_CONNECTION = 'new-connection';

export const NO_HANDLER = 'no-handler';

export const OPEN = 'open';

export const ONCE_PER_MONTH = 'once-per-month';

export const ONLINE = 'online';

export const OWNER = 'owner';

export const PAID = 'paid';

export const PAPERBACK = 'paperback';

export const PAST = 'past';

export const PAST_DUE = 'past-due';

export const PERSON = 'person';

export const PERSON_ATTRIBUTE = `${PERSON}.attribute`;

export const PERSON_CONTACT_POINT = `${PERSON}.contact-point`;

export const PHONE_NUMBER = 'phone-number';

export const PROSPECTIVE = 'prospective';

export const PROVIDER = 'provider';

export const RAISE_CSAT = 'raise-csat';

export const REDEEMED = 'redeemed';

export const RELEASED = 'released';

export const RELEASE_NEGATIVITY = 'release-negativity';

export const REQUESTED = 'requested';

export const RESET_REQUIRED = 'RESET_REQUIRED';

export const REVOKED = 'revoked';

export const SCHEDULED = 'scheduled';

export const SIDE_HUSTLE = 'side-hustle';

export const SMALL_TO_BEST = 'small-to-best';

export const SMS = 'sms';

export const STARTED = 'started';

export const STRIPE = 'stripe';

export const STRIPE_CHARGE = `${STRIPE}.charge`;

export const STRIPE_CUSTOMER = `${STRIPE}.customer`;

export const STRIPE_INVOICE = `${STRIPE}.invoice`;

export const STRIPE_PAYMENT_METHOD = `${STRIPE}.payment-method`;

export const STRIPE_PRICE = `${STRIPE}.price`;

export const STRIPE_PRICES = `${STRIPE}.prices`;

export const STRIPE_PRODUCT = `${STRIPE}.product`;

export const STRIPE_PRODUCTS = `${STRIPE}.products`;

export const STRIPE_SUBSCRIPTION = `${STRIPE}.subscription`;

export const SUSPENDED = 'suspended';

export const TRAINER = 'trainer';

export const TRAINING = 'training';

export const TRAINING_BOOK_ORDER = `${TRAINING}.book-order`;

export const TRAINING_CLASS = `${TRAINING}.class`;

export const TRAINING_PAYMENT_AGREEMENT = `${TRAINING}.payment-agreement`;

export const TRAINING_SEAT = `${TRAINING}.seat`;

export const TRAINING_TICKET = `${TRAINING}.ticket`;

export const UNCOLLECTABLE = 'uncollectable';

export const UNCONFIRMED = 'UNCONFIRMED';

export const UNDETERMINED = 'undetermined';

export const UNKNOWN = 'UNKNOWN';

export const UNREGISTERED = 'unregistered';

export const USER = 'user';

export const USER_ACCOUNT_RECOVERY = `${USER}.account-recovery`;

export const USER_COGNITO = `${USER}.cognito`;

export const USER_PERSON = `${USER}.person`;

export const VIDEO = 'video';

export const VOICE = 'voice';
