import { Routes } from "@angular/router";
import { InitialComponent } from "./initial/initial.component";
import { RecommendationsComponent } from "./recommendations/recommendations.component";

export const routes: Routes = [
  {
    component: InitialComponent,
    path: 'get-started',
  },
  {
    component: RecommendationsComponent,
    path: 'get-started/recommendations',
  },
];
