import { Component, Input } from '@angular/core';
import Stripe from 'stripe';

@Component({
  selector: 'ng-stripe-payment-method-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass'],
})
export class ListComponent {

  @Input() methods: Array<Stripe.PaymentMethod>;

}
