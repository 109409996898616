import { Component, Inject } from '@angular/core';
import { SubsComponent } from '@bcx/ng-helpers';
import { StartForm } from '../start-form/start-form.form';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SessionService } from '../session.service';
import { ConsultingSession } from '@bcx/models';

@Component({
  selector: 'ng-consulting-start-dialog',
  templateUrl: './start-dialog.component.html',
  styleUrls: ['./start-dialog.component.sass'],
})
export class StartDialogComponent extends SubsComponent {

  error?: string;

  form: StartForm;

  processing: boolean;

  session: ConsultingSession;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ConsultingSession,
    private dialog: MatDialogRef<StartDialogComponent>,
    private service: SessionService,
  ) {

    super();

    this.form = new StartForm();

    this.session = data;

  }

  closeDialog() {

    this.dialog.close();

  }

  submit() {

    console.log('form', this.form.value);

    this.service.start(this.session, {
      actual_start_epoch: this.form.value.actual_start_epoch
    })
    .then((started: ConsultingSession) => {

      this.dialog.close(started);

    })
    .catch((e: Error) => {

      console.error(e);

    });

  }

}
