import { INCREASE_CSAT, ProductIdMap, SmallToBestGoalNameMap } from "@bcx/models";
import { RecommendationMap } from "./recommendation.service";
import { GetStartedState } from "../get-started/get-started.state";

export const SmallToBestRecommendationMap: RecommendationMap = {};

SmallToBestRecommendationMap[INCREASE_CSAT] = {
  content: 'Increase customer satisfaction scores',
  isMatch: (state: GetStartedState) => {

    if (state.has_small_business && (state.small_to_best.goals[SmallToBestGoalNameMap.INCREASE_CSAT] || false)) {

      return true;

    }

    return false;

  },
  products: [
    ProductIdMap.training.books["small-to-best"],
    ProductIdMap.training.courses["small-to-best"],
  ],
};

export const SmallToBestRecommendations = Object.values(SmallToBestRecommendationMap);
