import { AttendingAsOptions, ConsultingSessionAttendeeActionNameMap, ConsultingSessionAttendeeAddAction, ConsultingSessionAttendeeAddPayload, ConsultingSessionAttendeeArriveAction, ConsultingSessionAttendeeArrivePayload, ConsultingSessionAttendeeCancelAction, ConsultingSessionAttendeeCancelPayload, ConsultingSessionAttendeeConfirmAction, ConsultingSessionAttendeeConfirmPayload, ConsultingSessionAttendeeGetAction, ConsultingSessionAttendeeGetPayload, ConsultingSessionAttendeeLeaveAction, ConsultingSessionAttendeeLeavePayload, ConsultingSessionAttendeeListAction, ConsultingSessionAttendeeListPayload, ConsultingSessionAttendeeRemoveAction, ConsultingSessionAttendeeRemovePayload, ConsultingSessionAttendeeStatusChange, ConsultingSessionAttendeeTiming } from "@bcx/models";
import { epoch } from "@digitaltoolbuilders/schema-helpers";
import Joi from "joi";
import { session_id } from "../session";
import { action_id, action_name, createActionSchema } from "../../model-action";
import { reason, user_id } from "../../common";

export const attending_as = Joi.string().allow(...AttendingAsOptions);

export const scheduled_arrival_epoch = epoch;

export const statusChange = Joi.object<ConsultingSessionAttendeeStatusChange>()
  .keys({
    action_id: action_id.required(),
    epoch: epoch.required(),
    reason: reason.required(),
    user_id: user_id.required(),
  });

export const cancellation = statusChange;

export const confirmation = statusChange;

export const timing = Joi.object<ConsultingSessionAttendeeTiming>()
  .keys({
    arrival_epoch: epoch.required(),
    departure_epoch: epoch.optional().allow(null),
  });

export const add = Joi.object<ConsultingSessionAttendeeAddPayload>()
  .keys({
    attending_as: attending_as.required(),
    scheduled: timing.required(),
    session_id: session_id.required(),
    user_id: user_id.required(),
  });

export const addAction = createActionSchema<ConsultingSessionAttendeeAddAction>(ConsultingSessionAttendeeActionNameMap.ADD, add);

export const arrive = Joi.object<ConsultingSessionAttendeeArrivePayload>()
  .keys({
    arrival_epoch: epoch.required(),
    last_updated_epoch: epoch.required(),
    session_id: session_id.required(),
    user_id: user_id.required(),
  });

export const arriveAction = createActionSchema<ConsultingSessionAttendeeArriveAction>(ConsultingSessionAttendeeActionNameMap.ARRIVE, arrive);

export const cancel = Joi.object<ConsultingSessionAttendeeCancelPayload>()
  .keys({
    cancellation: cancellation.required(),
    last_updated_epoch: epoch.required(),
    session_id: session_id.required(),
    user_id: user_id.required(),
  });

export const cancelAction = Joi.object<ConsultingSessionAttendeeCancelAction>()
  .keys({
    action_id: action_id.required(),
    action_name: action_name.required(),
    epoch: epoch.required(),
    payload: cancel.required(),
    user_id: user_id.required(),
  });

export const confirm = Joi.object<ConsultingSessionAttendeeConfirmPayload>()
.keys({
  confirmation: confirmation.required(),
  last_updated_epoch: epoch.required(),
  session_id: session_id.required(),
  user_id: user_id.required(),
});

export const confirmAction = Joi.object<ConsultingSessionAttendeeConfirmAction>()
.keys({
  action_id: action_id.required(),
  action_name: action_name.required(),
  epoch: epoch.required(),
  payload: confirmation.required(),
  user_id: user_id.required(),
});

export const get = Joi.object<ConsultingSessionAttendeeGetPayload>()
.keys({
  session_id: session_id.required(),
  user_id: user_id.required(),
});

export const getAction = createActionSchema<ConsultingSessionAttendeeGetAction>(ConsultingSessionAttendeeActionNameMap.GET, get);

export const leave = Joi.object<ConsultingSessionAttendeeLeavePayload>()
  .keys({
    departure_epoch: epoch.required(),
    last_updated_epoch: epoch.required(),
    session_id: session_id.required(),
    user_id: user_id.required(),
  });

export const leaveAction = Joi.object<ConsultingSessionAttendeeLeaveAction>()
  .keys({
    action_id: action_id.required(),
    action_name: action_name.required(),
    epoch: epoch.required(),
    payload: leave.required(),
    user_id: user_id.required(),
  });

export const list = Joi.object<ConsultingSessionAttendeeListPayload>()
  .keys({
    session_id: session_id.required(),
    user_id: user_id.required(),
  });

export const listAction = createActionSchema<ConsultingSessionAttendeeListAction>(ConsultingSessionAttendeeActionNameMap.LIST, list);

export const remove = Joi.object<ConsultingSessionAttendeeRemovePayload>()
  .keys({
    last_updated_epoch: epoch.required(),
    session_id: session_id.required(),
    user_id: user_id.required(),
  });

export const removeAction = createActionSchema<ConsultingSessionAttendeeRemoveAction>(ConsultingSessionAttendeeActionNameMap.REMOVE, remove);
