<div class="ng-picker-select-currency" fxLayout="column">

  <mat-form-field
    *ngIf="control">

    <mat-label>Currency</mat-label>

    <mat-select
      [formControl]="control">

      <mat-option 
        *ngFor="let currency of currencies"
        [value]="currency">{{currency}}</mat-option>

    </mat-select>

  </mat-form-field>

</div>
