import { Component, Input } from '@angular/core';
import { TrainingPaymentAgreement } from '@bcx/models';

@Component({
  selector: 'ng-training-payment-agreement-full-detail',
  templateUrl: './full-detail.component.html',
  styleUrls: ['./full-detail.component.sass'],
})
export class FullDetailComponent {

  @Input() agreement: TrainingPaymentAgreement;

}
