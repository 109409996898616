<div fxLayout="column"
  *ngIf="form"
  [formGroup]="form">

  <mat-form-field>

    <mat-label>Cancellation Reason</mat-label>

    <input type="text"
      formControlName="reason"
      matInput>

    <mat-error>Please enter a reason for cancellation.</mat-error>

  </mat-form-field>

  <mat-checkbox formControlName="confirm">I am want to cancel this session.</mat-checkbox>

</div>