import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DayComponent } from './day/day.component';
import { EventModule } from '../event/event.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [DayComponent],
  imports: [
    CommonModule,
    EventModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [DayComponent],
})
export class DayModule {}
