
import { v4 } from "uuid";
import { ARRIVED, CANCELED, CLIENT, CONFIRMED, CONSULTANT, CONSULTING_SESSION_ATTENDEE, DRAFT, LEFT } from "../common";
import { ConsistentRead, ExclusiveStartKey, Limit } from "../helpers";
import { ModelAbstract, ModelAction, ModelEvent, ModelInterface } from "../model-action";
import { List } from "@digitaltoolbuilders/model-helpers";
import { merge } from "lodash";
import Bluebird from "bluebird";

export const AttendingAsOptionMap = {
  CLIENT,
  CONSULTANT,
} as const;

export type AttendingAs = typeof AttendingAsOptionMap[keyof typeof AttendingAsOptionMap];

export const AttendingAsOptions = Object.values(AttendingAsOptionMap);

export class ConsultingSessionAttendee extends ModelAbstract implements ConsultingSessionAttendeeInterface {

  arrival?: ConsultingSessionAttendeeArrival;
  
  attending_as: AttendingAs;

  cancellation?: ConsultingSessionAttendeeCancellation;

  confirmation?: ConsultingSessionAttendeeStatusChange;
  
  scheduled: ConsultingSessionAttendeeTiming;
  
  session_id: string;

  status: ConsultingSessionAttendeeStatus;

  user_id: string;

  constructor(input: Partial<ConsultingSessionAttendee>) {

    super(input);

    if (input.arrival) this.arrival = input.arrival;
    if (input.attending_as) this.attending_as = input.attending_as;
    if (input.cancellation) this.cancellation = input.cancellation;
    if (input.confirmation) this.confirmation = input.confirmation;
    if (input.scheduled) this.scheduled = input.scheduled;
    if (input.session_id) this.session_id = input.session_id;
    if (input.status) this.status = input.status;
    if (input.user_id) this.user_id = input.user_id;

  }

  static override generateModelId(input: Pick<ConsultingSessionAttendee, 'session_id' | 'user_id'>): string {
    
    return `${input.session_id}:${input.user_id}`;

  }

}

export interface ConsultingSessionAttendeeArrival {

  epoch: number;

}

export const ConsultingSessionAttendeeActionNameMap = {
  ADD: `${CONSULTING_SESSION_ATTENDEE}/add`,
  ARRIVE: `${CONSULTING_SESSION_ATTENDEE}/arrive`,
  CANCEL: `${CONSULTING_SESSION_ATTENDEE}/cancel`,
  CONFIRM: `${CONSULTING_SESSION_ATTENDEE}/confirm`,
  GET: `${CONSULTING_SESSION_ATTENDEE}/get`,
  LEAVE: `${CONSULTING_SESSION_ATTENDEE}/leave`,
  LIST: `${CONSULTING_SESSION_ATTENDEE}/list`,
  REMOVE: `${CONSULTING_SESSION_ATTENDEE}/remove`,
} as const;

export type ConsultingSessionAttendeeActionName = typeof ConsultingSessionAttendeeActionNameMap[keyof typeof ConsultingSessionAttendeeActionNameMap];

export const ConsultingSessionAttendeeActionNames = Object.values(ConsultingSessionAttendeeActionNameMap);

export class ConsultingSessionAttendeeAddAction extends ModelAction<ConsultingSessionAttendeeAddPayload> {

  readonly action_name = ConsultingSessionAttendeeActionNameMap.ADD;

  constructor(input: Partial<Omit<ConsultingSessionAttendeeAddAction, 'action_name'>>) {

    super(input);

  }

}

export type ConsultingSessionAttendeeAddPayload = Omit<ConsultingSessionAttendee, 
  | 'arrived_epoch' 
  | 'canceled_epoch' 
  | 'cancellation_reason' 
  | 'confirmed_epoch'
  | 'left_epoch'
  | 'last_updated_epoch'>;

export class ConsultingSessionAttendeeAddedEvent extends ModelEvent<ConsultingSessionAttendee> {

  readonly event_name = ConsultingSessionAttendeeEventNameMap.ADDED;

  constructor(input: Partial<Omit<ConsultingSessionAttendeeAddedEvent, 'event_name'>>) {

    super(input);

  }

}

export class ConsultingSessionAttendeeArriveAction extends ModelAction<ConsultingSessionAttendeeArrivePayload> {

  readonly action_name = ConsultingSessionAttendeeActionNameMap.ARRIVE;

  constructor(input: Partial<Omit<ConsultingSessionAttendeeArriveAction, 'action_name'>>) {

    super(input);

  }

}

export type ConsultingSessionAttendeeArrivePayload = Partial<Pick<ConsultingSessionAttendee, 
  | 'last_updated_epoch'
  | 'session_id'
  | 'user_id'>>
  & {
    arrival_epoch: number;
  };
  
export class ConsultingSessionAttendeeArrivedEvent extends ModelEvent<ConsultingSessionAttendee> {

  readonly event_name = ConsultingSessionAttendeeEventNameMap.ARRIVED;

  constructor(input: Partial<Omit<ConsultingSessionAttendeeArrivedEvent, 'event_name'>>) {

    super(input);

  }

}

export class ConsultingSessionAttendeeCancelAction extends ModelAction<ConsultingSessionAttendeeCancelPayload> {

  readonly action_name = ConsultingSessionAttendeeActionNameMap.CANCEL;

  constructor(input: Partial<Omit<ConsultingSessionAttendeeCancelAction, 'action_name'>>) {

    super(input);

  }

}

export type ConsultingSessionAttendeeCancelPayload = Pick<ConsultingSessionAttendee, 
  | 'cancellation'
  | 'last_updated_epoch'
  | 'session_id'
  | 'user_id'>;

export class ConsultingSessionAttendeeCanceledEvent extends ModelEvent<ConsultingSessionAttendee> {

  readonly event_name = ConsultingSessionAttendeeEventNameMap.CANCELED;

  constructor(input: Partial<Omit<ConsultingSessionAttendeeCanceledEvent, 'event_name'>>) {

    super(input);

  }

}

export type ConsultingSessionAttendeeCancellation = ConsultingSessionAttendeeStatusChange;

export class ConsultingSessionAttendeeConfirmAction extends ModelAction<ConsultingSessionAttendeeConfirmPayload> {

  readonly action_name = ConsultingSessionAttendeeActionNameMap.CONFIRM;

  constructor(input: Partial<Omit<ConsultingSessionAttendeeConfirmAction, 'action_name'>>) {

    super(input);

  }

}

export type ConsultingSessionAttendeeConfirmation = ConsultingSessionAttendeeStatusChange;

export type ConsultingSessionAttendeeConfirmPayload = Pick<ConsultingSessionAttendee, 
  | 'confirmation' 
  | 'last_updated_epoch'
  | 'session_id'
  | 'user_id'>;

export class ConsultingSessionAttendeeConfirmedEvent extends ModelEvent<ConsultingSessionAttendee> {

  readonly event_name = ConsultingSessionAttendeeEventNameMap.CONFIRMED;

  constructor(input: Partial<Omit<ConsultingSessionAttendeeConfirmedEvent, 'event_name'>>) {

    super(input);

  }

}

export const ConsultingSessionAttendeeEventNameMap = {
  ADDED: `${CONSULTING_SESSION_ATTENDEE}:added`,
  ARRIVED: `${CONSULTING_SESSION_ATTENDEE}:arrived`,
  CANCELED: `${CONSULTING_SESSION_ATTENDEE}:canceled`,
  CONFIRMED: `${CONSULTING_SESSION_ATTENDEE}:confirmed`,
  LEFT: `${CONSULTING_SESSION_ATTENDEE}:left`,
  LISTED: `${CONSULTING_SESSION_ATTENDEE}:listed`,
  REMOVED: `${CONSULTING_SESSION_ATTENDEE}:removed`,
  VIEWED: `${CONSULTING_SESSION_ATTENDEE}:viewed`,
} as const;

export class ConsultingSessionAttendeeGetAction extends ModelAction<ConsultingSessionAttendeeGetPayload> {

  readonly action_name = ConsultingSessionAttendeeActionNameMap.GET;

  constructor(input: Partial<Omit<ConsultingSessionAttendeeGetAction, 'action_name'>>) {

    super(input);

  }

}

export type ConsultingSessionAttendeeGetPayload = Pick<ConsultingSessionAttendee, 
  | 'session_id' 
  | 'user_id' >
  & {
    consistent_read?: ConsistentRead;
  };

export interface ConsultingSessionAttendeeInterface extends ModelInterface {

  actual?: ConsultingSessionAttendeeTiming;

  attending_as: AttendingAs;

  cancellation?: ConsultingSessionAttendeeCancellation;

  confirmation?: ConsultingSessionAttendeeConfirmation;

  last_updated_epoch: number;

  scheduled: ConsultingSessionAttendeeTiming;

  session_id: string;

  status: ConsultingSessionAttendeeStatus;

  user_id: string;

}

export class ConsultingSessionAttendeeLeftEvent extends ModelEvent<ConsultingSessionAttendee> {

  readonly event_name = ConsultingSessionAttendeeEventNameMap.LEFT;

  constructor(input: Partial<Omit<ConsultingSessionAttendeeLeftEvent, 'event_name'>>) {

    super(input);

  }

}

export class ConsultingSessionAttendeeListAction extends ModelAction<ConsultingSessionAttendeeListPayload> {

  readonly action_name = ConsultingSessionAttendeeActionNameMap.LIST;

  constructor(input: Partial<Omit<ConsultingSessionAttendeeListAction, 'action_name'>>) {

    super(input);

  }

}

export class ConsultingSessionAttendeeListedEvent extends ModelEvent<List<ConsultingSessionAttendee>> {

  readonly event_name = ConsultingSessionAttendeeEventNameMap.LISTED;

  constructor(input: Partial<Omit<ConsultingSessionAttendeeListedEvent, 'event_name'>>) {

    super(input);

  }

}

export type ConsultingSessionAttendeeListPayload = Partial<Pick<ConsultingSessionAttendee, 
  | 'session_id'
  | 'user_id'>>
  & {
    exclusive_start_key?: ExclusiveStartKey;
    limit?: Limit;
  };

  export class ConsultingSessionAttendeeLeaveAction extends ModelAction<ConsultingSessionAttendeeLeavePayload> {

    readonly action_name = ConsultingSessionAttendeeActionNameMap.LEAVE;
  
    constructor(input: Partial<Omit<ConsultingSessionAttendeeLeaveAction, 'action_name'>>) {
  
      super(input);
  
    }
  
  }
  
export type ConsultingSessionAttendeeLeavePayload = Pick<ConsultingSessionAttendee, 
  | 'last_updated_epoch'
  | 'session_id'
  | 'user_id'>
  & {
    departure_epoch: number;
  };

export class ConsultingSessionAttendeeModelService {

  add(action: ConsultingSessionAttendeeAddAction) {

    const model = new ConsultingSessionAttendee({
      ...action.payload,
      last_updated_epoch: action.epoch,
      model_id: ConsultingSessionAttendee.generateModelId(action.payload),
    });
  
    const event = new ConsultingSessionAttendeeAddedEvent({
      action_id: action.action_id,
      event_id: v4(),
      epoch: action.epoch,
      model_id: model.model_id,
      payload: model,
      user_id: action.user_id,
    });
  
    return Promise.resolve({
      action,
      event,
      model,
    });
  
  }

  arrive(action: ConsultingSessionAttendeeArriveAction, original: ConsultingSessionAttendee) {

    const model = new ConsultingSessionAttendee(merge({}, original, {
      ...action.payload,
      arrival: {
        epoch: action.payload.arrival_epoch,
      },
      last_updated_epoch: action.epoch,
      status: ConsultingSessionAttendeeStatusMap.ARRIVED,
    }));

    const event = new ConsultingSessionAttendeeArrivedEvent({
      action_id: action.action_id,
      event_id: v4(),
      epoch: action.epoch,
      model_id: model.model_id,
      payload: model,
      user_id: action.user_id,
    });

    return Promise.resolve({
      action,
      event,
      model,
    });

  }

  cancel(action: ConsultingSessionAttendeeCancelAction, original: ConsultingSessionAttendee) {

    const model = new ConsultingSessionAttendee(merge({}, original, {
      ...action.payload,
      last_updated_epoch: action.epoch,
      status: ConsultingSessionAttendeeStatusMap.CANCELED,
    }));

    const event = new ConsultingSessionAttendeeCanceledEvent({
      action_id: action.action_id,
      event_id: v4(),
      epoch: action.epoch,
      model_id: model.model_id,
      payload: model,
      user_id: action.user_id,
    });

    return Promise.resolve({
      action,
      event,
      model,
    });

  }

  confirm(action: ConsultingSessionAttendeeConfirmAction, original: ConsultingSessionAttendee) {

    const model = new ConsultingSessionAttendee(merge({}, original, {
      ...action.payload,
      last_updated_epoch: action.epoch,
      status: ConsultingSessionAttendeeStatusMap.CONFIRMED,
    }));

    const event = new ConsultingSessionAttendeeConfirmedEvent({
      action_id: action.action_id,
      event_id: v4(),
      epoch: action.epoch,
      model_id: model.model_id,
      payload: model,
      user_id: action.user_id,
    });

    return Promise.resolve({
      action,
      event,
      model,
    });

  }

  leave(action: ConsultingSessionAttendeeLeaveAction, original: ConsultingSessionAttendee) {

    const model = new ConsultingSessionAttendee(merge({}, original, {
      ...action.payload,
      last_updated_epoch: action.epoch,
      status: ConsultingSessionAttendeeStatusMap.LEFT,
    }));

    const event = new ConsultingSessionAttendeeLeftEvent({
      action_id: action.action_id,
      event_id: v4(),
      epoch: action.epoch,
      model_id: model.model_id,
      payload: model,
      user_id: action.user_id,
    });

    return Promise.resolve({
      action,
      event,
      model,
    });

  }

  list(action: ConsultingSessionAttendeeListAction, list: List<ConsultingSessionAttendee>) {

    return Bluebird.try(() => {
      
      const event = new ConsultingSessionAttendeeListedEvent({
        action_id: action.action_id,
        event_id: v4(),
        epoch: action.epoch,
        model_id: action.model_id,
        payload: list,
        user_id: action.user_id,
      });
  
      return { action, event, list };

    });

  }

  remove(action: ConsultingSessionAttendeeRemoveAction, original: ConsultingSessionAttendee) {

    const model = new ConsultingSessionAttendee(merge({}, original, {
      ...action.payload,
      last_updated_epoch: action.epoch,
      model_deleted: true,
    }));

    const event = new ConsultingSessionAttendeeRemovedEvent({
      action_id: action.action_id,
      event_id: v4(),
      epoch: action.epoch,
      model_id: model.model_id,
      payload: model,
      user_id: action.user_id,
    });

    return {
      action,
      event,
      model,
    };

  }

  view(action: ConsultingSessionAttendeeGetAction, model: ConsultingSessionAttendee) {

    return Bluebird.try(() => {

      const event = new ConsultingSessionAttendeeViewedEvent({
        action_id: action.action_id,
        event_id: v4(),
        epoch: action.epoch,
        model_id: action.model_id,
        payload: model,
        user_id: action.user_id,
      });
  
      return { action, event, model };

    });

  }

}

export class ConsultingSessionAttendeeRemoveAction extends ModelAction<ConsultingSessionAttendeeRemovePayload> {

  readonly action_name = ConsultingSessionAttendeeActionNameMap.REMOVE;

  constructor(input: Partial<Omit<ConsultingSessionAttendeeRemoveAction, 'action_name'>>) {

    super(input);

  }

}

export type ConsultingSessionAttendeeRemovePayload = Pick<ConsultingSessionAttendee, 
  | 'last_updated_epoch'
  | 'session_id'
  | 'user_id'>;

export class ConsultingSessionAttendeeRemovedEvent extends ModelEvent<ConsultingSessionAttendee> {

  readonly event_name = ConsultingSessionAttendeeEventNameMap.REMOVED;

  constructor(input: Partial<Omit<ConsultingSessionAttendeeRemovedEvent, 'event_name'>>) {

    super(input);

  }

}
  
export interface ConsultingSessionAttendeeStatusChange {

  action_id: string;

  epoch: number;

  reason: string;

  user_id: string;

}

export const ConsultingSessionAttendeeStatusMap = {
  ARRIVED,
  CANCELED,
  CONFIRMED,
  DRAFT,
  LEFT,
} as const;

export type ConsultingSessionAttendeeStatus = typeof ConsultingSessionAttendeeStatusMap[keyof typeof ConsultingSessionAttendeeStatusMap];

export const ConsultingSessionAttendeeStatuses = Object.values(ConsultingSessionAttendeeStatusMap);

export interface ConsultingSessionAttendeeTiming {

  arrival_epoch: number;

  departure_epoch?: number;

}

export class ConsultingSessionAttendeeViewedEvent extends ModelEvent<ConsultingSessionAttendee> {

  readonly event_name = ConsultingSessionAttendeeEventNameMap.VIEWED;

  constructor(input: Partial<Omit<ConsultingSessionAttendeeViewedEvent, 'event_name'>>) {

    super(input);

  }

}
