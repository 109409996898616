import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { SignInSignOutService } from './sign-in-sign-out.service';

@Injectable({
  providedIn: 'root'
})
export class AuthedUserGuard  {
  
  constructor(
    private router: Router,
    private service: AuthService,
    private signIn: SignInSignOutService,
  ) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    // console.log('AuthedUserGuard', 'calling waitForAuthed');

    return this.service.isAuthed()
    .then((isAuthed: boolean) => {

      // console.log('AuthedUserGuard', 'after authed', isAuthed);

      if (!isAuthed) {

        return this.signIn.signIn()
        .then(() => {

          return true;

        });

      }

      return true;

    })
    .catch((e: Error) => {

      console.error(e);

      return this.signIn.signIn()
      .then(() => {

        return true;
        
      });

    });
    
  }
}
