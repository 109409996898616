import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs';
import format from 'date-fns/format';
import { SubsComponent } from '../../../subs.component';

@Component({
  selector: 'ng-picker-date-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.sass'],
})
export class PickerComponent extends SubsComponent implements OnChanges, OnInit {

  // eslint-disable-next-line
  @Input('control') external: FormControl;

  internalDate: FormControl;

  internalTime: FormControl;

  @Input() labelDate = 'Select a Date';

  @Input() labelTime = 'Select a Time';

  @Input() maxTime?: string;

  @Input() minTime?: string;

  constructor(private fb: FormBuilder) {

    super();

    this.internalDate = this.fb.control(null);

    this.internalTime = this.fb.control(null);

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['control']) {

      this.onExternalControlChange();

    }

  }

  ngOnInit(): void {

    this.subs['internalDate'] = this.internalDate.valueChanges
    .pipe(distinctUntilChanged())
    .subscribe((value?: Date) => {

      let converted: number | undefined;

      if (value) {

        converted = value.valueOf();

      }

      this.external.patchValue(converted);

    });

    this.subs['internalTime'] = this.internalTime.valueChanges
    .pipe(distinctUntilChanged())
    .subscribe((value?: string) => {

      let converted: number | undefined;

      const date = format(this.internalDate.value, 'MM/dd/yyyy');

      if (value) {

        console.log(`${date} ${value}`);

        converted = Date.parse(`${date} ${value}`);

      }

      this.external.patchValue(converted);

    });

  }

  private onExternalControlChange() {

    if (this.subs['external']) {

      this.subs['external'].unsubscribe();

    }

    if (this.external) {

      this.subs['external'] = this.external.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value?: number) => {

        let converted: Date | undefined;

        if (value) {

          converted = new Date(value);

        }

        this.internalDate.patchValue(converted);

      });

    }

  }

}
