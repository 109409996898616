import { inject, Inject, Injectable } from '@angular/core';
import { CONFIRMED, LastEvaluatedKey, TRAINING_SEAT, TrainingSeat, TrainingSeatConfirmAction, TrainingSeatConfirmPayload, TrainingSeatGetAction, TrainingSeatGetPayload, TrainingSeatListAction, TrainingSeatListPayload, TrainingSeatReleaseAction, TrainingSeatReleasePayload, TrainingSeatRequestAction, TrainingSeatRequestPayload, TrainingSeatResendConfirmationAction as TrainingSeatSendConfirmationAction, TrainingSeatSendConfirmationPayload, TrainingSeatStatusMap, TrainingTicket } from '@bcx/models';
import { SeatServiceAdapter } from './seat.service.adapter';
import { BACKEND_ADAPTER, BackendAdapter, BackendAdapterMap, IdService } from '@bcx/ng-helpers';
import { StateFacade, StateService } from '../../state/state.service';
import { RestService } from './rest.service';
import { WebsocketService } from './websocket.service';
import { trainingSeat as schemas } from '@bcx/schemas';
import { isAfter } from 'date-fns';
import { List } from '@digitaltoolbuilders/model-helpers';
import { PartialDeep } from 'type-fest';

export function createStateId(input: Pick<TrainingSeat, 'seat_id'>) {

  return TrainingSeat.generateModelId(input);

}

export function iteratorFn(list: List<TrainingSeat>, fn: (model: TrainingSeat) => void) {

  list.list.forEach(fn);

}

@Injectable({
  providedIn: 'root'
})
export class SeatService {

  private adapter: SeatServiceAdapter;

  private state: StateFacade<TrainingSeat, LastEvaluatedKey>;

  constructor(
    @Inject(BACKEND_ADAPTER) backendAdapter: BackendAdapter,
    private id: IdService,
    state: StateService,
  ) { 

    switch (backendAdapter) {

      case BackendAdapterMap.REST:

        this.adapter = inject(RestService);

        break;

      case BackendAdapterMap.WEBSOCKET:

        this.adapter = inject(WebsocketService);

        break;

    }

    this.state = state.facade<TrainingSeat, LastEvaluatedKey>(TRAINING_SEAT, {
      createStateId,
    });

  }

  canConfirm(seat: TrainingSeat) {

    switch (seat.status) {

      case TrainingSeatStatusMap.REQUESTED:

        return true;

      default:

        return false;

    }

  }

  canRelease(seat: TrainingSeat) {

    switch (seat.status) {

      case TrainingSeatStatusMap.CONFIRMED:
      case TrainingSeatStatusMap.REQUESTED:

        return true;

      default: 

        return false;

    }

  }

  confirm(original: TrainingSeat, data: Partial<TrainingSeatConfirmPayload>, now = Date.now()) {

    data.last_updated_epoch = original.last_updated_epoch;
    data.seat_id = original.seat_id;

    return schemas.confirm.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingSeatConfirmAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.confirm(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  createId() {

    return this.id.numeric(12);

  }

  get(data: Partial<TrainingSeatGetPayload>, now = Date.now()) {

    return schemas.get.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingSeatGetAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        loading: true,
      });

      return this.adapter.get(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          loading: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          loading: false,
        });

        throw e;

      });

    });

  }

  isConfirmed(seat: TrainingSeat) {

    if (seat.status === CONFIRMED) {

      return true;

    } else if (seat.confirmation) {

      return true;

    }

    return false;

  }

  isUpcoming(seat: TrainingSeat) {

    if (seat.status === TrainingSeatStatusMap.RELEASED) {

      return false;

    }

    return isAfter(seat.class_start_epoch, Date.now());

  }

  list(data: TrainingSeatListPayload, now = Date.now()) {

    return schemas.list.validateAsync(data, {})
    .then((clean) => {

      const group = 'all';

      const action = new TrainingSeatListAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: group,
        payload: clean,
        user_id: '',
      });

      this.state.many({
        group,
        loading: true,
      });

      return this.adapter.list(action)
      .then((event) => {

        const list = event.payload;

        this.state.many({
          group,
          list: list.list,
          loading: false,
        });

        return list;

      })
      .catch((e) => {

        this.state.many({
          e,
          group,
          loading: false,
        });

        throw e;

      });

    });

  }

  release(original: TrainingSeat, data: PartialDeep<TrainingSeatReleasePayload>, now = Date.now()) {

    data.last_updated_epoch = original.last_updated_epoch;
    data.seat_id = original.seat_id;

    return schemas.release.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingSeatReleaseAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.release(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  request(ticket: TrainingTicket, data: TrainingSeatRequestPayload, now = Date.now()) {

    return schemas.request.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingSeatRequestAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.request(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  sendConfirmation(original: TrainingSeat, data: Partial<TrainingSeatSendConfirmationPayload>, now = Date.now()) {

    data.seat_id = original.seat_id;

    return schemas.sendConfirmation.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingSeatSendConfirmationAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.sendConfirmation(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

}
