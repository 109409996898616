
import { isBoolean } from "lodash";
import { CONFIRMED, RELEASED, REQUESTED, TRAINING_SEAT } from "../common";
import { ModelAbstract, ModelAction, ModelEvent, ModelInterface } from "../model-action";
import { ConsistentRead, ExclusiveStartKey, Limit } from "../helpers";
import { List } from "@digitaltoolbuilders/model-helpers";

export class TrainingSeat extends ModelAbstract implements TrainingSeatInterface {

  cancellation?: TrainingSeatCancellation;

  class_id: string;

  confirmation?: TrainingSeatConfirmation;

  release?: TrainingSeatRelease;

  seat_id: string;

  schema_version = '1.0';

  class_start_epoch: number;

  status: TrainingSeatStatus;

  status_epoch: number;

  ticket_id: string;

  user_id: string;

  constructor(input: Partial<TrainingSeat>) {

    super(input);

    if (input.cancellation) this.cancellation = input.cancellation;
    if (input.class_id) this.class_id = input.class_id;
    if (input.release) this.release = input.release;
    if (input.class_id) this.class_id = input.class_id;
    if (input.schema_version) this.schema_version = input.schema_version;
    if (input.class_start_epoch) this.class_start_epoch = input.class_start_epoch;
    if (input.status) this.status = input.status;
    if (input.status_epoch) this.status_epoch = input.status_epoch;
    if (input.ticket_id) this.ticket_id = input.ticket_id;
    if (input.user_id) this.user_id = input.user_id;

  }

  static override generateModelId(input: Pick<TrainingSeat, 'seat_id'>) {

    return `${input.seat_id}`;

  }

}

export const TrainingSeatActionNameMap = {
  CONFIRM: `${TRAINING_SEAT}/confirm`,
  GET: `${TRAINING_SEAT}/get`,
  LIST: `${TRAINING_SEAT}/list`,
  RELEASE: `${TRAINING_SEAT}/release`,
  REQUEST: `${TRAINING_SEAT}/request`,
  RESEND_CONFIRMATION: `${TRAINING_SEAT}/resend-confirmation`,
};

export type TrainingSeatActionName = typeof TrainingSeatActionNameMap[keyof typeof TrainingSeatActionNameMap];

export const TrainingSeatActionNames = Object.values(TrainingSeatActionNameMap);

export interface TrainingSeatCancellation {

  action_id: string;

  epoch: number;

  reason: string;

  user_id: string;

}

export class TrainingSeatConfirmAction extends ModelAction<TrainingSeatConfirmPayload> {

  readonly action_name = TrainingSeatActionNameMap.CONFIRM;

  constructor(input: Partial<Omit<TrainingSeatConfirmAction, 'action_name'>>) {

    super(input);

  }

}

export interface TrainingSeatConfirmation {

  action_id: string;

  epoch: number;

  reason: string;

  user_id: string;

}

export class TrainingSeatConfirmedEvent extends ModelEvent<TrainingSeat> {

  readonly event_name = TrainingSeatEventNameMap.CONFIRMED;

  constructor(input: Partial<Omit<TrainingSeatConfirmedEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingSeatConfirmPayload = Pick<TrainingSeat, 
  | 'last_updated_epoch'
  | 'seat_id'>
  & {
    confirmed_epoch: number;
    reason?: string;
  };

export const TrainingSeatEventNameMap = {
  CONFIRMATION_SENT: `${TRAINING_SEAT}:confirmation-sent`,
  CONFIRMED: `${TRAINING_SEAT}:confirmed`,
  LISTED: `${TRAINING_SEAT}:listed`,
  RELEASED: `${TRAINING_SEAT}:released`,
  REQUESTED: `${TRAINING_SEAT}:requested`,
  VIEWED: `${TRAINING_SEAT}:viewed`,
} as const;

export type TrainingSeatEventName = typeof TrainingSeatEventNameMap[keyof typeof TrainingSeatEventNameMap];

export const TrainingSeatEventNames = Object.values(TrainingSeatEventNameMap);

export class TrainingSeatGetAction extends ModelAction<TrainingSeatGetPayload> {

  readonly action_name = TrainingSeatActionNameMap.GET;

  constructor(input: Partial<Omit<TrainingSeatGetAction, 'action_name'>>) {

    super(input);

  }

}

export type TrainingSeatGetPayload = Pick<TrainingSeat, 'seat_id'>
  & {
    consistent_read?: ConsistentRead;
  };

export class TrainingSeatListAction extends ModelAction<TrainingSeatListPayload> {

  readonly action_name = TrainingSeatActionNameMap.LIST;

  constructor(input: Partial<Omit<TrainingSeatListAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingSeatListedEvent extends ModelEvent<List<TrainingSeat>> {

  readonly event_name = TrainingSeatEventNameMap.LISTED;

  constructor(input: Partial<Omit<TrainingSeatListedEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingSeatListPayload = Partial<Pick<TrainingSeat, 
  | 'class_id'
  | 'status'
  | 'ticket_id'
  | 'user_id'>>
  & {
    exclusive_start_key?: ExclusiveStartKey;
    limit?: Limit;
  };

export interface TrainingSeatRelease {

  action_id: string;

  epoch: number;

  reason: string;

  user_id: string;

}
  
export class TrainingSeatReleaseAction extends ModelAction<TrainingSeatReleasePayload> {

  readonly action_name = TrainingSeatActionNameMap.RELEASE;

  constructor(input: Partial<Omit<TrainingSeatReleaseAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingSeatReleasedEvent extends ModelEvent<TrainingSeat> {

  readonly event_name = TrainingSeatEventNameMap.RELEASED;

  constructor(input: Partial<Omit<TrainingSeatReleasedEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingSeatReleasePayload = Pick<TrainingSeat, 
  | 'last_updated_epoch'
  | 'seat_id'> 
  & {
    reason: string;
    released_epoch: number;
  };

export class TrainingSeatRequestAction extends ModelAction<TrainingSeatRequestPayload> {

  readonly action_name = TrainingSeatActionNameMap.REQUEST;

  constructor(input: Partial<Omit<TrainingSeatRequestAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingSeatRequestedEvent extends ModelEvent<TrainingSeat> {

  readonly event_name = TrainingSeatEventNameMap.REQUESTED;

  constructor(input: Partial<Omit<TrainingSeatRequestedEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingSeatRequestPayload = Pick<TrainingSeat, 
  | 'class_id'
  | 'seat_id'
  | 'ticket_id'
  | 'user_id'>;
  
export interface TrainingSeatInterface extends ModelInterface {

  cancellation?: TrainingSeatCancellation;

  class_id: string;

  class_start_epoch: number;

  confirmation?: TrainingSeatConfirmation;

  release?: TrainingSeatRelease;

  seat_id: string;

  status: TrainingSeatStatus;

  status_epoch: number;

  ticket_id: string;

  user_id: string;

}

export class TrainingSeatResendConfirmationAction extends ModelAction<TrainingSeatSendConfirmationPayload> {

  readonly action_name = TrainingSeatActionNameMap.RESEND_CONFIRMATION;

  constructor(input: Partial<Omit<TrainingSeatResendConfirmationAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingSeatConfirmationSentEvent extends ModelEvent<TrainingSeat> {

  readonly event_name = TrainingSeatEventNameMap.CONFIRMATION_SENT;

  constructor(input: Partial<Omit<TrainingSeatConfirmationSentEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingSeatSendConfirmationPayload = Pick<TrainingSeat, 'seat_id'>;

export const TrainingSeatStatusMap = {
  CONFIRMED: CONFIRMED,
  RELEASED: RELEASED,
  REQUESTED: REQUESTED,
} as const;

export type TrainingSeatStatus = typeof TrainingSeatStatusMap[keyof typeof TrainingSeatStatusMap];

export const TrainingSeatStatuses = Object.values(TrainingSeatStatusMap);

export class TrainingSeatViewedEvent extends ModelEvent<TrainingSeat> {

  readonly event_name = TrainingSeatEventNameMap.VIEWED;

  constructor(input: Partial<Omit<TrainingSeatViewedEvent, 'event_name'>>) {

    super(input);

  }

}
