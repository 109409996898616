<div class="ng-stripe-charge-list-table">

  <table mat-table [dataSource]="table">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let charge">
        <ng-container *ngTemplateOutlet="actions; context: { charge }"></ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Amount</th>
      <td mat-cell *matCellDef="let charge">{{charge.amount | stripeCurrency:charge.currency || 'usd'}}</td>
    </ng-container>
    <ng-container matColumnDef="currency">
      <th mat-header-cell *matHeaderCellDef>Currency</th>
      <td mat-cell *matCellDef="let charge">{{charge.currency}}</td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let charge">{{charge.description}}</td>
    </ng-container>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let charge">{{charge.id}}</td>
    </ng-container>
    <ng-container matColumnDef="metadata">
      <th mat-header-cell *matHeaderCellDef>MetaData</th>
      <td mat-cell *matCellDef="let charge">{{charge.metadata | json}}</td>
    </ng-container>
    <ng-container matColumnDef="payment_method">
      <th mat-header-cell *matHeaderCellDef>Payment Method</th>
      <td mat-cell *matCellDef="let charge">{{charge.payment_method_details}}</td>
    </ng-container>
    <ng-container matColumnDef="refunded">
      <th mat-header-cell *matHeaderCellDef>Refunded</th>
      <td mat-cell *matCellDef="let charge">
        <span>{{charge.refunded}}</span>
        <span *ngIf="charge.refunded">{{charge.amount_refunded | stripeCurrency:charge.currency || 'usd'}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let charge">{{charge.status}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="display"></tr>
    <tr mat-row *matRowDef="let charge; columns: display"></tr>

    <tr mat-row *matNoDataRow><td [attr.colspan]="display.length">No Charges</td></tr>
  </table>

</div>
