
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Logger } from 'pino';
import { BehaviorSubject, Subject, distinctUntilChanged, filter, firstValueFrom, map, timeout } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { stringify } from 'qs';
import { minutesToMilliseconds } from 'date-fns';
import { NotAllowed } from '@digitaltoolbuilders/acl';
import { ValidationError } from 'joi';
import { DEFAULT_WEBSOCKET_NAME } from './common';
import { AnyPayload, WebSocketClientMessage, WebSocketEventMap, WebSocketServerMessage } from '@bcx/models';
import { DEFAULT_LOGGER } from '../logger';
import { ConditionalCheckFailedException, WSProviderConfig } from '@bcx/iso';
import { ComponentSubscriptions, ConfigService, DEFAULT_REGION, unsubscribeAll } from '@bcx/ng-helpers';
import { signRequest } from '@aws-amplify/core/internals/aws-client-utils';

export class WebSocketRouteNotFound extends Error {

  constructor(message: WebSocketClientMessage | WebSocketServerMessage) {

    super(`WebSocket Route Not Found: ${message.payload.action}`);

    this.name = 'WebSocketRouteNotFound';

    Object.setPrototypeOf(this, new.target.prototype);

  }

}

@Injectable({
  providedIn: 'root'
})
export class WebSocketService implements OnDestroy {

  private activeConfig: WSProviderConfig;

  private connected = new BehaviorSubject<boolean>(false);

  connected$ = this.connected.asObservable()
    .pipe(distinctUntilChanged());

  private connecting = new BehaviorSubject<boolean>(false);

  connecting$ = this.connecting.asObservable()
    .pipe(distinctUntilChanged());

  private connectPromise?: Promise<boolean>;

  private defaultService = 'execute-api';

  private received = new Subject<WebSocketServerMessage>();

  received$ = this.received.asObservable();

  private socket: WebSocket;

  private subs: ComponentSubscriptions = {};

  constructor(
    private auth: AuthService,
    private config: ConfigService,
    @Inject(DEFAULT_WEBSOCKET_NAME) private defaultName: string,
    @Inject(DEFAULT_REGION) private defaultRegion: string,
    @Inject(DEFAULT_LOGGER) private logger: Logger,
  ) { 

    this.subs['config'] = this.config.active$.subscribe(({ amplify }) => {

      if (amplify && amplify.API && amplify.API.WS) {

        this.onConfig(amplify.API.WS);

      } else {

        this.logger.info('no config available');

      }

    });

  }

  private connect() {

    if (!this.connectPromise) {

      this.connectPromise = this.auth.waitForCredentials()
      .then((creds) => {

        return this.auth.waitForIdentityToken()
        .then((token) => {

          const config = this.getEndpointConfig();

          this.logger.debug({ config }, 'connect');
      
          const identityToken = token.toString();
    
          const signed = signRequest({
            headers: {},
            method: 'GET',
            url: new URL(`${config.endpoint}?${stringify({ identityToken })}`),
          }, {
            credentials: creds,
            signingService: config.service || this.defaultService,
            signingRegion: config.region || this.defaultRegion,
          });
      
          return new Promise<boolean>((resolve, reject) => {

            this.socket = new WebSocket(signed.url);
        
            this.socket.addEventListener('close', () => {
        
              this.onClose();
        
            });
        
            this.socket.addEventListener('error', (ev) => {
        
              this.onError(ev);

              reject(false);
        
            });
        
            this.socket.addEventListener('message', (ev) => {
        
              this.onMessage(ev);
        
            });
        
            this.socket.addEventListener('open', () => {
        
              this.onOpen();

              resolve(true);
        
            });

          });
          
        });

      })
      .catch((e: Error) => {

        this.logger.error(e);

        this.connectPromise = undefined;

        return false;

      });

    }

    return this.connectPromise;

  }

  getEndpointConfig(name: string = this.defaultName) {

    const config = this.activeConfig[name] || {};

    return config;

  }

  init() {

    return Promise.resolve(true);

  }

  isConnected() {

    return this.connected.getValue();

  }

  ngOnDestroy(): void {
    
    this.connected.complete();
    this.connecting.complete();
    this.received.complete();

    unsubscribeAll(this.subs);

  }

  private onClose() {

    this.connectPromise = undefined;
    this.connected.next(false);

  }

  private onConfig(config: WSProviderConfig) {

    this.activeConfig = config;

  }

  private onError(ev: Event) {

    this.logger.debug({ ev });

  }

  only(...eventNames: Array<string>) {

    return this.received$
    .pipe(filter(({ event_name: eventName }) => {

      return eventNames.includes(eventName);

    }));

  }

  private onMessage(ev: MessageEvent) {

    const message = JSON.parse(ev.data) as WebSocketServerMessage;

    this.logger.debug({ event_name: message.event_name, payload: message.payload }, 'onMessage');
  
    this.received.next(message);

  }

  private onOpen() {

    this.connected.next(true);

  }

  private processMessage<Payload = AnyPayload>(message: WebSocketServerMessage<Payload>) {

    const payload: AnyPayload = message.payload;

    switch (message.event_name) {

      case WebSocketEventMap.CONDITIONAL:

        throw new ConditionalCheckFailedException();

      case WebSocketEventMap.ERROR:

        throw new Error(payload.message);

      case WebSocketEventMap.NOT_ALLOWED:

        throw new NotAllowed(payload.message);

      case WebSocketEventMap.NOT_VALID:

        throw new ValidationError(payload.message, payload.details, payload.original);

      case WebSocketEventMap.ROUTE_NOT_FOUND:

        throw new WebSocketRouteNotFound(message);

      default:

        return message;

    }

  }

  request<Server = AnyPayload, Client = AnyPayload>(message: WebSocketClientMessage<Client>) {

    return this.waitForConnected()
    .then(() => {

      const sent = this.sendMessage<Client>(message);

      return firstValueFrom(
        this.received$.pipe(filter((received) => {
  
          return received.action_id === sent.action_id;
  
        }))
        .pipe(map((message) => {
  
          return this.processMessage<Server>(message);
  
        }))
        .pipe(timeout({ 
          first: minutesToMilliseconds(3) 
        }))
      );
  
    });

  }

  sendMessage<Payload>(message: WebSocketClientMessage<Payload>) {

    this.logger.debug({
      message
    }, 'sendMessage');

    this.socket.send(JSON.stringify(message));

    return message;

  }

  waitForConnected() {

    this.connect();

    return firstValueFrom(this.connected$.pipe(filter((connected) => {

      return connected === true;

    })));

  }

}