import { StripePriceActionNameMap, StripePriceGetAction, StripePriceGetPayload, StripePriceListAction, StripePriceListPayload } from "@bcx/models";
import Joi from "joi";
import { subscription_id } from "./common";
import { createActionSchema } from "../model-action";

export const get = Joi.object<StripePriceGetPayload>()
  .keys({
    id: subscription_id.required(),
  });

export const getAction = createActionSchema<StripePriceGetAction>(StripePriceActionNameMap.GET, get);

export const list = Joi.object<StripePriceListPayload>()
  .keys({
    
  });

export const listAction = createActionSchema<StripePriceListAction>(StripePriceActionNameMap.LIST, list);
