import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
// import { Stripe } from 'stripe';

@Component({
  selector: 'ng-stripe-payment-method-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.sass'],
})
export class LabelComponent implements OnChanges {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() paymentMethod: any;

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['paymentMethod']) {

      // console.log('paymentMethod', this.paymentMethod);

    }

  }

}
