import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IntlPhoneNumberControl } from "@bcx/ng-helpers";

export class SignUpForm extends FormGroup {

  constructor() {

    super({
      zoneinfo: new FormControl<string>(Intl.DateTimeFormat().resolvedOptions().timeZone, [Validators.required]),
      name: new FormControl<string | undefined>(undefined, [Validators.required]),
      phone_number: new IntlPhoneNumberControl(),
    });

  }

}
