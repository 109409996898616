<div fxLayout="column"
  *ngIf="form"
  [formGroup]="form">

  <!-- <ng-user-select-user
    [control]="userControl"
    [ignoreUsers]="ignoreUsers"
    [returnUser]="true"></ng-user-select-user> -->

  <mat-form-field>

    <mat-select placeholder="Attending As"
      formControlName="attending_as"
      matInput>

      <mat-option 
        *ngFor="let option of attendingAsOptions"
        [value]="option">{{option}}</mat-option>

    </mat-select>

    <mat-error>Please select their attending as option.</mat-error>

  </mat-form-field>

</div>
