import {Stripe} from "stripe";
import { ModelAction, ModelEvent } from "../model-action";
import { STRIPE_PRODUCT } from "../common";

export const StripeProductActionNameMap = {
  CREATE: `${STRIPE_PRODUCT}/create`,
  GET: `${STRIPE_PRODUCT}/get`,
  LIST: `${STRIPE_PRODUCT}/list`,
  UPDATE: `${STRIPE_PRODUCT}/update`,
} as const;

export type StripeProductActionName = typeof StripeProductActionNameMap[keyof typeof StripeProductActionNameMap];

export const StripeProductActionNames = Object.values(StripeProductActionNameMap);

export class StripeProductCreateAction extends ModelAction<StripeProductCreatePayload> {

  readonly action_name = StripeProductActionNameMap.CREATE;

  constructor(input: Partial<Omit<StripeProductCreateAction, 'action_name'>>){

    super(input);

  }

}

export class StripeProductCreatedEvent extends ModelEvent<Stripe.Product> {

  readonly event_name = StripeProductEventNameMap.CREATED;

  constructor(input: Partial<Omit<StripeProductCreatedEvent, 'event_name'>>) {

    super(input);

  }

}

export type StripeProductCreatePayload = Stripe.ProductCreateParams;

export const StripeProductEventNameMap = {
  CREATED: `${STRIPE_PRODUCT}:created`,
  LISTED: `${STRIPE_PRODUCT}:listed`,
  UPDATED: `${STRIPE_PRODUCT}:updated`,
  VIEWED: `${STRIPE_PRODUCT}:viewed`,
} as const;

export type StripeProductEventName = typeof StripeProductEventNameMap[keyof typeof StripeProductEventNameMap];

export const StripeProductEventNames = Object.values(StripeProductEventNameMap);

export class StripeProductGetAction extends ModelAction<StripeProductGetPayload> {

  readonly action_name = StripeProductActionNameMap.GET;

  constructor(input: Partial<Omit<StripeProductGetAction, 'action_name'>>){

    super(input);

  }

}

export type StripeProductGetPayload = Stripe.ProductRetrieveParams & {
  id: string;
};

export class StripeProductListAction extends ModelAction<StripeProductListPayload> {

  readonly action_name = StripeProductActionNameMap.LIST;

  constructor(input: Partial<Omit<StripeProductListAction, 'action_name'>>){

    super(input);

  }

}

export type StripeProductListPayload = Stripe.ProductListParams;

export class StripeProductListedEvent extends ModelEvent<Stripe.ApiList<Stripe.Product>> {

  readonly event_name = StripeProductEventNameMap.LISTED;

  constructor(input: Partial<Omit<StripeProductListedEvent, 'event_name'>>) {

    super(input);

  }

}

export class StripeProductViewedEvent extends ModelEvent<Stripe.Product> {

  readonly event_name = StripeProductEventNameMap.VIEWED;

  constructor(input: Partial<Omit<StripeProductViewedEvent, 'event_name'>>) {

    super(input);

  }

}
