import { Component, Input } from '@angular/core';
import { ConsultingSession } from '@bcx/models';

@Component({
  selector: 'ng-consulting-session-full-card',
  templateUrl: './full-card.component.html',
  styleUrls: ['./full-card.component.sass'],
})
export class FullCardComponent {

  @Input() session: ConsultingSession;

}
