import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleSwitchComponent } from './role-switch/role-switch.component';

@NgModule({
  declarations: [RoleSwitchComponent],
  imports: [CommonModule],
  exports: [RoleSwitchComponent],
})
export class SharedModule {}
