<span fxLayout="row" fxLayoutGap="5px"
  *ngIf="price">

  <span>Selected Rate:</span>

  <span>{{price.unit_amount | stripeCurrency:price.currency}}</span>

  <span>&lpar;15 Minutes&rpar;</span>

</span>
