import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { StoreModule } from '@ngrx/store';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatNativeDateModule } from '@angular/material/core';
import { EffectsModule } from '@ngrx/effects';
import { HomepageComponent } from './homepage/homepage.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDividerModule } from '@angular/material/divider';
import { environment } from '../environments/environment';
import { FooterComponent } from './footer/footer.component';
import { BannerComponent } from './banner/banner.component';
import { HeaderComponent } from './header/header.component';
import { DEFAULT_API_NAME, NgAmplifyModule } from '@engagedcx/ng-amplify';
import { SignUpComponent } from './sign-up/sign-up.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AppConfigInitializer, PipesModule } from '@bcx/ng-helpers';
import { SharedModule } from './shared/shared.module';
import { GetStartedModule } from './get-started/get-started.module';
import { ProviderModule } from './provider/provider.module';
import { OwnerModule } from './owner/owner.module';
import { CoachModule } from './coach/coach.module';
import { SmallToBestModule } from './small-to-best/small-to-best.module';
import { GoalModule } from './goal/goal.module';
import { AdsModule } from './ads/ads.module';
import { NgPickerModule, PINO_LOG_LEVEL } from '@bcx/ng-helpers';
import { MarketingModule } from './marketing/marketing.module';

@NgModule({
  declarations: [
    AppComponent,
    BannerComponent,
    FooterComponent,
    HeaderComponent,
    HomepageComponent,
    SignUpComponent,
  ],
  imports: [
    AdsModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoachModule,
    EffectsModule.forRoot(),
    FormsModule,
    GetStartedModule,
    GoalModule,
    MarketingModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTooltipModule,
    NgAmplifyModule,
    NgPickerModule,
    PipesModule,
    OwnerModule,
    ProviderModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, {
      enableTracing: false,
      initialNavigation: 'enabledNonBlocking',
      scrollPositionRestoration: 'enabled',
    }),
    SharedModule,
    SmallToBestModule,
    StoreModule.forRoot({}),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    AppConfigInitializer,
    {
      provide: DEFAULT_API_NAME,
      useValue: 'marketing-tool',
    },
    {
      provide: PINO_LOG_LEVEL,
      useValue: 'debug',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
