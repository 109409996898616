import { inject, Inject, Injectable } from '@angular/core';
import { LastEvaluatedKey, PERSON_ATTRIBUTE, PersonAttribute, PersonAttributeGetPayload, PersonAttributeListPayload, PersonAttributeSavePayload } from '@bcx/models';
import { BACKEND_ADAPTER, BackendAdapter, BackendAdapterMap } from '@bcx/ng-helpers';
import { RestService } from './rest/rest.service';
import { WebsocketService } from './websocket/websocket.service';
import { personAttribute as schemas } from '@bcx/schemas';
import { FinderFn, StateFacade, StateService } from '../../state/state.service';
import { List } from '@digitaltoolbuilders/model-helpers';

export interface PersonAttributeServiceAdapter {

  get(clean: PersonAttributeGetPayload): Promise<PersonAttribute>;

  list(clean: PersonAttributeListPayload): Promise<List<PersonAttribute>>;

  save(clean: PersonAttributeSavePayload): Promise<PersonAttribute>;

}

export type PersonAttributeIdData = Pick<PersonAttribute, 'account_id' | 'person_id' | 'attr_key'>;

export function createStateId(attribute: PersonAttributeIdData) {

  return `${attribute.account_id}:${attribute.person_id}:${attribute.attr_key}`;

}

@Injectable({
  providedIn: 'root'
})
export class AttributeService {

  private adapter: PersonAttributeServiceAdapter;

  private state: StateFacade<PersonAttribute, LastEvaluatedKey>;

  constructor(
    @Inject(BACKEND_ADAPTER) backend_adapter: BackendAdapter,
    state: StateService,
  ) { 

    switch (backend_adapter) {

      case BackendAdapterMap.REST:

        this.adapter = inject(RestService);

        break;

      case BackendAdapterMap.WEBSOCKET:

        this.adapter = inject(WebsocketService);

        break;

      default:

        throw new Error(`unknown adapter: ${backend_adapter}`);

    }

    this.state = state.facade<PersonAttribute, LastEvaluatedKey>(PERSON_ATTRIBUTE, {
      createStateId,
    });

  }

  get(data: PersonAttributeGetPayload) {

    return schemas.get.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      this.state.single({
        id,
        loading: true,
      });

      return this.adapter.get(clean)
      .then((attribute) => {

        this.state.single({
          id,
          loading: false,
          model: attribute,
        });

        return attribute;

      })
      .catch((e) => {

        this.state.single({ e, id, });

        throw e;

      });

    });

  }

  list(data: PersonAttributeListPayload) {

    return schemas.list.validateAsync(data, {})
    .then((clean) => {

      const group = 'all';

      this.state.many({
        group,
        loading: true,
      });

      return this.adapter.list(clean)
      .then((list) => {

        this.state.many({
          group,
          list: list.list,
          loading: false, 
        });

        return list;

      })
      .catch((e) => {

        this.state.many({ 
          e,
          group,
          loading: false,
       });

        throw e;

      });

    });

  }

  save(data: PersonAttributeSavePayload) {

    return schemas.save.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      this.state.single({
        processing: true,
        id,
      });

      return this.adapter.save(clean)
      .then((updated) => {

        this.state.single({
          id,
          model: updated,
          processing: false, 
        });

        return updated;

      })
      .catch((e) => {

        this.state.single({ 
          e,
          id,
          processing: false, 
        });

        throw e;

      });

    });

  }

  select(data: PersonAttributeIdData) {

    return this.state.select(createStateId(data));

  }

  selectAllForPerson(data: Omit<PersonAttributeIdData, 'attr_key'>) {

    const group = createStateId({
      ...data,
      attr_key: '',
    });

    const finder: FinderFn<PersonAttribute> = (entity) => {

      return entity.model?.account_id === data.account_id
        && entity.model?.person_id === data.person_id;

    };

    return this.state.selectGroup(group, finder);

  }

}
