import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DebtorForm } from './debtor.form';
import { IntlPhoneNumberControl } from '@bcx/ng-helpers';
import { AddressForm } from '../../../person/contact-point/mail-address/form/form';

@Component({
  selector: 'ng-training-payment-agreement-debtor-form',
  templateUrl: './debtor-form.component.html',
  styleUrls: ['./debtor-form.component.sass'],
})
export class DebtorFormComponent implements OnChanges {

  addressForm: AddressForm;

  @Input() form: DebtorForm;

  phoneNumberControl: IntlPhoneNumberControl;

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['form'] && this.form) {

      this.addressForm = this.form.get('address') as AddressForm;
      this.phoneNumberControl = this.form.get('phone_number') as IntlPhoneNumberControl;

    }

  }

}
