import { Component, OnDestroy } from '@angular/core';
import { InitialQuestions, InitialQuestionsForm } from './initial-questions.form';
import { Observable, Subscription, throttleTime } from 'rxjs';
import { GetStartedService } from '../get-started.service';
import { COACH, OWNER, PROVIDER } from '@bcx/models';
import { GetStartedState } from '../get-started.state';

export type ViewModel = GetStartedState;

@Component({
  selector: 'app-get-started-initial-questions',
  templateUrl: './initial-questions.component.html',
  styleUrl: './initial-questions.component.sass',
})
export class InitialQuestionsComponent implements OnDestroy {

  COACH = COACH;

  form = new InitialQuestionsForm();

  OWNER = OWNER;

  PROVIDER = PROVIDER;

  private sub: Subscription;

  vm$: Observable<ViewModel>;

  constructor(
    private service: GetStartedService,
  ) {

    this.sub = this.form.valueChanges
    .pipe(throttleTime(250))
    .subscribe((value: InitialQuestions) => {

      this.determineRoleAndSave(value);

    });

    const state = this.service.getCurrentState();

    this.form.patchValue(state);

    this.vm$ = this.service.state$;

  }

  private determineRoleAndSave(input: InitialQuestions) {

    const role = this.service.determineRole(input);

    this.service.save({
      ...input,
      role,
    });

  }

  hasAnswer(input?: boolean) {

    return typeof input === 'boolean';

  }

  ngOnDestroy(): void {
    
    this.sub.unsubscribe();

  }

}
