<mat-card>

  <mat-card-header>

    <mat-card-title>Best CX Ever For Coaches</mat-card-title>
    <mat-card-title>How to provide the best experiences every time.</mat-card-title>

  </mat-card-header>

  <mat-card-content>

    

  </mat-card-content>

  <mat-card-actions>

    <ng-content></ng-content>

  </mat-card-actions>

</mat-card>
