import { CoachGoalMap, OwnerGoalMap, ProviderGoalMap, SmallToBestGoalMap, UNDETERMINED } from "@bcx/models";
import { CoachQuestionsMap } from "../coach/questions/questions.form";
import { OwnerQuestionsMap } from "../owner/questions/questions.form";
import { ProviderQuestionsMap } from "../provider/questions/questions.form";
import { SmallToBestQuestionsMap } from "../small-to-best/questions/questions.form";

export type DeepPartial<T> = T extends object ? {
  [P in keyof T]?: DeepPartial<T[P]>;
} : T;

export interface GetStartedState {

  can_change_policy?: boolean;

  coach: {
    goals: CoachGoalMap;
    questions: CoachQuestionsMap;
  };

  has_small_business?: boolean;

  industry: string;

  interacts_with_customers?: boolean;

  is_business_owner?: boolean;

  is_provider?: boolean;

  is_team_leader?: boolean;

  owner: {
    goals: OwnerGoalMap;
    questions: OwnerQuestionsMap;
  };

  provider: {
    goals: ProviderGoalMap;
    questions: ProviderQuestionsMap;
  };

  role: string;

  small_to_best: {
    goals: SmallToBestGoalMap;
    questions: SmallToBestQuestionsMap;
  };

}

export const DEFAULT_STATE: GetStartedState = {
  coach: {
    goals: {
      "decrease-cost-per-minute": undefined,
      "decrease-repeat-interactions": undefined,
      "decrease-turn-over-rate": undefined,
      "increase-average-purchase": undefined,
      "increase-compliance-quality-scores": undefined,
      "increase-conversion-rate": undefined,
      "increase-csat": undefined,
      "increase-customer-base": undefined,
      "increase-job-satisfaction": undefined,
      "increase-roi": undefined,
      "more-premiums-upgrades": undefined,
      "more-referrals": undefined,
    },
    questions: {},
  },
  has_small_business: undefined,
  industry: UNDETERMINED,
  is_business_owner: undefined,
  is_provider: undefined,
  is_team_leader: undefined,
  owner: {
    goals: {
      "best-experiences-every-time": undefined,
      "decrease-turn-over-rate": undefined,
      "increase-average-purchase": undefined,
      "increase-compliance-quality-scores": undefined,
      "increase-conversion-rate": undefined,
      "increase-csat": undefined,
      "increase-customer-base": undefined,
      "increase-job-satisfaction": undefined,
      "increase-roi": undefined,
      "more-premiums-upgrades": undefined,
      "more-referrals": undefined,
    },
    questions: {},
  },
  provider: {
    goals: {
      "achieve-promotions": undefined,
      "best-experiences-every-time": undefined,
      "increase-average-purchase": undefined,
      "increase-bonus-commission": undefined,
      "increase-compliance-quality-scores": undefined,
      "increase-conversion-rate": undefined,
      "increase-csat": undefined,
      "increase-job-satisfaction": undefined,
      "increase-pay-rate": undefined,
      "more-premiums-upgrades": undefined,
      "more-referrals": undefined,
      "release-negativity": undefined,
    },
    questions: {},
  },
  role: UNDETERMINED,
  small_to_best: {
    goals: {
      "best-experiences-every-time": undefined,
      "increase-average-purchase": undefined,
      "increase-conversion-rate": undefined,
      "increase-csat": undefined,
      "increase-customer-base": undefined,
      "increase-roi": undefined,
      "more-premiums-upgrades": undefined,
      "more-referrals": undefined,
      "release-negativity": undefined,
    },
    questions: {},
  },
};
