<div class="flex flex-col"
  [formGroup]="form">

  <mat-form-field>

    <mat-label>What industry is your small business in?</mat-label>

    <mat-select
      formControlName="industry"
      matInput>

      <mat-option 
        *ngFor="let industry of industries"
        [value]="industry">{{industry}}</mat-option>

    </mat-select>

  </mat-form-field>

  <mat-form-field>

    <mat-label>How many years of experience do you have in this industry?</mat-label>

    <mat-select
      formControlName="length_in_industry"
      matInput>

      <mat-option 
        *ngFor="let length of lengths"
        [value]="length">{{length}}</mat-option>

    </mat-select>

  </mat-form-field>

  <mat-form-field>

    <mat-label>How long have you been in business?</mat-label>

    <mat-select
      formControlName="length_in_business"
      matInput>

      <mat-option 
        *ngFor="let length of lengths"
        [value]="length">{{length}}</mat-option>

    </mat-select>

  </mat-form-field>

</div>
