import { Component } from '@angular/core';
import { TrainingCourseMap } from '@bcx/models';

@Component({
  selector: 'app-marketing-training',
  templateUrl: './training.component.html',
  styleUrl: './training.component.sass',
})
export class TrainingComponent {

  CourseMap = TrainingCourseMap;

}
