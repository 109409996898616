import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Intl {
  type Key = 'calendar' | 'collation' | 'currency' | 'numberingSystem' | 'timeZone' | 'unit';

  function supportedValuesOf(input: Key): string[];
}

@Component({
  selector: 'ng-picker-timezone-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.sass'],
})
export class SelectorComponent {

  @Input() control: FormControl;

  @Input() label = 'Select A Time Zone';

  timeZones = Intl.supportedValuesOf('timeZone');

}
