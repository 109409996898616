import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

export { PersonService } from './person.service';

export { AttributeService as PersonAttributeService } from './attribute/attribute.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ]
})
export class PersonModule { }
