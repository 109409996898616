import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeekComponent } from './week/week.component';
import { DayModule } from '../day/day.module';

@NgModule({
  declarations: [
    WeekComponent,
  ],
  exports: [
    WeekComponent,
  ],
  imports: [
    CommonModule,
    DayModule,
  ],
})
export class WeekModule {}
