
export const DEFAULT_ACCOUNT = process.env['CDK_DEFAULT_ACCOUNT'] || '044950392153';

export const DEFAULT_TIME_ZONE = 'America/Denver';

export const DEVELOPMENT_AWS_ACCOUNT = '912834435257';

export const BucketNamePrefix = 'dtb-artifact-store-v2-9b9f73d5';

export const OPERATIONS_AWS_ACCOUNT = '127169847567';

export const PRODUCTION_AWS_ACCOUNT = '560807778918';

export const STAGING_AWS_ACCOUNT = '950938655710';
