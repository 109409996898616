import { Injectable } from '@angular/core';
import { UserServiceAdapter } from './user.service.adapter';
import { ApiService } from '@engagedcx/ng-amplify';
import { RestServerResponse, User, USER, UserChangeRolesAction, UserCreateAction, UserCreatedEvent, UserGetAction, UserInterface, UserListAction, UserListedEvent, UserRolesChangedEvent, UserUpdateAction, UserUpdatedEvent, UserViewedEvent } from '@bcx/models';
import { List } from '@digitaltoolbuilders/model-helpers';

@Injectable({
  providedIn: 'root'
})
export class RestService implements UserServiceAdapter {

  private path = `/${USER}`;

  constructor(private api: ApiService) { }

  changeRoles(action: UserChangeRolesAction): Promise<UserRolesChangedEvent> {
    
    return this.api.post<RestServerResponse<UserInterface>>(this.path, action)
    .then((response) => {

      return new UserRolesChangedEvent({
        ...response.data,
        payload: new User(response.data.payload),
      });

    });

  }

  create(action: UserCreateAction): Promise<UserCreatedEvent> {
    
    return this.api.post<RestServerResponse<UserInterface>>(this.path, action)
    .then((response) => {

      return new UserCreatedEvent({
        ...response.data,
        payload: new User(response.data.payload),
      });

    });

  }

  get(action: UserGetAction): Promise<UserViewedEvent> {
    
    return this.api.post<RestServerResponse<UserInterface>>(this.path, action)
    .then((response) => {

      return new UserViewedEvent({
        ...response.data,
        payload: new User(response.data.payload),
      });

    });

  }

  list(action: UserListAction): Promise<UserListedEvent> {
    
    return this.api.post<RestServerResponse<List<UserInterface>>>(this.path, action)
    .then((response) => {

      return new UserListedEvent({
        ...response.data,
        payload: new List<User>({
          ...response.data.payload,
          list: response.data.payload.list.map((item) => new User(item)),
        }),
      });

    });

  }

  update(action: UserUpdateAction): Promise<UserUpdatedEvent> {
    
    return this.api.post<RestServerResponse<UserInterface>>(this.path, action)
    .then((response) => {

      return new UserUpdatedEvent({
        ...response.data,
        payload: new User(response.data.payload),
      });

    });

  }

}
