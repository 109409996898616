import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { CoachGoalsForm } from './goals.form';
import { CoachGoalMap, CoachGoalNameMap } from '@bcx/models';
import { GetStartedService } from '../../get-started/get-started.service';
import { combineLatest, map, Observable, throttleTime } from 'rxjs';
import { ListBaseComponent } from '../../goal/list-base/list-base.component';

export interface ViewModel {

  goals: CoachGoalMap;

  progress: number;

}

@Component({
  selector: 'app-coach-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.sass'],
})
export class GoalsComponent extends ListBaseComponent implements OnDestroy, OnInit {

  form = new CoachGoalsForm();

  private goals: Observable<CoachGoalMap>;

  names = CoachGoalNameMap;

  @Output() override progress$: Observable<number>;

  vm$: Observable<ViewModel>;

  constructor(private service: GetStartedService) {

    super();

    const state = this.service.getCurrentState();

    this.form.patchValue(state.coach.goals);

    this.goals = this.service.state$.pipe(map((state) => state.coach.goals));

    this.vm$ = combineLatest({
      goals: this.goals,
      progress: this.progress.asObservable(),
    });

    this.calculateProgress();

  }

  ngOnInit(): void {
    
    this.subs['valueChanges'] = this.form.valueChanges
    .pipe(throttleTime(250))
    .subscribe((goals) => {

      this.saveGoals(goals);

    });

    this.subs['goals'] = this.goals
    .pipe(throttleTime(250))
    .subscribe(() => {

      this.calculateProgress();

    });

  }

  private saveGoals(goals: CoachGoalMap) {

    this.service.save({
      coach: {
        goals,
      },
    });

  }

}
