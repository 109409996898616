<div class="flex flex-col"
  *ngIf="(vm$ | async) as vm" 
  [formGroup]="form">

  <h2 class="text-md">Which of these goals are important to you?</h2>

  <div class="flex flex-col divide-y-2">

    <app-goal-level-of-importance 
      [form]="form"
      [goalName]="names.BEST_EXPERIENCES_EVERY_TIME">

      Provide the best experiences every time.

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="vm.goals[names.BEST_EXPERIENCES_EVERY_TIME]"
      [form]="form"
      [goalName]="names.INCREASE_AVERAGE_PURCHASE">

      Increase average purchase size

    </app-goal-level-of-importance>
    
    <app-goal-level-of-importance 
    *ngIf="vm.goals[names.INCREASE_AVERAGE_PURCHASE]"
      [form]="form"
      [goalName]="names.INCREASE_CONVERSION_RATE">

      Increase conversion rates

    </app-goal-level-of-importance>
    
    <app-goal-level-of-importance 
      *ngIf="vm.goals[names.INCREASE_CONVERSION_RATE]"
      [form]="form"
      [goalName]="names.INCREASE_CSAT">

      Increase your customer satisfaction ratings

    </app-goal-level-of-importance>
    
    <app-goal-level-of-importance 
      *ngIf="vm.goals[names.INCREASE_CSAT]"
      [form]="form"
      [goalName]="names.INCREASE_CUSTOMER_BASE">

      Increase your customer base

    </app-goal-level-of-importance>
    
    <app-goal-level-of-importance 
      *ngIf="vm.goals[names.INCREASE_CUSTOMER_BASE]"
      [form]="form"
      [goalName]="names.INCREASE_ROI">

      Increase ROI for every dollar you spend on customer service

    </app-goal-level-of-importance>
    
    <app-goal-level-of-importance 
      *ngIf="vm.goals[names.INCREASE_ROI]"
      [form]="form"
      [goalName]="names.MORE_PREMIUMS_UPGRADES">

      Sell more premium products or services

    </app-goal-level-of-importance>
    
    <app-goal-level-of-importance 
      *ngIf="vm.goals[names.MORE_PREMIUMS_UPGRADES]"
      [form]="form"
      [goalName]="names.MORE_REFERRALS">

      Generate more referrals and loyal customers

    </app-goal-level-of-importance>
    
    <app-goal-level-of-importance 
      *ngIf="vm.goals[names.MORE_REFERRALS]"
      [form]="form"
      [goalName]="names.RELEASE_NEGATIVITY">

      Release the negativity from day to day customer service

    </app-goal-level-of-importance>

  </div>
  
  <section class="flex flex-col"
    *ngIf="vm.goals[names.RELEASE_NEGATIVITY]">
    
    <mat-form-field>

      <mat-label>What other goals do you have?</mat-label>

      <textarea 
        formControlName="other"
        matInput></textarea>
      
    </mat-form-field>
    
  </section>
  
</div>