import { FormControl, FormGroup, Validators } from "@angular/forms";

export class ReleaseForm extends FormGroup {

  constructor() {

    super({
      reason: new FormControl('', [Validators.required]),
    });

  }

}
