import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OfficeHoursService {

  endTime = '8:00 PM';

  localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  ourTimeZone = 'America/Denver';

  startTime = '8:00 AM';

}
