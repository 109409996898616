import { USER_PERSON } from "../common";

export class UserPerson implements UserPersonInterface {

  created_epoch: number;

  person_id: string;

  user_id: string;

  constructor(input: Partial<UserPerson>) {

    if (input.created_epoch) this.created_epoch = input.created_epoch;
    if (input.person_id) this.person_id = input.person_id;
    if (input.user_id) this.user_id = input.user_id;

  }

}

export const UserPersonActionMap = {
  CREATE: `${USER_PERSON}/create`,
  DELETE: `${USER_PERSON}/delete`,
  FIND: `${USER_PERSON}/find`,
  GET: `${USER_PERSON}/get`,
  VIEW: `${USER_PERSON}/view`,
};

export interface UserPersonInterface {

  created_epoch: number;

  person_id: string;

  user_id: string;

}
