<div>

  <mat-card *ngIf="paymentMethod">

    <mat-card-header>

      <mat-card-title>
<!-- 
        <ng-stripe-payment-method-label 
          [paymentMethod]="paymentMethod"></ng-stripe-payment-method-label> -->

      </mat-card-title>

    </mat-card-header>

    <mat-card-content>

      <pre>{{paymentMethod | json}}</pre>

    </mat-card-content>

  </mat-card>

</div>
