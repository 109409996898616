
export const STRIPE_EVENTS_TOPIC_ARN_ENV = 'STRIPE_EVENTS_TOPIC';

export const STRIPE_INVOICE_PAID = 'invoice.paid';

export const STRIPE_PRICE_CUSTOM_RESOURCE_PARAM_NAME = '/custom-cf/stripe/price/LambdaArn';

export const STRIPE_PRODUCT_CUSTOM_RESOURCE_PARAM_NAME = '/custom-cf/stripe/product/LambdaArn';

export const STRIPE_PUBLIC_KEY_PARAM = '/stripe/public-key';

export const STRIPE_SECRET_KEY_PARAM = '/stripe/secret-key';

export const STRIPE_SET_DEFAULT_PRICE_CUSTOM_RESOURCE_PARAM_NAME = '/custom-cf/stripe/set-default-price/LambdaArn';

export const STRIPE_SETUP_INTENT_SUCCEEDED = 'setup_intent.succeeded';

export const STRIPE_WEBHOOK_DEFAULT_PARAMETER_NAME = '/custom-cf-stripe/webhook/LambdaArn';

export const STRIPE_WEBHOOK_SECRET_PARAM = '/stripe/webhook/secret';

export const STRIPE_WEBHOOK_SECRET_ENV = 'STRIPE_WEBHOOK_SECRET';
