
export const DNS_DOMAIN_NAME_PARAM = '/dns/DomainName';

export const DNS_FULL_DOMAIN_NAME_PARAM_NAME = '/dns/FullDomainName';

export const DNS_HOSTED_ZONE_ID_PARAM_NAME = '/dns/HostedZoneId';

export const DNS_SSL_CERTIFICATE_ARN_PARAM = '/dns/ssl/CertificateArn';

export const DNS_SUB_DOMAIN_NAME_PARAM_NAME = '/dns/SubDomainName';
