
import { NativeAttributeValue } from '@aws-sdk/util-dynamodb';

export type ConditionExpression = string;

export type ConsistentRead = boolean | undefined;

export type ExpressionAttributeNames = Record<string, string>;

export type ExpressionAttributeValues = Record<string, NativeAttributeValue>;

export type ExclusiveStartKey = {

  [key: string]: NativeAttributeValue;

} | undefined;

export type FilterExpression = string;

export type KeyConditionExpression = string;

export type LastEvaluatedKey = {

  [key: string]: NativeAttributeValue;

} | undefined;

export type Limit = number | undefined;

export type UpdateExpression = string;
