import { StripePaymentMethodActionNameMap, StripePaymentMethodGetAction, StripePaymentMethodGetPayload, StripePaymentMethodListAction, StripePaymentMethodListPayload } from "@bcx/models";
import Joi from "joi";
import { subscription_id } from "./common";
import { createActionSchema } from "../model-action";

export const get = Joi.object<StripePaymentMethodGetPayload>()
  .keys({
    id: subscription_id.required(),
  });

export const getAction = createActionSchema<StripePaymentMethodGetAction>(StripePaymentMethodActionNameMap.GET, get);

export const list = Joi.object<StripePaymentMethodListPayload>()
  .keys({
    
  });

export const listAction = createActionSchema<StripePaymentMethodListAction>(StripePaymentMethodActionNameMap.LIST, list);
