import { Component, Inject, OnInit } from '@angular/core';
import { CancelForm } from '../cancel-form/cancel-form.form';
import { SubsComponent } from '@bcx/ng-helpers';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClassService } from '../class.service';
import { TrainingClass } from '@bcx/models';

@Component({
  selector: 'ng-training-cancel-dialog',
  templateUrl: './cancel-dialog.component.html',
  styleUrls: ['./cancel-dialog.component.sass'],
})
export class CancelDialogComponent extends SubsComponent implements OnInit {

  error?: string;

  form: CancelForm;

  processing: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public trainingClass: TrainingClass,
    private dialog: MatDialogRef<CancelDialogComponent>,
    private service: ClassService,
  ) {

    super();

    this.form = new CancelForm();

  }

  closeDialog() {

    this.dialog.close();

  }

  ngOnInit(): void {
    
    // this.subs['trainingClass'] = this.service.select(this.trainingClass.class_id)
    // .subscribe(({ error, processing }) => {

    //   this.error = error;

    //   this.processing = processing;

    // });

  }

  submit(): void {
    
    this.service.cancel(this.trainingClass, {
      canceled_epoch: this.form.value.canceled_epoch,
      reason: this.form.value.reason,
    })
    .then((canceled: TrainingClass) => {

      this.dialog.close(canceled);

    })
    .catch((e: Error) => {

      console.error(e);

    });

  }

}
