import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CreateForm } from './create-form.form';
import { combineLatest, distinctUntilChanged, filter, startWith } from 'rxjs';
import { SubsComponent } from '@bcx/ng-helpers';
import { ConsultingSessionCategories, ConsultingSessionTypeMap, ConsultingSessionTypes, ProductPrice } from '@bcx/models';
import { PriceService } from '../../../billing/price/price.service';

export const ONE_DAY = 12 * 60;

export const ONE_HOUR = 60;

export const TWO_HOUR = 120;

@Component({
  selector: 'ng-consulting-session-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.sass'],
})
export class CreateFormComponent extends SubsComponent implements OnChanges {

  categories = ConsultingSessionCategories;

  categoryControl: FormControl;

  currencyControl: FormControl;

  @Input() form: CreateForm;

  payerAccountId: FormControl;

  paymentMethodControl: FormControl;

  price?: ProductPrice;

  scheduledLengthMinutesControl: FormControl;

  scheduledStartEpochControl: FormControl;

  sessionTypeControl: FormControl;

  sessionTypes = ConsultingSessionTypes;

  stripePriceControl: FormControl;

  constructor(protected stripePrices: PriceService) {

    super();

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['form'] && this.form) {

      this.categoryControl = this.form.get('session_category') as FormControl;
      this.currencyControl = this.form.get('currency') as FormControl;
      this.payerAccountId = this.form.get('payer_account_id') as FormControl;
      this.paymentMethodControl = this.form.get('stripe_payment_method_id') as FormControl;
      this.scheduledLengthMinutesControl = this.form.get('scheduled_length_minutes') as FormControl;
      this.scheduledStartEpochControl = this.form.get('scheduled_start_epoch') as FormControl;
      this.sessionTypeControl = this.form.get('session_type') as FormControl;
      this.stripePriceControl = this.form.get('stripe_price_id') as FormControl;

      this.subs['price'] = combineLatest({
        category: this.categoryControl.valueChanges
        .pipe(startWith(this.categoryControl.value))
        .pipe(filter((value?: string): value is string => {

          return typeof value === 'string';

        }))
        .pipe(distinctUntilChanged()),
        currency: this.currencyControl.valueChanges
        .pipe(startWith(this.currencyControl.value))
        .pipe(filter((value?: string): value is string => {

          return typeof value === 'string';

        }))
        .pipe(distinctUntilChanged()),
        prices: this.stripePrices.prices$,
        sessionType: this.sessionTypeControl.valueChanges
        .pipe(startWith(ConsultingSessionTypeMap.ONLINE))
        .pipe(filter((value?: string): value is string => {

          return typeof value === 'string';

        }))
        .pipe(distinctUntilChanged())
      })
      .subscribe(({ category, currency, prices, sessionType }) => {

        this.price = prices.find((p) => {

          return p.currency === currency
            && p.session_category === category
            && p.session_type === sessionType;

        });

        console.log('stripe price', this.price, { category, currency, sessionType });

        if (this.price) {

          this.stripePriceControl.patchValue(this.price.stripe_price_id);

        }

      });

    }

  }

  set1Day() {

    this.form.patchValue({
      scheduled_length_minutes: ONE_DAY
    });

  }
  
  set1Hour() {

    this.form.patchValue({
      scheduled_length_minutes: ONE_HOUR
    });

  }
  
  set2Hour() {

    this.form.patchValue({
      scheduled_length_minutes: TWO_HOUR
    });

  }

}
