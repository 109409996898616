<div>

  <div fxLayout="row" fxLayoutAlign="end"
    mat-dialog-title>

    <button type="button" color="warn"
      (click)="closeDialog()"
      mat-icon-button><mat-icon>close</mat-icon></button>

  </div>

  <mat-dialog-content>

    <p>You have successfully signed out.</p>

  </mat-dialog-content>

  <mat-dialog-actions>

    

  </mat-dialog-actions>

</div>