<div mat-dialog-title>

  <div class="flex flex-row justify-between">

    <h1 class="text-lg">Other Options</h1>

    <button type="button" color="primary"
      (click)="closeDialog()"
      mat-icon-button><mat-icon>close</mat-icon></button>

  </div>

</div>

<mat-dialog-content>

  <div class="flex flex-row justify-between">

    <ng-container 
      *ngFor="let option of options"
      [ngSwitch]="option.step_type">
    
      <app-coach-book-card *ngSwitchCase="BOOK">

        <button type="button" color="primary"
          (click)="selectBook(option)"
          mat-raised-button>Select Book</button>

      </app-coach-book-card>

      <app-coach-consulting-card *ngSwitchCase="CONSULTING">

        <button type="button" color="primary"
          (click)="selectConsulting(option)"
          mat-raised-button>Select Consulting</button>

      </app-coach-consulting-card>

      <app-coach-training-card *ngSwitchCase="TRAINING">

        <button type="button" color="primary"
          (click)="selectTraining(option)"
          mat-raised-button>Select Training</button>

      </app-coach-training-card>

    </ng-container>

  </div>

</mat-dialog-content>

<mat-dialog-actions>



</mat-dialog-actions>