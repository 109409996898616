<mat-card
  *ngIf="session">

  <mat-card-header>

    <mat-card-title>{{session.actual.start_epoch || session.scheduled.start_epoch | date:'short'}}</mat-card-title>
    <mat-card-subtitle>{{session.status}} - {{session.category}} - {{session.type}}</mat-card-subtitle>

  </mat-card-header>

  <mat-card-content>

    <pre>{{session | json}}</pre>

  </mat-card-content>

</mat-card>
