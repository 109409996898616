import Joi from "joi";

export const charge_id = Joi.string();

export const customer_id = Joi.string();

export const invoice_id = Joi.string();

export const payment_method_id = Joi.string();

export const subscription_id = Joi.string();
