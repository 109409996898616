<section class="question">

  <span>{{label}}</span>

  <mat-button-toggle-group
    [formControl]="control"
    matInput>

    <mat-button-toggle 
      *ngFor="let group of groups"
      [value]="group">{{group}}</mat-button-toggle>

  </mat-button-toggle-group>

</section>