import { FormControl, FormGroup, Validators } from "@angular/forms";
import { OwnerGoalNames } from "@bcx/models";

export class OwnerGoalsForm extends FormGroup {

  constructor() {

    super({});

    OwnerGoalNames.forEach((goal) => {

      this.addControl(goal, new FormControl<string>('', [Validators.required]));

    });

    this.addControl('other', new FormControl(undefined, []));

  }

}
