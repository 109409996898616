import { BillingSettingsActionNameMap, BillingSettingsCreateAction, BillingSettingsCreatePayload, BillingSettingsGetAction, BillingSettingsGetPayload, BillingSettingsListAction, BillingSettingsListPayload, BillingSettingsUpdateAction, BillingSettingsUpdatePayload } from "@bcx/models";
import Joi from "joi";
import { currency, user_id } from "../common";
import { payment_method_id } from "../stripe";
import { createActionSchema } from "../model-action";
import { epoch } from "@digitaltoolbuilders/schema-helpers";

export const create = Joi.object<BillingSettingsCreatePayload>()
  .keys({
    default_currency: currency.required(),
    default_payment_method: payment_method_id.optional().allow(null),
    user_id: user_id.required(),
  });

export const createAction = createActionSchema<BillingSettingsCreateAction>(BillingSettingsActionNameMap.CREATE, create);

export const get = Joi.object<BillingSettingsGetPayload>()
  .keys({
    user_id: user_id.required(),
  });

export const getAction = createActionSchema<BillingSettingsGetAction>(BillingSettingsActionNameMap.GET, get);

export const list = Joi.object<BillingSettingsListPayload>()
  .keys({
    default_currency: currency.optional().allow(null),
  });

export const listAction = createActionSchema<BillingSettingsListAction>(BillingSettingsActionNameMap.LIST, list);

export const update = Joi.object<BillingSettingsUpdatePayload>()
  .keys({
    default_currency: currency.required(),
    default_payment_method: payment_method_id.optional().allow(null),
    last_updated_epoch: epoch.required(),
    user_id: user_id.required(),
  });

export const updateAction = createActionSchema<BillingSettingsUpdateAction>(BillingSettingsActionNameMap.UPDATE, update);
