import { Component, Inject, OnInit } from '@angular/core';
import { FinishForm } from '../finish-form/finish-form';
import { SubsComponent } from '@bcx/ng-helpers';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClassService } from '../class.service';
import { TrainingClass } from '@bcx/models';

@Component({
  selector: 'ng-training-finish-dialog',
  templateUrl: './finish-dialog.component.html',
  styleUrls: ['./finish-dialog.component.sass'],
})
export class FinishDialogComponent extends SubsComponent implements OnInit {

  error?: string;

  form = new FinishForm();

  processing: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public trainingClass: TrainingClass,
    private dialog: MatDialogRef<FinishDialogComponent>,
    private service: ClassService,
  ) {

    super();

  }

  closeDialog() {

    this.dialog.close();

  }

  ngOnInit(): void {
    
    // this.subs['trainingClass'] = this.service.select(this.trainingClass.class_id)
    // .subscribe(({ error, processing }) => {

    //   this.error = error;

    //   this.processing = processing;

    // });

  }

  submit(): void {
    
    this.service.finish(this.trainingClass, {
      finished_epoch: this.form.value.finished_epoch,
    })
    .then((finished: TrainingClass) => {

      this.dialog.close(finished);

    })
    .catch((e: Error) => {

      console.error(e);

    });

  }

}
