import { ProductPrice } from "../products";

export * from './charge';
export * from './customer';
export * from './invoice';
export * from './payment-method';
export * from './price';
export * from './product';
export * from './subscription';

export class StripePrices {

  [stripe_price_id: string]: ProductPrice & { stripe_price_id: string, stripe_product_id: string };

  constructor(input: StripePrices) {

    Object.keys(input).forEach((stripe_price_id) => {

      this[stripe_price_id] = input[stripe_price_id];

    });

  }

}

export class StripeProducts {

  [stripe_product_id: string]: string;

  constructor(input: StripeProducts) {

    Object.keys(input).forEach((product_id) => {

      this[product_id] = input[product_id];

    });

  }

}
