
import { createAction, props } from '@ngrx/store';
import { SetupIntent } from '@stripe/stripe-js';
import { Stripe } from 'stripe';

export interface AddPaymentMethodProps {

  data?: Stripe.SetupIntent;

  error?: string;

}

export const addPaymentMethod = createAction(
  '[Stripe.PaymentMethod] Add Payment Method',
);

export const addPaymentMethodFailure = createAction(
  '[Stripe.PaymentMethod] Add Payment Method Failure',
  props<AddPaymentMethodProps>()
);

export const addPaymentMethodSuccess = createAction(
  '[Stripe.PaymentMethod] Add Payment Method Success',
  props<AddPaymentMethodProps>()
);

export interface LoadMyPaymentMethodsProps {

  data?: Stripe.ApiList<Stripe.PaymentMethod>;

  error?: string;

}

export const loadMyPaymentMethods = createAction(
  '[Stripe.PaymentMethod] Load My Payment Methods'
);

export const loadMyPaymentMethodsSuccess = createAction(
  '[Stripe.PaymentMethod] Load My Payment Methods Success',
  props<LoadMyPaymentMethodsProps>()
);

export const loadMyPaymentMethodsFailure = createAction(
  '[Stripe.PaymentMethod] Load My Payment Methods Failure',
  props<LoadMyPaymentMethodsProps>()
);

export interface LoadPaymentMethodProps {

  error?: string;

  id: string;

  loading: boolean;

  paymentMethod?: Stripe.PaymentMethod;

}

export const loadPaymentMethod = createAction(
  '[Stripe.PaymentMethod] Load Payment Method',
  props<LoadPaymentMethodProps>(),
);

export interface LoadAllPaymentMethodsProps {

  data?: Stripe.ApiList<Stripe.PaymentMethod>;

  error?: string;

}

export const loadAllPaymentMethods = createAction(
  '[Stripe.PaymentMethod] Load All Payment Methods'
);

export const loadAllPaymentMethodsSuccess = createAction(
  '[Stripe.PaymentMethod] Load All Payment Methods Success',
  props<LoadAllPaymentMethodsProps>()
);

export const loadAllPaymentMethodsFailure = createAction(
  '[Stripe.PaymentMethod] Load All Payment Methods Failure',
  props<LoadAllPaymentMethodsProps>()
);

export interface RetrieveSetupIntentProps {

  error?: string;

  intent: string;

  setupIntent?: SetupIntent;

}

export const retrieveSetupIntent = createAction(
  '[Stripe.PaymentMethod] Retrieve Setup Intent',
  props<RetrieveSetupIntentProps>()
);

export const retrieveSetupIntentSuccess = createAction(
  '[Stripe.PaymentMethod] Retrieve Setup Intent Success',
  props<RetrieveSetupIntentProps>()
);

export const retrieveSetupIntentFailure = createAction(
  '[Stripe.PaymentMethod] Retrieve Setup Intent Failure',
  props<RetrieveSetupIntentProps>()
);
