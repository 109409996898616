<mat-card>

  <mat-card-header>

    <mat-card-title>Best CX Ever For Owners</mat-card-title>
    <mat-card-subtitle>How To Provide The Best Customer Experiences Every Time</mat-card-subtitle>

  </mat-card-header>

  <mat-card-content>

    

  </mat-card-content>

  <mat-card-actions>

    <ng-content></ng-content>

  </mat-card-actions>

</mat-card>