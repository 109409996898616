import { TrainingTicketActionNameMap, TrainingTicketCancelAction, TrainingTicketCancelPayload, TrainingTicketCreateAction, TrainingTicketCreatePayload, TrainingTicketGetAction, TrainingTicketGetPayload, TrainingTicketListAction, TrainingTicketListPayload, TrainingTicketRedeemAction, TrainingTicketRedeemPayload, TrainingTicketRevokeAction, TrainingTicketRevokePayload, TrainingTicketSuspendAction, TrainingTicketSuspendPayload } from "@bcx/models";
import Joi from "joi";
import { createActionSchema } from "../model-action";

export const status = Joi.string();

export const ticket_id = Joi.string();

export const cancel = Joi.object<TrainingTicketCancelPayload>()
  .keys({
    ticket_id: ticket_id.required(),
  });

export const cancelAction = createActionSchema<TrainingTicketCancelAction>(TrainingTicketActionNameMap.CANCEL, cancel);

export const create = Joi.object<TrainingTicketCreatePayload>()
  .keys({
    ticket_id: ticket_id.required(),
  });

export const createAction = createActionSchema<TrainingTicketCreateAction>(TrainingTicketActionNameMap.CREATE, create);

export const get = Joi.object<TrainingTicketGetPayload>()
  .keys({
    ticket_id: ticket_id.required(),
  });

export const getAction = createActionSchema<TrainingTicketGetAction>(TrainingTicketActionNameMap.GET, get);

export const list = Joi.object<TrainingTicketListPayload>()
  .keys({
    status: status.required(),
  });

export const listAction = createActionSchema<TrainingTicketListAction>(TrainingTicketActionNameMap.LIST, list);

export const redeem = Joi.object<TrainingTicketRedeemPayload>()
  .keys({
    ticket_id: ticket_id.required(),
  });

export const redeemAction = createActionSchema<TrainingTicketRedeemAction>(TrainingTicketActionNameMap.REDEEM, redeem);

export const revoke = Joi.object<TrainingTicketRevokePayload>()
  .keys({
    ticket_id: ticket_id.required(),
  });

export const revokeAction = createActionSchema<TrainingTicketRevokeAction>(TrainingTicketActionNameMap.REVOKE, revoke);

export const suspend = Joi.object<TrainingTicketSuspendPayload>()
  .keys({
    ticket_id: ticket_id.required(),
  });

export const suspendAction = createActionSchema<TrainingTicketSuspendAction>(TrainingTicketActionNameMap.SUSPEND, suspend);
