<mat-card
  *ngIf="session">

  <mat-card-header>

    <mat-card-title>Session &commat; {{session.actual.start_epoch || session.scheduled.start_epoch | date:'short'}}</mat-card-title>
    <mat-card-subtitle>{{session.status | capitalizeFirst}} : {{session.category | capitalizeFirst}} - {{session.type | capitalizeFirst}}</mat-card-subtitle>

  </mat-card-header>

  <mat-card-content>

    <pre *ngIf="false">{{session | json}}</pre>

  </mat-card-content>

</mat-card>