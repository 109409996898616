
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ONLINE } from "@bcx/models";

export class CreateForm extends FormGroup {

  constructor() {

    const start_epoch = new FormControl<number | null>(null, [Validators.required]);

    super({
      class_number: new FormControl('', [Validators.required]),
      class_type: new FormControl(ONLINE, [Validators.required]),
      role: new FormControl('', [Validators.required]),
      start_epoch,
    });

  }

}
