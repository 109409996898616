import { Component, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'ng-calendar-month-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.sass'],
})
export class NavigationComponent {

  @Output() events = new Subject<string>();

  isAfterMin = false;

  isBeforeMax = false;

  monthName: string;

  year: string;

  next() {

    this.events.next('next');

  }

  previous() {

    this.events.next('previous');

  }

}
