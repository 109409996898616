import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StartForm } from './start-form.form';

@Component({
  selector: 'ng-consulting-session-start-form',
  templateUrl: './start-form.component.html',
  styleUrls: ['./start-form.component.sass'],
})
export class StartFormComponent implements OnChanges {

  actualStartControl: FormControl;

  @Input() form: StartForm;

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['form'] && this.form) {

      this.actualStartControl = this.form.get('actual_start_epoch') as FormControl;

    }

  }
  
}
