
export const EMAIL = 'email';

export const PHONE_NUMBER = 'phone-number';

export interface AuthedUser {

  email: string;

  name: string;

  phone_number: string;

  roles: Array<string>;

  user_id: string;

}

export interface SignUpData {

  email?: string;

  name: string;

  phone_number?: string;

  otp: string;

}
