import { Injectable } from '@angular/core';
import { ClassServiceAdapter } from './class.service.adapter';
import { RestServerResponse, TRAINING_CLASS, TrainingClass, TrainingClassCancelAction, TrainingClassCanceledEvent, TrainingClassConfirmAction, TrainingClassConfirmedEvent, TrainingClassCreateAction, TrainingClassCreatedEvent, TrainingClassFinishAction, TrainingClassFinishedEvent, TrainingClassGetAction, TrainingClassInterface, TrainingClassListAction, TrainingClassListedEvent, TrainingClassRequestAction, TrainingClassRequestedEvent, TrainingClassStartAction, TrainingClassStartedEvent, TrainingClassViewedEvent } from '@bcx/models';
import { ApiService } from '@engagedcx/ng-amplify';
import { List } from '@digitaltoolbuilders/model-helpers';

@Injectable({
  providedIn: 'root'
})
export class RestService implements ClassServiceAdapter {

  readonly path = `/${TRAINING_CLASS}`;

  constructor(private restApi: ApiService) { }

  cancel(action: TrainingClassCancelAction): Promise<TrainingClassCanceledEvent> {
    
    return this.restApi.post<RestServerResponse<TrainingClassInterface>>(this.path, action)
    .then((response) => {

      return new TrainingClassCanceledEvent({
        ...response.data,
        payload: new TrainingClass(response.data.payload),
      });

    });

  }

  confirm(action: TrainingClassConfirmAction): Promise<TrainingClassConfirmedEvent> {
      
    return this.restApi.post<RestServerResponse<TrainingClassInterface>>(this.path, action)
    .then((response) => {

      return new TrainingClassConfirmedEvent({
        ...response.data,
        payload: new TrainingClass(response.data.payload),
      });

    });

  }

  create(action: TrainingClassCreateAction): Promise<TrainingClassCreatedEvent> {
      
    return this.restApi.post<RestServerResponse<TrainingClassInterface>>(this.path, action)
    .then((response) => {

      return new TrainingClassCreatedEvent({
        ...response.data,
        payload: new TrainingClass(response.data.payload),
      });

    });

  }

  finish(action: TrainingClassFinishAction): Promise<TrainingClassFinishedEvent> {
     
    return this.restApi.post<RestServerResponse<TrainingClassInterface>>(this.path, action)
    .then((response) => {

      return new TrainingClassFinishedEvent({
        ...response.data,
        payload: new TrainingClass(response.data.payload),
      });

    });

  }

  get(action: TrainingClassGetAction): Promise<TrainingClassViewedEvent> {
      
    return this.restApi.post<RestServerResponse<TrainingClassInterface>>(this.path, action)
    .then((response) => {

      return new TrainingClassViewedEvent({
        ...response.data,
        payload: new TrainingClass(response.data.payload),
      });

    });

  }

  list(action: TrainingClassListAction): Promise<TrainingClassListedEvent> {
      
    return this.restApi.post<RestServerResponse<List<TrainingClassInterface>>>(this.path, action)
    .then((response) => {

      return new TrainingClassListedEvent({
        ...response.data,
        payload: new List<TrainingClass>({
          ...response.data.payload,
          list: response.data.payload.list.map((item) => new TrainingClass(item)),
        }),
      });

    });

  }

  request(action: TrainingClassRequestAction): Promise<TrainingClassRequestedEvent> {
    
    return this.restApi.post<RestServerResponse<TrainingClassInterface>>(this.path, action)
    .then((response) => {

      return new TrainingClassRequestedEvent({
        ...response.data,
        payload: new TrainingClass(response.data.payload),
      });

    });

  }

  start(action: TrainingClassStartAction): Promise<TrainingClassStartedEvent> {
    
    return this.restApi.post<RestServerResponse<TrainingClassInterface>>(this.path, action)
    .then((response) => {

      return new TrainingClassStartedEvent({
        ...response.data,
        payload: new TrainingClass(response.data.payload),
      });

    });

  }

}
