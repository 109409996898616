import { StripeCustomerActionNameMap, StripeCustomerGetAction, StripeCustomerGetPayload, StripeCustomerListAction, StripeCustomerListPayload, StripeCustomerUpdateAction, StripeCustomerUpdatePayload } from "@bcx/models";
import Joi from "joi";
import { subscription_id } from "./common";
import { createActionSchema } from "../model-action";

export const get = Joi.object<StripeCustomerGetPayload>()
  .keys({
    id: subscription_id.required(),
  });

export const getAction = createActionSchema<StripeCustomerGetAction>(StripeCustomerActionNameMap.GET, get);

export const list = Joi.object<StripeCustomerListPayload>()
  .keys({
    
  });

export const listAction = createActionSchema<StripeCustomerListAction>(StripeCustomerActionNameMap.LIST, list);

export const update = Joi.object<StripeCustomerUpdatePayload>()
  .keys({
    
  });

export const updateAction = createActionSchema<StripeCustomerUpdateAction>(StripeCustomerActionNameMap.UPDATE, list);
