
<mat-card>

  <mat-card-header>

    <mat-card-title>

      <span>{{ticket.course | capitalizeFirst}}</span>

      <span>&nbsp;Training Class #{{ticket.course_class_number}} &lpar;{{ticket.class_type | capitalizeFirst}}&rpar;</span>

    </mat-card-title>

    <mat-card-subtitle>{{ticket.status | capitalizeFirst}} - &num;{{ticket.ticket_id}}</mat-card-subtitle>

  </mat-card-header>

  <mat-card-content>

    <div fxLayout="column" fxLayoutGap="10px"
      fxLayout.gt-xs="row">

      <mat-card fxFlex="50"
        *ngIf="trainingClass">

        <mat-card-header>

          <mat-card-title>Class Id: {{trainingClass.class_id}}</mat-card-title>
          <mat-card-subtitle>{{trainingClass.status | capitalizeFirst}}</mat-card-subtitle>

        </mat-card-header>

        <mat-card-content>

          <ng-container [ngSwitch]="trainingClass.status">

            <span *ngSwitchCase="ClassStatusMap.CANCELED">The class has been canceled. Please contact us for more details.</span>

            <span *ngSwitchCase="ClassStatusMap.CONFIRMED">The class has been confirmed. We will send a notification 24 hours before class begins.</span>

            <span *ngSwitchCase="ClassStatusMap.REQUESTED">The class still needs to be confirmed. When it is confirmed we will send you a notification.</span>

            <span *ngSwitchCase="ClassStatusMap.FINISHED">The class has finished.</span>

            <span *ngSwitchCase="ClassStatusMap.STARTED">The class has started.</span>

          </ng-container>

        </mat-card-content>

      </mat-card>

      <mat-card fxFlex="50"
        *ngIf="seat">

        <mat-card-header>

          <mat-card-title>Your Seat</mat-card-title>
          <mat-card-subtitle>{{seat.status | capitalizeFirst}} - &num;{{seat.seat_id}}</mat-card-subtitle>

        </mat-card-header>

        <mat-card-content>

          <ng-container [ngSwitch]="seat.status">

            <span *ngSwitchCase="StatusMap.RELEASED">Your seat has been released. Please contact us for more details.</span>

            <span *ngSwitchCase="StatusMap.CONFIRMED">Your seat has been confirmed.</span>

            <span *ngSwitchCase="StatusMap.REQUESTED">Your seat still needs to be confirmed. When it is confirmed we will send you a notification.</span>

          </ng-container>

        </mat-card-content>

      </mat-card>

    </div>

  </mat-card-content>

</mat-card>
