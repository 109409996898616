<div>

  <div class="flex flex-row">

    <div class="flex">

      <mat-card>

        <mat-card-header>

          <mat-card-title-group>

            <mat-card-title>Providers</mat-card-title>
            <mat-card-subtitle>4 Hours (1 class)</mat-card-subtitle>
            <img mat-card-lg-image src="/assets/people/provider.svg" >

          </mat-card-title-group>

        </mat-card-header>

        <mat-card-content>



        </mat-card-content>

        <mat-card-actions>

          <button type="button" color="primary"
            mat-raised-button>Register Now</button>

        </mat-card-actions>

      </mat-card>

    </div>

    <div class="flex">

      <mat-card>

        <mat-card-header>

          <mat-card-title-group>

            <mat-card-title>Owners</mat-card-title>
            <mat-card-subtitle>8 Hours (2 classes)</mat-card-subtitle>
            <img mat-card-lg-image src="/assets/people/owner.svg" >

          </mat-card-title-group>

        </mat-card-header>

        <mat-card-content>



        </mat-card-content>

        <mat-card-actions>

          <button type="button" color="primary"
            mat-raised-button>Register Now</button>

        </mat-card-actions>

      </mat-card>

    </div>

    <div class="flex">

      <mat-card>

        <mat-card-header>

          <mat-card-title-group>

            <mat-card-title>Coaches</mat-card-title>
            <mat-card-subtitle>4 Hours (1 class)</mat-card-subtitle>
            <img mat-card-lg-image src="/assets/people/coach.svg" >

          </mat-card-title-group>

        </mat-card-header>

        <mat-card-content>



        </mat-card-content>

        <mat-card-actions>

          <button type="button" color="primary"
            mat-raised-button>Register Now</button>

        </mat-card-actions>

      </mat-card>

    </div>

    <div class="flex">

      <mat-card>

        <mat-card-header>

          <mat-card-title-group>

            <mat-card-title>Small To Best CX</mat-card-title>
            <mat-card-subtitle>8 Hours (2 classes)</mat-card-subtitle>
            <img mat-card-lg-image src="/assets/people/small-to-best.svg" >

          </mat-card-title-group>

        </mat-card-header>

        <mat-card-content>



        </mat-card-content>

        <mat-card-actions>

          <button type="button" color="primary"
            mat-raised-button>Register Now</button>

        </mat-card-actions>

      </mat-card>

    </div>

  </div>

</div>
