<div class="flex flex-col margin10 border">
    
  <div class="flex flex-col">

    <mat-card class="border margin10">

      <div class="flex flex-col">

        <h2 class="center">Customer Experience Training</h2>
        <h3 class="center">Online & In Person</h3>
  
      </div>

    </mat-card>     
  
  </div>

  <div class="flex flex-col provider margin10">

    <mat-card class="margin20 border">

      <mat-card-header>
        
        <mat-card-title class="center padding10">Training for Providers</mat-card-title>
      
      </mat-card-header>

      <mat-card-content>

        <div class="flex flex-col justify-between sm:flex-row">

          <div class="flex flex-col training-content border">

            <p>Our provider class is focused on teaching you the strategies and tactics 
              that we developed to make our customer experiences the best.</p>
            <p>Our training is formatted to you as a provider and how you will 
              implement these practices on your own.</p>
            <p>We also teach you the techniques we use to deal with the negativity that 
              is a reality of providing customer service.</p>

            <div>

              <a color="basic"
              class="box-shadow"
              mat-raised-button
              routerLink="/training/register">Learn More</a> 
            
            </div> 
            
          </div>

          <div class="flex flex-col">

            <div class="flex flex-col box-shadow margin5 padding10">

              <h3 class="center">Includes:</h3>
              
              <p>"Best CX Ever For Providers"</p>
              <p>Training packet</p>
              <p>1 free hour of consulting</p>

              <div class="flex flex-row justify-center">

                <a color="primary"
                  mat-raised-button
                  routerLink="/training/register">Register</a>
                
              </div>

            </div>
          
          </div>
        
        </div>

      </mat-card-content>

    </mat-card>
  
  </div>

  <div class="flex flex-col owner margin10">

    <mat-card class="margin20 border">

      <mat-card-header>
          
        <mat-card-title class="center padding10">Training for Owners</mat-card-title>
      
      </mat-card-header>

      <mat-card-content>

        <div class="flex flex-col sm:flex-row">

          <div class="flex flex-col">

            <div class="flex flex-col box-shadow margin5 padding10">

              <h3 class="center">Includes:</h3>

              <p>"Best CX Ever for Owners"</p>
              <p>"Best CX Ever for Providers"</p>
              <p>Training packet</p>
              <p>4 hours of consulting FREE</p>

              <div class="flex flex-row justify-center">

                <a color="primary"
                  mat-raised-button
                  routerLink="/training/register">Register</a>
                
              </div>

            </div> 
            
          </div>

          <div class="flex flex-col training-content">

            <p>Taugh in three 4 hour sessions. </p>

            <div>

              <a color="basic"
              class="box-shadow"
              mat-raised-button
              routerLink="/training/register">Learn More</a> 
            
            </div> 

          </div>

        </div>

      </mat-card-content>
  
    </mat-card>
  
  </div>

</div>