import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConsultingSessionTypeMap, ConsultingSessionTypes } from '@bcx/models';

@Component({
  selector: 'ng-consulting-session-select-type',
  templateUrl: './select-type.component.html',
  styleUrls: ['./select-type.component.sass'],
})
export class SelectTypeComponent {

  @Input() category: string;

  @Input() control: FormControl;

  @Input() currency?: string;

  @Input() role: string;

  sessionTypes = ConsultingSessionTypes;

  isDisabled(sessionType: string) {

    if (sessionType === ConsultingSessionTypeMap.ONLINE) {

      return false;

    }

    return true;

  }

}
