<mat-form-field style="width: 100%"
  *ngIf="control">

  <mat-label>{{label}}</mat-label>

  <mat-select
    [formControl]="control"
    matInput>

    <mat-option *ngFor="let time of available"
      [value]="time.value">
    
      <span>{{time.label}}</span>
        
    </mat-option>

  </mat-select>

  <mat-error *ngIf="control.hasError('required')">{{requiredError}}</mat-error>

</mat-form-field>
