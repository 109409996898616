<div class="flex flex-col"
  *ngIf="(vm$ | async) as vm">

  <mat-progress-bar mode="determinate" [value]="vm.progress"></mat-progress-bar>

  <app-owner-goals
    (progress$)="onGoalsProgress($event)"></app-owner-goals>

  <app-owner-questions
    *ngIf="vm.showQuestions"
    (progress$)="onQuestionsProgress($event)"></app-owner-questions>

  <mat-progress-bar mode="determinate" [value]="vm.progress"></mat-progress-bar>

</div>
