<div fxLayout="column"
  *ngIf="customer">

  <mat-card>

    <mat-card-header>

      <mat-card-title>{{customer.name}}</mat-card-title>
      <mat-card-subtitle>{{customer.id}}</mat-card-subtitle>

    </mat-card-header>

    <mat-card-content>

      <dl>
        <dt>Balance:</dt><dd>{{customer.balance | stripeCurrency:customer.currency || 'usd'}}</dd>
      </dl>

    </mat-card-content>

    <mat-card-actions>

      <ng-container *ngTemplateOutlet="actions; context: { customer }"></ng-container>

    </mat-card-actions>

  </mat-card>

</div>
