import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonthComponent } from './month/month.component';
import { WeekModule } from '../week/week.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DayModule } from '../day/day.module';
import { NavigationComponent } from './navigation/navigation.component';

@NgModule({
  declarations: [MonthComponent, NavigationComponent],
  exports: [MonthComponent],
  imports: [
    CommonModule,
    DayModule,
    MatButtonModule,
    MatIconModule,
    WeekModule,
  ],
})
export class MonthModule {}
