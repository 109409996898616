import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { SeatService } from '../seat.service';
import { TrainingSeat } from '@bcx/models';

@Component({
  selector: 'ng-training-seat-smart-card',
  templateUrl: './smart-card.component.html',
  styleUrls: ['./smart-card.component.sass'],
})
export class SmartCardComponent implements OnChanges, OnDestroy {

  error?: string;

  loading: boolean;

  seat?: TrainingSeat;

  @Input() seat_id?: string;

  private subs: {[key: string]: Subscription} = {};

  constructor(private service: SeatService) {}

  fetchSeat() {

    if (this.seat_id) {

      return this.service.get({seat_id: this.seat_id})
      .catch((e) => {

        console.error(e);

      });

    } else {

      return Promise.reject(new Error('no seat_id'));

    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['seat_id']) {

      this.onSeatIdChange();

    }

  }

  ngOnDestroy(): void {
    
    Object.values(this.subs).forEach(sub => sub.unsubscribe());

  }

  private onSeatIdChange() {

    if (this.subs['seat']) {

      this.subs['seat'].unsubscribe();

    }

    if (this.seat_id) {

      

      this.fetchSeat();
  
    }

  }

}
