import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Currencies } from '@bcx/models';

@Component({
  selector: 'ng-billing-settings-select-currency',
  templateUrl: './select-currency.component.html',
  styleUrls: ['./select-currency.component.sass'],
})
export class SelectCurrencyComponent {

  @Input() control: FormControl;

  @Input() country: string;

  @Input() label = 'Select A Currency';

  currencies = Currencies;

}
