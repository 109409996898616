import { AnalyticsConfig, AuthConfig } from "@aws-amplify/core";
import { PinpointProviderConfig } from "@aws-amplify/core/dist/esm/providers/pinpoint/types";
import { APIConfig, APIRestConfig, RESTProviderConfig } from "@aws-amplify/core/dist/esm/singleton/API/types";
import { Region } from "./region-map";

export interface AmplifyConfig {

  Analytics: AnalyticsConfig;

  API: AmplifyAPIConfig;

  Auth: AuthConfig;

}

export type AmplifyAPIConfig = APIConfig & {
  WS?: WSProviderConfig;
};

export interface AppConfigMap {

  defaultRegion: Region;

  regionalConfigs: {
    [region: string]: AppRegionalConfig;
  };

  regions: Array<Region>;

}

export interface AppRegionalConfig {

  amplify: AmplifyConfig;

  stripe: StripeConfig;

}

export interface StripeConfig {

  publicKey: string;

}

export type APIWSConfig = APIRestConfig;

export interface WSProviderConfig {

  [name: string]: APIWSConfig

}
