import { isBoolean, merge } from "lodash";
import { CANCELED, CONFIRMED, DRAFT, FINISHED, IN_PERSON, ONLINE, PAST, REQUESTED, SCHEDULED, STARTED, TRAINING_CLASS } from "../common";
import { ModelAbstract, ModelAction, ModelEvent, ModelInterface } from "../model-action";
import { TrainingCourse } from "./courses";
import { List } from "@digitaltoolbuilders/model-helpers";
import { ConsistentRead, ExclusiveStartKey, Limit } from "../helpers";
import { v4 } from "uuid";
import Bluebird from "bluebird";

export class TrainingClass extends ModelAbstract implements TrainingClassInterface {

  actual?: TrainingClassTiming;

  cancellation?: TrainingClassCancellation;

  class_id: string;

  class_type: TrainingClassType;

  course: TrainingCourse;

  course_class_number: number;

  did_not_complete: boolean;

  google: TrainingClassGoogle;

  last_page_number?: number;

  last_paragraph?: string;

  last_slide_number?: number;

  scheduled: TrainingClassTiming;

  status: TrainingClassStatus;

  status_epoch: number;
  
  constructor(input: Partial<TrainingClass>) {

    super(input);

    if (input.actual) this.actual = input.actual;
    if (input.cancellation) this.cancellation = input.cancellation;
    if (input.class_id) this.class_id = input.class_id;
    if (input.class_type) this.class_type = input.class_type;
    if (input.course) this.course = input.course;
    if (input.course_class_number) this.course_class_number = input.course_class_number;
    if (input.created_epoch) this.created_epoch = input.created_epoch;
    if (input.did_not_complete) this.did_not_complete = input.did_not_complete;
    if (input.google) this.google = input.google;
    if (input.last_paragraph) this.last_paragraph = input.last_paragraph;
    if (input.last_page_number) this.last_page_number = input.last_page_number;
    if (input.last_slide_number) this.last_slide_number = input.last_slide_number;
    if (input.scheduled) this.scheduled = input.scheduled;
    if (input.status) this.status = input.status;
    if (input.status_epoch) this.status_epoch = input.status_epoch;

  }

  static override generateModelId(input: Pick<TrainingClass, 'class_id'>) {

    return `${input.class_id}`;

  }

}

export const TrainingClassActionNameMap = {
  CANCEL: `${TRAINING_CLASS}/cancel`,
  CONFIRM: `${TRAINING_CLASS}/confirm`,
  CREATE: `${TRAINING_CLASS}/create`,
  FINISH: `${TRAINING_CLASS}/finish`,
  GET: `${TRAINING_CLASS}/get`,
  LIST: `${TRAINING_CLASS}/list`,
  REQUEST: `${TRAINING_CLASS}/request`,
  START: `${TRAINING_CLASS}/start`,
  UPDATE: `${TRAINING_CLASS}/update`,
} as const;

export type TrainingClassActionName = typeof TrainingClassActionNameMap[keyof typeof TrainingClassActionNameMap];

export const TrainingClassActionNames = Object.values(TrainingClassActionNameMap);

export class TrainingClassCancelAction extends ModelAction<TrainingClassCancelPayload> {

  readonly action_name = TrainingClassActionNameMap.CANCEL;

  constructor(input: Partial<Omit<TrainingClassCancelAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingClassCanceledEvent extends ModelEvent<TrainingClass> {

  readonly event_name = TrainingClassEventNameMap.CANCELED;

  constructor(input: Partial<Omit<TrainingClassCanceledEvent, 'event_name'>>) {

    super(input);

  }

}

export interface TrainingClassCancellation {

  action_id: string;

  epoch: number;

  reason: string;

  user_id: string;

}

export type TrainingClassCancelPayload = Pick<TrainingClass, 'class_id' | 'last_updated_epoch'>
  & {
    canceled_epoch: number;
    reason: string;
  };

export class TrainingClassConfirmAction extends ModelAction<TrainingClassConfirmPayload> {

  readonly action_name = TrainingClassActionNameMap.CONFIRM;

  constructor(input: Partial<Omit<TrainingClassConfirmAction, 'action_name'>>) {

    super(input);

  }

}

export interface TrainingClassConfirmation {

  action_id: string;

  epoch: number;

  reason: string;

  user_id: string;

}

export class TrainingClassConfirmedEvent extends ModelEvent<TrainingClass> {

  readonly event_name = TrainingClassEventNameMap.CONFIRMED;

  constructor(input: Partial<Omit<TrainingClassConfirmedEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingClassConfirmPayload = Pick<TrainingClass, 'class_id' | 'last_updated_epoch'>
  & {
    confirmed_epoch: number;
    reason?: string;
  };

export class TrainingClassCreateAction extends ModelAction<TrainingClassCreatePayload> {

  readonly action_name = TrainingClassActionNameMap.CREATE;

  constructor(input: Partial<Omit<TrainingClassCreateAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingClassCreatedEvent extends ModelEvent<TrainingClass> {

  readonly event_name = TrainingClassEventNameMap.CREATED;

  constructor(input: Partial<Omit<TrainingClassCreatedEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingClassCreatePayload = Omit<TrainingClass, 'last_updated_epoch'>;

export const TrainingClassEventNameMap = {
  CANCELED: `${TRAINING_CLASS}:canceled`,
  CONFIRMED: `${TRAINING_CLASS}:confirmed`,
  CREATED: `${TRAINING_CLASS}:created`,
  FINISHED: `${TRAINING_CLASS}:finished`,
  LISTED: `${TRAINING_CLASS}:listed`,
  REQUESTED: `${TRAINING_CLASS}:requested`,
  STARTED: `${TRAINING_CLASS}:started`,
  UPDATED: `${TRAINING_CLASS}:updated`,
  VIEWED: `${TRAINING_CLASS}:viewed`,
} as const;

export class TrainingClassFinishAction extends ModelAction<TrainingClassFinishPayload> {

  readonly action_name = TrainingClassActionNameMap.FINISH;

  constructor(input: Partial<Omit<TrainingClassFinishAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingClassFinishedEvent extends ModelEvent<TrainingClass> {

  readonly event_name = TrainingClassEventNameMap.FINISHED;

  constructor(input: Partial<Omit<TrainingClassFinishedEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingClassFinishPayload = Pick<TrainingClass, 
  | 'class_id' 
  | 'last_updated_epoch'>
  & {
    finished_epoch: number;
  };

export class TrainingClassGetAction extends ModelAction<TrainingClassGetPayload> {

  readonly action_name = TrainingClassActionNameMap.GET;

  constructor(input: Partial<Omit<TrainingClassGetAction, 'action_name'>>) {

    super(input);

  }

}

export type TrainingClassGetPayload = Pick<TrainingClass, 'class_id'> & {
  consistent_read?: ConsistentRead;
};

export class TrainingClassListAction extends ModelAction<TrainingClassListPayload> {

  readonly action_name = TrainingClassActionNameMap.LIST;

  constructor(input: Partial<Omit<TrainingClassListAction, 'action_name'>>) {

    super(input);
    
  }

}

export class TrainingClassListedEvent extends ModelEvent<List<TrainingClass>> {

  readonly event_name = TrainingClassEventNameMap.LISTED;

  constructor(input: Partial<Omit<TrainingClassListedEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingClassListPayload = Partial<Pick<TrainingClass, 'course'>> & {
  after?: number;
  before?: number;
  exclusive_start_key?: ExclusiveStartKey;
  limit?: Limit;
};

export interface TrainingClassGoogle {

  calendar_event_link: string;

  meet_details: string;
    
  meet_link: string;

}

export interface TrainingClassInterface extends ModelInterface {

  actual?: TrainingClassTiming;

  cancellation?: TrainingClassCancellation;

  class_id: string;

  class_type: TrainingClassType;

  confirmation?: TrainingClassConfirmation;

  course: TrainingCourse;

  course_class_number: number;

  did_not_complete: boolean;

  finished_epoch?: number;

  google: TrainingClassGoogle;

  last_page_number?: number;

  last_paragraph?: string;

  last_slide_number?: number;

  scheduled: TrainingClassTiming;

  status: TrainingClassStatus;

  status_epoch: number;

}

export class TrainingClassModelService {

  create(action: TrainingClassCreateAction) {

    const model = new TrainingClass({
      ...action.payload,
      last_updated_epoch: action.epoch,
      model_id: TrainingClass.generateModelId(action.payload),
    });
  
    const event = new TrainingClassCreatedEvent({
      action_id: action.action_id,
      event_id: v4(),
      epoch: action.epoch,
      model_id: model.model_id,
      payload: model,
      user_id: action.user_id,
    });
  
    return {
      action,
      event,
      model,
    };
  
  }

  cancel(action: TrainingClassCancelAction, original: TrainingClass) {

    const model = new TrainingClass(merge({}, original, {
      ...action.payload,
      last_updated_epoch: action.epoch,
      status: TrainingClassStatusMap.CANCELED,
    }));
  
    const event = new TrainingClassCanceledEvent({
      action_id: action.action_id,
      event_id: v4(),
      epoch: action.epoch,
      model_id: model.model_id,
      payload: model,
      user_id: action.user_id,
    });
  
    return {
      action,
      event,
      model,
    };
  
  }

  confirm(action: TrainingClassConfirmAction, original: TrainingClass) {

    const model = new TrainingClass(merge({}, original, {
      ...action.payload,
      last_updated_epoch: action.epoch,
      status: TrainingClassStatusMap.CONFIRMED,
    }));
  
    const event = new TrainingClassConfirmedEvent({
      action_id: action.action_id,
      event_id: v4(),
      epoch: action.epoch,
      model_id: model.model_id,
      payload: model,
      user_id: action.user_id,
    });
  
    return {
      action,
      event,
      model,
    };
  
  }
    
  finish(action: TrainingClassFinishAction, original: TrainingClass) {

    const model = new TrainingClass(merge({}, original, {
      ...action.payload,
      last_updated_epoch: action.epoch,
      status: TrainingClassStatusMap.FINISHED,
    }));

    const event = new TrainingClassFinishedEvent({
      action_id: action.action_id,
      event_id: v4(),
      epoch: action.epoch,
      model_id: model.model_id,
      payload: model,
      user_id: action.user_id,
    });

    return {
      action,
      event,
      model,
    };

  }

  get(action: TrainingClassGetAction, model: TrainingClass) {

    return Bluebird.try(() => {

      const event = new TrainingClassViewedEvent({
        action_id: action.action_id,
        event_id: v4(),
        epoch: action.epoch,
        
      })

      return {
        action,
        event,
        model,
      };

    });

  }

  list(action: TrainingClassListAction, list: List<TrainingClass>) {

    return Bluebird.try(() => {

      const event = new TrainingClassListedEvent({
        action_id: action.action_id,
        event_id: v4(),
        epoch: action.epoch,
        payload: list,
        user_id: action.user_id,
      });

      return {
        action,
        event,
        list,
      };

    });

  }

  start(action: TrainingClassStartAction, original: TrainingClass) {

    const model = new TrainingClass(merge({}, original, {
      ...action.payload,
      last_updated_epoch: action.epoch,
      status: TrainingClassStatusMap.STARTED,
    }));

    const event = new TrainingClassStartedEvent({
      action_id: action.action_id,
      event_id: v4(),
      epoch: action.epoch,
      model_id: model.model_id,
      payload: model,
      user_id: action.user_id,
    });

    return {
      action,
      event,
      model,
    };

  }

}

export class TrainingClassRequestAction extends ModelAction<TrainingClassRequestPayload> {

  readonly action_name = TrainingClassActionNameMap.GET;

  constructor(input: Partial<Omit<TrainingClassRequestAction, 'action_name'>>) {

    super(input);
    
  }

}

export class TrainingClassRequestedEvent extends ModelEvent<TrainingClass> {

  readonly event_name = TrainingClassEventNameMap.REQUESTED;

  constructor(input: Partial<Omit<TrainingClassRequestedEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingClassRequestPayload = Pick<TrainingClass, 'class_id'>;

export class TrainingClassStartAction extends ModelAction<TrainingClassStartPayload> {

  readonly action_name = TrainingClassActionNameMap.START;

  constructor(input: Partial<Omit<TrainingClassStartAction, 'action_name'>>) {

    super(input);
    
  }

}

export class TrainingClassStartedEvent extends ModelEvent<TrainingClass> {

  readonly event_name = TrainingClassEventNameMap.STARTED;

  constructor(input: Partial<Omit<TrainingClassStartedEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingClassStartPayload = Pick<TrainingClass, 'class_id' | 'last_updated_epoch'> & {
  started_epoch: number;
};

export const TrainingClassStatusMap = {
  CANCELED,
  CONFIRMED,
  DRAFT,
  FINISHED,
  PAST,
  REQUESTED,
  SCHEDULED,
  STARTED,
} as const;

export type TrainingClassStatus = typeof TrainingClassStatusMap[keyof typeof TrainingClassStatusMap];

export const TrainingClassStatuses = Object.values(TrainingClassStatusMap);

export interface TrainingClassTiming {

  finish_epoch?: number;

  length_minutes?: number;

  start_epoch: number;

}

export class TrainingClassUpdateAction extends ModelAction<TrainingClassUpdatePayload> {

  readonly action_name = TrainingClassActionNameMap.UPDATE;

  constructor(input: Partial<Omit<TrainingClassUpdateAction, 'action_name'>>) {

    super(input);
    
  }

}

export class TrainingClassUpdatedEvent extends ModelEvent<TrainingClass> {

  readonly event_name = TrainingClassEventNameMap.UPDATED;

  constructor(input: Partial<Omit<TrainingClassUpdatedEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingClassUpdatePayload = Pick<TrainingClass, 'class_id'>;

export const TrainingClassTypeMap = {
  IN_PERSON,
  ONLINE,
} as const;

export type TrainingClassType = typeof TrainingClassTypeMap[keyof typeof TrainingClassTypeMap];

export const TrainingClassTypes = Object.values(TrainingClassTypeMap);

export class TrainingClassViewedEvent extends ModelEvent<TrainingClass> {

  readonly event_name = TrainingClassEventNameMap.VIEWED;

  constructor(input: Partial<Omit<TrainingClassViewedEvent, 'event_name'>>) {

    super(input);

  }

}
