import { FormControl, FormGroup, Validators } from "@angular/forms";

export interface InitialQuestions {

  can_change_policy: boolean;

  has_small_business: boolean;

  interacts_with_customers: boolean;

  is_business_owner: boolean;

  is_team_leader: boolean;

}

export class InitialQuestionsForm extends FormGroup {

  constructor() {

    super({
      can_change_policy: new FormControl<boolean | undefined>(undefined, [Validators.required]),
      has_small_business: new FormControl<boolean | undefined>(undefined, [Validators.required]),
      interacts_with_customers: new FormControl<boolean | undefined>(undefined, [Validators.required]),
      is_business_owner: new FormControl<boolean | undefined>(undefined, [Validators.required]),
      is_team_leader: new FormControl<boolean | undefined>(undefined, [Validators.required]),
    });

  }

}
