import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeFirst'
})
export class CapitalizeFirstPipe implements PipeTransform {

  transform(value?: string): string {

    let result = '';

    if (value) {

      result = value.substring(0,1).toLocaleUpperCase() + value.substring(1);

    }

    return result;
  }

}
