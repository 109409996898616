import { FormControl, FormGroup, Validators } from "@angular/forms";

export class CancelForm extends FormGroup {

  constructor() {

    super({
      confirm: new FormControl(false, [Validators.requiredTrue]),
      reason: new FormControl('', [Validators.required]),
    });

  }

}
