import { Component, Inject } from '@angular/core';
import { SubsComponent } from '@bcx/ng-helpers';
import { FinishForm } from '../finish-form/finish-form.form';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SessionService } from '../session.service';
import { ConsultingSession } from '@bcx/models';

@Component({
  selector: 'ng-consulting-finish-dialog',
  templateUrl: './finish-dialog.component.html',
  styleUrls: ['./finish-dialog.component.sass'],
})
export class FinishDialogComponent extends SubsComponent {

  error?: string;

  form: FinishForm;

  processing: boolean;

  session: ConsultingSession;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ConsultingSession,
    private dialog: MatDialogRef<FinishDialogComponent>,
    private service: SessionService,
  ) {

    super();

    this.form = new FinishForm();

    this.session = data;

  }

  closeDialog() {

    this.dialog.close();

  }

  submit() {

    this.service.finish(this.session, {
      actual_finish_epoch: this.form.value.actual_finish_epoch
    })
    .then((started: ConsultingSession) => {

      this.dialog.close(started);

    })
    .catch((e: Error) => {

      console.error(e);

    });

  }

}
