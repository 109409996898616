import { TrainingSeatActionNameMap, TrainingSeatConfirmAction, TrainingSeatConfirmPayload, TrainingSeatGetAction, TrainingSeatGetPayload, TrainingSeatListAction, TrainingSeatListPayload, TrainingSeatReleaseAction, TrainingSeatReleasePayload, TrainingSeatRequestAction, TrainingSeatRequestPayload, TrainingSeatResendConfirmationAction, TrainingSeatSendConfirmationPayload } from "@bcx/models";
import Joi from "joi";
import { createActionSchema } from "../model-action";
import { consistent_read } from "../dynamo-db";
import { class_id } from "./class";
import { user_id } from "../common";

export const seat_id = Joi.string();

export const confirm = Joi.object<TrainingSeatConfirmPayload>()
  .keys({
    seat_id: seat_id.required(),
  });

export const confirmAction = createActionSchema<TrainingSeatConfirmAction>(
  TrainingSeatActionNameMap.CONFIRM, 
  confirm,
);

export const get = Joi.object<TrainingSeatGetPayload>()
  .keys({
    consistent_read: consistent_read,
    seat_id: seat_id.required(),
  });

export const getAction = createActionSchema<TrainingSeatGetAction>(
  TrainingSeatActionNameMap.GET, 
  get,
);

export const list = Joi.object<TrainingSeatListPayload>()
  .keys({
    class_id: class_id.optional().allow(null),
  });

export const listAction = createActionSchema<TrainingSeatListAction>(
  TrainingSeatActionNameMap.LIST, 
  get,
);

export const release = Joi.object<TrainingSeatReleasePayload>()
  .keys({
    seat_id: class_id.optional().allow(null),
  });

export const releaseAction = createActionSchema<TrainingSeatReleaseAction>(
  TrainingSeatActionNameMap.RELEASE, 
  release,
);

export const request = Joi.object<TrainingSeatRequestPayload>()
  .keys({
    class_id: class_id.required(),
    seat_id: seat_id.required(),
    user_id: user_id.required(),
  });

export const requestAction = createActionSchema<TrainingSeatRequestAction>(
  TrainingSeatActionNameMap.REQUEST, 
  request,
);

export const sendConfirmation = Joi.object<TrainingSeatSendConfirmationPayload>()
  .keys({
    seat_id: seat_id.required(),
  });

export const resendConfirmationAction = createActionSchema<TrainingSeatResendConfirmationAction>(
  TrainingSeatActionNameMap.RESEND_CONFIRMATION, 
  request,
);
