import { PersonContactPointDeleteAction, PersonContactPointDeletePayload, PersonContactPointGetAction, PersonContactPointGetPayload, PersonContactPointListAction, PersonContactPointListPayload, PersonContactPointUpdateAction, PersonContactPointUpdatePayload, PersonEmailAddressCreateAction, PersonEmailAddressCreatePayload } from "@bcx/models";
import Joi from "joi";

export const create = Joi.object<PersonEmailAddressCreatePayload>()
  .keys({});

export const createAction = Joi.object<PersonEmailAddressCreateAction>()
  .keys({});

export const del = Joi.object<PersonContactPointDeletePayload>()
  .keys({});

export const delAction = Joi.object<PersonContactPointDeleteAction>()
  .keys({});

export const get = Joi.object<PersonContactPointGetPayload>()
  .keys({});

export const getAction = Joi.object<PersonContactPointGetAction>()
  .keys({});

export const list = Joi.object<PersonContactPointListPayload>()
  .keys({});

export const listAction = Joi.object<PersonContactPointListAction>()
  .keys({});

export const update = Joi.object<PersonContactPointUpdatePayload>()
  .keys({});

export const updateAction = Joi.object<PersonContactPointUpdateAction>()
  .keys({});
