<div fxLayout="column">

  <table mat-table [dataSource]="table">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let seat">
        <ng-container *ngTemplateOutlet="actions; context: { seat }"></ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="class_id">
      <th mat-header-cell *matHeaderCellDef>Class Id</th>
      <td mat-cell *matCellDef="let seat">{{seat.class_id}}</td>
    </ng-container>
    <ng-container matColumnDef="last_updated_epoch">
      <th mat-header-cell *matHeaderCellDef>Last Updated Epoch</th>
      <td mat-cell *matCellDef="let seat">{{seat.last_updated_epoch}}</td>
    </ng-container>
    <ng-container matColumnDef="registration_id">
      <th mat-header-cell *matHeaderCellDef>Registration Id</th>
      <td mat-cell *matCellDef="let seat">{{seat.registration_id}}</td>
    </ng-container>
    <ng-container matColumnDef="seat_id">
      <th mat-header-cell *matHeaderCellDef>Seat Id</th>
      <td mat-cell *matCellDef="let seat">{{seat.seat_id}}</td>
    </ng-container>
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>State</th>
      <td mat-cell *matCellDef="let seat">{{seat.state}}</td>
    </ng-container>
    <ng-container matColumnDef="state_changes">
      <th mat-header-cell *matHeaderCellDef>State Changes</th>
      <td mat-cell *matCellDef="let seat">{{seat.state_changes}}</td>
    </ng-container>
    <ng-container matColumnDef="user_id">
      <th mat-header-cell *matHeaderCellDef>User Id</th>
      <td mat-cell *matCellDef="let seat">{{seat.user_id}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="display"></tr>
    <tr mat-row *matRowDef="let seat; columns: display"></tr>

    <tr class="mat-row" *matNoRowsDef><td [attr.colspan]="display.length">None</td></tr>
  </table>

</div>
