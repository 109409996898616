
export const CA = 'CA';
export const US = 'US';

export const CountryMap = {
  CA,
  US,
} as const;



export const Countries = Object.values(CountryMap);