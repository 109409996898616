import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Stripe } from 'stripe';
import { PaymentMethodService } from '../payment-method.service';

@Component({
  selector: 'ng-stripe-payment-method-select, ng-stripe-select-payment-method',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.sass'],
})
export class SelectComponent {

  @Input() control: FormControl;

  @Input() label = 'Select Payment Method';

  @Input() paymentMethods: Array<Stripe.PaymentMethod> = [];

  constructor(private service: PaymentMethodService) {}

  addPaymentMethod() {

    this.service.addDialog({
      return_url: `${location.pathname}${location.search}`,
    });

  }

  isSelected(paymentMethod: Stripe.PaymentMethod) {

    return this.control.value?.id === paymentMethod.id;

  }

  selectMethod(paymentMethod: Stripe.PaymentMethod) {

    this.control.patchValue(paymentMethod);

  }

}
