import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { addDays, startOfDay } from 'date-fns';
import { CreateForm } from './create-form.form';
import { SubsComponent } from '@bcx/ng-helpers';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ng-training-class-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.sass'],
})
export class CreateFormComponent extends SubsComponent implements OnChanges, OnDestroy {

  @Input() form: CreateForm;

  @Input() maxTime?: string;

  @Input() minDate = startOfDay(addDays(new Date(), 1));

  @Input() minTime?: string;

  startEpoch: FormControl;

  constructor() {

    super();

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['form'] && this.form) {

      this.startEpoch = this.form.get('start_epoch') as FormControl;

    }

  }

}
