import { Injectable } from '@angular/core';
import { PaymentAgreementServiceAdapter } from './payment-agreement.adapter';
import { WebSocketService } from '@engagedcx/ng-amplify';
import { TrainingPaymentAgreement, TrainingPaymentAgreementAgreeAction, TrainingPaymentAgreementAgreedEvent, TrainingPaymentAgreementCancelAction, TrainingPaymentAgreementCanceledEvent, TrainingPaymentAgreementCreateAction, TrainingPaymentAgreementCreatedEvent, TrainingPaymentAgreementGenerateSignedUrlAction, TrainingPaymentAgreementGetAction, TrainingPaymentAgreementInterface, TrainingPaymentAgreementListAction, TrainingPaymentAgreementListedEvent, TrainingPaymentAgreementSignedUrl, TrainingPaymentAgreementSignedUrlGeneratedEvent, TrainingPaymentAgreementUpdateAction, TrainingPaymentAgreementUpdatedEvent, TrainingPaymentAgreementViewedEvent } from '@bcx/models';
import { List } from '@digitaltoolbuilders/model-helpers';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements PaymentAgreementServiceAdapter {

  constructor(private websocket: WebSocketService) { }

  agree(action: TrainingPaymentAgreementAgreeAction): Promise<TrainingPaymentAgreementAgreedEvent> {
    
    return this.websocket.request<TrainingPaymentAgreementInterface>(action)
    .then((message) => {

      return new TrainingPaymentAgreementAgreedEvent({
        ...message,
        payload: new TrainingPaymentAgreement(message.payload),
      });

    });

  }

  cancel(action: TrainingPaymentAgreementCancelAction): Promise<TrainingPaymentAgreementCanceledEvent> {
    
    return this.websocket.request<TrainingPaymentAgreementInterface>(action)
    .then((message) => {

      return new TrainingPaymentAgreementCanceledEvent({
        ...message,
        payload: new TrainingPaymentAgreement(message.payload),
      });

    });

  }

  create(action: TrainingPaymentAgreementCreateAction): Promise<TrainingPaymentAgreementCreatedEvent> {
      
    return this.websocket.request<TrainingPaymentAgreementInterface>(action)
    .then((message) => {

      return new TrainingPaymentAgreementCreatedEvent({
        ...message,
        payload: new TrainingPaymentAgreement(message.payload),
      });

    });

  }

  generateSignedUrl(action: TrainingPaymentAgreementGenerateSignedUrlAction): Promise<TrainingPaymentAgreementSignedUrlGeneratedEvent> {
    
    return this.websocket.request<TrainingPaymentAgreementSignedUrl>(action)
    .then((message) => {

      return new TrainingPaymentAgreementSignedUrlGeneratedEvent({
        ...message,
      });

    });

  }

  get(action: TrainingPaymentAgreementGetAction): Promise<TrainingPaymentAgreementViewedEvent> {
    
    return this.websocket.request<TrainingPaymentAgreementInterface>(action)
    .then((message) => {

      return new TrainingPaymentAgreementViewedEvent({
        ...message,
        payload: new TrainingPaymentAgreement(message.payload),
      });

    });

  }

  list(action: TrainingPaymentAgreementListAction): Promise<TrainingPaymentAgreementListedEvent> {
    
    return this.websocket.request<List<TrainingPaymentAgreementInterface>>(action)
    .then((message) => {

      return new TrainingPaymentAgreementListedEvent({
        ...message,
        payload: new List<TrainingPaymentAgreement>({
          ...message.payload,
          list: message.payload.list.map((item) => new TrainingPaymentAgreement(item)),
        }),
      });

    });

  }

  update(action: TrainingPaymentAgreementUpdateAction): Promise<TrainingPaymentAgreementUpdatedEvent> {
    
    return this.websocket.request<TrainingPaymentAgreementInterface>(action)
    .then((message) => {

      return new TrainingPaymentAgreementUpdatedEvent({
        ...message,
        payload: new TrainingPaymentAgreement(message.payload),
      });

    });

  }

}
