import { Component, OnInit } from '@angular/core';
import { MySettingsForm } from '../my-settings-form/my-settings-form.form';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../user.service';
import { AuthService } from '@engagedcx/ng-amplify';
import { User } from '@bcx/models';
import { SubsComponent } from '@bcx/ng-helpers';

@Component({
  selector: 'ng-user-my-settings-dialog',
  templateUrl: './my-settings-dialog.component.html',
  styleUrls: ['./my-settings-dialog.component.sass'],
})
export class MySettingsDialogComponent  extends SubsComponent implements OnInit {

  error?: string;

  form = new MySettingsForm();

  loading = true;

  processing: boolean;

  user: User;

  constructor(
    private auth: AuthService,
    private dialog: MatDialogRef<MySettingsDialogComponent>,
    private service: UserService,
  ) {

    super();

  }

  closeDialog() {

    this.dialog.close();

  }

  ngOnInit(): void {
    
    this.auth.waitForIdentity()
    .then((authed) => {

      this.service.get({
        user_id: authed.user_id,
      })
      .then((user?: User) => {

        if (user) {

          this.user = user;

          this.form.patchValue(user);

        }

      })
      .catch((e: Error) => {

        console.error(e);

      })
      .finally(() => {

        this.loading = false;

      });
  
    })
    .catch((e: Error) => {

      this.error = e.message;

      this.loading = false;

    });

  }

  submit() {

    return this.service.update(this.user, this.form.value)
    .then((updated: User) => {

      this.dialog.close(updated);

    })
    .catch((e: Error) => {

      console.error(e);
      
    });

  }

}
