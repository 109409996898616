import { Component, Input, TemplateRef } from '@angular/core';
import { User } from '@bcx/models';

@Component({
  selector: 'ng-user-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.sass'],
})
export class CardComponent {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  @Input() user?: User;

}
