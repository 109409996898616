<div>

  <mat-accordion>

    <mat-expansion-panel
      *ngFor="let seat of seats">

      <mat-expansion-panel-header>

        <mat-panel-title>
          Class &num; 
          on {{seat.class_start_epoch | date:'MMM d @ h:mm a'}}
        </mat-panel-title>

        <mat-panel-description>
          Seat Id: {{seat.seat_id}}
        </mat-panel-description>

      </mat-expansion-panel-header>

      <mat-action-row>

        <ng-container *ngTemplateOutlet="actions; context: { seat }"></ng-container>

      </mat-action-row>

      <ng-template matExpansionPanelContent>

        <pre>{{seat | json}}</pre>

      </ng-template>

    </mat-expansion-panel>

  </mat-accordion>

</div>
