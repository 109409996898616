import { Injectable } from '@angular/core';
import { TicketServiceAdapter } from './ticket.service.adapter';
import { WebSocketService } from '@engagedcx/ng-amplify';
import { TrainingTicket, TrainingTicketCancelAction, TrainingTicketCanceledEvent, TrainingTicketGetAction, TrainingTicketInterface, TrainingTicketListAction, TrainingTicketListedEvent, TrainingTicketRedeemAction, TrainingTicketRedeemedEvent, TrainingTicketRevokeAction, TrainingTicketRevokedEvent, TrainingTicketSuspendAction, TrainingTicketSuspendedEvent, TrainingTicketViewedEvent } from '@bcx/models';
import { List } from '@digitaltoolbuilders/model-helpers';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements TicketServiceAdapter {

  constructor(private websocket: WebSocketService) { }

  cancel(action: TrainingTicketCancelAction): Promise<TrainingTicketCanceledEvent> {
    
    return this.websocket.request<TrainingTicketInterface>(action)
    .then((message) => {

      return new TrainingTicketCanceledEvent({
        ...message,
        payload: new TrainingTicket(message.payload),
      });

    });

  }

  get(action: TrainingTicketGetAction): Promise<TrainingTicketViewedEvent> {
      
    return this.websocket.request<TrainingTicketInterface>(action)
    .then((message) => {

      return new TrainingTicketViewedEvent({
        ...message,
        payload: new TrainingTicket(message.payload),
      });

    });

  }

  list(action: TrainingTicketListAction): Promise<TrainingTicketListedEvent> {
      
    return this.websocket.request<List<TrainingTicketInterface>>(action)
    .then((message) => {

      return new TrainingTicketListedEvent({
        ...message,
        payload: new List<TrainingTicket>({
          ...message.payload,
          list: message.payload.list.map((item) => new TrainingTicket(item)),
        }),
      });

    });

  }

  redeem(action: TrainingTicketRedeemAction): Promise<TrainingTicketRedeemedEvent> {
      
    return this.websocket.request<TrainingTicketInterface>(action)
    .then((message) => {

      return new TrainingTicketRedeemedEvent({
        ...message,
        payload: new TrainingTicket(message.payload),
      });

    });

  }

  revoke(action: TrainingTicketRevokeAction): Promise<TrainingTicketRevokedEvent> {
      
    return this.websocket.request<TrainingTicketInterface>(action)
    .then((message) => {

      return new TrainingTicketRevokedEvent({
        ...message,
        payload: new TrainingTicket(message.payload),
      });

    });

  }

  suspend(action: TrainingTicketSuspendAction): Promise<TrainingTicketSuspendedEvent> {
      
    return this.websocket.request<TrainingTicketInterface>(action)
    .then((message) => {

      return new TrainingTicketSuspendedEvent({
        ...message,
        payload: new TrainingTicket(message.payload),
      });

    });

  }

}
