<div class="ng-calendar-week" fxLayout="column">

  <div class="ng-calendar-wrapper" fxLayout="column" fxLayoutAlign="start stretch"
    fxLayout.gt-sm="row">

    <ng-calendar-day fxFlex
      *ngFor="let day of week.days"
      [day]="day"
      [eventClick]="eventClick"
      [linkTemplate]="linkTemplate"
      [showMonthName]="showMonthName"></ng-calendar-day>

  </div>

</div>
