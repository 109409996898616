
export const CONSULTING_BILL_SESSION_STATE_MACHINE_PARAM_NAME = '/consulting/bill-session/StateMachineArn';

export const CONSULTING_SESSION_CANCELED_STATE_MACHINE_ARN_PARAM_NAME = '/consulting/canceled-session/StateMachineArn';

export const CONSULTING_SESSION_CONFIRMED_STATE_MACHINE_ARN_PARAM_NAME = '/consulting/confirmed-session/StateMachineArn';

export const CONSULTING_SESSION_FINISHED_STATE_MACHINE_ARN_PARAM_NAME = '/consulting/finished-session/StateMachineArn';

export const CONSULTING_SESSION_REQUESTED_STATE_MACHINE_ARN_PARAM_NAME = '/consulting/requested-session/StateMachineArn';

export const CONSULTING_SESSION_STARTED_STATE_MACHINE_ARN_PARAM_NAME = '/consulting/started-session/StateMachineArn';
