import { Injectable } from '@angular/core';
import { AttendeeServiceAdapter } from './attendee.service.adapter';
import { CONSULTING_SESSION_ATTENDEE, ConsultingSessionAttendee, ConsultingSessionAttendeeAddAction, ConsultingSessionAttendeeAddedEvent, ConsultingSessionAttendeeArriveAction, ConsultingSessionAttendeeArrivedEvent, ConsultingSessionAttendeeCancelAction, ConsultingSessionAttendeeCanceledEvent, ConsultingSessionAttendeeConfirmAction, ConsultingSessionAttendeeConfirmedEvent, ConsultingSessionAttendeeGetAction, ConsultingSessionAttendeeInterface, ConsultingSessionAttendeeLeaveAction, ConsultingSessionAttendeeLeftEvent, ConsultingSessionAttendeeListAction, ConsultingSessionAttendeeListedEvent, ConsultingSessionAttendeeRemoveAction, ConsultingSessionAttendeeRemovedEvent, ConsultingSessionAttendeeViewedEvent, RestServerResponse } from '@bcx/models';
import { ApiService } from '@engagedcx/ng-amplify';
import { List } from '@digitaltoolbuilders/model-helpers';

@Injectable({
  providedIn: 'root'
})
export class RestService implements AttendeeServiceAdapter {

  private path = `/${CONSULTING_SESSION_ATTENDEE}`;

  constructor(private rest: ApiService) { }

  add(action: ConsultingSessionAttendeeAddAction): Promise<ConsultingSessionAttendeeAddedEvent> {
    
    return this.rest.post<RestServerResponse<ConsultingSessionAttendeeInterface>>(this.path, action)
    .then((response) => {

      return new ConsultingSessionAttendeeAddedEvent({
        ...response.data,
        payload: new ConsultingSessionAttendee(response.data.payload),
      });

    });

  }

  arrive(action: ConsultingSessionAttendeeArriveAction): Promise<ConsultingSessionAttendeeArrivedEvent> {
      
    return this.rest.post<RestServerResponse<ConsultingSessionAttendeeInterface>>(this.path, action)
    .then((response) => {

      return new ConsultingSessionAttendeeArrivedEvent({
        ...response.data,
        payload: new ConsultingSessionAttendee(response.data.payload),
      });

    });

  }

  cancel(action: ConsultingSessionAttendeeCancelAction): Promise<ConsultingSessionAttendeeCanceledEvent> {
      
    return this.rest.post<RestServerResponse<ConsultingSessionAttendeeInterface>>(this.path, action)
    .then((response) => {

      return new ConsultingSessionAttendeeCanceledEvent({
        ...response.data,
        payload: new ConsultingSessionAttendee(response.data.payload),
      });

    });

  }

  confirm(action: ConsultingSessionAttendeeConfirmAction): Promise<ConsultingSessionAttendeeConfirmedEvent> {
      
    return this.rest.post<RestServerResponse<ConsultingSessionAttendeeInterface>>(this.path, action)
    .then((response) => {

      return new ConsultingSessionAttendeeConfirmedEvent({
        ...response.data,
        payload: new ConsultingSessionAttendee(response.data.payload),
      });

    });

  }

  get(action: ConsultingSessionAttendeeGetAction): Promise<ConsultingSessionAttendeeViewedEvent> {
      
    return this.rest.post<RestServerResponse<ConsultingSessionAttendeeInterface>>(this.path, action)
    .then((response) => {

      return new ConsultingSessionAttendeeViewedEvent({
        ...response.data,
        payload: new ConsultingSessionAttendee(response.data.payload),
      });

    });

  }

  leave(action: ConsultingSessionAttendeeLeaveAction): Promise<ConsultingSessionAttendeeLeftEvent> {
      
    return this.rest.post<RestServerResponse<ConsultingSessionAttendeeInterface>>(this.path, action)
    .then((response) => {

      return new ConsultingSessionAttendeeLeftEvent({
        ...response.data,
        payload: new ConsultingSessionAttendee(response.data.payload),
      });

    });

  }

  list(action: ConsultingSessionAttendeeListAction): Promise<ConsultingSessionAttendeeListedEvent> {
      
    return this.rest.post<RestServerResponse<List<ConsultingSessionAttendeeInterface>>>(this.path, action)
    .then((response) => {

      return new ConsultingSessionAttendeeListedEvent({
        ...response.data,
        payload: new List<ConsultingSessionAttendee>({
          ...response.data.payload,
          list: response.data.payload.list.map((item) => new ConsultingSessionAttendee(item)),
        }),
      });

    });

  }

  remove(action: ConsultingSessionAttendeeRemoveAction): Promise<ConsultingSessionAttendeeRemovedEvent> {
      
    return this.rest.post<RestServerResponse<ConsultingSessionAttendeeInterface>>(this.path, action)
    .then((response) => {

      return new ConsultingSessionAttendeeRemovedEvent({
        ...response.data,
        payload: new ConsultingSessionAttendee(response.data.payload),
      });

    });

  }

}
