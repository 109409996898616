import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { BusinessEmployeeCountGroups, BusinessOperationLengths, Industries } from '@bcx/models';
import { GetStartedService } from '../../get-started/get-started.service';
import { CoachQuestionsForm, CoachQuestionsMap } from './questions.form';
import { combineLatest, map, Observable, throttleTime } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ListBaseComponent } from '../../goal/list-base/list-base.component';

export interface ViewModel {

  progress: number;

  questions: CoachQuestionsMap;

}

@Component({
  selector: 'app-coach-questions',
  templateUrl: './questions.component.html',
  styleUrl: './questions.component.sass',
})
export class QuestionsComponent extends ListBaseComponent implements OnDestroy, OnInit {

  countGroups = BusinessEmployeeCountGroups;

  form = new CoachQuestionsForm();

  industry: FormControl;

  industries = Industries;

  lengthInBusiness: FormControl;

  lengthInIndustry: FormControl;

  lengthInPosition: FormControl;

  lengths = BusinessOperationLengths;

  numberOfEmployees: FormControl;

  numberOfTeamMembers: FormControl;

  @Output() override progress$: Observable<number>;

  questions$: Observable<CoachQuestionsMap>;

  vm$: Observable<ViewModel>;

  constructor(private service: GetStartedService) {

    super();

    this.industry = this.form.get('industry') as FormControl;
    this.lengthInIndustry = this.form.get('length_in_industry') as FormControl;
    this.lengthInBusiness = this.form.get('length_in_business') as FormControl;
    this.lengthInPosition = this.form.get('length_in_position') as FormControl;
    this.numberOfEmployees = this.form.get('number_of_employees') as FormControl;
    this.numberOfTeamMembers = this.form.get('number_of_team_members') as FormControl;

    const state = this.service.getCurrentState();

    this.form.patchValue(state.coach);

    this.questions$ = this.service.state$.pipe(map((state) => state.coach.questions));

    this.vm$ = combineLatest({
      questions: this.questions$,
      progress: this.progress$,
    });

    this.calculateProgress();

  }

  ngOnInit(): void {
    
    this.subs['progress'] = this.questions$
    .pipe(throttleTime(250))
    .subscribe(() => {

      this.calculateProgress();

    });

    this.subs['valueChanges'] = this.form.valueChanges
    .pipe(throttleTime(250))
    .subscribe((questions) => {

      this.service.save({
        coach: {
          questions,
        }
      });

    });

  }

}
