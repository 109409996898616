<mat-card *ngIf="!trainingClass">

  <mat-card-content>

    <mat-spinner *ngIf="loading"></mat-spinner>

    <span *ngIf="error">{{error}}</span>

  </mat-card-content>

</mat-card>
<mat-card *ngIf="trainingClass">

  <mat-card-header>

    <mat-card-title>
      <div fxLayout="row"
        fxLayout.gt-sm="column">

        <span>Training Class</span>
        <span fxFlex></span>
        <span>{{trainingClass.scheduled.start_epoch | date:'short'}}</span>

      </div>
    </mat-card-title>
    <mat-card-subtitle>
      
      <div fxLayout="column"
        fxLayout.gt-sm="row">

        <span>{{state}}</span>
        <span fxFlex></span>
        <span>
          <span *ngIf="isOwner">Owner </span>
          <span *ngIf="isProvider">Provider </span>
          Class &num;{{trainingClass.course_class_number}}
        </span>
        
      </div>

    </mat-card-subtitle>

  </mat-card-header>

  <mat-card-content>

    <dl><dt>Class Id:</dt><dd>{{trainingClass.class_id}}</dd></dl>
    <dl><dt>Last Updated:</dt><dd>{{trainingClass.last_updated_epoch | date:'short'}}</dd></dl>

    <pre *ngIf="showMore">{{trainingClass | json}}</pre>

  </mat-card-content>

  <mat-card-actions *ngIf="trainingClass">

    <ng-container *ngTemplateOutlet="actions; context: { trainingClass }"></ng-container>

    <button type="button" 
      (click)="showMore=!showMore"
      mat-icon-button>
      <mat-icon *ngIf="!showMore">more_vert</mat-icon>
      <mat-icon *ngIf="showMore">more_horiz</mat-icon>
    </button>

  </mat-card-actions>

</mat-card>
