<div fxLayout="column">

  <mat-form-field>

    <mat-label>{{labelDate}}</mat-label>
  
    <input
      [formControl]="internalDate"
      [matDatepicker]="datePicker"
      matInput>
  
    <mat-datepicker-toggle 
      matSuffix 
      [for]="datePicker"></mat-datepicker-toggle>
  
    <mat-datepicker #datePicker type="date">
  
      <mat-datepicker-actions>
  
        <button type="button"
          matDatepickerCancel
          mat-button>Cancel</button>
  
        <button type="button" 
          matDatepickerApply
          mat-raised-button>Apply</button>
  
      </mat-datepicker-actions>
  
    </mat-datepicker>
  
  </mat-form-field>

  <ng-picker-time-selector
    [control]="internalTime"
    [label]="labelTime"
    [max]="maxTime"
    [min]="minTime"></ng-picker-time-selector>

</div>
