import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Bluebird from 'bluebird';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { AuthedUser } from './common';

@Injectable({
  providedIn: 'root'
})
export class SignInSignOutService {

  constructor(private dialog: MatDialog) { }

  signIn() {

    return new Bluebird((resolve: (result?: AuthedUser) => void) => {

      const ref = this.dialog.open(SignInComponent, {

      });
  
      ref.afterClosed().subscribe(resolve);

    });

  }

  signOut() {

    return new Bluebird((resolve: (result?: boolean) => void) => {

      const ref = this.dialog.open(SignOutComponent, { });

      ref.afterClosed().subscribe(resolve);

    });

  }

}
