import { List } from "@digitaltoolbuilders/model-helpers";
import { ARCHIVED, COMPROMISED, CONFIRMED, FORCE_CHANGE_PASSWORD, RESET_REQUIRED, UNCONFIRMED, UNKNOWN, USER_COGNITO } from "../common";
import { ConsistentRead, ExclusiveStartKey, Limit } from "../helpers";
import { ModelAbstract, ModelAction, ModelEvent, ModelInterface } from "../model-action";

export class UserCognito extends ModelAbstract implements UserCognitoInterface {

  alias: string;

  last_login_epoch?: number;

  region: string;

  schema_version = '1.0';

  state: UserCognitoStatus;

  user_id: string;

  user_name: string;

  user_pool_id: string;

  constructor(input: Partial<UserCognito>) {

    super(input);

    if (input.alias) this.alias = input.alias;
    if (input.last_login_epoch) this.last_login_epoch = input.last_login_epoch;
    if (input.region) this.region = input.region;
    if (input.schema_version) this.schema_version = input.schema_version;
    if (input.state) this.state = input.state;
    if (input.user_id) this.user_id = input.user_id;
    if (input.user_name) this.user_name = input.user_name;
    if (input.user_pool_id) this.user_pool_id = input.user_pool_id;

  }

}

export const UserCognitoActionNameMap = {
  CREATE: `${USER_COGNITO}/create`,
  FIND: `${USER_COGNITO}/find`,
  GET: `${USER_COGNITO}/get`,
  LIST: `${USER_COGNITO}/list`,
  UPDATE: `${USER_COGNITO}/update`,
  VIEW: `${USER_COGNITO}/view`,
} as const;

export type UserCognitoActionName = typeof UserCognitoActionNameMap[keyof typeof UserCognitoActionNameMap];

export const UserCognitoActionNames = Object.values(UserCognitoActionNameMap);

export class UserCognitoCreateAction extends ModelAction<UserCognitoCreatePayload> {

  override readonly action_name = UserCognitoActionNameMap.CREATE;

  constructor(input: Partial<Omit<UserCognitoCreateAction, 'action_name'>>) {

    super(input);

  }

}

export type UserCognitoCreatePayload = Omit<UserCognito, 'last_updated_epoch'>;

export class UserCognitoCreatedEvent extends ModelEvent<UserCognito> {

  override readonly event_name = UserCognitoEventNameMap.CREATED;

  constructor(input: Partial<Omit<UserCognitoCreatedEvent, 'event_name'>>) {

    super(input);

  }

}

export const UserCognitoEventNameMap = {
  CREATED: `${USER_COGNITO}:created`,
  LISTED: `${USER_COGNITO}:listed`,
  VIEWED: `${USER_COGNITO}:viewed`,
} as const;

export class UserCognitoGetAction extends ModelAction<UserCognitoGetPayload> {

  override readonly action_name = UserCognitoActionNameMap.GET;

  constructor(input: Partial<Omit<UserCognitoGetAction, 'action_name'>>) {

    super(input);

  }

}

export type UserCognitoGetPayload = Pick<UserCognito, 'user_name' | 'user_pool_id'> & {
  consistent_read?: ConsistentRead;
};

export interface UserCognitoInterface extends ModelInterface {

  alias: string;

  last_login_epoch?: number;

  region: string;

  schema_version: string;

  state: UserCognitoStatus;

  user_id: string;

  user_name: string;

  user_pool_id: string;

}

export class UserCognitoListAction extends ModelAction<UserCognitoListPayload> {

  override readonly action_name = UserCognitoActionNameMap.LIST;

  constructor(input: Partial<Omit<UserCognitoListAction, 'action_name'>>) {

    super(input);

  }

}

export class UserCognitoListedEvent extends ModelEvent<List<UserCognito>> {

  override readonly event_name = UserCognitoEventNameMap.LISTED;

  constructor(input: Partial<Omit<UserCognitoListedEvent, 'event_name'>>) {

    super(input);

  }

}

export type UserCognitoListPayload = Partial<Pick<UserCognito, 'user_id' | 'user_pool_id'>> & {
  exclusive_start_key?: ExclusiveStartKey;
  limit?: Limit;
};

export const UserCognitoStatusMap = {
  ARCHIVED: ARCHIVED,
  COMPROMISED: COMPROMISED,
  CONFIRMED: CONFIRMED,
  FORCE_CHANGE_PASSWORD: FORCE_CHANGE_PASSWORD,
  RESET_REQUIRED: RESET_REQUIRED,
  UNCONFIRMED: UNCONFIRMED,
  UNKNOWN: UNKNOWN,
} as const;

export type UserCognitoStatus = typeof UserCognitoStatusMap[keyof typeof UserCognitoStatusMap];

export const UserCognitoStatuses = Object.values(UserCognitoStatusMap);

export class UserCognitoViewedEvent extends ModelEvent<UserCognito> {

  override readonly event_name = UserCognitoEventNameMap.VIEWED;

  constructor(input: Partial<Omit<UserCognitoViewedEvent, 'event_name'>>) {

    super(input);

  }

}
