import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FinishForm } from './finish-form';
import { FormControl, Validators } from '@angular/forms';
import { SubsComponent } from '@bcx/ng-helpers';

@Component({
  selector: 'ng-training-class-finish-form',
  templateUrl: './finish-form.component.html',
  styleUrls: ['./finish-form.component.sass'],
})
export class FinishFormComponent extends SubsComponent implements OnChanges {

  finishedContent: FormControl;

  finishedEpoch: FormControl;

  @Input() form: FinishForm;

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['form'] && this.form) {

      this.finishedContent = this.form.get('finished_content') as FormControl;
      this.finishedEpoch = this.form.get('finished_epoch') as FormControl;

      this.subscribeToFinishedContent();

    }

  }

  private subscribeToFinishedContent() {

    if (this.subs['finished_content']) {

      this.subs['finished_content'].unsubscribe();

    }

    this.subs['finished_content'] = this.finishedContent.valueChanges
    .subscribe((value: boolean) => {

      if (value) {

        this.form.get('last_page_number')?.addValidators(Validators.required);
        this.form.get('last_paragraph')?.addValidators(Validators.required);
        this.form.get('last_slide_number')?.addValidators(Validators.required);

      } else {

        this.form.get('last_page_number')?.removeValidators(Validators.required);
        this.form.get('last_paragraph')?.removeValidators(Validators.required);
        this.form.get('last_slide_number')?.removeValidators(Validators.required);

      }

    });

  }

}
