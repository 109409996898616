import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FinishForm } from './finish-form.form';

@Component({
  selector: 'ng-consulting-session-finish-form',
  templateUrl: './finish-form.component.html',
  styleUrls: ['./finish-form.component.sass'],
})
export class FinishFormComponent implements OnChanges {

  actualFinishControl: FormControl;

  @Input() form: FinishForm;

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['form'] && this.form) {

      this.actualFinishControl = this.form.get('actual_finish_epoch') as FormControl;

    }

  }

}
