import { CONSULTING_SESSION, ConsultingSessionCancelAction, ConsultingSessionCancelPayload, ConsultingSessionConfirmAction, ConsultingSessionConfirmPayload, ConsultingSessionCreateAction, ConsultingSessionCreatePayload, ConsultingSessionFinishAction, ConsultingSessionFinishPayload, ConsultingSessionGetAction, ConsultingSessionGetPayload, ConsultingSessionListAction, ConsultingSessionListPayload, ConsultingSessionRequestAction, ConsultingSessionRequestPayload, ConsultingSessionSetActualLengthAction, ConsultingSessionSetActualLengthPayload, ConsultingSessionStartAction, ConsultingSessionStartPayload } from "@bcx/models";
import Joi from "joi";
import { createActionSchema } from "../model-action";

export const session_id = Joi.string().max(200);

export const cancel = Joi.object<ConsultingSessionCancelPayload>()
  .keys({
    session_id: session_id.required(),
  });

export const cancelAction = createActionSchema<ConsultingSessionCancelAction>(CONSULTING_SESSION, cancel);

export const confirm = Joi.object<ConsultingSessionConfirmPayload>()
  .keys({
    session_id: session_id.required(),
  });

export const confirmAction = createActionSchema<ConsultingSessionConfirmAction>(CONSULTING_SESSION, confirm);

export const create = Joi.object<ConsultingSessionCreatePayload>()
  .keys({
    session_id: session_id.required(),
  });

export const createAction = createActionSchema<ConsultingSessionCreateAction>(CONSULTING_SESSION, create);

export const finish = Joi.object<ConsultingSessionFinishPayload>()
  .keys({
    session_id: session_id.required(),
  });

export const finishAction = createActionSchema<ConsultingSessionFinishAction>(CONSULTING_SESSION, finish);

export const get = Joi.object<ConsultingSessionGetPayload>()
  .keys({
    session_id: session_id.required(),
  });

export const getAction = createActionSchema<ConsultingSessionGetAction>(CONSULTING_SESSION, get);

export const list = Joi.object<ConsultingSessionListPayload>()
  .keys({
    
  });

export const listAction = createActionSchema<ConsultingSessionListAction>(CONSULTING_SESSION, list);

export const request = Joi.object<ConsultingSessionRequestPayload>()
  .keys({
    session_id: session_id.required(),
  });

export const requestAction = createActionSchema<ConsultingSessionRequestAction>(CONSULTING_SESSION, request);

export const setActualLength = Joi.object<ConsultingSessionSetActualLengthPayload>()
  .keys({
    session_id: session_id.required(),
  });

export const setActualLengthAction = createActionSchema<ConsultingSessionSetActualLengthAction>(CONSULTING_SESSION, setActualLength);

export const start = Joi.object<ConsultingSessionStartPayload>()
  .keys({
    session_id: session_id.required(),
  });

export const startAction = createActionSchema<ConsultingSessionStartAction>(CONSULTING_SESSION, start);
