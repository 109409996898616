<section class="question">

  <span>{{label}}</span>
  
  <mat-form-field>

    <mat-label>Industry</mat-label>
  
    <mat-select
      [formControl]="control"
      matInput>
  
      <mat-option 
        *ngFor="let industry of industries"
        [value]="industry">{{industry}}</mat-option>
  
    </mat-select>
  
    <mat-error>Please select an industry.</mat-error>
  
  </mat-form-field>

</section>