<div fxFlexFill fxLayout="column">

  <div fxLayout="row" fxLayoutAlign="center center">

    <span *ngIf="error">{{error}}</span>
    <mat-spinner *ngIf="loading || checking"></mat-spinner>

  </div>

  <div class="ng-training-payment-agreement-iframe-container"
    fxFlex>

    <iframe frameborder="0" height="100%" width="100%"
      *ngIf="safeUrl" 
      [src]="safeUrl" 
      (onload)="onIframeLoad()"></iframe>

  </div>

  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px"
    *ngIf="maxAttemptsReached">

    <span>There was an error, loading the agreement.</span>

    <button type="button" color="primary"
      *ngIf="url"
      (click)="tryAgain(url)"
      mat-raised-button>Try Again</button>

  </div>

</div>
