import { Injectable } from '@angular/core';
import { ContactPointServiceAdapter } from '../contact-point.service';
import { PersonContactPoint, PersonContactPointCreateAction, PersonContactPointCreatedEvent, PersonContactPointDeleteAction, PersonContactPointDeletedEvent, PersonContactPointGetAction, PersonContactPointInterface, PersonContactPointListAction, PersonContactPointListedEvent, PersonContactPointUpdateAction, PersonContactPointUpdatedEvent, PersonContactPointViewedEvent } from '@bcx/models';
import { WebSocketService } from '@engagedcx/ng-amplify';
import { List } from '@digitaltoolbuilders/model-helpers';

@Injectable({
  providedIn: 'root'
})
export class WebsocketServiceAdapter implements ContactPointServiceAdapter {

  constructor(private websocket: WebSocketService) { }

  create(clean: PersonContactPointCreateAction): Promise<PersonContactPointCreatedEvent> {
    
    return this.websocket.request<PersonContactPointInterface>(clean)
    .then((message) => {

      return new PersonContactPointCreatedEvent({
        ...message,
        payload: new PersonContactPoint(message.payload),
      });

    });

  }

  delete(action: PersonContactPointDeleteAction): Promise<PersonContactPointDeletedEvent> {
    
    return this.websocket.request<PersonContactPointInterface>(action)
    .then((message) => {

      return new PersonContactPointDeletedEvent({
        ...message,
        payload: new PersonContactPoint(message.payload),
      });

    });

  }

  get(clean: PersonContactPointGetAction): Promise<PersonContactPointViewedEvent> {
    
    return this.websocket.request<PersonContactPointInterface>(clean)
    .then((message) => {

      return new PersonContactPointViewedEvent({
        ...message,
        payload: new PersonContactPoint(message.payload),
      });

    });

  }

  list(clean: PersonContactPointListAction): Promise<PersonContactPointListedEvent> {
    
    return this.websocket.request<List<PersonContactPointInterface>>(clean)
    .then((message) => {

      return new PersonContactPointListedEvent({
        ...message,
        payload: {
          ...message.payload,
          list: message.payload.list.map((item) => {

            return new PersonContactPoint(item);

          }),
        },
      });

    });

  }

  update(clean: PersonContactPointUpdateAction): Promise<PersonContactPointUpdatedEvent> {
    
    return this.websocket.request<PersonContactPointInterface>(clean)
    .then((response) => {

      return new PersonContactPointUpdatedEvent({
        ...response,
        payload: new PersonContactPoint(response.payload),
      });

    });

  }

}
