<div class="ng-training-ticket-book-stub" fxLayout="column">

  <mat-card>

    <mat-card-header>

      <mat-card-title>

        <span>{{ticket.course | capitalizeFirst}}</span>

      </mat-card-title>

      <mat-card-subtitle>{{ticket.status | capitalizeFirst}} - &num;{{ticket.ticket_id}}</mat-card-subtitle>

    </mat-card-header>

    <mat-card-content>

      

    </mat-card-content>

    <mat-card-actions>

      <button mat-raised-button>View</button>

      <button mat-raised-button>Download</button>
  
    </mat-card-actions>

  </mat-card>

</div>
