import { Component, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, TemplateRef } from '@angular/core';
import { CalendarWeek } from '../../week/week';
import { BehaviorSubject, Observable, Subject, distinctUntilChanged, map } from 'rxjs';
import { addMonths, addWeeks, endOfMonth, format, isAfter, isBefore, startOfMonth, startOfWeek, subMonths } from 'date-fns';
import { CalendarDay, CalendarEvent } from '../../ng-calendar.module';
import { BeginsEnds } from '../../common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SubsComponent } from '@bcx/ng-helpers';

@Component({
  selector: 'ng-calendar-month',
  templateUrl: './month.component.html',
  styleUrls: ['./month.component.sass'],
})
export class MonthComponent extends SubsComponent implements OnChanges, OnInit {

  begins: Date;

  @Output() changed: Observable<BeginsEnds>;

  private changedSubject: BehaviorSubject<BeginsEnds>;

  ends: Date;

  @Output() eventClick = new Subject<CalendarEvent>()

  @Input() events: Array<CalendarEvent> = [];

  @Input() initial = new Date();

  isAfterMin = true;

  isBeforeMax = true;

  isGtSm = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() linkTemplate: TemplateRef<any>;

  @Input() max: Date;

  @Input() min: Date;

  month: number;

  monthName: string;

  private oldEvents: Array<CalendarEvent>;

  @Input() processing = false;

  weeks: Array<CalendarWeek> = [];

  year: number;

  constructor(private breakpoint: BreakpointObserver) {

    super();

    this.begins = startOfMonth(this.initial);

    this.ends = endOfMonth(this.initial);

    this.buildWeeks();

    this.changedSubject = new BehaviorSubject({
      begins: this.begins,
      ends: this.ends,
    });

    this.changed = this.changedSubject.asObservable();

  }

  private buildWeeks() {

    this.month = this.begins.getMonth() + 1;

    this.monthName = format(this.begins, 'MMMM');

    this.year = this.begins.getFullYear();

    const weeks: Array<CalendarWeek> = [];

    let iterator = startOfWeek(this.begins);

    let week: CalendarWeek;

    while(isBefore(iterator, this.ends)) {

      week = new CalendarWeek(iterator);

      weeks.push(week);

      iterator = addWeeks(iterator, 1);

    }

    this.weeks = weeks;

  }

  next() {

    this.begins = startOfMonth(addMonths(this.begins, 1));

    this.ends = endOfMonth(this.begins);

    this.onMaxMinChange();

    this.buildWeeks();

    this.changedSubject.next({
      begins: this.begins,
      ends: this.ends,
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['initial']) {

      this.onInitialChange();

    }

    if (changes['events']) {

      this.onEventsChange(changes['events']);

    }

    if (changes['max'] || changes['min']) {

      this.onMaxMinChange();

    }

  }

  ngOnInit(): void {
    
    this.subs['isGtSm'] = this.breakpoint.observe([
      Breakpoints.Medium,
    ])
    .pipe(map((results) => { return results.breakpoints[Breakpoints.Medium] }))
    .pipe(distinctUntilChanged())
    .subscribe((isGtSm: boolean) => {

      this.isGtSm = isGtSm;

    });

  }

  private onEventsChange(change: SimpleChange) {

    this.events = this.events || [];

    this.oldEvents = change.previousValue;

    // console.log('onEventsChange', this.events);

    this.weeks.forEach((week: CalendarWeek) => {

      week.days.forEach((day: CalendarDay) => {

        day.events = [];

      });

    });

    this.events
    .sort((a: CalendarEvent, b: CalendarEvent) => {

      if (isAfter(a.begins, b.begins)) {

        return 1;

      } else if (isBefore(a.begins, b.begins)) {

        return -1;

      } else {

        return 0;

      }

    })
    .forEach((event: CalendarEvent) => {

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const week = this.weeks.find((week: CalendarWeek<any>) => {

        return (isAfter(event.begins, week.begins) && isBefore(event.begins, week.ends));

      });

      if (week) {

        const day = week.days.find((day: CalendarDay) => {

          return isAfter(event.begins, day.begins) && isBefore(event.begins, day.ends);

        });

        if (day) {

          day.events.push(event);

        }

      }

    });

  }

  private onInitialChange() {

    this.begins = startOfMonth(this.initial);

    this.ends = endOfMonth(this.initial);

    this.buildWeeks();

    this.changedSubject.next({
      begins: this.begins,
      ends: this.ends,
    });

  }

  private onMaxMinChange() {

    if (this.max) {

      this.isBeforeMax = isBefore(this.ends, this.max);

    } else {

      this.isBeforeMax = true;

    }

    if (this.min) {

      this.isAfterMin = isAfter(this.begins, this.min);

    } else {

      this.isAfterMin = true;

    }

  }

  private onNavigationEvent(direction: string) {

    if (direction === 'previous') {

      this.previous();

    } else if (direction === 'next') {

      this.next();

    }

  }

  previous() {

    this.begins = startOfMonth(subMonths(this.begins, 1));

    this.ends = endOfMonth(this.begins);

    this.onMaxMinChange();

    this.buildWeeks();

    this.changedSubject.next({
      begins: this.begins,
      ends: this.ends,
    });

  }

}
