import { AnyPayload, CONDITIONAL, NEW_CONNECTION, NO_HANDLER } from "./common";
import { ModelAction, ModelEvent } from "./model-action";

export class WebSocketClientMessage<Payload = AnyPayload> implements ModelAction<Payload> {

  action_id: string;

  action_name: string;

  epoch: number;

  model_id: string;

  payload: Payload;

  user_id: string;

  constructor(input: Partial<WebSocketClientMessage<Payload>>) {

    if (input.action_id) this.action_id = input.action_id;
    if (input.action_name) this.action_name = input.action_name;
    if (input.epoch) this.epoch = input.epoch;
    if (input.model_id) this.model_id = input.model_id;
    if (input.payload) this.payload = input.payload;
    if (input.user_id) this.user_id = input.user_id;

  }

}

export class WebSocketServerMessage<Payload = AnyPayload> implements ModelEvent<Payload> {

  action_id?: string;

  epoch: number;

  event_id: string;

  event_name: string;

  model_id: string;

  payload: Payload;

  user_id: string;

  constructor(input: Partial<WebSocketServerMessage<Payload>>) {

    if (input.action_id) this.action_id = input.action_id;
    if (input.epoch) this.epoch = input.epoch;
    if (input.event_id) this.event_id = input.event_id;
    if (input.event_name) this.event_name = input.event_name;
    if (input.payload) this.payload = input.payload;
    if (input.user_id) this.user_id = input.user_id;

  }

}

export const WebSocketEventMap = {
  CONDITIONAL: CONDITIONAL,
  ERROR: 'error',
  NEW_CONNECTION: NEW_CONNECTION,
  NO_HANDLER: NO_HANDLER,
  NOT_ALLOWED: 'not-allowed',
  NOT_VALID: 'not-valid',
  ROUTE_NOT_FOUND: 'route-not-found',
} as const;

export type WebSocketEvent = typeof WebSocketEventMap[keyof typeof WebSocketEventMap];

export const WebSocketEvents = Object.values(WebSocketEventMap);
