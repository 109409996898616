<div class="ng-stripe-invoice-list-table">

  <table mat-table [dataSource]="table">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let invoice">
        <ng-container *ngTemplateOutlet="actions; context: { invoice }"></ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="collection_method">
      <th mat-header-cell *matHeaderCellDef>Collection Method</th>
      <td mat-cell *matCellDef="let invoice">{{invoice.collection_method}}</td>
    </ng-container>
    <ng-container matColumnDef="currency">
      <th mat-header-cell *matHeaderCellDef>Currency</th>
      <td mat-cell *matCellDef="let invoice">{{invoice.currency}}</td>
    </ng-container>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let invoice">{{invoice.id}}</td>
    </ng-container>
    <ng-container matColumnDef="metadata">
      <th mat-header-cell *matHeaderCellDef>MetaData</th>
      <td mat-cell *matCellDef="let invoice">{{invoice.metadata | json}}</td>
    </ng-container>
    <ng-container matColumnDef="period_end">
      <th mat-header-cell *matHeaderCellDef>Period End</th>
      <td mat-cell *matCellDef="let invoice">{{invoice.period_end}}</td>
    </ng-container>
    <ng-container matColumnDef="period_start">
      <th mat-header-cell *matHeaderCellDef>Period Start</th>
      <td mat-cell *matCellDef="let invoice">{{invoice.period_start}}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let invoice">{{invoice.status}}</td>
    </ng-container>
    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef>Total</th>
      <td mat-cell *matCellDef="let invoice">{{invoice.total | stripeCurrency:invoice.currency || 'usd'}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="display"></tr>
    <tr mat-row *matRowDef="let invoice; columns: display"></tr>

    <tr mat-row *matNoDataRow><td [attr.colspan]="display.length">No Invoices</td></tr>
  </table>

</div>
