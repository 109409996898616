import { AccountActionNameMap, AccountCreateAction, AccountCreatePayload, AccountGetAction, AccountGetPayload, AccountListAction, AccountListPayload, AccountStatuses, ModelAction } from "@bcx/models";
import Joi from "joi";
import { createActionSchema } from "../model-action";
import { consistent_read, exclusive_start_key, limit } from "../dynamo-db";

export const account_id = Joi.string().min(8).max(8).required();

export const display_name = Joi.string().max(200).optional().allow(null);

export const status = Joi.string().allow(...AccountStatuses);

export const create = Joi.object<AccountCreatePayload>()
  .keys({
    account_id: account_id.required(),
    display_name: display_name,
  });

export const createAction = createActionSchema<AccountCreateAction>(AccountActionNameMap.CREATE, create);

export const get = Joi.object<AccountGetPayload>()
  .keys({
    account_id: account_id.required(),
    consistent_read: consistent_read,
  });

export const getAction = createActionSchema<AccountGetAction>(AccountActionNameMap.GET, get);

export const list = Joi.object<AccountListPayload>()
  .keys({
    exclusive_start_key: exclusive_start_key,
    limit: limit,
    status: status.optional().allow(null),
  });

export const listAction = createActionSchema<AccountListAction>(AccountActionNameMap.LIST, list);
