import { Component, Input } from '@angular/core';
import { StubComponent } from '../stub/stub.component';
import { TrainingTicket } from '@bcx/models';

@Component({
  selector: 'ng-training-ticket-class-tearoff',
  templateUrl: './tearoff.component.html',
  styleUrls: ['./tearoff.component.sass'],
})
export class TearoffComponent extends StubComponent {

  @Input() override ticket: TrainingTicket;

}
