import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

export interface ComponentSubscriptions {

  [key: string]: Subscription;

}

export function unsubscribeAll(subs: ComponentSubscriptions) {

  Object.values(subs).forEach((sub) => sub.unsubscribe());

}

@Component({
  styles: [''],
  template: ''
})
export abstract class SubsComponent implements OnDestroy {

  protected subs: ComponentSubscriptions = {};

  ngOnDestroy(): void {

    unsubscribeAll(this.subs);

  }

}
