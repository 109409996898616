import { ChangeDetectionStrategy, Component, computed, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { GetStartedService } from '../get-started.service';
import { COACH, OWNER, PROVIDER } from '@bcx/models';
import { cloneDeep } from 'lodash';
import { GetStartedState } from '../get-started.state';
import { patchState, signalState } from '@ngrx/signals';

export interface InitialComponentState {
  currentStep: number;
  processing: boolean;
  show_business_step: boolean;
  show_more_details: boolean;
  show_provider_step: boolean;
  show_small_business_step: boolean;
  show_team_step: boolean;
}

const defaultState: InitialComponentState = {
  currentStep: 0,
  processing: false,
  show_business_step: false,
  show_more_details: false,
  show_provider_step: false,
  show_small_business_step: false,
  show_team_step: false,
};

export type ViewModel = GetStartedState & InitialComponentState;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-get-started-initial',
  templateUrl: './initial.component.html',
  styleUrl: './initial.component.sass',
})
export class InitialComponent implements OnDestroy {

  private state = signalState<InitialComponentState>(defaultState);

  $state = computed(() => {

    return {
      ...this.state(),
      ...this.service.$state(),
    };

  });

  private subs: {[key:string]: Subscription} = {};

  vm$: Observable<ViewModel>;

  constructor(
    private service: GetStartedService,
  ) {

    this.subs['state'] = this.service.state$.subscribe((state) => {

      this.onServiceStateChange(state);

    });

  }

  ngOnDestroy(): void {
    
    Object.values(this.subs).forEach((sub) => sub.unsubscribe());

  }

  private onServiceStateChange(service: GetStartedState) {

    patchState(this.state, (comp) => {

      const next = cloneDeep(comp);

      next.show_business_step = false;
      next.show_more_details = false;
      next.show_provider_step = false;
      next.show_small_business_step = false;
      next.show_team_step = false;
  
      next.show_small_business_step = service.has_small_business || false;
      next.show_team_step = service.is_team_leader || false;
      next.show_provider_step = service.interacts_with_customers || false;

      switch (service.role) {

        case COACH:
  
          next.show_team_step = true;
  
          break;
  
        case OWNER:
  
          next.show_business_step = true;
  
          break;
  
        case PROVIDER:
  
          next.show_provider_step = true;
  
          break;
  
      }

      return next;
    
    });

  }

  startOver() {

    this.service.reset();

    location.reload();

  }

}
