import { FormControl, FormGroup } from "@angular/forms";

export interface CoachQuestionsMap {

  industry?: string;

  length_in_business?: string;

  length_in_industry?: string;

  length_in_position?: string;

  number_of_employees?: string;

  number_of_team_members?: string;

}

export class CoachQuestionsForm extends FormGroup {

  constructor() {

    super({
      industry: new FormControl<string | undefined>(undefined, []),
      length_in_business: new FormControl<string | undefined>(undefined, []),
      length_in_industry: new FormControl<string | undefined>(undefined, []),
      length_in_position: new FormControl<string | undefined>(undefined, []),
      number_of_employees: new FormControl<string | undefined>(undefined, []),
      number_of_team_members: new FormControl<string | undefined>(undefined, []),
    });

  }

}
