import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepContentComponent } from './step-content/step-content.component';
import { StepLabelComponent } from './step-label/step-label.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { CardComponent } from './card/card.component';
import { RecommendationsModule } from '../../recommendations/recommendations.module';
import { StripePipesModule } from '@bcx/ng-models';

@NgModule({
  declarations: [
    StepContentComponent,
    StepLabelComponent,
    CardComponent,
    CardComponent,
  ],
  exports: [
    CardComponent,
    StepContentComponent,
    StepLabelComponent,
    CardComponent,
  ],
  imports: [
    CommonModule, 
    MatButtonModule, 
    MatCardModule, 
    RecommendationsModule,
    StripePipesModule,
  ],
})
export class TrainingModule {}
