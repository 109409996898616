import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ConfirmForm } from './confirm-form.form';
import { addMinutes, format } from 'date-fns';
import { stringify } from 'qs';
import { FormControl } from '@angular/forms';
import { SubsComponent } from '@bcx/ng-helpers';
import { filter, map } from 'rxjs';
import { TrainingClass } from '@bcx/models';

export const GOOGLE_DATE_FORMAT = 'YMMdd\'T\'HHmmss';
export const MEET_REGEX = /(https?:\/\/[^ ]*)/;

@Component({
  selector: 'ng-training-class-confirm-form',
  templateUrl: './confirm-form.component.html',
  styleUrls: ['./confirm-form.component.sass'],
})
export class ConfirmFormComponent extends SubsComponent implements OnChanges {

  @Input() form: ConfirmForm;

  googleMeetDetails: FormControl;

  @Input() trainingClass: TrainingClass;

  createGoogleCalendarEvent() {

    const role = `${this.trainingClass.course.substring(0,1).toUpperCase()}${this.trainingClass.course.substring(1)}`;

    const title = `${role} Training Class #${this.trainingClass.course_class_number}`;

    const dates = `${format(this.trainingClass.scheduled.start_epoch, GOOGLE_DATE_FORMAT)}/${format(addMinutes(this.trainingClass.scheduled.start_epoch, this.trainingClass.scheduled.length_minutes || 240), GOOGLE_DATE_FORMAT)}`;

    const details = `${role} Training Class #${this.trainingClass.course_class_number}`;

    const location = `${this.trainingClass.class_type}`;

    const query = {
      action: 'TEMPLATE',
      text: title,
      dates,
      details,
      location,
      crm: 'BUSY',
      sprop: `website:bestcxever.com`,
      trp: true,
      ctz: 'America/Denver',
    };

    window.open(`http://www.google.com/calendar/render?${stringify(query)}`, '_blank')?.focus();
  
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['form'] && this.form) {

      this.googleMeetDetails = this.form.get('google_meet_details') as FormControl;

      if (this.subs['meet']) {

        this.subs['meet'].unsubscribe();

      }

      this.subs['meet'] = this.googleMeetDetails.valueChanges
      .pipe(filter((meet: string | null): meet is string => {
  
        return typeof meet === 'string';
  
      }))
      .pipe((map((meet: string) => {

        const result = meet.match(MEET_REGEX);

        return (result) ? result[1] : null;

      })))
      .pipe(filter((link: string | null): link is string => {
  
        return typeof link === 'string';
  
      }))
      .subscribe((link: string) => {
  
        console.log('meet', link);

        this.form.patchValue({
          google_meet_link: link,
        });

      });
  
    }

  }

}
