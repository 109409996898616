import { COACH, OWNER, PROVIDER, SMALL_TO_BEST } from "../common";
import { Duration } from 'date-fns';

export interface TrainingCourseDetails {

  class_duration: Duration;

  number_of_classes: number;

}

export const TrainingCourseDetailsMap: {
  [course: string]: TrainingCourseDetails;
} = {};

TrainingCourseDetailsMap[COACH] = {
  class_duration: {
    hours: 4
  },
  number_of_classes: 1,
};

TrainingCourseDetailsMap[OWNER] = {
  class_duration: {
    hours: 4
  },
  number_of_classes: 3,
};

TrainingCourseDetailsMap[PROVIDER] = {
  class_duration: {
    hours: 4
  },
  number_of_classes: 1,
};

TrainingCourseDetailsMap[SMALL_TO_BEST] = {
  class_duration: {
    hours: 4
  },
  number_of_classes: 1,
};

export const TrainingCourseMap = {
  COACH,
  OWNER,
  PROVIDER,
  SMALL_TO_BEST,
} as const;

export type TrainingCourseKeys = keyof typeof TrainingCourseMap;

export type TrainingCourse = typeof TrainingCourseMap[TrainingCourseKeys];

export const TrainingCourses = Object.values(TrainingCourseMap);
