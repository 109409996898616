<div class="container" fxLayout="column">

  <h1 class="mat-headline-5">
    <span>Sign Up</span>
  </h1>

  <form fxLayout="column"
    [formGroup]="signUpForm"
    (ngSubmit)="signUp()">

    <mat-form-field>

      <mat-label>Your Name</mat-label>

      <input type="text"
        formControlName="name"
        matInput>

      <mat-error>Please enter your name.</mat-error>

    </mat-form-field>

    <ng-picker-intl-phone-number
      [control]="phoneNumberControl"></ng-picker-intl-phone-number>

    <ng-picker-timezone-selector
      [control]="localTimeZoneControl"
      label="Your Local Time Zone"></ng-picker-timezone-selector>

    <div fxLayout="row" fxLayoutAlign="space-between">

      <span></span>

      <mat-spinner *ngIf="processing" diameter="30"></mat-spinner>

      <button type="submit" color="primary"
        [disabled]="signUpForm.invalid || processing || otpSent"
        mat-raised-button>
        Sign Up
      </button>

    </div>

  </form>

  <mat-divider></mat-divider>

  <form fxLayout="column"
    *ngIf="otpSent"
    [formGroup]="verifyForm"
    (ngSubmit)="verify()">

    <mat-form-field>

      <mat-label>Enter The Code</mat-label>

      <input type="number"
        formControlName="code"
        matInput>

      <mat-error>Please enter the 6 digit code, we sent a text to your phone.</mat-error>

    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="space-between">

      <button type="button"
        (click)="resendCode()"
        [disabled]="processing"
        mat-raised-button>Resend Code</button>

      <mat-spinner *ngIf="processing" diameter="30"></mat-spinner>

      <button type="submit" color="primary"
        [disabled]="verifyForm.invalid || processing"
        mat-raised-button>Verify</button>

    </div>

  </form>

  <div class="already-signed-up" fxLayout="column" fxLayoutAlign="center center"
    *ngIf="alreadySignedUp">

    <div fxLayout="row" fxLayoutAlign="center">

      <p>You have already signed up. Please sign in.</p>

    </div>

    <div fxLayout="row" fxLayoutAlign="center">

      <button type="button" color="primary" mat-raised-button (click)="signIn()">Sign In</button>

    </div>

  </div>

</div>
