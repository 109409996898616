import { FormControl, FormGroup, Validators } from "@angular/forms";

export class FinishForm extends FormGroup {

  constructor() {

    super({
      finished_epoch: new FormControl(null, [Validators.required]),
      finished_content: new FormControl(false, [Validators.required]),
      last_page_number: new FormControl(null, []),
      last_paragraph: new FormControl(null, []),
      last_slide_number: new FormControl(null, []),
    });

  }

}
