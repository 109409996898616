<div class="ng-consulting-select-role" fxLayout="column"
  *ngIf="control">

  <mat-form-field>

    <mat-label>Select a Role</mat-label>

    <mat-select
      [formControl]="control"
      matInput>

      <mat-select-trigger style="width: 100%">

        <ng-consulting-session-role-option
          [role]="control.value"></ng-consulting-session-role-option>

      </mat-select-trigger>

      <mat-option 
        *ngFor="let role of categories"
        [value]="role">
      
        <ng-consulting-session-role-option
          [category]="category"
          [currency]="currency"
          [role]="role"
          [sessionType]="sessionType"></ng-consulting-session-role-option>

      </mat-option>

    </mat-select>

    <mat-error *ngIf="control.hasError('required')">Please select a role.</mat-error>

  </mat-form-field>

</div>
