import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RescheduleForm } from './reschedule-form.form';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ng-consulting-session-reschedule-form',
  templateUrl: './reschedule-form.component.html',
  styleUrls: ['./reschedule-form.component.sass'],
})
export class RescheduleFormComponent implements OnChanges {

  @Input() form: RescheduleForm;

  scheduledLengthMinutes: FormControl;

  scheduledStartEpoch: FormControl;

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['form'] && this.form) {

      this.scheduledLengthMinutes = this.form.get('scheduled_length_minutes') as FormControl;
      this.scheduledStartEpoch = this.form.get('scheduled_start_epoch') as FormControl;

    }

  }

}
