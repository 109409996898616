import { FormControl, FormGroup, Validators } from "@angular/forms";

export class FinishForm extends FormGroup {

  constructor() {

    super({
      actual_finish_epoch: new FormControl(null, [Validators.required]),
      reason: new FormControl(null, []),
    });

  }

}
