import { Injectable } from '@angular/core';
import { Currencies, OWNER, PROVIDER, USD } from '@bcx/models';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  currencies = Currencies;

  private currency = new BehaviorSubject(USD);

  currency$ = this.currency.asObservable()
  .pipe(distinctUntilChanged());

  private role = new BehaviorSubject<string>(OWNER);

  role$ = this.role.asObservable()
    .pipe(distinctUntilChanged());

  roles = [OWNER, PROVIDER];

  setCurrency(currency: string) {

    this.currency.next(currency);

  }

  setRole(role: string) {

    this.role.next(role);

  }

}
