import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BooksComponent } from './books/books.component';
import { TrainingComponent } from './training/training.component';
import { ConsultingComponent } from './consulting/consulting.component';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { CarouselModule } from '@coreui/angular';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [BooksComponent, TrainingComponent, ConsultingComponent],
  exports: [BooksComponent, TrainingComponent, ConsultingComponent],
  imports: [
    CarouselModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    RouterModule.forChild([]),
  ],
})
export class MarketingModule {}
