
import { createFeatureSelector, createSelector, } from '@ngrx/store';
import { Stripe } from 'stripe';
import { createEntityId, featureKey, getEntity, State } from './reducer';
import { AllEntitiesState } from '@bcx/ng-helpers';

export const selectState = createFeatureSelector<State>(
  featureKey
);

export const selectAll = createSelector(
  selectState,
  (state: State) => {

    const entities = Object.values(state.entities).filter((entity) => (entity.model) ? true : false);

    const all: AllEntitiesState<Stripe.Price, string> = {
      ...state.all,
      entities,
      models: entities.map((entity) => entity.model as Stripe.Price),
    };

    return all;

  }
);

export function selectPrice(id: string) {

  return createSelector(
    selectState,
    (state: State) => {
  
      const entityId = createEntityId(id);

      const entity = getEntity(entityId, state);

      return entity;
  
    },
  );

}
