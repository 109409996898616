import { FormControl, FormGroup, Validators } from "@angular/forms";

export class AddressForm extends FormGroup {

  constructor() {

    super({
      city: new FormControl<string | undefined>(undefined, [Validators.required]),
      country: new FormControl<string | undefined>(undefined, [Validators.required]),
      postal_code: new FormControl<string | undefined>(undefined, [Validators.required]),
      state: new FormControl<string | undefined>(undefined, [Validators.required]),
      street: new FormControl<string | undefined>(undefined, [Validators.required]),
      unit: new FormControl<string | undefined>(undefined, []),
    });

  }

}
