import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TrainingTicket } from '@bcx/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ng-training-ticket-list-card, ng-training-ticket-card-list',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.sass'],
})
export class ListCardComponent implements OnChanges {

  noTickets = true;

  @Input() tickets: Array<TrainingTicket>;

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['tickets']) {

      if (this.tickets && this.tickets.length) {

        this.noTickets = false;

      } else {

        this.noTickets = true;

      }

    }

  }

}
