import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { AnalyticsService } from './analytics.service';

@Directive({
  selector: '[recordClick]'
})
export class RecordClickDirective implements OnInit {

  @Input('recordClickAttrs') attrs?: any;

  @Input('recordClickName') name?: string;

  constructor(
    private ref: ElementRef,
    private service: AnalyticsService
  ) { }

  ngOnInit(): void {
    


  }

  @HostListener('click') onClick(event: any) {

    const html_attributes: any = {};

    for (let index = 0; index < this.ref.nativeElement.attributes.length; index++) {
      
      const element = this.ref.nativeElement.attributes[index];
      
      html_attributes[element.name] = element.value;

    }

    // this.service.record({
    //   name: this.name || 'click',
    //   attributes: {
    //     html_id: this.ref.nativeElement.id,
    //     html_innerText: this.ref.nativeElement.innerText,
    //     html_element: this.ref.nativeElement.localName,
    //     ...html_attributes,
    //     ...this.attrs || {},
    //     url: `${location.href}`
    //   }
    // });

  }

}
