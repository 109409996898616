import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {Stripe} from 'stripe';

@Component({
  selector: 'ng-stripe-invoice-list-table',
  templateUrl: './list-table.component.html',
  styleUrls: ['./list-table.component.sass'],
})
export class ListTableComponent implements OnChanges {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  @Input() invoices: Array<Stripe.Invoice> = [];

  @Input() display = [
    'id',
    'status',
    'total',
    'currency',
    'period_start',
    'period_end',
    'actions',
  ];

  table = new MatTableDataSource<Stripe.Invoice>([]);

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['invoices']) {

      this.table.data = this.invoices || [];

    }

  }

}

