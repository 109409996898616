import { List } from "@digitaltoolbuilders/model-helpers";
import { PERSON_ATTRIBUTE } from "../common";
import { ModelAction, ModelEvent, ModelInterface } from "../model-action";
import { ConsistentRead, ExclusiveStartKey, Limit } from "../helpers";

export class PersonAttribute implements PersonAttributeInterface {

  account_id: string;

  attr_key: string;

  attr_value: PersonAttributeValue;

  created_epoch: number;

  last_updated_epoch: number;

  model_deleted?: boolean;

  model_id: string;

  person_id: string;

  constructor(input: Partial<PersonAttribute>) {

    if (input.account_id) this.account_id = input.account_id;
    if (input.attr_key) this.attr_key = input.attr_key;
    if (input.attr_value) this.attr_value = input.attr_value;
    if (input.created_epoch) this.created_epoch = input.created_epoch;
    if (input.last_updated_epoch) this.last_updated_epoch = input.last_updated_epoch;
    if (input.model_deleted) this.model_deleted = input.model_deleted;
    if (input.model_id) this.model_id = input.model_id;
    if (input.person_id) this.person_id = input.person_id;

  }

}

export class PersonAttributeDeleteAction extends ModelAction<PersonAttributeDeletePayload> {

  override readonly action_name = PersonAttributeActionNameMap.DELETE;

  constructor(input: Partial<Omit<PersonAttributeDeleteAction, 'action_name'>>) {

    super(input);

  }

}

export type PersonAttributeDeletePayload = Omit<PersonAttribute, 'attr_value' | 'created_epoch'>;

export class PersonAttributeGetAction extends ModelAction<PersonAttributeGetPayload> {

  override readonly action_name = PersonAttributeActionNameMap.GET;

  constructor(input: Partial<Omit<PersonAttributeGetAction, 'action_name'>>) {

    super(input);

  }

}

export type PersonAttributeGetPayload = 
  & Pick<PersonAttribute, 'account_id' | 'attr_key' | 'person_id'> 
  & { consistent_read?: ConsistentRead };

export class PersonAttributeListAction extends ModelAction<PersonAttributeListPayload> {

  override readonly action_name = PersonAttributeActionNameMap.LIST;

  constructor(input: Partial<Omit<PersonAttributeListAction, 'action_name'>>) {

    super(input);

  }

}
  
export class PersonAttributeListedEvent extends ModelEvent<List<PersonAttribute>> {

  override readonly event_name = PersonAttributeEventNameMap.LISTED;

  constructor(input: Partial<Omit<PersonAttributeListedEvent, 'event_name'>>) {

    super(input);

  }

}

export type PersonAttributeListPayload = 
  & Pick<PersonAttribute, 'account_id' | 'person_id'>
  & Partial<Pick<PersonAttribute, 'attr_key'>>
  & {
    exclusive_start_key: ExclusiveStartKey,
    limit: Limit,
  };

export class PersonAttributeSaveAction extends ModelAction<PersonAttributeSavePayload> {

  override readonly action_name = PersonAttributeActionNameMap.SAVE;

  constructor(input: Partial<Omit<PersonAttributeSaveAction, 'action_name'>>) {

    super(input);

  }

}

export type PersonAttributeSavePayload = 
  & Omit<PersonAttribute, 'created_epoch' | 'last_updated_epoch'> 
  & Partial<Pick<PersonAttribute, 'created_epoch' | 'last_updated_epoch'>>;

export const PersonAttributeActionNameMap = {
  DELETE: `${PERSON_ATTRIBUTE}/delete`,
  GET: `${PERSON_ATTRIBUTE}/get`,
  LIST: `${PERSON_ATTRIBUTE}/list`,
  SAVE: `${PERSON_ATTRIBUTE}/save`,
} as const;

export type PersonAttributeActionName = typeof PersonAttributeActionNameMap[keyof typeof PersonAttributeActionNameMap];

export const PersonAttributeActionNames = Object.values(PersonAttributeActionNameMap);

export class PersonAttributeDeletedEvent extends ModelEvent<PersonAttribute> {

  override readonly event_name = PersonAttributeEventNameMap.DELETED;

  constructor(input: Partial<Omit<PersonAttributeDeletedEvent, 'event_name'>>) {

    super(input);

  }

}

export class PersonAttributeSavedEvent extends ModelEvent<PersonAttribute> {

  override readonly event_name = PersonAttributeEventNameMap.SAVED;

  constructor(input: Partial<Omit<PersonAttributeSavedEvent, 'event_name'>>) {

    super(input);

  }

}

export const PersonAttributeEventNameMap = {
  CREATED: `${PERSON_ATTRIBUTE}:created`,
  DELETED: `${PERSON_ATTRIBUTE}:deleted`,
  LISTED: `${PERSON_ATTRIBUTE}:listed`,
  SAVED: `${PERSON_ATTRIBUTE}:saved`,
  VIEWED: `${PERSON_ATTRIBUTE}:viewed`,
} as const;

export type PersonAttributeEventName = typeof PersonAttributeEventNameMap[keyof typeof PersonAttributeEventNameMap];

export const PersonAttributeEventNames = Object.values(PersonAttributeEventNameMap);

export interface PersonAttributeInterface extends ModelInterface {

  account_id: string;

  attr_key: string;

  attr_value: PersonAttributeValue;

  person_id: string;

}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PersonAttributeValue = any;

export class PersonAttributeViewedEvent extends ModelEvent<PersonAttribute> {

  override readonly event_name = PersonAttributeEventNameMap.VIEWED;

  constructor(input: Partial<Omit<PersonAttributeViewedEvent, 'event_name'>>) {

    super(input);

  }

}
