import { Component, Input, TemplateRef } from '@angular/core';
import { OWNER, PROVIDER, TrainingTicket } from '@bcx/models';

@Component({
  selector: 'ng-training-ticket-book-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.sass'],
})
export class SummaryCardComponent {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  OWNER = OWNER;

  PROVIDER = PROVIDER;

  @Input() ticket: TrainingTicket;

}
