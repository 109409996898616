<div class="flex flex-col divide-y-2"
  [formGroup]="form">

  <app-question-select-industry
    [control]="industry"
    label="What industry is your business?"></app-question-select-industry>

  <app-question-length-of-time
    [control]="lengthInBusiness"
    label="How long have you been in business?"></app-question-length-of-time>

  <app-question-number-of-people
    [control]="numberOfEmployees"
    label="How many employees does your business have?"></app-question-number-of-people>

</div>
