import { InjectionToken } from "@angular/core";

export const DEFAULT_WEBSOCKET_NAME = new InjectionToken<string>('Default WebSocket Name');

export const DEFAULT_WEBSOCKET_REGION = new InjectionToken<string>('Default WebSocket Region', {
  factory: () => {

    return 'us-west-2';

  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BodyData = Array<any> | Record<string, any>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QueryParams = any;
