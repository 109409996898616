import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SubsComponent } from '@bcx/ng-helpers';
import { ClassService } from '../../../class/class.service';
import { SeatService } from '../../../seat/seat.service';
import { TicketService } from '../../../ticket/ticket.service';
import { TrainingClass, TrainingClassStatusMap, TrainingSeat, TrainingSeatStatusMap, TrainingTicket } from '@bcx/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ng-training-ticket-class-stub',
  templateUrl: './stub.component.html',
  styleUrls: ['./stub.component.sass'],
})
export class StubComponent extends SubsComponent implements OnChanges {

  ClassStatusMap = TrainingClassStatusMap;

  StatusMap = TrainingSeatStatusMap;

  seat?: TrainingSeat;

  seatError?: string;

  seatLoading: boolean;

  state: string;

  @Input() ticket: TrainingTicket;

  trainingClass?: TrainingClass;

  trainingClassError?: string;

  trainingClassLoading: boolean;

  constructor(
    private classService: ClassService,
    private seatService: SeatService,
    private service: TicketService,
  ) {

    super();

  }

  canCancel() {

    if (this.seat && this.canRelease()) {

      return this.seatService.isUpcoming(this.seat);

    }

    return false;

  }

  canRedeem() {

    return this.service.canRedeem(this.ticket);

  }

  canRelease() {

    if (this.seat) {

      return this.seatService.canRelease(this.seat);

    }

    return false;

  }

  canReschedule() {

    return this.canRelease();

  }

  determineState() {

    this.state = this.ticket.status;

    if (this.trainingClass && this.seat) {

      if (!this.seatService.isUpcoming(this.seat)) {

        this.state = 'Class Has Past';

        return;

      }

      switch (this.trainingClass.status) {

        case TrainingClassStatusMap.CANCELED:
            
          this.state = 'Class Is Canceled';

          break;

        case TrainingClassStatusMap.FINISHED:

          this.state = 'Class Is Finished';

          break;

        case TrainingClassStatusMap.REQUESTED:

          this.state = 'Class Is Requested';

          break;

        case TrainingClassStatusMap.STARTED:

          this.state = 'Class Has Started';

          break;

        case TrainingClassStatusMap.CONFIRMED:

          switch (this.seat.status) {

            case TrainingSeatStatusMap.CONFIRMED:

              this.state = 'Seat Is Confirmed';

              break;

            case TrainingSeatStatusMap.REQUESTED:

              this.state = 'Seat Is Requested';

              break;

          }

          break;

      }

    } else {

      this.state = this.ticket.status;

    }

  }

  fetchSeat(seat_id: string) {

    return this.seatService.get({seat_id});

  }

  fetchTrainingClass(class_id: string) {

    return this.classService.get({class_id});

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['ticket']) {

      this.onTicketChange();

    }

  }

  private onTicketChange() {

    if (this.subs['class']) {

      this.subs['class'].unsubscribe();

    }

    if (this.subs['class-seat']) {

      this.subs['class-seat'].unsubscribe();

    }

    if (this.subs['seat']) {

      this.subs['seat'].unsubscribe();

    }

    if (this.ticket) {

      if (this.ticket.class_id && this.ticket.class_seat_id) {

        

        this.fetchSeat(this.ticket.class_seat_id);

        this.fetchTrainingClass(this.ticket.class_id);

      } else {

        this.determineState();

      }

    }

  }

  redeem() {

    this.service.redeem(this.ticket, {});

  }

  release() {

    this.seatService.release(this.seat as TrainingSeat, {});

  }

  reschedule() {

    //

  }

}
