import { Component, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalendarEvent } from '../event';

export interface FocusDialogData { 

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: TemplateRef<any>; 

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: TemplateRef<any>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  event: CalendarEvent<any>;
}

@Component({
  selector: 'ng-calendar-focus-dialog',
  templateUrl: './focus-dialog.component.html',
  styleUrls: ['./focus-dialog.component.sass'],
})
export class FocusDialogComponent {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: TemplateRef<any>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: TemplateRef<any>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  event: CalendarEvent<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: FocusDialogData,
    public dialog: MatDialogRef<FocusDialogComponent>,
  ) {

    this.actions = data.actions;

    this.content = data.content;

    this.event = data.event;

  }

  closeDialog() {
    
    this.dialog.close();

  }



}
