import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { CardComponent } from './card/card.component';
import { ListComponent } from './list/list.component';
import { ListTableComponent } from './list-table/list-table.component';
import { SelectComponent } from './select/select.component';
import { StoreModule } from '@ngrx/store';
import { featureKey, reducer } from './state/payment-method.reducer';
import { AddDialogComponent } from './add-dialog/add-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LabelComponent } from './label/label.component';
import { PaymentMethodService } from './payment-method.service';
import { AddSuccessDialogComponent } from './add-success-dialog/add-success-dialog.component';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [
    CardComponent,
    ListComponent,
    ListTableComponent,
    SelectComponent,
    AddDialogComponent,
    LabelComponent,
    AddSuccessDialogComponent,
  ],
  exports: [
    CardComponent,
    ListComponent,
    ListTableComponent,
    SelectComponent,
    LabelComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTableModule,
    ReactiveFormsModule,
    StoreModule.forFeature(featureKey, reducer),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (service: PaymentMethodService) => {
        return () => {

          return service.init();

        };
      },
      deps: [PaymentMethodService],
    },
  ],
})
export class PaymentMethodModule {}
