<div class="flex flex-col">

  <div class="flex flex-col md:flex-row">

    <app-small-to-best-consulting-card>

      <a color="primary"
        routerLink="/consulting/schedule"
        mat-raised-button>Schedule A Meeting</a>

    </app-small-to-best-consulting-card>
  
    <app-recommendation-list [product_id]="product_id"></app-recommendation-list>
  
  </div>

</div>
