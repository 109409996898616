import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { NotSureComponent } from './not-sure/not-sure.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { InitialComponent } from './initial/initial.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CoachModule } from '../coach/coach.module';
import { ProviderModule } from '../provider/provider.module';
import { OwnerModule } from '../owner/owner.module';
import { RouterModule } from '@angular/router';
import { routes } from './get-started.routes';
import { MatDividerModule } from '@angular/material/divider';
import { InitialQuestionsComponent } from './initial-questions/initial-questions.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RecommendationsModule } from '../recommendations/recommendations.module';
import { MatBadgeModule } from '@angular/material/badge';
import { RecommendationsComponent } from './recommendations/recommendations.component';
import { RedirectComponent } from './redirect/redirect.component';
import { SmallToBestModule } from '../small-to-best/small-to-best.module';

@NgModule({
  declarations: [
    NotSureComponent,
    InitialComponent,
    InitialQuestionsComponent,
    RecommendationsComponent,
    RedirectComponent,
  ],
  imports: [
    CoachModule,
    CommonModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatTabsModule,
    OwnerModule,
    ProviderModule,
    ReactiveFormsModule,
    RecommendationsModule,
    RouterModule.forChild(routes),
    SmallToBestModule,
  ],
})
export class GetStartedModule {}
