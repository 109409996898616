<mat-card *ngIf="ticket">

  <mat-card-header>

    <mat-card-title>

      <ng-container [ngSwitch]="ticket.course">

        <span *ngSwitchCase="OWNER">Owner</span>
        <span *ngSwitchCase="PROVIDER">Provider</span>

      </ng-container>
      <span>&nbsp;Book #{{ticket.book_id}}</span>
      
    </mat-card-title>
    <mat-card-subtitle>{{ticket.status}}</mat-card-subtitle>

  </mat-card-header>

  <mat-card-content>

    

  </mat-card-content>

  <mat-card-actions> 

    <ng-container *ngTemplateOutlet="actions; context: { ticket }"></ng-container>

  </mat-card-actions>

</mat-card>
