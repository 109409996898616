import { Component, Input } from '@angular/core';
import { Address } from '@bcx/models';

@Component({
  selector: 'ng-billing-address-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.sass'],
})
export class CardComponent {

  @Input() address: Address;

}
