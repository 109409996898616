
export const AUTHENTICATED_ROLE_ARN_PARAM_NAME = '/auth/identity-pool/authenticated-role/Arn';

export const AUTHENTICATED_ROLE_NAME_PARAM_NAME = '/auth/identity-pool/authenticated-role/Name';

export const IDENTITY_POOL_ID_PARAM_NAME = '/auth/identity-pool/Id';

export const IDENTITY_POOL_REGION_PARAM_NAME = '/auth/identity-pool/Region';

export const POST_CONFIRMATION_STATE_MACHINE_ARN_ENV = 'POST_CONFIRMATION_STATE_MACHINE_ARN';

export const POST_CONFIRMATION_STATE_MACHINE_ARN_PARAM_NAME = '/auth/user-pool/post-confirmation/StateMachineArn';

export const SEND_OTP_STATE_MACHINE_ARN_ENV = 'SEND_OTP_STATE_MACHINE_ARN';

export const SEND_OTP_STATE_MACHINE_ARN_PARAM = '/auth/user-pool/send-otp/StateMachineArn';

export const SEND_OTP_TEMPLATE_NAME = 'AuthSendOTP';

export const UNAUTHENTICATED_ROLE_ARN_PARAM_NAME = '/auth/identity-pool/unauthenticated-role/Arn';

export const UNAUTHENTICATED_ROLE_NAME_PARAM_NAME = '/auth/identity-pool/unauthenticated-role/Name';

export const USER_POOL_ID_PARAM_NAME = '/auth/user-pool/Id';

export const USER_POOL_CLIENT_ID_PARAM_NAME = '/auth/user-pool/ClientId';
