
import { on } from '@ngrx/store';
import { createImmerReducer } from 'ngrx-immer/store';
import { loadAllCustomers, loadCustomer, loadMyCustomer, loadMyCustomerFailure, loadMyCustomerSuccess, updateCustomer, updateMyCustomer } from './customer.actions';
import { Stripe } from 'stripe';
import { STRIPE_CUSTOMER } from '@bcx/models';
import { Entities, Entity, InternalAllEntitiesState, InternalManyEntitiesState } from '@bcx/ng-helpers';

export const featureKey = STRIPE_CUSTOMER;

export interface MyCustomerState extends Entity<Stripe.Customer> {

  id?: string;

}

export interface State {

  all: InternalManyEntitiesState<Stripe.Customer, string>;

  entities: Entities<Stripe.Customer>;

  my?: string;

}

export const initialState: State = {
  all: {
    ids: [],
    loading: false,
  },
  entities: {},
};

export function createEntityId(id: string) {

  return `${id}`;

}

export function getEntity(id: string, state: State) {

  const entity: Entity<Stripe.Customer> = state.entities[id] || {
    loaded: false,
    loading: false,
    processing: false,
  };

  return entity;

}

export const reducer = createImmerReducer(
  initialState,

  on(loadMyCustomer, (state: State, ) => {

    const id = 'my';

    const entity = getEntity(id, state);

    entity.loading = true;

    state.entities[id] = entity;

    return state;

  }),
  on(loadMyCustomerFailure, loadMyCustomerSuccess, (state, { data, error }) => {

    const loaeded = Date.now();

    const id = state.my || 'my';

    const entity = getEntity(id, state);

    entity.error = error;

    state.entities[id] = entity;

    if (data) {

      const id = createEntityId(data.id);

      const entity = getEntity(id, state);

      entity.model = data;
      entity.loaded = loaeded;
      entity.loading = false;

      state.entities[id] = entity;

      state.my = id;

    }

    return state;

  }),
  on(loadCustomer, (state: State, { customer, error, id, loading }) => {

    const entityId = createEntityId(id);

    const entity = getEntity(id, state);

    entity.error = error;
    entity.loading = loading;

    if (customer) {

      entity.model = customer;
      entity.loaded = Date.now();

    }

    state.entities[entityId] = entity;

    return state;

  }),
  on(loadAllCustomers, (state: State, { data, error, last, loading, }) => {

    state.all.error = error;
    state.all.last = last;
    state.all.loading = loading;

    if (data) {

      const loaded = Date.now();
      state.all.loaded = loaded;

      data.data.forEach((customer: Stripe.Customer) => {

        const id = createEntityId(customer.id);

        const entity = getEntity(id, state);

        entity.model = customer;
        entity.loaded = loaded;

        state.entities[id] = entity;

      });

    }

    return state;

  }),
  on(updateCustomer, updateMyCustomer, (state: State, { data, error, id, processing, type }) => {

    const entityId = createEntityId(id);

    const entity = getEntity(id, state);

    entity.error = error;
    entity.processing = processing;

    if (data) {

      entity.loaded = Date.now();
      entity.model = data;

    }

    state.entities[entityId] = entity;

    if (type === updateMyCustomer.type) {

      const id = state.my || 'my';

      const entity = getEntity(id, state);

      if (data) {

        entity.loaded = Date.now();
        entity.model = data;

      }

      state.entities[id] = entity;

    }

    return state;

  }),

);
