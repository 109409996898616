import { computed, Injectable } from '@angular/core';
import { BESTCXEVER_ACCOUNT_ID, PersonContactPointTypeMap, PersonEmailAddressCreatePayload } from '@bcx/models';
import { ContactPointService } from '../contact-point.service';
import { personEmailAddress as schemas } from '@bcx/schemas';

const account_id = BESTCXEVER_ACCOUNT_ID;

const contact_point_type = PersonContactPointTypeMap.EMAIL_ADDRESS;

@Injectable({
  providedIn: 'root'
})
export class EmailAddressService {

  constructor(private service: ContactPointService) { }

  create(data: PersonEmailAddressCreatePayload, now = Date.now()) {

    return schemas.create.validateAsync(data, {})
    .then((clean) => {

      return this.service.create(clean, now);

    });

  }

  listForPerson(person_id: string) {

    return this.service.list({
      account_id,
      person_id,
    });

  }

  selectForPerson(person_id: string) {

    return computed(() => {

      const result = this.service.selectForPerson({
        account_id,
        person_id,
      })();

      const entities = result.entities.filter((entity) => {

        return entity.model?.contact_point_type === contact_point_type;

      });

      return {
        ...result,
        entities,
        ids: entities.map(({ model }) => {

          return model?.model_id;

        }),
        models: entities.map(({ model }) => {

          return model;

        }),
      };

    });

  }

}
