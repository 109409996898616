
import { Injectable } from '@angular/core';
import { PersonServiceAdapterInterface } from '../person.service';
import { Person, PersonInterface, PersonCreateAction, PersonCreatedEvent, PersonGetAction, PersonViewedEvent, PersonListAction, PersonMergeAction, PersonMergedEvent, PersonUpdateAction, PersonUpdatedEvent, PersonListedEvent } from '@bcx/models';
import { List } from '@digitaltoolbuilders/model-helpers';
import { WebSocketService } from '@engagedcx/ng-amplify';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements PersonServiceAdapterInterface {

  constructor(private websocket: WebSocketService) {}

  create(action: PersonCreateAction): Promise<PersonCreatedEvent> {
    
    return this.websocket.request<PersonInterface>(action)
    .then((message) => {

      return new PersonCreatedEvent({
        ...message,
        payload: new Person(message.payload),
      });

    });

  }

  get(action: PersonGetAction): Promise<PersonViewedEvent> {
    
    return this.websocket.request<PersonInterface>(action)
    .then((message) => {

      return new PersonViewedEvent({
        ...message,
        payload: new Person(message.payload),
      });

    });

  }

  list(action: PersonListAction): Promise<PersonListedEvent> {
      
    return this.websocket.request<List<PersonInterface>>(action)
    .then((message) => {

      return new PersonListedEvent({
        ...message,
        payload: new List<Person>({
          ...message.payload,
          list: message.payload.list.map((item) => new Person(item)),
        }),
      });

    });

  }

  merge(action: PersonMergeAction): Promise<PersonMergedEvent> {
    
    return this.websocket.request<PersonInterface>(action)
    .then((message) => {

      return new PersonMergedEvent({
        ...message,
        payload: new Person(message.payload),
      });

    });

  }

  update(action: PersonUpdateAction): Promise<PersonUpdatedEvent> {
    
    return this.websocket.request<PersonInterface>(action)
    .then((message) => {

      return new PersonUpdatedEvent({
        ...message,
        payload: new Person(message.payload),
      });

    });

  }

}