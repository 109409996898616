import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MySettingsForm } from './my-settings-form.form';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ng-user-my-settings-form',
  templateUrl: './my-settings-form.component.html',
  styleUrls: ['./my-settings-form.component.sass'],
})
export class MySettingsFormComponent implements OnChanges {

  @Input() form: MySettingsForm;

  localTimeZoneControl: FormControl;

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['form'] && this.form) {

      this.localTimeZoneControl = this.form.get('local_time_zone') as FormControl;

    }

  }

}
