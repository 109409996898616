import { Injectable } from '@angular/core';
import { ApiService, AuthService } from '@engagedcx/ng-amplify';
import { Store } from '@ngrx/store';
import { AxiosResponse } from 'axios';
import { props } from 'bluebird';
import { Observable } from 'rxjs';
import {Stripe} from 'stripe';
import { loadAllPrices, loadPrice, } from './state/actions';
import { State } from './state/reducer';
import { selectAll, selectPrice } from './state/selectors';
import { AllEntitiesState } from '@bcx/ng-helpers';
import { stripePrice as schemas } from '@bcx/schemas';
import { StripePriceListPayload } from '@bcx/models';

@Injectable({
  providedIn: 'root'
})
export class PriceService {

  all$: Observable<AllEntitiesState<Stripe.Price, string>>;

  basePath = '/stripe/product/prices';

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private store: Store<State>,
  ) { 

    this.all$ = this.store.select(selectAll);

  }

  get(id: string) {

    return props({
      clean: schemas.get.validateAsync({ id }, {}),
      creds: this.auth.waitForCredentials()
    })
    .then(({ clean }) => {

      const path = `${this.basePath}/${clean.id}`;

      this.store.dispatch(loadPrice({ 
        id: clean.id,
        loading: true,
       }));

      return this.api.get(path, clean)
      .then((response: AxiosResponse<Stripe.Price>) => {
  
        const price = response.data;
  
        this.store.dispatch(loadPrice({ price, id: clean.id, loading: false }));
  
        return price;
  
      })
      .catch((e: any) => {

        this.store.dispatch(loadPrice({ error: e.message, id: clean.id, loading: false }));

        throw e;

      });

    });

  }

  list(data: Partial<StripePriceListPayload>) {

    return props({
      clean: schemas.list.validateAsync(data, {}),
      creds: this.auth.waitForCredentials()
    })
    .then(({ clean }) => {

      const path = `${this.basePath}`;

      this.store.dispatch(loadAllPrices({
        loading: true,
      }));

      return this.api.get(path, clean)
      .then((response: AxiosResponse<Array<Stripe.Price>>) => {
  
        const price = response.data;
  
        this.store.dispatch(loadAllPrices({ data: price, loading: false }));
  
        return price;
  
      })
      .catch((e: any) => {

        this.store.dispatch(loadAllPrices({ error: e.message, loading: false }));

        throw e;

      });

    });

  }

  select(id: string) {

    return this.store.select(selectPrice(id));

  }

}
