<div class="flex flex-col justify-between sm:flex-row">

  <div class="flex">

    <mat-card class="button-card">

      <mat-card-header>

        <mat-card-title>Book</mat-card-title>

      </mat-card-header>

      <mat-card-content>

        Digital or Paperback

      </mat-card-content>

      <mat-card-actions>

        <a color="primary"
          mat-raised-button
          routerLink="book/purchase">Buy Now</a>

      </mat-card-actions>

    </mat-card>

  </div>

  <div class="flex">

    <mat-card class="button-card">

      <mat-card-header>

        <mat-card-title>Training</mat-card-title>

      </mat-card-header>

      <mat-card-content class="flex">

        <ul>

          <li>Digital or Paperback Book</li>

          <li>6 Hours of Training</li>

        </ul>

      </mat-card-content>

      <mat-card-actions>

        <a color="primary"
          mat-raised-button
          routerLink="/training/register">Register Now</a>

      </mat-card-actions>

    </mat-card>

  </div>

  <div class="flex">

    <mat-card class="button-card">

      <mat-card-header>

        <mat-card-title>Consulting</mat-card-title>

      </mat-card-header>

      <mat-card-content class="flex">

        <ul>

          <li>Digital or Paperback Book</li>
          
          <li>6 Hours of Training</li>

          <li>4 Hours of Consulting</li>

        </ul>

      </mat-card-content>

      <mat-card-actions>

        <a color="primary"
          mat-raised-button
          routerLink="/consulting/schedule-meeting">Schedule Meeting</a>

      </mat-card-actions>

    </mat-card>

  </div>

</div>