import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { SubsComponent } from '@bcx/ng-helpers';
import { ClassService } from '../../../class/class.service';
import { SeatService } from '../../../seat/seat.service';
import { OWNER, PROVIDER, TrainingClass, TrainingSeat, TrainingTicket } from '@bcx/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ng-training-ticket-class-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.sass'],
})
export class SummaryCardComponent extends SubsComponent implements OnChanges {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  OWNER = OWNER;

  PROVIDER = PROVIDER;

  seat?: TrainingSeat;

  seatError?: string;

  seatLoading: boolean;

  @Input() ticket: TrainingTicket;

  trainingClass?: TrainingClass;

  trainingClassError?: string;

  trainingClassLoading: boolean;

  constructor(
    private classService: ClassService,
    private seatService: SeatService,
  ) {

    super();

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['ticket']) {

      this.onTicketChange();

    }

  }

  private onTicketChange() {

    if (this.subs['class']) {

      this.subs['class'].unsubscribe();

    }

    if (this.subs['seat']) {

      this.subs['seat'].unsubscribe();

    }

    if (this.ticket) {

      if (this.ticket.class_id) {

        

        this.classService.get({class_id: this.ticket.class_id})
        .catch((e: Error) => {

          console.error(e);

        });

      }

      if (this.ticket.class_seat_id) {

        

        this.seatService.get({ seat_id: this.ticket.class_seat_id })
        .catch((e: Error) => {

          console.error(e);

        });

      }

    }

  }

}
