
import { ACL, Assertion } from '@digitaltoolbuilders/acl';

import { hasMetadataUserId, isAttendee, isOwn, isOwnPerson, isRequestedBy, notDraft } from './assertions';
import { AuthedUser } from './common';
import { BillingSettingsActionNames, ConsultingSessionActionNameMap, ConsultingSessionActionNames, ConsultingSessionAttendeeActionNameMap, ConsultingSessionAttendeeActionNames, PersonActionNameMap, PersonActionNames, PersonContactPointActionNames, StripeChargeActionNames, StripeCustomerActionNameMap, StripeCustomerActionNames, StripeInvoiceActionNameMap, StripeInvoiceActionNames, StripePaymentMethodActionNameMap, StripePriceActionNameMap, StripePriceActionNames, StripeProductActionNameMap, StripeProductActionNames, StripeSubscriptionActionNames, TrainingClassActionNameMap, TrainingClassActionNames, TrainingPaymentAgreementActionNameMap, TrainingPaymentAgreementActions, TrainingSeatActionNameMap, TrainingSeatActionNames, TrainingTicketActionNameMap, TrainingTicketActionNames, UserActionNameMap, UserActionNames } from '@bcx/models';

export const ADMIN = 'admin';
export const ANONYMOUS = 'anonymous';
export const TRAINER = 'trainer';
export const USER = 'user';

export const ROLES = [ADMIN, TRAINER, USER];

const acl = new ACL();

const assertIsOwn = new Assertion(isOwn, 'Not Own User');
const assertHasMetadataUserId = new Assertion(hasMetadataUserId, 'Not For User');
const assertNotDraft = new Assertion(notDraft, 'Is Draft State');
const assertIsRequestedBy = new Assertion(isRequestedBy, 'Not Requested By User');
const assertIsAttendee = new Assertion(isAttendee, 'Not An Attendee');
const assertIsOwnPerson = new Assertion(isOwnPerson, 'Not Own Person');

acl.inherit(ADMIN, USER);
acl.inherit(TRAINER, USER);

// Admin

acl.allow(ADMIN, BillingSettingsActionNames)

acl.allow(ADMIN, ConsultingSessionActionNames);
acl.allow(ADMIN, ConsultingSessionAttendeeActionNames);

acl.allow(ADMIN, PersonActionNames);
acl.allow(ADMIN, PersonContactPointActionNames);

acl.allow(ADMIN, StripeChargeActionNames);
acl.allow(ADMIN, StripeCustomerActionNames);
acl.allow(ADMIN, StripeInvoiceActionNames);
acl.allow(ADMIN, StripeProductActionNames);
acl.allow(ADMIN, StripePriceActionNames);
acl.allow(ADMIN, StripeSubscriptionActionNames);

acl.allow(ADMIN, TrainingClassActionNames);
acl.allow(ADMIN, TrainingPaymentAgreementActions);
acl.allow(ADMIN, TrainingTicketActionNames);
acl.allow(ADMIN, TrainingSeatActionNames);

acl.allow(ADMIN, UserActionNames);

// Anonymous

// acl.allow(ANONYMOUS, CONSULTING_GET_STRIPE_PRICES);
// acl.allow(ANONYMOUS, [ TRAINING_STRIPE_PRICES_VIEW ]);

// User

acl.allow(USER, BillingSettingsActionNames, assertIsOwn);

acl.allow(USER, [
  ConsultingSessionActionNameMap.REQUEST,
], assertIsRequestedBy);

acl.allow(USER, [
  ConsultingSessionActionNameMap.CANCEL,
  ConsultingSessionActionNameMap.GET,
  ConsultingSessionActionNameMap.LIST,
  ConsultingSessionAttendeeActionNameMap.GET,
  ConsultingSessionAttendeeActionNameMap.LIST,
], assertIsAttendee);

acl.allow(USER, [
  PersonActionNameMap.CREATE,
  PersonActionNameMap.GET,
  PersonActionNameMap.LIST,
  PersonActionNameMap.UPDATE,
], assertIsOwn);

acl.allow(USER, PersonContactPointActionNames, assertIsOwnPerson);

acl.allow(USER, [
  StripeCustomerActionNameMap.CREATE,
  StripeCustomerActionNameMap.GET,
  StripeCustomerActionNameMap.UPDATE,
  StripeInvoiceActionNameMap.GET,
  StripePaymentMethodActionNameMap.CREATE,
  StripePaymentMethodActionNameMap.GET,
  StripePaymentMethodActionNameMap.LIST,
], assertHasMetadataUserId);

acl.allow(USER, [
  StripePriceActionNameMap.GET,
  StripePriceActionNameMap.LIST,
  StripeProductActionNameMap.GET,
  StripeProductActionNameMap.LIST,
]);

// acl.allow(USER, [
//   BOOK_ORDER_CREATE, 
//   BOOK_ORDER_CANCEL, 
//   BOOK_ORDER_PAY, 
//   BOOK_ORDER_UPDATE, 
//   BOOK_ORDER_VIEW
// ], assertIsOwn);

acl.allow(USER, [TrainingClassActionNameMap.REQUEST]);
acl.allow(USER, [TrainingClassActionNameMap.GET, TrainingClassActionNameMap.LIST], assertNotDraft);
acl.allow(USER, [
  TrainingSeatActionNameMap.GET,
  TrainingSeatActionNameMap.LIST,
  TrainingSeatActionNameMap.RELEASE,
  TrainingSeatActionNameMap.REQUEST,
  TrainingSeatActionNameMap.RESEND_CONFIRMATION,
], assertIsOwn);
acl.allow(USER, [
  TrainingPaymentAgreementActionNameMap.CREATE,
  TrainingPaymentAgreementActionNameMap.ACTIVATE,
  TrainingPaymentAgreementActionNameMap.AGREE,
  TrainingPaymentAgreementActionNameMap.GENERATE_SIGNED_URL,
  TrainingPaymentAgreementActionNameMap.GET,
  TrainingPaymentAgreementActionNameMap.LIST,
  TrainingPaymentAgreementActionNameMap.UPDATE,
], assertIsOwn);
acl.allow(USER, [TrainingTicketActionNameMap.GET, TrainingTicketActionNameMap.LIST], assertIsOwn);

// acl.allow(USER, [
//   UserAccount
//   USER_ACCOUNT_RECOVERY_CREATE, 
//   USER_ACCOUNT_RECOVERY_VERIFY,
//   USER_ACCOUNT_RECOVERY_VIEW,
// ], assertIsOwn);

acl.allow(USER, BillingSettingsActionNames, assertIsOwn);

acl.allow(USER, [UserActionNameMap.GET, UserActionNameMap.UPDATE], assertIsOwn);

export async function isAllowed<Context>(action: string, user: AuthedUser, context: Context) {

  return acl.isAllowed<Context>(user.roles, action, user, context);

}

export async function listAllowed<Context>(action: string, user: AuthedUser, context: Array<Context>) {

  return acl.listAllowed<Context>(user.roles, action, user, context);

}
