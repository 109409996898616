import { ACHIEVE_PROMOTIONS, BEST_EXPERIENCES_EVERY_TIME, DECREASE_COST_PER_MINUTE, DECREASE_REPEAT_INTERACTIONS, INCREASE_BONUS_COMMISSION, INCREASE_COMPLIANCE_QUALITY_SCORES, INCREASE_AVERAGE_PURCHASE, INCREASE_CONVERSION_RATE, INCREASE_CUSTOMER_BASE, INCREASE_JOB_SATISFACTION, INCREASE_PAY_RATE, INCREASE_ROI, DECREASE_TURN_OVER_RATE, MORE_PREMIUMS_UPGRADES, MORE_REFERRALS, INCREASE_CSAT, RELEASE_NEGATIVITY, COACH, OWNER, PROVIDER } from "./common";

export const BusinessEmployeeCountGroupMap = {
  ONE_TO_NINE: '1-9',
  TEN_TO_FOURTY_NINE: '10-49',
  FIFTY_PLUS: '50+'
} as const;

export type BusinessEmployeeCountGroup = typeof BusinessEmployeeCountGroupMap[keyof typeof BusinessEmployeeCountGroupMap];

export const BusinessEmployeeCountGroups = Object.values(BusinessEmployeeCountGroupMap);

export const BusinessOperationLengthMap = {
  ZERO_TO_TWO: '0-2',
  THREE_TO_FIVE: '3-5',
  SIX_PLUS: '6+',
} as const;

export type BusinessOperationLength = typeof BusinessOperationLengthMap[keyof typeof BusinessOperationLengthMap];

export const BusinessOperationLengths = Object.values(BusinessOperationLengthMap);

export type CoachGoalMap = {
  [key in CoachGoalName]: string | undefined;
} & {
  other?: string;
};

export const CoachGoalNameMap = {
  DECREASE_COST_PER_MINUTE,
  DECREASE_REPEAT_INTERACTIONS,
  DECREASE_TURN_OVER_RATE,
  INCREASE_AVERAGE_PURCHASE,
  INCREASE_COMPLIANCE_QUALITY_SCORES,
  INCREASE_CONVERSION_RATE,
  INCREASE_CSAT,
  INCREASE_CUSTOMER_BASE,
  INCREASE_JOB_SATISFACTION,
  INCREASE_ROI,
  MORE_PREMIUMS_UPGRADES,
  MORE_REFERRALS,
} as const;

export type CoachGoalName = typeof CoachGoalNameMap[keyof typeof CoachGoalNameMap];

export const CoachGoalNames = Object.values(CoachGoalNameMap);

export const CXRoleMap = {
  COACH,
  OWNER,
  PROVIDER,
} as const;

export type CXRole = typeof CXRoleMap[keyof typeof CXRoleMap];

export const CXRoles = Object.values(CXRoleMap);

export const IndustryMap = {
  ACCOMMODATION_SERVICES: 'accommodation-services',
  ADMINISTRATIVE_SUPPORT_SERVICES: 'administrative-support-services',
  BARS_TAVERNS_NIGHTCLUBS: 'bars-taverns-nightclubs',
  BUILDING_CONSTRUCTION: 'building-construction',
  CATERERS: 'caterers',
  CIVIL_ENGINEERING: 'civil-engineering',
  CIVIC_SOCIAL_ORGANIZATIONS: 'civic-social-organizations',
  COLLECTION_AGENCIES: 'collection-agencies',
  CONSUMER_SERVICES: 'consumer-services',
  CONSTRUCTION: 'construction',
  COSMOTOLOGY_BARBER_SCHOOLS: 'cosmotology_barber_schools',
  EDUCATION: 'educations',
  EVENT_SERVICES: 'event-services',
  FACILITIES_SERVICES: 'facilities-services',
  FINANCE: 'finance',
  FINE_ARTS_SCHOOLS: 'fine-arts-schools',
  FLIGHT_TRAINING: 'flight-training',
  FOOD_BEVERAGE_SERVICES: 'food-beverage-services',
  FUNDRAISING: 'fundraising',
  FURNITURE_REPAIR: 'furniture-repair',
  HEALTH_CARE: 'health-care',
  HIGHER_EDUCATION: 'higher-education',
  HIGHWAY_STREET_BRIDGE_CONSTRUCTION: 'highway-street-bridge-construction',
  HOSPITALITY: 'hospitality',
  HOTELS_MOTELS: 'hotels-motels',
  HOUSEHOLD_SERVICES: 'household-services',
  INDUSTRY_ASSOCIATIONS: 'industry-associations',
  JANITORIAL_SERVICES: 'janitorial-services',
  LANDSCAPING_SERVICES: 'landscaping-services',
  LANGUAGE_SCHOOLS: 'language-schools',
  LAUNDRY_DRYCLEANING_SERVICES: 'laundry-drycleaning-service',
  MINING: 'mining',
  MOBILE_FOOD_SERVICES: 'mobile-food-services',
  NONPROFIT_ORGANIZATIONS: 'non-profit-organizations',
  NONRESIDENTIAL_BUILDING_CONSTRUCTION: 'nonresidential-building-construction',
  OFFICE_ADMINISTRATION: 'office-administration',
  PERSON_CARE_SERVICES: 'person-care-services',
  PET_SERVICES: 'pet-services',
  POLITICAL_ORGANIZATIONS: 'political-organizations',
  PRIMARY_SECONDARY_EDUCATION: 'primary-secondary-education',
  PROFESSIONAL_ORGANIZATIONS: 'professional-organizations',
  PROFESSIONAL_TRAINING_COACHING: 'professional-training-coaching',
  RELIGIOUS_INSTITUTIONS: 'religious-institutions',
  REPAIR_MAINTENANCE: 'repair-maintenance',
  RESIDENTIAL_BUILDING_CONSTRUCTION: 'residential-building-construction',
  RESTAURANTS: 'restaurants',
  SECURITY_INVESTIGATIONS: 'security-investigations',
  SECURITY_GUARDS_PATROL_SERVICES: 'security-guards-patrol-services',
  SPECIALTY_TRADE_CONTRACTORS: 'specialty-trade-contractors',
  STAFFING_RECRUITING: 'staffing-recruiting',
  TECHNICAL_VOCATIONAL_TRAINING: 'technical-vocational-training',
  TELEPHONE_CALL_CENTERS: 'telephone-call-centers',
  TRANSLATION_LOCALIZATION_SERVICES: 'translation-localization-services',
  TRAVEL_ARRANGMENT_SERVICES: 'travel-arrangement-services',
  VEHICLE_REPAIR_MAINTENANCE: 'vehicle-repair-maintenance',
  WRITING_EDITING_SERVICES: 'writing-editing-services',
} as const;

export type Industry = typeof IndustryMap[keyof typeof IndustryMap];

export const Industries = Object.values(IndustryMap);

export const IndustryCodeMap = {
  ADMINISTRATION_SERVICES: 56,
  AGRICULTURE: 11,
  CONSTRUCTION: 23,
  FINANCE: 52,
  INFORMATION: 51,
  MANUFACTURING: 31,
  MINING: 21,
  PROFESSIONAL_SERVICES: 54,
  REAL_ESTATE: 53,
  RETAIL_TRADE: 44,
  TRANSPORTATION: 48,
  UTILITIES: 22,
  WAREHOUSING: 49,
  WHOLESALE_TRADE:  42,
};

export type OwnerGoalMap = {
  [key in OwnerGoalName]: string | undefined;
} & {
  other?: string;
};

export const OwnerGoalNameMap = {
  BEST_EXPERIENCES_EVERY_TIME,
  DECREASE_TURN_OVER_RATE,
  INCREASE_AVERAGE_PURCHASE,
  INCREASE_COMPLIANCE_QUALITY_SCORES,
  INCREASE_CONVERSION_RATE,
  INCREASE_CUSTOMER_BASE,
  INCREASE_CSAT,
  INCREASE_JOB_SATISFACTION,
  INCREASE_ROI,
  MORE_PREMIUMS_UPGRADES,
  MORE_REFERRALS,
} as const;

export type OwnerGoalName = typeof OwnerGoalNameMap[keyof typeof OwnerGoalNameMap];

export const OwnerGoalNames = Object.values(OwnerGoalNameMap);

export type ProviderGoalMap = {
  [key in ProviderGoalName]: string | undefined;
} & {
  other?: string;
};

export const ProviderGoalNameMap = {
  ACHIEVE_PROMOTIONS,
  BEST_EXPERIENCES_EVERY_TIME,
  INCREASE_AVERAGE_PURCHASE,
  INCREASE_BONUS_COMMISSION,
  INCREASE_COMPLIANCE_QUALITY_SCORES,
  INCREASE_CONVERSION_RATE,
  INCREASE_CSAT,
  INCREASE_JOB_SATISFACTION,
  INCREASE_PAY_RATE,
  MORE_PREMIUMS_UPGRADES,
  MORE_REFERRALS,
  RELEASE_NEGATIVITY,
} as const;

export type ProviderGoalName = typeof ProviderGoalNameMap[keyof typeof ProviderGoalNameMap];

export const ProviderGoalNames = Object.values(ProviderGoalNameMap);

export type SmallToBestGoalMap = {
  [key in SmallToBestGoalName]: string | undefined;
} & {
  other?: string;
};

export const SmallToBestGoalNameMap = {
  BEST_EXPERIENCES_EVERY_TIME,
  INCREASE_AVERAGE_PURCHASE,
  INCREASE_CONVERSION_RATE,
  INCREASE_CUSTOMER_BASE,
  INCREASE_CSAT,
  INCREASE_ROI,
  MORE_PREMIUMS_UPGRADES,
  MORE_REFERRALS,
  RELEASE_NEGATIVITY,
} as const;

export type SmallToBestGoalName = typeof SmallToBestGoalNameMap[keyof typeof SmallToBestGoalNameMap];

export const SmallToBestGoalNames = Object.values(SmallToBestGoalNameMap);
