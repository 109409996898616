<div fxLayout="column"
  *ngIf="form"
  [formGroup]="form">

  <ng-training-payment-agreement-agreement-card
    [agreement]="agreement"></ng-training-payment-agreement-agreement-card>

  <div fxLayout="column"
    *ngIf="!agreementIsActive">

    <mat-checkbox formControlName="agree">

      <span>I have read and agree to all the terms.</span>

    </mat-checkbox>

    <mat-form-field>

      <input type="text" placeholder="Your Signature"
        formControlName="signature"
        matInput>

      <mat-hint>Enter your full legal name, this will be your signature.</mat-hint>

      <mat-error *ngIf="signature.hasError('required')">
        Please enter your full legal name, it is required.
      </mat-error>

      <mat-error *ngIf="signature.hasError('noMatch')">
        Your signature must match your legal name. '{{agreement?.debtor?.legal_name}}'
      </mat-error>

    </mat-form-field>

  </div>

</div>
