import { inject, Inject, Injectable, Signal } from '@angular/core';
import { BESTCXEVER_ACCOUNT_ID, LastEvaluatedKey, PERSON, Person, PersonActionNameMap, PersonCreateAction, PersonCreatedEvent, PersonCreatePayload, PersonGetAction, PersonGetPayload, PersonListAction, PersonListedEvent, PersonListPayload, PersonMergeAction, PersonMergedEvent, PersonUpdateAction, PersonUpdatedEvent, PersonUpdatePayload, PersonViewedEvent } from '@bcx/models';
import { BACKEND_ADAPTER, BackendAdapter, BackendAdapterMap, Entity, IdService } from '@bcx/ng-helpers';
import { RestServiceAdapter } from './rest/rest.service';
import { WebsocketService } from './websocket/websocket.service';
import { person as schemas } from '@bcx/schemas';
import { StateFacade, StateService } from '../state/state.service';

export interface PersonServiceAdapterInterface {

  create(action: PersonCreateAction): Promise<PersonCreatedEvent>;

  get(action: PersonGetAction): Promise<PersonViewedEvent>;

  list(action: PersonListAction): Promise<PersonListedEvent>;

  merge(action: PersonMergeAction): Promise<PersonMergedEvent>;

  update(action: PersonUpdateAction): Promise<PersonUpdatedEvent>;

}

export function createStateId(clean: Pick<Person, 'account_id' | 'person_id'>) {

  return Person.generateModelId(clean);

}

export const ME_LOCAL_STORAGE_KEY = 'person.me';

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  private adapter: PersonServiceAdapterInterface;

  $me: Signal<Entity<Person>>;

  private state: StateFacade<Person, LastEvaluatedKey>;

  constructor(
    @Inject(BACKEND_ADAPTER) backendAdapter: BackendAdapter,
    private id: IdService,
    state: StateService,
  ) { 

    switch (backendAdapter) {

      case BackendAdapterMap.REST:

        this.adapter = inject(RestServiceAdapter);
        
        break;

      case BackendAdapterMap.WEBSOCKET:

        this.adapter = inject(WebsocketService);

        break;
    
      default:
      
        throw new Error(`unknown adapter: ${backendAdapter}`);
      
    }

    this.state = state.facade<Person, LastEvaluatedKey>(PERSON, {
      createStateId,
    });

    const me = this.getLocalMe();

    this.$me = this.state.select(createStateId(me));

  }

  create(data: PersonCreatePayload, now = Date.now()) {

    return schemas.create.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new PersonCreateAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.create(action)
      .then((event) => {
  
        this.state.single({
          id,
          model: event.payload,
          processing: false,
        });

        return event;
  
      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  createPersonId() {

    return this.id.uuid();

  }

  private getLocalMe() {

    const result = localStorage.getItem(ME_LOCAL_STORAGE_KEY);

    if (result) {

      return JSON.parse(result) as { account_id: string, person_id: string, };

    } else {

      const me = {
        account_id: BESTCXEVER_ACCOUNT_ID,
        person_id: this.createPersonId(),
      };

      localStorage.setItem(ME_LOCAL_STORAGE_KEY, JSON.stringify(me));

      return me;

    }

  }

  get(data: PersonGetPayload, now = Date.now()) {

    return schemas.get.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action: PersonGetAction = {
        action_id: this.id.uuid(),
        action_name: PersonActionNameMap.GET,
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      };

      this.state.single({
        id,
        loading: true,
      });

      return this.adapter.get(action)
      .then((event) => {

        this.state.single({
          id,
          model: event.payload as Person,
          loading: false,
        });

        return event;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          loading: false,
        });

        throw e;

      });

    });

  }

  list(data: PersonListPayload, now = Date.now()) {

    return schemas.list.validateAsync(data, {})
    .then((clean) => {

      const group = 'all';

      const action: PersonListAction = {
        action_id: this.id.uuid(),
        action_name: PersonActionNameMap.LIST,
        epoch: now,
        model_id: '',
        payload: clean,
        user_id: '',
      };

      this.state.many({ 
        group,
        loading: true,
      });

      return this.adapter.list(action)
      .then((event) => {

        const list = event.payload;

        this.state.many({ 
          group,
          list: list.list,
          loading: false,
        });

        return list;

      })
      .catch((e) => {

        this.state.many({
          e, 
          group,
          loading: false,
        });

        throw e;

      });

    });

  }

  me(now = Date.now()) {

    const clean = this.getLocalMe();

    const id = createStateId(clean);

    const action: PersonGetAction = {
      action_id: this.id.uuid(),
      action_name: PersonActionNameMap.GET,
      epoch: now,
      model_id: id,
      payload: clean,
      user_id: '',
    };

    this.state.single({ 
      id,
      loading: true,
    });

    return this.adapter.get(action)
    .then((event) => {

      const model = event.payload as Person;

      this.state.single({ 
        id,
        model,
        loading: false,
      });

      return model;

    })
    .catch((e) => {

      this.state.single({ 
        e,
        id,
        loading: false, 
      });

      throw e;

    });

  }

  select(account_id: string, person_id: string) {

    return this.state.select(createStateId({ account_id, person_id }));

  }

  selectAll() {

    return this.state.selectGroup('all');

  }

  selectMe() {

    const me = this.getLocalMe();

    return this.state.select(createStateId(me));

  }

  update(original: Person, data: PersonUpdatePayload, now = Date.now()) {

    data.account_id = original.account_id;
    data.last_updated_epoch = original.last_updated_epoch;
    data.person_id = original.person_id;

    return schemas.update.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action: PersonUpdateAction = {
        action_id: this.id.uuid(),
        action_name: PersonActionNameMap.UPDATE,
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      };

      this.state.single({
        id,
        processing: true,
      }, now);

      return this.adapter.update(action)
      .then((event) => {

        this.state.single({ 
          id,
          model: event.payload,
          processing: false,
        });
          
        return event;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });
  
        throw e;

      });

    });

  }

}
