import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import Stripe from 'stripe';

@Component({
  selector: 'ng-stripe-payment-method-list-table',
  templateUrl: './list-table.component.html',
  styleUrls: ['./list-table.component.sass'],
})
export class ListTableComponent implements OnChanges {

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() actions: TemplateRef<any>;
  
    @Input() methods: Array<Stripe.PaymentMethod> = [];
  
    @Input() display = [
      'id',
      'status',
      'currency',
      'period_start',
      'period_end',
      'actions',
    ];
  
    table = new MatTableDataSource<Stripe.PaymentMethod>([]);
  
    ngOnChanges(changes: SimpleChanges): void {
      
      if (changes['methods']) {
  
        this.table.data = this.methods || [];
  
      }
  
    }
  
  
}
