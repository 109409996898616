import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { OWNER, PROVIDER, TrainingClass } from '@bcx/models';

@Component({
  selector: 'ng-training-class-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.sass'],
})
export class SummaryCardComponent implements OnChanges {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  isProvider: boolean;

  isOwner: boolean;

  OWNER = OWNER;

  PROVIDER = PROVIDER;

  @Input() trainingClass?: TrainingClass;

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['trainingClass'] && this.trainingClass) {

      if (this.trainingClass.course === OWNER) {

        this.isOwner = true;
        this.isProvider = false;

      } else {

        this.isOwner = false;
        this.isProvider = true;

      }

    }

  }

}
