import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TrainingTicket, TrainingTicketStatusMap } from '@bcx/models';

@Component({
  selector: 'ng-training-ticket-book-tearoff',
  templateUrl: './tearoff.component.html',
  styleUrls: ['./tearoff.component.sass'],
})
export class TearoffComponent implements OnChanges {

  state: string;

  @Input() ticket: TrainingTicket;

  canRedeem() {

    return this.state === TrainingTicketStatusMap.AVAILABLE;

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['ticket']) {

      this.state = this.ticket.status;

    }

  }

  redeem() {

    //

  }

}
