import { Component, Input, OnInit, Signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService, AuthedUser, SignInSignOutService } from '@engagedcx/ng-amplify';
import { Entity, IntlPhoneNumberControl, SubsComponent } from '@bcx/ng-helpers';
import { SignUpForm } from './sign-up.form';
import { VerifyForm } from './verify.form';
import { Router } from '@angular/router';
import { PersonService } from '@bcx/ng-models';
import { Person } from '@bcx/models';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.sass'],
})
export class SignUpComponent extends SubsComponent implements OnInit {

  alreadySignedUp = false;

  authed?: AuthedUser;

  localTimeZoneControl: FormControl;

  otpSent = false;

  $person: Signal<Entity<Person>>;

  phoneNumberControl: IntlPhoneNumberControl;

  @Input() processing = false;

  signUpForm: SignUpForm;

  verifyForm: VerifyForm;

  constructor(
    private auth: AuthService,
    private personService: PersonService,
    private router: Router,
    private signInSignOut: SignInSignOutService,
  ) {

    super();

    this.signUpForm = new SignUpForm();

    this.localTimeZoneControl = this.signUpForm.get('zoneinfo') as FormControl;
    this.phoneNumberControl = this.signUpForm.get('phone_number') as IntlPhoneNumberControl;

    this.verifyForm = new VerifyForm();

    this.$person =this.personService.selectMe();

  }

  authenticate() {

    this.auth.isAuthed()
    .then((alreadySignedUp: boolean) => {

      console.log('alreadySignedUp', alreadySignedUp);

      this.alreadySignedUp = alreadySignedUp;

    })
    .catch((e: Error) => {

      console.error(e);

    });

  }

  fetchPerson() {

    return this.personService.me()
    .catch((e: Error) => {

      console.error(e);

    });

  }

  ngOnInit(): void {
    
    this.subs['authed'] = this.auth.identity$
    .subscribe((authed) => {

      this.authed = authed;

      if (this.authed) {

        this.fetchPerson();

      }

    });

    this.authenticate();

  }

  resendCode() {

    const phoneNumber = this.signUpForm.value.phone_number;
    
    this.auth.signIn(phoneNumber)
    .then(() => {

      this.otpSent = true;

      this.signUpForm.disable();

    });

  }

  signIn() {

    this.signInSignOut.signIn();

  }

  signUp() {

    this.processing = true;

    const zoneinfo = this.signUpForm.value.zoneinfo;
    const name = this.signUpForm.value.name;
    const phoneNumber = this.signUpForm.value.phone_number;

    this.auth.signUp(phoneNumber, {
      zoneinfo,
      name,
    })
    .catch((e: Error) => {

      // if (e.name === 'UsernameExistsException') {

      //   return true;

      // }

      throw e;

    })
    .then(() => {

      return this.auth.signIn(phoneNumber);

    })
    .then(() => {

      this.otpSent = true;

      this.signUpForm.disable();

    })
    .catch((e: Error) => {

      if (e.name === 'UsernameExistsException') {

        this.alreadySignedUp = true;

      } else {

        console.error(e);

      }

    })
    .finally(() => {

      this.processing = false;

    });

  }

  verify() {

    const code = this.verifyForm.value.code;

    this.processing = true;

    console.log('verify', 'code', code);

    this.auth.verifySignIn(`${code}`)
    .then(() => {

      this.router.navigateByUrl('/');

    })
    .catch((e: Error) => {

      console.error(e);

    })
    .finally(() => {

      this.processing = false;

    });

  }

}
