import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-goal-level-of-importance',
  templateUrl: './level-of-importance.component.html',
  styleUrl: './level-of-importance.component.sass',
})
export class LevelOfImportanceComponent {

  @Input() form: FormGroup;

  @Input() goalName: string;

}
