<ng-container [ngSwitch]="ticket.ticket_type">

  <ng-training-ticket-book-tearoff fxFlexFill
    *ngSwitchCase="BOOK"
    [ticket]="ticket"></ng-training-ticket-book-tearoff>

  <ng-training-ticket-class-tearoff fxFlexFill
    *ngSwitchCase="CLASS"
    [ticket]="ticket"></ng-training-ticket-class-tearoff>

  <ng-training-ticket-video-tearoff fxFlexFill
    *ngSwitchCase="VIDEO"
    [ticket]="ticket"></ng-training-ticket-video-tearoff>

</ng-container>
