import { FormControl, FormGroup, Validators } from "@angular/forms";

export class RemoveForm extends FormGroup {

  constructor() {

    super({
      confirm: new FormControl(false, [Validators.requiredTrue]),
    })

  }

}
