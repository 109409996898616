import { Component, Input, TemplateRef } from '@angular/core';
import { TrainingClass } from '@bcx/models';

@Component({
  selector: 'ng-training-class-list-cards',
  templateUrl: './list-cards.component.html',
  styleUrls: ['./list-cards.component.sass'],
})
export class ListCardsComponent {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  @Input() empty = 'No Classes';

  @Input() loading = false;

  @Input() trainingClasses: Array<TrainingClass> = [];

}
