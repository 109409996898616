<div class="ng-consulting-select-session-type" fxLayout="column"
  *ngIf="control">

  <mat-form-field>

    <mat-label>Select a Session Type</mat-label>

    <mat-select
      [formControl]="control"
      matInput>

      <mat-select-trigger style="width: 100%">

        <ng-consulting-session-type-option
          [role]="role"
          [sessionType]="control.value"></ng-consulting-session-type-option>

      </mat-select-trigger>

      <mat-option *ngFor="let sessionType of sessionTypes"
        [disabled]="isDisabled(sessionType)"
        [value]="sessionType">
      
        <ng-consulting-session-type-option
          [category]="category"
          [currency]="currency"
          [role]="role"
          [sessionType]="sessionType"></ng-consulting-session-type-option>

      </mat-option>

    </mat-select>

    <mat-error *ngIf="control.hasError('required')">Please select a session type.</mat-error>

  </mat-form-field>

</div>
