import { Injectable } from '@angular/core';
import { PersonServiceAdapterInterface } from '../person.service';
import { Person, PersonInterface, RestServerResponse, PERSON, PersonCreateAction, PersonCreatedEvent, PersonGetAction, PersonViewedEvent, PersonListAction, PersonMergeAction, PersonMergedEvent, PersonUpdateAction, PersonUpdatedEvent, PersonListedEvent } from '@bcx/models';
import { ApiService } from '@engagedcx/ng-amplify';
import { List } from '@digitaltoolbuilders/model-helpers';

@Injectable({
  providedIn: 'root'
})
export class RestServiceAdapter implements PersonServiceAdapterInterface {

  readonly path = `/${PERSON}`;

  constructor(private api: ApiService) { }

  create(action: PersonCreateAction): Promise<PersonCreatedEvent> {
    
    return this.api.post<RestServerResponse<PersonInterface>>(this.path, action)
    .then((response) => {

      return new PersonCreatedEvent({
        ...response.data,
        payload: new Person(response.data.payload),
      });

    });

  }

  get(action: PersonGetAction): Promise<PersonViewedEvent> {
    
    return this.api.post<RestServerResponse<PersonInterface>>(this.path, action)
    .then((response) => {

      return new PersonViewedEvent({
        ...response.data,
        payload: new Person(response.data.payload),
      });

    });

  }

  list(action: PersonListAction): Promise<PersonListedEvent> {
    
    return this.api.post<RestServerResponse<List<PersonInterface>>>(this.path, action)
    .then((response) => {

      return new PersonListedEvent({
        ...response.data,
        payload: new List<Person>({
          ...response.data.payload,
          list: response.data.payload.list.map((item) => new Person(item)),
        }),
      });

    });

  }

  merge(action: PersonMergeAction): Promise<PersonMergedEvent> {
    
    return this.api.post<RestServerResponse<PersonInterface>>(this.path, action)
    .then((response) => {

      return new PersonMergedEvent({
        ...response.data,
        payload: new Person(response.data.payload),
      });

    });

  }

  update(action: PersonUpdateAction): Promise<PersonUpdatedEvent> {
    
    return this.api.post<RestServerResponse<PersonInterface>>(this.path, action)
    .then((response) => {

      return new PersonUpdatedEvent({
        ...response.data,
        payload: new Person(response.data.payload),
      });

    });

  }

}
