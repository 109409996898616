<div fxLayout="column"
  *ngIf="address">

  <div fxLayout="column">

    <span>{{address.street}}</span>

    <span>{{address.unit}}</span>

  </div>

  <div fxLayout="column"
    fxLayout.gt-sm="row" fxLayoutGap.gt-sm="10px">

    <span>{{address.city}}, {{address.state}}</span>

    <span>{{address.postal_code}}</span>

  </div>

  <div fxLayout="row">

    <span>{{address.country}}</span>

  </div>

</div>