import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoalsComponent } from './goals/goals.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { QuestionsComponent } from './questions/questions.component';
import { TeamDialogComponent } from './team-dialog/team-dialog.component';
import { PathComponent } from './path/path.component';
import { MatStepperModule } from '@angular/material/stepper';
import { TrainingModule } from './training/training.module';
import { BookModule } from './book/book.module';
import { ConsultingModule } from './consulting/consulting.module';
import { RecommendationsModule } from '../recommendations/recommendations.module';
import { OtherOptionsComponent } from './other-options/other-options.component';
import { MatSelectModule } from '@angular/material/select';
import { GoalModule } from '../goal/goal.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { QuestionModule } from '../question/question.module';
import { QuestionaireComponent } from './questionaire/questionaire.component';
import { GoalsCardComponent } from './goals-card/goals-card.component';
import { StripePipesModule } from '@bcx/ng-models';

@NgModule({
  declarations: [
    GoalsComponent,
    PathComponent,
    QuestionsComponent,
    TeamDialogComponent,
    OtherOptionsComponent,
    QuestionaireComponent,
    GoalsCardComponent,
  ],
  exports: [
    GoalsComponent,
    PathComponent,
    QuestionsComponent,
    QuestionaireComponent,
    GoalsCardComponent,
  ],
  imports: [
    BookModule,
    CommonModule,
    ConsultingModule,
    GoalModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatStepperModule,
    QuestionModule,
    ReactiveFormsModule,
    RecommendationsModule,
    StripePipesModule,
    TrainingModule,
  ],
})
export class CoachModule {}
