<ng-container [ngSwitch]="ticket.ticket_type">

  <ng-training-ticket-book-stub
    *ngSwitchCase="BOOK"
    [ticket]="ticket"></ng-training-ticket-book-stub>

  <ng-training-ticket-class-stub
    *ngSwitchCase="CLASS"
    [ticket]="ticket"></ng-training-ticket-class-stub>

  <ng-training-ticket-video-stub
    *ngSwitchCase="VIDEO"
    [ticket]="ticket"></ng-training-ticket-video-stub>

</ng-container>
