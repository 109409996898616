<div class="ng-picker-mini-select-currency">

  <mat-form-field
    *ngIf="control">

    <mat-label>Currency</mat-label>

    <mat-select
      [formControl]="control">

      <mat-option 
        *ngFor="let currency of currencies"
        [value]="currency"
        [innerText]="codes[currency].hex"></mat-option>

    </mat-select>

  </mat-form-field>

</div>
