import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryCardComponent } from './summary-card/summary-card.component';
import { StubComponent } from './stub/stub.component';
import { TearoffComponent } from './tearoff/tearoff.component';

@NgModule({
  declarations: [SummaryCardComponent, StubComponent, TearoffComponent],
  imports: [CommonModule],
  exports: [SummaryCardComponent, StubComponent, TearoffComponent],
})
export class VideoModule {}
