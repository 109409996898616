import { Component, Input, OnChanges } from '@angular/core';
import { format } from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import { OfficeHoursService } from '../office-hours.service';
import { DEFAULT_FORMAT } from '../../common';

export const FULL_DATE_FORMAT = 'MM/dd/yyyy';

@Component({
  selector: 'ng-calendar-office-hours-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.sass'],
})
export class SummaryComponent implements OnChanges {

  @Input() date: Date = new Date();

  endTime = this.service.endTime;

  endLocal: string;

  endOur: string;

  localTimeZone = this.service.localTimeZone;

  startTime = this.service.startTime;

  startLocal: string;

  startOur: string;

  ourTimeZone = this.service.ourTimeZone;

  constructor(private service: OfficeHoursService) {



  }

  ngOnChanges(): void {
    
    this.date = this.date || new Date();

    const startTimeUTC = zonedTimeToUtc(Date.parse(`${format(this.date, FULL_DATE_FORMAT)} ${this.startTime}`), this.ourTimeZone);

    this.startLocal = formatInTimeZone(startTimeUTC, this.localTimeZone, DEFAULT_FORMAT);
    this.startOur = formatInTimeZone(startTimeUTC, this.ourTimeZone, DEFAULT_FORMAT);

    const endTimedUTC = zonedTimeToUtc(Date.parse(`${format(this.date, FULL_DATE_FORMAT)} ${this.endTime}`), this.ourTimeZone);

    this.endLocal = formatInTimeZone(endTimedUTC, this.localTimeZone, DEFAULT_FORMAT);
    this.endOur = formatInTimeZone(endTimedUTC, this.ourTimeZone, DEFAULT_FORMAT);

  }

}
