import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { OwnerQuestionsForm, OwnerQuestionsMap } from './questions.form';
import { BusinessEmployeeCountGroups, BusinessOperationLengths, Industries } from '@bcx/models';
import { GetStartedService } from '../../get-started/get-started.service';
import { map, Observable, startWith, throttleTime } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ListBaseComponent } from '../../goal/list-base/list-base.component';

@Component({
  selector: 'app-owner-questions',
  templateUrl: './questions.component.html',
  styleUrl: './questions.component.sass',
})
export class QuestionsComponent extends ListBaseComponent implements OnDestroy, OnInit  {

  form = new OwnerQuestionsForm();

  industry: FormControl;

  industries = Industries;

  groups = BusinessEmployeeCountGroups;

  lengths = BusinessOperationLengths;

  lengthInBusiness: FormControl;

  numberOfEmployees: FormControl;

  @Output() override progress$: Observable<number>;

  vm$: Observable<OwnerQuestionsMap>;

  constructor(private service: GetStartedService) {

    super();

    this.industry = this.form.get('industry') as FormControl;
    this.lengthInBusiness = this.form.get('length_in_business') as FormControl;
    this.numberOfEmployees = this.form.get('number_of_employees') as FormControl;

    const state = this.service.getCurrentState();

    this.form.patchValue(state.owner.questions);

    this.vm$ = this.form.valueChanges.pipe(startWith(this.form.value));

    this.calculateProgress();

  }

  ngOnInit(): void {
    
    this.subs['questions'] = this.service.state$
    .pipe(map((state) => state.owner.questions))
    .pipe(throttleTime(250))
    .subscribe(() => {

      this.calculateProgress();

    });

    this.subs['valueChanges'] = this.form.valueChanges
    .pipe(throttleTime(250))
    .subscribe((questions) => {

      this.service.save({
        owner: {
          questions,
        },
      });

    });

  }

}
