<div mat-dialog-title>

  <h1>Please answer the following questions:</h1>

</div>

<mat-dialog-content>

  <div>

    <div>

      <p>Are you the leader of a team?</p>

    </div>

    <mat-button-toggle-group>

      <mat-button-toggle>No</mat-button-toggle>

      <mat-button-toggle>Yes</mat-button-toggle>

    </mat-button-toggle-group>

  </div>

  <div>

    <div>

      <p>Can you change policies or procedures?</p>

    </div>

    <mat-button-toggle-group>

      <mat-button-toggle>No</mat-button-toggle>

      <mat-button-toggle>Yes</mat-button-toggle>

    </mat-button-toggle-group>

  </div>

</mat-dialog-content>

<mat-dialog-actions>

  <div fxLayout="row" fxFlex fxLayoutAlign="space-around center">

    <button class="owner" type="button"
      mat-raised-button
      (click)="owner()">
        I am an Owner
    </button>

    <button class="provider" type="button"
      mat-raised-button
      (click)="provider()">
        I am a Provider
    </button>

  </div>

</mat-dialog-actions>
