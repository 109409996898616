<div class="flex flex-col">

  <div class="flex flex-col md:flex-row">

    <app-small-to-best-training-card>

      <a color="primary"
        routerLink="/training/register"
        mat-raised-button>Register For Training</a>

    </app-small-to-best-training-card>
  
    <app-recommendation-list [product_id]="product_id"></app-recommendation-list>
  
  </div>

</div>
