<div class="ng-user-list-table" fxLayout="column">

  <table mat-table [dataSource]="table">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let user">
        <ng-container *ngTemplateOutlet="actions; context: { user }"></ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let user">{{user.email}}</td>
    </ng-container>
    <ng-container matColumnDef="email_verified">
      <th mat-header-cell *matHeaderCellDef>Email Verified</th>
      <td mat-cell *matCellDef="let user">{{user.email_verified}}</td>
    </ng-container>
    <ng-container matColumnDef="family_name">
      <th mat-header-cell *matHeaderCellDef>Family Name</th>
      <td mat-cell *matCellDef="let user">{{user.family_name}}</td>
    </ng-container>
    <ng-container matColumnDef="given_name">
      <th mat-header-cell *matHeaderCellDef>Given Name</th>
      <td mat-cell *matCellDef="let user">{{user.given_name}}</td>
    </ng-container>
    <ng-container matColumnDef="last_updated_epoch">
      <th mat-header-cell *matHeaderCellDef>Last Updated Epoch</th>
      <td mat-cell *matCellDef="let user">{{user.last_updated_epoch}}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let user">{{user.name}}</td>
    </ng-container>
    <ng-container matColumnDef="phone_number">
      <th mat-header-cell *matHeaderCellDef>Phone Number</th>
      <td mat-cell *matCellDef="let user">{{user.phone_number}}</td>
    </ng-container>
    <ng-container matColumnDef="phone_number_verified">
      <th mat-header-cell *matHeaderCellDef>Phone Number Verified</th>
      <td mat-cell *matCellDef="let user">{{user.phone_number_verified}}</td>
    </ng-container>
    <ng-container matColumnDef="picture">
      <th mat-header-cell *matHeaderCellDef>Picture</th>
      <td mat-cell *matCellDef="let user">{{user.picture}}</td>
    </ng-container>
    <ng-container matColumnDef="roles">
      <th mat-header-cell *matHeaderCellDef>Roles</th>
      <td mat-cell *matCellDef="let user">{{user.roles}}</td>
    </ng-container>
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>State</th>
      <td mat-cell *matCellDef="let user">{{user.state}}</td>
    </ng-container>
    <ng-container matColumnDef="state_changes">
      <th mat-header-cell *matHeaderCellDef>State Changes</th>
      <td mat-cell *matCellDef="let user">{{user.state_changes}}</td>
    </ng-container>
    <ng-container matColumnDef="user_id">
      <th mat-header-cell *matHeaderCellDef>User Id</th>
      <td mat-cell *matCellDef="let user">{{user.user_id}}</td>
    </ng-container>
    <ng-container matColumnDef="user_name">
      <th mat-header-cell *matHeaderCellDef>User Name</th>
      <td mat-cell *matCellDef="let user">{{user.user_name}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let user; columns: columnsToDisplay"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td [attr.colspan]="columnsToDisplay.length" style="text-align: center">
        <span>None</span>
      </td>
    </tr>
  </table>

</div>
