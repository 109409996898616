import { inject, Inject, Injectable } from '@angular/core';
import { TRAINING_CLASS, TrainingClass, TrainingClassCancelAction, TrainingClassCancelPayload, TrainingClassConfirmAction, TrainingClassConfirmPayload, TrainingClassCreateAction, TrainingClassCreatePayload, TrainingClassFinishPayload, TrainingClassGetAction, TrainingClassGetPayload, TrainingClassListAction, TrainingClassListPayload, TrainingClassRequestAction, TrainingClassRequestPayload, TrainingClassStartAction, TrainingClassStartPayload } from '@bcx/models';
import { BACKEND_ADAPTER, BackendAdapter, BackendAdapterMap, IdService } from '@bcx/ng-helpers';
import { trainingClass as schemas } from '@bcx/schemas';
import { StateFacade, StateService } from '../../state/state.service';
import { WebsocketService } from './websocket.service';
import { RestService } from './rest.service';
import { ClassServiceAdapter } from './class.service.adapter';
import { List } from '@digitaltoolbuilders/model-helpers';
import { v4 } from 'uuid';

export function createStateId(input: Pick<TrainingClass, 'class_id'>) {

  return TrainingClass.generateModelId(input);

}

export function iteratorFn(list: List<TrainingClass>, fn: (model: TrainingClass) => void) {

  list.list.forEach(fn);

}

@Injectable({
  providedIn: 'root'
})
export class ClassService {

  private adapter: ClassServiceAdapter;

  private state: StateFacade<TrainingClass, List<TrainingClass>>;

  constructor(
    @Inject(BACKEND_ADAPTER) adapter: BackendAdapter,
    private id: IdService,
    state: StateService,
  ) { 

    switch (adapter) {

      case BackendAdapterMap.REST:

        this.adapter = inject(RestService);

        break;

      case BackendAdapterMap.WEBSOCKET:

        this.adapter = inject(WebsocketService);

        break;

    }

    this.state = state.facade<TrainingClass, List<TrainingClass>>(TRAINING_CLASS, { createStateId })

  }

  cancel(original: TrainingClass, data: Partial<TrainingClassCancelPayload>, now = Date.now()) {

    data.class_id = original.class_id;
    data.last_updated_epoch = original.last_updated_epoch;

    return schemas.cancel.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingClassCancelAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.cancel(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  confirm(original: TrainingClass, data: Partial<TrainingClassConfirmPayload>, now = Date.now()) {

    data.class_id = original.class_id;
    data.last_updated_epoch = original.last_updated_epoch;

    return schemas.confirm.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingClassConfirmAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.confirm(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  create(data: Partial<TrainingClassCreatePayload>, now = Date.now()) {

    return schemas.create.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingClassCreateAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.create(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  createId() {

    return this.id.numeric(12);

  }

  finish(original: TrainingClass, data: Partial<TrainingClassFinishPayload>, now = Date.now()) {

    data.class_id = original.class_id;
    data.last_updated_epoch = original.last_updated_epoch;

    return schemas.start.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingClassStartAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.start(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  get(data: TrainingClassGetPayload, now = Date.now()) {

    return schemas.get.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingClassGetAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        loading: true,
      });

      return this.adapter.get(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          loading: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          loading: false,
        });

        throw e;

      });

    });

  }

  list(data: TrainingClassListPayload, now = Date.now()) {

    return schemas.list.validateAsync(data, {})
    .then((clean) => {

      const group = 'all';

      this.state.many({
        group,
        loading: true,
      });

      const action = new TrainingClassListAction({
        action_id: v4(),
        epoch: now,
        model_id: group,
        payload: clean,
        user_id: '',
      });

      return this.adapter.list(action)
      .then((event) => {

        this.state.many({
          group,
          last: event.payload.last_evaluated_key,
          list: event.payload.list,
          loading: false,
        });

        return event;

      })
      .catch((e) => {

        this.state.many({
          e,
          group,
          loading: false,
        });

        throw e;

      });

    });

  }

  request(data: Partial<TrainingClassRequestPayload>, now = Date.now()) {

    return schemas.request.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingClassRequestAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.request(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  start(original: TrainingClass, data: Partial<TrainingClassStartPayload>, now = Date.now()) {

    data.class_id = original.class_id;
    data.last_updated_epoch = original.last_updated_epoch;

    return schemas.start.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingClassStartAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.start(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

}
