import { FormControl, FormGroup, Validators } from "@angular/forms";

export class UpdateForm extends FormGroup {

  constructor() {

    super({
      email: new FormControl('', [Validators.email]),
      family_name: new FormControl('', []),
      given_name: new FormControl('', []),
      local_time_zone: new FormControl('', []),
      name: new FormControl('', [Validators.required]),
      phone_number: new FormControl('', []),
    });

  }

}
