<div fxLayout="column"
  *ngIf="control">

  <div fxLayout="row" fxLayoutAlign="center center"
    *ngIf="!paymentMethods.length">

    <button type="button" color="primary"
      (click)="addPaymentMethod()"
      mat-raised-button>Add Payment Method</button>

  </div>

  <ng-container
    *ngIf="paymentMethods.length">
  
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px"
      *ngIf="paymentMethods.length > 3">
        
      <mat-form-field fxFlex>

        <mat-label>{{label}}</mat-label>

        <mat-select 
          [formControl]="control"
          matInput>

          <mat-option
            *ngFor="let paymentMethod of paymentMethods"
            [value]="paymentMethod.id">

            <!-- <ng-stripe-payment-method-label 
              [paymentMethod]="paymentMethod"></ng-stripe-payment-method-label> -->

          </mat-option>

        </mat-select>

        <mat-error *ngIf="control.hasError('required')">
          Please select a payment method, it is required.
        </mat-error>

      </mat-form-field>

      <div fxLayout="row" fxLayoutAlign="center center">
        
        <button type="button" color="primary"
          (click)="addPaymentMethod()"
          matTooltip="Add Payment Method"
          mat-icon-button><mat-icon>add</mat-icon></button>
          
      </div>

    </div>

    <div fxLayout="row" fxLayoutGap="5px"
      *ngIf="paymentMethods.length < 4">

      <mat-card
        *ngFor="let paymentMethod of paymentMethods">

        <mat-card-header>

          <mat-card-title>

            <span>{{paymentMethod.card?.brand}} Card</span>
            <span>***-{{paymentMethod.card?.last4}}</span>

          </mat-card-title>

          <mat-card-subtitle>

            <span>{{paymentMethod.card?.exp_month}}/{{paymentMethod.card?.exp_year}}</span>

          </mat-card-subtitle>

        </mat-card-header>

        <mat-card-actions>

          <button type="button" color="primary"
            (click)="selectMethod(paymentMethod)"
            [disabled]="isSelected(paymentMethod)"
            mat-raised-button>

            <span *ngIf="!isSelected(paymentMethod)">Use This <span>Card</span></span>
            <span *ngIf="isSelected(paymentMethod)">Selected</span>

          </button>

        </mat-card-actions>

      </mat-card>

      <div fxLayout="row" fxLayoutAlign="center center">

        <button type="button" color="primary"
          (click)="addPaymentMethod()"
          mat-raised-button>Add</button>

      </div>

    </div>

  </ng-container>

</div>
