import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepLabelComponent } from './step-label/step-label.component';
import { StepContentComponent } from './step-content/step-content.component';
import { CardComponent } from './card/card.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { RecommendationsModule } from '../../recommendations/recommendations.module';
import { StripePipesModule } from '@bcx/ng-models';

@NgModule({
  declarations: [StepLabelComponent, StepContentComponent, CardComponent],
  exports: [StepLabelComponent, StepContentComponent, CardComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    RecommendationsModule,
    StripePipesModule,
  ],
})
export class ConsultingModule {}
