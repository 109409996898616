<div
  *ngIf="trainingClass">

  <mat-card>

    <mat-card-header>
  
      <mat-card-title [ngSwitch]="trainingClass.course">
        
        <span *ngSwitchCase="OWNER">Owner Class #{{trainingClass.course_class_number}} on {{trainingClass.scheduled.start_epoch | date:'MMM d @ h:mm a'}}</span>

        <span *ngSwitchCase="PROVIDER">Provider Class #{{trainingClass.course_class_number}} on {{trainingClass.scheduled.start_epoch | date:'MMM d @ h:mm a'}}</span>

      </mat-card-title>
      <mat-card-subtitle>
        
        <div fxLayout="row" fxLayoutGap="25px"
          fxLayout.gt-sm="column">

          <span>{{trainingClass.status}}</span>
          
          <span>Class Id: {{trainingClass.class_id}}</span>

        </div>

      </mat-card-subtitle>
  
    </mat-card-header>
  
    <mat-card-content>
  
      
  
    </mat-card-content>
  
    <mat-card-actions>
  
      <ng-container *ngTemplateOutlet="actions; context: { trainingClass }"></ng-container>
  
    </mat-card-actions>
  
  </mat-card>

</div>
