import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Industries } from '@bcx/models';

@Component({
  selector: 'app-question-select-industry',
  templateUrl: './select-industry.component.html',
  styleUrl: './select-industry.component.sass',
})
export class SelectIndustryComponent {

  @Input() control: FormControl;

  industries = Industries;

  @Input() label = 'Select An Industry';

}
