import { computed, Injectable } from '@angular/core';
import { Entities, Entity, EntityGroup } from '@bcx/ng-helpers';
import { patchState, signalState, SignalState } from '@ngrx/signals';

export type CreateStateIdFunction<Model> = (model: Model) => string;

export interface SingleProps<Model> {

  e?: Error;

  id: string;

  loading?: boolean;

  model?: Model;

  processing?: boolean;

}

export interface ManyOptions<Model> {

  createStateId: CreateStateIdFunction<Model>;

  model: string;

}

export interface ManyProps<Model, Last> {

  e?: Error;

  group: string;

  last?: Last;

  list?: Array<Model>;

  loading?: boolean;

  processing?: boolean;

}

export interface SelectProps {

  id: string;

}

export type FinderFn<Model> = (entity: Entity<Model>) => boolean;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface SelectGroupProps<Model = any> {

  finder?: FinderFn<Model>;

  group: string;

}

export interface State<Model, Last> {

  entities: Entities<Model>;

  groups: {
    [key: string]: EntityGroup<Model, Last>;
  };

}

export interface StateMap {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: SignalState<State<any, any>>;

}

export interface StateFacadeOptions<Model> {

  createStateId: CreateStateIdFunction<Model>;

}

export class StateFacade<Model, Last> {

  createStateId: CreateStateIdFunction<Model>;

  constructor(private model: string, private service: StateService, options: StateFacadeOptions<Model>) {

    this.createStateId = options.createStateId;

  }

  many(props: ManyProps<Model, Last>, now = Date.now()) {

    return this.service.many<Model, Last>({
      createStateId: this.createStateId,
      model: this.model
    }, props, now);

  }

  select(id: string) {

    return this.service.select<Model, Last>(this.model, { id });

  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectGroup(group: string, finder?: FinderFn<Model>) {

    return this.service.selectGroup<Model, Last>(this.model, { finder, group });

  }

  single(props: SingleProps<Model>, now = Date.now()) {

    return this.service.single<Model, Last>(this.model, props, now);

  }

}

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private states: StateMap = {};

  private createState<Model, Last>(model: string) {

    const state: State<Model, Last> = {
      entities: {},
      groups: {},
    };

    const signal = signalState<State<Model, Last>>(state);

    this.states[model] = signal;

    return signal;

  }

  private getEntity<Model, Last>(id: string, state: State<Model, Last>) {

    const entity: Entity<Model> = state.entities[id] || {
      loading: false,
      processing: false,
    };

    return entity;

  }

  private getGroup<Model, Last>(id: string, state: State<Model, Last>, finder?: FinderFn<Model>) {

    const group: EntityGroup<Model, Last> = state.groups[id] || {
      ids: [],
      loading: false,
    };

    let entities: Array<Entity<Model>>;
    
    if (finder) {

      entities = Object.values(state.entities).filter(finder);

    } else {

      entities = group.ids.map((id) => {

        return this.getEntity(id, state);
  
      });
  
    }

    return {
      ...group,
      entities,
      models: entities.map((entity) => entity.model as Model),
    };

  }

  private getState<Model, Last>(model: string) {

    return this.states[model] as SignalState<State<Model, Last>> || this.createState<Model, Last>(model);

  }

  facade<Model, Last>(model: string, options: StateFacadeOptions<Model>) {

    return new StateFacade<Model, Last>(model, this, options);

  }

  many<Model, Last>(opts: ManyOptions<Model>, props: ManyProps<Model, Last>, now = Date.now()) {

    const { createStateId, model }  = opts;

    const state = this.getState<Model, Last>(model);

    patchState(state, (state) => {

      const group = state.groups[props.group] || {
        ids: [],
        loading: false,
      };

      group.error = props.e?.message;
      group.loading = props.loading || false;

      const entities: Entities<Model> = {};
      
      if (props.list) {

        group.last = props.last;
        group.loaded = now;

        props.list.forEach((model: Model) => {

          const id = createStateId(model);

          if (!group.ids.includes(id)) {

            group.ids.push(id);

          }

          const entity = this.getEntity<Model, Last>(id, state);

          entity.model = model;
          entity.loaded = now;
          entity.loading = props.loading || false;

          entities[id] = entity;

        });

      }

      const groups: { [key: string]: EntityGroup<Model, Last> } = {};

      groups[props.group] = group;

      return {
        entities,
        groups,
      };

    });

  }

  select<Model, Last>(model: string, props: SelectProps) {

    const state = this.getState<Model, Last>(model);

    return computed(() => {

      return this.getEntity<Model, Last>(props.id, state());

    });

  }

  selectGroup<Model, Last>(model: string, props: SelectGroupProps) {

    const state = this.getState<Model, Last>(model);

    return computed(() => {

      return this.getGroup<Model, Last>(props.group, state(), props.finder);

    });

  }

  single<Model, Last>(model: string, props: SingleProps<Model>, now = Date.now()) {

    const state = this.getState<Model, Last>(model);

    patchState(state, (state) => {

      const entities: Entities<Model> = {};
      
      if (props.model) {

        const id = props.id;

        const entity = this.getEntity<Model, Last>(id, state);
  
        entity.model = props.model;
        entity.loaded = now;
        entity.loading = props.loading || false;

        entities[id] = entity;

      }

      return {
        entities,
      };

    });

  }

}
