import { Injectable } from '@angular/core';
import { ProductPrice } from '@bcx/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PriceService {

  private prices = new BehaviorSubject<Array<ProductPrice>>([]);

  prices$ = this.prices.asObservable();

  list() {

    return Promise.resolve([]);

  }

}
