import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SetupIntent } from '@stripe/stripe-js';
import { PaymentMethodService } from '../payment-method.service';
import { Subject } from 'rxjs';
import Stripe from 'stripe';
import { SubsComponent } from '@bcx/ng-helpers';

export interface AddSuccessData {

  setup_intent: string;

  setup_intent_client_secret: string;

}

@Component({
  selector: 'ng-stripe-add-success-dialog',
  templateUrl: './add-success-dialog.component.html',
  styleUrls: ['./add-success-dialog.component.sass'],
})
export class AddSuccessDialogComponent extends SubsComponent implements OnInit {

  error?: string;

  id: string;

  loading: boolean;

  paymentMethod?: Stripe.PaymentMethod;

  private paymentMethodSub = new Subject<string>();

  setupIntent?: SetupIntent;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: AddSuccessData,
    private dialog: MatDialogRef<AddSuccessDialogComponent>,
    private service: PaymentMethodService,
  ) { 

    super();

    this.id = data.setup_intent;

  }

  closeDialog() {

    this.dialog.close();

  }

  ngOnInit(): void {
    
    this.subs['paymentMethod'] = this.service.my$
    .subscribe(({ error, loading, models }) => {

      this.error = error;

      this.loading = loading;

      this.paymentMethod = models.find((pm: Stripe.PaymentMethod) => {

        return (this.setupIntent?.payment_method === pm.id);

      });

    });

    this.subs['setupIntent'] = this.service.selectSetupIntent(this.id)
    .subscribe(({ model, error, loading, }) => {

      console.log('setupIntent', error, loading, model);

      this.setupIntent = model;

      this.error = error;

      this.loading = this.loading || loading;

    });

  }

}
