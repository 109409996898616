<div fxLayout="column">

  <div fxLayout="row" fxLayoutAlign="center center">

    <span class="warn" *ngIf="error">{{error}}</span>

    <mat-spinner *ngIf="loading" diameter="30"></mat-spinner>

  </div>

  <table mat-table [dataSource]="table">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let trainingClass">
        <ng-container *ngTemplateOutlet="actions; context: { trainingClass }"></ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="class_id">
      <th mat-header-cell *matHeaderCellDef>Class Id</th>
      <td mat-cell *matCellDef="let trainingClass">{{trainingClass.class_id}}</td>
    </ng-container>
    <ng-container matColumnDef="last_updated_epoch">
      <th mat-header-cell *matHeaderCellDef>Last Updated Epoch</th>
      <td mat-cell *matCellDef="let trainingClass">{{trainingClass.last_updated_epoch}}</td>
    </ng-container>
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let trainingClass">{{trainingClass.role}}</td>
    </ng-container>
    <ng-container matColumnDef="scheduled_length_mins">
      <th mat-header-cell *matHeaderCellDef>Scheduled Length Mins</th>
      <td mat-cell *matCellDef="let trainingClass">{{trainingClass.scheduled_length_mins}}</td>
    </ng-container>
    <ng-container matColumnDef="class_number">
      <th mat-header-cell *matHeaderCellDef>Class &num;</th>
      <td mat-cell *matCellDef="let trainingClass">{{trainingClass.class_number}}</td>
    </ng-container>
    <ng-container matColumnDef="start_date">
      <th mat-header-cell *matHeaderCellDef>Start Date</th>
      <td mat-cell *matCellDef="let trainingClass">{{trainingClass.start_date}}</td>
    </ng-container>
    <ng-container matColumnDef="start_epoch">
      <th mat-header-cell *matHeaderCellDef>Start Epoch</th>
      <td mat-cell *matCellDef="let trainingClass">{{trainingClass.start_epoch}}</td>
    </ng-container>
    <ng-container matColumnDef="start_time">
      <th mat-header-cell *matHeaderCellDef>Start Time</th>
      <td mat-cell *matCellDef="let trainingClass">{{trainingClass.start_time}}</td>
    </ng-container>
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>State</th>
      <td mat-cell *matCellDef="let trainingClass">{{trainingClass.state}}</td>
    </ng-container>
    <ng-container matColumnDef="state_changes">
      <th mat-header-cell *matHeaderCellDef>State Changes</th>
      <td mat-cell *matCellDef="let trainingClass">{{trainingClass.state_changes}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="display"></tr>
    <tr mat-row *matRowDef="let trainingClass; columns: display"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td [attr.colspan]="display.length" style="text-align: center">
        <span>None</span>
      </td>
    </tr>
  </table>

</div>
