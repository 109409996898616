import {Stripe} from "stripe";
import { ModelAction, ModelEvent } from "../model-action";
import { STRIPE_SUBSCRIPTION } from "../common";

export const StripeSubscriptionActionNameMap = {
  CREATE: `${STRIPE_SUBSCRIPTION}/create`,
  GET: `${STRIPE_SUBSCRIPTION}/get`,
  LIST: `${STRIPE_SUBSCRIPTION}/list`,
  UPDATE: `${STRIPE_SUBSCRIPTION}/update`,
} as const;

export type StripeSubscriptionActionName = typeof StripeSubscriptionActionNameMap[keyof typeof StripeSubscriptionActionNameMap];

export const StripeSubscriptionActionNames = Object.values(StripeSubscriptionActionNameMap);

export class StripeSubscriptionCreateAction extends ModelAction<StripeSubscriptionCreatePayload> {

  readonly action_name = StripeSubscriptionActionNameMap.CREATE;

  constructor(input: Partial<Omit<StripeSubscriptionCreateAction, 'action_name'>>){

    super(input);

  }

}

export class StripeSubscriptionCreatedEvent extends ModelEvent<Stripe.Subscription> {

  readonly event_name = StripeSubscriptionEventNameMap.CREATED;

  constructor(input: Partial<Omit<StripeSubscriptionCreatedEvent, 'event_name'>>) {

    super(input);

  }

}

export type StripeSubscriptionCreatePayload = Stripe.SubscriptionCreateParams;

export const StripeSubscriptionEventNameMap = {
  CREATED: `${STRIPE_SUBSCRIPTION}:created`,
  LISTED: `${STRIPE_SUBSCRIPTION}:listed`,
  UPDATED: `${STRIPE_SUBSCRIPTION}:updated`,
  VIEWED: `${STRIPE_SUBSCRIPTION}:viewed`,
} as const;

export type StripeSubscriptionEventName = typeof StripeSubscriptionEventNameMap[keyof typeof StripeSubscriptionEventNameMap];

export const StripeSubscriptionEventNames = Object.values(StripeSubscriptionEventNameMap);

export class StripeSubscriptionGetAction extends ModelAction<StripeSubscriptionGetPayload> {

  readonly action_name = StripeSubscriptionActionNameMap.GET;

  constructor(input: Partial<Omit<StripeSubscriptionGetAction, 'action_name'>>){

    super(input);

  }

}

export type StripeSubscriptionGetPayload = Stripe.SubscriptionRetrieveParams & {
  id: string;
};

export class StripeSubscriptionListAction extends ModelAction<StripeSubscriptionListPayload> {

  readonly action_name = StripeSubscriptionActionNameMap.LIST;

  constructor(input: Partial<Omit<StripeSubscriptionListAction, 'action_name'>>){

    super(input);

  }

}

export type StripeSubscriptionListPayload = Stripe.SubscriptionListParams;

export class StripeSubscriptionListedEvent extends ModelEvent<Stripe.ApiList<Stripe.Subscription>> {

  readonly event_name = StripeSubscriptionEventNameMap.LISTED;

  constructor(input: Partial<Omit<StripeSubscriptionListedEvent, 'event_name'>>) {

    super(input);

  }

}

export class StripeSubscriptionViewedEvent extends ModelEvent<Stripe.Subscription> {

  readonly event_name = StripeSubscriptionEventNameMap.VIEWED;

  constructor(input: Partial<Omit<StripeSubscriptionViewedEvent, 'event_name'>>) {

    super(input);

  }

}
