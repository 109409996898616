<mat-card>

  <mat-card-header>

    <mat-card-title>Best CX Ever For Coaches</mat-card-title>
    <mat-card-title>How to provide the best customer experiences every time.</mat-card-title>

  </mat-card-header>

  <mat-card-content>

    <p>We have development a list of best practices that every coach should implement to provide 
      the best customer experiences, every time. Our list of practices was developed to achieve 
      the following goals:</p>

    <ul>
      <li>increase your customer base</li>
      <li>raise customer satifaction ratings</li>
      <li>get more referrals</li>
      <li>lower your turn over rate</li>
      <li>increase average purchase size</li>
      <li>sell more premium products or services</li>
    </ul>

  </mat-card-content>

  <mat-card-actions>



  </mat-card-actions>

</mat-card>