import { Component, Input } from '@angular/core';
import { SubsComponent } from '@bcx/ng-helpers';
import { SeatService } from '../seat.service';
import { TrainingSeat } from '@bcx/models';

@Component({
  selector: 'ng-training-seat-resend-confirmation-button',
  templateUrl: './resend-confirmation-button.component.html',
  styleUrls: ['./resend-confirmation-button.component.sass'],
})
export class ResendConfirmationButtonComponent extends SubsComponent {

  @Input() color = 'primary';

  error?: string;

  processing: boolean;

  @Input() seat: TrainingSeat;

  constructor(private service: SeatService) {

    super();

  }

  resend() {

    return this.service.sendConfirmation(this.seat, {})
    .catch((e: Error) => {

      console.error(e);

    })
    .finally(() => {

      this.subs['seat'].unsubscribe();

    });

  }

}
