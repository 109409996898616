import { RestClientRequest } from "@bcx/models";
import Joi from "joi";
import { action_id, action_name, payload } from "./model-action";
import { epoch } from "@digitaltoolbuilders/schema-helpers";
import { user_id } from "./common";

export const request = Joi.object<RestClientRequest>()
  .keys({
    action_id: action_id.required(),
    action_name: action_name.required(),
    epoch: epoch.required(),
    payload: payload.required(),
    user_id: user_id.required(),
  });
