import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StripePaymentElement, StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { Subscription } from 'rxjs';
import { Stripe } from 'stripe';
import { PaymentMethodService } from '../payment-method.service';
import { AddState } from '../state/payment-method.reducer';

export interface AddDialogData {

  return_url: string;

}

@Component({
  selector: 'ng-stripe-payment-method-add-dialog',
  templateUrl: './add-dialog.component.html',
  styleUrls: ['./add-dialog.component.sass'],
})
export class AddDialogComponent implements AfterViewInit, OnDestroy, OnInit {

  error?: string;

  form: FormGroup;

  paymentElement: StripePaymentElement;

  paymentElementControl: FormControl;

  paymentElementPromise: Promise<any>;

  processing = false;

  ready = false;

  returnUrl: string;

  setupIntent?: Stripe.SetupIntent;

  private subs: {[key: string]: Subscription} = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) data: AddDialogData,
    private dialog: MatDialogRef<AddDialogComponent>,
    private fb: FormBuilder,
    private service: PaymentMethodService,
  ) {

    this.paymentElementControl = this.fb.control(false, [Validators.requiredTrue]);

    this.form = this.fb.group({
      paymentElement: this.paymentElementControl
    });

    this.returnUrl = data.return_url;

  }

  close() {

    this.dialog.close();

  }

  createPaymentElement(setupIntent: Stripe.SetupIntent) {

    return this.service.createPaymentElement(setupIntent)
    .then((element: StripePaymentElement) => {

      this.paymentElement = element;

      this.paymentElement.on('change', (event: StripePaymentElementChangeEvent) => {

        console.log('paymentElementChange', event);

        this.paymentElementControl.patchValue(event.complete);

      });

    });

  }
  
  createSetupIntent() {

    this.paymentElementPromise = this.service.add()
    .then((setupIntent: Stripe.SetupIntent) => {

      return this.createPaymentElement(setupIntent);

    })
    .catch((e: any) => {

      console.error(e);

    });

  }

  ngAfterViewInit(): void {
    
    this.paymentElementPromise.then(() => {

      this.paymentElement.mount('#stripe-payment-element');

      this.paymentElement.on('ready', () => {

        this.ready = true;

      })

    });

  }

  ngOnDestroy(): void {
    
    Object.values(this.subs).forEach(sub => sub.unsubscribe());

  }

  ngOnInit(): void {

    this.subs['add'] = this.service.add$.subscribe((add: AddState) => {

      this.error = add.error;

      this.processing = add.processing;

      this.setupIntent = add.setupIntent;

    });

    this.createSetupIntent();

  }

  retry() {

    if (this.setupIntent) {

      this.submit();

    } else {

      this.createSetupIntent();

    }

  }

  submit() {

    return this.service.confirm(this.returnUrl)
    .catch((e: any) => {

      console.error(e);

    });

  }

}
