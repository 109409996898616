import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ng-training-ticket-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.sass'],
})
export class CreateFormComponent {

  @Input() form: FormGroup;

}
