import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { OfficeHoursService } from '@engagedcx/ng-calendar';
import { SubsComponent } from '@bcx/ng-helpers';
import { addDays, format, isValid } from 'date-fns';
import { combineLatest, distinctUntilChanged, startWith } from 'rxjs';

@Component({
  selector: 'ng-training-class-select-start',
  templateUrl: './select-start.component.html',
  styleUrls: ['./select-start.component.sass'],
})
export class SelectStartComponent  extends SubsComponent implements OnChanges {

  @Input() control: FormControl;

  dt: Date;

  dateControl: FormControl;

  localTimeZone = this.officeHours.localTimeZone;

  minDate = addDays(new Date(), 1);

  officeHoursEnd = this.officeHours.endTime;

  officeHoursStart = this.officeHours.startTime;

  timeControl: FormControl;

  constructor(
    private fb: FormBuilder,
    private officeHours: OfficeHoursService,
  ) {

    super();

    this.dateControl = this.fb.control('', [Validators.required]);

    this.timeControl = this.fb.control('', [Validators.required]);

    this.subscribeToInternal();

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['control'] && this.control) {

      this.onControlChange();

    }

  }

  private onControlChange() {

    if (this.subs['external']) {

      this.subs['external'].unsubscribe();

    }

    if (this.control) {

      this.subs['external'] = this.control.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((epoch: number) => {

        this.onControlValueChange(epoch);

      });

      this.onControlValueChange(this.control.value);

    }

  }

  private onControlValueChange(epoch?: number) {

    if (epoch) {

      const dt = new Date(epoch);
      
      if (isValid(dt)) {

        const time = format(dt, 'h:mm a');
    
        // console.log('patching date & time', dt, time);

        this.dateControl.patchValue(dt);
        this.timeControl.patchValue(time);
    
      }
  
    }

  }

  private subscribeToInternal() {

    // console.log('subscribing to interal');

    this.subs['controls'] = combineLatest({
      date: this.dateControl.valueChanges.pipe(startWith(null)).pipe(distinctUntilChanged()),
      time: this.timeControl.valueChanges.pipe(startWith(null)).pipe(distinctUntilChanged()),
    })
    .subscribe(({ date, time }) => {

      // console.log('select-start', 'controls', date, time);

      if (date && time) {

        const value = Date.parse(`${format(date, 'MM/dd/yyyy')} ${time}`);

        // console.log('select-start', 'date && time', date, time, value);

        this.control.patchValue(value);

      } else if (date) {

        // console.log('select-start', 'only date', date);

      } else if (time) {

        // console.log('select-start', 'only time', time);

      }

    });

  }

}
