import { Component, Input } from '@angular/core';
import { ReleaseForm } from './release-form.form';

@Component({
  selector: 'ng-training-seat-release-form',
  templateUrl: './release-form.component.html',
  styleUrls: ['./release-form.component.sass'],
})
export class ReleaseFormComponent {

  @Input() form: ReleaseForm;

}
