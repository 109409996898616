<div class="flex flex-col">

  <div class="flex flex-row justify-center">

    <h1 class="text-2xl">Consulting</h1>

  </div>

  <div class="flex flex-col">

    <c-carousel class="min-h-[200px]" [dark]="true">
      <c-carousel-indicators></c-carousel-indicators>
      <c-carousel-inner>

        <c-carousel-item>
          
          <div class="item">

            <h3>Need help solving your CX problem?</h3>

          </div>

          <c-carousel-caption class="d-none d-md-block">
            <h3>Need help solving your CX problem?</h3>
          </c-carousel-caption>

        </c-carousel-item>

        <c-carousel-item>
          
          <div class="item owner">

            <h3>Want help writing scripts specific to your job?</h3>

          </div>

          <c-carousel-caption class="d-none d-md-block">
            <h3>Want help writing scripts specific to your job?</h3>
          </c-carousel-caption>

        </c-carousel-item>

        <c-carousel-item>
          
          <div class="item">

            <h3>Need help balancing all 3 roles?</h3>

          </div>

          <c-carousel-caption class="d-none d-md-block">
            <h3>Need help balancing all 3 roles?</h3>
          </c-carousel-caption>

        </c-carousel-item>

        <c-carousel-item>
          
          <div class="item">

            <h3>Want to learn how to get rid of negativity?</h3>

          </div>

          <c-carousel-caption class="d-none d-md-block">
            <h3>Want to learn how to get rid of negativity?</h3>
          </c-carousel-caption>

        </c-carousel-item>

      </c-carousel-inner>
      <c-carousel-control [routerLink] caption="Previous" direction="prev"></c-carousel-control>
      <c-carousel-control [routerLink] caption="Next" direction="next"></c-carousel-control>
    </c-carousel>

  </div>

  <div class="flex flex-row justify-around">

    <button class="animate-bounce" type="button" color="primary"
      mat-raised-button>Schedule Meeting</button>

    <a mat-raised-button routerLink="/consulting">Learn More</a>

  </div>

</div>
