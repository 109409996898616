import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ConsultingBillingPreferenceMap, USD } from "@bcx/models";

export class SaveForm extends FormGroup {

  constructor() {

    super({
      consulting_billing_preference: new FormControl(ConsultingBillingPreferenceMap.INVOICE_PER_SESSION, [Validators.required]),
      currency: new FormControl(USD, [Validators.required]),
      default_payment_method: new FormControl(null, [Validators.required]),
    });

  }

}
