import { Injectable } from '@angular/core';
import { PersonAttributeServiceAdapter } from '../attribute.service';
import { PersonAttributeListPayload, PersonAttribute, PersonAttributeSavePayload, PersonAttributeGetPayload, PersonAttributeActionNameMap, PersonAttributeInterface } from '@bcx/models';
import { List } from '@digitaltoolbuilders/model-helpers';
import { WebSocketService } from '@engagedcx/ng-amplify';
import { IdService } from '@bcx/ng-helpers';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements PersonAttributeServiceAdapter {

  constructor(
    private id: IdService,
    private websocket: WebSocketService,
  ) { }

  get(clean: PersonAttributeGetPayload, now = Date.now()): Promise<PersonAttribute> {
    
    return this.websocket.request<PersonAttributeInterface>({
      action_id: this.id.uuid(),
      action_name: PersonAttributeActionNameMap.GET,
      epoch: now,
      model_id: '',
      payload: clean,
      user_id:'',
    })
    .then((message) => {

      return new PersonAttribute(message.payload);

    });

  }

  list(clean: PersonAttributeListPayload, now = Date.now()): Promise<List<PersonAttribute>> {
    
    return this.websocket.request<List<PersonAttributeInterface>>({
      action_id: this.id.uuid(),
      action_name: PersonAttributeActionNameMap.LIST,
      epoch: now,
      model_id: '',
      payload: clean,
      user_id:'',
    })
    .then((message) => {

      return new List({
        ...message.payload,
        list: message.payload.list.map((item) => new PersonAttribute(item)),
      });

    });

  }

  save(clean: PersonAttributeSavePayload, now = Date.now()): Promise<PersonAttribute> {

    return this.websocket.request<PersonAttributeInterface>({
      action_id: this.id.uuid(),
      action_name: PersonAttributeActionNameMap.SAVE, 
      epoch: now,
      model_id: '',
      payload: clean,
      user_id: '',
    })
    .then((message) => {

      return new PersonAttribute(message.payload);

    });

  }

}
