import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BooksComponent } from './books/books.component';
import { CustomerServiceRolesComponent } from './customer-service-roles/customer-service-roles.component';
import { ProductsServicesComponent } from './products-services/products-services.component';
import { TrainingComponent } from './training/training.component';
import { ConsultingComponent } from './consulting/consulting.component';
import { BestPracticesComponent } from './best-practices/best-practices.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { CoachModule } from '../coach/coach.module';
import { OwnerModule } from '../owner/owner.module';
import { ProviderModule } from '../provider/provider.module';

@NgModule({
  declarations: [
    BooksComponent,
    CustomerServiceRolesComponent,
    ProductsServicesComponent,
    TrainingComponent,
    ConsultingComponent,
    BestPracticesComponent,
  ],
  exports: [
    BooksComponent,
    CustomerServiceRolesComponent,
    ProductsServicesComponent,
    TrainingComponent,
    ConsultingComponent,
    BestPracticesComponent,
  ],
  imports: [
    CoachModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    OwnerModule,
    ProviderModule,
  ],
})
export class AdsModule {}
