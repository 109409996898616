import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConsultingSessionCategories } from '@bcx/models';

@Component({
  selector: 'ng-consulting-session-select-category',
  templateUrl: './select-category.component.html',
  styleUrls: ['./select-category.component.sass'],
})
export class SelectCategoryComponent {

  categories = ConsultingSessionCategories;

  @Input() control: FormControl;

  @Input() currency?: string;

  @Input() sessionType: string;

}
