
import * as Joi from 'joi';
import { ModelAction, UserChangeRolesPayload, UserCreatePayload, UserFindPayload, UserGetPayload, UserUpdatePayload, UserRole, UserRoles, UserListPayload, UserStatuses, UserListAction, UserUpdateAction, UserCreateAction, UserActionNameMap, UserFindAction, UserGetAction } from '@bcx/models';
import { epoch } from '@digitaltoolbuilders/schema-helpers';
import { action_id, action_name, createActionSchema } from '../model-action';
import { consistent_read, exclusive_start_key, limit } from '../dynamo-db';
import { user_id } from '../common';
  
export const email = Joi.string().email({
  tlds: false,
});

export const email_verified = Joi.boolean().default(false);

export const family_name = Joi.string().max(100);

export const given_name = Joi.string().max(100);

export const local_time_zone = Joi.string().max(100);

export const name = Joi.string().max(200);

export const phone_number = Joi.string();

export const phone_number_verified = Joi.boolean().default(false);

export const picture = Joi.string().uri({
  domain: {
    tlds: false,
  },
});

export const role = Joi.string().allow(...UserRoles);

export const roles = Joi.array<Array<UserRole>>()
  .items(role);

export const status = Joi.string().allow(...UserStatuses);

export const changeRoles = Joi.object<UserChangeRolesPayload>()
  .keys({
    last_updated_epoch: epoch.required(),
    roles: roles.required(),
    user_id: user_id.required(),
  });

export const changeRolesAction = Joi.object<ModelAction<UserChangeRolesPayload>>()
  .keys({
    action_id: action_id.required(),
    action_name: action_name.required(),
    epoch: epoch.required(),
    payload: changeRoles.required(),
    user_id: user_id.required(),
  });

export const create = Joi.object<UserCreatePayload>()
  .keys({
    email: email.optional().allow(null),
    family_name: family_name.optional().allow(null),
    given_name: given_name.optional().allow(null),
    local_time_zone: local_time_zone.required(),
    name: name.required(),
    phone_number: phone_number.optional().allow(null),
    picture: picture.optional().allow(null),
    user_id: user_id.required(),
  });

export const createAction = createActionSchema<UserCreateAction>(UserActionNameMap.CREATE, create);

export const find = Joi.object<UserFindPayload>()
  .keys({
    email: email.optional().allow(null),
    phone_number: email.optional().allow(null),
  })
  .xor('email', 'phone_number');

export const findAction = createActionSchema<UserFindAction>(UserActionNameMap.FIND, find);

export const get = Joi.object<UserGetPayload>()
  .keys({
    consistent_read: consistent_read,
    user_id: user_id.required(),
  });

export const getAction = createActionSchema<UserGetAction>(UserActionNameMap.GET, get);

export const list = Joi.object<UserListPayload>()
  .keys({
    exclusive_start_key: exclusive_start_key,
    limit: limit,
    status: status,
  });

export const listAction = createActionSchema<UserListAction>(UserActionNameMap.LIST, list);

export const update = Joi.object<UserUpdatePayload>()
  .keys({
    family_name: family_name.optional().allow(null),
    given_name: given_name.optional().allow(null),
    last_updated_epoch: epoch.required(),
    local_time_zone: local_time_zone.required(),
    name: name.required(),
    picture: picture.optional().allow(null),
    user_id: user_id.required(),
  });

export const updateAction = createActionSchema<UserUpdateAction>(UserActionNameMap.UPDATE, update);
