import { FormControl, FormGroup, Validators } from "@angular/forms";

export class MySettingsForm extends FormGroup {

  constructor() {

    super({
      family_name: new FormControl('', []),
      given_name: new FormControl('', []),
      local_time_zone: new FormControl('', []),
      name: new FormControl('', [Validators.required]),
    });

  }

}
