<div fxLayout="column" fxLayoutGap="10px">

  <mat-divider></mat-divider>

  <ng-consulting-session-select-start fxFlex
    [control]="scheduledStartEpoch"></ng-consulting-session-select-start>

  <mat-divider></mat-divider>

  <ng-consulting-session-select-length fxFlex
    [control]="scheduledLengthMinutes"></ng-consulting-session-select-length>

</div>