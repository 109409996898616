import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BusinessEmployeeCountGroups } from '@bcx/models';

@Component({
  selector: 'app-question-number-of-people',
  templateUrl: './number-of-people.component.html',
  styleUrl: './number-of-people.component.sass',
})
export class NumberOfPeopleComponent {

  @Input() control: FormControl;

  @Input() label = 'How many people?';

  groups = BusinessEmployeeCountGroups;

}
