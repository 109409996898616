
export * from './class/class.exports';

export * from './course/course.exports';

export * from './payment-agreement/payment-agreement.exports';

export * from './seat/seat.exports';

export * from './ticket/ticket.exports';

export { TrainingModule } from './training.module';
