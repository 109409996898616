import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BOOK, CONSULTING, Path, PathStep, PathStepTypeMap, TRAINING } from '@bcx/models';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-other-options',
  templateUrl: './other-options.component.html',
  styleUrl: './other-options.component.sass',
})
export class OtherOptionsComponent {

  BOOK = BOOK;

  CONSULTING = CONSULTING;

  options: Array<PathStep> = [
    {
      step_type: BOOK,
      stripe_product_id: '',
    },
    {
      step_type: TRAINING,
      stripe_product_id: '',
    },
  ];

  path: Path;

  TRAINING = TRAINING;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: Path,
    private dialog: MatDialogRef<OtherOptionsComponent>,
  ) {

    this.path = data;

  }

  closeDialog() {

    this.dialog.close(this.path);

  }

  isBookFirst() {

    const first = this.path.steps[0];

    if (first.step_type === PathStepTypeMap.BOOK) {

      return true;

    }

    return false;

  }

  isTrainingFirst() {

    const first = this.path.steps[0];

    if (first.step_type === PathStepTypeMap.TRAINING) {

      return true;

    }

    return false;

  }

  selectBook(option: PathStep) {

    const path = cloneDeep(this.path);

    if (this.isTrainingFirst()) {

      const bookStep: PathStep = {
        step_type: PathStepTypeMap.BOOK,
        stripe_product_id: option.stripe_product_id,
      };

      path.steps = [bookStep].concat(path.steps);

    }

    this.dialog.close(path);

  }

  selectConsulting(option: PathStep) {

    console.log('selectConsulting', option);

  }

  selectTraining(option: PathStep) {

    console.log('selectTraining', option);

    const path = cloneDeep(this.path);

    if (this.isBookFirst()) {

      path.steps.shift();

    }

    this.dialog.close(path);

  }

}
