import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { SubsComponent } from '@bcx/ng-helpers';
import { SeatService } from '../../seat/seat.service';
import { TrainingSeat } from '@bcx/models';

@Component({
  selector: 'ng-training-class-my-seat',
  templateUrl: './my-seat.component.html',
  styleUrls: ['./my-seat.component.sass'],
})
export class MySeatComponent extends SubsComponent implements OnChanges, OnInit {

  @Input() actions: TemplateRef<any>;

  @Input() classId?: string;

  error?: string;

  loading: boolean;

  seat?: TrainingSeat;

  seats: Array<TrainingSeat>;

  constructor(private service: SeatService) {

    super();

  }

  findSeat() {

    if (this.classId && this.seats) {

      this.seat = this.seats.find((seat: TrainingSeat) => {

        return seat.class_id === this.classId;

      });

    } else {

      this.seat = undefined;

    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['classId']) {

      this.onClassIdChange();

    }

  }

  ngOnInit(): void {
    
    // this.subs['seats'] = this.service.my$
    // .subscribe(({ error, loading, models, }) => {

    //   this.error = error;

    //   this.loading = loading;

    //   this.seats = models;

    // });

  }

  onClassIdChange() {

    this.findSeat();

  }

}
