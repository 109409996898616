<div class="flex flex-col h-full w-full">

  <div class="shrink-outer">

    <div class="shrink-inner">

      <div class="flex flex-col w-full">

        <app-header></app-header>

        <app-banner></app-banner>

      </div>

    </div>

  </div>

  <div class="flex flex-col">

    <div class="flex flex-col">

      <div class="border-x-2 border-slate-300 flex flex-col max-w-4xl mx-auto">

        <router-outlet></router-outlet>
    
      </div>
    
    </div>

  </div>

  <app-footer></app-footer>

</div>