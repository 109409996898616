
import { MetaData } from "../meta-data";
import { PERSON } from "../common";
import { ConsistentRead, ExclusiveStartKey, Limit } from "../helpers";
import { ModelAction, ModelEvent, ModelAbstract, ModelInterface } from "../model-action";
import { List } from "@digitaltoolbuilders/model-helpers";

export * from './attribute';

export * from './contact-point';

export * from './email-address';

export class Person extends ModelAbstract implements PersonInterface {

  account_id: string;

  meta_data: MetaData = {};

  person_id: string;

  constructor(input: Partial<Person>) {

    super(input);

    if (input.account_id) this.account_id = input.account_id;
    if (input.meta_data) this.meta_data = input.meta_data;
    if (input.person_id) this.person_id = input.person_id;

  }

  static override generateModelId(input: Pick<Person, 'account_id' | 'person_id'>) {

    return `${input.account_id}:${input.person_id}`;

  }

}

export class PersonCreateAction extends ModelAction<PersonCreatePayload> {

  override readonly action_name = PersonActionNameMap.CREATE;

  constructor(input: Partial<Omit<PersonCreateAction, 'action_name'>>) {
    
    super(input);

  }

}

export type PersonCreatePayload = PersonInterface;

export class PersonGetAction extends ModelAction<PersonGetPayload> {

  override readonly action_name = PersonActionNameMap.GET;

  constructor(input: Partial<Omit<PersonGetAction, 'action_name'>>) {
    
    super(input);
    
  }

}

export type PersonGetPayload = 
  & Pick<Person, 'account_id' | 'person_id'>
  & { consistent_read?: ConsistentRead };

export class PersonListAction extends ModelAction<PersonListPayload> {

  override readonly action_name = PersonActionNameMap.LIST;

  constructor(input: Partial<Omit<PersonListAction, 'action_name'>>) {
    
    super(input);
    
  }

}
  
export type PersonListPayload =
  & Pick<Person, 'account_id'>
  & {
    exclusive_start_key: ExclusiveStartKey,
    limit: Limit,
  };

export class PersonMergeAction extends ModelAction<PersonMergePayload> {

  override readonly action_name = PersonActionNameMap.MERGE;

  constructor(input: Partial<Omit<PersonMergeAction, 'action_name'>>) {
    
    super(input);
    
  }

}
  
export type PersonMergePayload = 
  & Pick<Person, 'account_id' | 'last_updated_epoch' | 'person_id'> 
  & {
    people_to_merge: Array<string>;
  };

export class PersonUpdateAction extends ModelAction<PersonUpdatePayload> {

  override readonly action_name = PersonActionNameMap.UPDATE;

  constructor(input: Partial<Omit<PersonUpdateAction, 'action_name'>>) {
    
    super(input);
    
  }

}
  
export type PersonUpdatePayload = Omit<Person, 'created_epoch'>;

export const PersonActionNameMap = {
  CREATE: `${PERSON}/create`,
  GET: `${PERSON}/get`,
  LIST: `${PERSON}/list`,
  ME: `${PERSON}/me`,
  MERGE: `${PERSON}/merge`,
  UPDATE: `${PERSON}/update`,
} as const;

export type PersonActionName = typeof PersonActionNameMap[keyof typeof PersonActionNameMap];

export const PersonActionNames = Object.values(PersonActionNameMap);

export class PersonCreatedEvent extends ModelEvent<Person> {

  override readonly event_name = PersonEventNameMap.CREATED;

  constructor(input: Partial<Omit<PersonCreatedEvent, 'event_name'>>) {

    super(input);
    
  }

}

export class PersonDeletedEvent extends ModelEvent<Person> {

  override readonly event_name = PersonEventNameMap.DELETED;

  constructor(input: Partial<Omit<PersonDeletedEvent, 'event_name'>>) {

    super(input);
    
  }

}

export class PersonListedEvent extends ModelEvent<List<Person>> {

  override readonly event_name = PersonEventNameMap.LISTED;

  constructor(input: Partial<Omit<PersonListedEvent, 'event_name'>>) {

    super(input);
    
  }

}

export class PersonMergedEvent extends ModelEvent<Person> {

  override readonly event_name = PersonEventNameMap.MERGED;

  constructor(input: Partial<Omit<PersonMergedEvent, 'event_name'>>) {

    super(input);
    
  }

}

export class PersonUpdatedEvent extends ModelEvent<Person> {

  override readonly event_name = PersonEventNameMap.UPDATED;

  constructor(input: Partial<Omit<PersonUpdatedEvent, 'event_name'>>) {

    super(input);
    
  }

}

export class PersonViewedEvent extends ModelEvent<Person> {

  override readonly event_name = PersonEventNameMap.VIEWED;

  constructor(input: Partial<Omit<PersonViewedEvent, 'event_name'>>) {

    super(input);
    
  }

}

export const PersonEventNameMap = {
  CREATED: `${PERSON}:created`,
  DELETED: `${PERSON}:deleted`,
  LISTED: `${PERSON}:listed`,
  MERGED: `${PERSON}:merged`,
  UPDATED: `${PERSON}:updated`,
  VIEWED: `${PERSON}:viewed`,
} as const;

export type PersonEventName = typeof PersonEventNameMap[keyof typeof PersonEventNameMap];

export const PersonEventNames = Object.values(PersonEventNameMap);

export interface PersonInterface extends ModelInterface {

  account_id: string;

  meta_data: MetaData;

  person_id: string;

}
