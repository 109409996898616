
<section class="flex flex-col justify-center items-center xs:flex-row xs:justify-between py-2"
  [formGroup]="form">
  
  <div class="flex flex-col">

    <ng-content></ng-content>

  </div>

  <mat-button-toggle-group class="flex min-w-fit"
    [formControlName]="goalName">

    <mat-button-toggle value="less">Less</mat-button-toggle>
    
    <mat-button-toggle value="important">Important</mat-button-toggle>

    <mat-button-toggle value="more">More</mat-button-toggle>

  </mat-button-toggle-group>
  
</section>
