import { STRIPE_CHARGE, StripeChargeGetAction, StripeChargeGetPayload, StripeChargeListAction, StripeChargeListPayload } from "@bcx/models";
import Joi from "joi";
import { createActionSchema } from "../model-action";
import { charge_id, customer_id } from './common';

export const get = Joi.object<StripeChargeGetPayload>()
  .keys({
    id: charge_id.required(),
  });

export const getAction = createActionSchema<StripeChargeGetAction>(STRIPE_CHARGE, get);

export const list = Joi.object<StripeChargeListPayload>()
  .keys({
    customer: customer_id.optional().allow(null),
  });

export const listAction = createActionSchema<StripeChargeListAction>(STRIPE_CHARGE, list);
