import { inject, Inject, Injectable } from '@angular/core';
import { ModelAction, USER, User, UserChangeRolesAction, UserChangeRolesPayload, UserCreateAction, UserCreatePayload, UserGetAction, UserGetPayload, UserListAction, UserListPayload, UserUpdateAction, UserUpdatePayload } from '@bcx/models';
import { user as schemas } from '@bcx/schemas';
import { StateFacade, StateService } from '../state/state.service';
import { UserServiceAdapter } from './user.service.adapter';
import { BACKEND_ADAPTER, BackendAdapter, BackendAdapterMap, IdService } from '@bcx/ng-helpers';
import { RestService } from './rest.service';
import { WebsocketService } from './websocket.service';
import { List } from '@digitaltoolbuilders/model-helpers';
import { isAllowed } from '@engagedcx/acl';

export function createStateId(input: Pick<User, 'user_id'>) {

  return User.generateModelId(input);

}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private adapter: UserServiceAdapter;

  private state: StateFacade<User, List<User>>;

  constructor(
    @Inject(BACKEND_ADAPTER) adapter: BackendAdapter,
    private id: IdService,
    state: StateService,
  ) { 

    switch (adapter) {

      case BackendAdapterMap.REST:

        this.adapter = inject(RestService);

        break;

      case BackendAdapterMap.WEBSOCKET:

        this.adapter = inject(WebsocketService);

        break;

    }

    this.state = state.facade<User, List<User>>(USER, { createStateId });

  }

  changeRoles(original: User, data: Partial<UserChangeRolesPayload>, now = Date.now()) {

    data.last_updated_epoch = original.last_updated_epoch;
    data.user_id = original.user_id;

    return schemas.changeRoles.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new UserChangeRolesAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.changeRoles(action)
      .then((event) => {

        const model = new User(event.payload);

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  create(data: Partial<UserCreatePayload>, now = Date.now()) {

    data.user_id = this.id.uuid();

    return schemas.create.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new UserCreateAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.create(action)
      .then((event) => {

        const model = new User(event.payload);

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  createId() {

    return this.id.uuid();

  }

  get(data: UserGetPayload, now = Date.now()) {

    return schemas.get.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new UserGetAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        loading: true,
      });

      return this.adapter.get(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          loading: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          loading: false,
        });

        throw e;

      });

    });

  }

  isAllowed(action_name: string, user: User) {

    try {

      isAllowed(action_name, user, user);

      return true;

    } catch (e) {

      return false;

    }

  }

  list(data: UserListPayload, now = Date.now()) {

    return schemas.list.validateAsync(data, {})
    .then((clean) => {

      const group = 'all';

      const action = new UserListAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: group,
        payload: clean,
        user_id: '',
      });

      this.state.many({
        group,
        loading: true,
      });

      return this.adapter.list(action)
      .then((event) => {

        const list = event.payload;

        this.state.many({
          group,
          list: list.list,
          loading: false,
        });

        return list;

      })
      .catch((e) => {

        this.state.many({
          e,
          group,
          processing: false,
        });

        throw e;

      });

    });

  }

  select(input: Pick<User, 'user_id'>) {

    const id = createStateId(input);

    return this.state.select(id);

  }

  update(original: User, data: Partial<UserUpdatePayload>, now = Date.now()) {

    data.last_updated_epoch = original.last_updated_epoch;
    data.user_id = original.user_id;

    return schemas.update.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new UserUpdateAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.update(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

}
