import { PersonCreateAction, PersonCreatePayload, PersonGetAction, PersonGetPayload, PersonListAction, PersonListPayload, PersonMergeAction, PersonMergePayload, PersonUpdateAction, PersonUpdatePayload } from "@bcx/models";
import { epoch } from "@digitaltoolbuilders/schema-helpers";
import Joi from "joi";
import { meta_data } from "../meta-data";
import { consistent_read, exclusive_start_key, limit } from "../dynamo-db";
import { account_id } from "../account/account";
import { action_id, action_name } from "../model-action";
import { user_id } from "../common";

export const person_id = Joi.string().max(200);

export const create = Joi.object<PersonCreatePayload>()
  .keys({
    account_id,
    created_epoch: epoch.optional().allow(null),
    meta_data: meta_data.required(),
    person_id: person_id.required(),
  });

export const createAction = Joi.object<PersonCreateAction>()
  .keys({
    action_id: action_id.required(),
    action_name: action_name.required(),
    epoch: epoch.required(),
    payload: create.required(),
    user_id: user_id.required(),
  });

export const get = Joi.object<PersonGetPayload>()
  .keys({
    account_id,
    consistent_read: consistent_read,
    person_id: person_id.required(),
  });

export const getAction = Joi.object<PersonGetAction>()
  .keys({
    action_id: action_id.required(),
    action_name: action_name.required(),
    epoch: epoch.required(),
    payload: get.required(),
    user_id: user_id.required(),
  });

export const list = Joi.object<PersonListPayload>()
  .keys({
    account_id,
    exclusive_start_key: exclusive_start_key,
    limit: limit,
  });

export const listAction = Joi.object<PersonListAction>()
  .keys({
    action_id: action_id.required(),
    action_name: action_name.required(),
    epoch: epoch.required(),
    payload: list.required(),
    user_id: user_id.required(),
  });

export const merge = Joi.object<PersonMergePayload>()
  .keys({
    person_id: person_id.required(),
    people_to_merge: Joi.array<Array<string>>().items(person_id.required()),
  });

export const mergeAction = Joi.object<PersonMergeAction>()
  .keys({
    action_id: action_id.required(),
    action_name: action_name.required(),
    epoch: epoch.required(),
    payload: merge.required(),
    user_id: user_id.required(),
  });

export const update = Joi.object<PersonUpdatePayload>()
  .keys({
    account_id,
    last_updated_epoch: epoch.required(),
    meta_data: meta_data.required(),
    person_id: person_id.required(),
  });

export const updateAction = Joi.object<PersonUpdateAction>()
  .keys({
    action_id: action_id.required(),
    action_name: action_name.required(),
    epoch: epoch.required(),
    payload: update.required(),
    user_id: user_id.required(),
  });
