import { Component, Input } from '@angular/core';
import { ProductPrice } from '@bcx/models';

@Component({
  selector: 'ng-consulting-session-selected-price',
  templateUrl: './selected-price.component.html',
  styleUrls: ['./selected-price.component.sass'],
})
export class SelectedPriceComponent {

  @Input() price?: ProductPrice;

}
