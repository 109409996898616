import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-owner-consulting-card',
  templateUrl: './card.component.html',
  styleUrl: './card.component.sass',
})
export class CardComponent {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

}
