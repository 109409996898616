import { AxiosError } from "axios";
import Joi, { ValidationErrorItem } from "joi";

export class ConditionalCheckFailedException extends Error {

  constructor() {

    super('Conditional Check Failed');

    this.name = 'ConditionalCheckFailedException';
    Object.setPrototypeOf(this, new.target.prototype);

  }

}

export class InvalidRequestException extends Error {

  constructor(message: string, public data?: Array<Joi.ValidationErrorItem>) {

    super(message);

    this.name = 'InvalidRequestException';
    Object.setPrototypeOf(this, new.target.prototype);

  }

}

export class NotFoundException extends Error {

  constructor(message: string) {

    super(message);

    this.name = 'NotFoundException';

    Object.setPrototypeOf(this, new.target.prototype);

  }

}

export function convertException(e: AxiosError) {

  if (e.isAxiosError) {

    let message = e.message;

    if (e.response) {

      switch (e.response.status) {

        case 400:

          message = (e.response.data as Array<ValidationErrorItem>)[0]?.message || 'Invalid Request';

          return new InvalidRequestException(message, e.response.data as Array<Joi.ValidationErrorItem>);

        case 409:

          return new ConditionalCheckFailedException();

      }

    }

    return new Error(message);

  } else {

    return e;

  }

}
