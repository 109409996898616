import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BusinessOperationLengths } from '@bcx/models';

@Component({
  selector: 'app-question-length-of-time',
  templateUrl: './length-of-time.component.html',
  styleUrl: './length-of-time.component.sass',
})
export class LengthOfTimeComponent {

  @Input() control: FormControl;

  @Input() label = 'How long?';

  @Input() lengths = BusinessOperationLengths;

}
