import { Injectable } from '@angular/core';
import { ChargeServiceAdapter } from './charge.service.adapter';
import { WebSocketService } from '@engagedcx/ng-amplify';
import {Stripe} from 'stripe';
import { StripeChargeGetAction, StripeChargeListAction, StripeChargeListedEvent, StripeChargeViewedEvent } from '@bcx/models';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements ChargeServiceAdapter {

  constructor(private socket: WebSocketService) { }

  get(action: StripeChargeGetAction): Promise<StripeChargeViewedEvent> {
    
    return this.socket.request<Stripe.Charge>(action)
    .then((message) => {

      return new StripeChargeViewedEvent({
        ...message,
      });

    });

  }

  list(action: StripeChargeListAction): Promise<StripeChargeListedEvent> {
    
    return this.socket.request<Stripe.ApiList<Stripe.Charge>>(action)
    .then((message) => {

      return new StripeChargeListedEvent({
        ...message,
      });

    });

  }

}
