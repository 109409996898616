<div class="flex flex-col justify-between"
  *ngIf="(vm$ | async) as vm"
  [formGroup]="form">

  <h2 class="text-md">Which of these goals are important to you?</h2>

  <div class="flex flex-col divide-y-2">

    <app-goal-level-of-importance 
      [form]="form" 
      [goalName]="names.DECREASE_COST_PER_MINUTE">

      Decrease cost per minute

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.DECREASE_COST_PER_MINUTE])"
      [form]="form" 
      [goalName]="names.DECREASE_REPEAT_INTERACTIONS">

      Decrease repeat interactions

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.DECREASE_REPEAT_INTERACTIONS])"
      [form]="form" 
      [goalName]="names.DECREASE_TURN_OVER_RATE">

      Decrease turn over rate

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.DECREASE_TURN_OVER_RATE])"
      [form]="form" 
      [goalName]="names.INCREASE_CSAT">

      Raise customer satisfaction ratings

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.INCREASE_CSAT])"
      [form]="form" [goalName]="names.INCREASE_AVERAGE_PURCHASE">

      Increase average purchase size

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.INCREASE_AVERAGE_PURCHASE])"
      [form]="form" [goalName]="names.INCREASE_JOB_SATISFACTION">

      Increase customer service agent job satisfaction

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.INCREASE_JOB_SATISFACTION])"
      [form]="form" [goalName]="names.INCREASE_AVERAGE_PURCHASE">

      Increase average purchase amount

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.INCREASE_AVERAGE_PURCHASE])"
      [form]="form" [goalName]="names.INCREASE_COMPLIANCE_QUALITY_SCORES">

      Increase compliance and quality scores

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.INCREASE_COMPLIANCE_QUALITY_SCORES])"
      [form]="form" [goalName]="names.INCREASE_ROI">

      Increase return on investment

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.INCREASE_ROI])"
      [form]="form" [goalName]="names.INCREASE_CONVERSION_RATE">

      Increase converstion rate

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.INCREASE_CONVERSION_RATE])"
      [form]="form" [goalName]="names.INCREASE_CUSTOMER_BASE">

      Increase customer base

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.INCREASE_CUSTOMER_BASE])"
      [form]="form" [goalName]="names.MORE_PREMIUMS_UPGRADES">

      Sell more premium products or services

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.MORE_PREMIUMS_UPGRADES])"
      [form]="form" [goalName]="names.MORE_REFERRALS">

      Generate more referrals.

    </app-goal-level-of-importance>

  </div>

  <section class="flex flex-col"
    *ngIf="false">
    
    <mat-form-field>
      <mat-label>What other goals do you want to achieve:</mat-label>
      <textarea 
        formControlName="other"
        matInput></textarea>
    </mat-form-field>
    
  </section>

</div>