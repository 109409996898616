<div class="flex flex-col border">

  <div class="flex flex-row justify-around">

    <div class="flex flex-col">

      <mat-card class="purple-shadow margin20">
  
          <mat-card-header class="justify-center">
            
            <mat-card-title>
              
              <h1>Provider Best Practices</h1>
            
            </mat-card-title>
                       
          </mat-card-header>      

        <mat-card-content class="padding10">

          <h2>Specifically for Providers</h2>
          <h2>Love Your Job Again</h2>
          <h2>Get Your Promotion</h2>
          <h2>Provide The Best Experiences</h2>

        </mat-card-content>
        
        <mat-card-actions>

          <div class="flex flex-col margin10">
  
            <a color="basic"
            mat-raised-button
            routerLink="/training/register">View All</a> 
        
          </div>
 
        </mat-card-actions>

      </mat-card>
    
    </div>

    <div class="flex flex-col">

      <mat-card class="green-shadow margin20">

        <mat-card-header class="justify-center">

          <mat-card-title>Owner Best Practices</mat-card-title>
          
        </mat-card-header>

        <mat-card-content class="padding10">

          <h2>Developed over 25 years in CX</h2>
          <h2>Support Your Providers</h2>
          <h2>Record and Analyze Data</h2>
          <h2>Be Known For Being The Best</h2>

        </mat-card-content>
        
        <mat-card-actions>

          <div class="flex flex-col margin10">
  
            <a color="basic"
              mat-raised-button
              routerLink="/training/register">View All</a> 
        
          </div>
 
        </mat-card-actions>
 
      </mat-card>
    
    </div>

  </div>

</div>