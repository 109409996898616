import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListAccordionComponent } from './list-accordion/list-accordion.component';
import { ListTableComponent } from './list-table/list-table.component';
import { RemoveFormComponent } from './remove-form/remove-form.component';
import { SummaryCardComponent } from './summary-card/summary-card.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PipesModule } from '@bcx/ng-helpers';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    ListAccordionComponent,
    ListTableComponent,
    RemoveFormComponent,
    SummaryCardComponent,
  ],
  exports: [
    ListAccordionComponent,
    ListTableComponent,
    RemoveFormComponent,
    SummaryCardComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatTableModule,
    PipesModule,
    ReactiveFormsModule,
  ]
})
export class AttendeeModule { }
