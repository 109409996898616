import { Injectable } from '@angular/core';
import { SessionServiceAdapter } from './session.service.adapter';
import { CONSULTING_SESSION, ConsultingSession, ConsultingSessionCancelAction, ConsultingSessionCanceledEvent, ConsultingSessionConfirmAction, ConsultingSessionConfirmedEvent, ConsultingSessionFinishAction, ConsultingSessionFinishedEvent, ConsultingSessionGetAction, ConsultingSessionInterface, ConsultingSessionListAction, ConsultingSessionListedEvent, ConsultingSessionRequestAction, ConsultingSessionRequestedEvent, ConsultingSessionStartAction, ConsultingSessionStartedEvent, ConsultingSessionViewedEvent, RestServerResponse } from '@bcx/models';
import { ApiService } from '@engagedcx/ng-amplify';
import { List } from '@digitaltoolbuilders/model-helpers';

@Injectable({
  providedIn: 'root'
})
export class RestService implements SessionServiceAdapter {

  private path = `/${CONSULTING_SESSION}`;

  constructor(private rest: ApiService) { }

  cancel(action: ConsultingSessionCancelAction): Promise<ConsultingSessionCanceledEvent> {
    
    return this.rest.post<RestServerResponse<ConsultingSessionInterface>>(this.path, action)
    .then((response) => {

      return new ConsultingSessionCanceledEvent({
        ...response.data,
        payload: new ConsultingSession(response.data.payload),
      });

    });

  }

  confirm(action: ConsultingSessionConfirmAction): Promise<ConsultingSessionConfirmedEvent> {
      
    return this.rest.post<RestServerResponse<ConsultingSessionInterface>>(this.path, action)
    .then((response) => {

      return new ConsultingSessionConfirmedEvent({
        ...response.data,
        payload: new ConsultingSession(response.data.payload),
      });

    });

  }

  finish(action: ConsultingSessionFinishAction): Promise<ConsultingSessionFinishedEvent> {
      
    return this.rest.post<RestServerResponse<ConsultingSessionInterface>>(this.path, action)
    .then((response) => {

      return new ConsultingSessionFinishedEvent({
        ...response.data,
        payload: new ConsultingSession(response.data.payload),
      });

    });

  }

  get(action: ConsultingSessionGetAction): Promise<ConsultingSessionViewedEvent> {
      
    return this.rest.post<RestServerResponse<ConsultingSessionInterface>>(this.path, action)
    .then((response) => {

      return new ConsultingSessionViewedEvent({
        ...response.data,
        payload: new ConsultingSession(response.data.payload),
      });

    });

  }

  list(action: ConsultingSessionListAction): Promise<ConsultingSessionListedEvent> {
      
    return this.rest.post<RestServerResponse<List<ConsultingSessionInterface>>>(this.path, action)
    .then((response) => {

      return new ConsultingSessionListedEvent({
        ...response.data,
        payload: new List<ConsultingSession>({
          ...response.data.payload,
          list: response.data.payload.list.map((item) => new ConsultingSession(item)),
        }),
      });

    });

  }

  request(action: ConsultingSessionRequestAction): Promise<ConsultingSessionRequestedEvent> {
      
    return this.rest.post<RestServerResponse<ConsultingSessionInterface>>(this.path, action)
    .then((response) => {

      return new ConsultingSessionRequestedEvent({
        ...response.data,
        payload: new ConsultingSession(response.data.payload),
      });

    });

  }

  start(action: ConsultingSessionStartAction): Promise<ConsultingSessionStartedEvent> {
      
    return this.rest.post<RestServerResponse<ConsultingSessionInterface>>(this.path, action)
    .then((response) => {

      return new ConsultingSessionStartedEvent({
        ...response.data,
        payload: new ConsultingSession(response.data.payload),
      });

    });

  }

}
