<mat-card
  *ngIf="user">

  <mat-card-header>

    <mat-card-title>{{user.name}}</mat-card-title>
    <mat-card-subtitle>{{user.status}}</mat-card-subtitle>

  </mat-card-header>

  <mat-card-content>

    <pre>{{user | json}}</pre>

  </mat-card-content>
 
  <mat-card-actions>

    <ng-container *ngTemplateOutlet="actions; context: { user }"></ng-container>

  </mat-card-actions>

</mat-card>
