import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, distinctUntilChanged, take } from 'rxjs';
import axios, { AxiosResponse } from 'axios';
import { DEFAULT_REGION } from './common';
import { AppConfigMap, AppRegionalConfig, Region, Regions } from '@bcx/iso';
import { LoggerService } from './logger.service';
import { Logger } from 'pino';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private active = new BehaviorSubject<Partial<AppRegionalConfig>>({});

  active$ = this.active.asObservable();

  private configMap: AppConfigMap;

  private logger: Logger;

  private region: BehaviorSubject<Region>;

  region$: Observable<string>;

  configMapURL = 'assets/app-config-map.json';

  constructor(
    @Inject(DEFAULT_REGION) defaultRegion: Region,
    parent: LoggerService,
  ) { 

    this.configMap = {
      defaultRegion,
      regionalConfigs: {},
      regions: Regions,
    };
    
    this.logger = parent.child({
      service: 'ConfigService',
    });

    this.region = new BehaviorSubject(defaultRegion);

    this.region$ = this.region.pipe(distinctUntilChanged());

  }

  fetchConfigMap() {

    this.logger.debug('fetchConfigMap');

    return axios.request({
      method: 'GET',
      responseType: 'json',
      url: this.configMapURL,
    })
    .then((response: AxiosResponse<AppConfigMap>) => {

      const configMap = response.data;

      this.configMap = configMap;

      this.logger.debug({ configMap }, 'fetchConfigMap');

      this.selectRegion(configMap.defaultRegion);

      return configMap;

    })
    .catch((e) => {

      this.logger.error(e);

      return this.configMap;

    });

  }

  selectRegion(region: Region) {

    this.logger.debug({ region }, 'selectRegion');

    this.logger.debug({ configMap: this.configMap }, 'selectRegion');

    this.region.next(region);

    const config: AppRegionalConfig = this.configMap.regionalConfigs[region] || {};

    this.logger.debug({ config, region }, 'selectRegion');

    this.active.next(config);

  }

}
