
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AllEntitiesState<Model, Last = any> = 
  & Omit<InternalAllEntitiesState<Model, Last>, 'entities'>
  & {
    entities: Array<Entity<Model>>;
    models: Array<Model>;
  };

export interface Entities<Model> {

  [key: string]: Entity<Model>;

}

export interface Entity<Model> {

  actions: Array<EntityAction>;

  error?: string;

  loaded?: number;

  loading: boolean;

  model?: Model;

  persisted?: number;

  persisting: boolean;

  processing: boolean;

}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface EntityAction<Payload = any> {

  action_id: string;

  action_name: string;

  payload: Payload;

  epoch: number;

  persisted?: number;

  user_id: string;

}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface EntityGroup<Model, Last = any> {

  error?: string;

  ids: Array<string>;

  last?: Last;

  loaded?: number;

  loading: boolean;

  models: Array<Model>;

}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type InternalEntityGroup<Model, Last = any> = Omit<EntityGroup<Model, Last>, 'models'>;

export interface InternalAllEntitiesState<Model, Last> {

  error?: string;

  entities: Entities<Model>;

  last?: Last;

  loaded?: number;

  loading: boolean;

}

export interface Entity<Model> {

  model?: Model;

  error?: string;

  loaded?: number;

  loading: boolean;

  processing: boolean;

}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ManyEntitiesState<Model, Last = any> {

  entities: Array<Entity<Model>>;

  models: Array<Model>;

  error?: string;

  ids: Array<string>;

  last?: Last;

  loaded?: number;

  loading: boolean;

}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type InternalManyEntitiesState<Model, Last = any> = Omit<ManyEntitiesState<Model, Last>, 'entities' | 'models'>;
