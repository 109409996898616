import { Injectable } from '@angular/core';
import { ClassServiceAdapter } from './class.service.adapter';
import { TrainingClass, TrainingClassCancelAction, TrainingClassCanceledEvent, TrainingClassConfirmAction, TrainingClassConfirmedEvent, TrainingClassCreateAction, TrainingClassCreatedEvent, TrainingClassFinishAction, TrainingClassFinishedEvent, TrainingClassGetAction, TrainingClassInterface, TrainingClassListAction, TrainingClassListedEvent, TrainingClassRequestAction, TrainingClassRequestedEvent, TrainingClassStartAction, TrainingClassStartedEvent, TrainingClassViewedEvent } from '@bcx/models';
import { WebSocketService } from '@engagedcx/ng-amplify';
import { List } from '@digitaltoolbuilders/model-helpers';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements ClassServiceAdapter {

  constructor(private socket: WebSocketService) { }

  cancel(action: TrainingClassCancelAction): Promise<TrainingClassCanceledEvent> {
    
    return this.socket.request<TrainingClassInterface>(action)
    .then((response) => {

      return new TrainingClassCanceledEvent({
        ...response,
        payload: new TrainingClass(response.payload),
      });

    });

  }

  confirm(action: TrainingClassConfirmAction): Promise<TrainingClassConfirmedEvent> {
      
    return this.socket.request<TrainingClassInterface>(action)
    .then((response) => {

      return new TrainingClassConfirmedEvent({
        ...response,
        payload: new TrainingClass(response.payload),
      });

    });

  }

  create(action: TrainingClassCreateAction): Promise<TrainingClassCreatedEvent> {
      
    return this.socket.request<TrainingClassInterface>(action)
    .then((response) => {

      return new TrainingClassCreatedEvent({
        ...response,
        payload: new TrainingClass(response.payload),
      });

    });

  }

  finish(action: TrainingClassFinishAction): Promise<TrainingClassFinishedEvent> {
      
    return this.socket.request<TrainingClassInterface>(action)
    .then((response) => {

      return new TrainingClassFinishedEvent({
        ...response,
        payload: new TrainingClass(response.payload),
      });

    });

  }

  get(action: TrainingClassGetAction): Promise<TrainingClassViewedEvent> {
      
    return this.socket.request<TrainingClassInterface>(action)
    .then((response) => {

      return new TrainingClassViewedEvent({
        ...response,
        payload: new TrainingClass(response.payload),
      });

    });

  }

  list(action: TrainingClassListAction): Promise<TrainingClassListedEvent> {
      
    return this.socket.request<List<TrainingClassInterface>>(action)
    .then((response) => {

      return new TrainingClassListedEvent({
        ...response,
        payload: new List<TrainingClass>({
          ...response.payload,
          list: response.payload.list.map((item) => new TrainingClass(item)),
        }),
      });

    });

  }

  request(action: TrainingClassRequestAction): Promise<TrainingClassRequestedEvent> {
      
    return this.socket.request<TrainingClassInterface>(action)
    .then((response) => {

      return new TrainingClassRequestedEvent({
        ...response,
        payload: new TrainingClass(response.payload),
      });

    });

  }

  start(action: TrainingClassStartAction): Promise<TrainingClassStartedEvent> {
      
    return this.socket.request<TrainingClassInterface>(action)
    .then((response) => {

      return new TrainingClassStartedEvent({
        ...response,
        payload: new TrainingClass(response.payload),
      });

    });

  }

}
