
export interface BeginsEnds {

  begins: Date;

  ends: Date;

}

export const DEFAULT_FORMAT = 'M/dd h:mm a';
