<div class="ng-amplify-authed-user">

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px"
    *ngIf="user">

    <div fxLayout="row" fxLayoutAlign="space-between center">

      <span *ngIf="isLargeScreen && showName">{{user.name}}</span>

      <button type="button"
        [matMenuTriggerFor]="menu"
        mat-icon-button><mat-icon>account_circle</mat-icon></button>

    </div>

  </div>

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px"
    *ngIf="!user">

    <button type="button" color="primary"
      (click)="signIn()"
      [disabled]="authenticating"
      mat-button
      recordClick>Sign In</button>

    <mat-spinner *ngIf="authenticating" diameter="20"></mat-spinner>

    <button type="button" color="primary"
      (click)="signUp()"
      [disabled]="authenticating"
      mat-button
      recordClick>Sign Up</button>

  </div>

</div>

<mat-menu #menu="matMenu">

  <ng-content></ng-content>

  <button color="warn"
    (click)="signOut()"
    mat-menu-item
    recordClick>Sign Out</button>

</mat-menu>
