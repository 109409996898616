import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SignAgreementForm } from './sign-agreement.form';
import { TrainingPaymentAgreement, TrainingPaymentAgreementStatusMap } from '@bcx/models';
import { SubsComponent } from '@bcx/ng-helpers';

@Component({
  selector: 'ng-training-payment-agreement-sign-agreement',
  templateUrl: './sign-agreement.component.html',
  styleUrls: ['./sign-agreement.component.sass'],
})
export class SignAgreementComponent extends SubsComponent implements OnChanges {

  agree: FormControl;

  @Input() agreement?: TrainingPaymentAgreement;

  @Input() agreementIsActive = false;

  error?: string;

  @Input() form: SignAgreementForm;

  isSigned = false;

  processing: boolean;

  signature: FormControl;

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['form'] && this.form) {

      this.agree = this.form.get('agree') as FormControl;
      this.signature = this.form.get('signature') as FormControl;

    }

    if (changes['agreement']) {

      this.onAgreementChange();

    }

  }

  onAgreementChange() {

    if (this.agreement
      && this.agreement.status === TrainingPaymentAgreementStatusMap.ACTIVE) {

      this.isSigned = true;

      this.form.disable();

    } else {

      this.isSigned = false;
      
      this.form.enable();

    }

  }

}
