<div class="ng-consulting-select-session-category" fxLayout="column"
  *ngIf="control">

  <mat-form-field>

    <mat-label>Select A Session Category</mat-label>

    <mat-select
      [formControl]="control"
      matInput>

      <mat-select-trigger style="width: 100%">

        <ng-consulting-session-category-option
          [category]="control.value"></ng-consulting-session-category-option>

      </mat-select-trigger>

      <mat-option 
        *ngFor="let category of categories"
        [value]="category">
      
        <ng-consulting-session-category-option
          [category]="category"
          [currency]="currency"
          [sessionType]="sessionType"></ng-consulting-session-category-option>

      </mat-option>

    </mat-select>

    <mat-error *ngIf="control.hasError('required')">Please select a session category.</mat-error>

  </mat-form-field>

</div>
