<div class="ng-user-create-form"
  *ngIf="form"
  [formGroup]="form">

  <mat-form-field>

    <input type="text" placeholder="User's Name"
      formControlName="name"
      matInput>

    <mat-error>Please enter the user's name.</mat-error>

  </mat-form-field>

  <div fxLayout="column"
    fxLayout.gt-sm="row">

    <mat-form-field fxFlex>

      <input type="text" placeholder="User's Given Name"
        formControlName="given_name"
        matInput>

    </mat-form-field>

    <mat-form-field fxFlex>

      <input type="text" placeholder="User's Family Name"
        formControlName="family_name"
        matInput>

    </mat-form-field>

  </div>

  <mat-form-field>

    <input type="email" placeholder="User's Email Address"
      formControlName="email"
      matInput>

    <mat-error>Please enter a valid email address.</mat-error>

  </mat-form-field>

  <mat-form-field>

    <input type="text" placeholder="User's Phone Number"
      formControlName="phone_number"
      matInput>

  </mat-form-field>

</div>
