import { Component, Input } from '@angular/core';
import { CancelForm } from './cancel-form.form';

@Component({
  selector: 'ng-consulting-session-cancel-form',
  templateUrl: './cancel-form.component.html',
  styleUrls: ['./cancel-form.component.sass'],
})
export class CancelFormComponent {

  @Input() form: CancelForm;

}
