import { FormControl, FormGroup } from "@angular/forms";

export interface SmallToBestQuestionsMap {

  industry?: string;

  length_in_business?: string;

  length_in_industry?: string;

  number_of_employees?: string;

}

export class SmallToBestQuestionsForm extends FormGroup {

  constructor() {

    super({
      industry: new FormControl<string | undefined>(undefined, []),
      length_in_business: new FormControl<string | undefined>(undefined, []),
      length_in_industry: new FormControl<string | undefined>(undefined, []),
      number_of_employees: new FormControl<string | undefined>(undefined, []),
    });

  }

}
