import { Component, Inject, OnInit } from '@angular/core';
import { CreateForm } from '../create-form/create-form.form';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClassService } from '../class.service';
import { SubsComponent } from '@bcx/ng-helpers';
import { TrainingClass } from '@bcx/models';

@Component({
  selector: 'ng-training-class-create-dialog',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.sass'],
})
export class CreateDialogComponent extends SubsComponent implements OnInit {

  class_id: string;

  error?: string;

  form = new CreateForm();

  minDate = new Date();

  processing: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public trainingClass: TrainingClass,
    private dialog: MatDialogRef<CreateDialogComponent>,
    private service: ClassService,
  ) {

    super();

  }

  closeDialog() {

    this.dialog.close();

  }

  ngOnInit(): void {
    
    this.class_id = this.service.createId();

    // this.subs['trainingClass'] = this.service.select(this.class_id)
    // .subscribe(({ error, processing }) => {

    //   this.error = error;

    //   this.processing = processing;

    // });

  }

  submit(): void {
    
    this.service.create({
      class_id: this.class_id,
      course_class_number: this.form.value.course_class_number,
      class_type: this.form.value.class_type,
    })
    .then((created) => {

      this.dialog.close(created);
      
    })
    .catch((e: Error) => {

      console.error(e);

    });

  }

}
