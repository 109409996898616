
export const DATA_V1_BUCKET_NAME_ENV = 'DATA_V1_BUCKET_NAME';

export const DATA_V1_BUCKET_ARN_PARAM_NAME = '/data/v1/BucketArn';

export const DATA_V1_BUCKET_NAME_PARAM_NAME = '/data/v1/BucketName';

export const DATA_V1_TABLE_ARN_ENV = 'DATA_V1_TABLE_ARN';

export const DATA_V1_TABLE_ARN_PARAM_NAME = '/data/v1/TableArn';

export const DATA_V1_TABLE_NAME_ENV = 'DATA_V1_TABLE_NAME';

export const DATA_V1_TABLE_NAME_PARAM_NAME = '/data/v1/TableName';

export const DATA_V1_TABLE_STREAM_ARN_ENV = 'DATA_V1_TABLE_ARN';

export const DATA_V1_TABLE_STREAM_ARN_PARAM_NAME = '/data/v1/TableStreamArn';

export const DATA_V1_TOPIC_ARN_ENV = 'DATA_V1_TOPIC_ARN';

export const DATA_V1_TOPIC_ARN_PARAM_NAME = '/data/v1/TopicArn';

export const DATA_V1_TOPIC_NAME_PARAM_NAME = '/data/v1/TopicName';
