import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmplifyService } from './amplify.service';
import { AnalyticsModule } from './analytics/analytics.module';
import { AuthModule } from './auth/auth.module';
import { ApiModule } from './api/api.module';
import { WebsocketModule } from './websocket/websocket.module';

export { AmplifyService };
export { AnalyticsService } from './analytics/analytics.service';
export { ApiService, DEFAULT_API_NAME } from './api/api.service';
export * from './auth/auth.exports';
export * from './logger';
export * from './websocket/websocket.exports';

export function initializeModuleFactory(amplify: AmplifyService) {

  return () => {

    return amplify.init();

  };

}

@NgModule({
  exports: [
    AnalyticsModule,
    ApiModule,
    AuthModule,
    WebsocketModule,
  ],
  imports: [
    AnalyticsModule,
    ApiModule,
    AuthModule,
    CommonModule,
    WebsocketModule,
  ],
  providers: [
    AmplifyService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [
        AmplifyService,
      ],
      useFactory: initializeModuleFactory,
    }
  ]
})
export class NgAmplifyModule {}
