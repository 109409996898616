import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserService } from '../user.service';
import { LastEvaluatedKey, User } from '@bcx/models';
import { SubsComponent } from '@bcx/ng-helpers';

@Component({
  selector: 'ng-user-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.sass'],
})
export class SelectUserComponent extends SubsComponent implements OnChanges, OnInit {

  availableUsers: Array<User> = [];

  @Input() control: FormControl;

  error?: string;

  @Input() ignoreUsers: Array<string> = [];

  lastEvaluatedKey?: LastEvaluatedKey;

  loading: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() optionLabel?: TemplateRef<any>

  @Input() placeholder = 'Select User';

  @Input() requiredErrorMessage = 'Please select a user.';

  @Input() returnUser = false;

  users: Array<User> = [];

  constructor(
    private service: UserService
  ) {

    super();

  }

  compareUser(a: User, b: User) {

    return a?.user_id === b?.user_id;

  }

  fetchUsers() {

    return this.service.list({
      exclusive_start_key: this.lastEvaluatedKey
    })
    .catch((e) => {

      console.error(e);

    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['ignoreUsers']) {

      this.ignoreUsers = this.ignoreUsers || [];

    }

  }

  ngOnInit(): void {

    this.fetchUsers()
    .catch((e) => {

      console.error(e);

    });

  }

}
