import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Recommendation, RecommendationService } from '../recommendation.service';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-recommendation-list',
  templateUrl: './list.component.html',
  styleUrl: './list.component.sass',
})
export class ListComponent implements OnChanges, OnDestroy {

  @Input() product_id: string;

  private productSub = new BehaviorSubject<string>('');

  vm$: Observable<{
    recos: Array<Recommendation>;
  }>;

  constructor(
    private service: RecommendationService,
  ) { 

    this.vm$ = combineLatest({
      recos: combineLatest({
        recos: this.service.recommendations$,
        product_id: this.productSub.asObservable(),
      })
      .pipe(map(({ recos, product_id }) => {

        return recos[product_id] || [];

      })),
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['product_id']) {

      this.productSub.next(this.product_id);

    }

  }

  ngOnDestroy(): void {
    
    this.productSub.complete();

  }

}
