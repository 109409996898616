import { InjectionToken } from "@angular/core";
import pino, { Logger } from "pino";

export const DEFAULT_LOGGER = new InjectionToken<Logger>('default logger', {
  factory: () => {

    return pino();

  }
});
