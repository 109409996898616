import { inject, Inject, Injectable } from '@angular/core';
import { TRAINING_TICKET, TrainingTicket, TrainingTicketCancelAction, TrainingTicketCancelPayload, TrainingTicketGetAction, TrainingTicketGetPayload, TrainingTicketListAction, TrainingTicketListPayload, TrainingTicketRedeemAction, TrainingTicketRedeemPayload, TrainingTicketRevokeAction, TrainingTicketRevokePayload, TrainingTicketStatusMap, TrainingTicketSuspendAction, TrainingTicketSuspendPayload } from '@bcx/models';
import { StateFacade, StateService } from '../../state/state.service';
import { BACKEND_ADAPTER, BackendAdapter, BackendAdapterMap, IdService } from '@bcx/ng-helpers';
import { RestService } from './rest.service';
import { WebsocketService } from './websocket.service';
import { TicketServiceAdapter } from './ticket.service.adapter';
import { trainingTicket as schemas } from '@bcx/schemas';
import { List } from '@digitaltoolbuilders/model-helpers';

export function createStateId(input: Pick<TrainingTicket, 'ticket_id'>) {

  return TrainingTicket.generateModelId(input);

}

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  private adapter: TicketServiceAdapter;

  private state: StateFacade<TrainingTicket, List<TrainingTicket>>;

  constructor(
    @Inject(BACKEND_ADAPTER) adapter: BackendAdapter,
    private id: IdService,
    state: StateService,
  ) { 

    switch (adapter) {

      case BackendAdapterMap.REST:

        this.adapter = inject(RestService);

        break;

      case BackendAdapterMap.WEBSOCKET:

        this.adapter = inject(WebsocketService);

        break;

      default:

        throw new Error(`unknown adapter: ${adapter}`);

    }

    this.state = state.facade<TrainingTicket, List<TrainingTicket>>(TRAINING_TICKET, {
      createStateId,
    });

  }

  cancel(original: TrainingTicket, data: Partial<TrainingTicketCancelPayload>, now = Date.now()) {

    data.last_updated_epoch = original.last_updated_epoch;
    data.ticket_id = original.ticket_id;

    return schemas.cancel.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingTicketCancelAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.cancel(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  canRedeem(ticket: TrainingTicket) {

    return ticket.status === TrainingTicketStatusMap.REDEEMED;

  }

  get(data: Partial<TrainingTicketGetPayload>, now = Date.now()) {

    return schemas.get.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingTicketGetAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        loading: true,
      });

      return this.adapter.get(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          loading: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          loading: false,
        });

        throw e;

      });

    });

  }

  isAvailable(ticket: TrainingTicket) {

    return ticket.status === TrainingTicketStatusMap.AVAILABLE;

  }

  list(data: Partial<TrainingTicketListPayload>, now = Date.now()) {

    return schemas.list.validateAsync(data, {})
    .then((clean) => {

      const group = 'all';

      const action = new TrainingTicketListAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: group,
        payload: clean,
        user_id: '',
      });

      this.state.many({
        group,
        loading: true,
      });

      return this.adapter.list(action)
      .then((event) => {

        const list = event.payload;

        this.state.many({
          group,
          list: list.list,
          loading: false,
        });

        return list;

      })
      .catch((e) => {

        this.state.many({
          e,
          group,
          loading: false,
        });

        throw e;

      });

    });

  }

  redeem(original: TrainingTicket, data: Partial<TrainingTicketRedeemPayload>, now = Date.now()) {

    data.last_updated_epoch = original.last_updated_epoch;
    data.ticket_id = original.ticket_id;

    return schemas.redeem.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingTicketRedeemAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.redeem(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  revoke(original: TrainingTicket, data: Partial<TrainingTicketRevokePayload>, now = Date.now()) {

    data.last_updated_epoch = original.last_updated_epoch;
    data.ticket_id = original.ticket_id;

    return schemas.revoke.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingTicketRevokeAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.revoke(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  suspend(original: TrainingTicket, data: Partial<TrainingTicketSuspendPayload>, now = Date.now()) {

    data.last_updated_epoch = original.last_updated_epoch;
    data.ticket_id = original.ticket_id;

    return schemas.suspend.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingTicketSuspendAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.suspend(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

}
