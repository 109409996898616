<div fxLayout="column">

  <mat-accordion>

    <mat-expansion-panel
      *ngFor="let attendee of attendees">

      <mat-expansion-panel-header>

        <mat-panel-title>Session on {{attendee.scheduled.arrival_epoch | date:'MMM d @ h:mm a'}}
        </mat-panel-title>

        <mat-panel-description>
          Session Id: {{attendee.session_id}}
        </mat-panel-description>

      </mat-expansion-panel-header>

      <mat-action-row>

        <ng-container *ngTemplateOutlet="actions; context: { attendee }"></ng-container>

      </mat-action-row>

      <ng-template matExpansionPanelContent>

        <pre>{{attendee | json}}</pre>

      </ng-template>

    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="attendees?.length === 0" [expanded]="true">

      <p>No Sessions</p>

    </mat-expansion-panel>

  </mat-accordion>

</div>