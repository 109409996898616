<div
  *ngIf="(vm$ | async) as vm">

  <h1 class="text-lg">Recommended Path To Best CX Ever</h1>

  <mat-tab-group>

    <mat-tab
      *ngIf="vm.is_business_owner">

        <ng-template matTabLabel>Your Business</ng-template>

        <ng-template matTabContent>

          <app-owner-path></app-owner-path>

        </ng-template>

    </mat-tab>

    <mat-tab
      *ngIf="vm.is_team_leader">

        <ng-template matTabLabel>Your Team</ng-template>

        <ng-template matTabContent>

          <app-coach-path></app-coach-path>

        </ng-template>

    </mat-tab>

    <mat-tab
      *ngIf="vm.is_provider">

        <ng-template matTabLabel>You</ng-template>

        <ng-template matTabContent>

          <app-provider-path></app-provider-path>

        </ng-template>

    </mat-tab>

    <mat-tab
      *ngIf="vm.has_small_business">

        <ng-template matTabLabel>Your Small Business</ng-template>
      
        <ng-template matTabContent>

          <app-small-to-best-path></app-small-to-best-path>

        </ng-template>

    </mat-tab>

  </mat-tab-group>

</div>
