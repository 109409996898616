<div fxFlexFill fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">

  <div class="mat-headline-5" fxLayout="column" fxLayoutAlign="center center">

    <span>{{ticket.redemption_epoch | date:'M/d @ h:mm a'}}</span>

    <span>{{state | capitalizeFirst}}</span>

  </div>

  <div class="actions" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="5px">

    <button type="button" color="primary"
      *ngIf="canRedeem()"
      (click)="redeem()"
      mat-raised-button>Redeem</button>

  </div>

</div>
