import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { addMinutes, endOfDay, format, isBefore, isEqual, startOfDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

@Component({
  selector: 'ng-picker-time-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.sass'],
})
export class SelectorComponent implements OnChanges {

  available: Array<{label:string,value: string}> = [];

  @Input() control: FormControl;

  @Input() date?: Date;

  @Input() interval = 15;

  @Input() label = 'Select Time';

  localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  @Input() max?: string;

  @Input() min?: string;

  @Input() officeHoursEnd?: string;

  @Input() officeHoursStart?: string;

  ourTimeZone = 'America/Denver';

  @Input() requiredError = 'Please select a time, it is required.';

  constructor() {

    this.onIntervalChange();

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['date']
      || changes['interval']
      || changes['max']
      || changes['min']) {

      this.onIntervalChange();

    }

  }

  private onIntervalChange() {

    const available: Array<{label:string,value:string}> = [];

    let iterator = this.date || new Date();
    
    iterator = (this.min) ? new Date(`${format(iterator, 'M/d/yyyy')} ${this.min}`) : startOfDay(iterator);

    const end = (this.max) ? new Date(`${format(iterator, 'M/d/yyyy')} ${this.max}`) : endOfDay(iterator);

    const ofStart = Date.parse(`${formatInTimeZone(iterator, this.ourTimeZone, 'MM/dd/yyyy')} 08:00 AM`);

    const ofEnd = Date.parse(`${formatInTimeZone(iterator, this.ourTimeZone, 'MM/dd/yyyy')} 08:00 PM`);

    while (isBefore(iterator, end) || isEqual(iterator, end)) {

      const local = Date.parse(formatInTimeZone(iterator, this.ourTimeZone, 'MM/dd/yyyy h:mm a'));

      let label = formatInTimeZone(iterator, this.localTimeZone, 'MM/dd @ h:mm a');

      if (!(local >= ofStart && local <= ofEnd)) {

        label += ' (Off Hours)';

      }

      const value = formatInTimeZone(iterator, this.localTimeZone, 'h:mm a');

      available.push({
        label, value
      });

      iterator = addMinutes(iterator, this.interval);

    }

    this.available = available;

  }

}
