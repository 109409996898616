import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListTableComponent } from './list-table/list-table.component';
import { MatTableModule } from '@angular/material/table';
import { PipesModule } from '../pipes/pipes.module';
import { FullDetailsComponent } from './full-details/full-details.component';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [ListTableComponent, FullDetailsComponent],
  exports: [ListTableComponent, FullDetailsComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatTableModule,
    PipesModule,
  ],
})
export class ChargeModule {}
