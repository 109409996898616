import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BOOK, CONSULTING, Path, PathStepTypeMap, PathTypeMap, TRAINING } from '@bcx/models';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { OtherOptionsComponent } from '../other-options/other-options.component';
import { cloneDeep } from 'lodash';
import { SettingsService } from '@bcx/ng-models';

const DEFAULT_PATH = {
  book_first: false,
  path_id: 'coach',
  path_type: PathTypeMap.COACH,
  steps: [
    {
      step_type: PathStepTypeMap.TRAINING,
    },
    {
      step_type: PathStepTypeMap.CONSULTING,
    },
  ],
};

export interface ViewModel {

  currency: string;

  otherOptionsPrice: number;

  path: Path;

}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-coach-path',
  templateUrl: './path.component.html',
  styleUrl: './path.component.sass',
})
export class PathComponent {

  BOOK = BOOK;

  CONSULTING = CONSULTING;

  private path = new BehaviorSubject<Path>(cloneDeep(DEFAULT_PATH));

  TRAINING = TRAINING;

  vm$: Observable<ViewModel>;

  constructor(
    private dialog: MatDialog,
    private settings: SettingsService,
  ) {

    this.vm$ = combineLatest({
      currency: this.settings.currency$,
      otherOptionsPrice: of(699),
      path: this.path,
    });

  }

  showOtherOptions(path: Path) {

    const ref = this.dialog.open(OtherOptionsComponent, {
      data: path
    });

    ref.afterClosed().subscribe((path: Path) => {

      this.path.next(path);

    });

  }

}
