import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'ng-picker-date-now-picker',
  templateUrl: './now-picker.component.html',
  styleUrls: ['./now-picker.component.sass'],
})
export class NowPickerComponent {

  @Input() control: FormControl;

  @Input() label: string;

  selectNow: FormControl;

  constructor(private fb: FormBuilder) {

    this.selectNow = this.fb.control(true, []);

  }

}
