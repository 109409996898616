import { Injectable } from '@angular/core';
import { customAlphabet, nanoid } from 'nanoid';
import { alphanumeric, numbers} from 'nanoid-dictionary';
import { v4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class IdService {

  alphanumeric(length = 10) {

    return customAlphabet(alphanumeric, length)();

  }

  nanoid(size?: number) {

    return nanoid(size);

  }

  numeric(length = 10) {

    return customAlphabet(numbers, length)();

  }

  password(length = 16) {

    return customAlphabet(alphanumeric + '!@#$%^&*()', length)() + 'a1$';

  }

  uuid() {

    return v4();

  }

}
