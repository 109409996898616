<div class="ng-user-terms-of-service-form" fxLayout="column" fxLayoutGap="10px">

  <ng-user-terms-of-service></ng-user-terms-of-service>

  <div>

    <mat-slide-toggle [formControl]="control">I have read, understand and agree to your terms of service.</mat-slide-toggle>

  </div>

</div>
