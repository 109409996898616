<form 
  [formGroup]="form"
  (ngSubmit)="submit()">

  <div fxLayout="row" fxLayoutAlign="space-between">

    <h1 class="mat-headline-5">Finish Training Class</h1>

    <button type="button" color="warn"
      (click)="closeDialog()"
      mat-icon-button><mat-icon>close</mat-icon></button>

  </div>

  <mat-dialog-content>

    <ng-training-class-finish-form [form]="form"></ng-training-class-finish-form>

  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">

    <span></span>

    <mat-spinner *ngIf="processing" diameter="30"></mat-spinner>

    <button type="submit" color="primary"
      [disabled]="form.invalid || processing"
      mat-raised-button>Finish Class</button>

  </mat-dialog-actions>

</form>
