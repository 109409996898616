import { Component, Input, OnInit } from '@angular/core';
// import { Stripe } from 'stripe';

@Component({
  selector: 'ng-stripe-payment-method-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.sass'],
})
export class CardComponent implements OnInit {

  @Input() paymentMethod: any;

  constructor() {}

  ngOnInit(): void {}
}
