import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { distinctUntilChanged, map } from 'rxjs';
import { SubsComponent } from '@bcx/ng-helpers';

@Component({
  selector: 'ng-amplify-auth-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.sass'],
})
export class SignOutComponent extends SubsComponent implements OnInit {

  isAuthed: boolean;

  processing = true;

  constructor(
    private auth: AuthService,
    private dialog: MatDialogRef<SignOutComponent>,
  ) {

    super();

  }

  closeDialog() {

    this.dialog.close();

  }

  ngOnInit(): void {
    
    this.subs['authed'] = this.auth.credentials$
    .pipe(map((creds) => {

      return (creds.sessionToken) ? true : false;

    }))
    .pipe(distinctUntilChanged())
    .subscribe((isAuthed: boolean) => {

      this.isAuthed = isAuthed;

    });

    this.auth.signOut()
    .then(() => {

      this.processing = false;

    })
    .catch((e: Error) => {

      console.error(e);

    });

  }

}
