import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { SmallToBestGoalsForm } from './goals.form';
import { SmallToBestGoalMap, SmallToBestGoalNameMap } from '@bcx/models';
import { GetStartedService } from '../../get-started/get-started.service';
import { combineLatest, map, Observable, throttleTime } from 'rxjs';
import { ListBaseComponent } from '../../goal/list-base/list-base.component';

export interface ViewModel {

  goals: SmallToBestGoalMap;

  progress: number;

}

@Component({
  selector: 'app-small-to-best-goals',
  templateUrl: './goals.component.html',
  styleUrl: './goals.component.sass',
})
export class GoalsComponent extends ListBaseComponent implements OnDestroy, OnInit {

  form = new SmallToBestGoalsForm();

  private goals: Observable<SmallToBestGoalMap>;

  names = SmallToBestGoalNameMap;

  @Output() override progress$: Observable<number>;

  vm$: Observable<ViewModel>;

  constructor(private service: GetStartedService) { 

    super();

    const state = this.service.getCurrentState();

    this.form.patchValue(state.small_to_best.goals);

    this.goals = this.service.state$.pipe(map((state) => state.small_to_best.goals));

    this.vm$ = combineLatest({
      goals: this.goals,
      progress: this.progress$,
    });

  }

  ngOnInit(): void {
    
    this.subs['valueChanges'] = this.form.valueChanges
    .pipe(throttleTime(250))
    .subscribe((goals) => {

      this.service.save({
        small_to_best: {
          goals,
        },
      });

    });

    this.subs['goals'] = this.goals
    .pipe(throttleTime(250))
    .subscribe(() => {

      this.calculateProgress();

    });
    
  }

}
