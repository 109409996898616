import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SettingsService } from '@bcx/ng-models';
import { combineLatest, Observable, of } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-owner-training-step-label',
  templateUrl: './step-label.component.html',
  styleUrl: './step-label.component.sass',
})
export class StepLabelComponent {

  vm$: Observable<{
    currency: string;
    price: number;
  }>;

  constructor(private settings: SettingsService) {

    this.vm$ = combineLatest({
      currency: this.settings.currency$,
      price: of(4999),
    });

  }

}
