import { inject, Inject, Injectable } from '@angular/core';
import { BACKEND_ADAPTER, BackendAdapter, BackendAdapterMap, IdService } from '@bcx/ng-helpers';
import { StateFacade, StateService } from '../../state/state.service';
import { personContactPoint as schemas } from '@bcx/schemas';
import { LastEvaluatedKey, PERSON_CONTACT_POINT, PersonContactPoint, PersonContactPointActionNameMap, PersonContactPointCreateAction, PersonContactPointCreatedEvent, PersonContactPointCreatePayload, PersonContactPointDeleteAction, PersonContactPointDeletedEvent, PersonContactPointDeletePayload, PersonContactPointGetAction, PersonContactPointGetPayload, PersonContactPointListAction, PersonContactPointListedEvent, PersonContactPointListPayload, PersonContactPointUpdateAction, PersonContactPointUpdatedEvent, PersonContactPointUpdatePayload, PersonContactPointViewedEvent } from '@bcx/models';
import { RestServiceAdapter } from './rest/rest.service';
import { WebsocketServiceAdapter } from './websocket/websocket.service';
import { List } from '@digitaltoolbuilders/model-helpers';

export interface ContactPointServiceAdapter {

  create(action: PersonContactPointCreateAction): Promise<PersonContactPointCreatedEvent>;
  
  delete(action: PersonContactPointDeleteAction): Promise<PersonContactPointDeletedEvent>;

  get(action: PersonContactPointGetAction): Promise<PersonContactPointViewedEvent>;
  
  list(action: PersonContactPointListAction): Promise<PersonContactPointListedEvent>;
  
  update(action: PersonContactPointUpdateAction): Promise<PersonContactPointUpdatedEvent>;

}

export function createStateId(input: Pick<PersonContactPoint, 'account_id' | 'person_id' | 'contact_point_id'>) {

  return PersonContactPoint.generateModelId(input);

}

export function iteratorFn(list: List<PersonContactPoint>, fn: (model: PersonContactPoint) => void) {

  list.list.forEach(fn);

}

@Injectable({
  providedIn: 'root'
})
export class ContactPointService {

  private adapter: ContactPointServiceAdapter;

  private state: StateFacade<PersonContactPoint, LastEvaluatedKey>;

  constructor(
    @Inject(BACKEND_ADAPTER) backendAdapter: BackendAdapter,
    private id: IdService,
    state: StateService,
  ) { 

    switch (backendAdapter) {

      case BackendAdapterMap.REST:

        this.adapter = inject(RestServiceAdapter);
        
        break;
    
      case BackendAdapterMap.WEBSOCKET:

        this.adapter = inject(WebsocketServiceAdapter);
        
        break;
    
      default:
        
        throw new Error(`unknown adapter: ${backendAdapter}`);

    }

    this.state = state.facade<PersonContactPoint, LastEvaluatedKey>(PERSON_CONTACT_POINT, {
      createStateId,
    });

  }

  create(data: PersonContactPointCreatePayload, now = Date.now()) {

    return schemas.create.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action: PersonContactPointCreateAction = {
        action_id: this.id.uuid(),
        action_name: PersonContactPointActionNameMap.CREATE,
        epoch: now,
        model_id: PersonContactPoint.generateModelId(clean),
        payload: clean,
        user_id: '',
      };

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.create(action)
      .then((event) => {

        this.state.single({
          id, 
          processing: false,
        });

        return event;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  delete(original: PersonContactPoint, now = Date.now()) {

    const data: PersonContactPointDeletePayload = {
      account_id: original.account_id,
      contact_point_id: original.contact_point_id,
      last_updated_epoch: original.last_updated_epoch,
      person_id: original.person_id,
    };

    return schemas.del.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action: PersonContactPointDeleteAction = {
        action_id: this.id.uuid(),
        action_name: PersonContactPointActionNameMap.DELETE,
        epoch: now,
        model_id: PersonContactPoint.generateModelId(clean),
        payload: clean,
        user_id: '',
      };

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.delete(action)
      .then((event) => {

        this.state.single({
          id, 
          processing: false,
        });

        return event;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  get(data: PersonContactPointGetPayload, now = Date.now()) {

    return schemas.get.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action: PersonContactPointGetAction = {
        action_id: this.id.uuid(),
        action_name: PersonContactPointActionNameMap.GET,
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      };

      this.state.single({
        id,
        loading: true,
      });

      return this.adapter.get(action)
      .then((event) => {

        this.state.single({
          id,
          loading: false,
          model: event.payload as PersonContactPoint,
        });

        return event;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          loading: false,
        });

        throw e;

      });

    });

  }

  list(data: PersonContactPointListPayload, group = 'all', now = Date.now()) {

    return schemas.list.validateAsync(data, {})
    .then((clean) => {

      const action = new PersonContactPointListAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: '',
        payload: clean,
        user_id: '',
      });

      this.state.many({
        group,
        loading: true,
      });

      return this.adapter.list(action)
      .then((event) => {

        const list = event.payload;

        this.state.many({
          group,
          list: list.list,
          loading: false,
        });

        return event;

      })
      .catch((e) => {

        this.state.many({
          e,
          group,
          loading: false,
        });

        throw e;

      });

    });

  }

  selectForPerson(input: Pick<PersonContactPoint, 'account_id' | 'person_id'>) {

    const group = `forPerson:${input.account_id}:${input.person_id}`;

    return this.state.selectGroup(group);

  }

  update(original: PersonContactPoint, data: PersonContactPointUpdatePayload, now = Date.now()) {

    data.account_id = original.account_id;
    data.contact_point_id = original.contact_point_id;
    data.last_updated_epoch = original.last_updated_epoch;
    data.person_id = original.person_id;

    return schemas.update.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action: PersonContactPointUpdateAction = {
        action_id: this.id.uuid(),
        action_name: PersonContactPointActionNameMap.UPDATE,
        epoch: now,
        model_id: PersonContactPoint.generateModelId(clean),
        payload: clean,
        user_id: '',
      };

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.update(action)
      .then((event) => {

        this.state.single({
          id, 
          processing: false,
        });

        return event;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

}
