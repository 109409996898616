import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ConfirmForm } from './confirm-form.form';
import { SubsComponent } from '@bcx/ng-helpers';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged, filter, map } from 'rxjs';
import { addMinutes, format } from 'date-fns';
import { stringify } from 'qs';
import { ConsultingSession } from '@bcx/models';

export const GOOGLE_DATE_FORMAT = 'YMMdd\'T\'HHmmss';
export const MEET_REGEX = /(https?:\/\/[^ ]*)/;

@Component({
  selector: 'ng-consulting-session-confirm-form',
  templateUrl: './confirm-form.component.html',
  styleUrls: ['./confirm-form.component.sass'],
})
export class ConfirmFormComponent extends SubsComponent implements OnChanges {

  @Input() form: ConfirmForm;

  googleMeetDetails: FormControl;

  googleMeetLink: FormControl;

  @Input() session: ConsultingSession;

  createGoogleCalendarEvent() {

    const category = `${this.session.category.substring(0,1).toUpperCase()}${this.session.category.substring(1)}`;

    const title = `${category} Session`;

    const dates = `${format(this.session.scheduled.start_epoch, GOOGLE_DATE_FORMAT)}/${format(addMinutes(this.session.scheduled.start_epoch, this.session.scheduled.length_minutes || 15), GOOGLE_DATE_FORMAT)}`;

    const details = `${title} #${this.session.session_id}`;

    const location = `${this.session.type}`;

    const query = {
      action: 'TEMPLATE',
      text: title,
      dates,
      details,
      location,
      crm: 'BUSY',
      sprop: `website:bestcxever.com`,
      trp: true,
      ctz: 'America/Denver',
    };

    window.open(`http://www.google.com/calendar/render?${stringify(query)}`, '_blank')?.focus();
  
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['form'] && this.form) {

      this.googleMeetDetails = this.form.get('google_meet_details') as FormControl;
      this.googleMeetLink = this.form.get('google_meet_link') as FormControl;

      this.subs['details'] = this.googleMeetDetails.valueChanges
      .pipe(map((details: string | null) => {

        if (details) {

          const result = details.match(MEET_REGEX);

          return (result) ? result[1] : null;
  
        } else {

          return null;

        }

      }))
      .pipe(filter((link: string | null): link is string => {

        return (typeof link === 'string');

      }))
      .pipe(distinctUntilChanged())
      .subscribe((link: string) => {

        this.googleMeetLink.patchValue(link);

      });

    }

  }

}
