import { Injectable } from '@angular/core';
import { PaymentAgreementServiceAdapter } from './payment-agreement.adapter';
import { ApiService } from '@engagedcx/ng-amplify';
import { RestServerResponse, TRAINING_PAYMENT_AGREEMENT, TrainingPaymentAgreement, TrainingPaymentAgreementAgreeAction, TrainingPaymentAgreementAgreedEvent, TrainingPaymentAgreementCancelAction, TrainingPaymentAgreementCanceledEvent, TrainingPaymentAgreementCreateAction, TrainingPaymentAgreementCreatedEvent, TrainingPaymentAgreementGenerateSignedUrlAction, TrainingPaymentAgreementGetAction, TrainingPaymentAgreementInterface, TrainingPaymentAgreementListAction, TrainingPaymentAgreementListedEvent, TrainingPaymentAgreementSignedUrl, TrainingPaymentAgreementSignedUrlGeneratedEvent, TrainingPaymentAgreementUpdateAction, TrainingPaymentAgreementUpdatedEvent, TrainingPaymentAgreementViewedEvent } from '@bcx/models';
import { List } from '@digitaltoolbuilders/model-helpers';

@Injectable({
  providedIn: 'root'
})
export class RestService implements PaymentAgreementServiceAdapter {

  private path = `/${TRAINING_PAYMENT_AGREEMENT}`;

  constructor(private api: ApiService) { }

  agree(action: TrainingPaymentAgreementAgreeAction): Promise<TrainingPaymentAgreementAgreedEvent> {
    
    return this.api.post<RestServerResponse<TrainingPaymentAgreementInterface>>(this.path, action)
    .then((response) => {

      return new TrainingPaymentAgreementAgreedEvent({
        ...response.data,
        payload: new TrainingPaymentAgreement(response.data.payload),
      });

    });

  }

  cancel(action: TrainingPaymentAgreementCancelAction): Promise<TrainingPaymentAgreementCanceledEvent> {
    
    return this.api.post<RestServerResponse<TrainingPaymentAgreementInterface>>(this.path, action)
    .then((response) => {

      return new TrainingPaymentAgreementCanceledEvent({
        ...response.data,
        payload: new TrainingPaymentAgreement(response.data.payload),
      });

    });

  }

  create(action: TrainingPaymentAgreementCreateAction): Promise<TrainingPaymentAgreementCreatedEvent> {
    
    return this.api.post<RestServerResponse<TrainingPaymentAgreementInterface>>(this.path, action)
    .then((response) => {

      return new TrainingPaymentAgreementCreatedEvent({
        ...response.data,
        payload: new TrainingPaymentAgreement(response.data.payload),
      });

    });

  }

  generateSignedUrl(action: TrainingPaymentAgreementGenerateSignedUrlAction): Promise<TrainingPaymentAgreementSignedUrlGeneratedEvent> {
    
    return this.api.post<RestServerResponse<TrainingPaymentAgreementSignedUrl>>(this.path, action)
    .then((response) => {

      return new TrainingPaymentAgreementSignedUrlGeneratedEvent({
        ...response.data,
      });

    });

  }

  get(action: TrainingPaymentAgreementGetAction): Promise<TrainingPaymentAgreementViewedEvent> {
    
    return this.api.post<RestServerResponse<TrainingPaymentAgreementInterface>>(this.path, action)
    .then((response) => {

      return new TrainingPaymentAgreementViewedEvent({
        ...response.data,
        payload: new TrainingPaymentAgreement(response.data.payload),
      });

    });

  }

  list(action: TrainingPaymentAgreementListAction): Promise<TrainingPaymentAgreementListedEvent> {
    
    return this.api.post<RestServerResponse<List<TrainingPaymentAgreementInterface>>>(this.path, action)
    .then((response) => {

      return new TrainingPaymentAgreementListedEvent({
        ...response.data,
        payload: new List<TrainingPaymentAgreement>({
          ...response.data.payload,
          list: response.data.payload.list.map((item) => new TrainingPaymentAgreement(item)),
        })
      })

    });

  }

  update(action: TrainingPaymentAgreementUpdateAction): Promise<TrainingPaymentAgreementUpdatedEvent> {
    
    return this.api.post<RestServerResponse<TrainingPaymentAgreementInterface>>(this.path, action)
    .then((response) => {

      return new TrainingPaymentAgreementUpdatedEvent({
        ...response.data,
        payload: new TrainingPaymentAgreement(response.data.payload),
      });

    });

  }

}
