import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BOOK, CONSULTING, Path, PathStepTypeMap, SMALL_TO_BEST, TRAINING } from '@bcx/models';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { OtherOptionsComponent } from '../other-options/other-options.component';
import { SettingsService } from '@bcx/ng-models';

@Component({
  selector: 'app-small-to-best-path',
  templateUrl: './path.component.html',
  styleUrl: './path.component.sass',
})
export class PathComponent {

  BOOK = BOOK;

  CONSULTING = CONSULTING;

  private path = new BehaviorSubject<Path>({
    book_first: false,
    path_id: '123456',
    path_type: SMALL_TO_BEST,
    steps: [
      {
        step_type: PathStepTypeMap.TRAINING,
      },
      {
        step_type: PathStepTypeMap.CONSULTING,
      },
    ],
  });

  TRAINING = TRAINING;

  vm$: Observable<{
    currency: string;
    otherOptionsPrice: number;
    path: Path;
  }>;

  constructor(
    private dialog: MatDialog,
    private settings: SettingsService,
  ) {

    this.vm$ = combineLatest({
      currency: this.settings.currency$,
      otherOptionsPrice: of(499),
      path: this.path,
    });

  }

  showOtherOptions() {

    const ref = this.dialog.open(OtherOptionsComponent, {
      data: this.path.getValue(),
    });

    ref.afterClosed().subscribe((path: Path) => {

      this.path.next(path);

    });

  }

}
