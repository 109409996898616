import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TrainingPaymentAgreement, TrainingPaymentAgreementActivationErrorCodeMap } from '@bcx/models';

export const CURRENCY_MATCHER = /\(([^()]*)\)/g

@Component({
  selector: 'ng-training-payment-agreement-activation-error',
  templateUrl: './activation-error.component.html',
  styleUrls: ['./activation-error.component.sass'],
})
export class ActivationErrorComponent implements OnChanges {

  @Input() agreement?: TrainingPaymentAgreement;

  rightCurrency?: string;

  wrongCurrency?: string;

  WRONG_CURRENCY_CODE = TrainingPaymentAgreementActivationErrorCodeMap.WRONG_CURRENCY;

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['agreement']) {

      this.onAgreementChange();

    }

  }

  private onAgreementChange() {

    if (this.agreement) {

      const message = this.agreement.failure?.error_message as string;

      const result = message.match(CURRENCY_MATCHER) || [];

      switch (this.agreement.failure?.error_code) {

        case TrainingPaymentAgreementActivationErrorCodeMap.WRONG_CURRENCY:

          this.wrongCurrency = result[0];

          this.rightCurrency = result[1];

          break;

      }

    }

  }

}
