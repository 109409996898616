import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UpdateForm } from './update-form.form';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ng-user-update-form',
  templateUrl: './update-form.component.html',
  styleUrls: ['./update-form.component.sass'],
})
export class UpdateFormComponent implements OnChanges {

  @Input() form: UpdateForm;

  localTimeZoneControl: FormControl;

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['form'] && this.form) {

      this.localTimeZoneControl = this.form.get('local_time_zone') as FormControl;

    }

  }

}
