import { inject, Inject, Injectable } from '@angular/core';
import { AuthService } from '@engagedcx/ng-amplify';
import {Stripe} from 'stripe';
import { props } from 'bluebird';
import { ChargeServiceAdapter } from './charge.service.adapter';
import { StateFacade, StateService } from '../../state/state.service';
import { BACKEND_ADAPTER, BackendAdapter, BackendAdapterMap, IdService } from '@bcx/ng-helpers';
import { WebsocketService } from './websocket.service';
import { RestService } from './rest.service';
import { STRIPE_CHARGE, StripeChargeGetAction, StripeChargeGetPayload, StripeChargeListAction, StripeChargeListPayload } from '@bcx/models';
import { stripeCharge as schemas } from '@bcx/schemas';

export function createStateId(input: Pick<Stripe.Charge, 'id'>) {

  return `${input.id}`;

}

@Injectable({
  providedIn: 'root'
})
export class ChargeService {

  private adapter: ChargeServiceAdapter;

  private state: StateFacade<Stripe.Charge, string>;

  constructor(
    private auth: AuthService,
    @Inject(BACKEND_ADAPTER) backendAdapter: BackendAdapter,
    private id: IdService,
    state: StateService,
  ) { 

    switch (backendAdapter) {

      case BackendAdapterMap.REST:

        this.adapter = inject(RestService);

        break;

      case BackendAdapterMap.WEBSOCKET:

        this.adapter = inject(WebsocketService);

        break;

      default:

        throw new Error(`unknown adapter: ${backendAdapter}`);

    }

    this.state = state.facade<Stripe.Charge, string>(STRIPE_CHARGE, { createStateId, });

  }

  get(data: Partial<StripeChargeGetPayload>, now = Date.now()) {

    return props({
      authed: this.auth.waitForIdentity(),
      clean: schemas.get.validateAsync(data, {}),
    })
    .then(({ authed, clean }) => {

      const id = createStateId(clean);

      const action = new StripeChargeGetAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: authed.user_id,
      });

      this.state.single({
        id,
        loading: true,
      });

      return this.adapter.get(action)
      .then((event) => {
  
        const model = event.payload;

        this.state.single({
          id,
          loading: false,
          model,
        });
  
        return model;
  
      })
      .catch((e) => {
  
        this.state.single({
          e,
          id,
          loading: false,
        });
  
        throw e;
  
      });

    });

  }

  list(data: Partial<StripeChargeListPayload>, now = Date.now()) {

    return props({
      clean: schemas.list.validateAsync(data, {}),
      authed: this.auth.waitForIdentity(),
    })
    .then(({ authed, clean }) => {

      const group = 'all';

      const action = new StripeChargeListAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: group,
        payload: clean,
        user_id: authed.user_id,
      });

      this.state.many({
        group,
        loading: true,
      });
      
      return this.adapter.list(action)
      .then((event) => {
  
        const list = event.payload;

        this.state.many({
          group,
          list: list.data,
          loading: false,
        });
        
        return list;
  
      })
      .catch((e) => {
  
        this.state.many({
          e,
          group,
          loading: false,
        });
        
        throw e;
  
      });

    });

  }

  select(id: string) {

    return this.state.select(id);

  }

}
