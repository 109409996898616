<div fxLayout="row" fxLayoutAlign="space-between center"
  mat-dialog-title>

  <h1 class="mat-headline-5">Payment Method Added</h1>

  <button type="button" color="warn"
    (click)="closeDialog()"
    mat-icon-button><mat-icon>close</mat-icon></button>

</div>

<mat-dialog-content>
<!-- 
  <ng-stripe-payment-method-label 
    *ngIf="paymentMethod"
    [paymentMethod]="paymentMethod"></ng-stripe-payment-method-label> -->

</mat-dialog-content>

<mat-dialog-actions>

  <mat-spinner *ngIf="loading" diameter="30"></mat-spinner>

</mat-dialog-actions>
