import { Injectable, OnDestroy } from '@angular/core';
import { Store, createSelector } from '@ngrx/store';
import { ApiService } from '@engagedcx/ng-amplify';
import { AuthService } from '@engagedcx/ng-amplify';
import { props } from 'bluebird';
import { MatDialog } from '@angular/material/dialog';

import { AddState, State, createEntityId, getEntity } from './state/payment-method.reducer';
import { Observable, Subscription, distinctUntilKeyChanged, filter } from 'rxjs';
import { Stripe } from 'stripe';
import { selectAdd, selectAll, selectForCustomer, selectMy, selectSetupIntent, selectState } from './state/payment-method.selectors';
import { AxiosResponse } from 'axios';
import { addPaymentMethod, addPaymentMethodFailure, addPaymentMethodSuccess, loadAllPaymentMethods, loadAllPaymentMethodsFailure, loadAllPaymentMethodsSuccess, loadMyPaymentMethods, loadMyPaymentMethodsFailure, loadMyPaymentMethodsSuccess, loadPaymentMethod, retrieveSetupIntent, retrieveSetupIntentFailure, retrieveSetupIntentSuccess } from './state/payment-method.actions';
import { StripeService } from '../stripe.service';
import { AddDialogComponent, AddDialogData } from './add-dialog/add-dialog.component';
import { SetupIntent, StripeElementsOptions } from '@stripe/stripe-js';
import { ActivatedRoute, Params } from '@angular/router';
import { AddSuccessData, AddSuccessDialogComponent } from './add-success-dialog/add-success-dialog.component';
import { StripePaymentMethodListPayload } from '@bcx/models';
import { AllEntitiesState, ManyEntitiesState } from '@bcx/ng-helpers';
import { stripePaymentMethod as schemas } from '@bcx/schemas';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService implements OnDestroy {

  add$: Observable<AddState>;

  all$: Observable<AllEntitiesState<Stripe.PaymentMethod>>;

  basePath = '/stripe/payment-methods';

  my$: Observable<ManyEntitiesState<Stripe.PaymentMethod>>;

  private sub: Subscription;

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private store: Store<State>,
    private stripe: StripeService,
  ) { 

    this.add$ = this.store.select(selectAdd);

    this.all$ = this.store.select(selectAll);

    this.my$ = this.store.select(selectMy);

  }

  add() {

    this.store.dispatch(addPaymentMethod());

    return this.auth.waitForIdentity()
    .then(() => {

      const path = `/stripe/payment-methods`;

      return this.api.post(path, {});

    })
    .then((response: AxiosResponse<Stripe.SetupIntent>) => {

      const data = response.data;

      this.store.dispatch(addPaymentMethodSuccess({ data }));

      return data;

    })
    .catch((e: Error) => {

      this.store.dispatch(addPaymentMethodFailure({error: e.message}));

      throw e;

    });

  }

  addDialog(data: AddDialogData) {

    const ref = this.dialog.open(AddDialogComponent, {
      data,
      maxWidth: '600px',
      width: '600px'
    });

    return ref;

  }

  addSuccessDialog(data: AddSuccessData) {

    this.dialog.open(AddSuccessDialogComponent, {
      data,
    });

  }

  confirm(returnUrl: string) {

    this.store.dispatch(addPaymentMethod());

    // will redirect to confirm payment details
    // and then to return_url

    return this.stripe.confirmSetupIntent({
      return_url: returnUrl
    });

  }

  createPaymentElement(setupIntent: Stripe.SetupIntent, options: StripeElementsOptions = {}) {

    return this.stripe.createPaymentElement(setupIntent, options);

  }

  get(id: string) {

    return props({
      clean: schemas.get.validateAsync({ id }, {}),
      creds: this.auth.waitForCredentials(),
    })
    .then(({ clean }) => {

      const path = `${this.basePath}/${clean.id}`;

      this.store.dispatch(loadPaymentMethod({
        id: clean.id,
        loading: true,
      }));

      return this.api.get(path, clean)
      .then((response: AxiosResponse<Stripe.PaymentMethod>) => {

        const paymentMethod = response.data;

        this.store.dispatch(loadPaymentMethod({
          id: clean.id,
          loading: false,
          paymentMethod,
        }));

        return paymentMethod;

      })
      .catch((e: Error) => {

        this.store.dispatch(loadPaymentMethod({
          error: e.message,
          id: clean.id,
          loading: false,
        }));

        throw e;

      });

    })

  }

  init() {

    this.sub = this.route.queryParams
    .pipe(filter((query: Params) => {

      return (query['setup_intent'] && query['setup_intent_client_secret'])

    }))
    .pipe(distinctUntilKeyChanged('setup_intent'))
    .subscribe(({ setup_intent, setup_intent_client_secret }) => {

      this.addSuccessDialog({
        setup_intent,
        setup_intent_client_secret,
      });

      this.retrieveSetupIntent(setup_intent, setup_intent_client_secret)
      .catch((e: Error) => {

        console.error(e);

      });

      this.my()
      .catch((e: Error) => {

        console.error(e);

      })

    });

  }

  list(data: StripePaymentMethodListPayload = {}) {

    this.store.dispatch(loadAllPaymentMethods());

    return props({
      clean: schemas.list.validateAsync(data, {}),
      creds: this.auth.waitForCredentials(),
    })
    .then(({ clean }) => {

      const path = `/stripe/payment-methods`;

      return this.api.get(path, clean);

    })
    .then((response: AxiosResponse<Stripe.ApiList<Stripe.PaymentMethod>>) => {

      const data = response.data;

      this.store.dispatch(loadAllPaymentMethodsSuccess({ data }));

      return data;

    })
    .catch((e: Error) => {

      this.store.dispatch(loadAllPaymentMethodsFailure({ error: e.message }));

      throw e;

    });

  }

  listForCustomer(data: StripePaymentMethodListPayload) {

    return this.list(data);

  }

  my() {

    this.store.dispatch(loadMyPaymentMethods());

    return this.auth.waitForIdentity()
    .then(() => {

      const path = `/stripe/payment-methods/my`;

      return this.api.get(path);

    })
    .then((response: AxiosResponse<Stripe.ApiList<Stripe.PaymentMethod>>) => {

      const data = response.data;

      this.store.dispatch(loadMyPaymentMethodsSuccess({ data }));

      return data;

    })
    .catch((e: Error) => {

      this.store.dispatch(loadMyPaymentMethodsFailure({ error: e.message }));

      throw e;

    });

  }

  ngOnDestroy(): void {
    
    this.sub?.unsubscribe();

  }

  retrieveSetupIntent(intent: string, secret: string) {

    this.store.dispatch(retrieveSetupIntent({ intent }))

    return this.stripe.retrieveSetupIntent(secret)
    .then((setupIntent: SetupIntent) => {

      this.store.dispatch(retrieveSetupIntentSuccess({ intent, setupIntent }));

      return setupIntent;

    })
    .catch((e: Error) => {

      this.store.dispatch(retrieveSetupIntentFailure({ error: e.message, intent }));

      throw e;

    });

  }

  select(id: string) {

    return this.store.select(createSelector(
      selectState,
      (state: State) => {

        const pm = createEntityId(id);

        const entity = getEntity(pm, state);

        return entity;

      },
    ));

  }

  selectForCustomer(customer: string) {

    return this.store.select(selectForCustomer(customer));

  }

  selectSetupIntent(intent: string) {

    return this.store.select(selectSetupIntent(intent));

  }

}
