import { Component, Input, TemplateRef } from '@angular/core';
import { CalendarEvent } from '../event';
import { Subject } from 'rxjs';

@Component({
  selector: 'ng-calendar-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.sass'],
})
export class EventComponent {

  @Input() eventClick: Subject<CalendarEvent>;

  @Input() event: CalendarEvent;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() linkTemplate: TemplateRef<any>;

  @Input() processing = false;

  onClick() {

    if (this.eventClick) {

      this.eventClick.next(this.event);

    }

  }

}
