import { Inject, Injectable, InjectionToken, Injector, OnDestroy } from '@angular/core';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { stringify } from 'qs';
import { JWT } from '@aws-amplify/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { DEFAULT_LOGGER } from '../logger';
import { Logger } from 'pino';
import { AWSCredentials, Signer } from '@aws-amplify/core/internals/utils';
import { ConfigService, DEFAULT_REGION } from '@bcx/ng-helpers';
import { RESTProviderConfig } from '@aws-amplify/core/dist/esm/singleton/API/types';
import { Headers, signRequest } from '@aws-amplify/core/internals/aws-client-utils';
import Bluebird from 'bluebird';

export const DEFAULT_API_NAME = new InjectionToken<string | null>('Default API name', {
  factory: () => {

    return null;

  },
});

@Injectable({
  providedIn: 'root'
})
export class ApiService implements OnDestroy {

  private activeConfig: RESTProviderConfig;

  private defaultService = 'execute-api';

  private endpoint?: string;

  private identityToken?: JWT;

  private logger: Logger;

  private region: string;

  private service: string;

  private subs: { [key: string]: Subscription } = {};

  constructor(
    private auth: AuthService, 
    private config: ConfigService,
    @Inject(DEFAULT_REGION) private defaultRegion: string, 
    private injector: Injector,
    @Inject(DEFAULT_LOGGER) parent: Logger,
  ) { 

    this.logger = parent.child({
      service: 'ApiService',
    });

    this.region = defaultRegion;

    this.service = this.defaultService;

    this.subs['config'] = this.config.active$
    .subscribe(({ amplify }) => {

      this.logger.debug('config', amplify);

      if (amplify && amplify['API']) {

        this.onConfig(amplify.API as RESTProviderConfig);

      }

    });

    this.subs['session'] = this.auth.identityToken$
    .subscribe((token) => {

      if (token) {

        this.onIdentity(token);

      }

    });

  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createUrl(path: string, query?: any) {

    let url = `${this.endpoint}${path}`;

    if (query) {

      url += `?${stringify(query, { indices: false })}`;

    }

    return url;

  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete<Response = any>(path: string, data: any, query?: any) {

    const url = this.createUrl(path, query);

    return this.sendRequest<Response>({
      data: data,
      method: 'DELETE',
      responseType: 'json',
      url: url
    });

  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get<Response = any>(path: string, query?: any) {

    const url = this.createUrl(path, query);

    return this.sendRequest<Response>({
      method: 'GET',
      responseType: 'json',
      url: url
    });

  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleException(exception: any) {

    console.error('ApiService', exception);

  }

  init() {

    return Promise.resolve();

  }

  ngOnDestroy(): void {
    
    Object.keys(this.subs).forEach((key: string) => {

      this.subs[key]?.unsubscribe();

    });

  }

  private onConfig(config: RESTProviderConfig) {
    
    this.logger.debug('onConfig', config);

    if (config.REST) {

      this.activeConfig = config;

      const defaultAPIName = this.injector.get(DEFAULT_API_NAME);

      if (defaultAPIName) {

        const api = this.activeConfig.REST[defaultAPIName];

        this.endpoint = api.endpoint;
        this.region = api.region || this.defaultRegion;
        this.service = api.service || this.defaultService;

      }

    }

  }

  private onCredentials() {



  }

  private onIdentity(token: JWT) {

    this.logger.debug('onIdentity', token);

    this.identityToken = token;

  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post<Response = any>(path: string, data: any, query?: any) {

    const url = this.createUrl(path, query);

    return this.sendRequest<Response>({
      data: data,
      method: 'POST',
      responseType: 'json',
      url: url
    });

  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put<Response = any>(path: string, data: any, query?: any) {

    const url = this.createUrl(path, query);

    return this.sendRequest<Response>({
      data: data,
      method: 'PUT',
      responseType: 'json',
      url: url
    });

  }

  safeResponse<T,D>(response: AxiosResponse<T,D>) {

    return {
      data: response.data,
      headers: response.headers,
      status: response.status,
      statusText: response.statusText
    } as AxiosResponse<T,D>;

  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendRequest<Response = any>(request: AxiosRequestConfig) {
    
    this.logger.debug('sendRequest', request);

    return this.auth.getCredentials()
    .then((credentials) => {
      
      this.logger.debug('sendRequest', credentials);

      return this.signRequest(credentials, request);
      
    })
    .then((signed) => {
      
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return axios.request<any, AxiosResponse<Response>>({
        ...signed,
        url: signed.url.toString(),
      });
      
    });
    
  }
  
  private signRequest(creds: AWSCredentials, req: AxiosRequestConfig) {
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let data: any;
    
    return Bluebird.try(() => {

      if (this.identityToken) {
      
        this.logger.debug({
          identity: this.identityToken 
        }, 'signRequest has identity');
  
        return this.auth.getSession()
        .then((session) => {
          
          req.headers = req.headers || {};
          
          req.headers['x-authed-user'] = session.tokens?.idToken;
      
        });
        
      } else {
  
        this.logger.info('signRequest no identity');

        return;
  
      }
      
    })
    .then(() => {
        
      if (req.data && typeof req.data !== 'string') {
        
        data = req.data;
        
        req.data = JSON.stringify(req.data);
        
      }
      
      const signed = signRequest({
        body: req.data,
        headers: req.headers as Headers || {},
        url: new URL(req.url as string),
        method: req.method as string,
      }, {
        credentials: creds,
        signingRegion: this.region,
        signingService: this.service,
      });
      
      delete signed.headers['host'];
      
      return {
        body: (data) ? data : undefined,
        headers: signed.headers,
        method: signed.method,
        url: signed.url,
      };
      
    });

  }

}