
import { addDays, endOfWeek, format, isBefore, startOfWeek } from "date-fns";
import { CalendarDay } from "../day/day";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class CalendarWeek<Data = any> {

  begins: Date;

  days: Array<CalendarDay<Data>>;

  ends: Date;

  month: number;

  monthName: string;

  constructor(begins: Date) {

    this.begins = startOfWeek(begins);
    this.ends = endOfWeek(begins);
    this.month = begins.getMonth() + 1;
    this.monthName = format(begins, 'LLLL');

    this.calculateDays();

  }

  calculateDays() {

    let iterator = startOfWeek(this.begins);

    this.days = [];

    while (isBefore(iterator, this.ends)) {

      const day = new CalendarDay<Data>(iterator);

      this.days.push(day);

      iterator = addDays(iterator, 1);

    }

  }

}
