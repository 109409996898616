import { Component, Inject, OnInit } from '@angular/core';
import { StartForm } from '../start-form/start-form.form';
import { SubsComponent } from '@bcx/ng-helpers';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClassService } from '../class.service';
import { TrainingClass } from '@bcx/models';

@Component({
  selector: 'ng-training-class-start-dialog',
  templateUrl: './start-dialog.component.html',
  styleUrls: ['./start-dialog.component.sass'],
})
export class StartDialogComponent extends SubsComponent implements OnInit {

  error?: string;

  form = new StartForm();

  processing: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public trainingClass: TrainingClass,
    private dialog: MatDialogRef<StartDialogComponent>,
    private service: ClassService,
  ) {

    super();

  }

  closeDialog() {

    this.dialog.close();

  }

  ngOnInit(): void {
    
    // this.subs['trainingClass'] = this.service.select(this.trainingClass.class_id)
    // .subscribe(({ error, processing }) => {

    //   this.error = error;

    //   this.processing = processing;

    // });

  }

  submit() {

    return this.service.start(this.trainingClass, {
      started_epoch: this.form.value.started_epoch,
    })
    .then((started: TrainingClass) => {

      this.dialog.close(started);

    })
    .catch((e: Error) => {

      console.error(e);

    });

  }

}
