import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { TrainingCourseMap, TrainingTicket } from '@bcx/models';

@Component({
  selector: 'ng-training-ticket-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.sass'],
})
export class CardComponent implements OnChanges {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  @Input() error?: string;

  isOwner = false;

  isProvider = false;

  @Input() loading: boolean;

  showAll = false;

  @Input() ticket?: TrainingTicket;

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['ticket']) {

      this.onTicketChange();

    }

  }

  private onTicketChange() {

    this.isOwner = false;

    this.isProvider = false;

    if (this.ticket) {

      switch (this.ticket.course) {

        case TrainingCourseMap.COACH:
        case TrainingCourseMap.OWNER:
        case TrainingCourseMap.SMALL_TO_BEST:

          this.isOwner = true;

          break;

        case TrainingCourseMap.PROVIDER:

          this.isProvider = true;

          break;

      }

    }

  }

  toggleShowAll() {

    this.showAll = !this.showAll;

  }

}
