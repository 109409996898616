import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

export const ONE_DAY = 12 * 60;

export const ONE_HOUR = 60;

export const TWO_HOUR = 120;

@Component({
  selector: 'ng-consulting-session-select-length',
  templateUrl: './select-length.component.html',
  styleUrls: ['./select-length.component.sass'],
})
export class SelectLengthComponent {

  @Input() control: FormControl;

  set1Day() {

    this.control.patchValue(ONE_DAY);

  }
  
  set1Hour() {

    this.control.patchValue(ONE_HOUR);

  }
  
  set2Hour() {

    this.control.patchValue(TWO_HOUR);

  }
  
}
