<ng-container
  *ngIf="(vm$ | async) as vm">

  <mat-stepper orientation="vertical">

    <mat-step 
      *ngFor="let step of vm.path.steps; index as i"
      [completed]="false"
      [editable]="true">
  
      <ng-template matStepLabel
        [ngSwitch]="step.step_type">
        
        <app-small-to-best-book-step-label *ngSwitchCase="BOOK"></app-small-to-best-book-step-label>

        <app-small-to-best-consulting-step-label *ngSwitchCase="CONSULTING"></app-small-to-best-consulting-step-label>
  
        <app-small-to-best-training-step-label *ngSwitchCase="TRAINING"></app-small-to-best-training-step-label>
  
      </ng-template>
  
      <ng-template matStepContent
        [ngSwitch]="step.step_type">
  
        <div class="flex flex-row"
          *ngIf="i === 0">

          <button type="button" color="primary"
            (click)="showOtherOptions()"
            mat-button>Other options starting {{vm.otherOptionsPrice | stripeCurrency:vm.currency}}</button>
      
        </div>
      
        <app-small-to-best-book-step-content *ngSwitchCase="BOOK"></app-small-to-best-book-step-content>
  
        <app-small-to-best-consulting-step-content *ngSwitchCase="CONSULTING"></app-small-to-best-consulting-step-content>

        <app-small-to-best-training-step-content *ngSwitchCase="TRAINING"></app-small-to-best-training-step-content>
  
      </ng-template>
  
    </mat-step>
  
    <mat-step 
      [completed]="false" 
      [editable]="true">
  
      <ng-template matStepLabel>Success! Your small business is providing the best experiences possible!</ng-template>
      
      <ng-template matStepContent>
  
  
  
      </ng-template>
  
    </mat-step>
  
  </mat-stepper>

</ng-container>
