
export class Deferred<Result , Reason> {

  promise: Promise<Result>;

  reject: (reason: Reason) => void;

  resolve: (result: Result) => void;

  constructor() {

    this.promise = new Promise<Result>((resolve, reject) => {

      this.reject = reject;

      this.resolve = resolve;

    });

  }

}

export function createDeferred<Result, Reason>() {

  return new Deferred<Result , Reason>();

}