import { Component, Input } from '@angular/core';
import { Stripe } from 'stripe';

@Component({
  selector: 'ng-stripe-customer-full-details',
  templateUrl: './full-details.component.html',
  styleUrls: ['./full-details.component.sass'],
})
export class FullDetailsComponent {

  @Input() customer: Stripe.Customer;

}
