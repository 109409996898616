<div fxLayout="column">

  <mat-card
    *ngIf="agreement">

    <mat-card-header>

      <mat-card-title>Agreement #{{agreement.agreement_id}}</mat-card-title>
      <mat-card-subtitle>{{agreement.status}}</mat-card-subtitle>

    </mat-card-header>

    <mat-card-content>

      <dl class="better-dl" fxLayout="row">
        <dt>Amount of Payment:</dt><dd>{{agreement.details.amount_of_payment | stripeCurrency:agreement.details.currency}}</dd>
        <span fxFlex></span>
        <dt>Number of Payments:</dt><dd>{{agreement.details.number_of_payments}}</dd>
        <span fxFlex></span>
        <dt>Discounted Total:</dt><dd>{{agreement.details.discount?.balance | stripeCurrency:agreement.details.currency}}</dd>
        <span fxFlex></span>
        <dt>You Are Saving:</dt><dd>{{agreement.details.discount?.savings_amount | stripeCurrency:agreement.details.currency}}</dd>
      </dl>

      <pre *ngIf="false">{{agreement | json}}</pre>

    </mat-card-content>

    <mat-card-actions>

      <ng-container *ngTemplateOutlet="actions; context: { agreement }"></ng-container>

    </mat-card-actions>

  </mat-card>

</div>