import { Injectable } from '@angular/core';
import { RestServerResponse, TRAINING_TICKET, TrainingTicket, TrainingTicketCancelAction, TrainingTicketCanceledEvent, TrainingTicketGetAction, TrainingTicketInterface, TrainingTicketListAction, TrainingTicketListedEvent, TrainingTicketRedeemAction, TrainingTicketRedeemedEvent, TrainingTicketRevokeAction, TrainingTicketRevokedEvent, TrainingTicketSuspendAction, TrainingTicketSuspendedEvent, TrainingTicketViewedEvent } from '@bcx/models';
import { ApiService } from '@engagedcx/ng-amplify';
import { TicketServiceAdapter } from './ticket.service.adapter';
import { List } from '@digitaltoolbuilders/model-helpers';

@Injectable({
  providedIn: 'root'
})
export class RestService implements TicketServiceAdapter {

  private path = `/${TRAINING_TICKET}`;

  constructor(private api: ApiService) { }

  cancel(action: TrainingTicketCancelAction): Promise<TrainingTicketCanceledEvent> {
    
    return this.api.post<RestServerResponse<TrainingTicketInterface>>(this.path, action)
    .then((response) => {

      return new TrainingTicketCanceledEvent({
        ...response.data,
        payload: new TrainingTicket(response.data.payload),
      });

    });

  }

  get(action: TrainingTicketGetAction): Promise<TrainingTicketViewedEvent> {
    
    return this.api.post<RestServerResponse<TrainingTicketInterface>>(this.path, action)
    .then((response) => {

      return new TrainingTicketViewedEvent({
        ...response.data,
        payload: new TrainingTicket(response.data.payload),
      });

    });

  }

  list(action: TrainingTicketListAction): Promise<TrainingTicketListedEvent> {
      
    return this.api.post<RestServerResponse<List<TrainingTicketInterface>>>(this.path, action)
    .then((response) => {

      return new TrainingTicketListedEvent({
        ...response.data,
        payload: new List<TrainingTicket>({
          ...response.data.payload,
          list: response.data.payload.list.map((item) => new TrainingTicket(item)),
        }),
      });

    });

  }

  redeem(action: TrainingTicketRedeemAction): Promise<TrainingTicketRedeemedEvent> {
      
    return this.api.post<RestServerResponse<TrainingTicketInterface>>(this.path, action)
    .then((response) => {

      return new TrainingTicketRedeemedEvent({
        ...response.data,
        payload: new TrainingTicket(response.data.payload),
      });

    });

  }

  revoke(action: TrainingTicketRevokeAction): Promise<TrainingTicketRevokedEvent> {
    
    return this.api.post<RestServerResponse<TrainingTicketInterface>>(this.path, action)
    .then((response) => {

      return new TrainingTicketRevokedEvent({
        ...response.data,
        payload: new TrainingTicket(response.data.payload),
      });

    });

  }

  suspend(action: TrainingTicketSuspendAction): Promise<TrainingTicketSuspendedEvent> {
      
    return this.api.post<RestServerResponse<TrainingTicketInterface>>(this.path, action)
    .then((response) => {

      return new TrainingTicketSuspendedEvent({
        ...response.data,
        payload: new TrainingTicket(response.data.payload),
      });

    });

  }

}
