import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ng-user-terms-of-service-form',
  templateUrl: './terms-of-service-form.component.html',
  styleUrls: ['./terms-of-service-form.component.sass'],
})
export class TermsOfServiceFormComponent {

  @Input() control: FormControl;

}
