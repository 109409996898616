import { Component, Input } from '@angular/core';
import { RequestForm } from './request-form.form';
import { CreateFormComponent } from '../create-form/create-form.component';

@Component({
  selector: 'ng-consulting-session-request-form',
  templateUrl: './request-form.component.html',
  styleUrls: ['./request-form.component.sass'],
})
export class RequestFormComponent extends CreateFormComponent {

  @Input() override form: RequestForm;

}
