<div fxLayout="column">

  <div fxLayout="row" fxLayoutAlign="space-between">

    <h1 class="mat-headline-5">Seating Chart</h1>

    <mat-spinner *ngIf="loading" diameter="30"></mat-spinner>

  </div>

  <div fxLayout="row wrap" fxLayoutGap="10px">

    <ng-template #actions let-seat="seat">

      <button type="button" color="primary"
        *ngIf="canConfirm(seat)"
        (click)="confirm(seat)"
        mat-raised-button>Confirm</button>

      <ng-training-seat-resend-confirmation-button  
        *ngIf="canSendConfirmationEmail(seat)"
        [seat]="seat"></ng-training-seat-resend-confirmation-button>
<!-- 
      <button type="button" color="primary"
        *ngIf="canSendConfirmationEmail(seat)"
        (click)="sendConfirmationEmail(seat)"
        mat-raised-button>Send Confirmation Email</button> -->

      <button type="button" color="warn"
        *ngIf="canRelease(seat)"
        (click)="release(seat)"
        mat-raised-button>Release</button>

    </ng-template>

    <div *ngFor="let seat of seats">

      <ng-training-seat-summary-card 
        [actions]="actions"
        [seat]="seat"></ng-training-seat-summary-card>
  
    </div>

    <div *ngIf="seats.length === 0">

      <mat-card>

        <mat-card-content>

          <span>No Seats</span>

        </mat-card-content>

      </mat-card>

    </div>

  </div>

</div>
