<div fxLayout="column"
  *ngIf="form"
  [formGroup]="form">

  <div fxLayout="column"
    *ngIf="attendee">

    <!-- <ng-user-name 
      [userId]="attendee.user_id"></ng-user-name> -->

    <span>Attending As: {{attendee.attending_as}}</span>

  </div>

  <p>Are you sure you want to delete this attendee?</p>

  <mat-slide-toggle formControlName="confirm">Yes, I want to remove this attendee.</mat-slide-toggle>

</div>
