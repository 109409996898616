import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConsultingSessionCategories } from '@bcx/models';

@Component({
  selector: 'ng-consulting-session-select-role',
  templateUrl: './select-role.component.html',
  styleUrls: ['./select-role.component.sass'],
})
export class SelectRoleComponent {

  @Input() category?: string;

  categories = ConsultingSessionCategories;

  @Input() control: FormControl;

  @Input() currency?: string;

  @Input() sessionType?: string;

}
