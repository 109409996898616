<div class="flex flex-col margin10 border">

  <h2 class="padding10">Consulting</h2>

  <div class="flex flex-col sm:flex-row consulting">

    <div class="flex flex-col padding10">

      <mat-card class="margin10">

        <mat-card-content>

          <p>Available in 15 min increments. </p>
          <p>Schedule on website or call. </p>
          <p>Can be hosted online or in person.</p>
      
          <div class="flex flex-row">

            <div class="flex flex-col">
              
              <p>500 W Broadway Ste 300<br/>
                SLC, UT 84101<br/>
                1-385-xxx-xxx</p>
            
            </div>

            <div class="flex flex-col align-end justify-end">
    
              <a color="basic"
              class="box-shadow"
              mat-raised-button
              routerLink="/training/register">Learn More</a> 
          
            </div>
          
          </div> 
        
        </mat-card-content>

      </mat-card>
      
      
    </div>

    <div class="flex flex-col padding10">

      <mat-card class="margin10">

        <mat-card-content>

          <p>We can discuss anything you want during your consulting session.</p>
          <p>We can help you design your experiences, build engagements plans, build your How To, 
            review your current experiences and help you make them the best.</p>
          <p>We can also spend time working on a specific practice or help you reach your goals.</p>
        
        </mat-card-content>

        <mat-card-actions>
      
          <a color="primary"
            mat-raised-button
            routerLink="/consulting/schedule-meeting">Schedule Meeting</a>
    
        </mat-card-actions>

      </mat-card>
    
    </div>
    
  </div>

</div>