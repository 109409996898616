import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card/card.component';
import { CreateFormComponent } from './create-form/create-form.component';
import { ListCardComponent } from './list-card/list-card.component';
import { ListTableComponent } from './list-table/list-table.component';
import { StubComponent } from './stub/stub.component';
import { SummaryCardComponent } from './summary-card/summary-card.component';
import { TearoffComponent } from './tearoff/tearoff.component';
import { BookModule } from './book/book.module';
import { TicketClassModule } from './class/class.module';
import { VideoModule } from './video/video.module';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
  declarations: [
    CardComponent,
    CreateFormComponent,
    ListCardComponent,
    ListTableComponent,
    StubComponent,
    SummaryCardComponent,
    TearoffComponent,
  ],
  exports: [
    BookModule,
    CardComponent,
    TicketClassModule,
    CreateFormComponent,
    ListCardComponent,
    ListTableComponent,
    StubComponent,
    SummaryCardComponent,
    TearoffComponent,
    VideoModule,
  ],
  imports: [
    BookModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTableModule,
    TicketClassModule,
    VideoModule,
  ]
})
export class TicketModule { }
