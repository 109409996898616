import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

export * from './billing/billing.exports';

export * from './consulting/consulting.exports';

export * from './person/person.exports';

export * from './stripe/stripe.exports';

export * from './training/training.exports';

export * from './user/user.exports';

@NgModule({
  imports: [CommonModule],
})
export class NgModelsModule {}
