import { TrainingCourses, TrainingPaymentAgreementActionNameMap, TrainingPaymentAgreementActivationErrorCodes, TrainingPaymentAgreementCompleteActivationAction, TrainingPaymentAgreementCompleteActivationPayload, TrainingPaymentAgreementCoupon, TrainingPaymentAgreementCreateAction, TrainingPaymentAgreementCreatePayload, TrainingPaymentAgreementCreateTicketsAction, TrainingPaymentAgreementCreateTicketsPayload, TrainingPaymentAgreementDebtor, TrainingPaymentAgreementDetails, TrainingPaymentAgreementDiscount, TrainingPaymentAgreementFailActivationAction, TrainingPaymentAgreementFailActivationPayload, TrainingPaymentAgreementGeneratePDFAction, TrainingPaymentAgreementGeneratePDFPayload, TrainingPaymentAgreementGenerateSignedUrlAction, TrainingPaymentAgreementGenerateSignedUrlPayload, TrainingPaymentAgreementGetAction, TrainingPaymentAgreementGetPayload, TrainingPaymentAgreementListAction, TrainingPaymentAgreementListPayload, TrainingPaymentAgreementSetSubscriptionAction, TrainingPaymentAgreementSetSubscriptionPayload, TrainingPaymentAgreementStatuses, TrainingPaymentAgreementStripe, TrainingPaymentAgreementUpdateAction, TrainingPaymentAgreementUpdatePayload } from "@bcx/models";
import { epoch } from "@digitaltoolbuilders/schema-helpers";
import Joi from "joi";
import { address, currency, date_of_birth, email, locale, phone_number, user_id } from "../common";
import { type as class_type } from './class';
import { customer_id, invoice_id, payment_method_id, subscription_id } from "../stripe";
import { action_id, action_name, createActionSchema } from "../model-action";
import { consistent_read, exclusive_start_key, limit } from "../dynamo-db";

export const agreement_id = Joi.string();

export const balance = Joi.number().integer().min(0);

export const coupon = Joi.object<TrainingPaymentAgreementCoupon>()
  .keys({
    code: Joi.string(),
    savings_amount: balance.required(),
  });

export const course = Joi.string().allow(...TrainingCourses);

export const description = Joi.string();

export const discount = Joi.object<TrainingPaymentAgreementDiscount>()
  .keys({
    balance: balance.required(),
    rate: Joi.number(),
    savings_amount: balance.required(),
  });

export const error_code = Joi.string().allow(...TrainingPaymentAgreementActivationErrorCodes);

export const error_message = Joi.string();

export const first_payment_date = Joi.string();

export const number_of_payments = Joi.number().integer().min(1);

export const payment_day = Joi.number().integer().min(1).max(28);

export const details = Joi.object<TrainingPaymentAgreementDetails>()
  .keys({
    amount_of_payment: Joi.number().integer(),
    balance: balance.required(),
    class_type: class_type.required(),
    coupon: coupon.required(),
    course: course.required(),
    currency: currency.required(),
    discount: discount.required(),
    locale: locale.optional().allow(null),
    number_of_payments: number_of_payments.required(),
    past_due_fee: Joi.number().integer().required(),
  });

export const legal_name = Joi.string();

export const debtor = Joi.object<TrainingPaymentAgreementDebtor>()
  .keys({
    address: address.required(),
    date_of_birth: date_of_birth.required(),
    email: email.required(),
    legal_name: legal_name.required(),
    phone_number: phone_number.required(),
    user_id: user_id.required(),
  });

export const status = Joi.string().allow(...TrainingPaymentAgreementStatuses);

export const stripe = Joi.object<TrainingPaymentAgreementStripe>()
  .keys({
    customer_id: customer_id.required(),
    invoice_id: invoice_id.required(),
    payment_method_id: payment_method_id.required(),
    subscription_id: subscription_id.optional().allow(null),
  });

export const completeActivation = Joi.object<TrainingPaymentAgreementCompleteActivationPayload>()
  .keys({
    activated_epoch: epoch.required(),
    agreement_id: agreement_id.required(),
    last_updated_epoch: epoch.required(),
  });

export const completeActivationAction = createActionSchema<TrainingPaymentAgreementCompleteActivationAction>(TrainingPaymentAgreementActionNameMap.COMPLETE_ACTIVATION, completeActivation);

export const create = Joi.object<TrainingPaymentAgreementCreatePayload>()
  .keys({
    agreement_id: agreement_id.required(),
    debtor: debtor.required(),
    description: description.required(),
    details: details.required(),
    first_payment_date: first_payment_date.optional().allow(null),
    payment_day: payment_day.optional().allow(null),
    start_date_epoch: epoch.optional().allow(null),
    stripe: stripe.optional().allow(null),
  });

export const createAction = createActionSchema<TrainingPaymentAgreementCreateAction>(TrainingPaymentAgreementActionNameMap.CREATE, create);

export const createTickets = Joi.object<TrainingPaymentAgreementCreateTicketsPayload>()
  .keys({
    agreement_id: agreement_id.required(),
    last_updated_epoch: epoch.required(),
  });

export const createTicketsAction = createActionSchema<TrainingPaymentAgreementCreateTicketsAction>(TrainingPaymentAgreementActionNameMap.CREATE_TICKETS, createTickets);

export const failActivation = Joi.object<TrainingPaymentAgreementFailActivationPayload>()
  .keys({
    error_code: error_code.required(),
    error_message: error_message.required(),
    failed_epoch: epoch.required(),
    agreement_id: agreement_id.required(),
    last_updated_epoch: epoch.required(),
  });

export const failActivationAction = createActionSchema<TrainingPaymentAgreementFailActivationAction>(
  TrainingPaymentAgreementActionNameMap.FAIL_ACTIVATION, 
  failActivation
);

export const generatePDF = Joi.object<TrainingPaymentAgreementGeneratePDFPayload>()
  .keys({
    agreement_id: agreement_id.required(),
  });

export const generatePDFAction = createActionSchema<TrainingPaymentAgreementGeneratePDFAction>(TrainingPaymentAgreementActionNameMap.GENERATE_PDF, generatePDF);

export const generateSignedUrl = Joi.object<TrainingPaymentAgreementGenerateSignedUrlPayload>()
  .keys({
    agreement_id: agreement_id.required(),
  });

export const generateSignedUrlAction = createActionSchema<TrainingPaymentAgreementGenerateSignedUrlAction>(TrainingPaymentAgreementActionNameMap.GENERATE_SIGNED_URL, generateSignedUrl);

export const get = Joi.object<TrainingPaymentAgreementGetPayload>()
  .keys({
    agreement_id: agreement_id.required(),
    consistent_read: consistent_read,
  });

export const getAction = createActionSchema<TrainingPaymentAgreementGetAction>(TrainingPaymentAgreementActionNameMap.GET, get);

export const list = Joi.object<TrainingPaymentAgreementListPayload>()
  .keys({
    exclusive_start_key: exclusive_start_key,
    limit: limit,
    status: status.optional().allow(null),
  });

export const listAction = createActionSchema<TrainingPaymentAgreementListAction>(TrainingPaymentAgreementActionNameMap.LIST, list);

export const setSubscription = Joi.object<TrainingPaymentAgreementSetSubscriptionPayload>()
  .keys({
    agreement_id: agreement_id.required(),
    last_updated_epoch: epoch.required(),
    stripe_subscription_id: subscription_id.required(),
  });

export const setSubscriptionAction = createActionSchema<TrainingPaymentAgreementSetSubscriptionAction>(TrainingPaymentAgreementActionNameMap.SET_SUBSCRIPTION, setSubscription);

export const update = Joi.object<TrainingPaymentAgreementUpdatePayload>()
  .keys({
    agreement_id: agreement_id.required(),
    debtor: debtor.required(),
    description: description.required(),
    details: details.required(),
    first_payment_date: first_payment_date.optional().allow(null),
    payment_day: payment_day.optional().allow(null),
    start_date_epoch: epoch.optional().allow(null),
    stripe: stripe.optional().allow(null),
  });

export const updateAction = createActionSchema<TrainingPaymentAgreementUpdateAction>(TrainingPaymentAgreementActionNameMap.UPDATE, update);
