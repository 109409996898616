
import { createAction, props } from '@ngrx/store';
import { Stripe } from 'stripe';

export interface LoadPriceProps {

  price?: Stripe.Price;

  error?: string;

  id: string;

  loading: boolean;

}

export const loadPrice = createAction(
  '[Stripe.Price] Load Price',
  props<LoadPriceProps>(),
);

export interface LoadAllProps {

  data?: Array<Stripe.Price>;

  error?: string;

  loading: boolean;

}

export const loadAllPrices = createAction(
  '[Stripe.Price] Load All Prices',
  props<LoadAllProps>(),
);
