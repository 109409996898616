import {Stripe} from "stripe";
import { ModelAction, ModelEvent } from "../model-action";
import { STRIPE_CHARGE } from "../common";

export const StripeChargeActionNameMap = {
  GET: `${STRIPE_CHARGE}/get`,
  LIST: `${STRIPE_CHARGE}/list`,
} as const;

export type StripeChargeActionName = typeof StripeChargeActionNameMap[keyof typeof StripeChargeActionNameMap];

export const StripeChargeActionNames = Object.values(StripeChargeActionNameMap);

export const StripeChargeEventNameMap = {
  LISTED: `${STRIPE_CHARGE}:listed`,
  VIEWED: `${STRIPE_CHARGE}:viewed`,
} as const;

export type StripeChargeEventName = typeof StripeChargeEventNameMap[keyof typeof StripeChargeEventNameMap];

export const StripeChargeEventNames = Object.values(StripeChargeEventNameMap);

export class StripeChargeGetAction extends ModelAction<StripeChargeGetPayload> {

  readonly action_name = StripeChargeActionNameMap.GET;

  constructor(input: Partial<Omit<StripeChargeGetAction, 'action_name'>>){

    super(input);

  }

}

export type StripeChargeGetPayload = Stripe.ChargeRetrieveParams & {
  id: string;
};

export class StripeChargeListAction extends ModelAction<StripeChargeListPayload> {

  readonly action_name = StripeChargeActionNameMap.LIST;

  constructor(input: Partial<Omit<StripeChargeListAction, 'action_name'>>){

    super(input);

  }

}

export type StripeChargeListPayload = Stripe.ChargeListParams;

export class StripeChargeListedEvent extends ModelEvent<Stripe.ApiList<Stripe.Charge>> {

  readonly event_name = StripeChargeEventNameMap.LISTED;

  constructor(input: Partial<Omit<StripeChargeListedEvent, 'event_name'>>) {

    super(input);

  }

}

export class StripeChargeViewedEvent extends ModelEvent<Stripe.Charge> {

  readonly event_name = StripeChargeEventNameMap.VIEWED;

  constructor(input: Partial<Omit<StripeChargeViewedEvent, 'event_name'>>) {

    super(input);

  }

}
