
import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, distinctUntilChanged, Observable, throttleTime } from 'rxjs';
import { MediaService, SubsComponent } from '@bcx/ng-helpers';

export interface ViewModel {

  isLargeScreen: boolean;

  scrollY: number;

  showBigLogo: boolean;

}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent extends SubsComponent implements OnInit {

  private showBigLogo = new BehaviorSubject(true);

  showBigLogo$ = this.showBigLogo.asObservable();

  vm$: Observable<ViewModel>;

  private y = new BehaviorSubject(0);

  y$ = this.y.asObservable();

  constructor(
    private media: MediaService,
  ) {

    super();

    this.vm$ = combineLatest({
      isLargeScreen: this.media.isLargeScreen$.pipe(distinctUntilChanged()),
      scrollY: this.y$.pipe(throttleTime(250)),
      showBigLogo: this.showBigLogo$.pipe(distinctUntilChanged()).pipe(throttleTime(250)),
    });

  }

  ngOnInit(): void {
    
    this.subs['scroll'] = this.y$
    .subscribe((y) => {

      // console.log('scroll', y);

      if (y < 15) {

        this.showBigLogo.next(true);

      } else {

        this.showBigLogo.next(false);

      }

    });

  }
  
  @HostListener('window:scroll', ['$event']) 
  onScroll() {

    this.y.next(window.scrollY);

  }

}
