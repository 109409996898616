import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripeCurrency'
})
export class StripeCurrencyPipe implements PipeTransform {

  constructor(private original: CurrencyPipe) {}

  // eslint-disable-next-line
  transform(value: number | null | undefined, currencyCode: string, ...others: any[]): string | null {

    if (typeof value === 'number') {

      let decimaled: number, uppered: string | undefined = undefined;

      if (currencyCode) {

        uppered = currencyCode.toUpperCase();

      }

      switch (currencyCode) {

        case 'cad':
        case 'usd': 

          decimaled = value / 100;

          break;

        default:

          decimaled = value;

      }

      return this.original.transform(decimaled, uppered, ...others);

    }

    return null;

  }

}
