
<div class="ng-calendar-day" fxLayout="column">

  <div class="ng-calendar-header" fxLayout="row" fxLayoutGap="3px">

    <span *ngIf="showMonthName">{{day.monthName}}</span><span>{{day.dayOfMonth}}</span>

  </div>

  <div class="ng-calendar-event-wrapper" fxLayout="column" fxLayoutGap="5px">

    <ng-calendar-event 
      *ngFor="let event of day.events"
      [event]="event"
      [eventClick]="eventClick"
      [linkTemplate]="linkTemplate"></ng-calendar-event>

  </div>

</div>
