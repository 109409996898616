<div
  [formGroup]="form">

  <app-question-toggle-switch
    [control]="earnCommissionBonus"
    label="Do you earn commissions or a bonus?"></app-question-toggle-switch>

  <app-question-select-industry
    [control]="industry"
    label="What industry do you work in?"></app-question-select-industry>

  <app-question-length-of-time
    [control]="lengthInIndustry"
    label="How long have you worked in this industry?"></app-question-length-of-time>

  <app-question-length-of-time
    [control]="lengthInPosition"
    label="How long have you been in your current position?"></app-question-length-of-time>

</div>
