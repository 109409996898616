
import { CoachGoalNameMap, DECREASE_COST_PER_MINUTE, DECREASE_REPEAT_INTERACTIONS, DECREASE_TURN_OVER_RATE, INCREASE_AVERAGE_PURCHASE, INCREASE_COMPLIANCE_QUALITY_SCORES, INCREASE_CSAT, INCREASE_JOB_SATISFACTION, ProductIdMap } from "@bcx/models";
import { GetStartedState } from "../get-started/get-started.state";
import { Recommendation } from "./recommendation.service";

export const CoachRecommendationMap: {
  [key: string]: Recommendation,
} = {} as const;

CoachRecommendationMap[DECREASE_COST_PER_MINUTE] = {
  content: 'Decrease cost per minute',
  isMatch: (state: GetStartedState) => {

    const goal_is_selected = state.coach.goals[DECREASE_COST_PER_MINUTE] || false;

    if (state.is_team_leader && goal_is_selected) {

      return true;

    }

    return false;

  },
  products: [
    ProductIdMap.training.books.coach,
    ProductIdMap.training.courses.coach,
  ]
};

CoachRecommendationMap[DECREASE_REPEAT_INTERACTIONS] = {
  content: 'Decrease repeat interactions',
  isMatch: (state: GetStartedState) => {

    const goal_is_selected = state.coach.goals[DECREASE_REPEAT_INTERACTIONS] || false;

    if (state.is_team_leader && goal_is_selected) {

      return true;

    }

    return false;

  },
  products: [
    ProductIdMap.training.books.coach,
    ProductIdMap.training.courses.coach,
  ]
};

CoachRecommendationMap[DECREASE_TURN_OVER_RATE] = {
  content: 'Decrease repeat interactions',
  isMatch: (state: GetStartedState) => {

    const goal_is_selected = state.coach.goals[DECREASE_TURN_OVER_RATE] || false;

    if (state.is_team_leader && goal_is_selected) {

      return true;

    }

    return false;

  },
  products: [
    ProductIdMap.training.books.coach,
    ProductIdMap.training.courses.coach,
  ]
};

CoachRecommendationMap[INCREASE_AVERAGE_PURCHASE] = {
  content: 'Increase average purchase size',
  isMatch: (state: GetStartedState) => {

    if (state.is_team_leader && (state.coach.goals[CoachGoalNameMap.INCREASE_AVERAGE_PURCHASE] || false)) {

      return true;

    }

    return false;

  },
  products: [
    ProductIdMap.training.books.coach,
    ProductIdMap.training.courses.coach,
  ],
};

CoachRecommendationMap[INCREASE_COMPLIANCE_QUALITY_SCORES] = {
  content: 'Increase compliance and quality scores',
  isMatch: (state: GetStartedState) => {

    if (state.is_team_leader && (state.coach.goals[CoachGoalNameMap.INCREASE_COMPLIANCE_QUALITY_SCORES] || false)) {

      return true;

    }

    return false;

  },
  products: [
    ProductIdMap.training.books.coach,
    ProductIdMap.training.courses.coach,
  ],
};

CoachRecommendationMap[INCREASE_CSAT] = {
  content: 'Increase customer satisfaction scores',
  isMatch: (state: GetStartedState) => {

    if (state.is_team_leader && (state.coach.goals[CoachGoalNameMap.INCREASE_CSAT] || false)) {

      return true;

    }

    return false;

  },
  products: [
    ProductIdMap.training.books.coach,
    ProductIdMap.training.courses.coach,
  ],
};

CoachRecommendationMap[INCREASE_JOB_SATISFACTION] = {
  content: "Increase your team's job satisfaction",
  isMatch: (state: GetStartedState) => {

    const isGoalSelected = (state.coach.goals[CoachGoalNameMap.INCREASE_JOB_SATISFACTION]) ? true : false;

    const isTeamLeader = (state.is_team_leader) ? true : false;

    return isTeamLeader && isGoalSelected;

  },
  products: [
    ProductIdMap.training.books.coach,
    ProductIdMap.training.courses.coach,
  ],
};

export const CoachRecommendations = Object.values(CoachRecommendationMap);
