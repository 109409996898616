import { Component, Input, TemplateRef } from '@angular/core';
import { TrainingSeat } from '@bcx/models';

@Component({
  selector: 'ng-training-seat-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.sass'],
})
export class SummaryCardComponent {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  @Input() seat?: TrainingSeat;

  showMore = false;

}
