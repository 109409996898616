<div fxFlexFill fxLayout="column"
  *ngIf="agreement">

  <ng-training-payment-agreement-summary-card 
    [agreement]="agreement"></ng-training-payment-agreement-summary-card>

  <ng-training-payment-agreement-agreement-card fxFlex
    [agreement]="agreement"></ng-training-payment-agreement-agreement-card>

</div>
