import { Component, Input, TemplateRef } from '@angular/core';
import { Stripe } from 'stripe';

@Component({
  selector: 'ng-stripe-customer-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.sass'],
})
export class SummaryCardComponent {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  @Input() customer: Stripe.Customer;

}
