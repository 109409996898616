import { Component, Input, TemplateRef } from '@angular/core';
import { TrainingSeat } from '@bcx/models';

@Component({
  selector: 'ng-training-seat-list-accordion',
  templateUrl: './list-accordion.component.html',
  styleUrls: ['./list-accordion.component.sass'],
})
export class ListAccordionComponent {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  @Input() seats: Array<TrainingSeat>;

}
