import { Component, Input } from '@angular/core';
import { TrainingTicket } from '@bcx/models';

@Component({
  selector: 'ng-training-ticket-book-stub',
  templateUrl: './stub.component.html',
  styleUrls: ['./stub.component.sass'],
})
export class StubComponent {

  @Input() ticket: TrainingTicket;

}
