<div>

  <div fxLayout="row" fxLayoutAlign="space-between">

    <mat-checkbox [formControl]="selectNow">Now</mat-checkbox>

    <span *ngIf="selectNow.value">Now</span>

  </div>

  <ng-picker-date-picker 
    [control]="control"
    [labelDate]="label"></ng-picker-date-picker>

</div>