import { FormControl, FormGroup, Validators } from "@angular/forms";

export class RescheduleForm extends FormGroup {

  constructor() {

    super({
      scheduled_start_epoch: new FormControl(null, [Validators.required]),
      scheduled_length_minutes: new FormControl(null, [Validators.required]),
    });

  }

}
