import {Stripe} from "stripe";
import { ModelAction, ModelEvent } from "../model-action";
import { STRIPE_PRICE } from "../common";

export const StripePriceActionNameMap = {
  CREATE: `${STRIPE_PRICE}/create`,
  GET: `${STRIPE_PRICE}/get`,
  LIST: `${STRIPE_PRICE}/list`,
  UPDATE: `${STRIPE_PRICE}/update`,
} as const;

export type StripePriceActionName = typeof StripePriceActionNameMap[keyof typeof StripePriceActionNameMap];

export const StripePriceActionNames = Object.values(StripePriceActionNameMap);

export class StripePriceCreateAction extends ModelAction<StripePriceCreatePayload> {

  readonly action_name = StripePriceActionNameMap.CREATE;

  constructor(input: Partial<Omit<StripePriceCreateAction, 'action_name'>>){

    super(input);

  }

}

export class StripePriceCreatedEvent extends ModelEvent<Stripe.Price> {

  readonly event_name = StripePriceEventNameMap.CREATED;

  constructor(input: Partial<Omit<StripePriceCreatedEvent, 'event_name'>>) {

    super(input);

  }

}

export type StripePriceCreatePayload = Stripe.PriceCreateParams;

export const StripePriceEventNameMap = {
  CREATED: `${STRIPE_PRICE}:created`,
  LISTED: `${STRIPE_PRICE}:listed`,
  UPDATED: `${STRIPE_PRICE}:updated`,
  VIEWED: `${STRIPE_PRICE}:viewed`,
} as const;

export type StripePriceEventName = typeof StripePriceEventNameMap[keyof typeof StripePriceEventNameMap];

export const StripePriceEventNames = Object.values(StripePriceEventNameMap);

export class StripePriceGetAction extends ModelAction<StripePriceGetPayload> {

  readonly action_name = StripePriceActionNameMap.GET;

  constructor(input: Partial<Omit<StripePriceGetAction, 'action_name'>>){

    super(input);

  }

}

export type StripePriceGetPayload = Stripe.PriceRetrieveParams & {
  id: string;
};

export class StripePriceListAction extends ModelAction<StripePriceListPayload> {

  readonly action_name = StripePriceActionNameMap.LIST;

  constructor(input: Partial<Omit<StripePriceListAction, 'action_name'>>){

    super(input);

  }

}

export type StripePriceListPayload = Stripe.PriceListParams;

export class StripePriceListedEvent extends ModelEvent<Stripe.ApiList<Stripe.Price>> {

  readonly event_name = StripePriceEventNameMap.LISTED;

  constructor(input: Partial<Omit<StripePriceListedEvent, 'event_name'>>) {

    super(input);

  }

}

export class StripePriceViewedEvent extends ModelEvent<Stripe.Price> {

  readonly event_name = StripePriceEventNameMap.VIEWED;

  constructor(input: Partial<Omit<StripePriceViewedEvent, 'event_name'>>) {

    super(input);

  }

}
