<div fxLayout="column"
  *ngIf="form"
  [formGroup]="form">

  <mat-form-field>

    <input type="text" placeholder="Reason for Cancelation"
      formControlName="reason"
      matInput>

    <mat-error>Please enter the reason you are canceling this class.</mat-error>

  </mat-form-field>

  <div>

    <p>Are you sure you want to cancel this class?</p>

  </div>

  <mat-checkbox 
    formControlName="confirm" 
    matInput>
    Yes, I want to cancel this class.
  </mat-checkbox>

</div>
