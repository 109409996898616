<div class="ng-user-contact-info-form" fxLayout="column"
  *ngIf="form"
  [formGroup]="form">

  <mat-form-field>

    <mat-label>Your Name</mat-label>

    <input type="text"
      formControlName="name"
      matInput>

    <mat-error>Please enter your name.</mat-error>

  </mat-form-field>

  <div fxLayout="column"
    fxLayout.gt-sm="row">

    <ng-picker-intl-phone-number fxFlex
      [control]="phoneNumberControl"></ng-picker-intl-phone-number>

    <mat-form-field fxFlex>

      <mat-label>Your Email Address</mat-label>

      <input type="email"
        formControlName="email_address"
        matInput>

      <mat-error>Please enter your email address.</mat-error>

    </mat-form-field>

  </div>

  <!-- <ng-person-contact-point-address-form [form]="addressForm"></ng-person-contact-point-address-form> -->

</div>
