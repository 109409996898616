import { COACH, EBOOK, HARDCOVER, OWNER, PAPERBACK, PROVIDER, SMALL_TO_BEST } from "../common";

export const COACH_ASIN = 'coach';

export const OWNER_ASIN = 'owner';

export const PROVIDER_ASIN = 'provider';

export const SMALL_TO_BEST_ASIN = 'small-to-best';

export const ASINMap: { [key: string]: string } = {} as const;

ASINMap[COACH] = COACH_ASIN;
ASINMap[OWNER] = OWNER_ASIN;
ASINMap[PROVIDER] = PROVIDER_ASIN;
ASINMap[SMALL_TO_BEST] = SMALL_TO_BEST_ASIN;

export type ASINKeys = keyof typeof ASINMap;

export type ASIN = typeof ASINMap[ASINKeys];

export const ASINs = Object.values(ASINMap);

export const TrainingBookMap = {
  COACH,
  OWNER,
  PROVIDER,
  SMALL_TO_BEST,
} as const;

export type TrainingBookKeys = keyof typeof TrainingBookMap;

export type TrainingBook = typeof TrainingBookMap[TrainingBookKeys];

export const TrainingBooks = Object.values(TrainingBookMap);

export const TrainingBookTypeMap = {
  EBOOK,
  HARDCOVER,
  PAPERBACK,
} as const;

export type TrainingBookType = typeof TrainingBookTypeMap[keyof typeof TrainingBookTypeMap];

export const TrainingBookTypes = Object.values(TrainingBookTypeMap);
