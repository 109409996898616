import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card/card.component';
import { SaveDialogComponent } from './save-dialog/save-dialog.component';
import { SaveFormComponent } from './save-form/save-form.component';
import { SelectCountryComponent } from './select-country/select-country.component';
import { SelectCurrencyComponent } from './select-currency/select-currency.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { PaymentMethodModule } from '../../stripe/payment-method/payment-method.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    CardComponent,
    SaveDialogComponent,
    SaveFormComponent,
    SelectCountryComponent,
    SelectCurrencyComponent,
  ],
  exports: [
    CardComponent,
    SaveDialogComponent,
    SaveFormComponent,
    SelectCountryComponent,
    SelectCurrencyComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    PaymentMethodModule,
    ReactiveFormsModule,
  ]
})
export class SettingsModule { }
