import { Injectable } from '@angular/core';
import { PersonAttributeServiceAdapter } from '../attribute.service';
import { PersonAttributeListPayload, PersonAttribute, PersonAttributeSavePayload, PersonAttributeGetPayload, PersonAttributeInterface } from '@bcx/models';
import { List } from '@digitaltoolbuilders/model-helpers';
import { ApiService } from '@engagedcx/ng-amplify';

@Injectable({
  providedIn: 'root'
})
export class RestService implements PersonAttributeServiceAdapter {

  constructor(
    private api: ApiService,
  ) { }

  generatePath(clean: Pick<PersonAttribute, 'account_id' | 'person_id'>) {

    return `/accounts/${clean.account_id}/people/${clean.person_id}/attributes`;

  }

  get(clean: PersonAttributeGetPayload): Promise<PersonAttribute> {
    
    const path = `${this.generatePath(clean)}/${clean.attr_key}`;

    return this.api.get<PersonAttributeInterface>(path, clean)
    .then((response) => {

      return new PersonAttribute(response.data);

    });

  }

  list(clean: PersonAttributeListPayload): Promise<List<PersonAttribute>> {
      
    const path = this.generatePath(clean);

    return this.api.get<List<PersonAttributeInterface>>(path, clean)
    .then((response) => {

      return new List<PersonAttribute>({
        ...response.data,
        list: response.data.list.map((item) => new PersonAttribute(item)),
      });

    });

  }

  save(clean: PersonAttributeSavePayload): Promise<PersonAttribute> {
    
    const path = `${this.generatePath(clean)}/${clean.attr_key}`;

    return this.api.post<PersonAttributeInterface>(path, clean)
    .then((response) => {

      return new PersonAttribute(response.data);

    });

  }

}
