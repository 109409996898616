import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TrainingClass } from '@bcx/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ng-training-class-list-table',
  templateUrl: './list-table.component.html',
  styleUrls: ['./list-table.component.sass'],
})
export class ListTableComponent implements OnChanges {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  @Input() classes: Array<TrainingClass> = [];

  @Input() error?: string;

  @Input() display: Array<string> = [
    'class_id',
    'state',
    'start_date',
    'start_time',
    'role',
    'class_number',
    'actions',
  ];

  @Input() loading: boolean;

  table = new MatTableDataSource<TrainingClass>([]);

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['classes']) {

      this.table.data = this.classes;

    }

  }

}
