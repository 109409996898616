import { FormControl, FormGroup, Validators } from "@angular/forms";

export class VerifyForm extends FormGroup {

  constructor() {

    super({
      code: new FormControl<string | undefined>(undefined, [Validators.required]),
    });

  }

}
