import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CoachGoalNames } from "@bcx/models";

export class CoachGoalsForm extends FormGroup {

  constructor() {

    super({
      other: new FormControl<string | undefined>(undefined, []),
    });

    CoachGoalNames.forEach((goal_name) => {

      this.addControl(goal_name, new FormControl<string>('', [Validators.required]));

    });

  }

}
