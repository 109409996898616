<div fxLayout="column"
  *ngIf="(vm$ | async) as vm"
  [formGroup]="form">

  <h2 class="text-md">What are your goals as a provider?</h2>

  <div class="flex flex-col divide-y-2">

    <app-goal-level-of-importance
      [form]="form"
      [goalName]="names.ACHIEVE_PROMOTIONS">
    
      Get promoted.

    </app-goal-level-of-importance>

    <app-goal-level-of-importance
      *ngIf="hasAnswer(vm.goals[names.ACHIEVE_PROMOTIONS])"
      [form]="form"
      [goalName]="names.BEST_EXPERIENCES_EVERY_TIME">
    
      Provide the best customer experiences every time.

    </app-goal-level-of-importance>

    <app-goal-level-of-importance
      *ngIf="hasAnswer(vm.goals[names.BEST_EXPERIENCES_EVERY_TIME])"
      [form]="form"
      [goalName]="names.INCREASE_AVERAGE_PURCHASE">
    
      Increase the average purchase size.

    </app-goal-level-of-importance>

    <app-goal-level-of-importance
      *ngIf="hasAnswer(vm.goals[names.INCREASE_AVERAGE_PURCHASE])"
      [form]="form"
      [goalName]="names.INCREASE_BONUS_COMMISSION">
    
      Increase your commissions or bonuses.

    </app-goal-level-of-importance>

    <app-goal-level-of-importance
      *ngIf="hasAnswer(vm.goals[names.INCREASE_BONUS_COMMISSION])"
      [form]="form"
      [goalName]="names.INCREASE_COMPLIANCE_QUALITY_SCORES">
    
      Increase your compliance and quality scores.

    </app-goal-level-of-importance>

    <app-goal-level-of-importance
      *ngIf="hasAnswer(vm.goals[names.INCREASE_COMPLIANCE_QUALITY_SCORES])"
      [form]="form"
      [goalName]="names.INCREASE_CONVERSION_RATE">
    
      Increase your conversion rate.

    </app-goal-level-of-importance>

    <app-goal-level-of-importance
      *ngIf="hasAnswer(vm.goals[names.INCREASE_CONVERSION_RATE])"
      [form]="form"
      [goalName]="names.INCREASE_CSAT">
    
      Increase your customer satisfaction scores.

    </app-goal-level-of-importance>

    <app-goal-level-of-importance
      *ngIf="hasAnswer(vm.goals[names.INCREASE_CSAT])"
      [form]="form"
      [goalName]="names.INCREASE_JOB_SATISFACTION">
    
      Increase your job satisfaction.

    </app-goal-level-of-importance>

    <app-goal-level-of-importance
      *ngIf="hasAnswer(vm.goals[names.INCREASE_JOB_SATISFACTION])"
      [form]="form"
      [goalName]="names.INCREASE_PAY_RATE">
    
      Get a raise.

    </app-goal-level-of-importance>

    <app-goal-level-of-importance
      *ngIf="hasAnswer(vm.goals[names.INCREASE_PAY_RATE])"
      [form]="form"
      [goalName]="names.MORE_PREMIUMS_UPGRADES">
    
      Sell more premium services or upgrades.

    </app-goal-level-of-importance>

    <app-goal-level-of-importance
      *ngIf="hasAnswer(vm.goals[names.MORE_PREMIUMS_UPGRADES])"
      [form]="form"
      [goalName]="names.MORE_REFERRALS">
    
      Generate more referrals.

    </app-goal-level-of-importance>

    <app-goal-level-of-importance
      *ngIf="hasAnswer(vm.goals[names.MORE_REFERRALS])"
      [form]="form"
      [goalName]="names.RELEASE_NEGATIVITY">
    
      Release negativity ever day.

    </app-goal-level-of-importance>

  </div>

  <section class="flex flex-col"
    *ngIf="hasAnswer(vm.goals[names.RELEASE_NEGATIVITY])">
      
    <mat-form-field>

      <mat-label>Other goals you have:</mat-label>
      <textarea 
        formControlName="other" 
        matInput></textarea>

    </mat-form-field>
    
  </section>

</div>