import { isBoolean } from "lodash";
import { AnyPayload } from "./common";

export abstract class ModelAbstract implements ModelInterface {

  created_epoch: number;

  last_updated_epoch: number;

  model_deleted?: boolean;

  model_id: string;

  constructor(input: Partial<ModelAbstract>) {

    if (input.created_epoch) this.created_epoch = input.created_epoch;
    if (input.last_updated_epoch) this.last_updated_epoch = input.last_updated_epoch;
    if (isBoolean(input.model_deleted)) this.model_deleted = input.model_deleted;
    if (input.model_id) this.model_id = input.model_id;

  }

  // eslint-disable-next-line
  static generateModelId(input: any): string {

    throw new Error('generateModelId is not implemented');

  }

}

export abstract class ModelAction<Payload = AnyPayload> {

  action_id: string;

  abstract readonly action_name: string;

  epoch: number;

  payload: Payload;

  model_id: string;

  user_id: string;

  constructor(input: Partial<ModelAction<Payload>>) {

    if (input.action_id) this.action_id = input.action_id;
    if (input.epoch) this.epoch = input.epoch;
    if (input.payload) this.payload = input.payload;
    if (input.model_id) this.model_id = input.model_id;
    if (input.user_id) this.user_id = input.user_id;

  }

}

export abstract class ModelEvent<Payload = AnyPayload> {

  action_id?: string;

  event_id: string;

  abstract readonly event_name: string;

  epoch: number;

  model_id: string;

  payload: Payload;

  user_id: string;

  constructor(input: Partial<ModelEvent<Payload>>) {

    if (input.action_id) this.action_id = input.action_id;
    if (input.event_id) this.event_id = input.event_id;
    if (input.epoch) this.epoch = input.epoch;
    if (input.model_id) this.model_id = input.model_id;
    if (input.payload) this.payload = input.payload;
    if (input.user_id) this.user_id = input.user_id;

  }

}

export interface ModelInterface {

  created_epoch: number;

  last_updated_epoch: number;

  model_deleted?: boolean;

  model_id: string;

}
