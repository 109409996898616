<div class="flex flex-col">

  <mat-tab-group>

    <mat-tab>

      <ng-template matTabLabel>

        Best CX Ever For Providers

      </ng-template>

      <ng-template matTabContent>

        <mat-card>

          <mat-card-header>

            <mat-card-title>Best CX Ever For Providers</mat-card-title>

            <mat-card-subtitle>How To Provide The Best Customer Experiences Every Time</mat-card-subtitle>

          </mat-card-header>

          <mat-card-content>



          </mat-card-content>

          <mat-card-actions>

            <a 
              mat-raised-button>Buy Book</a>

          </mat-card-actions>

        </mat-card>

      </ng-template>

    </mat-tab>

    <mat-tab>

      <ng-template matTabLabel>

        Best CX Ever For Coaches

      </ng-template>

      <ng-template matTabContent>

        <mat-card>

          <mat-card-header>

            <mat-card-title>Best CX Ever For Coaches</mat-card-title>

            <mat-card-subtitle>How To Provide The Best Customer Experiences Every Time</mat-card-subtitle>

          </mat-card-header>

          <mat-card-content>

            <span>Coming Soon!</span>

          </mat-card-content>

          <mat-card-actions>

            <a  
              mat-raised-button></a>

          </mat-card-actions>

        </mat-card>

      </ng-template>

    </mat-tab>

    <mat-tab>

      <ng-template matTabLabel>

        Best CX Ever For Owner

      </ng-template>

      <ng-template matTabContent>

        <mat-card>

          <mat-card-header>

            <mat-card-title>Best CX Ever For Owners</mat-card-title>

            <mat-card-subtitle>How To Provide The Best Customer Experiences Every Time</mat-card-subtitle>

          </mat-card-header>

          <mat-card-content>

            <span>Coming Soon!</span>

          </mat-card-content>

          <mat-card-actions>

            <a 
              mat-raised-button>Buy Book</a>

          </mat-card-actions>

        </mat-card>

      </ng-template>

    </mat-tab>

    <mat-tab>

      <ng-template matTabLabel>

        Small To Best CX Ever

      </ng-template>

      <ng-template matTabContent>

        <mat-card>

          <mat-card-header>

            <mat-card-title>Small To Best CX Ever</mat-card-title>

            <mat-card-subtitle>How To Implement Big Ideas In A Small Business With A Smaller Budget</mat-card-subtitle>

          </mat-card-header>

          <mat-card-content>

            <span>Coming Soon!</span>

          </mat-card-content>

          <mat-card-actions>

            <a 
              mat-raised-button>Buy Book</a>

          </mat-card-actions>

        </mat-card>

      </ng-template>

    </mat-tab>

  </mat-tab-group>

</div>
