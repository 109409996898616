<section class="question">

  <span>{{label}}</span>

  <mat-button-toggle-group
    [formControl]="control"
    matInput>
    
    <mat-button-toggle 
      *ngFor="let length of lengths"
      [value]="length">{{length}}</mat-button-toggle>

  </mat-button-toggle-group>

</section>