import { isBoolean, merge } from "lodash";
import { ModelAbstract, ModelAction, ModelEvent } from "../model-action";
import { ACTIVE, ADMIN, BANNED, CONSULTANT, CUSTOMER, SUSPENDED, TRAINER, UNREGISTERED, USER } from "../common";
import { ConsistentRead, ExclusiveStartKey } from "../helpers";
import { List } from "@digitaltoolbuilders/model-helpers";

export function mergeUserModels(original: UserInterface, updated: UserInterface) {

  return new User(merge({}, original, updated));

}

export class User extends ModelAbstract implements UserInterface {
  
  email: string;

  email_verified: boolean;

  family_name?: string;

  given_name?: string;

  local_time_zone: string;

  name: string;

  phone_number: string;

  phone_number_verified: boolean;

  picture?: string;

  roles: Array<UserRole> = [];

  schema_version = '2.0';

  status: UserStatus;

  user_id: string;

  constructor(input: Partial<User>) {

    super(input);

    if (input.email) this.email = input.email;
    if (isBoolean(input.email_verified)) this.email_verified = input.email_verified;
    if (input.family_name) this.family_name = input.family_name;
    if (input.given_name) this.given_name = input.given_name;
    if (input.local_time_zone) this.local_time_zone = input.local_time_zone;
    if (input.name) this.name = input.name;
    if (input.phone_number) this.phone_number = input.phone_number;
    if (isBoolean(input.phone_number_verified)) this.phone_number_verified = input.phone_number_verified;
    if (input.picture) this.picture = input.picture;
    if (input.roles) this.roles = input.roles;
    if (input.schema_version) this.schema_version = input.schema_version;
    if (input.status) this.status = input.status;
    if (input.user_id) this.user_id = input.user_id;

  }

  static override generateModelId(input: Pick<User, 'user_id'>) {

    return `${input.user_id}`;

  }

}

export const UserActionNameMap = {
  CHANGE_ROLES: `${USER}/change-roles`,
  CREATE: `${USER}/create`,
  FIND: `${USER}/find`,
  FIND_WITH_EMAIL: `${USER}/find-with-email`,
  FIND_WITH_PHONE_NUMBER: `${USER}/find-with-phone-number`,
  GET: `${USER}/get`,
  LIST: `${USER}/list`,
  REGISTER: `${USER}/register`,
  UPDATE: `${USER}/update`,
} as const;

export type UserActionName = typeof UserActionNameMap[keyof typeof UserActionNameMap];

export const UserActionNames = Object.values(UserActionNameMap);

export class UserChangeRolesAction extends ModelAction<UserChangeRolesPayload> {

  override readonly action_name = UserActionNameMap.CREATE;

  constructor(input: Partial<UserChangeRolesAction>) {

    super(input);

  }

}

export class UserRolesChangedEvent extends ModelEvent<User> {

  override readonly event_name = UserEventNameMap.ROLES_CHANGED;

  constructor(input: Partial<Omit<UserRolesChangedEvent, 'event_name'>>) {

    super(input);

  }

}

export type UserChangeRolesPayload = Pick<User, 'last_updated_epoch' | 'roles' | 'user_id'>;

export class UserCreateAction extends ModelAction<UserCreatePayload> {

  override readonly action_name = UserActionNameMap.CREATE;

  constructor(input: Partial<UserCreateAction>) {

    super(input);

  }

}

export class UserCreatedEvent extends ModelEvent<User> {

  override readonly event_name = UserEventNameMap.CREATED;

  constructor(input: Partial<Omit<UserCreatedEvent,'event_name'>>) {

    super(input);

  }

}

export type UserCreatePayload = Omit<User,
  | 'email_verified' 
  | 'last_updated_epoch' 
  | 'phone_number_verified'
  | 'roles' 
  | 'schema_version'
> & Partial<Pick<User, 'schema_version'>>;

export const UserEventNameMap = {
  CREATED: `${USER}:created`,
  FOUND: `${USER}:found`,
  FOUND_WITH_EMAIL: `${USER}:found-with-email`,
  FOUND_WITH_PHONE_NUMBER: `${USER}:found-with-phone-number`,
  LISTED: `${USER}:listed`,
  REGISTERED: `${USER}:registered`,
  ROLES_CHANGED: `${USER}:roles-changed`,
  UPDATED: `${USER}:update`,
  VIEWED: `${USER}:viewed`,
} as const;

export type UserEventName = typeof UserEventNameMap[keyof typeof UserEventNameMap];

export const UserEventNames = Object.values(UserEventNameMap);

export class UserGetAction extends ModelAction<UserGetPayload> {

  override readonly action_name = UserActionNameMap.GET;

  constructor(input: Partial<UserGetAction>) {

    super(input);

  }

}

export type UserGetPayload = Pick<User, 'user_id'> & {
  consistent_read?: ConsistentRead;
};

export class UserFindWithEmailAction extends ModelAction<UserFindWithEmailPayload> {

  override readonly action_name = UserActionNameMap.FIND_WITH_EMAIL;

  constructor(input: Partial<UserFindWithEmailAction>) {

    super(input);

  }

}

export class UserFoundWithEmailEvent extends ModelEvent<User> {

  override readonly event_name = UserEventNameMap.FOUND_WITH_EMAIL;

  constructor(input: Partial<UserFoundWithEmailEvent>) {

    super(input);

  }

}

export type UserFindWithEmailPayload = Pick<User, 'email'>;

export class UserFindWithPhoneNumberAction extends ModelAction<UserFindWithPhoneNumberPayload> {

  override readonly action_name = UserActionNameMap.FIND_WITH_PHONE_NUMBER;

  constructor(input: Partial<UserFindWithPhoneNumberAction>) {

    super(input);

  }

}

export class UserFoundWithPhoneNumberEvent extends ModelEvent<User> {

  override readonly event_name = UserEventNameMap.FOUND_WITH_PHONE_NUMBER;

  constructor(input: Partial<UserFoundWithPhoneNumberEvent>) {

    super(input);

  }

}

export type UserFindWithPhoneNumberPayload = Pick<User, 'phone_number'>;

export class UserFindAction extends ModelAction<UserFindPayload> {

  override readonly action_name = UserActionNameMap.FIND;

  constructor(input: Partial<UserFindAction>) {

    super(input);

  }

}

export type UserFindPayload = UserFindWithEmailPayload | UserFindWithPhoneNumberPayload;

export class UserFoundEvent extends ModelEvent<User> {

  override readonly event_name = UserEventNameMap.FOUND;

  constructor(input: Partial<UserFoundEvent>) {

    super(input);

  }

}

export class UserListAction extends ModelAction<UserListPayload> {

  override readonly action_name = UserActionNameMap.LIST;

  constructor(input: Partial<UserListAction>) {

    super(input);

  }

}

export class UserListedEvent extends ModelEvent<List<User>> {

  override readonly event_name = UserEventNameMap.LISTED;

  constructor(input: Partial<Omit<UserListedEvent, 'event_name'>>) {

    super(input);

  }

}

export type UserListPayload = Partial<Pick<User, 'status'>> & {
  exclusive_start_key: ExclusiveStartKey,
  limit?: number;
};

export class UserRegisterAction extends ModelAction<UserRegisterPayload> {

  override readonly action_name = UserActionNameMap.REGISTER;

  constructor(input: Partial<UserRegisterAction>) {

    super(input);

  }

}

export class UserRegisteredEvent extends ModelEvent<User> {

  override readonly event_name = UserEventNameMap.REGISTERED;

  constructor(input: Partial<UserRegisteredEvent>) {

    super(input);

  }

}

export type UserRegisterPayload = Pick<User, 'name' | 'phone_number'> & Partial<Pick<User, 
  | 'email' 
  | 'family_name' 
  | 'given_name' 
  | 'local_time_zone'
  | 'picture'
>>;

export class UserUpdateAction extends ModelAction<UserUpdatePayload> {

  override readonly action_name = UserActionNameMap.UPDATE;

  constructor(input: Partial<UserUpdateAction>) {

    super(input);

  }

}

export class UserUpdatedEvent extends ModelEvent<User> {

  override readonly event_name = UserEventNameMap.UPDATED;

  constructor(input: Partial<Omit<UserUpdatedEvent, 'event_name'>>) {

    super(input);

  }

}

export type UserUpdatePayload = Pick<User, 'family_name' | 'given_name' | 'last_updated_epoch' | 'local_time_zone' | 'name' | 'picture' | 'user_id'>;

export interface UserInterface extends ModelAbstract {

  email: string;

  email_verified: boolean;

  family_name?: string;

  given_name?: string;

  local_time_zone: string;

  name: string;

  phone_number: string;

  phone_number_verified: boolean;

  picture?: string;

  roles: Array<UserRole>;

  schema_version: string;

  status: UserStatus;

  user_id: string;

}

export const UserRoleMap = {
  ADMIN,
  CONSULTANT,
  CUSTOMER,
  TRAINER,
  USER,
} as const;

export type UserRole = typeof UserRoleMap[keyof typeof UserRoleMap];

export const UserRoles = Object.values(UserRoleMap);

export const UserStatusMap = {
  ACTIVE,
  BANNED,
  SUSPENDED,
  UNREGISTERED,
} as const;

export type UserStatus = typeof UserStatusMap[keyof typeof UserStatusMap];

export const UserStatuses = Object.values(UserStatusMap);

export class UserViewedEvent extends ModelEvent<User> {

  override readonly event_name = UserEventNameMap.VIEWED;

  constructor(input: Partial<Omit<UserViewedEvent, 'event_name'>>) {

    super(input);

  }

}
