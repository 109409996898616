<div fxLayout="column"
  *ngIf="form"
  [formGroup]="form">

  <ng-picker-select-country 
    [control]="countryControl"></ng-picker-select-country>

  <mat-form-field>

    <mat-label>Your Street Address</mat-label>

    <input type="text" name="street"
      formControlName="street"
      matInput>

    <mat-error>Please enter your street address.</mat-error>

  </mat-form-field>

  <mat-form-field>

    <mat-label>Unit</mat-label>

    <input type="text" name="unit" placeholder="Unit"
      formControlName="unit"
      matInput>

    <mat-error>Please enter your unit.</mat-error>

  </mat-form-field>

  <div fxLayout="column"
    fxLayout.gt-sm="row">

    <mat-form-field>

      <mat-label>City</mat-label>

      <input type="text" name="city" placeholder="City"
        formControlName="city"
        matInput>

      <mat-error>Please enter the city.</mat-error>

    </mat-form-field>

    <mat-form-field>

      <mat-label>State</mat-label>

      <input type="text" name="state" placeholder="State"
        formControlName="state"
        matInput>

      <mat-error>Please enter the state.</mat-error>

    </mat-form-field>

    <mat-form-field>

      <mat-label>Postal Code</mat-label>

      <input type="text" name="postal_code"
        formControlName="postal_code"
        matInput>

      <mat-error>Please enter the postal code.</mat-error>

    </mat-form-field>

  </div>

</div>