<form action="form"
  (ngSubmit)="submit()">

  <div fxLayout="row" fxLayoutAlign="space-between"
    mat-dialog-title>

    <h1 class="mat-headline-5">Reschedule Session</h1>

    <button type="button" color="warn"
      (click)="closeDialog()"
      mat-icon-button><mat-icon>close</mat-icon></button>

  </div>

  <mat-dialog-content fxLayout="column" fxLayoutGap="20px">

    <ng-consulting-session-summary-card [session]="oldSession"></ng-consulting-session-summary-card>

    <ng-consulting-session-reschedule-form [form]="form"></ng-consulting-session-reschedule-form>

  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">

    <span></span>

    <mat-spinner *ngIf="processing"></mat-spinner>

    <button type="submit" color="primary"
      [disabled]="form.invalid || processing"
      mat-raised-button>Reschedule Session</button>

  </mat-dialog-actions>

</form>