import Joi from "joi";
import { MetaData, MetaDataValue } from "@bcx/models";

export const meta_data_value = Joi.alternatives(
  Joi.boolean(),
  Joi.number(),
  Joi.string(),
  Joi.array<Array<MetaDataValue>>(),
);

export const meta_data = Joi.object<MetaData>()
  .pattern('*', meta_data_value);
