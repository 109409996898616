import { Component } from '@angular/core';

@Component({
  selector: 'ng-consulting-list-grid',
  templateUrl: './list-grid.component.html',
  styleUrls: ['./list-grid.component.sass'],
})
export class ListGridComponent {
  

  
}
