import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService, ExtendedIdentityTokenPayload } from '../auth.service';
import { combineLatest, map } from 'rxjs';
import { Router } from '@angular/router';
import { SignInSignOutService } from '../sign-in-sign-out.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SubsComponent } from '@bcx/ng-helpers';

@Component({
  selector: 'ng-amplify-authed-user',
  templateUrl: './authed-user.component.html',
  styleUrls: ['./authed-user.component.sass'],
})
export class AuthedUserComponent extends SubsComponent implements OnInit, OnDestroy {

  authenticating: boolean;

  isLargeScreen = false;

  @Input() showEmail = false;

  @Input() showName = true;

  user?: ExtendedIdentityTokenPayload;

  constructor(
    private breakpoint: BreakpointObserver,
    private router: Router,
    private service: AuthService,
    private signInSignOut: SignInSignOutService,
  ) {

    super();

  }

  ngOnInit(): void {
    
    this.subs['auth'] = combineLatest({
      authenticating: this.service.authenticating$,
      user: this.service.identity$,
    }).subscribe(({ authenticating, user }) => {

      this.authenticating = authenticating;

      this.user = user;

    });

    this.subs['media'] = this.breakpoint.observe([
      Breakpoints.Medium,
    ])
    .pipe(map((results) => {

      return results.breakpoints[Breakpoints.Medium];

    }))
    .subscribe((isLargeScreen: boolean) => {

      this.isLargeScreen = isLargeScreen;

    });

  }

  signIn() {

    this.signInSignOut.signIn()
    .then(() => {

      location.reload();

    })
    .catch((e: Error) => {

      console.error(e);

    });

  }

  signOut() {

    this.signInSignOut.signOut()
    .then(() => {

      this.router.navigateByUrl('/');

    })
    .catch((e: Error) => {

      console.error(e);

    });

  }

  signUp() {

    this.router.navigateByUrl('/sign-up');

  }

}
