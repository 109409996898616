
import { createFeatureSelector, createSelector, } from '@ngrx/store';
import { Stripe } from 'stripe';
import { createEntityId, featureKey, getEntity, State } from './invoice.reducer';
import { AllEntitiesState, Entity, ManyEntitiesState } from '@bcx/ng-helpers';

export const selectState = createFeatureSelector<State>(
  featureKey
);

export const selectAll = createSelector(
  selectState,
  (state: State) => {

    const entities = Object.values(state.entities).filter((entity) => (entity.model) ? true : false);

    const all: Omit<AllEntitiesState<Stripe.Invoice, string>, 'last_evaluated_key'> & {
      last_evaluated_key?: string;
    } = {
      ...state.all,
      entities,
      models: entities.map((entity) => entity.model as Stripe.Invoice),
    };

    return all;

  }
);

export const selectMyInvoices = createSelector(
  selectState,
  (state: State) => {

    const entities: Array<Entity<Stripe.Invoice>> = [];

    state.my.ids.forEach((id: string) => {

      const entity = getEntity(id, state);

      if (entity.model) {

        entities.push(entity);

      }

    });

    const my: Omit<ManyEntitiesState<Stripe.Invoice, string>, 'last_evaluated_key'> & {
      last_evaluated_key?: string;
    } = {
      ...state.my,
      entities,
      models: entities.map((entity: Entity<Stripe.Invoice>) => {

        return entity.model as Stripe.Invoice;

      }),
    };

    return my;

  }
);

export function selectForCustomer(customer: string) {

  return createSelector(
    selectState,
    (state: State) => {

      const entities: Array<Entity<Stripe.Invoice>> = Object.values(state.entities)
      .filter((entity: Entity<Stripe.Invoice>) => {

        return entity.model && entity.model.customer === customer;

      });

      const many: Omit<ManyEntitiesState<Stripe.Invoice, string>, 'last_evaluated_key'> & {
        last_evaluated_key?: string;
      } = {
        ...state.all,
        ids: entities.map((entity: Entity<Stripe.Invoice>) => {

          return entity.model?.id as string;

        }),
        entities,
        models: entities
        .map((entity: Entity<Stripe.Invoice>) => {
  
          return entity.model as Stripe.Invoice;
  
        }),
      };

      return many;

    },
  );

}

export function selectOne(id: string) {

  return createSelector(
    selectState,
    (state: State) => {

      const eId = createEntityId(id);

      return getEntity(eId, state);

    },
  );

}
