import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AddressForm } from './form';

@Component({
  selector: 'ng-billing-address-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.sass'],
})
export class FormComponent implements OnChanges {

  countryControl: FormControl;

  @Input() form: AddressForm;

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['form'] && this.form) {

      this.countryControl = this.form.get('country') as FormControl;

    }

  }
  
}
