import { inject, Inject, Injectable } from '@angular/core';
import { PaymentAgreementServiceAdapter } from './payment-agreement.adapter';
import { BACKEND_ADAPTER, BackendAdapter, BackendAdapterMap, IdService } from '@bcx/ng-helpers';
import { StateFacade, StateService } from '../../state/state.service';
import { LastEvaluatedKey, TRAINING_PAYMENT_AGREEMENT, TrainingPaymentAgreement, TrainingPaymentAgreementCreateAction, TrainingPaymentAgreementCreatePayload, TrainingPaymentAgreementGenerateSignedUrlAction, TrainingPaymentAgreementGenerateSignedUrlPayload, TrainingPaymentAgreementGetAction, TrainingPaymentAgreementGetPayload, TrainingPaymentAgreementListAction, TrainingPaymentAgreementListPayload, TrainingPaymentAgreementUpdateAction, TrainingPaymentAgreementUpdatePayload } from '@bcx/models';
import { RestService } from './rest.service';
import { WebsocketService } from './websocket.service';
import { trainingPaymentAgreement as schemas } from '@bcx/schemas';
import { List } from '@digitaltoolbuilders/model-helpers';

export function createStateId(input: Pick<TrainingPaymentAgreement, 'agreement_id'>) {

  return TrainingPaymentAgreement.generateModelId(input);

}

export function iteratorFn(list: List<TrainingPaymentAgreement>, fn: (model: TrainingPaymentAgreement) => void) {

  list.list.forEach(fn);

}

@Injectable({
  providedIn: 'root'
})
export class PaymentAgreementService {

  private adapter: PaymentAgreementServiceAdapter;

  private state: StateFacade<TrainingPaymentAgreement, LastEvaluatedKey>;

  constructor(
    @Inject(BACKEND_ADAPTER) backendAdapter: BackendAdapter,
    private id: IdService,
    state: StateService,
  ) { 

    switch (backendAdapter) {

      case BackendAdapterMap.REST:

        this.adapter = inject(RestService);

        break;

      case BackendAdapterMap.WEBSOCKET:

        this.adapter = inject(WebsocketService);

        break;

      default:

        throw new Error(`unknown adapter: ${backendAdapter}`);

    }

    this.state = state.facade<TrainingPaymentAgreement, LastEvaluatedKey>(TRAINING_PAYMENT_AGREEMENT, { createStateId });

  }

  create(data: TrainingPaymentAgreementCreatePayload, now = Date.now()) {

    return schemas.create.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingPaymentAgreementCreateAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.create(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  get(data: TrainingPaymentAgreementGetPayload, now = Date.now()) {

    return schemas.get.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingPaymentAgreementGetAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        loading: true,
      });

      return this.adapter.get(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          loading: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          loading: false,
        });

        throw e;

      });

    });

  }

  generateSignedUrl(data: TrainingPaymentAgreementGenerateSignedUrlPayload, now = Date.now()) {

    return schemas.generateSignedUrl.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingPaymentAgreementGenerateSignedUrlAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        loading: true,
      });

      return this.adapter.generateSignedUrl(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          loading: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          loading: false,
        });

        throw e;

      });

    });

  }

  list(data: TrainingPaymentAgreementListPayload, now = Date.now()) {

    return schemas.list.validateAsync(data, {})
    .then((clean) => {

      const group = 'all';

      const action = new TrainingPaymentAgreementListAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: group,
        payload: clean,
        user_id: '',
      });

      this.state.many({
        group,
        loading: true,
      });

      return this.adapter.list(action)
      .then((event) => {

        const list = event.payload;

        this.state.many({
          group,
          list: list.list,
          loading: false,
        });

        return list;

      })
      .catch((e) => {

        this.state.many({
          e,
          group,
          loading: false,
        });

        throw e;

      });

    });

  }

  update(data: TrainingPaymentAgreementUpdatePayload, now = Date.now()) {

    return schemas.update.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new TrainingPaymentAgreementUpdateAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.update(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

}
