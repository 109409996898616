import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IntlPhoneNumberControl } from "@bcx/ng-helpers";

export class ContactInfoForm extends FormGroup {

  constructor() {

    super({
      address: new FormGroup({}),
      email_address: new FormControl<string | undefined>(undefined, [Validators.email, Validators.required]),
      name: new FormControl<string | undefined>(undefined, [Validators.required]),
      phone_number: new IntlPhoneNumberControl({ validators: [Validators.required]}),
    });

  }

}
