import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { BOOK, CLASS, OWNER, PROVIDER, TrainingTicket, VIDEO } from '@bcx/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ng-training-ticket-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.sass'],
})
export class SummaryCardComponent {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  BOOK = BOOK;

  CLASS = CLASS;

  OWNER = OWNER;

  PROVIDER = PROVIDER;

  @Input() ticket: TrainingTicket;

  VIDEO = VIDEO;

}
