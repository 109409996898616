import {Stripe} from "stripe";
import { ModelAction, ModelEvent } from "../model-action";
import { STRIPE_INVOICE } from "../common";

export const StripeInvoiceActionNameMap = {
  CREATE: `${STRIPE_INVOICE}/create`,
  GET: `${STRIPE_INVOICE}/get`,
  LIST: `${STRIPE_INVOICE}/list`,
  UPDATE: `${STRIPE_INVOICE}/update`,
} as const;

export type StripeInvoiceActionName = typeof StripeInvoiceActionNameMap[keyof typeof StripeInvoiceActionNameMap];

export const StripeInvoiceActionNames = Object.values(StripeInvoiceActionNameMap);

export class StripeInvoiceCreateAction extends ModelAction<StripeInvoiceCreatePayload> {

  readonly action_name = StripeInvoiceActionNameMap.CREATE;

  constructor(input: Partial<Omit<StripeInvoiceCreateAction, 'action_name'>>){

    super(input);

  }

}

export class StripeInvoiceCreatedEvent extends ModelEvent<Stripe.Invoice> {

  readonly event_name = StripeInvoiceEventNameMap.CREATED;

  constructor(input: Partial<Omit<StripeInvoiceCreatedEvent, 'event_name'>>) {

    super(input);

  }

}

export type StripeInvoiceCreatePayload = Stripe.InvoiceCreateParams;

export const StripeInvoiceEventNameMap = {
  CREATED: `${STRIPE_INVOICE}:created`,
  LISTED: `${STRIPE_INVOICE}:listed`,
  UPDATED: `${STRIPE_INVOICE}:updated`,
  VIEWED: `${STRIPE_INVOICE}:viewed`,
} as const;

export type StripeInvoiceEventName = typeof StripeInvoiceEventNameMap[keyof typeof StripeInvoiceEventNameMap];

export const StripeInvoiceEventNames = Object.values(StripeInvoiceEventNameMap);

export class StripeInvoiceGetAction extends ModelAction<StripeInvoiceGetPayload> {

  readonly action_name = StripeInvoiceActionNameMap.GET;

  constructor(input: Partial<Omit<StripeInvoiceGetAction, 'action_name'>>){

    super(input);

  }

}

export type StripeInvoiceGetPayload = Stripe.InvoiceRetrieveParams & {
  id: string;
};

export class StripeInvoiceListAction extends ModelAction<StripeInvoiceListPayload> {

  readonly action_name = StripeInvoiceActionNameMap.LIST;

  constructor(input: Partial<Omit<StripeInvoiceListAction, 'action_name'>>){

    super(input);

  }

}

export type StripeInvoiceListPayload = Stripe.InvoiceListParams;

export class StripeInvoiceListedEvent extends ModelEvent<Stripe.ApiList<Stripe.Invoice>> {

  readonly event_name = StripeInvoiceEventNameMap.LISTED;

  constructor(input: Partial<Omit<StripeInvoiceListedEvent, 'event_name'>>) {

    super(input);

  }

}

export class StripeInvoiceViewedEvent extends ModelEvent<Stripe.Invoice> {

  readonly event_name = StripeInvoiceEventNameMap.VIEWED;

  constructor(input: Partial<Omit<StripeInvoiceViewedEvent, 'event_name'>>) {

    super(input);

  }

}
