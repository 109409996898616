
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class CalendarEvent<Data = any> {

  begins: Date;

  data: Data;

  description: string;

  disabled: boolean;

  ends: Date;

  title: string;

  constructor(input: CalendarEvent<Data>) {

    this.begins = input.begins;
    this.data = input.data;
    this.description = input.description;
    this.disabled = input.disabled;
    this.ends = input.ends;
    this.title = input.title;

  }

}
