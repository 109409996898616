import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  isLandscape$: Observable<boolean>;

  isLargeScreen$: Observable<boolean>;

  isPortrait$: Observable<boolean>;

  isSmallScreen$: Observable<boolean>;

  constructor(private breakpoint: BreakpointObserver) { 

    this.isLandscape$ = this.breakpoint.observe([Breakpoints.HandsetLandscape]).pipe(map((results) => results.matches));

    this.isLargeScreen$ = this.breakpoint.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).pipe(map((results) => results.matches));

    this.isPortrait$ = this.breakpoint.observe([Breakpoints.HandsetPortrait]).pipe(map((results) => results.matches));

    this.isSmallScreen$ = this.breakpoint.observe([Breakpoints.Small, Breakpoints.XSmall]).pipe(map((results) => results.matches));

  }

}
