import {Stripe} from "stripe";
import { ModelAction, ModelEvent } from "../model-action";
import { STRIPE_CUSTOMER } from "../common";

export const StripeCustomerActionNameMap = {
  CREATE: `${STRIPE_CUSTOMER}/create`,
  GET: `${STRIPE_CUSTOMER}/get`,
  LIST: `${STRIPE_CUSTOMER}/list`,
  UPDATE: `${STRIPE_CUSTOMER}/update`,
} as const;

export type StripeCustomerActionName = typeof StripeCustomerActionNameMap[keyof typeof StripeCustomerActionNameMap];

export const StripeCustomerActionNames = Object.values(StripeCustomerActionNameMap);

export class StripeCustomerCreateAction extends ModelAction<StripeCustomerCreatePayload> {

  readonly action_name = StripeCustomerActionNameMap.CREATE;

  constructor(input: Partial<Omit<StripeCustomerCreateAction, 'action_name'>>){

    super(input);

  }

}

export class StripeCustomerCreatedEvent extends ModelEvent<Stripe.Customer> {

  readonly event_name = StripeCustomerEventNameMap.CREATED;

  constructor(input: Partial<Omit<StripeCustomerCreatedEvent, 'event_name'>>) {

    super(input);

  }

}

export type StripeCustomerCreatePayload = Stripe.CustomerCreateParams;

export const StripeCustomerEventNameMap = {
  CREATED: `${STRIPE_CUSTOMER}:created`,
  LISTED: `${STRIPE_CUSTOMER}:listed`,
  UPDATED: `${STRIPE_CUSTOMER}:updated`,
  VIEWED: `${STRIPE_CUSTOMER}:viewed`,
} as const;

export type StripeCustomerEventName = typeof StripeCustomerEventNameMap[keyof typeof StripeCustomerEventNameMap];

export const StripeCustomerEventNames = Object.values(StripeCustomerEventNameMap);

export class StripeCustomerGetAction extends ModelAction<StripeCustomerGetPayload> {

  readonly action_name = StripeCustomerActionNameMap.GET;

  constructor(input: Partial<Omit<StripeCustomerGetAction, 'action_name'>>){

    super(input);

  }

}

export type StripeCustomerGetPayload = Stripe.CustomerRetrieveParams & {
  id: string;
};

export class StripeCustomerListAction extends ModelAction<StripeCustomerListPayload> {

  readonly action_name = StripeCustomerActionNameMap.LIST;

  constructor(input: Partial<Omit<StripeCustomerListAction, 'action_name'>>){

    super(input);

  }

}

export type StripeCustomerListPayload = Stripe.CustomerListParams;

export class StripeCustomerListedEvent extends ModelEvent<Stripe.ApiList<Stripe.Customer>> {

  readonly event_name = StripeCustomerEventNameMap.LISTED;

  constructor(input: Partial<Omit<StripeCustomerListedEvent, 'event_name'>>) {

    super(input);

  }

}

export class StripeCustomerUpdateAction extends ModelAction<StripeCustomerUpdatePayload> {

  readonly action_name = StripeCustomerActionNameMap.UPDATE;

  constructor(input: Partial<Omit<StripeCustomerUpdateAction, 'action_name'>>){

    super(input);

  }

}

export type StripeCustomerUpdatePayload = Stripe.CustomerUpdateParams & {
  id: string;
};

export class StripeCustomerUpdatedEvent extends ModelEvent<Stripe.ApiList<Stripe.Customer>> {

  readonly event_name = StripeCustomerEventNameMap.UPDATED;

  constructor(input: Partial<Omit<StripeCustomerUpdatedEvent, 'event_name'>>) {

    super(input);

  }

}

export class StripeCustomerViewedEvent extends ModelEvent<Stripe.Customer | Stripe.DeletedCustomer> {

  readonly event_name = StripeCustomerEventNameMap.VIEWED;

  constructor(input: Partial<Omit<StripeCustomerViewedEvent, 'event_name'>>) {

    super(input);

  }

}
