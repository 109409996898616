import { List } from "@digitaltoolbuilders/model-helpers";
import { Address } from "../address";
import { ACTIVATING, ACTIVATION_FAILED, ACTIVE, CANCELED, COLLECTABLE, DRAFT, PAID, PAST_DUE, TRAINING_PAYMENT_AGREEMENT, UNCOLLECTABLE } from "../common";
import { ElectronicSignature } from "../electronic-signature";
import { ConsistentRead, ExclusiveStartKey, Limit } from "../helpers";
import { ModelAbstract, ModelAction, ModelEvent, ModelInterface } from "../model-action";
import { TrainingClassType } from "./class";
import { TrainingCourse } from "./courses";
import { TrainingTicket } from "./ticket";

export class TrainingPaymentAgreement extends ModelAbstract implements TrainingPaymentAgreementInterface {

  activation?: TrainingPaymentAgreementActivation;

  agreement_id: string;

  cancellation?: TrainingPaymentAgreementCancellation;

  debtor: TrainingPaymentAgreementDebtor;

  description: string;

  details: TrainingPaymentAgreementDetails;

  failure?: TrainingPaymentAgreementFailure;

  first_payment_date: string;

  payment_day: string;

  signature?: ElectronicSignature;

  start_date_epoch: number;

  status: TrainingPaymentAgreementStatus;

  stripe: TrainingPaymentAgreementStripe;

  constructor(input: Partial<TrainingPaymentAgreement>) {

    super(input);

    if (input.activation) this.activation = input.activation;
    if (input.agreement_id) this.agreement_id = input.agreement_id;
    if (input.cancellation) this.cancellation = input.cancellation;
    if (input.debtor) this.debtor = input.debtor;
    if (input.description) this.description = input.description;
    if (input.details) this.details = input.details;
    if (input.failure) this.failure = input.failure;
    if (input.first_payment_date) this.first_payment_date = input.first_payment_date;
    if (input.payment_day) this.payment_day = input.payment_day;
    if (input.signature) this.signature = input.signature;
    if (input.start_date_epoch) this.start_date_epoch = input.start_date_epoch;
    if (input.status) this.status = input.status;
    if (input.stripe) this.stripe = input.stripe;

  }

  static override generateModelId(input: Pick<TrainingPaymentAgreement, 'agreement_id'>): string {
    
    return `${input.agreement_id}`;

  }

  static generatePDFS3Key(input: { agreement_id: string }) {
  
    return `${TRAINING_PAYMENT_AGREEMENT}/${input.agreement_id}/agreement.pdf`;
  
  }

}

export const TrainingPaymentAgreementActionNameMap = {
  ACTIVATE: `${TRAINING_PAYMENT_AGREEMENT}/activate`,
  AGREE: `${TRAINING_PAYMENT_AGREEMENT}/agree`,
  CANCEL: `${TRAINING_PAYMENT_AGREEMENT}/cancel`,
  COMPLETE_ACTIVATION: `${TRAINING_PAYMENT_AGREEMENT}/complete-activation`,
  CREATE: `${TRAINING_PAYMENT_AGREEMENT}/create`,
  CREATE_TICKETS: `${TRAINING_PAYMENT_AGREEMENT}/create-tickets`,
  FAIL_ACTIVATION: `${TRAINING_PAYMENT_AGREEMENT}/fail-activation`,
  GENERATE_PDF: `${TRAINING_PAYMENT_AGREEMENT}/generate-pdf`,
  GENERATE_SIGNED_URL: `${TRAINING_PAYMENT_AGREEMENT}/generate-signed-url`,
  GET: `${TRAINING_PAYMENT_AGREEMENT}/get`,
  LIST: `${TRAINING_PAYMENT_AGREEMENT}/list`,
  SET_SUBSCRIPTION: `${TRAINING_PAYMENT_AGREEMENT}/set-subscription`,
  UPDATE: `${TRAINING_PAYMENT_AGREEMENT}/update`,
} as const;

export type TrainingPaymentAgreementActionName = typeof TrainingPaymentAgreementActionNameMap[keyof typeof TrainingPaymentAgreementActionNameMap];

export const TrainingPaymentAgreementActions = Object.values(TrainingPaymentAgreementActionNameMap);

export class TrainingPaymentAgreementActivateAction extends ModelAction<TrainingPaymentAgreementActivatePayload> {

  readonly action_name = TrainingPaymentAgreementActionNameMap.ACTIVATE;

  constructor(input: Partial<Omit<TrainingPaymentAgreementActivateAction, 'action_name'>>) {

    super(input);

  }

}

export type TrainingPaymentAgreementActivatePayload = Pick<TrainingPaymentAgreement, 'agreement_id' | 'last_updated_epoch'>;

export class TrainingPaymentAgreementActivatedEvent extends ModelEvent<TrainingPaymentAgreement> {

  readonly event_name = TrainingPaymentAgreementEventNameMap.ACTIVATED;

  constructor(input: Partial<Omit<TrainingPaymentAgreementActivatedEvent, 'event_name'>>) {

    super(input);

  }

}

export interface TrainingPaymentAgreementActivation {

  action_id: string;

  epoch: number;

  user_id: string;

}

export class TrainingPaymentAgreementActivationFailedEvent extends ModelEvent<TrainingPaymentAgreement> {

  readonly event_name = TrainingPaymentAgreementEventNameMap.ACTIVATION_FAILED;

  constructor(input: Partial<Omit<TrainingPaymentAgreementActivationFailedEvent, 'event_name'>>) {

    super(input);

  }

}

export const TrainingPaymentAgreementActivationErrorCodeMap = {
  WRONG_CURRENCY: 'wrong-currency',
} as const;

export type TrainingPaymentAgreementActivationErrorCode = typeof TrainingPaymentAgreementActivationErrorCodeMap[keyof typeof TrainingPaymentAgreementActivationErrorCodeMap];

export const TrainingPaymentAgreementActivationErrorCodes = Object.values(TrainingPaymentAgreementActivationErrorCodeMap);

export class TrainingPaymentAgreementAgreeAction extends ModelAction<TrainingPaymentAgreementAgreePayload> {

  readonly action_name = TrainingPaymentAgreementActionNameMap.AGREE;

  constructor(input: Partial<Omit<TrainingPaymentAgreementAgreeAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingPaymentAgreementAgreedEvent extends ModelEvent<TrainingPaymentAgreement> {

  readonly event_name = TrainingPaymentAgreementEventNameMap.AGREED;

  constructor(input: Partial<Omit<TrainingPaymentAgreementAgreedEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingPaymentAgreementAgreePayload = Pick<TrainingPaymentAgreement, 'agreement_id'>;

export class TrainingPaymentAgreementCancelAction extends ModelAction<TrainingPaymentAgreementCancelPayload> {

  readonly action_name = TrainingPaymentAgreementActionNameMap.CANCEL;

  constructor(input: Partial<Omit<TrainingPaymentAgreementCancelAction, 'action_name'>>) {

    super(input);

  }

}

export type TrainingPaymentAgreementCancelPayload = Pick<TrainingPaymentAgreement, 'agreement_id' | 'last_updated_epoch'>;

export class TrainingPaymentAgreementCanceledEvent extends ModelEvent<TrainingPaymentAgreement> {

  readonly event_name = TrainingPaymentAgreementEventNameMap.CANCELED;

  constructor(input: Partial<Omit<TrainingPaymentAgreementCanceledEvent, 'event_name'>>) {

    super(input);

  }

}

export interface TrainingPaymentAgreementCancellation {

  action_id: string;

  epoch: number;

  reason: string;

  user_id: string;

}

export class TrainingPaymentAgreementCompleteActivationAction extends ModelAction<TrainingPaymentAgreementCompleteActivationPayload> {

  readonly action_name = TrainingPaymentAgreementActionNameMap.COMPLETE_ACTIVATION;

  constructor(input: Partial<Omit<TrainingPaymentAgreementCompleteActivationAction, 'action_name'>>) {

    super(input);

  }

}

export type TrainingPaymentAgreementCompleteActivationPayload = Pick<TrainingPaymentAgreement, 'agreement_id' | 'last_updated_epoch'> & {
  activated_epoch: number;
  stripe_invoice_id: string;
  stripe_subscription_id?: string;
};

export interface TrainingPaymentAgreementCoupon {

  code?: string;

  savings_amount: number;

}

export class TrainingPaymentAgreementCreateAction extends ModelAction<TrainingPaymentAgreementCreatePayload> {

  readonly action_name = TrainingPaymentAgreementActionNameMap.CREATE;

  constructor(input: Partial<Omit<TrainingPaymentAgreementCreateAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingPaymentAgreementCreatedEvent extends ModelEvent<TrainingPaymentAgreement> {

  readonly event_name = TrainingPaymentAgreementEventNameMap.CREATED;

  constructor(input: Partial<Omit<TrainingPaymentAgreementCreatedEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingPaymentAgreementCreatePayload = Omit<TrainingPaymentAgreement, 'last_updated_epoch'>;

export class TrainingPaymentAgreementCreateTicketsAction extends ModelAction<TrainingPaymentAgreementCreateTicketsPayload> {

  readonly action_name = TrainingPaymentAgreementActionNameMap.CREATE_TICKETS;

  constructor(input: Partial<Omit<TrainingPaymentAgreementCreateTicketsAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingPaymentAgreementTicketsCreatedEvent extends ModelEvent<List<TrainingTicket>> {

  readonly event_name = TrainingPaymentAgreementEventNameMap.TICKETS_CREATED;

  constructor(input: Partial<Omit<TrainingPaymentAgreementTicketsCreatedEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingPaymentAgreementCreateTicketsPayload = Pick<TrainingPaymentAgreement, 'agreement_id' | 'last_updated_epoch'>;

export interface TrainingPaymentAgreementDebtor {

  address: Address;

  date_of_birth?: string;

  email?: string;

  legal_name?: string;

  phone_number?: string;

  user_id: string;

}

export interface TrainingPaymentAgreementDetails {

  amount_of_payment: number;

  balance: number;

  class_type: TrainingClassType;

  coupon: TrainingPaymentAgreementCoupon;

  course: TrainingCourse;

  currency: string;

  discount?: TrainingPaymentAgreementDiscount;

  locale?: string;

  number_of_payments: number;

  past_due_fee: number;

  quantity: number;

}

export interface TrainingPaymentAgreementDiscount {
  
  balance: number;

  rate: number;

  savings_amount: number;

}

export const TrainingPaymentAgreementEventNameMap = {
  ACTIVATED: `${TRAINING_PAYMENT_AGREEMENT}:activated`,
  ACTIVATION_FAILED: `${TRAINING_PAYMENT_AGREEMENT}:activation-failed`,
  AGREED: `${TRAINING_PAYMENT_AGREEMENT}:agreed`,
  CANCELED: `${TRAINING_PAYMENT_AGREEMENT}:canceled`,
  CREATED: `${TRAINING_PAYMENT_AGREEMENT}:created`,
  LISTED: `${TRAINING_PAYMENT_AGREEMENT}:listed`,
  PDF_GENERATED: `${TRAINING_PAYMENT_AGREEMENT}:pdf-generated`,
  SIGNED_URL_GENERATED: `${TRAINING_PAYMENT_AGREEMENT}:signed-url-generated`,
  SUBSCRIPTION_SET: `${TRAINING_PAYMENT_AGREEMENT}:subscription-set`,
  TICKETS_CREATED: `${TRAINING_PAYMENT_AGREEMENT}:tickets-created`,
  UPDATED: `${TRAINING_PAYMENT_AGREEMENT}:updated`,
  VIEWED: `${TRAINING_PAYMENT_AGREEMENT}:viewed`,
} as const;

export type TrainingPaymentAgreementEventName = typeof TrainingPaymentAgreementEventNameMap[keyof typeof TrainingPaymentAgreementEventNameMap];

export const TrainingPaymentAgreementEventNames = Object.values(TrainingPaymentAgreementEventNameMap);

export class TrainingPaymentAgreementFailActivationAction extends ModelAction<TrainingPaymentAgreementFailActivationPayload> {

  readonly action_name = TrainingPaymentAgreementActionNameMap.FAIL_ACTIVATION;

  constructor(input: Partial<Omit<TrainingPaymentAgreementFailActivationAction, 'action_name'>>) {

    super(input);

  }

}

export type TrainingPaymentAgreementFailActivationPayload = Pick<TrainingPaymentAgreement, 'agreement_id' | 'last_updated_epoch'> & {
  error_code: string;
  error_message: string;
  failed_epoch: number;
};

export interface TrainingPaymentAgreementFailure {

  epoch: number;

  error_code: TrainingPaymentAgreementActivationErrorCode;

  error_message: string;

}

export class TrainingPaymentAgreementGenerateSignedUrlAction extends ModelAction<TrainingPaymentAgreementGenerateSignedUrlPayload> {

  readonly action_name = TrainingPaymentAgreementActionNameMap.GENERATE_SIGNED_URL;

  constructor(input: Partial<Omit<TrainingPaymentAgreementGenerateSignedUrlAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingPaymentAgreementSignedUrlGeneratedEvent extends ModelEvent<TrainingPaymentAgreementSignedUrl> {

  readonly event_name = TrainingPaymentAgreementEventNameMap.SIGNED_URL_GENERATED;

  constructor(input: Partial<Omit<TrainingPaymentAgreementSignedUrlGeneratedEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingPaymentAgreementGenerateSignedUrlPayload = Pick<TrainingPaymentAgreement, 'agreement_id'>;

export class TrainingPaymentAgreementGeneratePDFAction extends ModelAction<TrainingPaymentAgreementGeneratePDFPayload> {

  readonly action_name = TrainingPaymentAgreementActionNameMap.GENERATE_PDF;

  constructor(input: Partial<Omit<TrainingPaymentAgreementGeneratePDFAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingPaymentAgreementPDFGeneratedEvent extends ModelEvent<TrainingPaymentAgreementSignedUrl> {

  readonly event_name = TrainingPaymentAgreementEventNameMap.PDF_GENERATED;

  constructor(input: Partial<Omit<TrainingPaymentAgreementPDFGeneratedEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingPaymentAgreementGeneratePDFPayload = Pick<TrainingPaymentAgreement, 'agreement_id'>;

export class TrainingPaymentAgreementGetAction extends ModelAction<TrainingPaymentAgreementGetPayload> {

  readonly action_name = TrainingPaymentAgreementActionNameMap.GET;

  constructor(input: Partial<Omit<TrainingPaymentAgreementGetAction, 'action_name'>>) {

    super(input);

  }

}

export type TrainingPaymentAgreementGetPayload = Pick<TrainingPaymentAgreement, 'agreement_id'> & {
  consistent_read?: ConsistentRead;
};

export interface TrainingPaymentAgreementInterface extends ModelInterface {

  activation?: TrainingPaymentAgreementActivation;

  agreement_id: string;

  cancellation?: TrainingPaymentAgreementCancellation;

  debtor: TrainingPaymentAgreementDebtor;

  details: TrainingPaymentAgreementDetails;

  description: string;

  failure?: TrainingPaymentAgreementFailure;

  first_payment_date: string;

  payment_day: string;

  signature?: ElectronicSignature;

  start_date_epoch: number;

  status: TrainingPaymentAgreementStatus;

  stripe: TrainingPaymentAgreementStripe;

}

export class TrainingPaymentAgreementListAction extends ModelAction<TrainingPaymentAgreementListPayload> {

  readonly action_name = TrainingPaymentAgreementActionNameMap.LIST;

  constructor(input: Partial<Omit<TrainingPaymentAgreementListAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingPaymentAgreementListedEvent extends ModelEvent<List<TrainingPaymentAgreement>> {

  readonly event_name = TrainingPaymentAgreementEventNameMap.LISTED;

  constructor(input: Partial<Omit<TrainingPaymentAgreementListedEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingPaymentAgreementListPayload = Partial<Pick<TrainingPaymentAgreement, 'status'>>
  & {
    exclusive_start_key?: ExclusiveStartKey;
    limit?: Limit;
  };

export class TrainingPaymentAgreementSetSubscriptionAction extends ModelAction<TrainingPaymentAgreementSetSubscriptionPayload> {

  readonly action_name = TrainingPaymentAgreementActionNameMap.SET_SUBSCRIPTION;

  constructor(input: Partial<Omit<TrainingPaymentAgreementSetSubscriptionAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingPaymentAgreementSubscriptionSetEvent extends ModelEvent<TrainingPaymentAgreement> {

  readonly event_name = TrainingPaymentAgreementEventNameMap.SUBSCRIPTION_SET;

  constructor(input: Partial<Omit<TrainingPaymentAgreementSubscriptionSetEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingPaymentAgreementSetSubscriptionPayload = Pick<TrainingPaymentAgreement, 'agreement_id' | 'last_updated_epoch'> & {
  stripe_subscription_id: string;
};
  
export interface TrainingPaymentAgreementSignedUrl {

  url: string;

}

export const TrainingPaymentAgreementStatusMap = {
  ACTIVATION_FAILED,
  ACTIVE,
  ACTIVATING,
  CANCELED,
  COLLECTABLE,
  DRAFT,
  PAID,
  PAST_DUE,
  UNCOLLECTABLE,
} as const;

export type TrainingPaymentAgreementStatus = typeof TrainingPaymentAgreementStatusMap[keyof typeof TrainingPaymentAgreementStatusMap];

export const TrainingPaymentAgreementStatuses = Object.values(TrainingPaymentAgreementStatusMap);

export interface TrainingPaymentAgreementStripe {
  
  customer_id: string;

  invoice_id: string;

  payment_method_id: string;

  subscription_id?: string;

}

export class TrainingPaymentAgreementUpdateAction extends ModelAction<TrainingPaymentAgreementUpdatePayload> {

  readonly action_name = TrainingPaymentAgreementActionNameMap.UPDATE;

  constructor(input: Partial<Omit<TrainingPaymentAgreementUpdateAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingPaymentAgreementUpdatedEvent extends ModelEvent<TrainingPaymentAgreement> {

  readonly event_name = TrainingPaymentAgreementEventNameMap.UPDATED;

  constructor(input: Partial<Omit<TrainingPaymentAgreementUpdatedEvent, 'event_name'>>) {

    super(input);

  }

}

export type TrainingPaymentAgreementUpdatePayload = Omit<TrainingPaymentAgreement, 'activation' | 'cancellation'>;

export class TrainingPaymentAgreementViewedEvent extends ModelEvent<TrainingPaymentAgreement> {

  readonly event_name = TrainingPaymentAgreementEventNameMap.VIEWED;

  constructor(input: Partial<Omit<TrainingPaymentAgreementViewedEvent, 'event_name'>>) {

    super(input);

  }

}
