import { FormControl, FormGroup, Validators } from "@angular/forms";

export class StartForm extends FormGroup {

  constructor() {

    super({
      started_epoch: new FormControl(null, [Validators.required]),
    });

  }

}
