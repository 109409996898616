import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { PAST, TrainingClass, TrainingCourseMap } from '@bcx/models';
import { isBefore } from 'date-fns';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ng-training-class-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.sass'],
})
export class CardComponent implements OnChanges {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  @Input() error?: string;

  isOwner = false;

  isProvider = false;

  @Input() loading: boolean;

  showMore = false;

  state = '';

  @Input() trainingClass?: TrainingClass;

  determineState(trainingClass: TrainingClass) {

    let state = trainingClass.status;

    if (isBefore(trainingClass.scheduled.start_epoch, Date.now())) {

      state = PAST;

    }

    this.state = state;

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['trainingClass']) {

      this.onTrainingClassChange();

    }

  }

  private onTrainingClassChange() {

    this.isOwner = false;
    this.isProvider = false;

    if (this.trainingClass) {

      if (this.trainingClass.course === TrainingCourseMap.OWNER) {

        this.isOwner = true;
        this.isProvider = false;

      } else if (this.trainingClass.course === TrainingCourseMap.PROVIDER) {

        this.isOwner = false;
        this.isProvider = true;

      }

      this.determineState(this.trainingClass);

    }

  }

}
