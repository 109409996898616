import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-get-started-redirect',
  templateUrl: './redirect.component.html',
  styleUrl: './redirect.component.sass',
})
export class RedirectComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    
    setTimeout(() => {

      this.router.navigateByUrl('/get-started/recommendations');

    }, 10000);

  }

}
