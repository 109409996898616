<div class="flex flex-col md:flex-row">

  <app-owner-book-card>

    <a color="primary" target="_blank"
      [href]="safeUrl"
      mat-raised-button>Buy Book</a>

  </app-owner-book-card>

  <app-recommendation-list [product_id]="product_id"></app-recommendation-list>

</div>