import { Component, Input } from '@angular/core';
// import {Stripe} from 'stripe';

@Component({
  selector: 'ng-stripe-invoice-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.sass'],
})
export class SummaryCardComponent {

  @Input() invoice: any;

}
