<div class="flex flex-col"
  *ngIf="(vm$ | async) as vm">

  <mat-progress-bar mode="determinate" [value]="vm.progress"></mat-progress-bar>

  <app-small-to-best-goals
    (progress$)="onGoalsProgress($event)"></app-small-to-best-goals>

  <app-small-to-best-questions
    *ngIf="vm.showQuestions"
    (progress$)="onQuestionsProgress($event)"></app-small-to-best-questions>

  <mat-progress-bar mode="determinate" [value]="vm.progress"></mat-progress-bar>

</div>
