import { InjectionToken } from "@angular/core";
import { RegionMap } from "@bcx/iso";

export const DEFAULT_REGION = new InjectionToken('default region', {
  providedIn: 'root',
  factory: () => {

    return () => RegionMap.US_WEST_2;

  },
});
