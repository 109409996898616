import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { SmallToBestQuestionsForm, SmallToBestQuestionsMap } from './questions.form';
import { BusinessOperationLengths, Industries } from '@bcx/models';
import { GetStartedService } from '../../get-started/get-started.service';
import { combineLatest, map, Observable, throttleTime } from 'rxjs';
import { ListBaseComponent } from '../../goal/list-base/list-base.component';

export interface ViewModel {

  progress: number;

  questions: SmallToBestQuestionsMap;

}

@Component({
  selector: 'app-small-to-best-questions',
  templateUrl: './questions.component.html',
  styleUrl: './questions.component.sass',
})
export class QuestionsComponent extends ListBaseComponent implements OnDestroy, OnInit {

  form = new SmallToBestQuestionsForm();

  industries = Industries;

  lengths = BusinessOperationLengths;

  @Output() override progress$: Observable<number>;

  questions$: Observable<SmallToBestQuestionsMap>;

  vm$: Observable<ViewModel>;

  constructor(private service: GetStartedService) {

    super();

    const state = this.service.getCurrentState();

    this.form.patchValue(state.small_to_best.questions);

    this.questions$ = this.service.state$.pipe(map((state) => state.small_to_best.questions));

    this.vm$ = combineLatest({
      progress: this.progress$,
      questions: this.questions$,
    });

    this.calculateProgress();

  }

  ngOnInit(): void {
    
    this.subs['questions'] = this.questions$
    .pipe(throttleTime(250))
    .subscribe(() => this.calculateProgress());

    this.subs['valueChanges'] = this.form.valueChanges
    .pipe(throttleTime(250))
    .subscribe((questions) => {

      this.service.save({
        small_to_best: {
          questions,
        },
      });

    });

  }

}
