import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AuthService, ExtendedIdentityTokenPayload } from '../auth.service';
import { Router } from '@angular/router';
import { IntlPhoneNumberControl, SubsComponent } from '@bcx/ng-helpers';
import Bluebird from 'bluebird';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ng-amplify-auth-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.sass'],
})
export class SignInComponent  extends SubsComponent implements OnInit {

  attemptsLeft = 0;

  @ViewChild('code') codeElement: ElementRef;

  codeControl: FormControl;

  codeSent = false;

  cognitoUser: CognitoUser;

  form: FormGroup;

  notSignedUp = false;

  phoneNumberControl: FormControl;

  processing = false;

  user: ExtendedIdentityTokenPayload;

  constructor(
    private auth: AuthService,
    private dialog: MatDialogRef<SignInComponent>,
    private fb: FormBuilder,
    private router: Router,
  ) {

    super();

    this.codeControl = this.fb.control(undefined, [Validators.required]);

    this.phoneNumberControl = new IntlPhoneNumberControl();

    this.form = this.fb.group({
      phone_number: this.phoneNumberControl,
    });

  }

  private attemptSignIn(phone_number: string) {

    return this.auth.signIn(phone_number)
    .then(() => {

      this.codeSent = true;

      this.phoneNumberControl.disable();

      this.form.addControl('code', this.codeControl);

      setTimeout(() => {

        this.codeElement.nativeElement.focus();

      });

    });

  }

  ngOnInit(): void {
    
    this.subs['user'] = this.auth.identity$
    .subscribe((user) => {

      if (user) {

        this.user = user;

      }

    });

  }

  closeDialog() {

    this.dialog.close();

  }

  redirect() {

    this.router.navigateByUrl('/');

  }

  resendCode() {

    this.processing = true;

    const phone_number = this.form.value.phone_number;

    this.attemptSignIn(phone_number)
    .catch((e: Error) => {

      console.error(e);

    })
    .finally(() => {

      this.processing = false;

    });

  }

  signIn() {

    this.processing = true;

    const { phone_number, code } = this.form.value;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Bluebird.try((): Promise<any> => {

      if (code) {

        return this.auth.verifySignIn(`${code}`)
        .then((result) => {
    
          console.log('verify successful');
    
          this.dialog.close(result.isSignedIn);
    
        });
  
      } else {
  
        return this.attemptSignIn(phone_number);
  
      }

    })
    .catch((e: Error) => {

      if (e.name === 'NotAuthorizedException') {

        console.error('user is not signed up');

        this.notSignedUp = true;

      } else {

        console.error(e, {...e});

      }

    })
    .finally(() => {
      
      this.processing = false;

    });

  }

  signUp() {

    this.router.navigateByUrl('/sign-up');

    this.closeDialog();

  }

}
