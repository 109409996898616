import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IntlPhoneNumberControl } from "@bcx/ng-helpers";
import { AddressForm } from "../../../person/contact-point/mail-address/form/form";

export class DebtorForm extends FormGroup {

  constructor() {

    super({
      address: new AddressForm(),
      email: new FormControl(undefined, [Validators.required]),
      legal_name: new FormControl(undefined, [Validators.required]),
      phone_number: new IntlPhoneNumberControl({ validators: [Validators.required]}),
    });

  }

}
