
export const EN_US = 'en-US';

export const LocaleMap = {
  EN_US,
} as const;

export type Locale  = typeof LocaleMap[keyof typeof LocaleMap];

export const Locales = Object.values(LocaleMap);

export const DEFAULT_LOCALE = EN_US;
