import { isBoolean } from "lodash";
import { EMAIL, EMAIL_ADDRESS, MAIL_ADDRESS, PERSON_CONTACT_POINT, PHONE_NUMBER, SMS, VIDEO, VOICE } from "../common";
import { ModelAction, ModelEvent, ModelInterface } from "../model-action";
import { List } from "@digitaltoolbuilders/model-helpers";
import { ConsistentRead, ExclusiveStartKey, Limit } from "../helpers";

export interface CapabilityMap {

  [key: string]: boolean;

}

export interface EmailCapabilities extends CapabilityMap {

  attachments: boolean;

  images: boolean;

}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class PersonContactPoint<Details = any> implements PersonContactPointInterface {

  account_id: string;

  capabilities: CapabilityMap;

  contact_point: string;

  contact_point_hash: string;

  contact_point_id: string;

  contact_point_type: PersonContactPointType;

  created_epoch: number;

  details: Details;

  do_not_contact?: boolean;
  
  do_not_contact_epoch?: number;

  last_updated_epoch: number;

  mediums: PersonContactPointMedium[];

  model_deleted?: boolean;

  model_id: string;

  permission_to_contact?: boolean;
  
  permission_to_contact_epoch?: number;

  person_id: string;

  status: PersonContactPointStatus;

  verified: boolean;

  constructor(input: Partial<PersonContactPoint>) {

    if (input.account_id) this.account_id = input.account_id;
    if (input.capabilities) this.capabilities = input.capabilities;
    if (input.contact_point) this.contact_point = input.contact_point;
    if (input.contact_point_hash) this.contact_point_hash = input.contact_point_hash;
    if (input.contact_point_id) this.contact_point_id = input.contact_point_id;
    if (input.contact_point_type) this.contact_point_type = input.contact_point_type;
    if (input.created_epoch) this.created_epoch = input.created_epoch;
    if (input.details) this.details = input.details;
    if (isBoolean(input.do_not_contact)) this.do_not_contact = input.do_not_contact;
    if (input.do_not_contact_epoch) this.do_not_contact_epoch = input.do_not_contact_epoch;
    if (input.last_updated_epoch) this.last_updated_epoch = input.last_updated_epoch;
    if (input.mediums) this.mediums = input.mediums;
    if (isBoolean(input.model_deleted)) this.model_deleted = input.model_deleted;
    if (input.model_id) this.model_id = input.model_id;
    if (input.permission_to_contact) this.permission_to_contact = input.permission_to_contact;
    if (input.person_id) this.person_id = input.person_id;
    if (input.status) this.status = input.status;
    if (isBoolean(input.verified)) this.verified = input.verified;

  }

  static generateModelId(input: Pick<PersonContactPoint, 'account_id' | 'person_id' | 'contact_point_id'>) {

    return `${input.account_id}:${input.person_id}:${input.contact_point_id}`;

  }

}

export class PersonContactPointCreateAction extends ModelAction<PersonContactPointCreatePayload> {

  override readonly action_name = PersonContactPointActionNameMap.CREATE;

  constructor(input: Partial<Omit<PersonContactPointCreateAction, 'action_name'>>) {

    super(input);

  }

}

export class PersonContactPointCreatedEvent extends ModelEvent<PersonContactPoint> {

  override readonly event_name = PersonContactPointEventNameMap.CREATED;

  constructor(input: Partial<Omit<PersonContactPointCreatedEvent, 'event_name'>>) {

    super(input);

  }

}

export type PersonContactPointCreatePayload = Omit<PersonContactPoint, 'last_updated_epoch'>;

export class PersonContactPointDeleteAction extends ModelAction<PersonContactPointDeletePayload> {

  override readonly action_name = PersonContactPointActionNameMap.DELETE;

  constructor(input: Partial<Omit<PersonContactPointDeleteAction, 'action_name'>>) {

    super(input);

  }

}

export class PersonContactPointDeletedEvent extends ModelEvent<PersonContactPoint> {

  override readonly event_name = PersonContactPointEventNameMap.DELETED;

  constructor(input: Partial<Omit<PersonContactPointDeletedEvent, 'event_name'>>) {

    super(input);

  }

}

export type PersonContactPointDeletePayload = Pick<PersonContactPoint, 
  | 'account_id'
  | 'contact_point_id' 
  | 'last_updated_epoch' 
  | 'person_id'>;

export class PersonContactPointGetAction extends ModelAction<PersonContactPointGetPayload> {

  override readonly action_name = PersonContactPointActionNameMap.GET;

  constructor(input: Partial<Omit<PersonContactPointGetAction, 'action_name'>>) {

    super(input);

  }

}
  
export type PersonContactPointGetPayload = Pick<PersonContactPoint, 
  | 'account_id'
  | 'contact_point_id' 
  | 'person_id'>
  & {
    consistent_read?: ConsistentRead;
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface PersonContactPointInterface<Details = any> extends ModelInterface {

  account_id: string;

  capabilities: CapabilityMap;

  contact_point: string;

  contact_point_hash: string;

  contact_point_id: string;

  details: Details;

  do_not_contact?: boolean;

  do_not_contact_epoch?: number;

  mediums: Array<PersonContactPointMedium>;

  permission_to_contact?: boolean;

  permission_to_contact_epoch?: number;

  person_id: string;

  status: PersonContactPointStatus;

  verified: boolean;

}

export const PersonContactPointActionNameMap = {
  CREATE: `${PERSON_CONTACT_POINT}/create`,
  DELETE: `${PERSON_CONTACT_POINT}/delete`,
  DO_NOT_CONTACT: `${PERSON_CONTACT_POINT}/do-no-contact`,
  GET: `${PERSON_CONTACT_POINT}/get`,
  LIST: `${PERSON_CONTACT_POINT}/list`,
  OPT_IN: `${PERSON_CONTACT_POINT}/opt-in`,
  OPT_OUT: `${PERSON_CONTACT_POINT}/opt-out`,
  UPDATE: `${PERSON_CONTACT_POINT}/update`,
  VERIFY: `${PERSON_CONTACT_POINT}/verify`,
} as const;

export type PersonContactPointActionName = typeof PersonContactPointActionNameMap[keyof typeof PersonContactPointActionNameMap];

export const PersonContactPointActionNames = Object.values(PersonContactPointActionNameMap);

export const PersonContactPointEventNameMap = {
  CREATED: `${PERSON_CONTACT_POINT}:created`,
  DELETED: `${PERSON_CONTACT_POINT}:deleted`,
  DO_NO_CONTACT: `${PERSON_CONTACT_POINT}:do-not-contact`,
  LISTED: `${PERSON_CONTACT_POINT}:listed`,
  OPTED_IN: `${PERSON_CONTACT_POINT}:opted_in`,
  OPTED_OUT: `${PERSON_CONTACT_POINT}:opted_out`,
  UPDATED: `${PERSON_CONTACT_POINT}:updated`,
  VERIFIED: `${PERSON_CONTACT_POINT}:verified`,
  VIEWED: `${PERSON_CONTACT_POINT}:viewed`,
} as const;

export class PersonContactPointListAction extends ModelAction<PersonContactPointListPayload> {

  override readonly action_name = PersonContactPointActionNameMap.LIST;

  constructor(input: Partial<Omit<PersonContactPointListAction, 'action_name'>>) {

    super(input);

  }

}

export type PersonContactPointListPayload = Pick<PersonContactPoint, 'account_id'> 
  & Partial<Pick<PersonContactPoint, 'contact_point_type' | 'person_id'>>
  & {
    exclusive_start_key?: ExclusiveStartKey;
    limit?: Limit;
  };

export class PersonContactPointListedEvent extends ModelEvent<List<PersonContactPoint>> {

  override readonly event_name = PersonContactPointEventNameMap.LISTED;

  constructor(input: Partial<Omit<PersonContactPointListedEvent, 'event_name'>>) {

    super(input);

  }

}

export const PersonContactPointMediumMap = {
  EMAIL,
  SMS,
  VIDEO,
  VOICE,
} as const;

export type PersonContactPointMedium = typeof PersonContactPointMediumMap[keyof typeof PersonContactPointMediumMap];

export const PersonContactPointMediums = Object.values(PersonContactPointMediumMap);

export const PersonContactPointStatusMap = {
  EMAIL,
  SMS,
  VIDEO,
  VOICE,
} as const;

export type PersonContactPointStatus = typeof PersonContactPointStatusMap[keyof typeof PersonContactPointStatusMap];

export const PersonContactPointStatuses = Object.values(PersonContactPointStatusMap);

export const PersonContactPointTypeMap = {
  EMAIL_ADDRESS,
  MAIL_ADDRESS,
  PHONE_NUMBER,
} as const;

export type PersonContactPointType = typeof PersonContactPointTypeMap[keyof typeof PersonContactPointTypeMap];

export const PersonContactPointTypes = Object.values(PersonContactPointTypeMap);

export class PersonContactPointUpdateAction extends ModelAction<PersonContactPointUpdatePayload> {

  override readonly action_name = PersonContactPointActionNameMap.UPDATE;

  constructor(input: Partial<Omit<PersonContactPointUpdateAction, 'action_name'>>) {

    super(input);

  }

}

export class PersonContactPointUpdatedEvent extends ModelEvent<PersonContactPoint> {

  override readonly event_name = PersonContactPointEventNameMap.UPDATED;

  constructor(input: Partial<Omit<PersonContactPointUpdatedEvent, 'event_name'>>) {

    super(input);

  }

}

export type PersonContactPointUpdatePayload = Pick<PersonContactPoint, 
  | 'account_id'
  | 'contact_point_id' 
  | 'last_updated_epoch' 
  | 'person_id'>;

export class PersonContactPointViewedEvent extends ModelEvent<PersonContactPoint> {

  override readonly event_name = PersonContactPointEventNameMap.VIEWED;

  constructor(input: Partial<Omit<PersonContactPointViewedEvent, 'event_name'>>) {

    super(input);

  }

}

export interface PhoneNumberCapabilities extends CapabilityMap {

  mms: boolean;

  sms: boolean;

  voice: boolean;

}
