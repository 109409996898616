import { FormControl, FormGroup, Validators } from "@angular/forms";

export class ConfirmForm extends FormGroup {

  constructor() {

    super({
      confirm: new FormControl(false, [Validators.requiredTrue]),
    });

  }

}
