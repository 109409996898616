import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';
import { AuthedUserComponent } from './authed-user/authed-user.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgPickerModule } from '@bcx/ng-helpers';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

export function initalizeModuleFactory(service: AuthService) {
  return () => {
    return service.init();
  };
}

@NgModule({
  declarations: [AuthedUserComponent, SignInComponent, SignOutComponent],
  exports: [AuthedUserComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    NgPickerModule,
    ReactiveFormsModule,
    RouterModule.forChild([]),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [AuthService],
      multi: true,
      useFactory: initalizeModuleFactory,
    },
  ],
})
export class AuthModule {}
