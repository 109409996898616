import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ProviderGoalNames } from "@bcx/models";

export class ProviderGoalsForm extends FormGroup {

  constructor() {

    super({
      other: new FormControl<string | undefined>(undefined, []),
    });

    ProviderGoalNames.forEach((goal) => {

      this.addControl(goal, new FormControl<string>('', [Validators.required]));

    });

  }

}
