<div fxLayout="column" fxLayoutGap="10px"
  *ngIf="form"
  [formGroup]="form">

  <div fxLayout="column"
    fxLayout.gt-sm="row">

    <!-- <ng-user-select-user
      [control]="payerAccountId"></ng-user-select-user> -->

    <ng-billing-settings-select-currency
      [control]="currencyControl"></ng-billing-settings-select-currency>
<!-- 
    <ng-stripe-payment-method-select
      [control]="paymentMethodControl"></ng-stripe-payment-method-select> -->

  </div>

  <div fxLayout="column"
    fxLayout.gt-sm="row">

    <ng-consulting-session-select-type fxFlex
      [control]="sessionTypeControl"
      [category]="categoryControl.value"></ng-consulting-session-select-type>

    <mat-form-field fxFlex>

      <mat-label>Select A Category</mat-label>

      <mat-select
        formControlName="session_category"
        matInput>
  
        <mat-option *ngFor="let category of categories" [value]="category">{{category}}</mat-option>
  
      </mat-select>
  
      <mat-error>Pleae select a category for the session.</mat-error>
  
    </mat-form-field>
  
  </div>

  <mat-divider></mat-divider>

  <ng-consulting-session-select-start [control]="scheduledStartEpochControl"></ng-consulting-session-select-start>

  <mat-divider></mat-divider>

  <ng-consulting-session-select-length [control]="scheduledLengthMinutesControl"></ng-consulting-session-select-length>

</div>
