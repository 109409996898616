import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubsComponent } from '@bcx/ng-helpers';
import { SessionService } from '../session.service';
import { RescheduleForm } from '../reschedule-form/reschedule-form.form';
import { ConsultingSession } from '@bcx/models';

@Component({
  selector: 'ng-consulting-reschedule-dialog',
  templateUrl: './reschedule-dialog.component.html',
  styleUrls: ['./reschedule-dialog.component.sass'],
})
export class RescheduleDialogComponent extends SubsComponent {

  error?: string;

  form = new RescheduleForm();

  processing: boolean;

  oldSession: ConsultingSession;

  session_id: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { session: ConsultingSession },
    private dialog: MatDialogRef<ConsultingSession>,
    private service: SessionService,
  ) {

    super();

    this.oldSession = data.session;

    this.session_id = this.service.createId();

    this.form.patchValue({
      scheduled_length_minutes: this.oldSession.scheduled.length_minutes,
    });

  }

  closeDialog() {

    this.dialog.close();

  }

  submit() {

    return this.service.cancel(this.oldSession, {
      cancellation: {
        reason: 'rescheduling'
      }
    })
    .then(() => {

      return this.service.request({
        ...this.form.value,
        currency: this.oldSession.currency,
        session_category: this.oldSession.category,
        session_id: this.session_id,
        session_type: this.oldSession.type,
        stripe_payment_method_id: this.oldSession.billing.stripe_payment_method_id,
        stripe_price_id: this.oldSession.billing.stripe_price_id,
      });

    })
    .then((session) => {

      this.dialog.close(session);

    })
    .catch((e: Error) => {

      console.error(e);

    });

  }

}
