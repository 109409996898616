import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MIN_HEIGHT, MIN_WIDTH } from '../common';

@Component({
  selector: 'app-not-sure',
  templateUrl: './not-sure.component.html',
  styleUrl: './not-sure.component.sass',
})
export class NotSureComponent {

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<NotSureComponent>,
  ) {

  }

  owner() {

    //

  }

  provider() {

    //

  }

}
