import { Component, Inject, OnInit } from '@angular/core';
import { ConfirmForm } from '../confirm-form/confirm-form.form';
import { SubsComponent } from '@bcx/ng-helpers';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClassService } from '../class.service';
import { TrainingClass } from '@bcx/models';

@Component({
  selector: 'ng-training-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.sass'],
})
export class ConfirmDialogComponent extends SubsComponent implements OnInit {

  error?: string;

  form = new ConfirmForm();

  processing: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public trainingClass: TrainingClass,
    private dialog: MatDialogRef<ConfirmDialogComponent>,
    private service: ClassService,
  ) {

    super();

  }

  closeDialog() {

    this.dialog.close();

  }

  logForm() {

    console.log('form', this.form);

  }

  ngOnInit(): void {
    
    // this.subs['trainingClass'] = this.service.select(this.trainingClass.class_id)
    // .subscribe(({ error, processing }) => {

    //   this.error = error;

    //   this.processing = processing;

    // });

  }

  submit(): void {
    
    this.service.confirm(this.trainingClass, {
      confirmed_epoch: this.form.value.confirmed_epoch || Date.now(),
      reason: this.form.value.reason || '',
    })
    .then((confirmed) => {

      this.dialog.close(confirmed);

    })
    .catch((e: Error) => {

      console.error(e);

    });

  }

}
