<mat-card *ngIf="ticket">

  <mat-card-header>

    <mat-card-title>

      <ng-container [ngSwitch]="ticket.course">

        <span *ngSwitchCase="OWNER">Owner</span>
        <span *ngSwitchCase="PROVIDER">Provider</span>

      </ng-container>
      <span>&nbsp;Class #{{ticket.course_class_number}} &lpar;{{ticket.class_type}}&rpar;</span>
      
    </mat-card-title>
    <mat-card-subtitle>{{ticket.status}}</mat-card-subtitle>

  </mat-card-header>

  <mat-card-content>

    <div fxLayout="column" fxLayoutGap="10px">

      <mat-card>

        <mat-card-header>

          <mat-card-title>Your Class</mat-card-title>

        </mat-card-header>

        <mat-card-content>

          <span *ngIf="!ticket.class_id">No Class Scheduled</span>

          <mat-spinner *ngIf="trainingClassLoading"></mat-spinner>

        </mat-card-content>

        <mat-card-actions>

          <button type="button" color="primary"
            mat-raised-button>Schedule Class</button>

        </mat-card-actions>

      </mat-card>

      <mat-card>

        <mat-card-header>

          <mat-card-title>Your Seat</mat-card-title>

        </mat-card-header>

        <mat-card-content>

          <span *ngIf="!ticket.class_id">No Class Scheduled</span>

          <mat-spinner *ngIf="trainingClassLoading"></mat-spinner>

        </mat-card-content>

        <mat-card-actions>

          <button type="button" color="primary"
            mat-raised-button>Release Seat</button>

        </mat-card-actions>

      </mat-card>

    </div>

  </mat-card-content>

  <mat-card-actions> 

    <ng-container *ngTemplateOutlet="actions; context: { ticket }"></ng-container>

  </mat-card-actions>

</mat-card>
