import { Component, OnInit, Output } from '@angular/core';
import { OwnerGoalMap, OwnerGoalNameMap } from '@bcx/models';
import { OwnerGoalsForm } from './goals.form';
import { GetStartedService } from '../../get-started/get-started.service';
import { BehaviorSubject, combineLatest, map, Observable, startWith, throttleTime } from 'rxjs';
import { ListBaseComponent } from '../../goal/list-base/list-base.component';

export interface ViewModel {

  goals: OwnerGoalMap;

  progress: number;

}

@Component({
  selector: 'app-owner-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.sass'],
})
export class GoalsComponent extends ListBaseComponent implements OnInit {

  @Output() completed = new BehaviorSubject(false);

  form = new OwnerGoalsForm();

  names = OwnerGoalNameMap;

  @Output() override progress$: Observable<number>;

  vm$: Observable<ViewModel>;

  constructor(private service: GetStartedService) { 

    super();

    const state = this.service.getCurrentState();

    this.form.patchValue(state.owner.goals);

    this.vm$ = combineLatest({
      goals: this.form.valueChanges.pipe(startWith(this.form.value)),
      progress: this.progress$,
    });

    this.calculateProgress();

  }

  ngOnInit(): void {
    
    this.subs['goals'] = this.service.state$
    .pipe(map((state) => state.owner.goals))
    .pipe(throttleTime(250))
    .subscribe(() => {

      this.calculateProgress();

    });

    this.subs['valueChanges'] = this.form.valueChanges
    .pipe(throttleTime(250))
    .subscribe((goals) => {

      this.service.save({
        owner: {
          goals,
        },
      });

    });

  }

}
