import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BOOK, CLASS, TrainingTicket, VIDEO } from '@bcx/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ng-training-ticket-tearoff',
  templateUrl: './tearoff.component.html',
  styleUrls: ['./tearoff.component.sass'],
})
export class TearoffComponent {

  BOOK = BOOK;

  CLASS = CLASS;

  @Input() ticket: TrainingTicket;

  VIDEO = VIDEO;

}
