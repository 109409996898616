<div fxLayout="column"
  *ngIf="control">

  <mat-form-field>

    <mat-label>{{label}}</mat-label>

    <mat-select
      [formControl]="control"
      matInput>

      <mat-option *ngFor="let timeZone of timeZones" [value]="timeZone">{{timeZone}}</mat-option>

    </mat-select>

  </mat-form-field>

</div>
