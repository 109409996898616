import { Injectable } from '@angular/core';
import { ChargeServiceAdapter } from './charge.service.adapter';
import { RestServerResponse, STRIPE_CHARGE, StripeChargeGetAction, StripeChargeListAction, StripeChargeListedEvent, StripeChargeViewedEvent } from '@bcx/models';
import { ApiService } from '@engagedcx/ng-amplify';
import {Stripe} from 'stripe';

@Injectable({
  providedIn: 'root'
})
export class RestService implements ChargeServiceAdapter {

  private path = `/${STRIPE_CHARGE}`;

  constructor(private rest: ApiService) { }

  get(action: StripeChargeGetAction): Promise<StripeChargeViewedEvent> {
    
    return this.rest.post<RestServerResponse<Stripe.Charge>>(this.path, action)
    .then((response) => {

      return new StripeChargeViewedEvent({
        ...response.data,
      });

    });

  }

  list(action: StripeChargeListAction): Promise<StripeChargeListedEvent> {
    
    return this.rest.post<RestServerResponse<Stripe.ApiList<Stripe.Charge>>>(this.path, action)
    .then((response) => {

      return new StripeChargeListedEvent({
        ...response.data,
      });

    });

  }

}
