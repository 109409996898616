<div fxFlexFill fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">

  <div class="mat-headline-5" fxLayout="column" fxLayoutAlign="center center">

    <span>{{trainingClass?.scheduled?.start_epoch || ticket.redemption_epoch | date:'M/d @ h:mm a'}}</span>

    <span>{{state | capitalizeFirst}}</span>

  </div>

  <div fxLayout="column" fxLayoutAlign="center center"
    *ngIf="trainingClass && trainingClass.google">

    <a target="_blank" 
      *ngIf="trainingClass.google.calendar_event_link"
      [href]="trainingClass.google.calendar_event_link">
      <img border="0" src="https://www.google.com/calendar/images/ext/gc_button1_en.gif">
    </a>

    <a target="_blank" color="primary"
      *ngIf="trainingClass.google.meet_link"
      [href]="trainingClass.google.meet_link"
      mat-raised-button>
      Join
    </a>

  </div>

  <div class="actions" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="5px">

    <button type="button" color="primary"
      *ngIf="canRedeem()"
      (click)="redeem()"
      mat-raised-button>Redeem</button>

    <button type="button"
      *ngIf="canReschedule()"
      (click)="reschedule()"
      mat-raised-button>Reschedule</button>

    <button type="button" color="warn"
      *ngIf="canCancel()"
      (click)="release()"
      mat-raised-button>Cancel</button>

  </div>

</div>
