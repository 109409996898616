import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrainingSeat } from '@bcx/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ng-training-seat-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.sass'],
})
export class SummaryComponent {

  @Input() seat: TrainingSeat;

}
