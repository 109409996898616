import { Component, OnInit } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubsComponent } from '@bcx/ng-helpers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent extends SubsComponent implements OnInit {

  title = 'marketing-tool';

  constructor(
    private swUpdate: SwUpdate,
    private snackBar: MatSnackBar
  ) {

    super();

  }

  ngOnInit(): void {
    
    this.subs['sw'] = this.swUpdate.versionUpdates.subscribe((event: VersionEvent) => {

      this.onVersionEvent(event);

    });

  }

  private onVersionEvent(event: VersionEvent) {

    if (event.type === 'VERSION_DETECTED') {
      
      const ref = this.snackBar.open('There is a newer version of our site available.', 'Reload', {
        
      });

      ref.onAction().subscribe(() => {

        location.reload();

      });

    }

  }

}
