import { InjectionToken } from "@angular/core";

export const BackendAdapterMap = {
  REST: 'rest',
  WEBSOCKET: 'websocket',
} as const;

export type BackendAdapter = typeof BackendAdapterMap[keyof typeof BackendAdapterMap];

export const BACKEND_ADAPTER = new InjectionToken<BackendAdapter>('backend adapter', {
  factory: () => {

    return BackendAdapterMap.REST;

  },
  providedIn: 'root',
});
