import { Injectable } from '@angular/core';
import { Currency, CurrencyMap } from '@bcx/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private currency = new BehaviorSubject<Currency>(CurrencyMap.USD);

  currency$ = this.currency.asObservable();

}
