import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SessionService } from '../session.service';
import { SubsComponent } from '@bcx/ng-helpers';
import { ConfirmForm } from '../confirm-form/confirm-form.form';
import { ConsultingSession } from '@bcx/models';

@Component({
  selector: 'ng-consulting-session-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.sass'],
})
export class ConfirmDialogComponent extends SubsComponent {

  error?: string;

  form: ConfirmForm;

  processing: boolean;

  session: ConsultingSession;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ConsultingSession,
    private dialog: MatDialogRef<ConfirmDialogComponent>,
    private service: SessionService,
  ) {

    super();

    this.form = new ConfirmForm();

    this.session = data;

  }

  closeDialog() {

    this.dialog.close();

  }

  submit() {

    this.service.confirm(this.session, {
      google: {
        calendar_link: this.form.value.google_calendar_link,
        meet_details: this.form.value.google_meet_details,
        meet_link: this.form.value.google_meet_link,
      }
    })
    .then((confirmed: ConsultingSession) => {

      this.dialog.close(confirmed);

    })
    .catch((e: Error) => {

      console.error(e);

    });

  }

}
