import { FormControl, FormGroup, Validators } from "@angular/forms";

export class ConfirmForm extends FormGroup {

  constructor() {

    super({
      confirm: new FormControl(false, [Validators.requiredTrue]),
      google_calendar_link: new FormControl('', [Validators.required]),
      google_meet_details: new FormControl('', [Validators.required]),
      google_meet_link: new FormControl('', [Validators.required]),
    });

  }

}
