<div class="ng-calendar-month" fxFlexFill fxLayout="column">

  <div class="ng-calendar-header" fxLayout="row" fxLayoutAlign="space-between">

    <button type="button" color="primary"
      *ngIf="isAfterMin"
      (click)="previous()"
      mat-icon-button>
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
    <span *ngIf="!isAfterMin"></span>

    <div fxLayout="row" fxLayoutAlign="center center">

      <span>{{monthName}} {{year}}</span>

    </div>

    <button type="button" color="primary"
      *ngIf="isBeforeMax"
      (click)="next()"
      mat-icon-button>
      <mat-icon>arrow_forward_ios</mat-icon>
    </button>
    <span *ngIf="!isBeforeMax"></span>

  </div>

  <div class="ng-calendar-content" fxLayout="column">

    <ng-calendar-week 
      *ngFor="let week of weeks"
      [eventClick]="eventClick"
      [linkTemplate]="linkTemplate"
      [showMonthName]="!isGtSm"
      [week]="week"></ng-calendar-week>

  </div>

  <div class="ng-calendar-footer" fxLayout="row" fxLayoutAlign="space-between">

    <button type="button" color="primary"
      *ngIf="isAfterMin"
      (click)="previous()"
      mat-icon-button>
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
    <span *ngIf="!isAfterMin"></span>

    <div fxLayout="row" fxLayoutAlign="center center">

      <span>{{monthName}} {{year}}</span>

    </div>

    <button type="button" color="primary"
      *ngIf="isBeforeMax"
      (click)="next()"
      mat-icon-button>
      <mat-icon>arrow_forward_ios</mat-icon>
    </button>
    <span *ngIf="!isBeforeMax"></span>

  </div>

</div>
