<div fxLayout="column"
  [formGroup]="form"
  *ngIf="form">

  <mat-form-field>

    <input type="text" placeholder="Reason"
      formControlName="reason"
      matInput>

    <mat-error>The reason is required.</mat-error>

  </mat-form-field>

</div>
