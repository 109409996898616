<div fxLayout="row" fxLayoutGap="5px"
  *ngIf="control">

  <mat-form-field fxFlex>

    <input type="datetime-local"
      [formControl]="internal"
      matInput>

    <mat-error>Please enter a date and time, it is required.</mat-error>

  </mat-form-field>

  <button type="button" color="primary"
    (click)="now()"
    mat-raised-button>Now</button>

</div>
