<mat-card *ngIf="ticket">

  <mat-card-header>

    <mat-card-title>
      <span>Training Ticket</span>
      <span fxFlex></span>
      <span *ngIf="isOwner">Owner Training Session {{ticket.course_class_number}}</span>
      <span *ngIf="isProvider">Provider Training Session {{ticket.course_class_number}}</span>
    </mat-card-title>
    <mat-card-subtitle>{{ticket.status}}</mat-card-subtitle>

  </mat-card-header>

  <mat-card-content>

    <dl><dt>Ticket Id:</dt><dd>{{ticket.ticket_id}}</dd></dl>
    <dl><dt>Class Id:</dt><dd>{{ticket.class_id}}</dd></dl>
    <dl><dt>Class Seat Id:</dt><dd>{{ticket.class_seat_id}}</dd></dl>
    <dl><dt>Last Updated:</dt><dd>{{ticket.last_updated_epoch | date:'short'}}</dd></dl>
    <dl><dt>Stripe Invoice Id:</dt><dd>{{ticket.stripe_invoice_id}}</dd></dl>
    <dl><dt>User Id:</dt><dd>{{ticket.user_id}}</dd></dl>

    <pre *ngIf="showAll">{{ticket | json}}</pre>

  </mat-card-content>

  <mat-card-actions> 

    <button type="button"
      (click)="toggleShowAll()"
      mat-icon-button>
      <mat-icon *ngIf="showAll">more_vert</mat-icon>
      <mat-icon *ngIf="!showAll">more_horiz</mat-icon>
    </button>

    <ng-container *ngTemplateOutlet="actions; context: { ticket }"></ng-container>

  </mat-card-actions>

</mat-card>
<mat-card *ngIf="!ticket">

  <mat-card-content>

    <mat-spinner *ngIf="loading"></mat-spinner>

    <span *ngIf="error">{{error}}</span>

  </mat-card-content>

</mat-card>
