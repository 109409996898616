import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {Stripe} from 'stripe';
import { PriceService } from '../price.service';
import { SubsComponent } from '@bcx/ng-helpers';

@Component({
  selector: 'ng-stripe-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.sass'],
})
export class PriceComponent extends SubsComponent implements OnChanges {

  error?: string;

  @Input() id: string;

  loading: boolean;

  price: Stripe.Price;

  constructor(
    private service: PriceService,
  ) {

    super();

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['id']) {

      this.onIdChange();

    }

  }

  onIdChange() {

    if (this.subs['price']) {

      this.subs['price'].unsubscribe();

    }

    if (this.id) {

      this.subs['price'] = this.service.select(this.id).subscribe(({ error, loading, model }) => {

        this.error = error;

        this.loading = loading;

        if (model) {

          this.price = model;
          

        }

      });

      this.service.get(this.id)
      .catch((e) => {

        console.error(e);

      });

    }

  }

}
