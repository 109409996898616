import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ContactInfoForm } from './contact-info.form';
import { IntlPhoneNumberControl } from '@bcx/ng-helpers';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ng-user-contact-info-form',
  templateUrl: './contact-info-form.component.html',
  styleUrls: ['./contact-info-form.component.sass'],
})
export class ContactInfoFormComponent implements OnChanges {

  addressForm: FormGroup;

  @Input() form: ContactInfoForm;

  phoneNumberControl: IntlPhoneNumberControl;

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['form'] && this.form) {

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.addressForm = this.form.get('address') as any;
      this.phoneNumberControl = this.form.get('phone_number') as IntlPhoneNumberControl

    }

  }

}
