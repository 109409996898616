import { Injectable } from '@angular/core';
import { ContactPointServiceAdapter } from '../contact-point.service';
import { ApiService } from '@engagedcx/ng-amplify';
import { PERSON_CONTACT_POINT, PersonContactPoint, PersonContactPointCreateAction, PersonContactPointCreatedEvent, PersonContactPointDeleteAction, PersonContactPointDeletedEvent, PersonContactPointGetAction, PersonContactPointInterface, PersonContactPointListAction, PersonContactPointListedEvent, PersonContactPointUpdateAction, PersonContactPointUpdatedEvent, PersonContactPointViewedEvent, RestServerResponse } from '@bcx/models';
import { List } from '@digitaltoolbuilders/model-helpers';
import { IdService } from '@bcx/ng-helpers';

@Injectable({
  providedIn: 'root'
})
export class RestServiceAdapter implements ContactPointServiceAdapter {

  private path = `/${PERSON_CONTACT_POINT}`;

  constructor(private api: ApiService, private id: IdService) { }

  create(action: PersonContactPointCreateAction): Promise<PersonContactPointCreatedEvent> {
    
    return this.api.post<PersonContactPointCreatedEvent>(this.path, action)
    .then((response) => {

      return new PersonContactPointCreatedEvent({
        ...response.data,
        payload: new PersonContactPoint(response.data.payload),
      });

    });

  }

  delete(action: PersonContactPointDeleteAction): Promise<PersonContactPointDeletedEvent> {
      
    return this.api.post<RestServerResponse<PersonContactPointInterface>>(this.path, action)
    .then((response) => {

      return new PersonContactPointDeletedEvent({
        ...response.data,
        payload: new PersonContactPoint(response.data),
      });

    });

  }

  get(action: PersonContactPointGetAction): Promise<PersonContactPointViewedEvent> {
    
    return this.api.post<RestServerResponse<PersonContactPointInterface>>(this.path, action)
    .then((response) => {

      return new PersonContactPointViewedEvent({
        ...response.data,
        payload: new PersonContactPoint(response.data.payload),
      });

    });

  }

  list(action: PersonContactPointListAction): Promise<PersonContactPointListedEvent> {
    
    return this.api.post<RestServerResponse<List<PersonContactPointInterface>>>(this.path, action)
    .then((response) => {

      return new PersonContactPointListedEvent({
        ...response.data,
        payload: {
          ...response.data.payload,
          list: response.data.payload.list.map((item) => {

            return new PersonContactPoint(item);

          }),
        },
      });

    });

  }

  update(action: PersonContactPointUpdateAction): Promise<PersonContactPointUpdatedEvent> {
    
    return this.api.post<RestServerResponse<PersonContactPointInterface>>(this.path, action)
    .then((response) => {

      return new PersonContactPointUpdatedEvent({
        ...response.data,
        payload: new PersonContactPoint(response.data.payload),
      });

    });

  }

}
