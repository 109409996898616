import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { format } from 'date-fns';
import { distinctUntilChanged } from 'rxjs';
import { SubsComponent } from '../../../subs.component';

export const DATETIME_LOCAL_FORMAT = 'yyyy-MM-dd\'T\'HH:mm:ss';

@Component({
  selector: 'ng-picker-datetime-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.sass'],
})
export class PickerComponent extends SubsComponent implements OnChanges, OnInit {

  @Input() control?: FormControl;

  internal: FormControl;

  @Input() returnEpoch = false;

  constructor(private fb: FormBuilder) {

    super();

    this.internal = this.fb.control(null, []);

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['control'] && this.control) {

      this.onControlChange(this.control);

    }

  }

  ngOnInit(): void {
    
    this.subs['interal'] = this.internal.valueChanges
    .pipe(distinctUntilChanged())
    .subscribe((value?: string) => {

      console.log('internal', value);

      this.onInternalChange(value);

    });

  }

  now() {

    this.internal.patchValue(format(new Date(), DATETIME_LOCAL_FORMAT));

  }

  private onControlChange(control: FormControl) {

    if (this.subs['control']) {

      this.subs['control'].unsubscribe();

    }

    this.subs['control'] = control.valueChanges
    .pipe(distinctUntilChanged())
    .subscribe((value?: number | string) => {

      console.log('control', value);

      if (typeof value === 'number') {

        this.internal.patchValue(format(value, DATETIME_LOCAL_FORMAT));

      } else {

        this.internal.patchValue(value);

      }

    });

  }

  private onInternalChange(value?: string) {

    if (this.control) {

      this.control.patchValue((value && this.returnEpoch) ? Date.parse(value) : value);

    }

  }

}
