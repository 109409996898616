import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionModule } from './session/session.module';

@NgModule({
  declarations: [],
  exports: [
    SessionModule,
  ],
  imports: [
    CommonModule,
    SessionModule,
  ]
})
export class ConsultingModule { }
