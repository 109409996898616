import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivationErrorComponent } from './activation-error/activation-error.component';
import { AgreementCardComponent } from './agreement-card/agreement-card.component';
import { DebtorFormComponent } from './debtor-form/debtor-form.component';
import { FullDetailComponent } from './full-detail/full-detail.component';
import { ListTableComponent } from './list-table/list-table.component';
import { SignAgreementComponent } from './sign-agreement/sign-agreement.component';
import { SummaryCardComponent } from './summary-card/summary-card.component';
import { MatCardModule } from '@angular/material/card';
import { PipesModule } from '../../stripe/pipes/pipes.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgPickerModule } from '@bcx/ng-helpers';
import { AddressModule } from '../../billing/address/address.module';
import { MatTableModule } from '@angular/material/table';



@NgModule({
  declarations: [
    ActivationErrorComponent,
    AgreementCardComponent,
    DebtorFormComponent,
    FullDetailComponent,
    ListTableComponent,
    SignAgreementComponent,
    SummaryCardComponent,
  ],
  exports: [
    ActivationErrorComponent,
    AgreementCardComponent,
    DebtorFormComponent,
    FullDetailComponent,
    ListTableComponent,
    SignAgreementComponent,
    SummaryCardComponent,
  ],
  imports: [
    AddressModule,
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatPseudoCheckboxModule,
    MatTableModule,
    NgPickerModule,
    PipesModule,
    ReactiveFormsModule,
  ]
})
export class PaymentAgreementModule { }
