import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { TrainingPaymentAgreement } from '@bcx/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ng-training-payment-agreement-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.sass'],
})
export class SummaryCardComponent {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  @Input() agreement: TrainingPaymentAgreement;

}
