import { TrainingClassActionNameMap, TrainingClassCancelAction, TrainingClassCancelPayload, TrainingClassConfirmAction, TrainingClassConfirmPayload, TrainingClassCreateAction, TrainingClassCreatePayload, TrainingClassFinishAction, TrainingClassFinishPayload, TrainingClassGetAction, TrainingClassGetPayload, TrainingClassListAction, TrainingClassListPayload, TrainingClassRequestAction, TrainingClassRequestPayload, TrainingClassStartAction, TrainingClassStartPayload, TrainingClassTypes } from "@bcx/models";
import Joi from "joi";
import { consistent_read } from "../dynamo-db";
import { createActionSchema } from "../model-action";
import { epoch } from "@digitaltoolbuilders/schema-helpers";

export const class_id = Joi.string();

export const type = Joi.string().allow(...TrainingClassTypes);

export const cancel = Joi.object<TrainingClassCancelPayload>()
  .keys({
    class_id: class_id.required(),
    last_updated_epoch: epoch.required(),
  });

export const cancelAction = createActionSchema<TrainingClassCancelAction>(TrainingClassActionNameMap.CANCEL, cancel);

export const confirm = Joi.object<TrainingClassConfirmPayload>()
  .keys({
    class_id: class_id.required(),
    last_updated_epoch: epoch.required(),
  });

export const confirmAction = createActionSchema<TrainingClassConfirmAction>(TrainingClassActionNameMap.CONFIRM, confirm);

export const create = Joi.object<TrainingClassCreatePayload>()
  .keys({
    class_id: class_id.required(),
  });

export const createAction = createActionSchema<TrainingClassCreateAction>(TrainingClassActionNameMap.CREATE, create);

export const finish = Joi.object<TrainingClassFinishPayload>()
  .keys({
    class_id: class_id.required(),
  });

export const finishAction = createActionSchema<TrainingClassFinishAction>(TrainingClassActionNameMap.FINISH, finish);

export const get = Joi.object<TrainingClassGetPayload>()
  .keys({
    consistent_read: consistent_read,
    class_id: class_id.required(),
  });

export const getAction = createActionSchema<TrainingClassGetAction>(TrainingClassActionNameMap.GET, get);

export const list = Joi.object<TrainingClassListPayload>()
  .keys({
    after: epoch.optional().allow(null),
    before: epoch.optional().allow(null),
  });

export const listAction = createActionSchema<TrainingClassListAction>(TrainingClassActionNameMap.LIST, list);

export const request = Joi.object<TrainingClassRequestPayload>()
  .keys({
    class_id: class_id.required(),
  });

export const requestAction = createActionSchema<TrainingClassRequestAction>(TrainingClassActionNameMap.REQUEST, request);

export const start = Joi.object<TrainingClassStartPayload>()
  .keys({
    class_id: class_id.required(),
  });

export const startAction = createActionSchema<TrainingClassStartAction>(TrainingClassActionNameMap.START, start);
