
import { createFeatureSelector, createSelector, } from '@ngrx/store';
import { SetupIntent } from '@stripe/stripe-js';
import { Stripe } from 'stripe';
import { featureKey, getEntity, State } from './payment-method.reducer';
import { AllEntitiesState, Entity, ManyEntitiesState } from '@bcx/ng-helpers';

export const selectState = createFeatureSelector<State>(
  featureKey
);

export const selectAll = createSelector(
  selectState,
  (state: State) => {

    const entities = Object.values(state.entities)
    .filter((entity) => (entity.model) ? true : false);

    const all: AllEntitiesState<Stripe.PaymentMethod> = {
      ...state.all,
      entities,
      models: entities.map((entity) => {
  
        return entity.model as Stripe.PaymentMethod;
  
      }),
    };

    return all;

  }
);

export const selectAdd = createSelector(
  selectState,
  (state: State) => {

    return state.add;

  }
);

export function selectForCustomer(customer: string) {

  return createSelector(
    selectState,
    (state: State) => {

      const entities: Array<Entity<Stripe.PaymentMethod>> = Object.values(state.entities)
      .filter((entity: Entity<Stripe.PaymentMethod>) => {

        return entity.model && entity.model.customer === customer;

      });

      const many: ManyEntitiesState<Stripe.PaymentMethod> = {
        ...state.all,
        ids: entities.map((entity: Entity<Stripe.PaymentMethod>) => {

          return entity.model?.id as string;

        }),
        entities,
        models: entities.map((entity: Entity<Stripe.PaymentMethod>) => {

          return entity.model as Stripe.PaymentMethod;

        }),
      };

      return many;

    },
  )

}

export const selectMy = createSelector(
  selectState,
  (state: State) => {

    const entities: Array<Entity<Stripe.PaymentMethod>> = [];

    state.my.ids.forEach((id: string) => {

      const entity = getEntity(id, state);

      if (entity.model) {

        entities.push(entity);

      }

    });

    const my: ManyEntitiesState<Stripe.PaymentMethod> = {
      ...state.my,
      entities,
      models: entities.map((entity: Entity<Stripe.PaymentMethod>) => {

        return entity.model as Stripe.PaymentMethod;

      }),
    };

    return my;

  }
);

export const selectSetupIntent = (intent: string) => {

  return createSelector(
    selectState,
    (state: State) => {
  
      const entity: Entity<SetupIntent> = state.setupIntents[intent] || {};

      return entity;
  
    }
  );

}
