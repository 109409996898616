import { StripeInvoiceActionNameMap, StripeInvoiceGetAction, StripeInvoiceGetPayload, StripeInvoiceListAction, StripeInvoiceListPayload } from "@bcx/models";
import Joi from "joi";
import { subscription_id } from "./common";
import { createActionSchema } from "../model-action";

export const get = Joi.object<StripeInvoiceGetPayload>()
  .keys({
    id: subscription_id.required(),
  });

export const getAction = createActionSchema<StripeInvoiceGetAction>(StripeInvoiceActionNameMap.GET, get);

export const list = Joi.object<StripeInvoiceListPayload>()
  .keys({
    
  });

export const listAction = createActionSchema<StripeInvoiceListAction>(StripeInvoiceActionNameMap.LIST, list);
