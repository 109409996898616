import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";

export class SignAgreementForm extends FormGroup {

  constructor() {

    super({
      agree: new FormControl(false, [Validators.requiredTrue]),
      signature: new FormControl('', [Validators.required]),
    });

    this.get('signature')?.addValidators((control: AbstractControl<string>) => {

      if (this.signatureMatchesLegalName(control)) {

        return null;

      }

      return {
        noMatch: true
      }

    });

  }

  signatureMatchesLegalName(control: AbstractControl<string>) {

    const debtorForm = control.parent?.parent?.get('debtor');

    const legalName = debtorForm?.value.legal_name;

    const signature = control.value;

    // console.log('signatureMatchesLegalName', legalName, signature);

    return (legalName === signature);

  }

}
