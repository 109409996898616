import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '@bcx/models';

@Component({
  selector: 'ng-user-list-table',
  templateUrl: './list-table.component.html',
  styleUrls: ['./list-table.component.sass'],
})
export class ListTableComponent implements OnChanges {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  @Input() columnsToDisplay: Array<string> = [
    'user_id',
    'state',
    'name',
    'roles',
    'email',
    'phone_number',
    'user_name',
    'actions',
  ];

  table = new MatTableDataSource<User>([]);

  @Input() users: Array<User> = [];

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['users']) {

      this.table.data = this.users;

    }

  }

}
