<div fxLayout="row" fxLayoutAlign="space-between"
  mat-dialog-title>

  <h1 class="mat-headline-5">{{event.title}}</h1>

  <button type="button" color="warn"
    (click)="closeDialog()"
    mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>

</div>

<mat-dialog-content>

  <ng-container *ngTemplateOutlet="content; context: { dialog, event }"></ng-container>

</mat-dialog-content>

<mat-dialog-actions>

  <span></span>

  <ng-container *ngTemplateOutlet="actions; context: { dialog, event }"></ng-container>

</mat-dialog-actions>
