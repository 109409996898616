import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrainingTicket } from '@bcx/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ng-training-ticket-video-tearoff',
  templateUrl: './tearoff.component.html',
  styleUrls: ['./tearoff.component.sass'],
})
export class TearoffComponent {

  @Input() ticket: TrainingTicket;
  
}
