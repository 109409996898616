import { inject, Inject, Injectable } from '@angular/core';
import { SessionServiceAdapter } from './session.service.adapter';
import { StateFacade, StateService } from '../../state/state.service';
import { CONSULTING_SESSION, ConsultingSession, ConsultingSessionCancelAction, ConsultingSessionCancelPayload, ConsultingSessionConfirmAction, ConsultingSessionConfirmPayload, ConsultingSessionFinishAction, ConsultingSessionFinishPayload, ConsultingSessionGetAction, ConsultingSessionGetPayload, ConsultingSessionListAction, ConsultingSessionListPayload, ConsultingSessionRequestAction, ConsultingSessionRequestPayload, ConsultingSessionStartAction, ConsultingSessionStartPayload } from '@bcx/models';
import { BACKEND_ADAPTER, BackendAdapter, BackendAdapterMap, IdService } from '@bcx/ng-helpers';
import { RestService } from './rest.service';
import { WebsocketService } from './websocket.service';
import { consultingSession as schemas } from '@bcx/schemas';
import { PartialDeep } from 'type-fest';
import { List } from '@digitaltoolbuilders/model-helpers';

export function createStateId(input: Pick<ConsultingSession, 'session_id'>) {

  return ConsultingSession.generateModelId(input);

}

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private adapter: SessionServiceAdapter;

  private state: StateFacade<ConsultingSession, List<ConsultingSession>>;

  constructor(
    @Inject(BACKEND_ADAPTER) ba: BackendAdapter,
    private id: IdService,
    state: StateService,
  ) { 

    switch (ba) {

      case BackendAdapterMap.REST:

        this.adapter = inject(RestService);

        break;

      case BackendAdapterMap.WEBSOCKET:

        this.adapter = inject(WebsocketService);

        break;

      default:

        throw new Error(`unknown adapter: ${ba}`);

    }

    this.state = state.facade<ConsultingSession, List<ConsultingSession>>(CONSULTING_SESSION, { createStateId });

  }

  cancel(original: ConsultingSession, data: PartialDeep<ConsultingSessionCancelPayload>, now = Date.now()) {

    data.last_updated_epoch = original.last_updated_epoch;
    data.session_id = original.session_id;

    return schemas.cancel.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new ConsultingSessionCancelAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.cancel(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  confirm(original: ConsultingSession, data: Partial<ConsultingSessionConfirmPayload>, now = Date.now()) {

    data.last_updated_epoch = original.last_updated_epoch;
    data.session_id = original.session_id;

    return schemas.confirm.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new ConsultingSessionConfirmAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.confirm(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  createId() {

    return this.id.alphanumeric(12);

  }

  finish(original: ConsultingSession, data: Partial<ConsultingSessionFinishPayload>, now = Date.now()) {

    data.last_updated_epoch = original.last_updated_epoch;
    data.session_id = original.session_id;

    return schemas.finish.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new ConsultingSessionFinishAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.finish(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  get(data: Partial<ConsultingSessionGetPayload>, now = Date.now()) {

    return schemas.get.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new ConsultingSessionGetAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        loading: true,
      });

      return this.adapter.get(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          loading: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          loading: false,
        });

        throw e;

      });

    });

  }

  list(data: Partial<ConsultingSessionListPayload>, now = Date.now()) {

    return schemas.list.validateAsync(data, {})
    .then((clean) => {

      const group = 'all';

      const action = new ConsultingSessionListAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: group,
        payload: clean,
        user_id: '',
      });

      this.state.many({
        group,
        loading: true,
      });

      return this.adapter.list(action)
      .then((event) => {

        const list = event.payload;

        this.state.many({
          group,
          last: list.last_evaluated_key,
          list: list.list,
          loading: false,
        });

        return list;

      })
      .catch((e) => {

        this.state.many({
          e,
          group,
          loading: false,
        });

        throw e;

      });

    });

  }

  request(data: Partial<ConsultingSessionRequestPayload>, now = Date.now()) {

    return schemas.request.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new ConsultingSessionRequestAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.request(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

  start(original: ConsultingSession, data: Partial<ConsultingSessionStartPayload>, now = Date.now()) {

    data.last_updated_epoch = original.last_updated_epoch;
    data.session_id = original.session_id;

    return schemas.start.validateAsync(data, {})
    .then((clean) => {

      const id = createStateId(clean);

      const action = new ConsultingSessionStartAction({
        action_id: this.id.uuid(),
        epoch: now,
        model_id: id,
        payload: clean,
        user_id: '',
      });

      this.state.single({
        id,
        processing: true,
      });

      return this.adapter.start(action)
      .then((event) => {

        const model = event.payload;

        this.state.single({
          id,
          model,
          processing: false,
        });

        return model;

      })
      .catch((e) => {

        this.state.single({
          e,
          id,
          processing: false,
        });

        throw e;

      });

    });

  }

}
