import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CancelDialogComponent } from './cancel-dialog/cancel-dialog.component';
import { CancelFormComponent } from './cancel-form/cancel-form.component';
import { CardComponent } from './card/card.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CreateDialogComponent } from './create-dialog/create-dialog.component';
import { CreateFormComponent } from './create-form/create-form.component';
import { FinishDialogComponent } from './finish-dialog/finish-dialog.component';
import { FinishFormComponent } from './finish-form/finish-form.component';
import { ListCardsComponent } from './list-cards/list-cards.component';
import { ListTableComponent } from './list-table/list-table.component';
import { MySeatComponent } from './my-seat/my-seat.component';
import { RequestFormComponent } from './request-form/request-form.component';
import { SeatingChartComponent } from './seating-chart/seating-chart.component';
import { SelectStartComponent } from './select-start/select-start.component';
import { SmartCardComponent } from './smart-card/smart-card.component';
import { StartDialogComponent } from './start-dialog/start-dialog.component';
import { StartFormComponent } from './start-form/start-form.component';
import { SummaryComponent } from './summary/summary.component';
import { SummaryCardComponent } from './summary-card/summary-card.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgPickerModule } from '@bcx/ng-helpers';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { NgCalendarModule } from '@engagedcx/ng-calendar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NativeDateModule } from '@angular/material/core';
import { SeatModule } from '../seat/seat.module';
import { MatTableModule } from '@angular/material/table';
import { ConfirmFormComponent } from './confirm-form/confirm-form.component';

@NgModule({
  declarations: [
    CancelDialogComponent,
    CancelFormComponent,
    CardComponent,
    ConfirmDialogComponent,
    ConfirmFormComponent,
    CreateDialogComponent,
    CreateFormComponent,
    FinishDialogComponent,
    FinishFormComponent,
    ListCardsComponent,
    ListTableComponent,
    MySeatComponent,
    RequestFormComponent,
    SeatingChartComponent,
    SelectStartComponent,
    SmartCardComponent,
    StartDialogComponent,
    StartFormComponent,
    SummaryComponent,
    SummaryCardComponent,
  ],
  exports: [
    CancelDialogComponent,
    CancelFormComponent,
    CardComponent,
    ConfirmDialogComponent,
    ConfirmFormComponent,
    CreateDialogComponent,
    CreateFormComponent,
    FinishDialogComponent,
    FinishFormComponent,
    ListCardsComponent,
    ListTableComponent,
    MySeatComponent,
    RequestFormComponent,
    SeatingChartComponent,
    SelectStartComponent,
    SmartCardComponent,
    StartDialogComponent,
    StartFormComponent,
    SummaryComponent,
    SummaryCardComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTableModule,
    NativeDateModule,
    NgCalendarModule,
    NgPickerModule,
    ReactiveFormsModule,
    SeatModule,
  ]
})
export class ClassModule { }
