<div fxLayout="column"
  *ngIf="settings">

  <mat-card>

    <mat-card-header 
      *ngIf="title">

      <mat-card-title>{{title}}</mat-card-title>
      <mat-card-subtitle *ngIf="subtitle">{{subtitle}}</mat-card-subtitle>

    </mat-card-header>

    <mat-card-content fxLayout="column" fxLayoutGap="10px">

      <span>Currency: {{settings.default_currency}}</span>

      <div>

        <span *ngIf="!paymentMethod">Default Payment Method: {{settings.default_payment_method}}</span>
<!-- 
        <ng-stripe-payment-method-label 
          *ngIf="paymentMethod"
          [paymentMethod]="paymentMethod"></ng-stripe-payment-method-label> -->
        
      </div>

    </mat-card-content>

    <mat-card-actions>

      <ng-container *ngTemplateOutlet="actions; context: { paymentMethod, settings }"></ng-container>

    </mat-card-actions>

  </mat-card>

</div>
