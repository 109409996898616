
export const AED = 'aed';
export const AFN = 'afn';
export const ALL = 'all';
export const AMD = 'amd';
export const ANG = 'ang';
export const ARS = 'ars';
export const AUD = 'aud';
export const AWG = 'awg';
export const AZN = 'azn';
export const BAM = 'bam';
export const BBD = 'brd';
export const BDT = 'bdt';
export const BGN = 'bgn';
export const BHD = 'bhd';
export const BIF = 'bif';
export const BMD = 'bmd';
export const BND = 'bnd';
export const BOB = 'bob';
export const BRL = 'brl';
export const BSD = 'bsd';
export const CAD = 'cad';
export const CLP = 'clp';
export const DJF = 'djf';
export const GNF = 'gnf';
export const HUF = 'huf';
export const INR = 'inr';
export const ISK = 'isk';
export const JPY = 'jpy';
export const JOD = 'jod';
export const KMF = 'kmf';
export const KRW = 'krw';
export const KWD = 'kwd';
export const MGA = 'mga';
export const OMR = 'omr';
export const PYG = 'pyg';
export const RWF = 'rwf';
export const TND = 'tnd';
export const TWD = 'twd';
export const UGX = 'ugx';
export const VND = 'vnd';
export const VUV = 'vuv';
export const XAF = 'xaf';
export const XOF = 'xof';
export const XPF = 'xpf';
export const USD = 'usd';

export const CurrencyMap = {
  CAD,
  USD,
} as const;

export type Currency = typeof CurrencyMap[keyof typeof CurrencyMap];

export const Currencies = Object.values(CurrencyMap);

export const CurrencyCodesMap: {[key: string]: { decimal?: string, iso: string, hex: string, html?: string }} = {} as const;

CurrencyCodesMap[CAD] = {
  decimal: 'C&#36;',
  hex: 'C&#x24;',
  iso: CAD,
};
CurrencyCodesMap[USD] = {
  decimal: '&#36;',
  hex: '&#x0024;',
  html: '',
  iso: USD
};
