<div class="ng-training-payment-agreement-debtor-form" fxLayout="column"
  *ngIf="form"
  [formGroup]="form">

  <mat-form-field>

    <mat-label>Legal Name</mat-label>

    <input type="text"
      formControlName="legal_name"
      matInput>

    <mat-error>Please enter your legal name, it is required.</mat-error>

  </mat-form-field>

  <div fxLayout="column"
    fxLayout.gt-sm="row">

    <ng-picker-intl-phone-number fxFlex
      [control]="phoneNumberControl"></ng-picker-intl-phone-number>

    <mat-form-field fxFlex>
  
      <mat-label>Email Address</mat-label>
  
      <input type="email"
        formControlName="email"
        matInput>
  
      <mat-error>Please enter your email address, it is required.</mat-error>
  
    </mat-form-field>
  
  </div>

  <ng-billing-address-form 
    [form]="addressForm"></ng-billing-address-form>

</div>