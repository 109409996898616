<div class="flex flex-col">

  <div class="flex flex-col md:flex-row">

    <app-owner-consulting-card>

      <a color="primary"
        mat-raised-button
        routerLink="/consulting/schedule-meeting">Schedule Meeting</a>

    </app-owner-consulting-card>

    <app-recommendation-list></app-recommendation-list>
  
  </div>

</div>
