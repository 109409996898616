<div fxLayout="column"
  *ngIf="form"
  [formGroup]="form">

  <ng-picker-datetime-picker 
    [control]="finishedEpoch" 
    label="Finished"
    [returnEpoch]="true"></ng-picker-datetime-picker>

  <mat-checkbox formControlName="finished_content">Did you finish all the content?</mat-checkbox>

  <div fxLayout="column"
    *ngIf="!finishedContent.value">

    <div fxLayout="column"
      fxLayout.gt-sm="row">

      <mat-form-field fxFlex>

        <mat-label>Last Slide Number</mat-label>
    
        <input type="number" min="1" step="1" 
          formControlName="last_slide_number"
          matInput />
    
        <mat-hint>Enter the last slide number that was taught.</mat-hint>
    
        <mat-error>Please enter the last slide number that was taught.</mat-error>
    
      </mat-form-field>
    
      <mat-form-field fxFlex>
  
        <mat-label>Last Page Number</mat-label>
    
        <input type="number" min="1" step="1" 
          formControlName="last_page_number"
          matInput />
    
        <mat-hint>Enter the last page number that was taught.</mat-hint>
    
        <mat-error>Please enter the last page number that was taught.</mat-error>
    
      </mat-form-field>

    </div>
  
    <mat-form-field>

      <mat-label>Last Paragraph</mat-label>
  
      <textarea 
        formControlName="last_paragraph"
        matInput></textarea>
  
      <mat-hint>Copy and paste the last paragraph that was taught.</mat-hint>
  
      <mat-error>Please copy and paste the last paragraph that was taught.</mat-error>
  
    </mat-form-field>
  
  </div>

</div>
