import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonthModule } from './month/month.module';
import { WeekModule } from './week/week.module';
import { DayModule } from './day/day.module';
import { EventModule } from './event/event.module';
import { OfficeHoursModule } from './office-hours/office-hours.module';

export { BeginsEnds } from './common'; 
export { CalendarDay } from './day/day';
export { CalendarEvent } from './event/event';
export { CalendarWeek } from './week/week';
export { OfficeHoursService } from './office-hours/office-hours.service';

@NgModule({
  imports: [
    CommonModule,
    DayModule,
    EventModule,
    MonthModule,
    OfficeHoursModule,
    WeekModule,
  ],
  exports: [
    DayModule,
    EventModule,
    MonthModule,
    OfficeHoursModule,
    WeekModule,
  ]
})
export class NgCalendarModule {}
