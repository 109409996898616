import { FormControl, FormGroup } from "@angular/forms";

export interface ProviderQuestionsMap {

  earn_commission_bonus?: boolean;

  industry?: string;

  length_in_industry?: string;

  length_in_position?: string;

}

export class ProviderQuestionsForm extends FormGroup {

  constructor() {

    super({
      earn_commission_bonus: new FormControl<boolean | undefined>(undefined, []),
      industry: new FormControl<string | undefined>(undefined, []),
      length_in_industry: new FormControl<string | undefined>(undefined, []),
      length_in_position: new FormControl<string | undefined>(undefined, []),
    });

  }

}
