import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ConsultingSessionAttendee } from '@bcx/models';

@Component({
  selector: 'ng-consulting-session-attendee-list-table',
  templateUrl: './list-table.component.html',
  styleUrls: ['./list-table.component.sass'],
})
export class ListTableComponent implements OnChanges {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  @Input() attendees: Array<ConsultingSessionAttendee>;

  @Input() display = [
    'attendee',
    'attending_as',
    'state',
    'actions',
  ];

  table = new MatTableDataSource<ConsultingSessionAttendee>([]);

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['attendees']) {

      this.table.data = this.attendees || [];

    }

  }

}
