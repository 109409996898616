<div class="ng-billing-settings-select-currency" fxLayout="column"
  *ngIf="control">

  <mat-form-field>

    <mat-label>{{label}}</mat-label>

    <mat-select
      [formControl]="control"
      matInput>

      <mat-option 
        *ngFor="let currency of currencies"
        [value]="currency">{{currency.toUpperCase()}}</mat-option>

    </mat-select>

    <mat-error>Please select a currency.</mat-error>

  </mat-form-field>

</div>
