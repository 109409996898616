import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { featureKey, reducer } from './state/invoice.reducer';
import { SummaryCardComponent } from './summary-card/summary-card.component';
import { MatCardModule } from '@angular/material/card';
import { FullDetailsComponent } from './full-details/full-details.component';
import { ListTableComponent } from './list-table/list-table.component';
import { PipesModule } from '../pipes/pipes.module';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [
    SummaryCardComponent,
    FullDetailsComponent,
    ListTableComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatTableModule,
    PipesModule,
    StoreModule.forFeature(featureKey, reducer),
  ],
  exports: [SummaryCardComponent, FullDetailsComponent, ListTableComponent],
})
export class InvoiceModule {}
