import { Component, Input, TemplateRef } from '@angular/core';
import { ConsultingSession } from '@bcx/models';

@Component({
  selector: 'ng-consulting-session-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.sass'],
})
export class SummaryCardComponent {

  // eslint-disable-next-line
  @Input() actions: TemplateRef<any>;

  @Input() session?: ConsultingSession;

}
