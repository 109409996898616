import { Component, OnChanges } from '@angular/core';
import { CreateFormComponent } from '../create-form/create-form.component';

@Component({
  selector: 'ng-consulting-session-options-form',
  templateUrl: './options-form.component.html',
  styleUrls: ['./options-form.component.sass'],
})
export class OptionsFormComponent extends CreateFormComponent implements OnChanges {

  

}
