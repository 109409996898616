
import { STRIPE_SUBSCRIPTION } from '@bcx/models';
import { createAction, props } from '@ngrx/store';
import { Stripe } from 'stripe';

export interface LoadSubscriptionsProps {

  data?: Array<Stripe.Subscription>;

  error?: string;

  last?: string;

  loading: boolean;

}

export const loadMySubscriptions = createAction(
  `[${STRIPE_SUBSCRIPTION}] Load My Subscriptions`,
  props<LoadSubscriptionsProps>(),
);

export interface SubscriptionProps {

  subscription?: Stripe.Subscription;

  error?: string;

  id: string;

  loading: boolean;

  processing: boolean;

}

export const loadSubscription = createAction(
  `[${STRIPE_SUBSCRIPTION}] Load Subscription`,
  props<SubscriptionProps>(),
);

export const loadAllSubscriptions = createAction(
  `[${STRIPE_SUBSCRIPTION}] Load All Subscriptions`,
  props<LoadSubscriptionsProps>(),
);
