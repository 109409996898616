import { Component, Input } from '@angular/core';
import { RemoveForm } from './remove-form.form';
import { ConsultingSessionAttendee } from '@bcx/models';

@Component({
  selector: 'ng-consulting-session-attendee-remove-form',
  templateUrl: './remove-form.component.html',
  styleUrls: ['./remove-form.component.sass'],
})
export class RemoveFormComponent {

  @Input() attendee: ConsultingSessionAttendee;

  @Input() form: RemoveForm;

}
