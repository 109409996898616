
import { Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { SignUpComponent } from './sign-up/sign-up.component';

export const routes: Routes = [
  {
    component: HomepageComponent,
    path: '',
  },
  {
    component: SignUpComponent,
    path: 'sign-up',
  },
];
