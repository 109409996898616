import { APP_INITIALIZER, FactoryProvider } from "@angular/core";
import { ConfigService } from "./config.service";

export { ConfigService };

export const AppConfigInitializer: FactoryProvider = {
  deps: [ConfigService],
  multi: true,
  provide: APP_INITIALIZER,
  useFactory: (service: ConfigService) => {

    return () => service.fetchConfigMap();

  },
};
