import { PersonContactPoint, PersonContactPointCreateAction, PersonContactPointCreatePayload } from "./contact-point";

export interface EmailAddressDetails {

  address: string;

  domain: string;

}

export class PersonEmailAddress extends PersonContactPoint<EmailAddressDetails> {



}

export type PersonEmailAddressCreatePayload = PersonContactPointCreatePayload & {
  details: EmailAddressDetails;
};

export type PersonEmailAddressCreateAction = PersonContactPointCreateAction & {
  payload: PersonEmailAddressCreatePayload;
};
