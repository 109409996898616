import { Component, Input, TemplateRef } from '@angular/core';
import { TrainingSeat } from '@bcx/models';

@Component({
  selector: 'ng-training-seat-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.sass'],
})
export class CardComponent {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  @Input() error?: string;

  @Input() loading: boolean;

  @Input() seat?: TrainingSeat;

}
