<mat-card 
  *ngIf="attendee">

  <mat-card-header>

    <mat-card-title>
      Attendee arriving {{attendee.scheduled.arrival_epoch | date:'MMM d @ h:mm a'}}
    </mat-card-title>

    <mat-card-subtitle>Session Id: {{attendee.session_id}}</mat-card-subtitle>

  </mat-card-header>

  <mat-card-content>

    <div fxLayout="column" fxLayoutGap="5px">

      <span>Attendee Status: {{attendee.status | capitalizeFirst}}</span>

    </div>

  </mat-card-content>

  <mat-card-actions>

    <ng-container *ngTemplateOutlet="actions; context: { attendee }"></ng-container>

  </mat-card-actions>

</mat-card>
