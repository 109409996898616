<div fxLayout="column"
  *ngIf="seat">

  <ng-template #internalActions>

    <ng-container *ngTemplateOutlet="actions; context: { seat }"></ng-container>

  </ng-template>

  <mat-card>

    <mat-card-header>

      <mat-card-title>Seat</mat-card-title>
      <mat-card-subtitle>{{seat.status}}</mat-card-subtitle>

    </mat-card-header>

    <mat-card-content>

      <ng-user-name [userId]="seat.user_id"></ng-user-name>

      <span>Requested: {{seat.created_epoch | date:'short'}}</span>

      <pre *ngIf="showMore">{{seat | json}}</pre>

    </mat-card-content>

    <mat-card-actions>

      <ng-container *ngTemplateOutlet="internalActions; context: { seat }"></ng-container>

      <button type="button"
        (click)="showMore=!showMore"
        mat-icon-button>
        <mat-icon>more_vert</mat-icon>
      </button>

    </mat-card-actions>

  </mat-card>

</div>
