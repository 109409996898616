<div class="ng-user-update-form"
  *ngIf="form"
  [formGroup]="form">

  <mat-form-field>

    <mat-label>Your Full Name</mat-label>

    <input type="text"
      formControlName="name"
      matInput>

    <mat-error>Please enter the your full name.</mat-error>

  </mat-form-field>

  <div fxLayout="column"
    fxLayout.gt-sm="row"
    *ngIf="false">

    <mat-form-field fxFlex>

      <mat-label>Your Given Name</mat-label>

      <input type="text"
        formControlName="given_name"
        matInput>

    </mat-form-field>

    <mat-form-field fxFlex>

      <mat-label>Your Family Name</mat-label>

      <input type="text"
        formControlName="family_name"
        matInput>

    </mat-form-field>

  </div>

  <ng-picker-timezone-selector
    [control]="localTimeZoneControl"
    label="Your Local Time Zone"></ng-picker-timezone-selector>

</div>
