import { Injectable } from '@angular/core';
import { ApiService } from '@engagedcx/ng-amplify';
import { AuthService } from '@engagedcx/ng-amplify';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Stripe } from 'stripe';
import { State } from './state/invoice.reducer';
import { selectAll, selectForCustomer, selectMyInvoices, selectOne } from './state/invoice.selectors';
import { props } from 'bluebird';
import { AxiosResponse } from 'axios';
import { loadAllInvoices, loadInvoice, loadMyInvoices } from './state/invoice.actions';
import { stripeInvoice as schemas } from '@bcx/schemas';
import { AllEntitiesState, ManyEntitiesState } from '@bcx/ng-helpers';
import { StripeInvoiceGetPayload, StripeInvoiceListPayload } from '@bcx/models';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  all$: Observable<Omit<AllEntitiesState<Stripe.Invoice>, 'last_evaluated_key'> & { last_evaluated_key?: string }>;

  basePath = '/stripe/invoices';

  my$: Observable<Omit<ManyEntitiesState<Stripe.Invoice>, 'last_evaluated_key'> & {
    last_evaluated_key?: string;
  }>;

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private store: Store<State>,
  ) { 

    this.all$ = this.store.select(selectAll);

    this.my$ = this.store.select(selectMyInvoices);

  }

  get(data: StripeInvoiceGetPayload) {

    return props({
      clean: schemas.get.validateAsync(data, {}),
      creds: this.auth.waitForCredentials(),
    })
    .then(({ clean }) => {

      const path = `${this.basePath}/${clean.id}`;

      this.store.dispatch(loadInvoice({
        id: clean.id,
        loading: true,
      }));

      return this.api.get(path, clean)
      .then((response: AxiosResponse<Stripe.Invoice>) => {

        const invoice = response.data;

        this.store.dispatch(loadInvoice({
          id: clean.id,
          invoice,
          loading: false,
        }));
  
        return invoice;

      })
      .catch((e: Error) => {

        this.store.dispatch(loadInvoice({
          error: e.message,
          id: clean.id,
          loading: false,
        }));
  
        throw e;

      });

    });

  }

  list(data: StripeInvoiceListPayload) {

    return props({
      clean: schemas.list.validateAsync(data, {}),
      creds: this.auth.waitForCredentials(),
    })
    .then(({ clean }) => {

      const path = '/stripe/invoices';

      this.store.dispatch(loadAllInvoices({
        loading: true,
      }));

      return this.api.get(path, clean)
      .then((response: AxiosResponse<{ data:Array<Stripe.Invoice>, has_more: boolean }>) => {

        const result = response.data;

        let last: string | undefined;

        if (result.has_more) {

          last = result.data[result.data.length - 1].id;

        }

        this.store.dispatch(loadAllInvoices({ 
          data: result.data,
          last,
          loading: false,
         }));

        return result;

      })
      .catch((e: Error) => {

        this.store.dispatch(loadAllInvoices({
          error: e.message,
          loading: false,
        }));

        throw e;

      });

    });

  }

  listForCustomer(data: StripeInvoiceListPayload) {

    return this.list(data);

  }

  my() {

    return props({
      authed: this.auth.waitForIdentity(),
    })
    .then(() => {

      const path = '/stripe/my-invoices';

      this.store.dispatch(loadMyInvoices({
        loading: true,
      }));

      return this.api.get(path)
      .then((response: AxiosResponse<{ data:Array<Stripe.Invoice>, has_more: boolean }>) => {

        const result = response.data;

        this.store.dispatch(loadMyInvoices({ 
          data: result.data,
          loading: false, 
        }));

        return result;

      })
      .catch((e: Error) => {

        this.store.dispatch(loadMyInvoices({
          error: e.message,
          loading: false,
        }));

        throw e;

      });

    });

  }

  select(id: string) {

    return this.store.select(selectOne(id));

  }

  selectForCustomer(customer: string) {

    return this.store.select(selectForCustomer(customer));

  }

}
