import { Inject, Injectable, InjectionToken } from '@angular/core';
import pino, { Logger } from 'pino';

export const PINO_LOG_LEVEL = new InjectionToken<string>('PINO_LOG_LEVEL', {
  factory: () => {

    return 'info';

  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LoggingAny = any;

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  private logger: Logger;

  constructor(
    @Inject(PINO_LOG_LEVEL) public level: string,
  ) { 

    this.logger = pino({
      level: this.level,
    });

  }

  child(bindings: pino.Bindings, options?: pino.ChildLoggerOptions<never>) {

    return this.logger.child(bindings, options);

  }

  debug(obj: object | string, msg?: string, ...args: Array<LoggingAny>) {

    return this.logger.debug(obj, msg, ...args);

  }

  error(obj: object | string, msg?: string) {

    return this.logger.error(obj, msg);

  }

  info(obj: object | string, msg?: string, ...args: Array<LoggingAny>) {

    return this.logger.info(obj, msg, ...args);

  }

}