import { Injectable, OnDestroy } from '@angular/core';
import { AppRegionalConfig } from '@bcx/iso';
import { ConfigService, Deferred } from '@bcx/ng-helpers';
import { loadStripe, Stripe as StripeJs, StripeElements, StripeElementsOptions } from '@stripe/stripe-js';
import { Subscription } from 'rxjs';
import { Stripe } from 'stripe';

const PUBLIC_KEY = 'pk_test_51Li4G4GNlpGQfY7tGFjRMx93zK0L0CODCdNWf4jYwcDTnPrFzle6ahXfTZzvqfDBlUAzc4khqTqDMRwJC3DCSvwr00i0bNOEjB';

@Injectable({
  providedIn: 'root'
})
export class StripeService implements OnDestroy {

  clientDeferred: Deferred<StripeJs,Error>;

  elements: StripeElements;

  publicKey: string = PUBLIC_KEY;

  stripe: StripeJs;

  private subs: {[key:string]:Subscription} = {};

  constructor(private config: ConfigService) { 

    this.subs['config'] = this.config.active$
    .subscribe((config) => {

      this.onConfig(config);

    });

    this.clientDeferred = new Deferred();

  }

  confirmSetupIntent(data: {
    return_url: string
  }) {

    // console.log('StripeService.confirmSetupIntent', data);

    return this.getStripeClient()
    .then((stripe) => {

      // eslint-disable-next-line
      const params: any = {
        elements: this.elements,
        confirmParams: {
          return_url: `https://${location.host}${data.return_url}`
        },
        redirect: 'always',
      };

      // console.log('stripe.confirmSetup', params);

      return stripe.confirmSetup(params);

    })
    .then((result) => {

      // console.log('StripeService.confirmSetup', result);

      if (result.error) {

        throw result.error;

      }

      // console.log('StripeService.confirmSetup', result);

    });

  }

  createPaymentElement(intent: Stripe.PaymentIntent | Stripe.SetupIntent, options: StripeElementsOptions = {}) {

    options.clientSecret = intent.client_secret as string;

    return this.getStripeClient()
    .then((stripe: StripeJs) => {

      this.elements = stripe.elements(options);

      return this.elements.create('payment');

    });

  }

  getStripeClient() {

    return this.clientDeferred.promise;

  }

  private loadStripeClient() {

    loadStripe(this.publicKey)
    .then((stripe: StripeJs | null) => {

      if (stripe) {

        this.stripe = stripe;

        this.clientDeferred.resolve(this.stripe);

      } else {

        this.clientDeferred.reject(new Error('No stripe Client'));

      }

    });

  }

  ngOnDestroy(): void {
    
    Object.values(this.subs).forEach((sub: Subscription) => sub.unsubscribe());

  }

  private onConfig(config: Partial<AppRegionalConfig>) {

    if (config.stripe) {

      this.publicKey = config.stripe.publicKey;

      if (this.publicKey) {

        this.loadStripeClient();

      }

    }

  }

  retrieveSetupIntent(secret: string) {

    return this.getStripeClient()
    .then((stripe: StripeJs) => {

      return stripe.retrieveSetupIntent(secret);

    })
    .then(({ error, setupIntent }) => {

      console.log('retrieveSetupIntent', setupIntent);

      if (error) {

        throw error;

      }

      return setupIntent;

    });
  
  }

}
