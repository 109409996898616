import { Component, Inject } from '@angular/core';
import { SubsComponent } from '@bcx/ng-helpers';
import { SessionService } from '../session.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CancelForm } from '../cancel-form/cancel-form.form';
import { ConsultingSession } from '@bcx/models';

@Component({
  selector: 'ng-consulting-cancel-dialog',
  templateUrl: './cancel-dialog.component.html',
  styleUrls: ['./cancel-dialog.component.sass'],
})
export class CancelDialogComponent extends SubsComponent {

  error?: string;

  form: CancelForm;

  processing: boolean;

  session: ConsultingSession;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { session: ConsultingSession },
    private dialog: MatDialogRef<CancelDialogComponent>,
    private service: SessionService,
  ) {

    super();

    this.session = data.session;

    this.form = new CancelForm();

  }

  closeDialog() {

    this.dialog.close();

  }

  submit() {

    this.service.cancel(this.session, {
      cancellation: {
        reason:this.form.value.reason
      },
    })
    .then((canceled: ConsultingSession) => {

      this.dialog.close(canceled);

    })
    .catch((e: Error) => {

      console.error(e);

    });

  }

}
