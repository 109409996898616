import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { featureKey, reducer } from './state/reducer';
import { PriceComponent } from './price/price.component';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [PriceComponent],
  imports: [
    CommonModule, 
    PipesModule,
    StoreModule.forFeature(featureKey, reducer),
  ],
  exports: [PriceComponent],
})
export class PriceModule {}
