<mat-card *ngIf="!seat">

  <mat-card-content>

    <mat-spinner *ngIf="loading"></mat-spinner>

    <span *ngIf="!error">{{error}}</span>

  </mat-card-content>

</mat-card>

<mat-card *ngIf="seat">

  <mat-card-header>

    <mat-card-title>
      <span>Training Seat</span>
      <span fxFlex></span>
      <span>{{seat.seat_id}}</span>
    </mat-card-title>
    <mat-card-subtitle>{{seat.status}}</mat-card-subtitle>

  </mat-card-header>

  <mat-card-content>

    <dl><dt>Last Updated:</dt><dd>{{seat.last_updated_epoch | date:'short'}}</dd></dl>
    <dl><dt>Class Id:</dt><dd>{{seat.class_id}}</dd></dl>
    <dl><dt>Ticket Id:</dt><dd>{{seat.ticket_id}}</dd></dl>
    <dl><dt>User Id:</dt><dd>{{seat.user_id}}</dd></dl>

    <pre *ngIf="false">{{seat | json}}</pre>

  </mat-card-content>

  <mat-card-actions>

    <ng-container *ngTemplateOutlet="actions; context: { seat }"></ng-container>

  </mat-card-actions>

</mat-card>
