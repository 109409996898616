<div fxLayout="column">

  <table mat-table [dataSource]="table">
    <ng-container matColumnDef="actual_finish">
      <th mat-header-cell *matHeaderCellDef>Actual Finish</th>
      <td mat-cell *matCellDef="let session">
        {{session.actual_finish_epoch | date:'short'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="actual_length">
      <th mat-header-cell *matHeaderCellDef>Actual Length</th>
      <td mat-cell *matCellDef="let session">
        {{session.actual_length_minutes}} mins
      </td>
    </ng-container>
    <ng-container matColumnDef="actual_start">
      <th mat-header-cell *matHeaderCellDef>Actual Start</th>
      <td mat-cell *matCellDef="let session">
        {{session.actual_start_epoch | date:'short'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="last_updated">
      <th mat-header-cell *matHeaderCellDef>Last Updated</th>
      <td mat-cell *matCellDef="let session">
        {{session.last_updated_epoch | date:'short'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="scheduled_length">
      <th mat-header-cell *matHeaderCellDef>Scheduled Length</th>
      <td mat-cell *matCellDef="let session">
        {{session.scheduled_length_minutes}} mins
      </td>
    </ng-container>
    <ng-container matColumnDef="scheduled_start">
      <th mat-header-cell *matHeaderCellDef>Schedule Start</th>
      <td mat-cell *matCellDef="let session">
        {{session.scheduled_start_epoch | date:'short'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="session_id">
      <th mat-header-cell *matHeaderCellDef>Session Id</th>
      <td mat-cell *matCellDef="let session">{{session.session_id}}</td>
    </ng-container>
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>State</th>
      <td mat-cell *matCellDef="let session">{{session.state}}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{actionsLabel}}</th>
      <td mat-cell *matCellDef="let session">
        <ng-container *ngTemplateOutlet="actions; context: { session }"></ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="display"></tr>
    <tr mat-row *matRowDef="let session; columns: display"></tr>

    <tr class="mat-row" *matNoDataRow><td [attr.colspan]="display.length">None</td></tr>
  </table>

</div>
