import { Address, Currencies, Locales } from "@bcx/models";
import Joi from "joi";

export const city = Joi.string();

export const country = Joi.string();

export const currency = Joi.string().allow(...Currencies);

export const locale = Joi.string().allow(...Locales);

export const postal_code = Joi.string();

export const state = Joi.string();

export const street = Joi.string();

export const town = Joi.string();

export const unit = Joi.string();

export const address = Joi.object<Address>()
  .keys({
    city: city.required(),
    country: country.required(),
    postal_code: postal_code.required(),
    state: state.required(),
    street: street.required(),
    town: town.optional().allow(null),
    unit: unit.optional().allow(null),
  });

export const date_of_birth = Joi.string();

export const email = Joi.string().email({
  tlds: false,
});

export const phone_number = Joi.string().max(44).min(5);

export const reason = Joi.string().max(250);

export const user_id = Joi.string().max(200);
