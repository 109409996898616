import { StripeSubscriptionActionNameMap, StripeSubscriptionGetAction, StripeSubscriptionGetPayload, StripeSubscriptionListAction, StripeSubscriptionListPayload } from "@bcx/models";
import Joi from "joi";
import { subscription_id } from "./common";
import { createActionSchema } from "../model-action";

export const get = Joi.object<StripeSubscriptionGetPayload>()
  .keys({
    id: subscription_id.required(),
  });

export const getAction = createActionSchema<StripeSubscriptionGetAction>(StripeSubscriptionActionNameMap.GET, get);

export const list = Joi.object<StripeSubscriptionListPayload>()
  .keys({
    
  });

export const listAction = createActionSchema<StripeSubscriptionListAction>(StripeSubscriptionActionNameMap.LIST, list);
