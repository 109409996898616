import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Currencies } from '@bcx/models';

@Component({
  selector: 'ng-picker-select-currency',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.sass'],
})
export class SelectComponent {

  @Input() control: FormControl;

  currencies = Currencies;

}
