import { Component, Input, } from '@angular/core';
import { IntlPhoneNumberControl } from './intl.control';

@Component({
  selector: 'ng-picker-intl-phone-number',
  templateUrl: './intl.component.html',
  styleUrls: ['./intl.component.sass'],
})
export class IntlComponent {

  @Input() control: IntlPhoneNumberControl;

  @Input() error?: string;

  @Input() hint?: string;



}
