
export const RegionMap = {
  US_EAST_1: 'us-east-1',
  US_WEST_2: 'us-west-2',
} as const;

export type Region = typeof RegionMap[keyof typeof RegionMap];

export const Regions = Object.values(RegionMap);

export const DEFAULT_REGION = RegionMap.US_WEST_2;
