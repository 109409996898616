<div class="ng-user-update-form"
  *ngIf="form"
  [formGroup]="form">

  <mat-form-field>

    <mat-label>User's Full Name</mat-label>

    <input type="text"
      formControlName="name"
      matInput>

    <mat-error>Please enter the user's name.</mat-error>

  </mat-form-field>

  <div fxLayout="column"
    fxLayout.gt-sm="row">

    <mat-form-field fxFlex>

      <mat-label>User's Given Name</mat-label>

      <input type="text" 
        formControlName="given_name"
        matInput>

    </mat-form-field>

    <mat-form-field fxFlex>

      <mat-label>User's Family Name</mat-label>

      <input type="text"
        formControlName="family_name"
        matInput>

    </mat-form-field>

  </div>

  <mat-form-field>

    <mat-label>User's Email Address</mat-label>

    <input type="email"
      formControlName="email"
      matInput>

    <mat-error>Please enter a valid email address.</mat-error>

  </mat-form-field>

  <mat-form-field>

    <mat-label>User's Phone Number</mat-label>

    <input type="text"
      formControlName="phone_number"
      matInput>

  </mat-form-field>

  <ng-picker-timezone-selector
    [control]="localTimeZoneControl"
    label="User's Local Time Zone"></ng-picker-timezone-selector>

</div>
