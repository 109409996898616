<div class="ng-calendar-month-navigation" fxLayout="row" fxLayoutAlign="space-between">

  <button type="button" color="primary"
    *ngIf="isAfterMin"
    (click)="previous()"
    mat-icon-button>
    <mat-icon>arrow_back_ios</mat-icon>
  </button>
  <span *ngIf="!isAfterMin"></span>

  <div fxLayout="row" fxLayoutAlign="center center">

    <span>{{monthName}} {{year}}</span>

  </div>

  <button type="button" color="primary"
    *ngIf="isBeforeMax"
    (click)="next()"
    mat-icon-button>
    <mat-icon>arrow_forward_ios</mat-icon>
  </button>
  <span *ngIf="!isBeforeMax"></span>

</div>
