import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Amplify } from 'aws-amplify';
import { DEFAULT_LOGGER } from './logger';
import { Logger } from 'pino';
import { ConfigService } from '@bcx/ng-helpers';
import { AmplifyConfig } from '@bcx/iso';

@Injectable({
  providedIn: 'root'
})
export class AmplifyService implements OnDestroy {

  private logger: Logger;

  private subs: { [key: string]: Subscription } = {};

  constructor(
    private config: ConfigService,
    @Inject(DEFAULT_LOGGER) parent: Logger,
  ) { 

    this.logger = parent.child({
      service: 'AmplifyService',
    });

  }

  init() {

    this.subs['config'] = this.config.active$
    .subscribe(({ amplify }) => {

      if (amplify) {

        // eslint-disable-next-line
        const configuration: any = {
          ...amplify as AmplifyConfig
        };

        Amplify.configure(configuration);

        this.logger.debug('configured successfully', configuration);

        // Analytics.autoTrack('session', {
        //   enable: true,
        //   attributes: () => {
  
        //     return {
        //       'test-attribute': 'session'
        //     };
  
        //   },
        //   provider: 'AWSPinpoint',
        // });
  
        // Analytics.autoTrack('pageView', {
        //   enable: true,
        //   eventName: 'pageView',
        //   attributes: () => {
  
        //     return {
        //       'test-attribute': 'pageView'
        //     };
  
        //   },
        //   type: 'SPA',
        //   provider: 'AWSPinpoint',
        // });
  
        // Analytics.autoTrack('event', {
        //   enable: true,
        //   events: ['click'],
        //   selectorPrefix: 'data-amplify-analytics-',
        //   provider: 'AWSPinpoint',
        //   attributes: () => {
  
        //     return {
        //       'test-attribute': 'event',
        //     };
  
        //   }
        // });
  
      } else {

        this.logger.debug('no `amplify` key in config');

      }

    });

  }

  ngOnDestroy(): void {
    
    Object.values(this.subs).forEach(sub => sub.unsubscribe());

  }

}
