import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateModule } from './date/date.module';
import { LengthModule } from './length/length.module';
import { TimeModule } from './time/time.module';
import { DatetimeModule } from './datetime/datetime.module';
import { TimezoneModule } from './timezone/timezone.module';
import { PhoneNumberModule } from './phone-number/phone-number.module';
import { CountryModule } from './country/country.module';
import { CurrencyModule } from './currency/currency.module';

export * from './phone-number/phone-number.exports';

@NgModule({
  exports: [
    CountryModule,
    CurrencyModule,
    DateModule,
    DatetimeModule,
    LengthModule,
    PhoneNumberModule,
    TimeModule,
    TimezoneModule,
  ],
  imports: [
    CommonModule,
    CountryModule,
    CurrencyModule,
    DateModule,
    DatetimeModule,
    LengthModule,
    PhoneNumberModule,
    TimeModule,
    TimezoneModule,
  ],
})
export class NgPickerModule {}
