
<form 
  [formGroup]="form"
  (ngSubmit)="submit()">

  <div fxLayout="row" fxLayoutAlign="space-between"
    mat-dialog-title>

    <h1 class="mat-headline">Add Payment Method</h1>

    <button type="button" color="warn"
      (click)="close()"
      mat-icon-button><mat-icon>close</mat-icon></button>

  </div>

  <mat-dialog-content>

    <div fxLayout="row" fxLayoutAlign="center center"
      *ngIf="!ready">

      <mat-spinner diameter="30"></mat-spinner>

    </div>

    <div id="stripe-payment-element"></div>

  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">

    <div fxLayout="column" fxLayoutAlign="space-between"
      fxLayout.gt-sm="row"
      *ngIf="error">

      <span>There was an error, please try again.</span>

      <button type="button"
        (click)="retry()"
        mat-raised-button>Retry</button>

    </div>

    <button type="submit" color="primary"
      [disabled]="form.invalid || processing"
      mat-raised-button>Add Payment Method</button>

  </mat-dialog-actions>

</form>
