import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SmallToBestGoalNames } from "@bcx/models";

export class SmallToBestGoalsForm extends FormGroup {

  constructor() {

    super({
      other: new FormControl<string | undefined>(undefined, []),
    });

    SmallToBestGoalNames.forEach((goal) => {

      this.addControl(goal, new FormControl<string>('', [Validators.required]));

    });

  }

}
