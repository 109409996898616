
import { on } from '@ngrx/store';
import { createImmerReducer } from 'ngrx-immer/store';
import { loadAllInvoices, loadInvoice, loadMyInvoices } from './invoice.actions';
import { Stripe } from 'stripe';
import { STRIPE_INVOICE } from '@bcx/models';
import { Entities, Entity, InternalManyEntitiesState } from '@bcx/ng-helpers';

export const featureKey = STRIPE_INVOICE;

export interface State {

  all: InternalManyEntitiesState<Stripe.Invoice, string>;

  entities: Entities<Stripe.Invoice>;

  my: Omit<InternalManyEntitiesState<Stripe.Invoice, string>, 'last_evaluated_key'> & {
    last_evaluated_key?: string;
  };

}

export const initialState: State = {
  all: {
    ids: [],
    loading: false,
  },
  entities: {},
  my: {
    ids: [],
    loading: false,
  }
};

export function createEntityId(id: string) {

  return `${id}`;

}

export function getEntity(id: string, state: State) {

  const entity: Entity<Stripe.Invoice> = state.entities[id] || {
    loaded: false,
    loading: false,
    processing: false,
  };

  return entity;

}

export const reducer = createImmerReducer(
  initialState,

  on(loadInvoice, (state: State, { error, id, invoice, loading }) => {

    const entityId = createEntityId(id);

    const entity = getEntity(id, state);

    entity.error = error;
    entity.loading = loading;

    if (invoice) {

      entity.model = invoice;
      entity.loaded = Date.now();

    }

    state.entities[entityId] = entity;

    return state;

  }),
  on(loadAllInvoices, (state: State, { error, last, loading, data }) => {

    state.all.error = error;
    state.all.loading = loading;

    if (data) {

      state.all.last = last;

      const loaded = Date.now();

      data.forEach((invoice: Stripe.Invoice) => {

        const id = createEntityId(invoice.id);

        const entity = getEntity(id, state);

        entity.model = invoice;
        entity.loaded = loaded;

        state.entities[id] = entity;

      });

    }

    return state;

  }),
  on(loadMyInvoices, (state: State, { data, error, loading, last }) => {

    state.my.error = error;
    state.my.loading = loading;


    if (data) {

      state.my.last = last;

      const loaded = Date.now();

      data.forEach((invoice: Stripe.Invoice) => {

        const id = createEntityId(invoice.id);

        const entity = getEntity(id, state);

        entity.model = invoice;
        entity.loaded = loaded;

        state.entities[id] = entity;

        if (!state.my.ids.includes(id)) {

          state.my.ids.push(id);

        }

      });

    }

    return state;

  }),

);
