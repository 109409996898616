import { Component, Input, TemplateRef } from '@angular/core';
import { ConsultingSessionAttendee } from '@bcx/models';

@Component({
  selector: 'ng-consulting-session-attendee-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.sass'],
})
export class SummaryCardComponent {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  @Input() attendee?: ConsultingSessionAttendee;

}
