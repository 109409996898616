import { Component, Input } from '@angular/core';
import {Stripe} from 'stripe';

@Component({
  selector: 'ng-stripe-charge-full-details',
  templateUrl: './full-details.component.html',
  styleUrls: ['./full-details.component.sass'],
})
export class FullDetailsComponent {

  @Input() charge: Stripe.Charge;

}
