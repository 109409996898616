import { Component, Input } from '@angular/core';
import { TrainingClass } from '@bcx/models';

@Component({
  selector: 'ng-training-class-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.sass'],
})
export class SummaryComponent {

  @Input() trainingClass: TrainingClass
  
}
