
import { on } from '@ngrx/store';
import { createImmerReducer } from 'ngrx-immer/store';
import { loadAllPrices, loadPrice, } from './actions';
import { Stripe } from 'stripe';
import { STRIPE_PRICE } from '@bcx/models';
import { Entities, Entity, InternalManyEntitiesState } from '@bcx/ng-helpers';

export const featureKey = STRIPE_PRICE;

export interface State {

  all: InternalManyEntitiesState<Stripe.Price, string>;

  entities: Entities<Stripe.Price>;

}

export const initialState: State = {
  all: {
    ids: [],
    loading: false,
  },
  entities: {},
};

export function createEntityId(id: string) {

  return `${id}`;

}

export function getEntity(id: string, state: State) {

  const entity: Entity<Stripe.Price> = state.entities[id] || {
    loaded: false,
    loading: false,
    processing: false,
  };

  return entity;

}

export const reducer = createImmerReducer(
  initialState,

  on(loadPrice, (state: State, { error, id, loading, price }) => {

    const entityId = createEntityId(id);

    const entity = getEntity(id, state);

    entity.error = error;
    entity.loading = loading;

    if (price) {

      entity.loaded = Date.now();
      entity.model = price;

    }

    state.entities[entityId] = entity;

    return state;

  }),
  on(loadAllPrices, (state: State, { error, loading, data }) => {

    state.all.error = error;
    state.all.loading = loading;

    if (data) {

      state.all.loaded = Date.now();
      
      data.forEach((price: Stripe.Price) => {

        const id = createEntityId(price.id);

        const entity = getEntity(id, state);

        entity.model = price;
        entity.error = error;
        entity.loaded = state.all.loaded;
        entity.loading = loading;

        state.entities[id] = entity;

      });

    }

    return state;

  }),

);
