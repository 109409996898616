<div class="flex flex-col">

  <div class="flex flex-col md:flex-row">

    <app-small-to-best-book-card>

      

    </app-small-to-best-book-card>
  
    <app-recommendation-list [product_id]="product_id"></app-recommendation-list>
  
  </div>

</div>
