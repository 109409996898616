<div class="flex flex-col main-footer">

  <div class="flex flex-row justify-between items-center">

    <a class="footer-list-item" 
      mat-button
      routerLink="/about-us">About Us</a>

    <a class="footer-list-item" 
      mat-button 
      routerLink="/contact-us">Contact Us</a>

    <a class="footer-list-item" 
      mat-button
      routerLink="/cookies">Cookies</a>

    <a class="footer-list-item" 
      mat-button
      routerLink="/privacy-policy">Privacy Policy</a>

    <a class="footer-list-item" 
      mat-button
      routerLink="/terms-of-service">Terms of Service</a>

  </div>

  <div class="flex flex-row justify-center items-center copyright">

    <span>Copyright &copy; 2021 - {{current_date | date:'YYYY'}}</span>

  </div>

</div>
