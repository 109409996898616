<div fxLayout="column"
  fxLayout.gt-md="row"
  *ngIf="control">

  <mat-form-field fxFlex>

    <mat-label>Session Length (Minutes)</mat-label>

    <input type="number"
      min="15" step="15"
      [formControl]="control"
      matInput>

    <mat-hint>Length in minutes.</mat-hint>

    <mat-error>Please enter the length in minutes.</mat-error>

  </mat-form-field>

  <div class="wrapper" fxFlex fxLayout="column" fxLayoutAlign="center" fxLayoutGap="10px"
    fxLayout.gt-sm="row">

    <button type="button"
      (click)="set1Hour()"
      mat-raised-button>1 Hour</button>

    <button type="button"
      (click)="set2Hour()"
      mat-raised-button>2 Hour</button>

    <button type="button"
      (click)="set1Day()"
      mat-raised-button>1 Day (12 hours)</button>

  </div>

  <mat-divider></mat-divider>

</div>
