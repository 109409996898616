import { ExclusiveStartKey } from "@bcx/models";
import Joi from "joi";

export const consistent_read = Joi.boolean().default(false).optional();

export const native_attribute_value = Joi.alternatives(
  Joi.boolean(),
  Joi.string(),
  Joi.number(),
);

export const exclusive_start_key = Joi.object<ExclusiveStartKey>()
  .pattern('*', native_attribute_value);

export const limit = Joi.number().integer().default(25).optional();

