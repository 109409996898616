<div fxLayout="column">

  <table mat-table [dataSource]="table">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let attendee">
        <ng-container *ngTemplateOutlet="actions; context: { attendee }"></ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="arrived">
      <th mat-header-cell *matHeaderCellDef>Arrived</th>
      <td mat-cell *matCellDef="let attendee">{{attendee.arrived_epoch | date:'short'}}</td>
    </ng-container>
    <ng-container matColumnDef="attendee">
      <th mat-header-cell *matHeaderCellDef>Attendee</th>
      <td mat-cell *matCellDef="let attendee">
        <!-- <ng-user-name [userId]="attendee.user_id"></ng-user-name> -->
      </td>
    </ng-container>
    <ng-container matColumnDef="attending_as">
      <th mat-header-cell *matHeaderCellDef>Attending As</th>
      <td mat-cell *matCellDef="let attendee">{{attendee.attending_as}}</td>
    </ng-container>
    <ng-container matColumnDef="last_updated">
      <th mat-header-cell *matHeaderCellDef>Last Updated</th>
      <td mat-cell *matCellDef="let attendee">{{attendee.last_updated_epoch | date:'short'}}</td>
    </ng-container>
    <ng-container matColumnDef="left_epoch">
      <th mat-header-cell *matHeaderCellDef>Left</th>
      <td mat-cell *matCellDef="let attendee">{{attendee.left_epoch}}</td>
    </ng-container>
    <ng-container matColumnDef="scheduled_arrival">
      <th mat-header-cell *matHeaderCellDef>Scheduled Arrival</th>
      <td mat-cell *matCellDef="let attendee">{{attendee.scheduled_arrival_epoch | date:'short'}}</td>
    </ng-container>
    <ng-container matColumnDef="session_id">
      <th mat-header-cell *matHeaderCellDef>session_id</th>
      <td mat-cell *matCellDef="let attendee">{{attendee.session_id}}</td>
    </ng-container>
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>State</th>
      <td mat-cell *matCellDef="let attendee">{{attendee.state}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="display"></tr>
    <tr mat-row *matRowDef="let attendee; columns: display"></tr>

    <tr class="mat-row" *matNoDataRow><td [attr.colspan]="display.length"></td></tr>
  </table>

</div>
