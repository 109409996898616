
import { ConsultingSessionAttendee, DRAFT, Person, PersonContactPoint, UserPerson } from "@bcx/models";
import { AuthedUser } from "./common";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasMetadataUserId(role: string, action: string, user: AuthedUser, context: any) {

  if (context.metadata && context.metadata.user_id) {

    return context.metadata.user_id === user.user_id;

  }

  return false;

}

export function isAttendee(role: string, action: string, user: AuthedUser, context: { attendee: ConsultingSessionAttendee }) {

  if (context.attendee) {

    return context.attendee.user_id === user.user_id;

  }

  return false;

}

export function isDraft(role: string, action: string, user: AuthedUser, context: { state: string }) {

  return context.state === DRAFT;

}

export function isOwn(role: string, action: string, user: AuthedUser, context: { user_id: string }) {

  return context.user_id === user.user_id;

}

export function isOwnPerson(role: string, action: string, user: AuthedUser, context: { person: Person, userPerson: UserPerson }) {

  return context.userPerson?.user_id === user.user_id;

}

export function isOwnKey(key: string) {

  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (role: string, action: string, user: AuthedUser, context: any) => {

    return user.user_id === context[key];

  };

}

export interface IsAcceptedBy {

  accepted_by: {
    user_id: string
  };

}

export function isAcceptedBy(role: string, action: string, user: AuthedUser, context: IsAcceptedBy) {

  return context.accepted_by?.user_id === user.user_id;

}

export interface IsRequestedBy {

  requested_by: {
    user_id: string
  };

}

export function isRequestedBy(role: string, action: string, user: AuthedUser, context: IsRequestedBy) {

  return context.requested_by?.user_id === user.user_id;

}

export function notDraft(role: string, action: string, user: AuthedUser, context: { state: string }) {

  return context.state !== DRAFT;

}
