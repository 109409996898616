import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SubsComponent } from '@bcx/ng-helpers';
import { SeatService } from '../../seat/seat.service';
import { MatDialog } from '@angular/material/dialog';
import { ReleaseDialogComponent } from '../../seat/release-dialog/release-dialog.component';
import { ConfirmDialogComponent } from '../../seat/confirm-dialog/confirm-dialog.component';
import { TrainingClass, TrainingClassStatusMap, TrainingSeat } from '@bcx/models';

@Component({
  selector: 'ng-training-class-seating-chart',
  templateUrl: './seating-chart.component.html',
  styleUrls: ['./seating-chart.component.sass'],
})
export class SeatingChartComponent extends SubsComponent implements OnChanges {

  @Input() class_id: string;

  error?: string;

  loading: boolean;

  seats: Array<TrainingSeat> = [];

  @Input() trainingClass: TrainingClass;

  constructor(
    private dialog: MatDialog,
    private service: SeatService,
  ) {

    super();

  }

  canConfirm(seat: TrainingSeat) {

    if (this.trainingClass?.status === TrainingClassStatusMap.CONFIRMED) {

      return this.service.canConfirm(seat);

    }

    return false;

  }

  canRelease(seat: TrainingSeat) {

    return this.service.canRelease(seat);

  }

  canSendConfirmationEmail(seat: TrainingSeat) {

    return this.service.isConfirmed(seat);

  }

  confirm(seat: TrainingSeat) {

    this.dialog.open(ConfirmDialogComponent, {
      data: {
        seat,
        trainingClass: this.trainingClass
      },
    });

  }

  fetchSeats() {

    return this.service.list({ class_id: this.class_id })
    .catch((e: Error) => {

      console.error(e);

    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['class_id']) {

      this.onClassIdChange();

    }

  }

  private onClassIdChange() {

    if (this.subs['seats']) {

      this.subs['seats'].unsubscribe();

    }

    if (this.class_id) {

      // this.subs['seats'] = this.service.selectForClass(this.class_id)
      // .subscribe(({ error, loading, models }) => {

      //   this.error = error;

      //   this.loading = loading;

      //   this.seats = models.sort((a: TrainingSeat, b: TrainingSeat) => {

      //     if (a.requested_epoch > b.requested_epoch) {

      //       return 1;

      //     } else if (a.requested_epoch < b.requested_epoch) {

      //       return -1;

      //     } else {

      //       return 0;

      //     }

      //   });

      // });

      this.fetchSeats();

    }

  }

  release(seat: TrainingSeat) {

    this.dialog.open(ReleaseDialogComponent, {
      data: seat,
    });

  }

  sendConfirmationEmail(seat: TrainingSeat) {

    this.service.sendConfirmation(seat, {})
    .catch((e: Error) => {

      console.error(e);
      
    });

  }

}
