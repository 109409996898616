import { Component, HostBinding, OnInit } from '@angular/core';
import { distinctUntilChanged, map } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from '../settings.service';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CONSULTING, OWNER, PROSPECTIVE, PROVIDER } from '@bcx/models';
import { SubsComponent } from '@bcx/ng-helpers';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.sass'],
})
export class HomepageComponent extends SubsComponent implements OnInit {

  CONSULTING = CONSULTING;

  currentRole: string;

  isGtSm = false;

  OWNER = OWNER;

  PROSPECTIVE = PROSPECTIVE;

  PROVIDER = PROVIDER;

  @HostBinding('class.route-flex-fill') routeFlexFill = true;

  constructor(
    private dialog: MatDialog,
    private breakpoint: BreakpointObserver,
    private router: Router,
    private settings: SettingsService,
  ) {

    super();

  }

  ngOnInit(): void {

    this.subs['media'] = this.breakpoint.observe([
      Breakpoints.Small,
      Breakpoints.Medium,
    ])
    .pipe(map((results) => {

      return results.breakpoints[Breakpoints.Medium];

    }))
    .pipe(distinctUntilChanged())
    .subscribe((isGtSm: boolean) => {

      this.isGtSm = isGtSm;

    });

    this.subs['role'] = this.settings.role$
    .pipe(distinctUntilChanged())
    .subscribe((role: string) => {

      this.currentRole = role;

    });

  }

  registerForTraining(role?: string) {

    role;

  }

  scheduleSession(session_type: string = PROSPECTIVE, role?: string) {

    this.router.navigate(['consulting','schedule-meeting'], {
      queryParams: {
        session_role: role,
        session_type,
      }
    });

  }

}
