<div fxLayout="column">

  <mat-form-field
    *ngIf="control">

    <mat-label>Your Phone Number</mat-label>

    <input type="tel"
      [formControl]="control"
      matInput>

    <mat-hint *ngIf="hint">{{hint}}</mat-hint>

    <mat-error *ngIf="control.hasError('required')">{{ error || 'Please enter your phone number.' }}</mat-error>

    <mat-error *ngIf="control.hasError('invalid')">Please enter a valid phone number.</mat-error>

    <mat-error *ngIf="control.hasError('invalid-country-code')">Please enter the country code. &lpar; +1 , +44 , ... &rpar;</mat-error>

  </mat-form-field>

</div>
