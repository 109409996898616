<div fxLayout="column"
  *ngIf="control">

  <mat-form-field>

    <input type="number" placeholder="Length of Minutes"
      [attr.max]="max"
      [attr.min]="min"
      [attr.step]="step"
      [formControl]="control"
      matInput>

    <mat-error 
      *ngIf="control.hasError('required')">Please enter the number of minutes.</mat-error>

  </mat-form-field>

  <div fxLayout="row" fxLayoutAlign="space-between">

    <button type="button"
      (click)="setOneHour()"
      mat-raised-button>1 Hour</button>

    <button type="button"
      (click)="setTwoHours()"
      mat-raised-button>2 Hours</button>

    <button type="button"
      (click)="setOneDay()"
      mat-raised-button>1 Day</button>

  </div>

</div>
