<div class="flex flex-col margin10 border">

  <h1 class="padding10">Books</h1>

  <div class="flex flex-col sm:flex-row">

    <mat-card class="flex margin20 box-shadow">

      <div class="flex flex-col justify-center book-title">
        
        <mat-card-header>

          <mat-card-title>Best CX Ever For Provider</mat-card-title>

        </mat-card-header>

      </div>

      <mat-card-content>

        <div class="flex flex-row justify-around">

          <div class="flex flex-col padding10">

            <img src="../assets/book-covers/provider-cover-233x195.png" 
              alt="provider book cover" 
              class="box-shadow">
          
          </div>

          <div class="flex flex-row">

            <div class="flex flex-col">
  
              <p>Digital: $4.99</p>
              <p>Paperback: $14.99</p>
  
              <a color="primary"
                mat-raised-button
                routerLink="book/purchase">Buy Now</a>
            
            </div>

          </div>

        </div>

        <div class="flex flex-col padding10">

          <p>Contains 22 Best Practices designed to teach providers how to love their job, 
            stategies and tactics to help guide your customer to a solution, communicate your message clearly, 
            provide confident answers, release the daily negativity, and more.</p>
        
        </div>

      </mat-card-content>

    </mat-card>

    <mat-card class="flex margin20 box-shadow">

      <mat-card-header>

        <mat-card-title class="book-title">Best CX Ever for Owners</mat-card-title>

      </mat-card-header>

      <mat-card-content>

        <div class="flex flex-row justify-around">

          <div class="flex flex-col padding10">

            <img src="../assets/book-covers/owner-cover-226x259.png" 
              alt="Owner Book cover"
              class="box-shadow">
          
          </div>
          
          <div class="flex flex-row">

            <div class="flex flex-col padding10">

              <p>Digital: $4.99</p>
              <p>Paperback: $14.99</p>

              <a color="primary"
                mat-raised-button
                routerLink="book/purchase">Buy Now</a>
            
            </div>

          </div>

        </div>

        <div class="flex flex-col padding10">

          <p>Contains 22 Best Practices
            Designed to teach owners and decision makers how to support their providers, 
            how to record and analyze data, how to learn from that data and 
            continuously improve.</p>
        
        </div>

      </mat-card-content>

    </mat-card>
  
  </div>

</div>