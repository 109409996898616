import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Countries } from '@bcx/models';

@Component({
  selector: 'ng-picker-select-country',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.sass'],
})
export class SelectComponent {

  @Input() control: FormControl;

  @Input() error = 'Please select the country.';

  @Input() label = 'Your Country';

  countries = Countries;

}
