<div fxLayout="column" fxLayoutGap="10px"
  *ngIf="form"
  [formGroup]="form">

  <ng-billing-settings-select-currency
    [control]="currencyControl"></ng-billing-settings-select-currency>

  <ng-consulting-session-select-category fxFlex
    [control]="categoryControl"
    [currency]="currencyControl.value"
    [sessionType]="sessionTypeControl.value"></ng-consulting-session-select-category>

  <ng-consulting-session-select-type fxFlex
    [category]="categoryControl.value"
    [control]="sessionTypeControl"
    [currency]="currencyControl.value"></ng-consulting-session-select-type>

  <ng-consulting-session-selected-price
    [price]="price"></ng-consulting-session-selected-price>

  <mat-divider></mat-divider>

  <ng-consulting-session-select-start
    [control]="scheduledStartEpochControl"></ng-consulting-session-select-start>

  <mat-divider></mat-divider>

  <ng-consulting-session-select-length
    [control]="scheduledLengthMinutesControl"></ng-consulting-session-select-length>

</div>
