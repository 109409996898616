<div fxLayout="column"
  *ngIf="control">

  <mat-form-field>

    <mat-select 
      *ngIf="returnUser"
      [compareWith]="compareUser"
      [placeholder]="placeholder"
      [formControl]="control"
      matInput>

      <mat-option 
        *ngFor="let user of availableUsers"
        [value]="user">
        <ng-container *ngTemplateOutlet="optionLabel || defaultOptionLabel; context: { user }"></ng-container>
      </mat-option>

    </mat-select>

    <mat-select 
      *ngIf="!returnUser"
      [placeholder]="placeholder"
      [formControl]="control"
      matInput>

      <mat-option 
        *ngFor="let user of availableUsers"
        [value]="user.user_id">
        <ng-container *ngTemplateOutlet="optionLabel || defaultOptionLabel; context: { user }"></ng-container>
      </mat-option>

    </mat-select>

    <mat-error *ngIf="control.hasError('required')">{{requiredErrorMessage}}</mat-error>

    <ng-template #defaultOptionLabel let-user="user">{{user.name}} {{user.user_id}}</ng-template>

    <mat-spinner diameter="20"
      *ngIf="loading"
      matSuffix></mat-spinner>

  </mat-form-field>

</div>
