<div class="flex flex-col"
  *ngIf="(vm$ | async) as vm"
  [formGroup]="form">

  <h2 class="text-md">Which of the following goals are important to you?</h2>

  <div class="flex flex-col divide-y-2">

    <app-goal-level-of-importance 
      [form]="form"
      [goalName]="names.BEST_EXPERIENCES_EVERY_TIME">
    
      Provide the best experiences every time.

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.BEST_EXPERIENCES_EVERY_TIME])"
      [form]="form"
      [goalName]="names.DECREASE_TURN_OVER_RATE">
    
      Lower your turnover rate

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.DECREASE_TURN_OVER_RATE])"
      [form]="form"
      [goalName]="names.INCREASE_AVERAGE_PURCHASE">
    
      Increase average purchase size

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.INCREASE_AVERAGE_PURCHASE])"
      [form]="form"
      [goalName]="names.INCREASE_COMPLIANCE_QUALITY_SCORES">
    
      Increase compliance and quality scores

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.INCREASE_COMPLIANCE_QUALITY_SCORES])"
      [form]="form"
      [goalName]="names.INCREASE_CONVERSION_RATE">
    
      Increase customer/sales conversion rate

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.INCREASE_CONVERSION_RATE])"
      [form]="form"
      [goalName]="names.INCREASE_CUSTOMER_BASE">
    
      Increase your customer base

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.INCREASE_CUSTOMER_BASE])"
      [form]="form"
      [goalName]="names.INCREASE_CSAT">
    
      Increase customer satisfaction ratings

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
    *ngIf="hasAnswer(vm.goals[names.INCREASE_CSAT])"
      [form]="form"
      [goalName]="names.INCREASE_JOB_SATISFACTION">
    
      Increase customer service agent job satisfaction

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.INCREASE_JOB_SATISFACTION])"
      [form]="form"
      [goalName]="names.INCREASE_ROI">
    
      Increase ROI for every dollar you spend on customer service

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.INCREASE_ROI])"
      [form]="form"
      [goalName]="names.MORE_REFERRALS">
    
      Get more referrals

    </app-goal-level-of-importance>

    <app-goal-level-of-importance 
      *ngIf="hasAnswer(vm.goals[names.MORE_REFERRALS])"
      [form]="form"
      [goalName]="names.MORE_PREMIUMS_UPGRADES">
    
      Sell more premium products or services

    </app-goal-level-of-importance>

  </div>

  <section class="flex flex-col"
    *ngIf="hasAnswer(vm.goals[names.MORE_PREMIUMS_UPGRADES])">
      
    <mat-form-field class="flex w-full">
      
      <mat-label>What other goals do you have?</mat-label>
      <textarea 
        formControlName="other"
        matInput></textarea>
    
    </mat-form-field>
    
  </section>

</div>