import { Injectable } from '@angular/core';
import { ApiService } from '@engagedcx/ng-amplify';
import { AuthService } from '@engagedcx/ng-amplify';
import { Store } from '@ngrx/store';
import { AxiosResponse } from 'axios';
import { Observable } from 'rxjs';
import { Stripe } from 'stripe';
import { loadAllCustomers, loadCustomer, loadMyCustomer, loadMyCustomerFailure, loadMyCustomerSuccess, updateCustomer, updateMyCustomer } from './state/customer.actions';
import { MyCustomerState, State } from './state/customer.reducer';
import { selectAll, selectMy, selectOne } from './state/customer.selectors';
import { props } from 'bluebird';
import { AllEntitiesState } from '@bcx/ng-helpers';
import { stripeCustomer as schemas } from '@bcx/schemas';
import { StripeCustomerGetPayload, StripeCustomerListPayload, StripeCustomerUpdatePayload } from '@bcx/models';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  all$: Observable<AllEntitiesState<Stripe.Customer>>;

  my$: Observable<MyCustomerState>;

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private store: Store<State>,
  ) { 

    this.all$ = this.store.select(selectAll);

    this.my$ = this.store.select(selectMy);

  }

  get(data: Partial<StripeCustomerGetPayload>) {

    return props({
      clean: schemas.get.validateAsync(data, {}),
      creds: this.auth.waitForCredentials(),
    })
    .then(({ clean }) => {

      const path = `/stripe/customers/${clean.id}`;

      this.store.dispatch(loadCustomer({
        id: clean.id,
        loading: true,
      }))

      return this.api.get(path, clean)
      .then((response: AxiosResponse<Stripe.Customer>) => {

        const customer = response.data;

        this.store.dispatch(loadCustomer({
          id:customer.id,
          loading: false,
          customer,
        }));

        return customer;

      })
      .catch((e: Error) => {

        this.store.dispatch(loadCustomer({
          id: clean.id,
          error: e.message,
          loading: false,
        }));

        throw e;

      });

    });

  }

  list(data: StripeCustomerListPayload) {

    return props({
      clean: schemas.list.validateAsync(data, {}),
      creds: this.auth.waitForCredentials()
    })
    .then(({ clean }) => {

      const path = `/stripe/customers`;

      this.store.dispatch(loadAllCustomers({
        loading: true,
      }));
  
      return this.api.get(path, clean);

    })
    .then((response: AxiosResponse<{ data:Array<Stripe.Customer>, has_more: boolean }>) => {

      const data = response.data;

      let last: string | undefined;

      if (data.has_more) {

        last = data.data[data.data.length - 1].id;

      }

      this.store.dispatch(loadAllCustomers({ data, last, loading: false }));

      return data;

    })
    .catch((e: Error) => {

      this.store.dispatch(loadAllCustomers({ error: e.message, loading: false }));

      throw e;

    });

  }

  my() {

    this.store.dispatch(loadMyCustomer());

    return this.auth.waitForIdentity()
    .then(() => {

      const path = `/stripe/customers/my`;

      return this.api.get(path);

    })
    .then((response: AxiosResponse<Stripe.Customer>) => {

      const data = response.data;

      this.store.dispatch(loadMyCustomerSuccess({ data }));

      return data;

    })
    .catch((e: Error) => {

      this.store.dispatch(loadMyCustomerFailure({ error: e.message }));

      throw e;

    });

  }

  select(id: string) {

    return this.store.select(selectOne(id));

  }

  update(data: StripeCustomerUpdatePayload) {

    return props({
      clean: schemas.update.validateAsync(data),
      creds: this.auth.waitForCredentials(),
    })
    .then(({ clean }) => {

      this.store.dispatch(updateCustomer({
        id: clean.id,
        processing: true,
      }));

      const path = `/stripe/customers/${clean.id}`;

      return this.api.post(path, clean)
      .then((response: AxiosResponse<Stripe.Customer>) => {
  
        const data = response.data;
  
        this.store.dispatch(updateCustomer({ 
          data, 
          id: clean.id,
          processing: false 
        }));
  
        return data;
  
      })
      .catch((e: Error) => {
  
        this.store.dispatch(updateCustomer({ 
          error: e.message, 
          id: clean.id,
          processing: false, 
        }));
  
        throw e;
  
      });

    });

  }

  updateMy(data: Partial<StripeCustomerUpdatePayload>) {

    if (data.address && data.address.line2 === '') {

      data.address.line2 = undefined;

    }

    console.log('updateMy', data);

    return props({
      clean: schemas.update.validateAsync(data),
      creds: this.auth.waitForCredentials(),
    })
    .then(({ clean }) => {

      this.store.dispatch(updateMyCustomer({
        id: clean.id,
        processing: true,
      }));

      const path = `/stripe/customers/my`;

      return this.api.post(path, clean)
      .then((response: AxiosResponse<Stripe.Customer>) => {
  
        const data = response.data;
  
        this.store.dispatch(updateMyCustomer({ 
          data, 
          id: clean.id,
          processing: false,
        }));
  
        return data;
  
      })
      .catch((e: Error) => {
  
        this.store.dispatch(updateMyCustomer({ 
          error: e.message, 
          id: clean.id,
          processing: false,
        }));
  
        throw e;
  
      });

    });

  }

}
