<div class="flex flex-row h-full main-header w-full"
  *ngIf="(vm$ | async) as vm">

  <div class="basis-1/3 flex flex-row justify-start">

    <button 
      mat-icon-button 
      [matMenuTriggerFor]="main">
      <mat-icon>menu</mat-icon>
    </button>

  </div>

  <div class="basis-1/3 flex flex-row justify-center items-center pb-2">

    <a class="flex flex-row gap-x-2" href="/"
      *ngIf="!vm.showBigLogo">  

      <img src="/assets/logo/bestcxlogo-w32px.png" alt="Best CX Ever Logo">

      <span class="text-base sm:text-lg">Best CX Ever</span>

    </a>

    <a href="/"
      *ngIf="vm.showBigLogo">  

      <img src="/assets/logo/bestcxlogo-w96px.png" alt="Best CX Ever Logo">

    </a>

  </div>

  <div class="basis-1/3 flex flex-row justify-end items-start">

    <ng-amplify-authed-user>

      <a mat-menu-item routerLink="/your/consulting">Your Consulting</a>
      <a mat-menu-item routerLink="/your/payments">Your Payments</a>
      <a mat-menu-item routerLink="/your/training">Your Training</a>

    </ng-amplify-authed-user>

  </div>

</div>

<mat-menu #main="matMenu">

  <a mat-menu-item routerLink="/">Home</a>
<!-- 
  <a mat-menu-item routerLink="/experience">Experience</a>

  <a mat-menu-item routerLink="/owner">Owners</a>

  <a mat-menu-item routerLink="/provider">Providers</a>

  <a mat-menu-item routerLink="/experience/trainers">Trainers</a> -->

  <!-- <a mat-menu-item routerLink="/testimonials">Testimonials</a> -->

</mat-menu>
