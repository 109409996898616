<mat-card>

  <mat-card-content>

    <ul>
      <li>Provide the best experience every time</li>
      <li>Increase pay rate, bonuses and achieve promotions</li>
      <li>Increase customer satisfaction ratings</li>
      <li>Raise job satisfaction</li>
      <li>Increased conversion rates and revenue</li>
      <li>More repeat customers and more referrals</li>
      <li>Higher quality and compliance ratings</li>
    </ul>

  </mat-card-content>

</mat-card>