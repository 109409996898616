import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SeatService } from '../seat.service';
import { ConfirmForm } from '../confirm-form/confirm-form.form';
import { TrainingClass, TrainingSeat, User } from '@bcx/models';
import { UserService } from '../../../user/user.service';
import { SubsComponent } from '@bcx/ng-helpers';

@Component({
  selector: 'ng-training-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.sass'],
})
export class ConfirmDialogComponent extends SubsComponent implements OnInit {

  error?: string;

  form = new ConfirmForm();

  isConfirmed = false;

  processing: boolean;

  seat: TrainingSeat;

  user?: User;

  userError?: string;

  userLoading: boolean;

  trainingClass: TrainingClass;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {
      seat: TrainingSeat,
      trainingClass: TrainingClass,
    },
    private dialog: MatDialogRef<ConfirmDialogComponent>,
    private service: SeatService,
    private userService: UserService,
  ) {

    super();

    this.seat = data.seat;

    this.trainingClass = data.trainingClass;

  }

  closeDialog() {

    this.dialog.close();

  }

  fetchUser() {

    return this.userService.get({user_id: this.seat.user_id})
    .catch((e: Error) => {

      console.error(e);

    });

  }

  ngOnInit(): void {

    this.fetchUser();

  }

  submit() {

    this.service.confirm(this.seat, {})
    .then((confirmed: TrainingSeat) => {

      this.dialog.close(confirmed);

    })
    .catch((e: Error) => {

      console.error(e);

    });

  }

}
