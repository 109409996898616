<div class="ng-training-ticket-card-list">

  <div fxLayout="column" fxLayoutAlign="center center"
    *ngIf="noTickets">

    <span>No Tickets</span>

  </div>

  <div class="ng-training-ticket-card"
    *ngFor="let ticket of tickets">

    <ng-training-ticket-tearoff [ticket]="ticket"></ng-training-ticket-tearoff>
    
    <ng-training-ticket-stub [ticket]="ticket"></ng-training-ticket-stub>

  </div>

</div>