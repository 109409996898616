import { Injectable } from '@angular/core';
import { SessionServiceAdapter } from './session.service.adapter';
import { WebSocketService } from '@engagedcx/ng-amplify';
import { ConsultingSession, ConsultingSessionCancelAction, ConsultingSessionCanceledEvent, ConsultingSessionConfirmAction, ConsultingSessionConfirmedEvent, ConsultingSessionFinishAction, ConsultingSessionFinishedEvent, ConsultingSessionGetAction, ConsultingSessionInterface, ConsultingSessionListAction, ConsultingSessionListedEvent, ConsultingSessionRequestAction, ConsultingSessionRequestedEvent, ConsultingSessionStartAction, ConsultingSessionStartedEvent, ConsultingSessionViewedEvent } from '@bcx/models';
import { List } from '@digitaltoolbuilders/model-helpers';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements SessionServiceAdapter {

  constructor(private socket: WebSocketService) { }

  cancel(action: ConsultingSessionCancelAction): Promise<ConsultingSessionCanceledEvent> {
    
    return this.socket.request<ConsultingSessionInterface>(action)
    .then((message) => {

      return new ConsultingSessionCanceledEvent({
        ...message,
        payload: new ConsultingSession(message.payload),
      });

    });

  }

  confirm(action: ConsultingSessionConfirmAction): Promise<ConsultingSessionConfirmedEvent> {
      
    return this.socket.request<ConsultingSessionInterface>(action)
    .then((message) => {

      return new ConsultingSessionConfirmedEvent({
        ...message,
        payload: new ConsultingSession(message.payload),
      });

    });

  }

  finish(action: ConsultingSessionFinishAction): Promise<ConsultingSessionFinishedEvent> {
      
    return this.socket.request<ConsultingSessionInterface>(action)
    .then((message) => {

      return new ConsultingSessionFinishedEvent({
        ...message,
        payload: new ConsultingSession(message.payload),
      });

    });

  }

  get(action: ConsultingSessionGetAction): Promise<ConsultingSessionViewedEvent> {
      
    return this.socket.request<ConsultingSessionInterface>(action)
    .then((message) => {

      return new ConsultingSessionViewedEvent({
        ...message,
        payload: new ConsultingSession(message.payload),
      });

    });

  }

  list(action: ConsultingSessionListAction): Promise<ConsultingSessionListedEvent> {
      
    return this.socket.request<List<ConsultingSessionInterface>>(action)
    .then((message) => {

      return new ConsultingSessionListedEvent({
        ...message,
        payload: new List<ConsultingSession>({
          ...message.payload,
          list: message.payload.list.map((item) => new ConsultingSession(item)),
        }),
      });

    });

  }

  request(action: ConsultingSessionRequestAction): Promise<ConsultingSessionRequestedEvent> {
      
    return this.socket.request<ConsultingSessionInterface>(action)
    .then((message) => {

      return new ConsultingSessionRequestedEvent({
        ...message,
        payload: new ConsultingSession(message.payload),
      });

    });

  }

  start(action: ConsultingSessionStartAction): Promise<ConsultingSessionStartedEvent> {
      
    return this.socket.request<ConsultingSessionInterface>(action)
    .then((message) => {

      return new ConsultingSessionStartedEvent({
        ...message,
        payload: new ConsultingSession(message.payload),
      });

    });

  }

}
