<div fxLayout="column" fxLayoutGap="10px"
  *ngIf="form"
  [formGroup]="form">

  <ng-billing-settings-select-currency
    [control]="currencyControl"></ng-billing-settings-select-currency>

  <ng-consulting-session-select-category fxFlex
    [control]="categoryControl"
    [currency]="currencyControl.value"
    [sessionType]="sessionTypeControl.value"></ng-consulting-session-select-category>

  <ng-consulting-session-select-type fxFlex
    [category]="categoryControl.value"
    [control]="sessionTypeControl"
    [currency]="currencyControl.value"></ng-consulting-session-select-type>

  <div fxLayout="row" fxLayoutAlign="center center">

    <h2 class="mat-headline-6">
      
      <ng-consulting-session-selected-price
        [price]="price"></ng-consulting-session-selected-price>

    </h2>

  </div>

</div>
