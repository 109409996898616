<div class="switch-container">

  <div class="outside-track">

    <div class="inside-track" 
      [ngClass]="role">
      
    </div>

    <div class="slider" [@rightLeft]="role === 'provider' ? 'left' : 'right'"
      [ngClass]="role">&nbsp;</div>
    
    <div class="buttons">

      <button type="button" 
        (click)="selectProvider()">
        Providers
      </button>
    
      <button type="button"
        (click)="selectOwner()">
        Owners
      </button>

    </div>

  </div>

</div>
