<ng-container *ngIf="(vm$ | async) as vm">

  <div class="divide-y divide-slate-200 flex flex-col"
    [formGroup]="form">

    <section class="question">

      <h2 class="text-base">Do you interact with customers on a regular basis?</h2>
    
      <mat-button-toggle-group  formControlName="interacts_with_customers">
    
        <mat-button-toggle [value]="false">No</mat-button-toggle>
    
        <mat-button-toggle [value]="true">Yes</mat-button-toggle>
    
      </mat-button-toggle-group>
    
    </section>
    
    <section class="question"
      *ngIf="hasAnswer(vm.interacts_with_customers)">
    
      <div class="flex flex-row justify-between">

        <h2 class="text-base">Are you the leader of a team of providers?</h2>

        <button type="button" color="primary"
          
          mat-icon-button><mat-icon>help</mat-icon></button>

      </div>
    
      <mat-button-toggle-group formControlName="is_team_leader">
    
        <mat-button-toggle [value]="false">No</mat-button-toggle>
    
        <mat-button-toggle [value]="true">Yes</mat-button-toggle>
    
      </mat-button-toggle-group>
    
    </section>
    
    <section class="question"
      *ngIf="hasAnswer(vm.is_team_leader)">

      <h2 class="text-base">Are you an owner of the business?</h2>
    
      <mat-button-toggle-group formControlName="is_business_owner">
    
        <mat-button-toggle [value]="false">No</mat-button-toggle>
    
        <mat-button-toggle [value]="true">Yes</mat-button-toggle>
    
      </mat-button-toggle-group>
    
    </section>
    
    <section class="question"
      *ngIf="hasAnswer(vm.is_business_owner)">
    
      <h2 class="text-base">Can you change any policies or procedures?</h2>
    
      <mat-button-toggle-group formControlName="can_change_policy">
    
        <mat-button-toggle [value]="false">No</mat-button-toggle>
    
        <mat-button-toggle [value]="true">Yes</mat-button-toggle>
    
      </mat-button-toggle-group>
    
    </section>
    
    <section class="question"
      *ngIf="hasAnswer(vm.can_change_policy)">
    
      <h2 class="text-base">Do you have a side hustle / hobby for profit / small business?</h2>
    
      <mat-button-toggle-group formControlName="has_small_business">
    
        <mat-button-toggle [value]="false">No</mat-button-toggle>
    
        <mat-button-toggle [value]="true">Yes</mat-button-toggle>
    
      </mat-button-toggle-group>
    
    </section>

  </div>

  <section class="border-2 border-slate-200 flex flex-col justify-center items-center m-2 p-2"
    *ngIf="hasAnswer(vm.has_small_business)"
    [ngSwitch]="vm.role">

    <span *ngSwitchCase="COACH">You are a "Coach"</span>

    <span *ngSwitchCase="OWNER">You are an "Owner"</span>

    <span *ngSwitchCase="PROVIDER">You are a "Provider"</span>

    <span *ngIf="vm.is_team_leader">Who leads a team of "Providers"</span>

    <span *ngIf="vm.interacts_with_customers">Who interacts with customers regularly</span>

    <span *ngIf="vm.has_small_business">Who has a "Small Business"</span>

  </section>

</ng-container>
