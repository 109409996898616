import { Component, Input } from '@angular/core';
import { CreateForm } from './create-form.form';

@Component({
  selector: 'ng-user-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.sass'],
})
export class CreateFormComponent {

  @Input() form: CreateForm;

}
