<div fxLayout="column"
  *ngIf="control">

  <ng-calendar-office-hours-summary fxFlex
    [date]="dateControl.value"></ng-calendar-office-hours-summary>

  <div fxLayout="column"
    fxLayout.gt-sm="row">

    <mat-form-field fxFlex>

      <mat-label>Select A Date</mat-label>

      <input 
        [formControl]="dateControl"
        [matDatepicker]="datepicker"
        [min]="minDate"
        matInput>

      <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>

      <mat-datepicker #datepicker></mat-datepicker>

      <mat-error>Please select a date for the meeting.</mat-error>

    </mat-form-field>

    <ng-picker-time-selector fxFlex
      [control]="timeControl"
      [date]="dateControl.value"
      label="Select A Time"
      [officeHoursEnd]="officeHoursEnd"
      [officeHoursStart]="officeHoursStart"
      requiredError="Please select a time for the meeting."></ng-picker-time-selector>

  </div>

</div>
