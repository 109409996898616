import { Injectable } from '@angular/core';
import { UserCognitoListPayload } from '@bcx/models';
import { userCognito as schemas } from '@bcx/schemas';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  constructor() { }

  list(data: UserCognitoListPayload, now = Date.now()) {

    return schemas.list.validateAsync(data, {})
    .then((clean) => {

      

    });

  }

}
