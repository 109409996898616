
import { on } from '@ngrx/store';
import { createImmerReducer } from 'ngrx-immer/store';
import { loadMyPaymentMethods, loadMyPaymentMethodsFailure, loadMyPaymentMethodsSuccess, loadPaymentMethod, loadAllPaymentMethods, loadAllPaymentMethodsFailure, addPaymentMethod, addPaymentMethodFailure, addPaymentMethodSuccess, retrieveSetupIntent, retrieveSetupIntentFailure, retrieveSetupIntentSuccess } from './payment-method.actions';
import { Stripe } from 'stripe';
import { SetupIntent } from '@stripe/stripe-js';
import { STRIPE_PAYMENT_METHOD } from '@bcx/models';
import { Entities, Entity, InternalManyEntitiesState, ManyEntitiesState } from '@bcx/ng-helpers';

export const featureKey = STRIPE_PAYMENT_METHOD;

export interface AddState {

  error?: string;

  setupIntent?: Stripe.SetupIntent;

  processing: boolean;

}

export interface State {

  add: AddState;

  all: Omit<InternalManyEntitiesState<Stripe.PaymentMethod>,'entities'>;

  entities: Entities<Stripe.PaymentMethod>;

  my: Omit<ManyEntitiesState<Stripe.PaymentMethod>, 'entities' | 'models'>;

  setupIntents: Entities<SetupIntent>;

}

export const initialState: State = {
  all: {
    ids: [],
    loading: false,
  },
  add: {
    processing: false,
  },
  entities: {},
  my: {
    ids: [],
    loading: false,
  },
  setupIntents: {},
};

export function createEntityId(id: string) {

  return `${id}`;

}

export function getEntity(id: string, state: State) {

  const entity: Entity<Stripe.PaymentMethod> = state.entities[id] || {
    loaded: false,
    loading: false,
    processing: false,
  };

  return entity;

}

export const reducer = createImmerReducer(
  initialState,

  on(addPaymentMethod, (state: State) => {

    state.add.processing = true;

    return state;

  }),
  on(addPaymentMethodFailure, addPaymentMethodSuccess, (state: State, { data, error }) => {

    state.add.error = error;
    state.add.processing = false;
    state.add.setupIntent = data;

    return state;

  }),
  on(loadMyPaymentMethods, (state: State) => {

    state.my.loading = true;

    return state;

  }),
  on(loadMyPaymentMethodsFailure, loadMyPaymentMethodsSuccess, (state, { data, error, }) => {

    const loaded = Date.now();

    state.my.error = error;
    state.my.loaded = loaded;
    state.my.loading = false;

    if (data) {

      data.data.forEach((paymentMethod: Stripe.PaymentMethod) => {

        const id = createEntityId(paymentMethod.id);

        const entity = getEntity(id, state);

        entity.model = paymentMethod;
        entity.loaded = loaded;
        entity.loading = false;

        state.entities[id] = entity;

        if (!state.my.ids.includes(id)) {

          state.my.ids.push(id);

        }

      });

    }

    return state;

  }),
  on(loadPaymentMethod, (state: State, { error, id, loading, paymentMethod }) => {

    const entityId = createEntityId(id);

    const entity = getEntity(id, state);

    entity.error = error;
    entity.loading = loading;

    if (paymentMethod) {

      entity.model = paymentMethod;
      entity.loaded = Date.now();

    }

    state.entities[entityId] = entity;

    return state;

  }),
  on(loadAllPaymentMethods, (state: State) => {

    state.all.loading = true;

    return state;

  }),
  on(loadAllPaymentMethodsFailure, loadMyPaymentMethodsSuccess, (state, { data, error }) => {

    const loaded = Date.now();

    state.all.error = error;
    state.all.loaded = loaded;
    state.all.loading = false;

    if (data) {

      data.data.forEach((paymentMethod: Stripe.PaymentMethod) => {

        const id = createEntityId(paymentMethod.id);

        const entity = getEntity(id, state);

        entity.model = paymentMethod;
        entity.loaded = loaded;
        entity.loading = false;

        state.entities[id] = entity;

      });

    }

    return state;

  }),
  on(retrieveSetupIntent, (state, { error, intent, setupIntent }) => {

    const entity = state.setupIntents[intent] || {
      loaded: false,
      loading: false,
    };

    entity.error = error;
    entity.loading = true;

    if (setupIntent) {

      entity.model = setupIntent;
      entity.loaded = Date.now();

    }

    state.setupIntents[intent] = entity;

    return state;

  }),
  on(retrieveSetupIntentFailure, retrieveSetupIntentSuccess, (state, { error, intent, setupIntent }) => {

    const entity = state.setupIntents[intent] || {
      loaded: false,
      loading: false,
    };

    entity.error = error;
    entity.model = setupIntent || entity.model;
    entity.loaded = Date.now();
    entity.loading = false;

    state.setupIntents[intent] = entity;

    return state;

  }),

);
