import { Component, Input, } from '@angular/core';
import { FormControl } from '@angular/forms';

export const ONE_DAY = 12 * 60;

export const ONE_HOUR = 60;

export const TWO_HOUR = 120;

@Component({
  selector: 'ng-picker-length-minutes',
  templateUrl: './minutes.component.html',
  styleUrls: ['./minutes.component.sass'],
})
export class MinutesComponent {

  @Input() control: FormControl;

  @Input() max?: number;

  @Input() min?: number = 15;

  @Input() step?: number = 15;

  setOneDay() {

    if (this.control) {

      this.control.patchValue(ONE_DAY);

    }

  }

  setOneHour() {

    if (this.control) {

      this.control.patchValue(ONE_HOUR);

    }

  }

  setTwoHours() {

    if (this.control) {

      this.control.patchValue(TWO_HOUR);

    }

  }

}
