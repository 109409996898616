import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { featureKey, reducer } from './state/subscription.reducer';
import { ListTableComponent } from './list-table/list-table.component';
import { MatTableModule } from '@angular/material/table';
import { PipesModule } from '../pipes/pipes.module';
import { FullDetailsComponent } from './full-details/full-details.component';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [ListTableComponent, FullDetailsComponent],
  exports: [ListTableComponent, FullDetailsComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatTableModule,
    PipesModule,
    StoreModule.forFeature(featureKey, reducer),
  ],
})
export class SubscriptionModule {}
