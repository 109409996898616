<div>

  <button type="button" [color]="color"
    (click)="resend()"
    mat-raised-button>
    <span *ngIf="!processing">Resend Confirmation Email</span>
    <mat-spinner *ngIf="processing" color="accent" diameter="20"></mat-spinner>
  </button>

</div>
