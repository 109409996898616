import { ChangeDetectionStrategy, Component, Inject, OnInit, Signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../user.service';
import { UpdateForm } from '../update-form/update-form.form';
import { User } from '@bcx/models';
import { Entity, SubsComponent } from '@bcx/ng-helpers';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ng-user-update-dialog',
  templateUrl: './update-dialog.component.html',
  styleUrls: ['./update-dialog.component.sass'],
})
export class UpdateDialogComponent extends SubsComponent implements OnInit {

  error?: string;

  form = new UpdateForm();

  processing: boolean;

  user: User;

  $user: Signal<Entity<User>>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: User,
    private dialog: MatDialogRef<UpdateDialogComponent>,
    private service: UserService,
  ) {

    super();

    this.user = data;

    this.form.patchValue(this.user);

  }

  closeDialog() {

    this.dialog.close();

  }

  ngOnInit(): void {
    
    this.$user = this.service.select({ user_id: this.user.user_id });

  }

  submit() {

    return this.service.update(this.user, this.form.value)
    .then((updated: User) => {

      this.dialog.close(updated);

    })
    .catch((e: Error) => {

      console.error(e);
      
    });

  }

}
