import { Component, Input } from '@angular/core';
import { ConfirmForm } from './confirm-form.form';

@Component({
  selector: 'ng-training-seat-confirm-form',
  templateUrl: './confirm-form.component.html',
  styleUrls: ['./confirm-form.component.sass'],
})
export class ConfirmFormComponent {

  @Input() form: ConfirmForm;

}
