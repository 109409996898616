<div class="flex flex-col">

  <h1 class="text-lg sticky-title">The First Step On Your Path To Best CX Ever</h1>

  <mat-stepper class="sticky-stepper" orientation="vertical">

    <mat-step>

      <ng-template matStepLabel>Tell Us About You</ng-template>

      <app-get-started-initial-questions></app-get-started-initial-questions>

      <div class="action-bar">

        <button type="button" color="warn"
          (click)="startOver()"
          matTooltip="Remove all answers and start over"
          mat-button>Start Over</button>

        <button type="button" color="primary" 
          mat-button
          matStepperNext>Next</button>

      </div>

    </mat-step>

    <mat-step
      *ngIf="$state().show_business_step">

      <ng-template matStepLabel>Tell Us About Your Business</ng-template>

      <app-owner-questionaire></app-owner-questionaire>

      <div class="action-bar">

        <button type="button" 
          mat-button
          matStepperPrevious>Previous</button>

        <button type="button" color="primary" 
          mat-button
          matStepperNext>Next</button>

      </div>

    </mat-step>

    <mat-step
      *ngIf="$state().show_team_step">

      <ng-template matStepLabel>About Your Team</ng-template>

      <app-coach-questionaire></app-coach-questionaire>

      <div class="action-bar">

        <button type="button" 
          mat-button
          matStepperPrevious>Previous</button>

        <button type="button" color="primary" 
          mat-button
          matStepperNext>Next</button>

      </div>

    </mat-step>

    <mat-step
      *ngIf="$state().show_provider_step">

      <ng-template matStepLabel>About You (Provider)</ng-template>

      <app-provider-questionaire></app-provider-questionaire>

      <div class="action-bar">

        <button type="button" 
          mat-button
          matStepperPrevious>Previous</button>

        <button type="button" color="primary" 
          mat-button
          matStepperNext>Next</button>

      </div>

    </mat-step>

    <mat-step
      *ngIf="$state().show_small_business_step">

      <ng-template matStepLabel>About Your Small Business</ng-template>

      <app-small-to-best-questionaire></app-small-to-best-questionaire>

      <div class="action-bar">

        <button type="button" 
          mat-button
          matStepperPrevious>Previous</button>

        <button type="button" color="primary" 
          mat-button
          matStepperNext>Next</button>

      </div>

    </mat-step>

    <mat-step>

      <ng-template matStepLabel>Find Recommendations</ng-template>

      <ng-template matStepContent>

        <div class="flex flex-row justify-center items-center p-2">

          <span>We are processing your responses. It may take up to 30 seconds.</span>

        </div>

        <div class="flex flex-row justify-center items-center p-2">

          <mat-spinner></mat-spinner>

        </div>

        <app-get-started-redirect></app-get-started-redirect>

        <div class="flex flex-row justify-between">

          <button type="button" 
            mat-button
            matStepperPrevious>Previous</button>

        </div>

      </ng-template>

    </mat-step>

  </mat-stepper>

</div>
