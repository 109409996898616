<form 
  [formGroup]="form"
  (ngSubmit)="signIn()">

  <div fxLayout="row" fxLayoutAlign="space-between"
    mat-dialog-title>

    <h1 class="mat-headline-5">
      <span>Sign In</span>
    </h1>

    <button type="button" color="warn"
      (click)="closeDialog()"
      mat-icon-button><mat-icon>close</mat-icon></button>

  </div>

  <mat-dialog-content>
    
    <ng-picker-intl-phone-number
      [control]="phoneNumberControl"></ng-picker-intl-phone-number>

    <mat-form-field
      *ngIf="codeSent">

      <mat-label>Enter The Code</mat-label>

      <input #code type="number" 
        formControlName="code"
        matInput>

      <mat-error>Please enter the 6 digit code we sent in a text message.</mat-error>

    </mat-form-field>

    <div fxLayout="column" fxLayoutGap="10px" style="margin-top: 10px"
      *ngIf="notSignedUp">

      <p style="color: red">We could not find anyone with that phone number. You may need to sign up.</p>

      <div fxLayout="row" fxLayoutAlign="center center">

        <button type="button" 
          (click)="signUp()"
          mat-raised-button>Sign Up</button>
        
      </div>

    </div>

  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between">

    <a color="primary"
      *ngIf="!codeSent || processing"
      mat-button 
      matTooltip="Sign Up Here"
      routerLink="/sign-up">Not a user?</a>

    <button type="button"
      *ngIf="codeSent"
      (click)="resendCode()"
      [disabled]="attemptsLeft > 0 || processing"
      mat-raised-button>Resend Code</button>

    <mat-spinner *ngIf="processing" diameter="30"></mat-spinner>

    <button type="submit" color="primary"
      [disabled]="form.invalid || processing"
      mat-raised-button>
      <span *ngIf="!codeSent">Send OTP</span>
      <span *ngIf="codeSent">Verify Code</span>
    </button>

  </mat-dialog-actions>

</form>
