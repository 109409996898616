import { BOOK, COACH, CONSULTING, OWNER, PROVIDER, SIDE_HUSTLE, TRAINING } from "./common";

export interface PathInterface {

  book_first: boolean;

  path_id: string;

  path_type: PathType;

  user_id?: string;

}

export class Path implements PathInterface {

  book_first = false;

  path_id: string;

  path_type: string;

  steps: Array<PathStep> = [];

  user_id?: string;

  constructor(input: Partial<Path>) {

    if (input.book_first) this.book_first = input.book_first;
    if (input.path_id) this.path_id = input.path_id;
    if (input.path_type) this.path_type = input.path_type;
    if (input.steps) this.steps = input.steps;
    if (input.user_id) this.user_id = input.user_id;

  }

}

export interface PathStep {

  step_type: PathStepType;

  stripe_product_id?: string;

}

export const PathStepTypeMap = {
  BOOK: BOOK,
  CONSULTING: CONSULTING,
  TRAINING: TRAINING,
};

export type PathStepType = typeof PathStepTypeMap[keyof typeof PathStepTypeMap];

export const PathStepTypes = Object.values(PathStepTypeMap);

export const PathTypeMap = {
  COACH: COACH,
  OWNER: OWNER,
  PROVIDER: PROVIDER,
  SIDE_HUSTLE: SIDE_HUSTLE,
};

export type PathType = typeof PathTypeMap[keyof typeof PathTypeMap];

export const PathTypes = Object.values(PathTypeMap);