import { FormControl, FormGroup, Validators } from "@angular/forms";

export class CreateForm extends FormGroup {

  constructor() {

    super({
      currency: new FormControl(null, [Validators.required]),
      payer_account_id: new FormControl('', [Validators.required]),
      scheduled_start_epoch: new FormControl(undefined, [Validators.required]),
      scheduled_length_minutes: new FormControl(15, [Validators.required]),
      session_category: new FormControl('', [Validators.required]),
      session_type: new FormControl('', [Validators.required]),
      stripe_payment_method_id: new FormControl(null, []),
      stripe_price_id: new FormControl(null, [Validators.required]),
    });

  }

}
