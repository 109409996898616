import { PersonAttributeDeleteAction, PersonAttributeDeletePayload, PersonAttributeGetAction, PersonAttributeGetPayload, PersonAttributeListAction, PersonAttributeListPayload, PersonAttributeSaveAction, PersonAttributeSavePayload, PersonAttributeValue } from "@bcx/models";
import Joi from "joi";
import { account_id } from "../account/account";
import { epoch } from "@digitaltoolbuilders/schema-helpers";
import { person_id } from "./person";
import { consistent_read, exclusive_start_key, limit } from "../dynamo-db";
import { action_id, action_name } from "../model-action";
import { user_id } from "../common";

export const attr_key = Joi.string().max(350);

export const attr_value = Joi.alternatives(
  Joi.number(),
  Joi.string(),
  Joi.any(),
  Joi.array<Array<PersonAttributeValue>>(),
);

export const del = Joi.object<PersonAttributeDeletePayload>()
  .keys({
    account_id: account_id,
    attr_key: attr_key.required(),
    person_id: person_id.required(),
  });

export const delAction = Joi.object<PersonAttributeDeleteAction>()
  .keys({
    action_id: action_id.required(),
    action_name: action_name.required(),
    epoch: epoch.required(),
    payload: del.required(),
    user_id: user_id.required(),
  });

export const get = Joi.object<PersonAttributeGetPayload>()
  .keys({
    account_id: account_id,
    attr_key: attr_key.required(),
    consistent_read: consistent_read,
    person_id: person_id.required(),
  });

export const getAction = Joi.object<PersonAttributeGetAction>()
  .keys({
    action_id: action_id.required(),
    action_name: action_name.required(),
    epoch: epoch.required(),
    payload: get.required(),
    user_id: user_id.required(),
  });

export const list = Joi.object<PersonAttributeListPayload>()
  .keys({
    account_id: account_id,
    attr_key: attr_key.optional().allow(null),
    exclusive_start_key: exclusive_start_key,
    limit: limit,
    person_id: person_id.optional().allow(null),
  });

export const listAction = Joi.object<PersonAttributeListAction>()
  .keys({
    action_id: action_id.required(),
    action_name: action_name.required(),
    epoch: epoch.required(),
    payload: list.required(),
    user_id: user_id.required(),
  });

export const save = Joi.object<PersonAttributeSavePayload>()
  .keys({
    account_id: account_id,
    attr_key: attr_key.required(),
    attr_value: attr_value.required(),
    created_epoch: epoch.optional().allow(null),
    last_updated_epoch: epoch.optional().allow(null),
    person_id: person_id.required(),
  });

export const saveAction = Joi.object<PersonAttributeSaveAction>()
  .keys({
    action_id: action_id.required(),
    action_name: action_name.required(),
    epoch: epoch.required(),
    payload: save.required(),
    user_id: user_id.required(),
  });
