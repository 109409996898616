import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailAddressModule } from './email-address/email-address.module';
import { MailAddressModule } from './mail-address/mail-address.module';
import { PhoneNumberModule } from './phone-number/phone-number.module';
import { RestModule } from './rest/rest.module';
import { WebsocketModule } from './websocket/websocket.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EmailAddressModule,
    MailAddressModule,
    PhoneNumberModule,
    RestModule,
    WebsocketModule,
  ]
})
export class ContactPointModule { }
