
import { createFeatureSelector, createSelector, } from '@ngrx/store';
import { Stripe } from 'stripe';
import { createEntityId, featureKey, getEntity, State } from './subscription.reducer';
import { AllEntitiesState, Entity, ManyEntitiesState } from '@bcx/ng-helpers';

export const selectState = createFeatureSelector<State>(
  featureKey
);

export const selectAll = createSelector(
  selectState,
  (state: State) => {

    const entities = Object.values(state.entities)
    .filter((entity) => (entity.model) ? true : false);

    const all: AllEntitiesState<Stripe.Subscription, string> = {
      ...state.all,
      entities,
      models: entities.map((entity) => {
  
        return entity.model as Stripe.Subscription
  
      }),
    };

    return all;

  }
);

export function selectForCustomer(customer: string) {

  return createSelector(
    selectState,
    (state: State) => {

      const entities = Object.values(state.entities)
      .filter((entity: Entity<Stripe.Subscription>) => {

        return entity.model && entity.model.customer === customer;

      });

      const many: ManyEntitiesState<Stripe.Subscription, string> = {
        ...state.all,
        ids: entities.map((entity: Entity<Stripe.Subscription>) => {

          return entity.model?.id as string;

        }),
        entities,
        models: entities.map((entity: Entity<Stripe.Subscription>) => {

          return entity.model as Stripe.Subscription;

        }),
      };

      return many;

    },
  )

}

export const selectMySubscriptions = createSelector(
  selectState,
  (state: State) => {

    const entities = state.my.ids
    .map((id: string) => {

      return getEntity(id, state);

    })
    .filter((entity: Entity<Stripe.Subscription>) => {

      return (entity.model) ? true : false;

    });

    const my: ManyEntitiesState<Stripe.Subscription, string> = {
      ...state.my,
      entities,
      models: entities.map((entity: Entity<Stripe.Subscription>) => {

        return entity.model as Stripe.Subscription;

      })
    };

    return my;

  }
);

export function selectOne(id: string) {

  return createSelector(
    selectState,
    (state: State) => {

      const entityId = createEntityId(id);

      const entity = getEntity(entityId, state);

      return entity;

    }
  );

}
