import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepContentComponent } from './step-content/step-content.component';
import { StepLabelComponent } from './step-label/step-label.component';
import { CardComponent } from './card/card.component';
import { MatCardModule } from '@angular/material/card';
import { RecommendationsModule } from '../../recommendations/recommendations.module';

@NgModule({
  declarations: [StepContentComponent, StepLabelComponent, CardComponent],
  exports: [StepContentComponent, StepLabelComponent, CardComponent],
  imports: [
    CommonModule,
    MatCardModule,
    RecommendationsModule,
  ],
})
export class ConsultingModule {}
