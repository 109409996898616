import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateFormComponent } from './update-form/update-form.component';
import { UpdateDialogComponent } from './update-dialog/update-dialog.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { CardComponent } from './card/card.component';
import { ContactInfoFormComponent } from './contact-info-form/contact-info-form.component';
import { CreateFormComponent } from './create-form/create-form.component';
import { ListTableComponent } from './list-table/list-table.component';
import { MySettingsDialogComponent } from './my-settings-dialog/my-settings-dialog.component';
import { MySettingsFormComponent } from './my-settings-form/my-settings-form.component';
import { NameComponent } from './name/name.component';
import { SelectUserComponent } from './select-user/select-user.component';
import { TermsOfServiceFormComponent } from './terms-of-service-form/terms-of-service-form.component';
import { NgPickerModule } from '@bcx/ng-helpers';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [
    CardComponent,
    ContactInfoFormComponent,
    CreateFormComponent,
    ListTableComponent,
    MySettingsDialogComponent,
    MySettingsFormComponent,
    NameComponent,
    SelectUserComponent,
    TermsOfServiceComponent,
    TermsOfServiceFormComponent,
    UpdateDialogComponent,
    UpdateFormComponent,
  ],
  exports: [
    CardComponent,
    ContactInfoFormComponent,
    CreateFormComponent,
    ListTableComponent,
    MySettingsDialogComponent,
    MySettingsFormComponent,
    NameComponent,
    SelectUserComponent,
    TermsOfServiceComponent,
    TermsOfServiceFormComponent,
    UpdateDialogComponent,
    UpdateFormComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTableModule,
    NgPickerModule,
    ReactiveFormsModule,
  ]
})
export class UserModule { }
