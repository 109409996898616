import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryCardComponent } from './summary-card/summary-card.component';
import { MatCardModule } from '@angular/material/card';
import { SeatModule } from '../../seat/seat.module';
import { ClassModule } from '../../class/class.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { StubComponent } from './stub/stub.component';
import { TearoffComponent } from './tearoff/tearoff.component';
import { PipesModule } from '@bcx/ng-helpers';

@NgModule({
  declarations: [SummaryCardComponent, StubComponent, TearoffComponent],
  exports: [SummaryCardComponent, StubComponent, TearoffComponent],
  imports: [
    ClassModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    PipesModule,
    SeatModule,
  ],
})
export class TicketClassModule {}
