<div>

  <mat-card
    *ngIf="seat">

    <mat-card-header>

      <mat-card-title>Your Seat</mat-card-title>
      <mat-card-subtitle>{{seat.status}}</mat-card-subtitle>

    </mat-card-header>

    <mat-card-content>

      <pre>{{seat | json}}</pre>

    </mat-card-content>

    <mat-card-actions>

      <ng-container *ngTemplateOutlet="actions; context: { seat }"></ng-container>

    </mat-card-actions>

  </mat-card>

</div>
