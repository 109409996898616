import { Component, Input, TemplateRef, } from '@angular/core';
import { CalendarWeek } from '../week';
import { CalendarEvent } from '../../ng-calendar.module';
import { Subject } from 'rxjs';

@Component({
  selector: 'ng-calendar-week',
  templateUrl: './week.component.html',
  styleUrls: ['./week.component.sass'],
})
export class WeekComponent {
  
  @Input() eventClick: Subject<CalendarEvent>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() linkTemplate: TemplateRef<any>;
  
  @Input() showMonthName = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() week: CalendarWeek<any>;

}
