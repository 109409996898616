import { List } from "@digitaltoolbuilders/model-helpers";
import { ACCOUNT, ACTIVE, CLOSED, INACTIVE, OPEN } from "../common";
import { ConsistentRead, ExclusiveStartKey, Limit } from "../helpers";
import { ModelInterface, ModelAction, ModelEvent, ModelAbstract } from "../model-action";

export class Account extends ModelAbstract implements AccountInterface {

  account_id: string;

  display_name: string;

  status: AccountStatus;

  constructor(input: Partial<AccountInterface>) {

    super(input);

    if (input.account_id) this.account_id = input.account_id;
    if (input.display_name) this.display_name = input.display_name;
    if (input.status) this.status = input.status;

  }

}

export class AccountCreateAction extends ModelAction<AccountCreatePayload> {

  override readonly action_name = AccountActionNameMap.CREATE;

  constructor(input: Partial<Omit<AccountCreateAction, 'action_name'>>) {

    super(input);

  }

}

export type AccountCreatePayload = Omit<Account, 'last_updated_epoch' | 'status'>;

export class AccountGetAction extends ModelAction<AccountGetPayload> {

  override readonly action_name = AccountActionNameMap.GET;

  constructor(input: Partial<Omit<AccountGetAction, 'action_name'>>) {

    super(input);

  }

}

export type AccountGetPayload = 
  & Pick<Account, 'account_id'>
  & {
    consistent_read: ConsistentRead;
  };

export class AccountListAction extends ModelAction<AccountListPayload> {

  override readonly action_name = AccountActionNameMap.LIST;

  constructor(input: Partial<Omit<AccountListAction, 'action_name'>>) {

    super(input);

  }

}
  
export type AccountListPayload = 
  & Partial<Pick<Account, 'status'>>
  & {
    exclusive_start_key: ExclusiveStartKey;
    limit: Limit;
  };

export const AccountActionNameMap = {
  CLOSE: `${ACCOUNT}/close`,
  CREATE: `${ACCOUNT}/create`,
  GET: `${ACCOUNT}/get`,
  LIST: `${ACCOUNT}/list`,
  SUSPEND: `${ACCOUNT}/suspend`,
} as const;

export type AccountActionName = typeof AccountActionNameMap[keyof typeof AccountActionNameMap];

export const AccountActionNames = Object.values(AccountActionNameMap);

export class AccountCreatedEvent extends ModelEvent<Account> {

  override event_name = AccountEventNameMap.CREATED;

  constructor(input: Partial<Omit<AccountCreatedEvent, 'event_name'>>) {

    super(input);

  }

}

export class AccountListedEvent extends ModelEvent<List<Account>> {

  override event_name = AccountEventNameMap.LISTED;

  constructor(input: Partial<Omit<AccountListedEvent, 'event_name'>>) {

    super(input);

  }

}

export class AccountViewedEvent extends ModelEvent<Account> {

  override event_name = AccountEventNameMap.VIEWED;

  constructor(input: Partial<Omit<AccountViewedEvent, 'event_name'>>) {

    super(input);

  }

}

export const AccountEventNameMap = {
  CLOSED: `${ACCOUNT}:closed`,
  CREATED: `${ACCOUNT}:created`,
  LISTED: `${ACCOUNT}:listed`,
  SUSPENDED: `${ACCOUNT}:suspended`,
  VIEWED: `${ACCOUNT}:viewed`,
} as const;

export type AccountEventName = typeof AccountEventNameMap[keyof typeof AccountEventNameMap];

export const AccountEventNames = Object.values(AccountEventNameMap);

export interface AccountInterface extends ModelInterface {

  account_id: string;

  display_name: string;

  status: AccountStatus;

}

export const AccountStatusMap = {
  ACTIVE,
  CLOSED,
  INACTIVE,
  OPEN,
} as const;

export type AccountStatus = typeof AccountStatusMap[keyof typeof AccountStatusMap];

export const AccountStatuses = Object.values(AccountStatusMap);
