import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttendeeModule } from './attendee/attendee.module';
import { AddAttendeeFormComponent } from './add-attendee-form/add-attendee-form.component';
import { CancelDialogComponent } from './cancel-dialog/cancel-dialog.component';
import { CancelFormComponent } from './cancel-form/cancel-form.component';
import { CategoryOptionComponent } from './category-option/category-option.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmFormComponent } from './confirm-form/confirm-form.component';
import { CreateDialogComponent } from './create-dialog/create-dialog.component';
import { CreateFormComponent } from './create-form/create-form.component';
import { FinishDialogComponent } from './finish-dialog/finish-dialog.component';
import { FinishFormComponent } from './finish-form/finish-form.component';
import { FullCardComponent } from './full-card/full-card.component';
import { ListGridComponent } from './list-grid/list-grid.component';
import { ListTableComponent } from './list-table/list-table.component';
import { OptionsFormComponent } from './options-form/options-form.component';
import { RequestFormComponent } from './request-form/request-form.component';
import { RescheduleDialogComponent } from './reschedule-dialog/reschedule-dialog.component';
import { RescheduleFormComponent } from './reschedule-form/reschedule-form.component';
import { RoleOptionComponent } from './role-option/role-option.component';
import { SelectCategoryComponent } from './select-category/select-category.component';
import { SelectLengthComponent } from './select-length/select-length.component';
import { SelectRoleComponent } from './select-role/select-role.component';
import { SelectTypeComponent } from './select-type/select-type.component';
import { SelectStartComponent } from './select-start/select-start.component';
import { SelectedPriceComponent } from './selected-price/selected-price.component';
import { StartDialogComponent } from './start-dialog/start-dialog.component';
import { StartFormComponent } from './start-form/start-form.component';
import { SummaryCardComponent } from './summary-card/summary-card.component';
import { TypeOptionComponent } from './type-option/type-option.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PipesModule as StripePipesModule } from '../../stripe/pipes/pipes.module';
import { NgPickerModule, PipesModule } from '@bcx/ng-helpers';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgCalendarModule } from '@engagedcx/ng-calendar';
import { MatDividerModule } from '@angular/material/divider';
import { SettingsModule } from '../../billing/settings/settings.module';

@NgModule({
  declarations: [
    AddAttendeeFormComponent,
    CancelDialogComponent,
    CancelFormComponent,
    CategoryOptionComponent,
    ConfirmDialogComponent,
    ConfirmFormComponent,
    CreateDialogComponent,
    CreateFormComponent,
    FinishDialogComponent,
    FinishFormComponent,
    FullCardComponent,
    ListGridComponent,
    ListTableComponent,
    OptionsFormComponent,
    RequestFormComponent,
    RescheduleDialogComponent,
    RescheduleFormComponent,
    RoleOptionComponent,
    SelectCategoryComponent,
    SelectLengthComponent,
    SelectRoleComponent,
    SelectStartComponent,
    SelectTypeComponent,
    SelectedPriceComponent,
    StartDialogComponent,
    StartFormComponent,
    SummaryCardComponent,
    TypeOptionComponent,
  ],
  exports: [
    AddAttendeeFormComponent,
    CancelDialogComponent,
    CancelFormComponent,
    CategoryOptionComponent,
    ConfirmDialogComponent,
    ConfirmFormComponent,
    CreateDialogComponent,
    CreateFormComponent,
    FinishDialogComponent,
    FinishFormComponent,
    FullCardComponent,
    ListGridComponent,
    ListTableComponent,
    OptionsFormComponent,
    RequestFormComponent,
    RescheduleDialogComponent,
    RescheduleFormComponent,
    RoleOptionComponent,
    SelectCategoryComponent,
    SelectLengthComponent,
    SelectRoleComponent,
    SelectStartComponent,
    SelectTypeComponent,
    SelectedPriceComponent,
    StartDialogComponent,
    StartFormComponent,
    SummaryCardComponent,
    TypeOptionComponent,
  ],
  imports: [
    AttendeeModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTableModule,
    NgCalendarModule,
    NgPickerModule,
    PipesModule,
    ReactiveFormsModule,
    SettingsModule,
    StripePipesModule,
  ]
})
export class SessionModule { }
