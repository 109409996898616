import { Injectable } from '@angular/core';
import { UserServiceAdapter } from './user.service.adapter';
import { WebSocketService } from '@engagedcx/ng-amplify';
import { User, UserChangeRolesAction, UserCreateAction, UserCreatedEvent, UserGetAction, UserInterface, UserListAction, UserListedEvent, UserRolesChangedEvent, UserUpdateAction, UserUpdatedEvent, UserViewedEvent } from '@bcx/models';
import { List } from '@digitaltoolbuilders/model-helpers';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements UserServiceAdapter {

  constructor(private websocket: WebSocketService) { }

  changeRoles(action: UserChangeRolesAction): Promise<UserRolesChangedEvent> {
    
    return this.websocket.request<UserInterface>(action)
    .then((message) => {

      return new UserRolesChangedEvent({
        ...message,
        payload: new User(message.payload),
      });

    });

  }

  create(action: UserCreateAction): Promise<UserCreatedEvent> {
    
    return this.websocket.request<UserInterface>(action)
    .then((message) => {

      return new UserCreatedEvent({
        ...message,
        payload: new User(message.payload),
      });

    });

  }

  get(action: UserGetAction): Promise<UserViewedEvent> {
    
    return this.websocket.request<UserInterface>(action)
    .then((message) => {

      return new UserViewedEvent({
        ...message,
        payload: new User(message.payload),
      });

    });

  }

  list(action: UserListAction): Promise<UserListedEvent> {
    
    return this.websocket.request<List<UserInterface>>(action)
    .then((message) => {

      return new UserListedEvent({
        ...message,
        payload: new List<User>({
          ...message.payload,
          list: message.payload.list.map((item) => new User(item)),
        }),
      });

    });

  }

  update(action: UserUpdateAction): Promise<UserUpdatedEvent> {
    
    return this.websocket.request<UserInterface>(action)
    .then((message) => {

      return new UserUpdatedEvent({
        ...message,
        payload: new User(message.payload),
      });

    });

  }

}
