import {Stripe} from "stripe";
import { ModelAction, ModelEvent } from "../model-action";
import { STRIPE_PAYMENT_METHOD } from "../common";

export const StripePaymentMethodActionNameMap = {
  CREATE: `${STRIPE_PAYMENT_METHOD}/create`,
  GET: `${STRIPE_PAYMENT_METHOD}/get`,
  LIST: `${STRIPE_PAYMENT_METHOD}/list`,
  UPDATE: `${STRIPE_PAYMENT_METHOD}/update`,
} as const;

export type StripePaymentMethodActionName = typeof StripePaymentMethodActionNameMap[keyof typeof StripePaymentMethodActionNameMap];

export const StripePaymentMethodActionNames = Object.values(StripePaymentMethodActionNameMap);

export class StripePaymentMethodCreateAction extends ModelAction<StripePaymentMethodCreatePayload> {

  readonly action_name = StripePaymentMethodActionNameMap.CREATE;

  constructor(input: Partial<Omit<StripePaymentMethodCreateAction, 'action_name'>>){

    super(input);

  }

}

export class StripePaymentMethodCreatedEvent extends ModelEvent<Stripe.PaymentMethod> {

  readonly event_name = StripePaymentMethodEventNameMap.CREATED;

  constructor(input: Partial<Omit<StripePaymentMethodCreatedEvent, 'event_name'>>) {

    super(input);

  }

}

export type StripePaymentMethodCreatePayload = Stripe.PaymentMethodCreateParams;

export const StripePaymentMethodEventNameMap = {
  CREATED: `${STRIPE_PAYMENT_METHOD}:created`,
  LISTED: `${STRIPE_PAYMENT_METHOD}:listed`,
  UPDATED: `${STRIPE_PAYMENT_METHOD}:updated`,
  VIEWED: `${STRIPE_PAYMENT_METHOD}:viewed`,
} as const;

export type StripePaymentMethodEventName = typeof StripePaymentMethodEventNameMap[keyof typeof StripePaymentMethodEventNameMap];

export const StripePaymentMethodEventNames = Object.values(StripePaymentMethodEventNameMap);

export class StripePaymentMethodGetAction extends ModelAction<StripePaymentMethodGetPayload> {

  readonly action_name = StripePaymentMethodActionNameMap.GET;

  constructor(input: Partial<Omit<StripePaymentMethodGetAction, 'action_name'>>){

    super(input);

  }

}

export type StripePaymentMethodGetPayload = Stripe.PaymentMethodRetrieveParams & {
  id: string;
};

export class StripePaymentMethodListAction extends ModelAction<StripePaymentMethodListPayload> {

  readonly action_name = StripePaymentMethodActionNameMap.LIST;

  constructor(input: Partial<Omit<StripePaymentMethodListAction, 'action_name'>>){

    super(input);

  }

}

export type StripePaymentMethodListPayload = Stripe.PaymentMethodListParams;

export class StripePaymentMethodListedEvent extends ModelEvent<Stripe.ApiList<Stripe.PaymentMethod>> {

  readonly event_name = StripePaymentMethodEventNameMap.LISTED;

  constructor(input: Partial<Omit<StripePaymentMethodListedEvent, 'event_name'>>) {

    super(input);

  }

}

export class StripePaymentMethodViewedEvent extends ModelEvent<Stripe.PaymentMethod> {

  readonly event_name = StripePaymentMethodEventNameMap.VIEWED;

  constructor(input: Partial<Omit<StripePaymentMethodViewedEvent, 'event_name'>>) {

    super(input);

  }

}
