<mat-card>

  <mat-card-content>

    <ul>
      <li>increase your customer base</li>
      <li>raise customer satifaction ratings</li>
      <li>get more referrals</li>
      <li>lower your turn over rate</li>
      <li>increase average purchase size</li>
      <li>sell more premium products or services</li>
    </ul>

  </mat-card-content>

</mat-card>