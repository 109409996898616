import { Injectable } from '@angular/core';
import { AttendeeServiceAdapter } from './attendee.service.adapter';
import { WebSocketService } from '@engagedcx/ng-amplify';
import { ConsultingSessionAttendee, ConsultingSessionAttendeeAddAction, ConsultingSessionAttendeeAddedEvent, ConsultingSessionAttendeeArriveAction, ConsultingSessionAttendeeArrivedEvent, ConsultingSessionAttendeeCancelAction, ConsultingSessionAttendeeCanceledEvent, ConsultingSessionAttendeeConfirmAction, ConsultingSessionAttendeeConfirmedEvent, ConsultingSessionAttendeeGetAction, ConsultingSessionAttendeeInterface, ConsultingSessionAttendeeLeaveAction, ConsultingSessionAttendeeLeftEvent, ConsultingSessionAttendeeListAction, ConsultingSessionAttendeeListedEvent, ConsultingSessionAttendeeRemoveAction, ConsultingSessionAttendeeRemovedEvent, ConsultingSessionAttendeeViewedEvent } from '@bcx/models';
import { List } from '@digitaltoolbuilders/model-helpers';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements AttendeeServiceAdapter {

  constructor(private socket: WebSocketService) { }

  add(action: ConsultingSessionAttendeeAddAction): Promise<ConsultingSessionAttendeeAddedEvent> {
    
    return this.socket.request<ConsultingSessionAttendeeInterface>(action)
    .then((message) => {

      return new ConsultingSessionAttendeeAddedEvent({
        ...message,
        payload: new ConsultingSessionAttendee(message.payload),
      });

    });

  }

  arrive(action: ConsultingSessionAttendeeArriveAction): Promise<ConsultingSessionAttendeeArrivedEvent> {
      
    return this.socket.request<ConsultingSessionAttendeeInterface>(action)
    .then((message) => {

      return new ConsultingSessionAttendeeArrivedEvent({
        ...message,
        payload: new ConsultingSessionAttendee(message.payload),
      });

    });

  }

  cancel(action: ConsultingSessionAttendeeCancelAction): Promise<ConsultingSessionAttendeeCanceledEvent> {
      
    return this.socket.request<ConsultingSessionAttendeeInterface>(action)
    .then((message) => {

      return new ConsultingSessionAttendeeCanceledEvent({
        ...message,
        payload: new ConsultingSessionAttendee(message.payload),
      });

    });

  }

  confirm(action: ConsultingSessionAttendeeConfirmAction): Promise<ConsultingSessionAttendeeConfirmedEvent> {
      
    return this.socket.request<ConsultingSessionAttendeeInterface>(action)
    .then((message) => {

      return new ConsultingSessionAttendeeConfirmedEvent({
        ...message,
        payload: new ConsultingSessionAttendee(message.payload),
      });

    });

  }

  get(action: ConsultingSessionAttendeeGetAction): Promise<ConsultingSessionAttendeeViewedEvent> {
    
    return this.socket.request<ConsultingSessionAttendeeInterface>(action)
    .then((message) => {

      return new ConsultingSessionAttendeeViewedEvent({
        ...message,
        payload: new ConsultingSessionAttendee(message.payload),
      });

    });

  }

  list(action: ConsultingSessionAttendeeListAction): Promise<ConsultingSessionAttendeeListedEvent> {
      
    return this.socket.request<List<ConsultingSessionAttendeeInterface>>(action)
    .then((message) => {

      return new ConsultingSessionAttendeeListedEvent({
        ...message,
        payload: new List<ConsultingSessionAttendee>({
          ...message.payload,
          list: message.payload.list.map((item) => new ConsultingSessionAttendee(item)),
        }),
      });

    });

  }

  leave(action: ConsultingSessionAttendeeLeaveAction): Promise<ConsultingSessionAttendeeLeftEvent> {
      
    return this.socket.request<ConsultingSessionAttendeeInterface>(action)
    .then((message) => {

      return new ConsultingSessionAttendeeLeftEvent({
        ...message,
        payload: new ConsultingSessionAttendee(message.payload),
      });

    });

  }

  remove(action: ConsultingSessionAttendeeRemoveAction): Promise<ConsultingSessionAttendeeRemovedEvent> {
      
    return this.socket.request<ConsultingSessionAttendeeInterface>(action)
    .then((message) => {

      return new ConsultingSessionAttendeeRemovedEvent({
        ...message,
        payload: new ConsultingSessionAttendee(message.payload),
      });

    });

  }

}
