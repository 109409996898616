import { Component, Input, TemplateRef } from '@angular/core';
import { BillingSettings } from '@bcx/models';
import {Stripe} from 'stripe';

@Component({
  selector: 'ng-billing-settings-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.sass'],
})
export class CardComponent {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() paymentMethod?: Stripe.PaymentMethod;

  @Input() settings: BillingSettings;

  @Input() subtitle?: string;

  @Input() title?: string;

}
