<div fxLayout="column">

  <table mat-table [dataSource]="table">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let ticket">
        <ng-container *ngTemplateOutlet="actions; context: { ticket }"></ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="class_id">
      <th mat-header-cell *matHeaderCellDef>Class Id</th>
      <td mat-cell *matCellDef="let ticket">{{ticket.class_id}}</td>
    </ng-container>
    <ng-container matColumnDef="last_updated_epoch">
      <th mat-header-cell *matHeaderCellDef>Last Updated Epoch</th>
      <td mat-cell *matCellDef="let ticket">{{ticket.last_updated_epoch}}</td>
    </ng-container>
    <ng-container matColumnDef="ticket_id">
      <th mat-header-cell *matHeaderCellDef>Ticket Id</th>
      <td mat-cell *matCellDef="let ticket">{{ticket.ticket_id}}</td>
    </ng-container>
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let ticket">{{ticket.role}}</td>
    </ng-container>
    <ng-container matColumnDef="class_seat_id">
      <th mat-header-cell *matHeaderCellDef>Class Seat Id</th>
      <td mat-cell *matCellDef="let ticket">{{ticket.class_seat_id}}</td>
    </ng-container>
    <ng-container matColumnDef="class_number">
      <th mat-header-cell *matHeaderCellDef>Class Number</th>
      <td mat-cell *matCellDef="let ticket">{{ticket.class_number}}</td>
    </ng-container>
    <ng-container matColumnDef="stripe_invoice_id">
      <th mat-header-cell *matHeaderCellDef>Stripe Invoice Id</th>
      <td mat-cell *matCellDef="let ticket">{{ticket.stripe_invoice_id}}</td>
    </ng-container>
    <ng-container matColumnDef="user_id">
      <th mat-header-cell *matHeaderCellDef>User Id</th>
      <td mat-cell *matCellDef="let ticket">{{ticket.user_id}}</td>
    </ng-container>
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>State</th>
      <td mat-cell *matCellDef="let ticket">{{ticket.state}}</td>
    </ng-container>
    <ng-container matColumnDef="state_changes">
      <th mat-header-cell *matHeaderCellDef>State Changes</th>
      <td mat-cell *matCellDef="let ticket">{{ticket.state_changes}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="display"></tr>
    <tr mat-row *matRowDef="let ticket; columns: display"></tr>

    <tr class="mat-row" *matNoRowsDef><td [attr.colspan]="display.length">None</td></tr>
  </table>

</div>
