
import { FormGroup } from '@angular/forms';
import { SubsComponent } from '@bcx/ng-helpers';
import { isString } from 'lodash';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

export abstract class ListBaseComponent extends SubsComponent {

  abstract form: FormGroup;

  protected progress = new BehaviorSubject(0);

  progress$ = this.progress.pipe(distinctUntilChanged());

  calculateProgress() {

    let valid = 0;

    let total = 0;

    Object.keys(this.form.controls).forEach((key) => {

      if (key === 'other') return;

      total++;

      // console.log('calculateProgress', key, this.form.controls[key]);

      if (this.form.controls[key].valid) {

        valid++;

      }

    });

    const progress = Math.ceil((valid / total) * 100);

    // console.log('calculateProgress', { progress, completed: valid, total });

    this.progress.next(progress);

  }

  hasAnswer(answer?: string) {

    return isString(answer);

  }

}
