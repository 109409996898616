<div fxLayout="column">

  <table mat-table [dataSource]="table">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let agreement">
        <ng-container *ngTemplateOutlet="actions; context: { agreement }"></ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="agreement_id">
      <th mat-header-cell *matHeaderCellDef>Agreement Id</th>
      <td mat-cell *matCellDef="let agreement">{{agreement.agreement_id}}</td>
    </ng-container>
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>State</th>
      <td mat-cell *matCellDef="let agreement">{{agreement.state}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="display"></tr>
    <tr mat-row *matRowDef="let agreement; columns: display"></tr>

    <tr class="mat-row" *matNoDataRow><td [attr.colspan]>No Agreements</td></tr>
  </table>

</div>
