
import { AVAILABLE, BOOK, CLASS, REDEEMED, REVOKED, SUSPENDED, TRAINING_TICKET, VIDEO } from "../common";
import { ConsistentRead, ExclusiveStartKey, Limit } from "../helpers";
import { ModelAbstract, ModelAction, ModelEvent } from "../model-action";
import { TrainingBookType } from "./books";
import { TrainingClassType } from "./class";
import { TrainingCourse } from "./courses";
import { List } from "@digitaltoolbuilders/model-helpers";

export class TrainingTicket extends ModelAbstract implements TrainingTicketInterface {

  book_id?: string;

  book_type?: TrainingBookType;

  class_id?: string;

  class_seat_id?: string;

  class_type?: TrainingClassType;

  course: TrainingCourse;

  course_class_number?: number;

  payment_agreement_id?: string;

  redemption_epoch?: number;

  status: TrainingTicketStatus;

  status_epoch: number;

  stripe_invoice_id: string;
  
  ticket_id: string;

  ticket_type: TrainingTicketType;

  user_id: string;

  constructor(input: Partial<TrainingTicket>) {

    super(input);

    if (input.book_id) this.book_id = input.book_id;
    if (input.book_type) this.book_type = input.book_type;
    if (input.class_id) this.class_id = input.class_id;
    if (input.class_seat_id) this.class_seat_id = input.class_seat_id;
    if (input.class_type) this.class_type = input.class_type;
    if (input.course) this.course = input.course;
    if (input.course_class_number) this.course_class_number = input.course_class_number;
    if (input.payment_agreement_id) this.payment_agreement_id = input.payment_agreement_id;
    if (input.redemption_epoch) this.redemption_epoch = input.redemption_epoch;
    if (input.status) this.status = input.status;
    if (input.status_epoch) this.status_epoch = input.status_epoch;
    if (input.stripe_invoice_id) this.stripe_invoice_id = input.stripe_invoice_id;
    if (input.ticket_id) this.ticket_id = input.ticket_id;
    if (input.ticket_type) this.ticket_type = input.ticket_type;
    if (input.user_id) this.user_id = input.user_id;

  }

}

export const TrainingTicketActionNameMap = {
  CANCEL: `${TRAINING_TICKET}/cancel`,
  CREATE: `${TRAINING_TICKET}/create`,
  GET: `${TRAINING_TICKET}/get`,
  LIST: `${TRAINING_TICKET}/list`,
  REDEEM: `${TRAINING_TICKET}/redeem`,
  REVOKE: `${TRAINING_TICKET}/revoke`,
  SUSPEND: `${TRAINING_TICKET}/suspend`,
  UPDATE: `${TRAINING_TICKET}/update`,
} as const;

export type TrainingTicketActionName = typeof TrainingTicketActionNameMap[keyof typeof TrainingTicketActionNameMap];

export const TrainingTicketActionNames = Object.values(TrainingTicketActionNameMap);

export class TrainingTicketCancelAction extends ModelAction<TrainingTicketCancelPayload> {

  readonly action_name = TrainingTicketActionNameMap.CANCEL;

  constructor(input: Partial<Omit<TrainingTicketCancelAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingTicketCanceledEvent extends ModelEvent<TrainingTicket> {

  readonly event_name = TrainingTicketEventNameMap.CANCELED;

  constructor(input: Partial<Omit<TrainingTicketCanceledEvent, 'event_name'>>) {

    super(input);
    
  }

}

export type TrainingTicketCancelPayload = Pick<TrainingTicket, 'last_updated_epoch' | 'ticket_id'>;

export class TrainingTicketCreateAction extends ModelAction<TrainingTicketCreatePayload> {

  readonly action_name = TrainingTicketActionNameMap.CREATE;

  constructor(input: Partial<Omit<TrainingTicketCreateAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingTicketCreatedEvent extends ModelEvent<TrainingTicket> {

  readonly event_name = TrainingTicketEventNameMap.CREATED;

  constructor(input: Partial<Omit<TrainingTicketCreatedEvent, 'event_name'>>) {

    super(input);
    
  }

}

export type TrainingTicketCreatePayload = Omit<TrainingTicket, 'last_updated_epoch'>;

export const TrainingTicketEventNameMap = {
  CANCELED: `${TRAINING_TICKET}:canceled`,
  CREATED: `${TRAINING_TICKET}:created`,
  LISTED: `${TRAINING_TICKET}:listed`,
  REDEEMED: `${TRAINING_TICKET}:redeemed`,
  REVOKED: `${TRAINING_TICKET}:revoked`,
  SUSPENDED: `${TRAINING_TICKET}:suspended`,
  UPDATED: `${TRAINING_TICKET}:updated`,
  VIEWED: `${TRAINING_TICKET}:viewed`,
} as const;

export type TrainingTicketEventName = typeof TrainingTicketEventNameMap[keyof typeof TrainingTicketEventNameMap];

export const TrainingTicketEventNames = Object.values(TrainingTicketEventNameMap);

export class TrainingTicketGetAction extends ModelAction<TrainingTicketGetPayload> {

  readonly action_name = TrainingTicketActionNameMap.GET;

  constructor(input: Partial<Omit<TrainingTicketGetAction, 'action_name'>>) {

    super(input);

  }

}

export type TrainingTicketGetPayload = Pick<TrainingTicket, 'ticket_id'> & {
  consistent_read?: ConsistentRead;
};

export class TrainingTicketListAction extends ModelAction<TrainingTicketListPayload> {

  readonly action_name = TrainingTicketActionNameMap.LIST;

  constructor(input: Partial<Omit<TrainingTicketListAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingTicketListedEvent extends ModelEvent<List<TrainingTicket>> {

  readonly event_name = TrainingTicketEventNameMap.LISTED;

  constructor(input: Partial<Omit<TrainingTicketListedEvent, 'event_name'>>) {

    super(input);
    
  }

}

export type TrainingTicketListPayload = Partial<Pick<TrainingTicket, 'payment_agreement_id' | 'status'>> & {
  exclusive_start_key?: ExclusiveStartKey;
  limit?: Limit;
};

export class TrainingTicketRedeemAction extends ModelAction<TrainingTicketRedeemPayload> {

  readonly action_name = TrainingTicketActionNameMap.REDEEM;

  constructor(input: Partial<Omit<TrainingTicketRedeemAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingTicketRedeemedEvent extends ModelEvent<TrainingTicket> {

  readonly event_name = TrainingTicketEventNameMap.REDEEMED;

  constructor(input: Partial<Omit<TrainingTicketRedeemedEvent, 'event_name'>>) {

    super(input);
    
  }

}

export type TrainingTicketRedeemPayload = Pick<TrainingTicket, 'last_updated_epoch' | 'ticket_id'>;

export class TrainingTicketRevokeAction extends ModelAction<TrainingTicketRevokePayload> {

  readonly action_name = TrainingTicketActionNameMap.REVOKE;

  constructor(input: Partial<Omit<TrainingTicketRevokeAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingTicketRevokedEvent extends ModelEvent<TrainingTicket> {

  readonly event_name = TrainingTicketEventNameMap.REVOKED;

  constructor(input: Partial<Omit<TrainingTicketRevokedEvent, 'event_name'>>) {

    super(input);
    
  }

}

export type TrainingTicketRevokePayload = Pick<TrainingTicket, 'last_updated_epoch' | 'ticket_id'>;

export class TrainingTicketSuspendAction extends ModelAction<TrainingTicketSuspendPayload> {

  readonly action_name = TrainingTicketActionNameMap.SUSPEND;

  constructor(input: Partial<Omit<TrainingTicketSuspendAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingTicketSuspendedEvent extends ModelEvent<TrainingTicket> {

  readonly event_name = TrainingTicketEventNameMap.SUSPENDED;

  constructor(input: Partial<Omit<TrainingTicketSuspendedEvent, 'event_name'>>) {

    super(input);
    
  }

}

export type TrainingTicketSuspendPayload = Pick<TrainingTicket, 'last_updated_epoch' | 'ticket_id'>;

export class TrainingTicketUpdateAction extends ModelAction<TrainingTicketUpdatePayload> {

  readonly action_name = TrainingTicketActionNameMap.UPDATE;

  constructor(input: Partial<Omit<TrainingTicketUpdateAction, 'action_name'>>) {

    super(input);

  }

}

export class TrainingTicketUpdatedEvent extends ModelEvent<TrainingTicket> {

  readonly event_name = TrainingTicketEventNameMap.UPDATED;

  constructor(input: Partial<Omit<TrainingTicketUpdatedEvent, 'event_name'>>) {

    super(input);
    
  }

}

export type TrainingTicketUpdatePayload = Pick<TrainingTicket, 'last_updated_epoch' | 'ticket_id'>;

export interface TrainingTicketInterface extends ModelAbstract {

  book_id?: string;

  book_type?: TrainingBookType;

  class_id?: string;

  class_seat_id?: string;

  class_type?: TrainingClassType;

  course: TrainingCourse;

  course_class_number?: number;

  payment_agreement_id?: string;

  redemption_epoch?: number;

  status: TrainingTicketStatus;

  status_epoch: number;

  stripe_invoice_id: string;

  ticket_id: string;

  ticket_type: TrainingTicketType;

  user_id: string;

  video_id?: string;

}

export const TrainingTicketStatusMap = {
  AVAILABLE,
  REDEEMED,
  REVOKED,
  SUSPENDED,
} as const;

export type TrainingTicketStatus = typeof TrainingTicketStatusMap[keyof typeof TrainingTicketStatusMap];

export const TrainingTicketStatuses = Object.values(TrainingTicketStatusMap);

export const TrainingTicketTypeMap = {
  BOOK,
  CLASS,
  VIDEO,
} as const;

export type TrainingTicketType = typeof TrainingTicketTypeMap[keyof typeof TrainingTicketTypeMap];

export const TrainingTicketTypes = Object.values(TrainingTicketTypeMap);

export class TrainingTicketViewedEvent extends ModelEvent<TrainingTicket> {

  readonly event_name = TrainingTicketEventNameMap.VIEWED;

  constructor(input: Partial<Omit<TrainingTicketViewedEvent, 'event_name'>>) {

    super(input);

  }

}
