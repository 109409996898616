import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubsComponent } from '@bcx/ng-helpers';
import { SaveForm } from '../save-form/save-form.form';
import {Stripe} from 'stripe';
import { filter, map, switchMap } from 'rxjs';
import { BillingSettings } from '@bcx/models';
import { CustomerService } from '../../../stripe/customer/customer.service';
import { PaymentMethodService } from '../../../stripe/payment-method/payment-method.service';
import { SettingsService } from '../../../billing/settings/settings.service';

@Component({
  selector: 'ng-billing-settings-save-dialog',
  templateUrl: './save-dialog.component.html',
  styleUrls: ['./save-dialog.component.sass'],
})
export class SaveDialogComponent extends SubsComponent implements OnInit {

  error?: string;

  form: SaveForm;

  paymentMethods: Array<Stripe.PaymentMethod> = [];

  paymentMethodsError?: string;

  paymentMethodsLoading: boolean;

  processing: boolean;

  settings: BillingSettings;

  constructor(
    private customerService: CustomerService,
    @Inject(MAT_DIALOG_DATA) data: BillingSettings,
    private dialog: MatDialogRef<SaveDialogComponent>,
    private paymentMethodService: PaymentMethodService,
    private service: SettingsService,
  ) {

    super();

    this.form = new SaveForm();

    this.settings = data;

    this.form.patchValue(this.settings);

  }

  closeDialog() {

    this.dialog.close();

  }
  
  ngOnInit(): void {
    
    function isCustomer(customer?: Stripe.Customer): customer is Stripe.Customer {

      return customer !== undefined;

    }

    this.subs['paymentMethods'] = this.customerService.my$
    .pipe(map(({ model }) => {

      return model;

    }))
    .pipe(filter(isCustomer))
    .pipe(switchMap((customer: Stripe.Customer) => {

      return this.paymentMethodService.selectForCustomer(customer.id);

    }))
    .subscribe(({ error, loading, models }) => {

      this.paymentMethods = models;

      this.paymentMethodsError = error;

      this.paymentMethodsLoading = loading;

    });

    // this.subs['settings'] = this.service.select(this.settings.user_id)
    // .subscribe(({ error, processing }) => {

    //   this.error = error;

    //   this.processing = processing;

    // });

    this.customerService.my()
    .then((customer: Stripe.Customer) => {

      return this.paymentMethodService.listForCustomer({
        customer: customer.id,
      });

    })
    .catch((e: Error) => {

      console.error(e);

    });

  }

  submit() {

    // return this.service.save({
    //   ...this.form.value,
    //   user_id: this.settings.user_id,
    // })
    // .then((saved: BillingSettings) => {

    //   this.dialog.close(saved);

    // })
    // .catch((e: Error) => {

    //   console.error(e);

    // });

  }

}
