
import { endOfDay, format, startOfDay } from 'date-fns';
import { CalendarEvent } from '../event/event';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class CalendarDay<Data = any> {

  begins: Date;

  dayOfMonth: number;

  dayOfWeek: string;

  ends: Date;

  events: Array<CalendarEvent<Data>> = [];

  month: number;

  monthName: string;

  constructor(day: Date) {

    this.begins = startOfDay(day);

    this.ends = endOfDay(day);

    this.dayOfMonth = day.getDate();

    this.dayOfWeek = format(day, 'E');

    this.month = day.getMonth() + 1;

    this.monthName = format(day, 'LLLL');

  }

}
