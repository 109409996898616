import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SeatService } from '../seat.service';
import { ReleaseForm } from '../release-form/release-form.form';
import { TrainingSeat } from '@bcx/models';
import { SubsComponent } from '@bcx/ng-helpers';

@Component({
  selector: 'ng-training-release-dialog',
  templateUrl: './release-dialog.component.html',
  styleUrls: ['./release-dialog.component.sass'],
})
export class ReleaseDialogComponent extends SubsComponent {

  error?: string;

  form = new ReleaseForm();

  processing: boolean;

  seat: TrainingSeat;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: TrainingSeat,
    private dialog: MatDialogRef<ReleaseDialogComponent>,
    private service: SeatService,
  ) {

    super();

    this.seat = data;

  }

  closeDialog() {

    this.dialog.close();

  }

  submit() {

    return this.service.release(this.seat, {
      reason: this.form.value.reason,
    })
    .then((released: TrainingSeat) => {

      this.dialog.close(released);

    })
    .catch((e: Error) => {

      console.error(e);

    });

  }

}
