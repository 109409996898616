import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClassService } from '../class.service';
import { TrainingClass } from '@bcx/models';

@Component({
  selector: 'ng-training-class-smart-card',
  templateUrl: './smart-card.component.html',
  styleUrls: ['./smart-card.component.sass'],
})
export class SmartCardComponent implements OnChanges, OnDestroy, OnInit {

  @Input() class_id?: string;

  error?: string;

  loading: boolean;

  private subs: {[key:string]:Subscription} = {};

  trainingClass?: TrainingClass;

  constructor(private service: ClassService) {}

  fetchClass() {

    if (this.class_id) {
      
      return this.service.get({class_id: this.class_id})
      .catch((e: any) => {

        console.error(e);
        
      });

    } else {

      return Promise.reject(new Error('no class_id'));

    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['class_id']) {

      this.onClassIdChange();

    }

  }

  ngOnDestroy(): void {
    
    Object.values(this.subs).forEach(sub => sub.unsubscribe());

  }

  ngOnInit(): void {



  }

  private onClassIdChange() {

    if (this.subs['class']) {

      this.subs['class'].unsubscribe();

    }

    if (this.class_id) {

      // this.subs['class'] = this.service.select(this.class_id)
      // .subscribe((entity: Entity<TrainingClass>) => {

      //   this.error = entity.error;

      //   this.loading = entity.loading;

      //   this.trainingClass = entity.entity;

      // });

      this.fetchClass();

    }

  }

}
