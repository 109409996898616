import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SaveForm } from './save-form.form';
import { Stripe } from 'stripe';

@Component({
  selector: 'ng-billing-settings-save-form',
  templateUrl: './save-form.component.html',
  styleUrls: ['./save-form.component.sass'],
})
export class SaveFormComponent implements OnChanges {

  currency: FormControl;

  defaultPaymentMethod: FormControl;

  @Input() form: SaveForm;

  @Input() paymentMethods: Array<Stripe.PaymentMethod> = [];

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['form'] && this.form) {

      this.currency = this.form.get('currency') as FormControl;
      this.defaultPaymentMethod = this.form.get('default_payment_method') as FormControl;

    }

  }

}
