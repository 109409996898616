import { Inject, Injectable } from '@angular/core';
import { ConfirmSignInOutput, SignInInput, SignInOutput, confirmSignIn, signIn, signUp, SignUpOutput, fetchAuthSession, getCurrentUser, AuthUser, signOut, AuthSession } from 'aws-amplify/auth';
import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';
import { BehaviorSubject, distinctUntilChanged, filter, firstValueFrom } from 'rxjs';
// import { LoggerService, createDeferred } from '@elite-cs/ng-helpers';
import { Hub } from 'aws-amplify/utils';
import { Logger } from 'pino';
import { AWSCredentials, JWT } from '@aws-amplify/core/internals/utils';
import { DEFAULT_LOGGER } from '../logger';
import { createDeferred } from '@bcx/ng-helpers';

export interface IdentityTokenPayload {

  aud?: string | string[];

  auth_time: number;

  'cognito:username': string;

  email: string;

  email_verified: boolean;

  event_id: string;

  exp?: number;

  iat?: number;

  iss?: string;

  jti?: string;

  name: string;

  nbf?: number;

  origin_jti: string;

  phone_number: string;

  phone_number_verified: boolean;

  scope?: string;

  sub?: string

  token_use: string;
  
  zoneinfo: string;

}

export interface ExtendedIdentityTokenPayload extends IdentityTokenPayload {

  roles: Array<string>;

  user_id: string;

  user_state: string;

}

export interface UserAttributes {

  address?: string;

  birthdate?: string;

  family_name?: string;

  gender?: string;

  given_name?: string;

  locale?: string;

  middle_name?: string;

  name: string;

  nickname?: string;

  picture?: string;

  zoneinfo?: string;

  [key: string]: string | undefined;

}

export function areCredentialsDistinct(prev: AWSCredentials, curr: AWSCredentials) {

  return prev.accessKeyId === curr.accessKeyId
    || prev.secretAccessKey === curr.secretAccessKey
    || prev.sessionToken === curr.sessionToken;

}

export function isAWSCredentials(creds?: AWSCredentials): creds is AWSCredentials {

  return (creds) ? true : false;

}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private accessToken = new BehaviorSubject<JWT | undefined>(undefined);

  accessToken$ = this.accessToken.asObservable();

  private authenticating = new BehaviorSubject<boolean>(false);

  authenticating$ = this.authenticating.asObservable()
  .pipe(distinctUntilChanged());

  private authUser = new BehaviorSubject<AuthUser | undefined>(undefined);

  authUser$ = this.authUser.asObservable();

  codeSent = false;

  private credentials = new BehaviorSubject<AWSCredentials | undefined>(undefined);

  credentials$ = this.credentials.asObservable()
  .pipe(filter(isAWSCredentials))
  .pipe(distinctUntilChanged(areCredentialsDistinct));

  currentUser$ = this.authUser$.pipe(filter((authedUser?: AuthUser): authedUser is AuthUser => {

    return (authedUser) ? true : false;

  }));

  private identity = new BehaviorSubject<ExtendedIdentityTokenPayload | undefined>(undefined);

  identity$ = this.identity.asObservable();

  private identityToken = new BehaviorSubject<JWT | undefined>(undefined);

  identityToken$ = this.identityToken.asObservable();

  private initialDeferred = createDeferred();

  private initial = this.initialDeferred.promise;

  isSignedIn = false;

  private logger: Logger;

  private waitForAuthedPromise = firstValueFrom(this.currentUser$);

  private waitForCredsPromise = firstValueFrom(this.credentials$);

  constructor(
    @Inject(DEFAULT_LOGGER) parent: Logger,
  ) { 

    this.logger = parent.child({
      service: 'AuthService',
    });

  }

  getCredentials() {

    return firstValueFrom(this.credentials$);

  }

  getIdentity() {

    return this.identity.getValue();

  }

  getSession() {

    return fetchAuthSession();

  }

  init() {

    Hub.listen('auth', ( capsule ) => {

      switch (capsule.payload.event) {

        case 'tokenRefresh':
        case 'signedIn':
        case 'signedOut':

          this.reload();
          
          break;

      }

    });

    Hub.listen('core', ( capsule ) => {

      switch (capsule.payload.event) {
        
        case 'configure':

          this.load();
          
          break;
      
      }

    });

  }

  isAuthed() {

    return this.initial
    .then(() => {

      return fetchAuthSession();

    })
    .then((session: AuthSession) => {

      return (session.tokens?.idToken) ? true : false;

    })
    .catch((e: Error) => {

      if (e.name !== "UserUnAuthenticatedException") {

        this.logger.error(e);

      }

      return false;

    });

  }

  private load() {

    return fetchAuthSession()
    .then(({ credentials, identityId, tokens, userSub, }) => {

      this.credentials.next(credentials);

      this.accessToken.next(tokens?.accessToken);

      this.identityToken.next(tokens?.idToken);

      if (tokens?.idToken?.payload) {

        // eslint-disable-next-line
        const anypayload = tokens?.idToken?.payload as any;

        const payload: ExtendedIdentityTokenPayload = {
          ...tokens?.idToken?.payload as unknown as IdentityTokenPayload,
          roles: JSON.parse(anypayload.roles),
          user_id: anypayload.user_id,
          user_state: anypayload.user_state,
        };

        this.identity.next(payload);

        return getCurrentUser()
        .then((authUser: AuthUser) => {
  
          this.authUser.next(authUser);
  
          const result = {
            authUser,
            credentials,
            identityId,
            tokens,
            userSub,
          };
  
          return result;
  
        });
  
      } else {
  
        return {};

      }

    })
    .then((result) => {

      this.logger.debug(result, 'loaded');
  
      this.initialDeferred.resolve(true);

      return result;

    })
    .catch((e: Error) => {

      this.logger.debug({e});

      this.initialDeferred.reject(e);

    });

  }

  private reload(forceRefresh?: boolean) {

    this.logger.debug({ forceRefresh }, 'reloading');

    this.load();

  }

  signIn(phoneNumber: string) {

    const params: SignInInput = {
      username: phoneNumber,
      options: {
        authFlowType: 'CUSTOM_WITHOUT_SRP',
      },
    };

    this.logger.debug({ params }, 'signIn');

    return signIn(params)
    .then((result: SignInOutput) => {

      const nextStep = result.nextStep;

      this.isSignedIn = result.isSignedIn;

      switch (nextStep.signInStep) {

        case 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE':

          this.codeSent = true;
          
          break;
      
      }

      return result;

    });

  }

  signOut() {

    return signOut();

  }

  signUp(phoneNumber: string, userAttributes: UserAttributes) {

    const password = customAlphabet(alphanumeric + '!@#$%^&*()', 16)() + 'a1$';

    const params = {
      username: phoneNumber,
      password,
      options: {
        userAttributes,
      },
    };

    this.logger.debug({ params }, 'signUp');

    return signUp(params)
    .then((result: SignUpOutput) => {

      this.logger.debug({ result }, 'signUp');

      return this.signIn(phoneNumber);

    });

  }

  verifySignIn(code: string) {

    const params = {
      challengeResponse: code,
    };

    return confirmSignIn(params)
    .then((result: ConfirmSignInOutput) => {

      this.isSignedIn = result.isSignedIn;

      switch (result.nextStep.signInStep) {

        case 'DONE':

          break;
      
      }

      return result;

    });

  }

  waitForAuthedUser() {

    return this.waitForAuthedPromise;

  }

  waitForCredentials() {

    return this.waitForCredsPromise;

  }

  waitForIdentity() {

    return firstValueFrom(this.identity$
      .pipe(filter((identity?: ExtendedIdentityTokenPayload): identity is ExtendedIdentityTokenPayload => {

        return (identity) ? true : false;

      }))
    );

  }

  waitForIdentityToken() {

    return firstValueFrom(this.identityToken$
      .pipe(filter((token): token is JWT => {

        return (token) ? true : false;

      }))
    );

  }

}


// import { Inject, Injectable, OnDestroy } from '@angular/core';
// import { HubCapsule } from '@aws-amplify/core';
// import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
// import { BehaviorSubject, distinctUntilChanged, filter, firstValueFrom, Observable, Subscription } from 'rxjs';
// import { IdService } from '@engagedcx/ng-id';
// import Bluebird from 'bluebird';
// import { AuthedUser } from './common';
// import { AWSCredentials } from '@aws-amplify/core/internals/utils';
// import { DEFAULT_LOGGER } from '../logger';
// import { Logger } from 'pino';

// const SAVED_URL_KEY = 'ngAuth.saved_url';

// export const DEFAULT_DIALOG_WIDTH = '80%';
// export const DEFAULT_DIALOG_HEIGHT = '80%';

// @Injectable({
//   providedIn: 'root'
// })
// export class AuthService implements OnDestroy {

//   private authenticating = new BehaviorSubject(false);

//   authenticating$: Observable<boolean>;

//   private cognitoUser = new BehaviorSubject<CognitoUser | undefined>(undefined);

//   cognitoUser$: Observable<CognitoUser | undefined> = this.cognitoUser.asObservable();

//   private credentials = new BehaviorSubject<AWSCredentials>({
//     accessKeyId: '',
//     secretAccessKey: '',
//     sessionToken: ''
//   });

//   credentials$: Observable<AWSCredentials>;

//   // eslint-disable-next-line
//   private hubCancel: any;

//   initialAuth: Bluebird<boolean>;
  
//   private initialAuthReject: (reason: Error) => void;

//   private initialAuthResolve: (result: boolean) => void;

//   isAuthed = false;

//   private session = new BehaviorSubject<CognitoUserSession | undefined>(undefined);

//   session$: Observable<CognitoUserSession | undefined>;
  
//   private subs: {[key: string]: Subscription} = {};

//   private user = new BehaviorSubject<AuthedUser | undefined>(undefined);

//   user$: Observable<AuthedUser | undefined>;

//   constructor(
//     private id: IdService,
//     @Inject(DEFAULT_LOGGER) private logger: Logger,
//   ) { 

//     this.authenticating$ = this.authenticating.pipe(distinctUntilChanged());

//     this.credentials$ = this.credentials
//     .pipe(distinctUntilChanged((prev, curr) => {

//       return prev.sessionToken === curr.sessionToken;

//     }));

//     this.session$ = this.session
//     .pipe(distinctUntilChanged((prev?: CognitoUserSession | any, curr?: CognitoUserSession | any) => {

//       return prev?.idToken.jwtToken === curr?.idToken.jwtToken;

//     }));

//     this.user$ = this.user
//     .pipe(distinctUntilChanged((prev?: AuthedUser, curr?: AuthedUser) => {
      
//       return prev?.user_id === curr?.user_id;

//     }));

//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     this.initialAuth = new Bluebird((resolve: (value: boolean) => void, reject: (reason?: any) => void) => {

//       this.initialAuthReject = reject;
//       this.initialAuthResolve = resolve;

//     });

//   }

//   attemptSignIn(phone_number: string) {

//     this.authenticating.next(true);

//     return Auth.signIn(phone_number)
//     .then((user: CognitoUser) => {

//       this.cognitoUser.next(user);

//       return user;

//     })
//     .finally(() => {

//       this.authenticating.next(false);

//     });

//   }

//   authenticate() {

//     this.authenticating.next(true);

//     return this.reload()
//     .finally(() => {

//       this.authenticating.next(false);

//     });

//   }

//   getCredentials() {

//     return Auth.currentCredentials()
//     .then((credentials) => {

//       if (credentials.authenticated) {

//         this.isAuthed = true;

//       }


//       this.credentials.next(credentials);

//       return credentials;

//     });

//   }

//   getSavedUrl() {

//     return localStorage.getItem(SAVED_URL_KEY);

//   }

//   getSession() {

//     return Auth.currentSession()
//     .then((session: CognitoUserSession) => {

//       this.session.next(session);

//       return session;

//     });

//   }

//   getUser() {

//     return Auth.currentAuthenticatedUser()
//     // eslint-disable-next-line
//     .then((result: CognitoUser | any) => {

//       return this.getSession()
//       .then((session: CognitoUserSession) => {

//         const payload = session.getIdToken().payload;

//         const attrs = result.attributes || result;
  
//         const user: AuthedUser = {
//           ...attrs,
//           user_id: payload['user_id'] || attrs.user_id || attrs.sub,
//           picture: payload['picture'],
//           roles: (payload['roles']) ? JSON.parse(payload['roles']) : [],
//         };
  
//         this.user.next(user);
  
//         return user;
  
//       });

//     });

//   }

//   init() {

//     this.hubCancel = Hub.listen('auth', (data: HubCapsule) => {

//       this.logger.debug(data);

//       switch (data.payload.event) {

//         case 'configured':

//           this.onConfigured();
        
//           break;

//         case 'signIn':

//           this.onSignIn();

//           break;
        
//         case 'signOut':

//           this.onSignOut();

//           break;
        
//       }

//     });

//   }

//   isAuthenticated() {

//     return this.initialAuth
//     .then((isAuthed: boolean) => {

//       if (isAuthed) {

//         return true;

//       } else {

//         return this.getCredentials()
//         .then((creds: ICredentials) => {

//           return creds.authenticated;

//         });

//       }

//     });

//   }

//   ngOnDestroy(): void {
    
//     Object.values(this.subs).forEach((sub: Subscription) => {

//       sub.unsubscribe();

//     });

//     if (this.hubCancel) {

//       this.hubCancel();

//     }

//   }

//   private onConfigured() {

//     this.logger.debug('configured', 'getting credentials');

//     this.reload();

//   }

//   private onSignIn() {

//     this.reload();

//   }

//   private onSignOut() {

//     this.reload();

//   }

//   private reload() {

//     return this.getCredentials()
//     .then((credentials: ICredentials) => {

//       if (this.initialAuth.isPending()) {

//         this.initialAuthResolve(credentials.authenticated);

//       }

//       if (!credentials.authenticated) {

//         this.session.next(undefined);
//         this.user.next(undefined);

//         return false;

//       }

//       return Bluebird.props({
//         session: this.getSession(),
//         user: this.getUser(),
//       })
//       .then(() => {

//         return true;

//       });

//     });

//   }

//   signIn(phone_number: string) {

//     this.authenticating.next(true);

//     return Auth.signIn(phone_number)
//     .then((user: CognitoUser) => {

//       this.cognitoUser.next(user);

//       return user;

//     });

//   }

//   signOut() {

//     this.isAuthed = false;

//     this.authenticating.next(false);

//     return Auth.signOut({});
    
//   }

//   signUpEmail(email: string) {

//     return Auth.updateUserAttributes(this.cognitoUser.value, {
//       email,
//     });

//   }

//   signUpPhoneNumber(phone_number: string, attributes: {
//     name: string;
//     zoneinfo?: string;
//   }) {

//     const password = this.id.password(16);

//     return Auth.signUp({
//       attributes,
//       username: phone_number,
//       password,
//     });

//   }

//   verifySignIn(otp: string, user?: CognitoUser) {

//     console.log('verifySignIn', 'otp', otp);

//     return Auth.sendCustomChallengeAnswer(user || this.cognitoUser.value, otp)
//     .then(() => {

//       return this.getCredentials()
//       .then(() => {

//         return Promise.all([
//           this.getUser(),
//           this.getSession(),
//         ]);

//       });

//     })
//     .finally(() => {

//       this.authenticating.next(false);

//     });

//   }

//   waitForAuthed(): Promise<AuthedUser> {

//     return firstValueFrom(this.user$.pipe(
//       filter((user: AuthedUser | undefined): user is AuthedUser => {

//         return (user) ? true : false;

//       })
//     ));

//   }
  
//   waitForCredentials(): Promise<ICredentials> {
    
//     return firstValueFrom(this.credentials$.pipe(
//       filter((credentials: ICredentials) => {

//         if (credentials.accessKeyId !== '') {

//           return true;

//         } else {

//           return false;
//         }

//       })
//     ));
    
//   }
  
// }