import { Component, OnInit } from '@angular/core';
import { SubsComponent } from '@bcx/ng-helpers';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

export interface ViewModel {

  progress: number;

  showQuestions: boolean;

}

@Component({
  selector: 'app-coach-questionaire',
  templateUrl: './questionaire.component.html',
  styleUrl: './questionaire.component.sass',
})
export class QuestionaireComponent extends SubsComponent implements OnInit {

  private goals = new BehaviorSubject(0);

  private progress = new BehaviorSubject(0);

  progress$ = this.progress.asObservable();

  private questions = new BehaviorSubject(0);

  private showQuestions = new BehaviorSubject(false);

  vm$: Observable<ViewModel> = combineLatest({
    progress: this.progress$,
    showQuestions: this.showQuestions.asObservable(),
  });
  
  ngOnInit(): void {
    
    this.subs['progress'] = combineLatest({
      goals: this.goals.asObservable(),
      questions: this.questions.asObservable(),
    })
    .subscribe(({ goals, questions }) => {

      const progress = Math.ceil((goals + questions) / 2);

      this.progress.next(progress);

      console.log('coach questionaire', { progress, goals, questions });

    });

  }

  onGoalsProgress(progress: number) {

    this.goals.next(progress);

    if (progress >= 100) {

      this.showQuestions.next(true);

    } else {

      this.showQuestions.next(false);

    }

  }

  onQuestionsProgress(progress: number) {

    this.questions.next(progress);

  }

}
