import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntlComponent } from './intl/intl.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

@NgModule({
  declarations: [IntlComponent],
  exports: [IntlComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
  ],
})
export class PhoneNumberModule {}
