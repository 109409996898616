import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LengthOfTimeComponent } from './length-of-time/length-of-time.component';
import { NumberOfPeopleComponent } from './number-of-people/number-of-people.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectIndustryComponent } from './select-industry/select-industry.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    LengthOfTimeComponent,
    NumberOfPeopleComponent,
    SelectIndustryComponent,
    ToggleSwitchComponent,
  ],
  exports: [
    LengthOfTimeComponent,
    NumberOfPeopleComponent,
    SelectIndustryComponent,
    ToggleSwitchComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatSelectModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
  ],
})
export class QuestionModule {}
