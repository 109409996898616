import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProductIdMap } from '@bcx/models';

@Component({
  selector: 'app-owner-book-step-content',
  templateUrl: './step-content.component.html',
  styleUrl: './step-content.component.sass',
})
export class StepContentComponent {

  safeUrl: SafeResourceUrl;

  product_id = ProductIdMap.training.books.owner;

  constructor(
    private sani: DomSanitizer,
  ) {

    this.safeUrl = this.sani.bypassSecurityTrustUrl('https://www.amazon.com/gp/product/B0D8KRSLN8?ref=ppx_pt2_dt_b_prod_image')

  }

}
