<mat-stepper 
  *ngIf="(vm$ | async) as vm"
  orientation="vertical">

  <mat-step 
    *ngFor="let step of vm.path.steps; index as i"
    [completed]="false" [editable]="true">

    <ng-template matStepLabel
      [ngSwitch]="step.step_type">
    
      <app-coach-book-step-label *ngSwitchCase="BOOK"></app-coach-book-step-label>
    
      <app-coach-consulting-step-label *ngSwitchCase="CONSULTING"></app-coach-consulting-step-label>
    
      <app-coach-training-step-label *ngSwitchCase="TRAINING"></app-coach-training-step-label>
    
    </ng-template>

    <ng-template matStepContent
      [ngSwitch]="step.step_type">
    
      <div class="flex flex-row"
        *ngIf="i === 0">

        <button type="button" color="primary"
          (click)="showOtherOptions(vm.path)"
          mat-button>Other options starting {{vm.otherOptionsPrice | stripeCurrency:vm.currency}}</button>
    
      </div>
    
      <app-coach-book-step-content *ngSwitchCase="BOOK"></app-coach-book-step-content>
    
      <app-coach-consulting-step-content *ngSwitchCase="CONSULTING"></app-coach-consulting-step-content>
    
      <app-coach-training-step-content *ngSwitchCase="TRAINING"></app-coach-training-step-content>
    
    </ng-template>

  </mat-step>

  <mat-step 
    [completed]="false" 
    [editable]="true">

    <ng-template matStepLabel>Success! Your team is providing the best experiences possible!</ng-template>
    
    <ng-template matStepContent>



    </ng-template>

  </mat-step>

</mat-stepper>
