import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoalsComponent } from './goals/goals.component';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { QuestionsComponent } from './questions/questions.component';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PathComponent } from './path/path.component';
import { QuestionaireComponent } from './questionaire/questionaire.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { GoalModule } from '../goal/goal.module';
import { QuestionModule } from '../question/question.module';
import { GoalsCardComponent } from './goals-card/goals-card.component';

@NgModule({
  declarations: [
    GoalsComponent,
    QuestionsComponent,
    PathComponent,
    QuestionaireComponent,
    GoalsCardComponent,
  ],
  exports: [
    GoalsComponent,
    PathComponent,
    QuestionsComponent,
    QuestionaireComponent,
    GoalsCardComponent,
  ],
  imports: [
    CommonModule,
    GoalModule,
    MatCardModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    QuestionModule,
    ReactiveFormsModule,
  ],
})
export class ProviderModule {}
