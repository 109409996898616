import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { ProviderQuestionsForm, ProviderQuestionsMap } from './questions.form';
import { BusinessOperationLengths, Industries } from '@bcx/models';
import { combineLatest, map, Observable, throttleTime } from 'rxjs';
import { GetStartedService } from '../../get-started/get-started.service';
import { ListBaseComponent } from '../../goal/list-base/list-base.component';
import { FormControl } from '@angular/forms';

export interface ViewModel {

  progress: number;

  questions: ProviderQuestionsMap;

}

@Component({
  selector: 'app-provider-questions',
  templateUrl: './questions.component.html',
  styleUrl: './questions.component.sass',
})
export class QuestionsComponent extends ListBaseComponent implements OnDestroy, OnInit {

  earnCommissionBonus: FormControl;

  form = new ProviderQuestionsForm();

  industry: FormControl;

  industries = Industries;

  lengthInIndustry: FormControl;
  
  lengthInPosition: FormControl;

  lengths = BusinessOperationLengths;

  @Output() override progress$: Observable<number>;

  questions$: Observable<ProviderQuestionsMap>;

  vm$: Observable<ViewModel>;

  constructor(private service: GetStartedService) {

    super();

    this.earnCommissionBonus = this.form.get('earn_commission_bonus') as FormControl;
    this.industry = this.form.get('industry') as FormControl;
    this.lengthInIndustry = this.form.get('length_in_industry') as FormControl;
    this.lengthInPosition = this.form.get('length_in_position') as FormControl;

    const state = this.service.getCurrentState();

    this.form.patchValue(state.provider.questions);

    this.questions$ = this.service.state$.pipe(map((state) => state.provider.questions));

    this.vm$ = combineLatest({
      progress: this.progress$,
      questions: this.questions$,
    });

  }

  ngOnInit(): void {
    
    this.subs['questions'] = this.questions$
    .pipe(throttleTime(250))
    .subscribe(() => this.calculateProgress() );

    this.subs['valueChanges'] = this.form.valueChanges
    .pipe(throttleTime(250))
    .subscribe((questions) => {

      this.service.save({
        provider: {
          questions,
        },
      });

    });

  }

}
