import { TrainingBook, TrainingBookType } from "./training/books";
import { TrainingCourse } from "./training/courses";
import { COACH, CONSULTING, EBOOK, IN_PERSON, ONLINE, OWNER, PROVIDER, SMALL_TO_BEST, TRAINING } from "./common";
import { CAD, MetaData, ConsultingSessionCategory, ConsultingSessionType, TrainingClassType, USD } from "./models";
import {Stripe} from "stripe";

export interface ProductDetails {

  active: boolean;

  description: string;

  metadata: MetaData;

  name: string;

}

export type ProductId = string;

export const ProductIdMap: {
  consulting: {
    sessions: {
      [key in ConsultingSessionCategory]: string;
    };
  };
  training: {
    books: {
      [key in TrainingBook]: string;
    };
    courses: {
      [key in TrainingCourse]: string;
    };
  };
} = {
  consulting: {
    sessions: {
      "coach": `${CONSULTING}.session.${COACH}`,
      "owner": `${CONSULTING}.session.${OWNER}`,
      "provider": `${CONSULTING}.session.${PROVIDER}`,
      "small-to-best": `${CONSULTING}.session.${SMALL_TO_BEST}`,
    },
  },
  training: {
    books: {
      'coach': `${TRAINING}.book.${COACH}`,
      'owner': `${TRAINING}.book.${OWNER}`,
      'provider': `${TRAINING}.book.${PROVIDER}`,
      'small-to-best': `${TRAINING}.book.${SMALL_TO_BEST}`,
    },
    courses: {
      coach: `${TRAINING}.course.${COACH}`,
      owner: `${TRAINING}.course.${OWNER}`,
      provider: `${TRAINING}.course.${OWNER}`,
      "small-to-best": `${TRAINING}.course.${OWNER}`,
    }
  },
};

export const ProductIds: Array<string> = ([] as Array<string>)
  .concat(Object.values(ProductIdMap.consulting.sessions))
  .concat(Object.values(ProductIdMap.training.books))
  .concat(Object.values(ProductIdMap.training.courses));

export const ProductDetailsMap: {
  [product_id: string]: ProductDetails
} = {};

ProductDetailsMap[ProductIdMap.consulting.sessions.coach] = {
  active: true,
  description: 'Meet one on one with our experts. Discuss any thing about your team.',
  metadata: {},
  name: 'Coach Consulting Session',
};
  
ProductDetailsMap[ProductIdMap.consulting.sessions.owner] = {
  active: true,
  description: 'Meet one on one with our experts. Discuss any thing about your team.',
  metadata: {},
  name: 'Owner Consulting Session',
};

ProductDetailsMap[ProductIdMap.consulting.sessions.provider] = {
  active: true,
  description: 'Meet one on one with our experts. Discuss any thing about your team.',
  metadata: {},
  name: 'Provider Consulting Session',
};

ProductDetailsMap[ProductIdMap.consulting.sessions["small-to-best"]] = {
  active: true,
  description: 'Meet one on one with our experts. Discuss any thing about your small business/side hustle/hobby for profit.',
  metadata: {},
  name: 'Small To Best Consulting Session',
};

export interface ProductPrice {

  book?: TrainingBook;

  book_type?: TrainingBookType;

  class_type?: TrainingClassType;

  course?: TrainingCourse;

  currency: string;

  is_default?: boolean;

  non_refundable_amount?: number;

  number_of_payments?: number;

  past_due_fee?: number;

  recurring?: Stripe.PriceCreateParams.Recurring;

  session_category?: ConsultingSessionCategory;

  session_type?: ConsultingSessionType;

  stripe_price_id: string;

  stripe_product_id: string;

  unit_amount: number;

}

export interface ProductPricingRecurring {

  interval: string;

  interval_count: number;

}

export const ProductPricingMap: {
  [id: string]: Array<ProductPrice>;
} = {};

ProductPricingMap[ProductIdMap.consulting.sessions.coach] = [
  {
    currency: CAD,
    past_due_fee: 3400,
    session_type: ONLINE,
    stripe_price_id: '',
    stripe_product_id: '',
    unit_amount: 6825,
  },
  {
    currency: CAD,
    past_due_fee: 3400,
    session_type: IN_PERSON,
    stripe_price_id: '',
    stripe_product_id: '',
    unit_amount: 13500,
  },
  {
    currency: USD,
    past_due_fee: 2500,
    session_type: ONLINE,
    stripe_price_id: '',
    stripe_product_id: '',
    unit_amount: 500,
  },
  {
    currency: USD,
    past_due_fee: 2500,
    session_type: IN_PERSON,
    stripe_price_id: '',
    stripe_product_id: '',
    unit_amount: 10000,
  },
];

ProductPricingMap[ProductIdMap.training.books.coach] = [
  {
    currency: CAD,
    book_type: EBOOK,
    stripe_price_id: '',
    stripe_product_id: '',
    unit_amount: 3500,
  },
  {
    currency: USD,
    book_type: EBOOK,
    stripe_price_id: '',
    stripe_product_id: '',
    unit_amount: 3500,
  }
];

ProductPricingMap[ProductIdMap.training.courses.coach] = [
  {
    currency: CAD,
    class_type: ONLINE,
    number_of_payments: 1,
    recurring: {
      interval: 'month',
      interval_count: 1,
    },
    stripe_price_id: '',
    stripe_product_id: '',
    unit_amount: 150000,
  }
];

export const ProductTypeMap = {
  CONSULTING,
  TRAINING,
} as const;

export type ProductType = typeof ProductTypeMap[keyof typeof ProductTypeMap];
