<div fxLayout="column"
  *ngIf="form"
  [formGroup]="form">

  <mat-form-field>

    <mat-select placeholder="Training Role"
      formControlName="role"
      matInput>

      <mat-option value="owner">Owner</mat-option>
      <mat-option value="provider">Provider</mat-option>

    </mat-select>

    <mat-error>Please select a role.</mat-error>

  </mat-form-field>

  <mat-form-field>

    <mat-select placeholder="Class Number"
      formControlName="class_number"
      matInput>

      <mat-option [value]="1">Class 1</mat-option>
      <mat-option [value]="2">Class 2</mat-option>
      <mat-option [value]="3">Class 3</mat-option>

    </mat-select>

    <mat-error>Please select a class number.</mat-error>

  </mat-form-field>

  <mat-divider></mat-divider>

  <ng-training-class-select-start
    [control]="startEpoch"></ng-training-class-select-start>

</div>