import { Component, Inject } from '@angular/core';
import { SubsComponent } from '@bcx/ng-helpers';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SessionService } from '../session.service';
import { CreateForm } from '../create-form/create-form.form';

export interface CreateDialogData {

  session_category?: string;

  session_role?: string;

  session_type?: string;

}

@Component({
  selector: 'ng-consulting-create-dialog',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.sass'],
})
export class CreateDialogComponent extends SubsComponent {

  error?: string;

  form: CreateForm;

  processing: boolean;

  sessionId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: CreateDialogData,
    private dialog: MatDialogRef<CreateDialogComponent>,
    private service: SessionService,
  ) {

    super();

    this.form = new CreateForm();

    this.sessionId = this.service.createId();

    this.form.patchValue(data);

  }

  closeDialog() {

    this.dialog.close();

  }

  submit() {

    this.service.request({
      ...this.form.value,
      session_id: this.sessionId,
    })
    .then((created) => {

      this.dialog.close(created);

    })
    .catch((e: Error) => {

      console.error(e);

    });

  }

}

