import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { GetStartedService } from '../get-started/get-started.service';
import Bluebird, { props } from 'bluebird';
import { CoachRecommendations } from './coach.recommendations';
import { OwnerRecommendations } from './owner.recommendations';
import { ProviderRecommendations } from './provider.recommendations';
import { SmallToBestRecommendations } from './small-to-best.recommendations';
import { GetStartedState } from '../get-started/get-started.state';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RecommendationCriteria = any;

export type RecommendationFilters = {

// eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (value: any) => boolean;
  
};

export interface Recommendation {

  content: string;

  isMatch: (state: GetStartedState) => boolean | Promise<boolean>;

  products: Array<string>;

}

export interface RecommendationMap {

  [key: string]: Recommendation;

}

export type RecommendationList = Array<Recommendation>;

export interface RecommendationListMap {

  [key: string]: RecommendationList;

}

@Injectable({
  providedIn: 'root'
})
export class RecommendationService implements OnDestroy {

  private recommendations = new BehaviorSubject<RecommendationListMap>({});

  recommendations$ = this.recommendations.asObservable();

  private sub: Subscription;

  constructor(
    private service: GetStartedService,
  ) { 

    this.sub = this.service.state$
    .subscribe((state) => {

      this.determineRecommendations(state);

    });

  }

  determineRecommendations(state: GetStartedState) {

    return props({
      coach: Bluebird.filter(CoachRecommendations, (reco) => reco.isMatch(state)),
      owner: Bluebird.filter(OwnerRecommendations, (reco) => reco.isMatch(state)),
      provider: Bluebird.filter(ProviderRecommendations, (reco) => reco.isMatch(state)),
      side_hustle: Bluebird.filter(SmallToBestRecommendations, (reco) => reco.isMatch(state)),
    })
    .then((results) => {
      
      const listMap: RecommendationListMap = {};

      Object.values(results).forEach((recos) => {

        recos.forEach((reco) => {

          reco.products.forEach((product_id) => {

            listMap[product_id] = listMap[product_id] || [];

            listMap[product_id].push(reco);
  
          }); 

        });

      });

      this.recommendations.next(listMap);

    });

  }

  ngOnDestroy(): void {
    
    this.sub.unsubscribe();

  }

}
