import { Injectable } from '@angular/core';
import { SeatServiceAdapter } from './seat.service.adapter';
import { ApiService } from '@engagedcx/ng-amplify';
import { RestServerResponse, TRAINING_SEAT, TrainingSeat, TrainingSeatConfirmAction, TrainingSeatConfirmationSentEvent, TrainingSeatConfirmedEvent, TrainingSeatGetAction, TrainingSeatInterface, TrainingSeatListAction, TrainingSeatListedEvent, TrainingSeatReleaseAction, TrainingSeatReleasedEvent, TrainingSeatRequestAction, TrainingSeatRequestedEvent, TrainingSeatResendConfirmationAction, TrainingSeatViewedEvent } from '@bcx/models';
import { List } from '@digitaltoolbuilders/model-helpers';

@Injectable({
  providedIn: 'root'
})
export class RestService implements SeatServiceAdapter {

  private path = `/${TRAINING_SEAT}`;

  constructor(private api: ApiService) { }

  confirm(action: TrainingSeatConfirmAction): Promise<TrainingSeatConfirmedEvent> {
    
    return this.api.post<RestServerResponse<TrainingSeatInterface>>(this.path, action)
    .then((response) => {

      return new TrainingSeatConfirmedEvent({
        ...response.data,
        payload: new TrainingSeat(response.data.payload),
      });

    });

  }

  get(action: TrainingSeatGetAction): Promise<TrainingSeatViewedEvent> {
    
    return this.api.post<RestServerResponse<TrainingSeatInterface>>(this.path, action)
    .then((response) => {

      return new TrainingSeatViewedEvent({
        ...response.data,
        payload: new TrainingSeat(response.data.payload),
      });

    });

  }

  list(action: TrainingSeatListAction): Promise<TrainingSeatListedEvent> {
    
    return this.api.post<RestServerResponse<List<TrainingSeatInterface>>>(this.path, action)
    .then((response) => {

      return new TrainingSeatListedEvent({
        ...response.data,
        payload: new List<TrainingSeat>({
          ...response.data.payload,
          list: response.data.payload.list.map((item) => new TrainingSeat(item)),
        }),
      });

    });

  }

  release(action: TrainingSeatReleaseAction): Promise<TrainingSeatReleasedEvent> {
    
    return this.api.post<RestServerResponse<TrainingSeatInterface>>(this.path, action)
    .then((response) => {

      return new TrainingSeatReleasedEvent({
        ...response.data,
        payload: new TrainingSeat(response.data.payload),
      });

    });

  }

  request(action: TrainingSeatRequestAction): Promise<TrainingSeatRequestedEvent> {
    
    return this.api.post<RestServerResponse<TrainingSeatInterface>>(this.path, action)
    .then((response) => {

      return new TrainingSeatRequestedEvent({
        ...response.data,
        payload: new TrainingSeat(response.data.payload),
      });

    });

  }

  sendConfirmation(action: TrainingSeatResendConfirmationAction): Promise<TrainingSeatConfirmationSentEvent> {
    
    return this.api.post<RestServerResponse<TrainingSeatInterface>>(this.path, action)
    .then((response) => {

      return new TrainingSeatConfirmationSentEvent({
        ...response.data,
        payload: new TrainingSeat(response.data.payload),
      });

    });

  }

}
