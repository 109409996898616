import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TrainingSeat } from '@bcx/models';

@Component({
  selector: 'ng-training-seat-list-table',
  templateUrl: './list-table.component.html',
  styleUrls: ['./list-table.component.sass'],
})
export class ListTableComponent implements OnChanges {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() actions: TemplateRef<any>;

  @Input() display = [
    'seat_id',
    'state',
    'actions',
  ];

  @Input() seats: Array<TrainingSeat> = [];

  table = new MatTableDataSource<TrainingSeat>([]);

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['seats']) {

      this.table.data = this.seats;

    }

  }

}
