import { Component } from '@angular/core';
import { ProductIdMap } from '@bcx/models';
import { SettingsService } from '@bcx/ng-models';
import { combineLatest, Observable, of } from 'rxjs';

export interface ViewModel {

  currency: string;

  price: number;

}

@Component({
  selector: 'app-coach-book-step-label',
  templateUrl: './step-label.component.html',
  styleUrl: './step-label.component.sass',
})
export class StepLabelComponent {

  product_id = ProductIdMap.training.books.coach;

  vm$: Observable<ViewModel>;

  constructor(
    private settings: SettingsService,
  ) {

    this.vm$ = combineLatest({
      currency: this.settings.currency$,
      price: of(999),
    });

  }

}
