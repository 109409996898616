
import { STRIPE_CUSTOMER } from '@bcx/models';
import { createAction, props } from '@ngrx/store';
import { Stripe } from 'stripe';

export interface LoadMyCustomerProps {

  data?: Stripe.Customer;

  error?: string;

}

export const loadMyCustomer = createAction(
  '[Stripe.Customer] Load My Customer'
);

export const loadMyCustomerSuccess = createAction(
  '[Stripe.Customer] Load My Customer Success',
  props<LoadMyCustomerProps>()
);

export const loadMyCustomerFailure = createAction(
  '[Stripe.Customer] Load My Customer Failure',
  props<LoadMyCustomerProps>()
);

export interface LoadCustomerProps {

  customer?: Stripe.Customer;

  error?: string;

  id: string;

  loading: boolean;

}

export const loadCustomer = createAction(
  '[Stripe.Customer] Load Customer Method',
  props<LoadCustomerProps>(),
);

export interface LoadAllCustomersProps {

  data?: { data:Array<Stripe.Customer>, has_more: boolean };

  error?: string;

  last?: string;

  loading: boolean;

}

export const loadAllCustomers = createAction(
  '[Stripe.Customer] Load All Customers',
  props<LoadAllCustomersProps>(),
);

export interface UpdateCustomerProps {

  data?: Stripe.Customer;

  error?: string;

  id: string;

  processing: boolean;

}

export const updateCustomer = createAction(
  `[${STRIPE_CUSTOMER}] Update`,
  props<UpdateCustomerProps>(),
);

export const updateMyCustomer = createAction(
  `[${STRIPE_CUSTOMER}] Update My`,
  props<UpdateCustomerProps>(),
);
